import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadStucklisteSuppliersUseCase from 'modules/inbound/stucklisteSuppliers/application/DownloadStucklisteSuppliersUseCase';

const downloadStucklisteSuppliers = createAsyncThunk(
  'stucklisteSuppliers/downloadStucklisteSuppliers',
  async (providerIds?: number[]) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let queryParams = {
      tz: timeZone
    };
    if (providerIds?.length) {
      queryParams['provider_ids'] = `[${providerIds}]`;
    }
    const response = await DownloadStucklisteSuppliersUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { downloadStucklisteSuppliers };
