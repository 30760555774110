import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { UnloadingPointEntryDTO } from '../../infrastructure/dto/UnloadingPointsDTO/entriesBySlotAndUnloadingPointDTOMapper';
import {
  ALL_UNLOADING_POINTS_STATE,
  AllUnloadingPointStateTypes,
  UnloadingPointStateType
} from '../../infrastructure/dto/types';

export interface IUnloadingPointEntry {
  urgent: boolean;
  isCritical: boolean;
  unloadingPointBooked: boolean;
  executedState: AllUnloadingPointStateTypes;
  startTime: string;
  endTime: string;
  plateNumber: string;
  transportId: number;
  carrier: string;
  supplierName: string;
  stops: number;
  packages: number;
}

class UnloadingPointEntry implements IUnloadingPointEntry {
  urgent: boolean;
  isCritical: boolean;
  unloadingPointBooked: boolean;
  executedState: AllUnloadingPointStateTypes;
  startTime: string;
  endTime: string;
  plateNumber: string;
  transportId: number;
  carrier: string;
  supplierName: string;
  stops: number;
  packages: number;

  constructor({
    urgent,
    isCritical,
    unloadingPointBooked,
    executedState,
    startTime,
    endTime,
    plateNumber,
    transportId,
    carrier,
    supplierName,
    stops,
    packages
  }: IUnloadingPointEntry) {
    this.urgent = urgent;
    this.isCritical = isCritical;
    this.unloadingPointBooked = unloadingPointBooked;
    this.executedState = executedState;
    this.startTime = startTime;
    this.endTime = endTime;
    this.plateNumber = plateNumber;
    this.transportId = transportId;
    this.carrier = carrier;
    this.supplierName = supplierName;
    this.stops = stops;
    this.packages = packages;
  }

  private static _getHourFromUtc(utc: string): string {
    return utc ? `${GetDateFormatedService.getShortHourLocale(utc)}h` : '-';
  }

  private static _isOfTypeUnloadingPointState(
    type: UnloadingPointStateType | null
  ): boolean {
    return ALL_UNLOADING_POINTS_STATE.includes(type as UnloadingPointStateType);
  }

  static generateFromDTO(entryDTO: UnloadingPointEntryDTO) {
    return new UnloadingPointEntry({
      urgent: !!entryDTO.urgent,
      isCritical: entryDTO.is_critical,
      carrier: entryDTO.carrier,
      unloadingPointBooked: entryDTO.unloading_point_booked,
      executedState: this._isOfTypeUnloadingPointState(entryDTO.executed_state)
        ? entryDTO.executed_state
        : 'UNKNOWN',
      plateNumber: entryDTO.plate_number,
      transportId: entryDTO.transport_id,
      supplierName: entryDTO.supplier_name,
      stops: entryDTO.stops,
      startTime: this._getHourFromUtc(entryDTO.start_time),
      endTime: this._getHourFromUtc(entryDTO.end_time),
      packages: entryDTO.packages
    });
  }
}

export { UnloadingPointEntry };
