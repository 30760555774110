import IJitRepository from '../domain/IJitRepository';
import PartCoverageData from '../domain/PartCoverageData';
import jitRepository from '../infrastructure/repositories/JitRepository';

class GetPartCoverageService {
  private _jitRepository: IJitRepository;

  constructor({ repository }) {
    this._jitRepository = repository;
  }

  execute({
    queryParams,
    username
  }: {
    queryParams: object;
    username: string;
  }) {
    return this._jitRepository
      .get({ queryParams, username })
      .then((res) => PartCoverageData.generateFromDTO(res));
  }
}

export default new GetPartCoverageService({ repository: jitRepository });

export { GetPartCoverageService };
