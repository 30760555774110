import IncomingMaterials from 'modules/inbound/entries/domain/NextHours/IncomingMaterials';

export enum EntryType {
  TRUCKS = 'TRUCKS',
  PACKAGES = 'PACKAGES'
}
export enum GroupBy {
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}

export type SelectedHour = {
  top: string;
  color: string;
  utcDate: string;
};
export interface DischargeDocksState {
  incomingMaterialsNextDays: IncomingMaterials;
  incomingMaterialsNextHours: IncomingMaterials;
  incomingMaterialsNextDaysRequestStatus: string;
  incomingMaterialsNextHoursRequestStatus: string;
  selectedHoursEntryType: EntryType;
  selectedDaysEntryType: EntryType;
  selectedHour: SelectedHour;
}
