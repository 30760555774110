export interface ICallOffTracking {
  warehouseId: string;
  name: string;
  totalCars: number;
  onTimeCarsPercentage: number;
  onTimeCars: number;
}

interface GenerateFromDTOParams {
  id: string;
  name: string;
  totalCars: number;
  delayedCars: number;
}

class CallOffTracking implements ICallOffTracking {
  warehouseId: string;
  name: string;
  totalCars: number;
  onTimeCarsPercentage: number;
  onTimeCars: number;

  constructor({
    warehouseId,
    name,
    totalCars,
    onTimeCarsPercentage,
    onTimeCars
  }: CallOffTracking) {
    this.warehouseId = warehouseId;
    this.name = name;
    this.totalCars = totalCars;
    this.onTimeCarsPercentage = onTimeCarsPercentage;
    this.onTimeCars = onTimeCars;
  }
  static generateFromDTO({
    id,
    name,
    totalCars,
    delayedCars
  }: GenerateFromDTOParams): CallOffTracking {
    return new CallOffTracking({
      warehouseId: id,
      name,
      totalCars,
      onTimeCarsPercentage:
        Math.round(((totalCars - delayedCars) / totalCars) * 100) || 0,
      onTimeCars: totalCars - delayedCars
    });
  }

  static generateEmpty() {
    return new CallOffTracking({
      warehouseId: '',
      name: '',
      totalCars: 0,
      onTimeCarsPercentage: 0,
      onTimeCars: 0
    });
  }
}

export default CallOffTracking;
