import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

export interface CountryRoutesWaypointDTO {
  id: string;
  movementType: string;
  name: string;
  type: CheckpointType;
  totalCars: number;
  order: number;
}

export interface CountryRoutesLinkDTO {
  sourceWaypointId: string;
  sourceWaypointMovementType: string;
  destinationWaypointId: string;
  destinationWaypointMovementType: string;
  totalCars: number;
}

class CountryRoutesDTO {
  waypoints: CountryRoutesWaypointDTO[];
  links: CountryRoutesLinkDTO[];

  constructor({ waypoints, links }) {
    this.waypoints = waypoints;
    this.links = links;
  }
}

export default CountryRoutesDTO;
