export interface EntriesInPlantFiltersDTO {
  warehouses: string[];
  last_positions: string[];
}

export const entriesInPlantFiltersDTOMapper = (
  data: any
): EntriesInPlantFiltersDTO => ({
  warehouses: data.warehouses || [],
  last_positions: data.last_positions || []
});
