import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';
import {
  CountryRoutesLinkDTO,
  CountryRoutesWaypointDTO
} from 'modules/outbound/fulfillment/infrastructure/dto/CountryRoutesDTO';
import countriesService from 'modules/shared/services/CountriesService';

interface GenerateFromDTOParams {
  waypoints: CountryRoutesWaypointDTO[];
  links: CountryRoutesLinkDTO[];
}

export interface CountryRoutesWaypoint {
  id: string;
  name: string;
  type: CheckpointType;
  totalCars: number;
  order: number;
}

export interface CountryRoutesLink {
  sourceWaypointId: string;
  destinationWaypointId: string;
  totalCars: number;
}

export interface ICountryRoutes {
  countryCode: string;
  countryName: string;
  waypoints: CountryRoutesWaypoint[];
  links: CountryRoutesLink[];
}

class CountryRoutes implements ICountryRoutes {
  countryCode: string;
  countryName: string;
  waypoints: CountryRoutesWaypoint[];
  links: CountryRoutesLink[];

  constructor({ countryCode, countryName, waypoints, links }: CountryRoutes) {
    this.countryCode = countryCode;
    this.countryName = countryName;
    this.waypoints = waypoints;
    this.links = links;
  }

  static generateFromDTO({
    dto: { waypoints, links },
    countryCode
  }: {
    dto: GenerateFromDTOParams;
    countryCode: string;
  }): CountryRoutes {
    return new CountryRoutes({
      countryCode,
      countryName: countriesService.getCountryNameByISOCode(countryCode),
      waypoints: waypoints.map(
        ({ id, movementType, name, type, totalCars, order }) => ({
          id: this._generateIdWithMovementType({ id, movementType }),
          name: movementType === 'out' ? `${name} (AUS)` : name,
          type,
          totalCars,
          order
        })
      ),
      links: links.map(
        ({
          sourceWaypointId,
          sourceWaypointMovementType,
          destinationWaypointId,
          destinationWaypointMovementType,
          totalCars
        }) => ({
          sourceWaypointId: this._generateIdWithMovementType({
            id: sourceWaypointId,
            movementType: sourceWaypointMovementType
          }),
          destinationWaypointId: this._generateIdWithMovementType({
            id: destinationWaypointId,
            movementType: destinationWaypointMovementType
          }),
          totalCars
        })
      )
    });
  }

  static generateEmpty() {
    return new CountryRoutes({
      countryCode: '',
      countryName: '',
      waypoints: [],
      links: []
    });
  }

  private static _generateIdWithMovementType({ id, movementType }) {
    return `${id}-${movementType}`;
  }
}

export default CountryRoutes;
