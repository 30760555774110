class DeliveriesGeolocationDTO {
  countryCode: string;
  cumulativeDelay: number;
  globalDelayed: number;
  total: number;

  constructor({ countryCode, cumulativeDelay, globalDelayed, total }) {
    this.countryCode = countryCode;
    this.cumulativeDelay = cumulativeDelay;
    this.globalDelayed = globalDelayed;
    this.total = total;
  }
}

export default DeliveriesGeolocationDTO;
