import { createSlice } from '@reduxjs/toolkit';
import { SuppliesDataState } from './types';
import {
  getSuppliesData,
  getRequestsStatus,
  getClosedStatus,
  getCriticalStockSummary,
  getWarehousesTarget,
  downloadSupplies,
  getWarehousesRanking,
  getCriticalPieces,
  getSubzonesRanking
} from './async';
import localeService from 'infrastructure/i18n/LocaleService';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { CriticalStockColor, CriticalTypes } from 'modules/shared/domain/enums';
import { SuppliesText } from 'modules/inbound/supplies/infrastructure/i18n/SupplyText';
import { SuppliesTranslations } from 'modules/inbound/supplies/infrastructure/i18n/typing';
import { BarStatus } from '../../layout/pages/types';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { WAREHOUSE_RANKING_EMPTY } from 'modules/inbound/supplies/domain/warehouseRankingMapper';
import SuppliesStatus from 'modules/inbound/supplies/domain/SuppliesStatus';

const suppliesTranslations: SuppliesTranslations = {
  ...SuppliesText[localeService.getLanguage()]
};

export const initialState: SuppliesDataState = {
  data: {
    lastUpdate: {
      suppliesWithoutStockLastUpdate: '-',
      suppliesWithStockLastUpdate: '-'
    },
    transportOrders: [],
    columns: [],
    savedColumns: [],
    requestsStatusChart: SuppliesStatus.generateEmpty(),
    closedStatusChart: SuppliesStatus.generateEmpty(),
    warehousesTarget: WAREHOUSE_RANKING_EMPTY,
    warehousesRanking: WAREHOUSE_RANKING_EMPTY,
    subzonesRanking: WAREHOUSE_RANKING_EMPTY
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  requestStatusChartStatus: '',
  requestClosedChartStatus: '',
  requestStatusCriticalStock: '',
  requestSubzonesRanking: '',
  requestWarehousesTarget: '',
  requestWarehousesRanking: '',
  downloadRequestStatus: '',
  suppliesFile: null,
  selectedColumn: {
    key: '',
    isAscending: false
  },
  criticalStockSummary: { stats: [], total: 0 },
  criticalPieces: [],
  requestCriticalPiecesStatus: ''
};

const SUPPLIES_COLUMNS_VIEW_STATE_KEY = 'SuppliesColumnsView';

const suppliesDataSlice = createSlice({
  name: 'suppliesData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${SUPPLIES_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.data.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.data.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: SUPPLIES_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: state.data.columns
      });
    },
    updateVisibleColumns: (state, action) => {
      state.data.savedColumns = state.data.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsOrder: (state, action) => {
      state.data.savedColumns = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSuppliesData.fulfilled, (state, action) => {
      state.data.transportOrders = action.payload.transportOrders;
      state.data.columns = action.payload.columns || [];
      state.data.lastUpdate = action.payload.lastUpdate;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuppliesData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuppliesData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRequestsStatus.fulfilled, (state, action) => {
      state.data.requestsStatusChart = action.payload;
      state.requestStatusChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getRequestsStatus.pending, (state, action) => {
      state.requestStatusChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getRequestsStatus.rejected, (state, action) => {
      state.requestStatusChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getClosedStatus.fulfilled, (state, action) => {
      state.data.closedStatusChart = action.payload;
      state.requestClosedChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getClosedStatus.pending, (state, action) => {
      state.requestClosedChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getClosedStatus.rejected, (state, action) => {
      state.requestClosedChartStatus = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStockSummary.fulfilled, (state, action) => {
      state.criticalStockSummary.stats = action.payload.criticalStock.stats;
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStockSummary.pending, (state, action) => {
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStockSummary.rejected, (state, action) => {
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });

    builder.addCase(getWarehousesTarget.fulfilled, (state, action) => {
      state.data.warehousesTarget = action.payload;
      state.requestWarehousesTarget = action.meta.requestStatus;
    });
    builder.addCase(getWarehousesTarget.pending, (state, action) => {
      state.requestWarehousesTarget = action.meta.requestStatus;
    });
    builder.addCase(getWarehousesTarget.rejected, (state, action) => {
      state.requestWarehousesTarget = action.meta.requestStatus;
    });
    builder.addCase(downloadSupplies.fulfilled, (state, action) => {
      state.suppliesFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_Supplies_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadSupplies.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadSupplies.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehousesRanking.fulfilled, (state, action) => {
      state.data.warehousesRanking = action.payload;
      state.requestWarehousesRanking = action.meta.requestStatus;
    });
    builder.addCase(getWarehousesRanking.pending, (state, action) => {
      state.requestWarehousesRanking = action.meta.requestStatus;
    });
    builder.addCase(getWarehousesRanking.rejected, (state, action) => {
      state.requestWarehousesRanking = action.meta.requestStatus;
    });
    builder.addCase(getSubzonesRanking.fulfilled, (state, action) => {
      state.data.subzonesRanking = action.payload;
      state.requestSubzonesRanking = action.meta.requestStatus;
    });
    builder.addCase(getSubzonesRanking.pending, (state, action) => {
      state.requestSubzonesRanking = action.meta.requestStatus;
    });
    builder.addCase(getSubzonesRanking.rejected, (state, action) => {
      state.requestSubzonesRanking = action.meta.requestStatus;
    });
    builder.addCase(getCriticalPieces.fulfilled, (state, action) => {
      state.criticalPieces = action.payload.criticalSupplies;
      state.requestCriticalPiecesStatus = action.meta.requestStatus;
    });
    builder.addCase(getCriticalPieces.pending, (state, action) => {
      state.requestCriticalPiecesStatus = action.meta.requestStatus;
    });
    builder.addCase(getCriticalPieces.rejected, (state, action) => {
      state.requestCriticalPiecesStatus = action.meta.requestStatus;
    });
  }
});

export const getSupplyData = (state) =>
  state.suppliesDataState.data.transportOrders;
export const getColumns = (state) => state.suppliesDataState.data.savedColumns;
export const getLastUpdate = (state) => state.suppliesDataState.data.lastUpdate;
export const getSuppliesTotalCount = (state) =>
  state.suppliesDataState.pagination.totalCount;

/** Request made chart */
export const getTotalOverviewRequestStatus = (state) =>
  state.suppliesDataState.data.requestsStatusChart.total;
export const getRequestsStatusDataPreviousDays = (state) => {
  const requestStatusChartPreviousDays =
    state.suppliesDataState.data.requestsStatusChart.previousDays;
  return [
    {
      fromDateTime: requestStatusChartPreviousDays.fromDateTime,
      toDateTime: requestStatusChartPreviousDays.toDateTime,
      searchModality: requestStatusChartPreviousDays.searchModality,
      xAxisLabels: {
        top: requestStatusChartPreviousDays.generatedAt,
        bottom: requestStatusChartPreviousDays.total
      },
      stackedBars: requestStatusChartPreviousDays.stats.map((statusItem) => {
        return {
          number: statusItem.counter,
          color: BarStatus[statusItem.status]
        };
      })
    }
  ];
};
export const getRequestsStatusDataHours = (state) => {
  const requestStatusChartHours =
    state.suppliesDataState.data.requestsStatusChart.hours;
  return requestStatusChartHours.map((item, index) => {
    return {
      fromDateTime: item.fromDateTime,
      toDateTime: item.toDateTime,
      searchModality: item.searchModality,
      xAxisLabels: {
        tooltipInfo:
          index === 1
            ? `${GetDateFormatedService.getShortHourLocale(
                item.fromDateTime
              )} - ${GetDateFormatedService.getShortHourLocale(
                item.toDateTime
              )}`
            : null,
        top: item.generatedAt,
        bottom: item.total
      },
      stackedBars: item.stats.map((statusItem) => {
        return {
          number: statusItem.counter,
          color: BarStatus[statusItem.status]
        };
      })
    };
  });
};

/** Closed request chart */
export const getClosedStatusDataPreviousDays = (state) => {
  const requestClosedChartPreviousDays =
    state.suppliesDataState.data.closedStatusChart.previousDays;
  return [
    {
      fromDateTime: requestClosedChartPreviousDays.fromDateTime,
      toDateTime: requestClosedChartPreviousDays.toDateTime,
      searchModality: requestClosedChartPreviousDays.searchModality,
      xAxisLabels: {
        top: requestClosedChartPreviousDays.generatedAt,
        bottom: requestClosedChartPreviousDays.total
      },
      stackedBars: requestClosedChartPreviousDays.stats.map((statusItem) => {
        return {
          number: statusItem.counter,
          color: BarStatus[statusItem.status]
        };
      })
    }
  ];
};
export const getClosedStatusDataHours = (state) => {
  const requestClosedChartHours =
    state.suppliesDataState.data.closedStatusChart.hours;
  return requestClosedChartHours.map((item, index) => {
    return {
      fromDateTime: item.fromDateTime,
      toDateTime: item.toDateTime,
      searchModality: item.searchModality,
      xAxisLabels: {
        tooltipInfo:
          index === 1
            ? `${GetDateFormatedService.getShortHourLocale(
                item.fromDateTime
              )} - ${GetDateFormatedService.getShortHourLocale(
                item.toDateTime
              )}`
            : null,
        top: item.generatedAt,
        bottom: item.total
      },
      stackedBars: item.stats.map((statusItem) => {
        return {
          number: statusItem.counter,
          color: BarStatus[statusItem.status]
        };
      })
    };
  });
};

export const getStatistics = (state) => {
  return (
    state?.map((item) => {
      return {
        xAxisLabels: {
          left: item.name,
          right: item.total
        },
        stackedBars: item.stats.map((item) => {
          return {
            number: item.counter,
            color: BarStatus[item.status]
          };
        })
      };
    }) ?? []
  );
};

/**  Warehouses Destination ranking chart*/
export const getTotalWarehousesStatistics = (state) => {
  const totalWarehouses = [
    state.suppliesDataState.data.warehousesTarget.totalStatistics
  ];
  return getStatistics(totalWarehouses);
};

export const getWarehouseDestinationRankingStatistics = (state) => {
  const warehousesTargetStatistics =
    state.suppliesDataState.data.warehousesTarget.statistics;

  return getStatistics(warehousesTargetStatistics);
};

export const getTotalWarehousesRequests = (state) =>
  state.suppliesDataState.data.warehousesTarget.totalStatistics.total;

export const getWarehousesDestinationStatisticsTotal = (state) => {
  const warehouseStatistics =
    state.suppliesDataState.data.warehousesTarget.statistics;

  return (
    Math.ceil(Math.max(...warehouseStatistics.map(({ total }) => total)) / 4) *
    4
  );
};

/**  Warehouses Origin ranking chart*/
export const getTotalWarehousesOriginStatistics = (state) => {
  const totalWarehouses = [
    state.suppliesDataState.data.warehousesRanking.totalStatistics
  ];
  return getStatistics(totalWarehouses);
};

export const getWarehouseOriginRankingStatistics = (state) => {
  const warehousesTargetStatistics =
    state.suppliesDataState.data.warehousesRanking.statistics;

  return getStatistics(warehousesTargetStatistics);
};

export const getTotalWarehousesOriginRequests = (state) =>
  state.suppliesDataState.data.warehousesRanking.totalStatistics.total;

export const getWarehousesOriginStatisticsTotal = (state) => {
  const warehouseStatistics =
    state.suppliesDataState.data.warehousesRanking.statistics;

  return (
    Math.ceil(Math.max(...warehouseStatistics.map(({ total }) => total)) / 4) *
    4
  );
};

/**  Warehouses Origin ranking chart*/

export const getSubzonesRankingStatistics = (state) => {
  const warehousesTargetStatistics =
    state.suppliesDataState.data.subzonesRanking.statistics;

  return getStatistics(warehousesTargetStatistics);
};

export const getSubzonesStatisticsTotal = (state) => {
  const warehouseStatistics =
    state.suppliesDataState.data.subzonesRanking.statistics;

  return (
    Math.ceil(Math.max(...warehouseStatistics.map(({ total }) => total)) / 4) *
    4
  );
};

/** Status Selectors */
export const getisLoadingStatus = (state) =>
  state.suppliesDataState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.suppliesDataState.requestStatus === 'rejected';
export const getisLoadingRequestsStatus = (state) =>
  state.suppliesDataState.requestStatusChartStatus === 'pending';
export const getHasErrorRequestsStatus = (state) =>
  state.suppliesDataState.requestStatusChartStatus === 'rejected';
export const getisLoadingClosedStatus = (state) =>
  state.suppliesDataState.requestClosedChartStatus === 'pending';
export const getHasErrorClosedStatus = (state) =>
  state.suppliesDataState.requestClosedChartStatus === 'rejected';
export const getisLoadingCriticalStock = (state) =>
  state.suppliesDataState.requestStatusCriticalStock === 'pending';
export const getHasErrorCriticalStock = (state) =>
  state.suppliesDataState.requestStatusCriticalStock === 'rejected';
export const getisLoadingrequestWarehouses = (state) =>
  state.suppliesDataState.requestWarehouses === 'pending';
export const getHasErrorrequestWarehouses = (state) =>
  state.suppliesDataState.requestWarehouses === 'rejected';
export const getisLoadingWarehousesRanking = (state) =>
  state.suppliesDataState.requestWarehousesRanking === 'pending';
export const getHasErrorWarehousesRanking = (state) =>
  state.suppliesDataState.requestWarehousesRanking === 'rejected';
export const getIsLoadingSubzonesRanking = (state) =>
  state.suppliesDataState.requestSubzonesRanking === 'pending';
export const getHasErrorSubzonesRanking = (state) =>
  state.suppliesDataState.requestSubzonesRanking === 'rejected';

export const getCurrentPage = (state) =>
  state.suppliesDataState.pagination.page;
export const getResultsPerPage = (state) =>
  state.suppliesDataState.pagination.size;
export const getTotalPages = (state) =>
  state.suppliesDataState.pagination.pageCount;
export const getSelectedColumn = (state) =>
  state.suppliesDataState.selectedColumn;

export const getCriticalStockSummaryData = (state) => {
  const summary = state.suppliesDataState.criticalStockSummary.stats;
  let formatData: any[] = [];
  summary?.forEach((item) => {
    formatData.push({
      type: item.status,
      label: suppliesTranslations[CriticalTypes[item.status]],
      value: item.counter,
      color: CriticalStockColor[item.status]
    });
  });
  return formatData;
};

export const getDownloadSuppliesSuccessful = (state) =>
  state.suppliesDataState.downloadRequestStatus === 'fulfilled' &&
  state.suppliesDataState.suppliesFile !== null;
export const getDownloadSuppliesError = (state) =>
  state.suppliesDataState.downloadRequestStatus === 'rejected';
export const getDownloadSuppliesIsPending = (state) =>
  state.suppliesDataState.downloadRequestStatus === 'pending';

export const getRequestStatusChartScale = (state) => {
  const requestStatusChartPreviousDays =
    state.suppliesDataState.data.requestsStatusChart.previousDays;
  const requestStatusChartHours =
    state.suppliesDataState.data.requestsStatusChart.hours;
  const requestStatusChartMaxValuePreviousDays = requestStatusChartPreviousDays
    ? Math.ceil(Math.max(requestStatusChartPreviousDays.total) / 4) * 4
    : 0;
  const requestStatusChartMaxValueHours = requestStatusChartHours
    ? Math.ceil(
        Math.max(...requestStatusChartHours.map((hour) => hour.total)) / 4
      ) * 4
    : 0;

  return {
    RequestChartMaxValuePreviousDays: requestStatusChartMaxValuePreviousDays,
    RequestChartMaxValueHours: requestStatusChartMaxValueHours,
    RequestChartScalePreviousDays: Array(...Array(5)).map(
      (_, i) => (i * requestStatusChartMaxValuePreviousDays) / 4
    ),
    RequestChartScaleHours: Array(...Array(5)).map(
      (_, i) => (i * requestStatusChartMaxValueHours) / 4
    )
  };
};

export const getClosedStatusChartScale = (state) => {
  const closedStatusChartPreviousDays =
    state.suppliesDataState.data.closedStatusChart.previousDays;
  const closedStatusChartHours =
    state.suppliesDataState.data.closedStatusChart.hours;
  const closedStatusChartMaxValuePreviousDays = closedStatusChartPreviousDays
    ? Math.ceil(Math.max(closedStatusChartPreviousDays.total) / 4) * 4
    : 0;
  const closedStatusChartMaxValueHours = closedStatusChartHours
    ? Math.ceil(
        Math.max(...closedStatusChartHours.map((hour) => hour.total)) / 4
      ) * 4
    : 0;
  return {
    ClosedChartMaxValuePreviousDays: closedStatusChartMaxValuePreviousDays,
    ClosedChartMaxValueHours: closedStatusChartMaxValueHours,
    ClosedChartScalePreviousDays: Array(...Array(5)).map(
      (_, i) => (i * closedStatusChartMaxValuePreviousDays) / 4
    ),
    ClosedChartScaleHours: Array(...Array(5)).map(
      (_, i) => (i * closedStatusChartMaxValueHours) / 4
    )
  };
};

export const getSuppliesCriticalPieces = (state) =>
  state.suppliesDataState.criticalPieces;
export const getIsRejectedRequestCriticalPieces = (state) =>
  state.suppliesDataState.requestCriticalPiecesStatus === 'rejected';
export const getIsPendingRequestCriticalPieces = (state) =>
  state.suppliesDataState.requestCriticalPiecesStatus === 'pending';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  resetDownloadStatus,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  updateColumnsOrder
} = suppliesDataSlice.actions;

export default suppliesDataSlice.reducer;
