import CoverageAtRisk from '../../../shared/domain/CoverageAtRisk';
import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import DirectDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class GetCoverageAtRiskService {
  private _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ directDeliveryRepository }) {
    this._directDeliveryRepository = directDeliveryRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._directDeliveryRepository
      .getCoverageAtRisk({ queryParams })
      .then((res) => CoverageAtRisk.generateFromDTO(res));
  }
}

export default new GetCoverageAtRiskService({
  directDeliveryRepository: DirectDeliveryRepository
});

export { GetCoverageAtRiskService };
