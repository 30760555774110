import { createSlice } from '@reduxjs/toolkit';
import { SummaryCriticalDetails } from 'modules/inbound/shared/domain/SummaryCriticalStock';
import IncomingTransfers from 'modules/inbound/transfer/domain/IncomingTransfers';
import {
  getCriticalStock,
  getIncomingMaterialsCriticalStock,
  getIncomingTransfersNextDays,
  getIncomingTransfersNextHours,
  getTransferStock,
  getTransferStockNextHours
} from './async';
import {
  StatusColor,
  StatusType,
  TransferState,
  TransferStatus
} from './types';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';

export const initialState: TransferState = {
  lastUpdate: '',
  transferStock: [],
  columns: [],
  savedColumns: [],
  transferStockRequestStatus: '',
  incomingTransfersNextDays: IncomingTransfers.generateEmpty(),
  incomingTransfersNextHours: IncomingTransfers.generateEmpty(),
  incomingTransfersNextDaysRequestStatus: '',
  incomingTransfersNextHoursRequestStatus: '',
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: '',
    isAscending: false
  },
  criticalStock: { stats: [], total: 0 },
  incomingMaterialsCriticalStock: { stats: [], total: 0 },
  requestStatusCriticalStock: '',
  requestStatusIncomingMaterialsCriticalStock: '',
  filters: {
    search: '',
    selectedCriticalStockType: '',
    selectedNextHoursArrivals: {
      date: '',
      top: '',
      status: null
    },
    selectedSubHeaderFilters: {
      selectedBasicContainers: [],
      selectedSupplyContainers: [],
      selectedWarehouseTypes: [],
      selectedWarehouses: [],
      selectedPlanningType: [],
      selectedSupplyTypes: []
    }
  },
  isFilterSaved: false,
  isTableStockSelected: true
};

const FILTERS_TRANSFERS_KEY = 'FiltersTransfers';
const FILTERS_TRANSFERS_NEXT_HOURS_KEY = 'FiltersTransfersNextHours';
const TRANSFERS_COLUMNS_VIEW_STATE_KEY = 'TransfersColumnsView';
const TRANSFERS_NEXT_8_HOURS_COLUMNS_VIEW_STATE_KEY =
  'TransfersNext8HoursColumnsView';

const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateFilterSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    updateIsMaterialInTransferPageSelected: (state, action) => {
      state.columns = [];
      state.pagination = initialState.pagination;
      if (action.payload !== state.isTableStockSelected) {
        state.filters.selectedSubHeaderFilters =
          initialState.filters.selectedSubHeaderFilters;
        state.isTableStockSelected = action.payload;
      }
      if (!action.payload) {
        state.filters.selectedCriticalStockType =
          initialState.filters.selectedCriticalStockType;
      }
      if (action.payload) {
        state.filters.selectedNextHoursArrivals =
          initialState.filters.selectedNextHoursArrivals;
      }
    },
    updateSelectedCriticalStockType: (state, action) => {
      state.filters.selectedCriticalStockType = action.payload;
      state.pagination.page = 1;
    },
    updateSelectedBasicContainers: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedBasicContainers =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedSupplyContainers: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedSupplyContainers =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedWarehouseTypes: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedWarehouseTypes =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedWarehouses: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedWarehouses =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedPlanningType: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedPlanningType =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedSupplyTypes: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedSupplyTypes =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedNextHoursTransfer: (state, action) => {
      state.filters.selectedNextHoursArrivals = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state, action) => {
      state.filters.selectedSubHeaderFilters =
        initialState.filters.selectedSubHeaderFilters;
      state.isTableStockSelected
        ? localStorage.removeItem(`${action.payload}-${FILTERS_TRANSFERS_KEY}`)
        : localStorage.removeItem(
            `${action.payload}-${FILTERS_TRANSFERS_NEXT_HOURS_KEY}`
          );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      state.isTableStockSelected
        ? localStorage.setItem(
            `${action.payload}-${FILTERS_TRANSFERS_KEY}`,
            JSON.stringify(state.filters.selectedSubHeaderFilters)
          )
        : localStorage.setItem(
            `${action.payload}-${FILTERS_TRANSFERS_NEXT_HOURS_KEY}`,
            JSON.stringify(state.filters.selectedSubHeaderFilters)
          );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = state.isTableStockSelected
        ? localStorage.getItem(`${action.payload}-${FILTERS_TRANSFERS_KEY}`)
        : localStorage.getItem(
            `${action.payload}-${FILTERS_TRANSFERS_NEXT_HOURS_KEY}`
          );

      state.filters.selectedSubHeaderFilters = filters
        ? JSON.parse(filters)
        : initialState.filters.selectedSubHeaderFilters;

      state.isFilterSaved = !!filters;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        state.isTableStockSelected
          ? `${action.payload}-${TRANSFERS_COLUMNS_VIEW_STATE_KEY}`
          : `${action.payload}-${TRANSFERS_NEXT_8_HOURS_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      const selectedColumnKey = state.isTableStockSelected
        ? TRANSFERS_COLUMNS_VIEW_STATE_KEY
        : TRANSFERS_NEXT_8_HOURS_COLUMNS_VIEW_STATE_KEY;

      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: selectedColumnKey,
        defaultColumns: state.columns
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsOrder: (state, action) => {
      state.savedColumns = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getTransferStock.fulfilled, (state, action) => {
      state.lastUpdate = action.payload.lastUpdate;
      state.transferStock = action.payload.stockLocationItems || [];
      state.columns = action.payload.columns || [];
      state.transferStockRequestStatus = action.meta.requestStatus;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getTransferStock.pending, (state, action) => {
      state.transferStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransferStock.rejected, (state, action) => {
      state.transferStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransferStockNextHours.fulfilled, (state, action) => {
      state.lastUpdate = initialState.lastUpdate;
      state.transferStock = action.payload.incomingMaterials || [];
      state.columns = action.payload.columns || [];
      state.transferStockRequestStatus = action.meta.requestStatus;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getTransferStockNextHours.pending, (state, action) => {
      state.transferStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransferStockNextHours.rejected, (state, action) => {
      state.transferStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingTransfersNextDays.fulfilled, (state, action) => {
      state.incomingTransfersNextDays = action.payload || [];
      state.incomingTransfersNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingTransfersNextDays.pending, (state, action) => {
      state.incomingTransfersNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingTransfersNextDays.rejected, (state, action) => {
      state.incomingTransfersNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getIncomingTransfersNextHours.fulfilled,
      (state, action) => {
        state.incomingTransfersNextHours = action.payload || [];
        state.incomingTransfersNextHoursRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getIncomingTransfersNextHours.pending, (state, action) => {
      state.incomingTransfersNextHoursRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingTransfersNextHours.rejected, (state, action) => {
      state.incomingTransfersNextHoursRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStock.fulfilled, (state, action) => {
      state.criticalStock.stats = action.payload.criticalStock.stats;
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStock.pending, (state, action) => {
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });
    builder.addCase(getCriticalStock.rejected, (state, action) => {
      state.requestStatusCriticalStock = action.meta.requestStatus;
    });
    builder.addCase(
      getIncomingMaterialsCriticalStock.fulfilled,
      (state, action) => {
        state.incomingMaterialsCriticalStock.stats =
          action.payload.criticalStock.stats;
        state.requestStatusIncomingMaterialsCriticalStock =
          action.meta.requestStatus;
      }
    );
    builder.addCase(
      getIncomingMaterialsCriticalStock.pending,
      (state, action) => {
        state.requestStatusIncomingMaterialsCriticalStock =
          action.meta.requestStatus;
      }
    );
    builder.addCase(
      getIncomingMaterialsCriticalStock.rejected,
      (state, action) => {
        state.requestStatusIncomingMaterialsCriticalStock =
          action.meta.requestStatus;
      }
    );
  }
});

//selectors
export const getLastUpdate = (state) => state.transferState.lastUpdate;
export const getStock = (state) => state.transferState.transferStock;
export const getIsTableStockSelected = (state) =>
  state.transferState.isTableStockSelected;
export const getColumns = (state) => state.transferState.savedColumns;
export const getCurrentPage = (state) => state.transferState.pagination.page;
export const getResultsPerPage = (state) => state.transferState.pagination.size;
export const getTotalPages = (state) =>
  state.transferState.pagination.pageCount;
export const getTotalCount = (state) =>
  state.transferState.pagination.totalCount;
export const getFilterSearch = (state) => state.transferState.filters.search;

export const getStockIsPending = (state) =>
  state.transferState.transferStockRequestStatus === 'pending';

export const getStockHasError = (state) =>
  state.transferState.transferStockRequestStatus === 'rejected';
export const getSelectedColumn = (state) => state.transferState.selectedColumn;

export const getIncomingTransfersForNextDaysHasError = (state) =>
  state.transferState.incomingTransfersNextDaysRequestStatus === 'rejected';
export const getIncomingTransfersForNextHoursHasError = (state) =>
  state.transferState.incomingTransfersNextHoursRequestStatus === 'rejected';
export const getHasFiltersSelected = (state) =>
  Object.keys(state.transferState.filters.selectedSubHeaderFilters).some(
    (key) => state.transferState.filters.selectedSubHeaderFilters[key].length
  );

export const getIncomingTransfersForNextsDaysFormated = (state) =>
  state.transferState.incomingTransfersNextDays.incomingTransfersItems.map(
    ({ dateFormated, plannedTransfers, unplannedTransfers }) => ({
      stackedBars: [
        { color: StatusColor[StatusType.PLANNED], number: plannedTransfers },
        {
          color: StatusColor[StatusType.NOT_PLANNED],
          number: unplannedTransfers
        }
      ],
      xAxisLabels: {
        top: dateFormated,
        bottom: (plannedTransfers + unplannedTransfers).toString()
      }
    })
  ) || [];

export const getIncomingTransfersForNextsHoursFormated = (state) =>
  state.transferState.incomingTransfersNextHours.incomingTransfersItems.map(
    ({ date, hours, plannedTransfers, unplannedTransfers }) => ({
      stackedBars: [
        { color: StatusColor[StatusType.PLANNED], number: plannedTransfers },
        {
          color: StatusColor[StatusType.NOT_PLANNED],
          number: unplannedTransfers
        }
      ],
      xAxisLabels: {
        top: hours,
        bottom: (plannedTransfers + unplannedTransfers).toString(),
        date
      }
    })
  ) || [];

export const getIncomingTransfersForNextsHoursMaxValueLegend = (state) => {
  const maxValue = Math.max(
    ...state.transferState.incomingTransfersNextHours.incomingTransfersItems.map(
      ({ plannedTransfers, unplannedTransfers }) =>
        plannedTransfers + unplannedTransfers
    )
  );
  return Math.ceil(maxValue / 4) * 4;
};

export const getIncomingTransfersForNextsDaysMaxValueLegend = (state) => {
  const maxValue = Math.max(
    ...state.transferState.incomingTransfersNextDays.incomingTransfersItems.map(
      ({ plannedTransfers, unplannedTransfers }) =>
        plannedTransfers + unplannedTransfers
    )
  );
  return Math.ceil(maxValue / 4) * 4;
};

export const getIncomingTransfersForNextsHoursLegend = (state) => {
  return Array(...Array(5)).map(
    (_, i) => (i * getIncomingTransfersForNextsHoursMaxValueLegend(state)) / 4
  );
};

export const getIncomingTransfersForNextsDaysLegend = (state) => {
  return Array(...Array(5)).map(
    (_, i) => (i * getIncomingTransfersForNextsDaysMaxValueLegend(state)) / 4
  );
};

export const getIncomingTransfersForNextsDaysIsPending = (state) =>
  state.transferState.incomingTransfersNextDaysRequestStatus === 'pending';

export const getIncomingTransfersForNextsHoursIsPending = (state) =>
  state.transferState.incomingTransfersNextHoursRequestStatus === 'pending';

export const getisLoadingCriticalStock = (state) =>
  state.transferState.requestStatusCriticalStock === 'pending';
export const getHasErrorCriticalStock = (state) =>
  state.transferState.requestStatusCriticalStock === 'rejected';

export const getIsChartFilterActive = (state) =>
  state.transferState.filtersActive;

export const getCriticalStockData = (state) => {
  const summary: SummaryCriticalDetails[] =
    state.transferState.criticalStock.stats;

  const incomingMaterialsSummary: SummaryCriticalDetails[] =
    state.transferState.incomingMaterialsCriticalStock.stats;

  const transferSummary = summary.find((item) => item.status === 'TRANSFER');
  const inTransitAlreadyTransferredSummary = incomingMaterialsSummary.find(
    (item) => item.status === 'IN_TRANSIT_ALREADY_TRANSFERRED'
  );

  return {
    transfer: {
      status: 'TRANSFER',
      counter: transferSummary?.counter ?? 0
    },
    inTransitAlreadyTransferred: {
      status: 'IN_TRANSIT_ALREADY_TRANSFERRED',
      counter: inTransitAlreadyTransferredSummary?.counter ?? 0
    }
  };
};

export const getSelectedCriticalStockType = (state) =>
  state.transferState.filters.selectedCriticalStockType;
export const getSelectedFilters = (state) =>
  state.transferState.filters.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) => state.transferState.isFilterSaved;
export const getSelectedNextHoursTransfersUI = (state) => {
  const selectedArrival = state.transferState.filters.selectedNextHoursArrivals;
  return { ...selectedArrival, color: selectedArrival.status };
};
export const getSelectedNextHoursTransfers = (state) => {
  const selectedNextHours =
    state.transferState.filters.selectedNextHoursArrivals;

  if (selectedNextHours.date.length) {
    let fromDate = new Date(selectedNextHours.date);

    fromDate.setHours(fromDate.getHours() + 1);

    return {
      to: fromDate.toISOString(),
      from: selectedNextHours.date,
      status: TransferStatus[selectedNextHours.status]
    };
  }
  return selectedNextHours;
};

export const {
  updateCurrentPage,
  updateFilterSearch,
  updateSelectedColumn,
  updateIsMaterialInTransferPageSelected,
  updateSelectedCriticalStockType,
  updateSelectedBasicContainers,
  updateSelectedSupplyContainers,
  updateSelectedWarehouseTypes,
  updateSelectedWarehouses,
  updateSelectedPlanningType,
  updateSelectedSupplyTypes,
  updateSelectedNextHoursTransfer,
  resetFilters,
  saveFilters,
  loadFilters,
  updateVisibleColumns,
  updateColumnsOrder,
  saveColumnsView,
  loadColumnsView
} = transferSlice.actions;
export default transferSlice.reducer;
