import styled, { createGlobalStyle } from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';
import { SIDEBAR_DESKTOP_WIDTH } from 'modules/shared/layout/containers/Sidebar/styles/Wrapper';
import { TABLET_BREAKPOINT } from 'modules/shared/hooks/useBreakpoints';

const GlobalAppStyles = createGlobalStyle<{
  theme: ThemeSEATProps;
}>`
    body {
      overflow-x: hidden;
      min-width: 100%;
      min-height: 100%;
      position: relative;
      padding: 0;
      margin: 0;
      background-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.black.rgb};
    }
  
  * {
    font-family: 'SeatBcn-Regular', 'SeatBcn-Medium','Arial';

    /* firefox */
    scrollbar-width: thin;
    scrollbar-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.darkGray.rgb} transparent;
    /* chrome, safari, edge */
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.darkerGray.rgb};
      border-radius: 16px;
    }
  }
`;

const AppWrapper = styled.main`
  display: flex;
  align-items: flex-start;
`;

const AppContent = styled.section`
  flex: 1;
  max-width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    padding-left: calc(${SIDEBAR_DESKTOP_WIDTH} + 1.5rem);
  }

  & > * {
    margin-bottom: 1rem;
  }

  .react-tooltip {
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.neutral50.rgb};
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.black.rgb};
    font-size: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.fonts.regularExtraSmall.minSizeAsPixels};
    box-shadow: 0px 9px 30px 0px #000;
    max-width: 400px;
    z-index: 50;

    ul {
      padding-left: 1.5rem;
    }

    b {
      font-family: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.fontFamily.bold};
    }
  }

  .comment-tooltip {
    overflow-wrap: break-word;
  }

  .outbound {
    b {
      color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.orange.hexa};
    }
  }
`;

export { AppWrapper, AppContent };
export default GlobalAppStyles;
