import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from '../infrastructure/repositories/DeliveriesGeolocationRepository';

class DownloadVesselVinsUseCase {
  private _repository: ITransitRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._repository.downloadVesselVins!({
      queryParams
    });
  }
}

export default new DownloadVesselVinsUseCase({
  repository: deliveriesGeolocationRepository
});

export { DownloadVesselVinsUseCase };
