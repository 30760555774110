import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { API_GET_FACTORY_DELAY } from './urls';
import IFactoryDelayRepository from '../../domain/IFactoryDelayRepository';
import FactoryDelayDTO from '../dto/FactoryDelayDTO';

class FactoryDelayRepository
  extends BaseRepository
  implements IFactoryDelayRepository
{
  private _api: IApi;
  private _apiGetFactoryDelay: string;

  constructor({ api, apiGetFactoryDelay }) {
    super();
    this._api = api;
    this._apiGetFactoryDelay = apiGetFactoryDelay;
  }

  getFactoryDelay(): Promise<FactoryDelayDTO> {
    return this._api
      .get(this._apiGetFactoryDelay)
      .then((response) => new FactoryDelayDTO(response.factoryDelay));
  }
}

export default new FactoryDelayRepository({
  api: api,
  apiGetFactoryDelay: API_GET_FACTORY_DELAY
});

export { FactoryDelayRepository };
