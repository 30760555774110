import { RefillsSaturationStatus } from './types';

export interface RefillsSaturation {
  status: string;
  percentage: number;
}

interface GenerateFromDTOParams {
  saturation: RefillsSaturation;
}

class RefillsSaturationData {
  saturation: RefillsSaturation;
  constructor({ saturation }) {
    this.saturation = saturation;
  }

  static generateFromDTO({
    saturation
  }: GenerateFromDTOParams): RefillsSaturationData {
    return new RefillsSaturationData({
      saturation: {
        status: RefillsSaturationStatus[saturation.status],
        percentage: saturation.percentage
      }
    });
  }
}

export default RefillsSaturationData;
