const convertToBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result?.toString().split('base64,')[1]);
    reader.onerror = () => reject(new Error('error'));
  });
};

export default convertToBase64;
