import { createAsyncThunk } from '@reduxjs/toolkit';
import getSuppliesFiltersService from 'modules/inbound/supplies/application/GetSuppliesFiltersService';

const getSuppliesFilters = createAsyncThunk(
  `suppliesFilters/getSuppliesFilters`,
  async () => {
    const response = await getSuppliesFiltersService.execute();
    return response;
  }
);

export { getSuppliesFilters };
