import { Country } from 'modules/shared/domain/Country';
import countriesService from 'modules/shared/services/CountriesService';

export interface ITransitFulfillment {
  country: Country;
  percentage: number;
  achivementStatus: AchievementStatus;
  totalCars: number;
  checkpointThreshold: string;
  currentYearTotalCars: number;
  currentYearPercentage: number;
  currentYearAchievmentStatus: AchievementStatus;
  currentYear: string;
}

interface GenerateFromDTOParams {
  country: string;
  delayedCars: number;
  totalCars: number;
  checkpointThreshold: string;
  currentYearTotalCars: number;
  currentYearDelayedCars: number;
}

export const enum AchievementStatus {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
  NO_DATA = 'NO_DATA'
}

export const HIGHT_TRESHOLD = 95;
export const MODERATE_TRESHOLD = 90;

class TransitFulfillment implements ITransitFulfillment {
  country: Country;
  percentage: number;
  achivementStatus: AchievementStatus;
  totalCars: number;
  checkpointThreshold: string;
  currentYearTotalCars: number;
  currentYearPercentage: number;
  currentYearAchievmentStatus: AchievementStatus;
  currentYear: string;

  constructor({
    country,
    percentage,
    achivementStatus,
    totalCars,
    checkpointThreshold,
    currentYearTotalCars,
    currentYearPercentage,
    currentYearAchievmentStatus,
    currentYear
  }: ITransitFulfillment) {
    this.country = country;
    this.percentage = percentage;
    this.achivementStatus = achivementStatus;
    this.totalCars = totalCars;
    this.checkpointThreshold = checkpointThreshold;
    this.currentYearTotalCars = currentYearTotalCars;
    this.currentYearPercentage = currentYearPercentage;
    this.currentYearAchievmentStatus = currentYearAchievmentStatus;
    this.currentYear = currentYear;
  }

  private static getImageSrcFromCountryName(countryName: string) {
    return `${process.env.PUBLIC_URL}/images/flags-circle/${countryName}.svg`;
  }

  static generateFromDTO({
    country,
    delayedCars,
    totalCars,
    checkpointThreshold,
    currentYearTotalCars,
    currentYearDelayedCars
  }: GenerateFromDTOParams) {
    const percentage = Math.round((1 - delayedCars / totalCars) * 100);
    const currentYearPercentage = Math.round(
      (1 - currentYearDelayedCars / currentYearTotalCars) * 100
    );

    return new TransitFulfillment({
      country: {
        name: countriesService.getCountryNameByISOCode(country),
        iso: country,
        flag: {
          src: TransitFulfillment.getImageSrcFromCountryName(country),
          alt: country
        },
        key: country.toUpperCase()
      },
      percentage,
      achivementStatus: this.calculateAchievementStatus(percentage),
      totalCars,
      checkpointThreshold,
      currentYear: new Date().getFullYear().toString(),
      currentYearPercentage,
      currentYearAchievmentStatus: this.calculateAchievementStatus(
        currentYearPercentage
      ),
      currentYearTotalCars
    });
  }

  static generateEmpty() {
    return new TransitFulfillment({
      country: {
        name: '',
        iso: '',
        flag: {
          src: '',
          alt: ''
        },
        key: ''
      },
      percentage: 0,
      achivementStatus: AchievementStatus.NO_DATA,
      totalCars: 0,
      checkpointThreshold: '',
      currentYear: '',
      currentYearPercentage: 0,
      currentYearAchievmentStatus: AchievementStatus.NO_DATA,
      currentYearTotalCars: 0
    });
  }

  private static calculateAchievementStatus(
    percentage: number
  ): AchievementStatus {
    if (!percentage) {
      return AchievementStatus.NO_DATA;
    }

    if (percentage >= HIGHT_TRESHOLD) {
      return AchievementStatus.HIGH;
    }

    if (percentage < HIGHT_TRESHOLD && percentage >= MODERATE_TRESHOLD) {
      return AchievementStatus.MODERATE;
    }
    return AchievementStatus.LOW;
  }
}

export default TransitFulfillment;
