import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';
import localeService from 'infrastructure/i18n/LocaleService';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';

export interface ICallOffTrackingByCheckpoint {
  id: string;
  name: string;
  type: CheckpointType;
  delayedCarsWithCallOff: number;
  delayedCarsWithCallOffPercentage: number;
  carsWithCallOff: number;
  carsWithCallOffPercentage: number;
}

interface GenerateFromDTOParams {
  name: string;
  type: CheckpointType;
  delayedFinishedGoodsWithCallOff: number;
  totalFinishedGoodsWithCallOff: number;
  totalFinishedGoods: number;
}

class CallOffTrackingByCheckpoint implements ICallOffTrackingByCheckpoint {
  id: string;
  name: string;
  type: CheckpointType;
  carsWithCallOff: number;
  carsWithCallOffPercentage: number;
  delayedCarsWithCallOff: number;
  delayedCarsWithCallOffPercentage: number;

  constructor({
    id,
    name,
    type,
    carsWithCallOff,
    carsWithCallOffPercentage,
    delayedCarsWithCallOff,
    delayedCarsWithCallOffPercentage
  }: CallOffTrackingByCheckpoint) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.carsWithCallOff = carsWithCallOff;
    this.carsWithCallOffPercentage = carsWithCallOffPercentage;
    this.delayedCarsWithCallOff = delayedCarsWithCallOff;
    this.delayedCarsWithCallOffPercentage = delayedCarsWithCallOffPercentage;
  }
  static generateFromDTO({
    name,
    type,
    delayedFinishedGoodsWithCallOff,
    totalFinishedGoods,
    totalFinishedGoodsWithCallOff
  }: GenerateFromDTOParams): CallOffTrackingByCheckpoint {
    return new CallOffTrackingByCheckpoint({
      id: name,
      name: this._getCheckpointNames(name),
      type,
      carsWithCallOff: totalFinishedGoodsWithCallOff,
      carsWithCallOffPercentage:
        Math.round(
          (totalFinishedGoodsWithCallOff / totalFinishedGoods) * 100
        ) || 0,
      delayedCarsWithCallOff: delayedFinishedGoodsWithCallOff,
      delayedCarsWithCallOffPercentage:
        Math.round(
          (delayedFinishedGoodsWithCallOff / totalFinishedGoodsWithCallOff) *
            100
        ) || 0
    });
  }

  static generateEmpty() {
    return new CallOffTrackingByCheckpoint({
      id: '',
      name: '',
      type: CheckpointType.NONE,
      carsWithCallOff: 0,
      carsWithCallOffPercentage: 0,
      delayedCarsWithCallOff: 0,
      delayedCarsWithCallOffPercentage: 0
    });
  }

  private static _getCheckpointNames(key) {
    const lang = localeService.getLanguage();
    const group = DETAILED_CHECKPOINTS_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.checkpoints[key];
  }
}

export default CallOffTrackingByCheckpoint;
