import {
  IIncomingTransferItemDTO,
  IIncomingTransfersDTO
} from '../infrastructure/dto/IncomingTransfersDTO';

export enum IncomingTransfersTimeRange {
  days,
  hours
}

export interface IIncomingTransfers {
  incomingTransfersItems: IIncomingTransfersItem[];
}

export interface IIncomingTransfersItem {
  date: string;
  dateFormated: string;
  hours: string;
  plannedTransfers: number;
  unplannedTransfers: number;
}

class IncomingTransfers implements IIncomingTransfers {
  incomingTransfersItems: IIncomingTransfersItem[];

  constructor({ incomingTransfersItems }: IncomingTransfers) {
    this.incomingTransfersItems = incomingTransfersItems;
  }

  static generateFromDTO({
    incoming_material_item
  }: IIncomingTransfersDTO): IncomingTransfers {
    return new IncomingTransfers({
      incomingTransfersItems: incoming_material_item.map(
        ({
          date,
          planned_transfers,
          unplanned_transfers
        }: IIncomingTransferItemDTO): IIncomingTransfersItem => ({
          date: date,
          dateFormated: new Intl.DateTimeFormat('es', {
            month: '2-digit',
            day: '2-digit'
          }).format(new Date(date)),
          hours: new Intl.DateTimeFormat('es', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid'
          }).format(new Date(date)),
          plannedTransfers: planned_transfers,
          unplannedTransfers: unplanned_transfers
        })
      )
    });
  }

  static generateEmpty() {
    return new IncomingTransfers({
      incomingTransfersItems: []
    });
  }
}

export default IncomingTransfers;
