import { createSlice } from '@reduxjs/toolkit';
import { BarStatus } from '../DirectDeliveryReducer/types';
import { getDirectDeliveryFilters } from './async';
import { FiltersState, SelectedSubHeaderFilters } from './types';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';
import { StatusTypeEnum } from 'modules/inbound/directDelivery/infrastructure/dto/DirectDeliveryFiltersDTO';

export const initialState: FiltersState = {
  filters: {
    warehouseCodes: [],
    lines: [],
    providerIds: [],
    models: [],
    status: []
  },
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedWarehouses: [],
    selectedLines: [],
    selectedProviderIds: [],
    selectedModels: [],
    selectedStatus: { key: '', text: '' }
  },
  search: '',
  chartFiltersActive: false,
  chartFilters: {
    selectedCoverageAtRisk: {
      status: '',
      name: ''
    }
  },
  isFilterSaved: false
};

const DIRECT_DELIVERY_FILTERS_STATE_KEY = 'DirectDeliveryFiltersState';

const DirectDeliveryFiltersSlice = createSlice({
  name: 'directDeliveryFilters',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehouses = action.payload;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
    },
    updateSelectedProviderIds: (state, action) => {
      state.selectedSubHeaderFilters.selectedProviderIds = action.payload;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
    },
    updateSelectedStatus: (state, action) => {
      state.selectedSubHeaderFilters.selectedStatus = action.payload;
    },

    updateSelectedCoverageAtRisk: (state, action) => {
      state.chartFilters.selectedCoverageAtRisk = action.payload;
      state.chartFiltersActive = !!action.payload;
    },
    resetChartFilters: (state) => {
      state.chartFiltersActive &&
        (state.selectedSubHeaderFilters.selectedModels =
          initialState.selectedSubHeaderFilters.selectedModels);
      state.chartFilters.selectedCoverageAtRisk =
        initialState.chartFilters.selectedCoverageAtRisk;
      state.chartFiltersActive = initialState.chartFiltersActive;
    },
    resetFilters: (state, action) => {
      if (state.chartFiltersActive) {
        state.selectedSubHeaderFilters.selectedWarehouses =
          initialState.selectedSubHeaderFilters.selectedWarehouses;
        state.selectedSubHeaderFilters.selectedLines =
          initialState.selectedSubHeaderFilters.selectedLines;
        state.selectedSubHeaderFilters.selectedProviderIds =
          initialState.selectedSubHeaderFilters.selectedProviderIds;
        state.selectedSubHeaderFilters.selectedStatus =
          initialState.selectedSubHeaderFilters.selectedStatus;
      } else {
        state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      }
      localStorage.removeItem(
        `${action.payload}-${DIRECT_DELIVERY_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${DIRECT_DELIVERY_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const localStorageFilters = getLocalStorage<SelectedSubHeaderFilters>(
        `${action.payload}-${DIRECT_DELIVERY_FILTERS_STATE_KEY}`,
        { ...state.selectedSubHeaderFilters }
      );

      state.selectedSubHeaderFilters = {
        selectedLines: localStorageFilters.selectedLines || [],
        selectedModels: localStorageFilters.selectedModels || [],
        selectedProviderIds: localStorageFilters.selectedProviderIds || [],
        selectedWarehouses: localStorageFilters.selectedWarehouses || [],
        selectedStatus: localStorageFilters.selectedStatus || {
          key: '',
          text: ''
        }
      };
      state.isFilterSaved =
        Object.keys(localStorageFilters).some(
          (key) => localStorageFilters[key]?.length
        ) || !!localStorageFilters.selectedStatus?.key;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectDeliveryFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDirectDeliveryFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDirectDeliveryFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) =>
  state.directDeliveryFiltersState.filters;
export const getFilterSearch = (state) =>
  state.directDeliveryFiltersState.search;
export const getIsChartFilterActive = (state) =>
  state.directDeliveryFiltersState.chartFiltersActive;
export const getSelectedFilters = (state) =>
  state.directDeliveryFiltersState.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.directDeliveryFiltersState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.directDeliveryFiltersState.selectedSubHeaderFilters).some(
    (key) =>
      state.directDeliveryFiltersState.selectedSubHeaderFilters[key]?.length ||
      !!state.directDeliveryFiltersState.selectedSubHeaderFilters.selectedStatus
        ?.key
  );
export const getSelectedCoverageAtRisk = (state) =>
  state.directDeliveryFiltersState.chartFilters;
export const getSelectedCoverageAtRiskUI = (state) => {
  return {
    top: state.directDeliveryFiltersState.chartFilters.selectedCoverageAtRisk
      .name,
    color:
      BarStatus[
        state.directDeliveryFiltersState.chartFilters.selectedCoverageAtRisk
          .status
      ]
  };
};
export const getStatusTypes = (state) =>
  state.directDeliveryFiltersState.selectedSubHeaderFilters.selectedStatus.key
    ? [StatusTypeEnum.NONE, StatusTypeEnum.OK, StatusTypeEnum.NO_OK]
    : state.directDeliveryFiltersState.filters.status;

export const {
  updateFilterSearch,
  updateSelectedWarehouses,
  updateSelectedLines,
  updateSelectedProviderIds,
  updateSelectedModels,
  updateSelectedStatus,
  resetFilters,
  saveFilters,
  loadFilters,
  updateSelectedCoverageAtRisk,
  resetChartFilters
} = DirectDeliveryFiltersSlice.actions;
export default DirectDeliveryFiltersSlice.reducer;
