import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';
import { IDateRange } from '../domain/IEndOfProductionDateRepository';

export interface EndOfProductionFilters {
  [NameValues.PROVIDER_INBOUNDERS]: string[];
  [NameValues.STATUS]: string[];
  [NameValues.WAREHOUSE_CODES]: string[];
  [NameValues.MODELS]: string[];
  [NameValues.END_OF_STOCK_TYPE]?: string | null;
  [NameValues.EXPIRATION_DATE]?: IDateRange;
}

export interface EndOfProductionSearch {
  [key: string]: string[] | string;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

export const eopSearchQueryBuilder = (
  search: EndOfProductionSearch
): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values) && name === NameValues.CODE) {
      queryString += values
        .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
        .join(SearchOperators.OR);

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};

export const eopFiltersQueryBuilder = (
  filters: EndOfProductionFilters
): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (
      (Array.isArray(values) && !isValid(values)) ||
      (name === NameValues.EXPIRATION_DATE && values.fromDate === '') ||
      (name === NameValues.END_OF_STOCK_TYPE && !isValid(values))
    ) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    const multiple = Array.isArray(values) && values.length > 1;
    if (Array.isArray(values) && name === NameValues.MODELS) {
      queryString += `${NameValues.MODELS}${SearchOperators.LK}(${values.join(
        ';'
      )})`;
    }
    if (Array.isArray(values) && name !== NameValues.MODELS) {
      queryString += multiple
        ? `${name}${SearchOperators.IN}(${values.join(';')})`
        : `${name}${SearchOperators.EQUAL}${values[0]}`;
    }
    if (name === NameValues.END_OF_STOCK_TYPE) {
      queryString += `${NameValues.END_OF_STOCK_TYPE}${SearchOperators.EQUAL}${values}`;
    }
    if (name === NameValues.EXPIRATION_DATE) {
      const { fromDate, toDate } = values;
      queryString += `${NameValues.EXPIRATION_DATE}:>=:${fromDate}||${NameValues.EXPIRATION_DATE}:<=:${toDate}`;
    }

    queryString += '||';
  });
  return queryString.slice(0, -2);
};
