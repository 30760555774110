export interface LineGroupsTableDataDTO {
  columns: string[];
}

export interface TypeItemDTO {
  type: string;
  read_only: boolean;
}
export interface LineGroupsTypesDTO {
  id: TypeItemDTO;
  s_fab: TypeItemDTO;
  g_lin: TypeItemDTO;
  description_g_lin: TypeItemDTO;
  line: TypeItemDTO;
  warehouse: TypeItemDTO;
  created_at: TypeItemDTO;
  updated_at: TypeItemDTO;
}

class LineGroupsBodyDTO {
  data: LineGroupsTableDataDTO;
  types: LineGroupsTypesDTO;

  constructor({ data, types }) {
    this.data = data;
    this.types = types;
  }
}

export default LineGroupsBodyDTO;
