import { createAsyncThunk } from '@reduxjs/toolkit';
import getWarehouseStockOverviewService from 'modules/outbound/stock/services/GetWarehouseStockOverviewService';
import { getFastLaneSelected } from './index';

const getWarehouseStockOverview = createAsyncThunk(
  `warehouseStock/getWarehouseStockOverview`,
  async (_, { getState }) => {
    const appState: any = getState();
    const fastLaneSelected = getFastLaneSelected(appState);
    const queryParams = { fastLane: fastLaneSelected };
    const response = await getWarehouseStockOverviewService.getAll(queryParams);
    return response;
  }
);

export { getWarehouseStockOverview };
