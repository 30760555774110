import {
  LineGroupsTableDataDTO,
  LineGroupsTypesDTO
} from '../infrastructure/dto/LineGroupsBodyDTO';

interface ComponentParams {
  type: {
    multiSelect: boolean;
    inputType?: string;
    optionsData?: string[];
  };
  readOnly: boolean;
}

interface LineGroupsBodyData {
  columns: string[];
}
export interface LineGroupsTypes {
  sFab: ComponentParams;
  gLin: ComponentParams;
  descriptionGLin: ComponentParams;
  line: ComponentParams;
  warehouse: ComponentParams;
  createdAt: ComponentParams;
  updatedAt: ComponentParams;
}

interface GenerateFromDTOParams {
  data: LineGroupsTableDataDTO;
  types: LineGroupsTypesDTO;
}

class LineGroupsBody {
  data: LineGroupsBodyData;
  types: LineGroupsTypes;
  constructor({ data, types }: LineGroupsBody) {
    this.data = data;
    this.types = types;
  }
  private static _generateComponentParams(type) {
    if (type === 'string') {
      return { multiSelect: false, inputType: 'text' };
    }
    if (type === 'float' || type === 'integer' || type === 'number') {
      return { multiSelect: false, inputType: 'number' };
    }
    if (Array.isArray(type)) {
      return { multiSelect: true, optionsData: type };
    }
    return { multiSelect: false, inputType: 'text' };
  }
  private static _toCamelCase(str) {
    str = str.replace(/[-_\s]+(.)?/g, (match, ch) =>
      ch ? ch.toUpperCase() : ''
    );

    return str.substr(0, 1).toLowerCase() + str.substr(1);
  }

  static generateFromDTO({
    data,
    types
  }: GenerateFromDTOParams): LineGroupsBody {
    return new LineGroupsBody({
      data: {
        columns: data.columns.map((item) => this._toCamelCase(item))
      },
      types: {
        sFab: {
          type: this._generateComponentParams(types.s_fab.type),
          readOnly: types.s_fab.read_only
        },
        gLin: {
          type: this._generateComponentParams(types.g_lin.type),
          readOnly: types.g_lin.read_only
        },
        descriptionGLin: {
          type: this._generateComponentParams(types.description_g_lin.type),
          readOnly: types.description_g_lin.read_only
        },
        line: {
          type: this._generateComponentParams(types.line.type),
          readOnly: types.line.read_only
        },
        warehouse: {
          type: this._generateComponentParams(types.warehouse.type),
          readOnly: types.warehouse.read_only
        },
        createdAt: {
          type: this._generateComponentParams(types.created_at.type),
          readOnly: types.created_at.read_only
        },
        updatedAt: {
          type: this._generateComponentParams(types.updated_at.type),
          readOnly: types.updated_at.read_only
        }
      }
    });
  }
}

export default LineGroupsBody;
