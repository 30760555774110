import NoJitFiltersDTO from '../infrastructure/dto/NoJitFiltersDTO';

export interface IProviders {
  id: number;
  name: string;
}

export interface NoJitFilters {
  countries: string[];
  lines: string[];
  providers: IProviders[];
  inbounders: string[];
  models: string[];
  workshops: string[];
  warehouses: string[];
}

class NoJitFiltersData {
  countries: string[];
  lines: string[];
  providers: IProviders[];
  inbounders: string[];
  models: string[];
  workshops: string[];
  warehouses: string[];

  constructor({
    countries,
    lines,
    providers,
    inbounders,
    models,
    workshops,
    warehouses
  }: NoJitFilters) {
    this.countries = countries;
    this.lines = lines;
    this.providers = providers;
    this.inbounders = inbounders;
    this.models = models;
    this.workshops = workshops;
    this.warehouses = warehouses;
  }
  static generateFromDTO({
    countries,
    lines,
    providers,
    inbounders,
    models,
    workshops,
    warehouses
  }: NoJitFiltersDTO): NoJitFilters {
    return new NoJitFiltersData({
      countries: countries,
      lines: lines,
      providers: providers,
      inbounders: inbounders,
      models: models,
      workshops: workshops,
      warehouses: warehouses
    });
  }
}

export default NoJitFiltersData;
