import { FamiliesCoverageFiltersDTO } from 'modules/inbound/jit/infrastructure/dto/FamiliesCoverageFiltersDTO';
import { FlowTypes } from '../infrastructure/dto/JitFlowTypesDTO';
import { IProvider, ISequencer } from './types';

export interface FamiliesCoverageFilters {
  families: string[];
  lines: number[];
  tier1ProviderIds: IProvider[];
  logisticOperatorsIds: ISequencer[];
  flowTypes: FlowTypes;
  inbounders: string[];
}

class FamiliesCoverageFiltersData {
  families: string[];
  lines: number[];
  tier1ProviderIds: IProvider[];
  logisticOperatorsIds: ISequencer[];
  flowTypes: FlowTypes;
  inbounders: string[];

  constructor({
    lines,
    tier1ProviderIds,
    logisticOperatorsIds,
    flowTypes,
    inbounders,
    families
  }: FamiliesCoverageFilters) {
    this.lines = lines;
    this.tier1ProviderIds = tier1ProviderIds;
    this.logisticOperatorsIds = logisticOperatorsIds;
    this.flowTypes = flowTypes;
    this.inbounders = inbounders;
    this.families = families;
  }

  static generateFromDTO({
    families,
    lines,
    tier_1_provider_ids,
    logistic_operators_ids,
    flow_types,
    inbounders
  }: FamiliesCoverageFiltersDTO): FamiliesCoverageFilters {
    return new FamiliesCoverageFiltersData({
      families,
      lines,
      tier1ProviderIds: tier_1_provider_ids,
      logisticOperatorsIds: logistic_operators_ids,
      flowTypes: flow_types,
      inbounders
    });
  }
}

export default FamiliesCoverageFiltersData;
