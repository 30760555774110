export const downloadXlsxFile = ({
  file,
  fileName
}: {
  file: Blob;
  fileName: string;
}) => {
  const url = window.URL.createObjectURL(file);
  const downloadLink = document.createElement('a');
  const completeFileName = `${fileName}.xlsx`;
  downloadLink.href = url;
  downloadLink.download = completeFileName;
  downloadLink.click();
  downloadLink.parentNode?.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
};
