/* eslint-disable no-undef */
import GeolocatedTransport from '../../domain/GeolocatedTransport';
import GeolocatedVessel from '../../domain/GeolocatedVessel';

export const TransportType = 'TRANSPORT';
export const VesselType = 'VESSEL';

export type GeolocatedTransportsState = {
  transports: GeolocatedTransport[];
  vessels: GeolocatedVessel[];
  transportsRequestStatus: string;
  vesselsRequestStatus: string;
  filters: {
    search: string;
    selectedTransportId: string;
    selectedVessel: string;
  };
  downloadVesselVinsRequestStatus: string;
  vesselVinsFile: any;
};
