import { ThemeSEATColors, ThemeSEATOverlays } from './typing';

export const getRGBAFormat =
  (red: number, green: number, blue: number) =>
  (alpha = 1): string => {
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  };

export const commonColors: ThemeSEATColors = {
  darkGreen: {
    hexa: '#05601F',
    rgb: getRGBAFormat(5, 96, 31)(),
    rgba: getRGBAFormat(5, 96, 31)
  },
  green: {
    hexa: '#b7d8aa',
    rgb: getRGBAFormat(183, 216, 170)(),
    rgba: getRGBAFormat(183, 216, 170)
  },
  green50: {
    hexa: '#E5FFDA',
    rgb: getRGBAFormat(229, 255, 218)(),
    rgba: getRGBAFormat(229, 255, 218)
  },
  green700: {
    hexa: '#99C089',
    rgb: getRGBAFormat(153, 192, 137)(),
    rgba: getRGBAFormat(153, 192, 137)
  },
  green900: {
    hexa: '#72A55D',
    rgb: getRGBAFormat(114, 165, 93)(),
    rgba: getRGBAFormat(114, 165, 93)
  },
  yellow: {
    hexa: '#ffbf2f',
    rgb: getRGBAFormat(255, 191, 47)(),
    rgba: getRGBAFormat(255, 191, 47)
  },
  yellow50: {
    hexa: '#FFDC8F',
    rgb: getRGBAFormat(255, 220, 143)(),
    rgba: getRGBAFormat(255, 220, 143)
  },
  yellow700: {
    hexa: '#F4B422',
    rgb: getRGBAFormat(244, 180, 34)(),
    rgba: getRGBAFormat(244, 180, 34)
  },
  yellow900: {
    hexa: '#EAA200',
    rgb: getRGBAFormat(234, 164, 0)(),
    rgba: getRGBAFormat(234, 164, 0)
  },
  coral: {
    hexa: '#fe6058',
    rgb: getRGBAFormat(254, 96, 88)(),
    rgba: getRGBAFormat(254, 96, 88)
  },
  orange: {
    hexa: '#ea5d1a',
    rgb: getRGBAFormat(234, 93, 26)(),
    rgba: getRGBAFormat(234, 93, 26),
    shadowAlternative: getRGBAFormat(59, 38, 29)
  },
  orange50: {
    hexa: '#FFCAB1',
    rgb: getRGBAFormat(255, 202, 177)(),
    rgba: getRGBAFormat(255, 202, 177)
  },
  orange300: {
    hexa: '#FFA479',
    rgb: getRGBAFormat(255, 164, 121)(),
    rgba: getRGBAFormat(255, 164, 121)
  },
  orange500: {
    hexa: '#FC8349',
    rgb: getRGBAFormat(252, 131, 73)(),
    rgba: getRGBAFormat(252, 131, 73)
  },
  orange700: {
    hexa: '#EA5D1A',
    rgb: getRGBAFormat(234, 93, 26)(),
    rgba: getRGBAFormat(234, 93, 26)
  },
  orange900: {
    hexa: '#DB4904',
    rgb: getRGBAFormat(219, 73, 4)(),
    rgba: getRGBAFormat(219, 73, 4)
  },
  lightOrange: {
    hexa: '#ff9e1a',
    rgb: getRGBAFormat(255, 158, 26)(),
    rgba: getRGBAFormat(255, 158, 26)
  },
  red: {
    hexa: '#ec6b5f',
    rgb: getRGBAFormat(236, 107, 95)(),
    rgba: getRGBAFormat(236, 107, 95)
  },
  red50: {
    hexa: '#FFBBB5',
    rgb: getRGBAFormat(255, 187, 181)(),
    rgba: getRGBAFormat(255, 187, 181)
  },
  red700: {
    hexa: '#EF4839',
    rgb: getRGBAFormat(239, 72, 57)(),
    rgba: getRGBAFormat(239, 72, 57)
  },
  red900: {
    hexa: '#EF4839',
    rgb: getRGBAFormat(215, 18, 0)(),
    rgba: getRGBAFormat(215, 18, 0)
  },
  redDanger: {
    hexa: '#FF0000',
    rgb: getRGBAFormat(255, 0, 0)(),
    rgba: getRGBAFormat(255, 0, 0)
  },
  white: {
    hexa: '#ffffff',
    rgb: getRGBAFormat(255, 255, 255)(),
    rgba: getRGBAFormat(255, 255, 255)
  },
  lightGray: {
    hexa: '#f6f5f4',
    rgb: getRGBAFormat(246, 245, 244)(),
    rgba: getRGBAFormat(246, 245, 244)
  },
  mediumGray: {
    hexa: '#eeece9',
    rgb: getRGBAFormat(238, 236, 233)(),
    rgba: getRGBAFormat(238, 236, 233)
  },
  darkGray: {
    hexa: '#dfdad3',
    rgb: getRGBAFormat(223, 218, 211)(),
    rgba: getRGBAFormat(223, 218, 211)
  },
  darkerGray: {
    hexa: '#807e7d',
    rgb: getRGBAFormat(128, 126, 125)(),
    rgba: getRGBAFormat(128, 126, 125)
  },
  gray300: {
    hexa: '#726E6C',
    rgb: getRGBAFormat(114, 110, 108)(),
    rgba: getRGBAFormat(114, 110, 108)
  },
  gray500: {
    hexa: '#4E4946',
    rgb: getRGBAFormat(78, 73, 70)(),
    rgba: getRGBAFormat(78, 73, 70)
  },
  black50: {
    hexa: '#c1c0c0',
    rgb: getRGBAFormat(193, 192, 192)(),
    rgba: getRGBAFormat(193, 192, 192)
  },
  black: {
    hexa: '#33302e',
    rgb: getRGBAFormat(51, 48, 46)(),
    rgba: getRGBAFormat(51, 48, 46)
  },
  extraBlack: {
    hexa: '#000000',
    rgb: getRGBAFormat(0, 0, 0)(),
    rgba: getRGBAFormat(0, 0, 0)
  },
  modalBlack: {
    hexa: '#201E21',
    rgb: getRGBAFormat(32, 30, 33)(),
    rgba: getRGBAFormat(32, 30, 33)
  },
  blue: {
    hexa: '#3a95ff',
    rgb: getRGBAFormat(58, 149, 255)(),
    rgba: getRGBAFormat(58, 149, 255)
  },
  lightBlue: {
    hexa: '#00ffe8',
    rgb: getRGBAFormat(0, 255, 232)(0.4),
    rgba: getRGBAFormat(0, 255, 232)
  },
  darkBlue: {
    hexa: '#002473',
    rgb: getRGBAFormat(0, 36, 115)(),
    rgba: getRGBAFormat(0, 36, 115)
  },
  lightPurple: {
    hexa: '#C9A7FF',
    rgb: getRGBAFormat(201, 167, 255)(),
    rgba: getRGBAFormat(201, 167, 255)
  },
  purple: {
    hexa: '#941AEA',
    rgb: getRGBAFormat(148, 26, 234)(),
    rgba: getRGBAFormat(148, 26, 234)
  },
  neutral50: {
    hexa: '#E6E6E6',
    rgb: getRGBAFormat(230, 230, 230)(),
    rgba: getRGBAFormat(230, 230, 230)
  },
  neutral200: {
    hexa: '#A5A5A5',
    rgb: getRGBAFormat(165, 165, 165)(),
    rgba: getRGBAFormat(165, 165, 165)
  },
  neutral300: {
    hexa: '#6F6F6F',
    rgb: getRGBAFormat(111, 111, 111)(),
    rgba: getRGBAFormat(111, 111, 111)
  },
  neutral500: {
    hexa: '#4A4A4A',
    rgb: getRGBAFormat(74, 74, 74)(),
    rgba: getRGBAFormat(74, 74, 74)
  },
  neutral600: {
    hexa: '#3E3E3F',
    rgb: getRGBAFormat(62, 62, 63)(),
    rgba: getRGBAFormat(62, 62, 63)
  },
  neutral700: {
    hexa: '#353535',
    rgb: getRGBAFormat(53, 53, 53)(),
    rgba: getRGBAFormat(53, 53, 53)
  },
  neutral900: {
    hexa: '#201E21',
    rgb: getRGBAFormat(32, 30, 33)(),
    rgba: getRGBAFormat(32, 30, 33)
  },
  // Colors for some specifics situations
  fill: {
    hexa: '#6c6c6c',
    rgb: getRGBAFormat(108, 108, 108)(),
    rgba: getRGBAFormat(108, 108, 108)
  },
  blankPage: {
    hexa: '#fafafa',
    rgb: getRGBAFormat(250, 250, 250)(),
    rgba: getRGBAFormat(250, 250, 250)
  }
};

export const commonOverlays: ThemeSEATOverlays = {
  light: {
    hexa: '#353c43',
    rgb: getRGBAFormat(53, 60, 67)(0.2),
    rgba: getRGBAFormat(53, 60, 67)
  },
  heavy: {
    hexa: '#353c43',
    rgb: getRGBAFormat(53, 60, 67)(0.9),
    rgba: getRGBAFormat(53, 60, 67)
  },
  modal: {
    hexa: '#04040f',
    rgb: getRGBAFormat(4, 4, 15)(0.4),
    rgba: getRGBAFormat(4, 4, 15)
  }
};
