import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import EntriesWarehouseDlzDetailDTO, {
  ActionType,
  EntryHandleDTO,
  WarehouseDlzCurrentActionDTO,
  WarehouseDlzStopDTO
} from '../../infrastructure/dto/InPlantDTO/EntriesWarehouseDlzDetailDTO';

export type TransportActionType = (typeof ActionType)[keyof typeof ActionType];
interface WarehouseDlzStop {
  type: TransportActionType | null;
  tooltipInfo: string;
  label: string;
  showInfo: boolean;
}

class EntriesWarehouseDlzDetail {
  warehouseStops: WarehouseDlzStop[];
  stopsPending: string[];
  globalDlz: string;
  currentAction: WarehouseDlzStop | null;
  percentage: string;

  constructor({
    warehouseStops,
    stopsPending,
    globalDlz,
    currentAction,
    percentage
  }: EntriesWarehouseDlzDetail) {
    this.warehouseStops = warehouseStops;
    this.stopsPending = stopsPending;
    this.globalDlz = globalDlz;
    this.currentAction = currentAction;
    this.percentage = percentage;
  }

  private static _getTotalStopsInfo(
    entry: EntryHandleDTO,
    completed: WarehouseDlzStopDTO[],
    pending: WarehouseDlzStopDTO[],
    current: WarehouseDlzCurrentActionDTO | null
  ) {
    const totalStops: WarehouseDlzStop[] = [];

    if (entry) {
      totalStops.push({
        type: ActionType.ENTRY_HANDLE,
        tooltipInfo: GetDateFormatedService.formatSecondsToMinutes(
          entry.duration
        ),
        label: 'Manip.',
        showInfo: true
      });
    }

    if (completed?.length) {
      completed.forEach((stop) => {
        totalStops.push({
          type: stop.type,
          tooltipInfo: GetDateFormatedService.formatSecondsToMinutes(
            stop.warehouse_dlz
          ),
          label: stop.position,
          showInfo: true
        });
      });
    }

    if (current?.is_stop) {
      totalStops.push({
        type: current.type,
        tooltipInfo: GetDateFormatedService.formatSecondsToMinutes(
          current.warehouse_dlz
        ),
        label: current.position,
        showInfo: !current?.is_stop
      });
    }

    if (pending?.length) {
      pending.forEach((stop) => {
        totalStops.push({
          type: stop.type,
          tooltipInfo: GetDateFormatedService.formatSecondsToMinutes(
            stop.warehouse_dlz
          ),
          label: stop.position,
          showInfo: false
        });
      });
    }

    totalStops.push({
      type: ActionType.EXIT,
      tooltipInfo: '',
      label: 'Exit',
      showInfo: true
    });
    return totalStops;
  }

  private static _getCurrentPercentage(
    completed: WarehouseDlzStopDTO[],
    pending: WarehouseDlzStopDTO[],
    current: WarehouseDlzCurrentActionDTO | null
  ) {
    let totalStopsCompleted: number = 0;
    let inProgressNumber: number = 0;

    if (completed?.length) {
      totalStopsCompleted += completed.length;
    }

    if (current?.is_stop) {
      totalStopsCompleted += 1;
    }

    if (current && !current.is_stop) {
      inProgressNumber = 0.5;
    }

    const percentageNumber =
      ((totalStopsCompleted + inProgressNumber) * 100) /
      (totalStopsCompleted + pending?.length + 1);

    return `${percentageNumber > 100 ? 100 : percentageNumber}%`;
  }

  static generateFromDTO({
    current_action,
    entry_handle,
    global_dlz,
    stops_completed,
    stops_pending
  }: EntriesWarehouseDlzDetailDTO): EntriesWarehouseDlzDetail {
    return new EntriesWarehouseDlzDetail({
      warehouseStops: this._getTotalStopsInfo(
        entry_handle,
        stops_completed,
        stops_pending,
        current_action
      ),
      globalDlz: GetDateFormatedService.formatSecondsToMinutes(global_dlz),
      stopsPending: stops_pending?.length
        ? stops_pending.map((stop) => stop.position)
        : ['-'],
      currentAction: current_action
        ? {
            type: current_action?.type,
            tooltipInfo: `${
              current_action?.type
            } ${GetDateFormatedService.formatSecondsToMinutes(
              current_action.warehouse_dlz
            )}`,
            label: current_action?.position,
            showInfo: true
          }
        : null,
      percentage: this._getCurrentPercentage(
        stops_completed,
        stops_pending,
        current_action
      )
    });
  }

  static generateEmpty() {
    return new EntriesWarehouseDlzDetail({
      warehouseStops: [],
      stopsPending: [],
      globalDlz: '',
      currentAction: null,
      percentage: '0%'
    });
  }
}

export default EntriesWarehouseDlzDetail;
