import { ITopFlowsLackCapacityDTO } from '../infrastructure/dto/TopFlowsLackCapacityDTO';

export interface ITopFlowsLackCapacity {
  deviationByFlow: DeviationByFlow[];
  negativeDeviationFlowsQuantity: number;
  negativeDeviationPercentage: number;
}

export interface DeviationByFlow {
  flow: string;
  deviation: number;
}
class TopFlowsLackCapacity implements ITopFlowsLackCapacity {
  deviationByFlow: DeviationByFlow[];
  negativeDeviationFlowsQuantity: number;
  negativeDeviationPercentage: number;

  constructor({
    deviationByFlow,
    negativeDeviationFlowsQuantity,
    negativeDeviationPercentage
  }: TopFlowsLackCapacity) {
    this.deviationByFlow = deviationByFlow;
    this.negativeDeviationFlowsQuantity = negativeDeviationFlowsQuantity;
    this.negativeDeviationPercentage = negativeDeviationPercentage;
  }

  static generateFromDTO({
    deviationByFlow,
    negativeDeviationFlowsQuantity,
    negativeDeviationPercentage
  }: ITopFlowsLackCapacityDTO): TopFlowsLackCapacity {
    return new TopFlowsLackCapacity({
      deviationByFlow: deviationByFlow.map((deviation: DeviationByFlow) => {
        return {
          flow: deviation.flow,
          deviation: deviation.deviation
        };
      }),
      negativeDeviationFlowsQuantity,
      negativeDeviationPercentage
    });
  }
}

export default TopFlowsLackCapacity;
