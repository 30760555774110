import { UrgentIncomingTrucksDTO } from '../../infrastructure/dto/NextHoursDTO/urgentIncomingTrucksDTOMapper';
import { UrgentIncomingTrucks } from './UrgentIncomingTrucks';
import { UrgentTruck } from './UrgentTruck';

export const urgentIncomingTrucksMapper = (
  dto: UrgentIncomingTrucksDTO
): UrgentIncomingTrucks => ({
  total: dto.total,
  entries: dto.entries.map((entry) => UrgentTruck.generateFromDto(entry))
});
