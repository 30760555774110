import LocaleService from 'infrastructure/i18n/LocaleService';
import { SuppliesTranslations } from '../infrastructure/i18n/typing';
import { SuppliesText } from '../infrastructure/i18n/SupplyText';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';

const suppliesTranslations: SuppliesTranslations = {
  ...SuppliesText[LocaleService.getLanguage()]
};

export enum SuppliesColumnKeys {
  code = 'code',
  lines = 'lines',
  description = 'description',
  materialIndicator = 'materialIndicator',
  status = 'status',
  supplyType = 'supplyType',
  orderRequest = 'orderRequest',
  transportRequest = 'transportRequest',
  warehouseUnitNumber = 'warehouseUnitNumber',
  providerDeliveryNote = 'providerDeliveryNote',
  warehouseSourceType = 'warehouseSourceType',
  createdAt = 'createdAt',
  lineGroup = 'lineGroup',
  confirmationDate = 'confirmationDate',
  preparationDate = 'preparationDate',
  lineLocation = 'lineLocation',
  manufacturingSection = 'manufacturingSection',
  warehouseSource = 'warehouseSource',
  delayTimeInMinutes = 'delayTimeInMinutes',
  criticalStockType = 'criticalStockType',
  warehouseTarget = 'warehouseTarget'
}

export const SUPPLY_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: suppliesTranslations.code,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.code,
        key: NameValues.CODE,
        value: suppliesTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.LINE,
    value: suppliesTranslations.lines,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.lines,
        key: NameValues.LINE,
        value: suppliesTranslations.lines,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.DESCRIPTION,
    value: suppliesTranslations.description,
    sortable: false,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.description,
        key: NameValues.DESCRIPTION,
        value: suppliesTranslations.description,
        sortable: false,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.MATERIAL_INDICATOR,
    value: suppliesTranslations.materialIndicator,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.materialIndicator,
        key: NameValues.MATERIAL_INDICATOR,
        value: suppliesTranslations.materialIndicator,
        sortable: true,
        resize: true,
        draggable: true,
        tooltipInfo: suppliesTranslations.materialIndicatorTooltip
      }
    ]
  },
  {
    key: NameValues.LINE_GROUP,
    value: suppliesTranslations.lineGroup,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.lineGroup,
        key: NameValues.LINE_GROUP,
        value: suppliesTranslations.lineGroup,
        sortable: true,
        draggable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.MANUFACTURING_SECTION,
    value: suppliesTranslations.manufacturingSection,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.manufacturingSection,
        key: NameValues.MANUFACTURING_SECTION,
        value: suppliesTranslations.manufacturingSection,
        sortable: true,
        draggable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.SUPPLY_TYPE,
    value: suppliesTranslations.supplyType,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.supplyType,
        key: NameValues.SUPPLY_TYPE,
        value: suppliesTranslations.supplyType,
        sortable: true,
        draggable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.ORDER_REQUEST,
    value: suppliesTranslations.orderRequest,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.orderRequest,
        key: NameValues.ORDER_REQUEST,
        value: suppliesTranslations.orderRequest,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.TRANSPORT_REQUEST,
    value: suppliesTranslations.transportRequest,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.transportRequest,
        key: NameValues.TRANSPORT_REQUEST,
        value: suppliesTranslations.transportRequest,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE_UNIT_NUMBER,
    value: suppliesTranslations.warehouseUnitNumber,
    sortable: false,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.warehouseUnitNumber,
        key: NameValues.WAREHOUSE_UNIT_NUMBER,
        value: suppliesTranslations.warehouseUnitNumber,
        sortable: false,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_DELIVERY_NOTE,
    value: suppliesTranslations.providerDeliveryNote,
    sortable: false,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.providerDeliveryNote,
        key: NameValues.PROVIDER_DELIVERY_NOTE,
        value: suppliesTranslations.providerDeliveryNote,
        sortable: false,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE_SOURCE,
    value: suppliesTranslations.warehouseSource,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.warehouseSource,
        key: NameValues.WAREHOUSE_SOURCE,
        value: suppliesTranslations.warehouseSource,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE_SOURCE_TYPE,
    value: suppliesTranslations.warehouseSourceType,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.warehouseSourceType,
        key: NameValues.WAREHOUSE_SOURCE_TYPE,
        value: suppliesTranslations.warehouseSourceType,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.LINE_LOCATION,
    value: suppliesTranslations.lineLocation,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.lineLocation,
        key: NameValues.LINE_LOCATION,
        value: suppliesTranslations.lineLocation,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.CREATED_AT,
    value: suppliesTranslations.createdAt,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.createdAt,
        key: NameValues.CREATED_AT,
        value: suppliesTranslations.createdAt,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.PREPARATION_DATE,
    value: suppliesTranslations.preparationDate,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.preparationDate,
        key: NameValues.PREPARATION_DATE,
        value: suppliesTranslations.preparationDate,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.CONFIRMATION_DATE,
    value: suppliesTranslations.confirmationDate,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.confirmationDate,
        key: NameValues.CONFIRMATION_DATE,
        value: suppliesTranslations.confirmationDate,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE_TARGET,
    value: suppliesTranslations.warehouseTarget,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.warehouseTarget,
        key: NameValues.WAREHOUSE_TARGET,
        value: suppliesTranslations.warehouseTarget,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.DELAY_TIME_IN_MINUTES,
    value: suppliesTranslations.delayTimeInMinutes,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: SuppliesColumnKeys.delayTimeInMinutes,
        key: NameValues.DELAY_TIME_IN_MINUTES,
        value: suppliesTranslations.delayTimeInMinutes,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  }
];
