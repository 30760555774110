import { createSlice } from '@reduxjs/toolkit';
import { getDeliveryTimeSlots, getDeliveryTimeSlotsDetail } from './async';
import { DeliveryTimeState } from './types';

export const initialState: DeliveryTimeState = {
  slots: [],
  selectedCountries: [],
  requestStatus: '',
  slotsRangeDetails: {},
  bankOrderSlotsRangeDetailsRequestStatus: '',
  weekOrdersProductionToOrdersManufacturingSlotsRangeRequestStatus: '',
  carsBetweenFactoryAndRedistributionWarehouseSlotsRangeDetailsRequestStatus: ''
};

const slotsRangeDetailsOptionsForRequestStatus = {
  bankOrder: 'bankOrderSlotsRangeDetailsRequestStatus',
  weekOrdersProductionToOrdersManufacturing:
    'weekOrdersProductionToOrdersManufacturingSlotsRangeRequestStatus',
  carsBetweenFactoryAndRedistributionWarehouse:
    'carsBetweenFactoryAndRedistributionWarehouseSlotsRangeDetailsRequestStatus'
};

const deliveryTimeSlice = createSlice({
  name: 'deliveryTime',
  initialState,
  reducers: {
    updateSelectedCountries: (state, action) => {
      state.selectedCountries = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeliveryTimeSlots.fulfilled, (state, action) => {
      state.slots = action.payload || [];
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryTimeSlots.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryTimeSlots.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryTimeSlotsDetail.fulfilled, (state, action) => {
      const { slotsRangeDetails, slotsRangeName } = action.payload;
      if (Object.keys(state.slotsRangeDetails).includes(slotsRangeName)) {
        state.slotsRangeDetails[slotsRangeName] = {
          averageTime: slotsRangeDetails.averageTime
        };
      } else {
        state.slotsRangeDetails[slotsRangeName] = {};
        state.slotsRangeDetails[slotsRangeName].averageTime =
          slotsRangeDetails.averageTime;
      }

      state[slotsRangeDetailsOptionsForRequestStatus[slotsRangeName]] =
        action.meta.requestStatus;
    });
    builder.addCase(getDeliveryTimeSlotsDetail.pending, (state, action) => {
      state[
        slotsRangeDetailsOptionsForRequestStatus[action.meta['slotsRangeName']]
      ] = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryTimeSlotsDetail.rejected, (state, action) => {
      state[
        slotsRangeDetailsOptionsForRequestStatus[action.meta['slotsRangeName']]
      ] = action.meta.requestStatus;
    });
  }
});

//selectors
export const getSlots = (state) => state.deliveryTimeState.slots;
export const slotsRequestIsPending = (state) =>
  state.deliveryTimeState.requestStatus === 'pending';

export const slotsRequestIsFulfilled = (state) =>
  state.deliveryTimeState.requestStatus === 'fulfilled';

export const getSelectedCountries = (state) =>
  state.deliveryTimeState.selectedCountries;

export const getSelectedCountriesISO = (state) =>
  state.deliveryTimeState.selectedCountries.map((item) => item.key);

export const getSlotsRangeDetails = (state) =>
  state.deliveryTimeState.slotsRangeDetails;

export const getBankOrderSlotsRangeDetailsRequestStatusIsFulfilled = (state) =>
  state.deliveryTimeState.bankOrderSlotsRangeDetailsRequestStatus ===
  'fulfilled';

export const getweekOrdersProductionToOrdersManufacturingSlotsRangeRequestStatusRequestStatusIsFulfilled =
  (state) =>
    state.deliveryTimeState
      .weekOrdersProductionToOrdersManufacturingSlotsRangeRequestStatus ===
    'fulfilled';

export const getZp8SlotsRangeDetailsRequestStatusIsFulfilled = (state) =>
  state.deliveryTimeState
    .carsBetweenFactoryAndRedistributionWarehouseSlotsRangeDetailsRequestStatus ===
  'fulfilled';

//actions
export const { updateSelectedCountries } = deliveryTimeSlice.actions;

export default deliveryTimeSlice.reducer;
