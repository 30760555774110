import { LocationStatus } from '../../domain/GeolocatedTransport';

interface GeolocatedTransportDTO {
  transportId: string;
  lastLocationCoordinates: { longitude: number; latitude: number };
  status: LocationStatus;
}

class GeolocatedTransportsResponseDTO {
  transports: GeolocatedTransportDTO[];

  constructor({ transports }) {
    this.transports = transports;
  }
}

export default GeolocatedTransportsResponseDTO;
