import { createSlice } from '@reduxjs/toolkit';
import EndOfProductionFilters from 'modules/inbound/endOfProductionDate/domain/EndOfProductionFilters';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { getEndOfProductionFilters } from './async';
import { EndOfProductionFiltersState } from './types';

export const initialState: EndOfProductionFiltersState = {
  filters: EndOfProductionFilters.generateEmpty(),
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedProviderInbounders: [],
    selectedStatus: [],
    selectedWarehouseCodes: [],
    selectedModels: [],
    selectedDateRange: { fromDate: null, toDate: null }
  },
  search: '',
  selectedEndOfStockSummary: null,
  isFilterSaved: false
};

export const FILTERS_END_OF_PRODUCTION_STATE_KEY = 'FiltersEndOfProduction';

const endOfProductionFiltersSlice = createSlice({
  name: 'endOfProductionFiltersData',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedInbounders: (state, action) => {
      state.selectedSubHeaderFilters.selectedProviderInbounders =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedStatus: (state, action) => {
      state.selectedSubHeaderFilters.selectedStatus = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehouseCodes = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedDateRange: (state, action) => {
      state.selectedSubHeaderFilters.selectedDateRange = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedEndOfStockSummary: (state, action) => {
      state.selectedEndOfStockSummary =
        state.selectedEndOfStockSummary === action.payload
          ? null
          : action.payload;
    },
    resetFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${FILTERS_END_OF_PRODUCTION_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${FILTERS_END_OF_PRODUCTION_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${FILTERS_END_OF_PRODUCTION_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        const savedSelecteFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;

        state.selectedSubHeaderFilters = {
          ...savedSelecteFilters,
          selectedDateRange: parsedFilters.selectedDateRange.fromDate
            ? {
                fromDate: new Date(parsedFilters.selectedDateRange.fromDate),
                toDate: new Date(parsedFilters.selectedDateRange.toDate)
              }
            : initialState.selectedSubHeaderFilters.selectedDateRange
        };

        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEndOfProductionFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfProductionFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfProductionFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getFilters = (state) => state.endOfProductionFiltersData.filters;
export const isLoading = (state) =>
  state.endOfProductionFiltersData.requestStatus === 'pending';
export const hasError = (state) =>
  state.endOfProductionFiltersData.requestStatus === 'rejected';
export const getFormatedCustomDateForUI = (state) => {
  const { selectedDateRange } = getSelectedFilters(state);
  const fromDate = GetDateFormatedService.getDateFormated(
    selectedDateRange.fromDate,
    []
  );
  const toDate = GetDateFormatedService.getDateFormated(
    selectedDateRange.toDate,
    []
  );
  return selectedDateRange.fromDate ? `${fromDate} - ${toDate}` : null;
};

export const getFormatedCustomDate = (state) => {
  return GetDateFormatedService.formatDateRanges(
    state.endOfProductionFiltersData.selectedSubHeaderFilters.selectedDateRange
      .fromDate,
    state.endOfProductionFiltersData.selectedSubHeaderFilters.selectedDateRange
      .toDate
  );
};
export const getFilterSearch = (state) =>
  state.endOfProductionFiltersData.search;
export const getSelectedFilters = (state) =>
  state.endOfProductionFiltersData.selectedSubHeaderFilters;
export const getSelectedEndOfStockSummary = (state) =>
  state.endOfProductionFiltersData.selectedEndOfStockSummary;
export const getIsFilterSaved = (state) =>
  state.endOfProductionFiltersData.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.endOfProductionFiltersData.selectedSubHeaderFilters).some(
    (key) =>
      state.endOfProductionFiltersData.selectedSubHeaderFilters[key].length
  ) ||
  state.endOfProductionFiltersData.selectedSubHeaderFilters.selectedDateRange
    .fromDate;

export const {
  updateFilterSearch,
  updateSelectedInbounders,
  updateSelectedStatus,
  updateSelectedWarehouses,
  updateSelectedModels,
  updateSelectedDateRange,
  updateSelectedEndOfStockSummary,
  resetFilters,
  saveFilters,
  loadFilters
} = endOfProductionFiltersSlice.actions;

export default endOfProductionFiltersSlice.reducer;
