import LocaleService from 'infrastructure/i18n/LocaleService';
import TransfersFiltersDataDTO from '../infrastructure/dto/TransfersFiltersDTO';
import { TransferText } from '../infrastructure/i18n/TransferText';
import { TransferTranslations } from '../infrastructure/i18n/typing';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

const transferTranslations: TransferTranslations = {
  ...TransferText[LocaleService.getLanguage()]
};
export interface TransfersFilters {
  basicContainers: string[];
  supplyContainers: string[];
  warehouseTypes: string[];
  warehouses: string[];
  transfersCategories: SelectItemProps[];
  supplyTypes: string[];
}

class TransfersFiltersData {
  basicContainers: string[];
  supplyContainers: string[];
  warehouseTypes: string[];
  warehouses: string[];
  transfersCategories: SelectItemProps[];
  supplyTypes: string[];

  constructor({
    basicContainers,
    supplyContainers,
    warehouseTypes,
    warehouses,
    transfersCategories,
    supplyTypes
  }: TransfersFilters) {
    this.basicContainers = basicContainers;
    this.supplyContainers = supplyContainers;
    this.warehouseTypes = warehouseTypes;
    this.warehouses = warehouses;
    this.transfersCategories = transfersCategories;
    this.supplyTypes = supplyTypes;
  }

  private static _toCamelCase(key) {
    if (!key) return NO_DATA;
    return key
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
      );
  }

  static generateFromDTO({
    basic_containers,
    supply_containers,
    warehouse_types,
    warehouses,
    transfers_categories,
    supply_types
  }: TransfersFiltersDataDTO): TransfersFilters {
    return new TransfersFiltersData({
      basicContainers: basic_containers,
      supplyContainers: supply_containers,
      warehouseTypes: warehouse_types,
      warehouses: warehouses,
      transfersCategories: transfers_categories.map((category) => ({
        key: category,
        text: transferTranslations[this._toCamelCase(category)]
      })),
      supplyTypes: supply_types
    });
  }

  static generateEmpty() {
    return new TransfersFiltersData({
      basicContainers: [],
      supplyContainers: [],
      warehouseTypes: [],
      warehouses: [],
      transfersCategories: [],
      supplyTypes: []
    });
  }
}

export default TransfersFiltersData;
