import LocaleService from 'infrastructure/i18n/LocaleService';
import { StatusPlanType } from 'modules/inbound/shared/domain/StatusPlanTypes';
import { TableColumnProps } from 'modules/inbound/shared/ui/layout/components/Table/types';
import { NoJitText } from '../infrastructure/i18n/NoJitText';
import { NoJitTranslations } from '../infrastructure/i18n/typing';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { TRANSPORT_DETAILS_COLUMNS } from './TransportDetailsColumns';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import { NoJitItemPlanning, getStatusPlanTranslation } from './NoJitPlanning';
import {
  MRP_COMPLETENESS_TYPES,
  MRP_PUNCTUALITY_TYPES
} from 'modules/inbound/shared/infraestructure/dto/PlanningTransportsDTO';

const noJitTranslations: NoJitTranslations & InboundTranslations = {
  ...NoJitText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

interface GenerateFromDTOParams {
  planning: NoJitItemPlanning[];
}

export interface INoJitTransportsDetail {
  transportData: {
    mrps: TransportDetailItem[];
    loads: TransportDetailItem[];
  };
  transportColumns: TableColumnProps[];
}

export interface TransportDetailItem {
  geolocated?: boolean;
  type: string;
  providerId?: number;
  status: string;
  amount: number;
  departureDate?: string;
  leadtime: number | string;
  providerDeliveryNote: string;
  providerDeliveryTime: string;
  officialDeliveryTime: string;
  dateType: string;
  transporter: string;
  plateNumber: string;
}

class NoJitTransportsDetail implements INoJitTransportsDetail {
  transportData: {
    mrps: TransportDetailItem[];
    loads: TransportDetailItem[];
  };
  transportColumns: TableColumnProps[];

  constructor({ transportData, transportColumns }: NoJitTransportsDetail) {
    this.transportData = transportData;
    this.transportColumns = transportColumns;
  }

  private static _snakeCaseToCamel(key) {
    if (!key) return NO_DATA;
    return key
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
      );
  }

  private static _formattedTransportStatus(load) {
    if (load.isDelayed) {
      return `${noJitTranslations.delayed} ${getStatusPlanTranslation(
        load.type
      )}`;
    }
    switch (load.type) {
      case StatusPlanType.TransportPending:
        return `${noJitTranslations.inConsolidation} ${noJitTranslations.tp}`;
      case StatusPlanType.TransportInTransit:
        return `${noJitTranslations.inExpedition} ${noJitTranslations.tr}`;
      case StatusPlanType.TransportInPlant:
        return `${noJitTranslations.unloadingInPlant} ${noJitTranslations.ep}`;
      default:
        return NO_DATA;
    }
  }

  private static _formattedMrpPunctualityStatus(
    punctuality: MRP_PUNCTUALITY_TYPES
  ) {
    switch (punctuality) {
      case 'ON_TIME':
        return noJitTranslations.onTime;
      case 'RISK_OF_DELAY':
        return noJitTranslations.riskOfDelay;
      case 'RISK_OF_OVERTAKE':
        return noJitTranslations.riskOfOvertake;
      case 'UNKNOWN':
        return noJitTranslations.unknow;
      default:
        return '-';
    }
  }

  private static _formattedMrpCompletenessStatus(
    completeness: MRP_COMPLETENESS_TYPES
  ) {
    switch (completeness) {
      case 'COMPLETE':
        return noJitTranslations.complete;
      case 'LEFTOVER':
        return noJitTranslations.leftover;
      case 'MISSING':
        return noJitTranslations.missing;
      case 'UNKNOWN':
        return noJitTranslations.unknow;
      case 'INCOMPLETE':
        return noJitTranslations.incomplete;
      default:
        return '-';
    }
  }

  private static _formattedTypeTranslation(load): string {
    switch (load.isUrgent || load.geolocated || load.isKcc) {
      case load.isUrgent:
        return noJitTranslations.urgentTransit;
      case load.geolocated:
        return noJitTranslations.geolocated;
      case load.isKcc:
        return noJitTranslations.kccTransit;
      default:
        return noJitTranslations.transit;
    }
  }

  private static _formatLeadtime(leadtimeDays) {
    let leadtimeDaysFormatted = leadtimeDays.toString();
    if (leadtimeDaysFormatted === 'MAX') {
      return leadtimeDaysFormatted;
    }
    return leadtimeDaysFormatted === '1'
      ? `${leadtimeDaysFormatted} ${noJitTranslations.unitSingleDay}`
      : `${leadtimeDaysFormatted} ${noJitTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    planning
  }: GenerateFromDTOParams): NoJitTransportsDetail {
    const formatPlanningTransportsDetail: TransportDetailItem[] = [];
    const formatPlanningMrpsDetail: TransportDetailItem[] = [];

    if (planning) {
      Object.values(planning).forEach((item) => {
        if (item.loads?.length) {
          item.loads.forEach((load) => {
            formatPlanningTransportsDetail.push({
              geolocated: load.geolocated,
              type: this._formattedTypeTranslation(load),
              providerDeliveryTime: load.providerDeliveryTime ?? NO_DATA,
              officialDeliveryTime: load.officialDeliveryTime ?? NO_DATA,
              dateType: load.windowBookedId
                ? `${noJitTranslations[this._snakeCaseToCamel(load.source)]} (${
                    load.windowBookedId
                  })`
                : `${noJitTranslations[this._snakeCaseToCamel(load.source)]}`,
              amount: load.amount,
              departureDate: load.departureDate,
              leadtime: load.leadtime
                ? this._formatLeadtime(load.leadtime)
                : NO_DATA,
              status: this._formattedTransportStatus(load),
              providerDeliveryNote:
                load.providerDeliveryNote?.padStart(8, '0') ?? NO_DATA,
              plateNumber: load.plateNumber,
              transporter: load.transporter ?? NO_DATA
            });
          });
        }
        if (item.mrps?.length) {
          item.mrps.forEach((mrp) => {
            formatPlanningMrpsDetail.push({
              type: noJitTranslations.mrp,
              providerId: mrp.providerId ?? NO_DATA,
              amount: mrp.amount,
              departureDate: NO_DATA,
              leadtime: mrp.leadtime
                ? this._formatLeadtime(mrp.leadtime)
                : NO_DATA,
              status: `${this._formattedMrpPunctualityStatus(
                mrp.deliveryStatuses.punctuality
              )}, ${this._formattedMrpCompletenessStatus(
                mrp.deliveryStatuses.completeness
              )}`,
              providerDeliveryNote: NO_DATA,
              providerDeliveryTime: NO_DATA,
              officialDeliveryTime: mrp.endPlanningDate ?? NO_DATA,
              dateType: NO_DATA,
              plateNumber: NO_DATA,
              transporter: NO_DATA
            });
          });
        }
      });
    }

    return new NoJitTransportsDetail({
      transportData: {
        loads: formatPlanningTransportsDetail,
        mrps: formatPlanningMrpsDetail
      },
      transportColumns: TRANSPORT_DETAILS_COLUMNS
    });
  }

  static generateEmpty() {
    return new NoJitTransportsDetail({
      transportData: {
        loads: [],
        mrps: []
      },
      transportColumns: []
    });
  }
}

export default NoJitTransportsDetail;
