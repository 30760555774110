import { LoadsStatusDTO } from '../application/loadsStatusDTOMapper';

type GenerateFromDTOParams = LoadsStatusDTO;

class LoadsStatus {
  ACP: number;
  DLY: number;
  PND: number;
  RQS: number;

  constructor({ ACP, DLY, PND, RQS }: LoadsStatus) {
    this.ACP = ACP;
    this.PND = PND;
    this.DLY = DLY;
    this.RQS = RQS;
  }

  static generateFromDto({
    ACP,
    DLY,
    PND,
    RQS
  }: GenerateFromDTOParams): LoadsStatus {
    return new LoadsStatus({
      ACP: ACP,
      PND: PND,
      DLY: DLY,
      RQS: RQS
    });
  }
}

export default LoadsStatus;
