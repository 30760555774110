import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { JitText } from '../infrastructure/i18n/JitText';
import { JitTranslations } from '../infrastructure/i18n/typing';

const jitTranslations: JitTranslations & InboundTranslations = {
  ...JitText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export enum ColumnKeys {
  code = 'code',
  providerCode = 'providerCode',
  families = 'families',
  lines = 'lines',
  sequencerCoverage = 'sequencerCoverage',
  margin = 'margin',
  OLProvider = 'OLProvider',
  OLStock = 'OLStock',
  OLUpdated = 'OLUpdated',
  T1Provider = 'T1Provider',
  T1Stock = 'T1Stock',
  T1Updated = 'T1Updated',
  providerType = 'providerType',
  models = 'models'
}

export const PART_COVERAGE_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: jitTranslations.code,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.code,
        key: NameValues.CODE,
        value: jitTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_CODE,
    value: jitTranslations.providerCode,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.providerCode,
        key: NameValues.PROVIDER_CODE,
        value: jitTranslations.providerCode,
        sortable: false,
        tooltipInfo: jitTranslations.providerPartTooltipInfo
      }
    ]
  },
  {
    key: NameValues.FAMILIES,
    value: jitTranslations.family,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.families,
        key: NameValues.FAMILIES,
        value: jitTranslations.family,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.LINES,
    value: jitTranslations.line,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.lines,
        key: NameValues.LINES,
        value: jitTranslations.line,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.COVERAGE_WITH,
    value: jitTranslations.coverageWithLabel,
    headerVisible: true,
    headerSpan: 2,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.sequencerCoverage,
        key: NameValues.SEQUENCER_COVERAGE,
        value: jitTranslations.sequencerCoverage,
        sortable: true,
        tooltipInfo: jitTranslations.T1Stock2Tooltip
      },
      {
        uiKey: ColumnKeys.margin,
        key: NameValues.MARGIN,
        value: jitTranslations.margin,
        sortable: true,
        tooltipInfo: jitTranslations.T2TransitsTooltip,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.LOGISTIC_OPERATOR,
    value: jitTranslations.OL,
    headerVisible: true,
    headerSpan: 3,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.SEQUENCER_STOCK,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.OLProvider,
        key: NameValues.SEQUENCER_CODE,
        value: jitTranslations.OLProvider,
        sortable: false,
        resize: true,
        tooltipInfo: jitTranslations.OLProviderTooltip
      },
      {
        uiKey: ColumnKeys.OLStock,
        key: NameValues.SEQUENCER_STOCK,
        value: jitTranslations.OLStock,
        sortable: false,
        resize: true,
        align: 'right'
      },
      {
        uiKey: ColumnKeys.OLUpdated,
        key: NameValues.LAST_UPDATED,
        value: jitTranslations.OLUpdated,
        sortable: false,
        resize: true,
        tooltipInfo: jitTranslations.OLUpdatedTooltip
      }
    ]
  },
  {
    key: NameValues.PROVIDER_TIER_1,
    value: jitTranslations.T1,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.PROVIDER_STOCK,
    columns: [
      {
        uiKey: ColumnKeys.T1Provider,
        key: NameValues.PROVIDER_ID,
        value: jitTranslations.provider,
        sortable: false,
        resize: true,
        tooltipInfo: jitTranslations.T1ProviderTooltip
      },
      {
        uiKey: ColumnKeys.T1Stock,
        key: NameValues.PROVIDER_STOCK,
        value: jitTranslations.T1Stock,
        sortable: false,
        resize: true,
        align: 'right'
      },
      {
        uiKey: ColumnKeys.T1Updated,
        key: NameValues.LAST_UPDATED,
        value: jitTranslations.T1Updated,
        sortable: false,
        resize: true,
        tooltipInfo: jitTranslations.T1UpdatedTooltip
      }
    ]
  },
  {
    key: NameValues.PROVIDER_TYPE,
    value: jitTranslations.type,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.providerType,
        key: NameValues.PROVIDER_TYPE,
        value: jitTranslations.type,
        sortable: false,
        tooltipInfo: jitTranslations.typeTooltip
      }
    ]
  },
  {
    key: NameValues.MODELS,
    value: jitTranslations.models,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.models,
        key: NameValues.MODELS,
        value: jitTranslations.models,
        sortable: false,
        tooltipInfo: jitTranslations.modelCarRelation
      }
    ]
  }
];
