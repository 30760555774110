import {
  NameValues,
  FiltersItem,
  TimeGraphStatus,
  SearchModality,
  StatusTypes
} from './NameValues';

const cleanUpQueryDuplicatesAndGetString = (queryArray) => {
  let queryUniq = queryArray.reduce(
    (acc, queryItem) => (acc.includes(queryItem) ? acc : [...acc, queryItem]),
    []
  );

  return queryUniq.filter((item) => item).join('||');
};

const getTimeGraphQuery = (selectedGraph: TimeGraphStatus) => {
  switch (selectedGraph.searchModality) {
    case SearchModality.TO:
      return `${selectedGraph.searchBy}:<=:${selectedGraph.toDateTime}`;
    case SearchModality.FROM:
      return `${selectedGraph.searchBy}:>=:${selectedGraph.fromDateTime}`;
    case SearchModality.BETWEEN:
      return [
        `${selectedGraph.searchBy}:>=:${selectedGraph.fromDateTime}`,
        `${selectedGraph.searchBy}:<:${selectedGraph.toDateTime}`
      ];
    default:
      break;
  }
};

const getGraphStatusQuery = (status: StatusTypes | null, isClosed: boolean) => {
  if (isClosed) {
    if (status === StatusTypes.CLOSED) {
      return [`${NameValues.IS_CLOSED}:=:1`];
    }
    return [`${NameValues.IS_CLOSED}:=:1`, `${NameValues.STATUS}:=:${status}`];
  }
  return [`${NameValues.IS_CLOSED}:=:0`, `${NameValues.STATUS}:=:${status}`];
};

const getDefaultQueryString = (filterName: string, values: string[]) => {
  if (values.length > 1) {
    return `${filterName}:IN:(${values.join(';')})`;
  }

  return `${filterName}:=:${values}`;
};

const getFiltersQueryString = (queryFilters: FiltersItem[]) => {
  let querySearch: string[] = [];

  queryFilters.forEach((filter) => {
    if (filter.values.length) {
      const values = filter.values.map((value) => value.key);
      switch (filter.name) {
        case NameValues.MODELS:
        case NameValues.LINES:
        case NameValues.LINE:
          querySearch.push(`${filter.name}:LK:(${values.join(';')})`);
          break;
        case NameValues.MODELS_LKDD:
        case NameValues.LINES_LKDD:
          querySearch.push(
            `{${filter.name}:LK:(${values.join(';')})|OR|${
              filter.name.split('_')[0]
            }:LK:(${values.join(';')})}`
          );
          break;
        case NameValues.MATERIAL_INDICATOR:
          querySearch.push(`${filter.name}:IN:(${values.join(';')})`);
          break;
        default:
          querySearch.push(getDefaultQueryString(filter.name, values));
      }
    }
  });

  return querySearch;
};

export {
  cleanUpQueryDuplicatesAndGetString,
  getTimeGraphQuery,
  getGraphStatusQuery
};
export default getFiltersQueryString;
