import IProviderContactListRepository from '../../domain/ProviderContactList/IProviderContactList';
import ProviderContactListRepository from '../../infraestructure/repositories/ProviderContactListRepository';

class GetProviderContactListUseCase {
  private _contactListRepository: IProviderContactListRepository;

  constructor({ repository }) {
    this._contactListRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._contactListRepository.getProviderContactList({ queryParams });
  }
}

export { GetProviderContactListUseCase };
export default new GetProviderContactListUseCase({
  repository: ProviderContactListRepository
});
