import { createSlice } from '@reduxjs/toolkit';
import {
  SaturationUnloadingPointsFiltersState,
  SelectedFilters
} from './types';
import { SaturationUnloadingPointsFilters } from 'modules/inbound/entries/domain/UnloadingPoints/SaturationUnloadingPointsFilters';
import { getSaturationUnloadingPointsFilters } from './async';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';

export const initialState: SaturationUnloadingPointsFiltersState = {
  filters: SaturationUnloadingPointsFilters.generateEmpty(),
  selectedFilters: {
    selectedCarriers: [],
    selectedSuppliers: [],
    selectedUnloadingPoints: [],
    selectedWarehouses: []
  },
  areFiltersSaved: false
};

const ENTRIES_UNLOADING_POINTS_FILTERS_STATE_KEY =
  'EntriesUnloadingPointsFiltersSaved';

const saturationUnloadingPointsFiltersSlice = createSlice({
  name: 'saturationUnloadingPointsFilters',
  initialState,
  reducers: {
    updateSelectedCarriers: (state, action) => {
      state.selectedFilters.selectedCarriers = action.payload;
      state.areFiltersSaved = false;
    },
    updateSelectedSuppliers: (state, action) => {
      state.selectedFilters.selectedSuppliers = action.payload;
      state.areFiltersSaved = false;
    },
    updateSelectedUnloadingPoints: (state, action) => {
      state.selectedFilters.selectedUnloadingPoints = action.payload;
      state.areFiltersSaved = false;
    },
    updateSelectedWarehouses: (state, action) => {
      state.selectedFilters.selectedWarehouses = action.payload;
      state.areFiltersSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${ENTRIES_UNLOADING_POINTS_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedFilters)
      );
      state.areFiltersSaved = true;
    },
    resetFilters: (state, action) => {
      localStorage.removeItem(
        `${action.payload}-${ENTRIES_UNLOADING_POINTS_FILTERS_STATE_KEY}`
      );
      state.selectedFilters = initialState.selectedFilters;
      state.areFiltersSaved = false;
    },
    loadFilters: (state, action) => {
      const localStoragefilters = getLocalStorage<SelectedFilters>(
        `${action.payload}-${ENTRIES_UNLOADING_POINTS_FILTERS_STATE_KEY}`,
        { ...state.selectedFilters }
      );

      state.selectedFilters = {
        selectedCarriers: localStoragefilters.selectedCarriers || [],
        selectedSuppliers: localStoragefilters.selectedSuppliers || [],
        selectedUnloadingPoints:
          localStoragefilters.selectedUnloadingPoints || [],
        selectedWarehouses: localStoragefilters.selectedWarehouses || []
      };

      state.areFiltersSaved = Object.keys(localStoragefilters).some(
        (key) => localStoragefilters[key].length
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSaturationUnloadingPointsFilters.fulfilled,
      (state, action) => {
        state.filters = action.payload;
      }
    );
  }
});

export const getUnloadingPointsFilters = (state) =>
  state.saturationUnloadingPointsFiltersState.filters;

export const getHasFiltersSaved = (state) =>
  state.saturationUnloadingPointsFiltersState.areFiltersSaved;
export const getSelectedFilters = (state) =>
  state.saturationUnloadingPointsFiltersState.selectedFilters;
export const getHasFiltersSelected = (state) => {
  const filtersSelected =
    state.saturationUnloadingPointsFiltersState.selectedFilters;
  return Object.keys(filtersSelected).some(
    (key) => filtersSelected[key].length
  );
};

export const {
  updateSelectedCarriers,
  updateSelectedSuppliers,
  updateSelectedUnloadingPoints,
  updateSelectedWarehouses,
  saveFilters,
  resetFilters,
  loadFilters
} = saturationUnloadingPointsFiltersSlice.actions;
export default saturationUnloadingPointsFiltersSlice.reducer;
