import GlobalGeolocationDelay from '../domain/GlobalDelay';
import IGlobalDelayRepository from '../domain/IGlobalDelayRepository';
import globalDelayRepository from '../infrastructure/repositories/GlobalDelayRepository';

class GetGlobalDelayUseCase {
  private _repository: IGlobalDelayRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<GlobalGeolocationDelay> {
    return this._repository
      .getGlobalDelay()
      .then((response) => GlobalGeolocationDelay.generateFromDto(response));
  }
}

export default new GetGlobalDelayUseCase({
  repository: globalDelayRepository
});

export { GetGlobalDelayUseCase };
