import cognitoAuthenticationService from 'modules/shared/infrastructure/cognitoAuthentication/CognitoAuthenticationService';
import IAuthenticationService from 'modules/shared/domain/IAuthenticationService';

class AuthenticationService implements IAuthenticationService {
  _authClient: IAuthenticationService;
  constructor({ authClient }) {
    this._authClient = authClient;
  }
  initSession({ username, password }: { username: string; password: string }) {
    return this._authClient.initSession({ username, password });
  }
  logout() {
    return this._authClient.logout();
  }
  getIdToken() {
    return this._authClient.getIdToken();
  }
  checkSession() {
    return this._authClient.checkSession();
  }
  getUsername() {
    return this._authClient.getUsername();
  }
  getUserEmail() {
    return this._authClient.getUserEmail();
  }
  getUserGroup() {
    return this._authClient.getUserGroup();
  }
  forgotPassword({ username }: { username: string }) {
    return this._authClient.forgotPassword({ username });
  }

  forgotPasswordSubmit({
    username,
    code,
    password
  }: {
    username: string;
    code: string;
    password: string;
  }) {
    return this._authClient.forgotPasswordSubmit({ username, code, password });
  }

  completeNewPassword({ newPassword }: { newPassword: string }) {
    return this._authClient.completeNewPassword({ newPassword });
  }
}

const authenticationService = new AuthenticationService({
  authClient: cognitoAuthenticationService
});

export { authenticationService };
