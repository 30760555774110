import { createAsyncThunk } from '@reduxjs/toolkit';
import getFinishedGoodsByCheckpointService from 'modules/outbound/fulfillment/services/FinishedGoodsByCheckpointService';
import getFinishedGoodCheckpointTimelineService from 'modules/outbound/fulfillment/services/FinishedGoodCheckpointTimelineService';
import getFinishedGoodTransportDetailsUseCase from 'modules/outbound/fulfillment/application/GetFinishedGoodTransportDetailsUseCase';
import getTransportDetailsByIdUseCase from 'modules/outbound/fulfillment/application/GetTransportDetailsByIdUseCase';
import { getSelectedCountry } from 'modules/outbound/fulfillment/redux/DeliveriesGeolocationReducer';
import {
  getSelectedCheckpoint,
  getStatusFilter
} from 'modules/outbound/fulfillment/redux/DeliveriesGeolocationCheckpointsReducer';
import {
  getResultsPerPage,
  getCurrentPage,
  getSelectedVin
} from 'modules/outbound/fulfillment/redux/FinishedGoodsByCheckpointReducer';
import {
  getGeolocationFastLaneSelected,
  getGeolocationLogisticObjectiveSelected,
  getGeolocationSelectedBrands,
  getGeolocationSelectedCommercialPackagesInPlainFormat,
  getGeolocationSelectedDeliveryPointsInPlainFormat,
  getSelectedGeolocationClientTypeInPlainFormat,
  getSelectedGeolocationFactoryInPlainFormat
} from '../FiltersStateReducer';
import {
  getSearchedValue,
  getSelectedTransportId
} from '../GeolocatedTransportsReducer';
import {
  FinishedGoodsByCheckpointSort,
  FinishedGoodsByCheckpointSortTypes,
  FinishedGoodTransportDetailsAndVin
} from './types';

const getFinishedGoodsByCheckpoint = createAsyncThunk(
  `finishedGoodsByCheckpoint/getFinishedGoodsByCheckpoint`,
  async (_, { getState }) => {
    const appState: any = getState();
    const countrySelected = getSelectedCountry(appState);
    const checkpointSelected = getSelectedCheckpoint(appState);
    const itemsPerPage = getResultsPerPage(appState);
    const currentPage = getCurrentPage(appState);
    const selectedStatus = getStatusFilter(appState);
    const fastLaneSelected = getGeolocationFastLaneSelected(appState);
    const selectedClientType =
      getSelectedGeolocationClientTypeInPlainFormat(appState);
    const selectedBrands = getGeolocationSelectedBrands(appState);
    const commercialPackagesSelected =
      getGeolocationSelectedCommercialPackagesInPlainFormat(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const selectedFactory =
      getSelectedGeolocationFactoryInPlainFormat(appState);
    const deliverypointsSelected =
      getGeolocationSelectedDeliveryPointsInPlainFormat(appState);
    const queryParams = {
      clientType: selectedClientType,
      page: currentPage,
      itemsPerPage,
      status: selectedStatus,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: selectedBrands,
      commercialPackages: commercialPackagesSelected,
      deliveryPoints: deliverypointsSelected,
      logisticObjective: logisticObjectiveSelected,
      sortBy: FinishedGoodsByCheckpointSort.asc,
      sortField: FinishedGoodsByCheckpointSortTypes.factoryWarehouseInputDate
    };
    const response = await getFinishedGoodsByCheckpointService.get({
      checkpointRef: checkpointSelected,
      countryCode: countrySelected,
      queryParams
    });
    return response;
  }
);

const getFinishedGoodCheckpointsTimeline = createAsyncThunk(
  `finishedGoodsByCheckpoint/getFinishedGoodCheckpointsTimeline`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedVin = getSelectedVin(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const queryParams = {
      logisticObjective: logisticObjectiveSelected
    };
    const response = await getFinishedGoodCheckpointTimelineService.get({
      vin: selectedVin,
      queryParams
    });
    return response;
  }
);

const getFinishedGoodTransportDetails = createAsyncThunk(
  `finishedGoodsByCheckpoint/getFinishedGoodTransportDetails`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedVin = getSelectedVin(appState);
    const geoMapVin = getSearchedValue(appState);
    const vin = geoMapVin?.length > 0 ? geoMapVin : selectedVin;

    const response = await getFinishedGoodTransportDetailsUseCase.execute({
      vin
    });
    return { ...response, vin } as FinishedGoodTransportDetailsAndVin;
  }
);

const getTransportDetailsById = createAsyncThunk(
  `finishedGoodsByCheckpoint/getTransportDetailsById`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedTransportId = getSelectedTransportId(appState);

    const response = await getTransportDetailsByIdUseCase.execute({
      transportId: selectedTransportId
    });
    return response;
  }
);

export {
  getFinishedGoodsByCheckpoint,
  getFinishedGoodCheckpointsTimeline,
  getFinishedGoodTransportDetails,
  getTransportDetailsById
};
