import localeService, {
  LocaleService
} from 'infrastructure/i18n/LocaleService';
import { COUNTRY_DICTIONARY } from 'modules/shared/services/countriesDictionary';
import { ReducedCountry } from 'modules/shared/domain/Country';

interface IISOCountries {
  ESP: string;
  ITA: string;
  HUN: string;
  NLD: string;
}

class CountriesService {
  private data: {
    language: string;
    countries: IISOCountries;
  }[];
  private localeService: LocaleService;
  constructor({ dictionary, localeService }) {
    this.data = dictionary;
    this.localeService = localeService;
  }

  private _getCountriesAccordingToLang(lang) {
    return this.data.find((group) => group.language === lang)?.countries;
  }

  getCountryNameByISOCode(ISOCode) {
    const language = this.localeService.getLanguage();
    const countries = this._getCountriesAccordingToLang(language);
    if (countries?.[ISOCode]) {
      return countries[ISOCode];
    }
    return ISOCode;
  }

  getAllAvailableCountries(): ReducedCountry[] {
    const language = this.localeService.getLanguage();
    const countries = this._getCountriesAccordingToLang(language);
    return countries
      ? Object.keys(countries).map((iso) => ({
          name: countries[iso],
          iso
        }))
      : [];
  }
}

export { CountriesService };
export default new CountriesService({
  dictionary: COUNTRY_DICTIONARY,
  localeService: localeService
});
