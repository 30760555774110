class TransfersFiltersDataDTO {
  basic_containers: string[];
  supply_containers: string[];
  warehouse_types: string[];
  warehouses: string[];
  transfers_categories: string[];
  supply_types: string[];

  constructor({
    basic_containers,
    supply_containers,
    warehouse_types,
    warehouses,
    transfers_categories,
    supply_types
  }) {
    this.basic_containers = basic_containers ?? [];
    this.supply_containers = supply_containers ?? [];
    this.warehouse_types = warehouse_types ?? [];
    this.warehouses = warehouses ?? [];
    this.transfers_categories = transfers_categories ?? [];
    this.supply_types = supply_types ?? [];
  }
}

export default TransfersFiltersDataDTO;
