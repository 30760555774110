import { createSlice } from '@reduxjs/toolkit';
import { generateExpirationTableColumnsInfo } from 'modules/inbound/expiration/domain/generateExpirationTableColumnsInfo';
import { HandlingUnits } from 'modules/inbound/expiration/domain/HandlingUnitsData';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import {
  getExpirationsData,
  getExpirationsDownload,
  getHandlingUnits
} from './async';
import { ExpirationDataState } from './types';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';

const initialState: ExpirationDataState = {
  lastUpdate: '',
  expirations: [],
  targetUids: [],
  expirationsRequestStatus: '',
  columns: generateExpirationTableColumnsInfo(),
  savedColumns: [],
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: '',
    isAscending: false
  },
  handlingUnits: HandlingUnits.generateEmpty().handlingUnits,
  handlingUnitsStatus: '',
  expirationsFile: null,
  downloadRequestStatus: ''
};

const EXPIRATION_COLUMNS_VIEW_STATE_KEY = 'ExpirationColumnsView';

const expirationSlice = createSlice({
  name: 'expirations',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${EXPIRATION_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: EXPIRATION_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsOrder: (state, action) => {
      state.savedColumns = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getExpirationsData.fulfilled, (state, action) => {
      state.lastUpdate = action.payload.lastUpdate;
      state.expirations = action.payload.expirations || [];
      state.targetUids = action.payload.targetUids;
      state.pagination = action.payload.pagination;
      state.expirationsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationsData.pending, (state, action) => {
      state.expirationsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationsData.rejected, (state, action) => {
      state.expirationsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getHandlingUnits.fulfilled, (state, action) => {
      state.handlingUnits = action.payload.handlingUnits;
      state.handlingUnitsStatus = action.meta.requestStatus;
    });
    builder.addCase(getHandlingUnits.pending, (state, action) => {
      state.handlingUnitsStatus = action.meta.requestStatus;
    });
    builder.addCase(getHandlingUnits.rejected, (state, action) => {
      state.handlingUnitsStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationsDownload.fulfilled, (state, action) => {
      state.expirationsFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_Expirations_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationsDownload.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationsDownload.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getLastUpdate = (state) => state.expirationsDataState.lastUpdate;
export const getExpirations = (state) => state.expirationsDataState.expirations;
export const getColumns = (state) => state.expirationsDataState.savedColumns;
export const getPageInfo = (state) => state.expirationsDataState.pagination;
export const getCurrentPageUids = (state) =>
  state.expirationsDataState.targetUids;

export const getExpirationsDataIsPending = (state) =>
  state.expirationsDataState.expirationsRequestStatus === 'pending';
export const getExpirationsDataHasError = (state) =>
  state.expirationsDataState.expirationsRequestStatus === 'rejected';
export const getSelectedColumn = (state) =>
  state.expirationsDataState.selectedColumn;
export const getHandlingUnitsData = (state) =>
  state.expirationsDataState.handlingUnits;
export const getIsHUPending = (state) =>
  state.expirationsDataState.handlingUnitsStatus === 'pending';
export const getHasHUError = (state) =>
  state.expirationsDataState.handlingUnitsStatus === 'rejected';
export const getDownloadExpirationsSuccessful = (state) =>
  state.expirationsDataState.downloadRequestStatus === 'fulfilled' &&
  state.expirationsDataState.expirationsFile !== null;
export const getDownloadExpirationsError = (state) =>
  state.expirationsDataState.downloadRequestStatus === 'rejected';
export const getDownloadExpirationsIsPending = (state) =>
  state.expirationsDataState.downloadRequestStatus === 'pending';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  resetDownloadStatus,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  updateColumnsOrder
} = expirationSlice.actions;
export default expirationSlice.reducer;
