import DeliveriesGeolocationDTO from 'modules/outbound/fulfillment/infrastructure/dto/DeliveriesGeolocationDTO';
import DeliveriesGeolocation from '../domain/DeliveriesGeolocation';
import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';

class DeliveriesGeolocationService {
  private _deliveriesGeolocationRepository: ITransitRepository;

  constructor({ deliveriesGeolocationRepository }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
  }

  getAll({ queryParams }: { queryParams: object }) {
    return this._deliveriesGeolocationRepository
      .get({ queryParams })
      .then((res) =>
        res
          .map((item) => this._mapToDeliveriesGeolocation(item))
          .sort(this._sortByPercentage)
      )
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToDeliveriesGeolocation(
    dto: DeliveriesGeolocationDTO
  ): DeliveriesGeolocation {
    return DeliveriesGeolocation.generateFromDTO(dto);
  }

  private _sortByPercentage(
    firstItem: DeliveriesGeolocation,
    secondItem: DeliveriesGeolocation
  ) {
    return secondItem.cumulativePercentage - firstItem.cumulativePercentage;
  }
}

export default new DeliveriesGeolocationService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository
});

export { DeliveriesGeolocationService };
