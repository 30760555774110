import { EndOfProductionDateStockDTO } from '../infrastructure/dto/EndOfProductionDateDataDTO';
import { EndOfStockSummaryType } from './EndOfProductionSummary';

class EndOfProductionStock {
  amountOrdered: string;
  blockedMaterial: string;
  code: string;
  consumption: string;
  cost: string;
  description: string;
  endOfConsumptionDate: string;
  endOfStockTypes: EndOfStockSummaryType[];
  expirationDate: string;
  lastEntry: string;
  lastOrderDate: string;
  leftoverStock: string;
  leftoverStockCost: string;
  materialInTransit: string;
  models: string;
  percentageAsignements: string;
  providerIds: number[] | string[];
  providerInbounders: string[];
  providerNames: string[];
  status: string[];
  stock: string;
  stockVolume: string;
  stucklisteModels: string;
  termins: string[] | string;
  warehouseCodes: string;

  constructor({
    amountOrdered,
    blockedMaterial,
    code,
    consumption,
    cost,
    description,
    endOfConsumptionDate,
    endOfStockTypes,
    expirationDate,
    lastEntry,
    lastOrderDate,
    leftoverStock,
    leftoverStockCost,
    materialInTransit,
    models,
    percentageAsignements,
    providerIds,
    providerInbounders,
    providerNames,
    status,
    stock,
    stockVolume,
    stucklisteModels,
    termins,
    warehouseCodes
  }) {
    this.amountOrdered = amountOrdered;
    this.blockedMaterial = blockedMaterial;
    this.code = code;
    this.consumption = consumption;
    this.cost = cost;
    this.description = description;
    this.endOfConsumptionDate = endOfConsumptionDate;
    this.endOfStockTypes = endOfStockTypes;
    this.expirationDate = expirationDate;
    this.lastEntry = lastEntry;
    this.lastOrderDate = lastOrderDate;
    this.leftoverStock = leftoverStock;
    this.leftoverStockCost = leftoverStockCost;
    this.materialInTransit = materialInTransit;
    this.models = models;
    this.percentageAsignements = percentageAsignements;
    this.providerIds = providerIds;
    this.providerInbounders = providerInbounders;
    this.providerNames = providerNames;
    this.status = status;
    this.stock = stock;
    this.stockVolume = stockVolume;
    this.stucklisteModels = stucklisteModels;
    this.termins = termins;
    this.warehouseCodes = warehouseCodes;
  }

  private static _removeDuplicatedItems(array) {
    if (!array.length) {
      return ['-'];
    }
    return array.filter((item, index) => array.indexOf(item) === index);
  }

  private static _formatThousandsDot(quantity) {
    if (quantity === null) {
      return '-';
    }
    return quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });
  }

  private static _calculateEndOfConsumptionDateDays(date: string | null) {
    const HORIZON_DAYS = 75;
    if (date) {
      const now = new Date().getTime();
      const endOfConsumptionTime = new Date(date).getTime();
      const daysToEndConsumptionDate = Math.floor(
        (endOfConsumptionTime - now) / (1000 * 60 * 60 * 24)
      );
      if (
        endOfConsumptionTime > now &&
        daysToEndConsumptionDate > HORIZON_DAYS
      ) {
        return 'MAX';
      }
      return date;
    }
    return '-';
  }

  static generateFromDTO(
    dto: EndOfProductionDateStockDTO
  ): EndOfProductionStock {
    return new EndOfProductionStock({
      amountOrdered: this._formatThousandsDot(dto.amount_ordered),
      blockedMaterial:
        dto.blocked_material! >= 0
          ? this._formatThousandsDot(dto.blocked_material)
          : '-',
      code: dto.code,
      consumption: this._formatThousandsDot(dto.consumption),
      cost: this._formatThousandsDot(dto.cost) + ' €',
      description: dto.description ? dto.description : '-',
      endOfConsumptionDate: this._calculateEndOfConsumptionDateDays(
        dto.end_of_consumption_date
      ),
      endOfStockTypes: dto.end_of_stock_types ?? [],
      expirationDate: dto.expiration_date ?? '-',
      lastEntry: dto.last_entry
        ? `${dto.last_entry.date} | ${dto.last_entry.provider_delivery_note}`
        : '-',
      lastOrderDate: dto.last_order_date ?? '-',
      leftoverStock: this._formatThousandsDot(dto.leftover_stock),
      leftoverStockCost:
        this._formatThousandsDot(dto.leftover_stock_cost) + ' €',
      materialInTransit: this._formatThousandsDot(dto.material_in_transit),
      models: dto.models ? dto.models.replaceAll('|', ' | ') : '-',
      percentageAsignements: dto.percentage_asignements?.length
        ? dto.percentage_asignements.join(' | ')
        : '-',
      providerIds: dto.provider_ids?.length ? dto.provider_ids : ['-'],
      providerInbounders: dto.provider_inbounders?.length
        ? dto.provider_inbounders
        : ['-'],
      providerNames: dto.provider_names?.length ? dto.provider_names : ['-'],
      status: this._removeDuplicatedItems(dto.status),
      stock: this._formatThousandsDot(dto.stock),
      stockVolume: dto.stock_volume
        ? `${this._formatThousandsDot(dto.stock_volume)} \u{33A5}`
        : '-',
      stucklisteModels: dto.stuckliste_models.join(' | ') || '-',
      termins: dto.termins.length ? dto.termins : '-',
      warehouseCodes: dto.warehouse_codes.join(' | ') || '-'
    });
  }
}

export { EndOfProductionStock };
