import IUsersRepository from '../domain/IUsersRepository';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class CreateUserDataService {
  private readonly _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute(body) {
    return this._usersRepository.post(body).then((res) => res);
  }
}

export default new CreateUserDataService({
  repository: usersRepository
});

export { CreateUserDataService };
