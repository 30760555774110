import IJitRepository from '../domain/IJitRepository';
import JitFamilies from '../domain/FamiliesCoverageData';
import JitRepository from '../infrastructure/repositories/JitRepository';

class GetFamiliesCoverageService {
  private _jitRepository: IJitRepository;

  constructor({ jitRepository }) {
    this._jitRepository = jitRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._jitRepository
      .getFamilies({ queryParams })
      .then((res) => JitFamilies.generateFromDTO(res));
  }
}

export default new GetFamiliesCoverageService({
  jitRepository: JitRepository
});

export { GetFamiliesCoverageService };
