import { DeliveryStatuses } from '../domain/DeliveryStatusesData';
import { INoJitRepository } from '../domain/INoJitRepository';
import NoJitRepository from '../infrastructure/repositories/NoJitRepository';

class GetDeliveryStatusesUseCase {
  private _nojitRepository: INoJitRepository;

  constructor({ nojitRepository }) {
    this._nojitRepository = nojitRepository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<DeliveryStatuses> {
    return this._nojitRepository.getDeliveryStatuses({ queryParams });
  }
}

const getDeliveryStatusesUseCase = new GetDeliveryStatusesUseCase({
  nojitRepository: NoJitRepository
});

export { GetDeliveryStatusesUseCase, getDeliveryStatusesUseCase };
