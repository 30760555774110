import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import DirectDeliveryDataDTO, {
  DirectDeliveryItemDTO
} from '../infrastructure/dto/DirectDeliveryDataDTO';
import {
  IPagination,
  Pagination
} from 'modules/inbound/shared/domain/IPagination';
import { generatePlanningDatesColumns } from './generatePlanningDatesColumns';
import { DirectDelivery } from './DirectDelivery';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';

interface DirectDeliveriesData {
  stockUpdatedAt: string;
  assemblyNeedsUpdatedAt: string;
  bodyworkNeedsUpdatedAt: string;
  planningDates: string[];
  directDeliveries: DirectDelivery[];
  planningColumns: ColumnProps[];
  pagination: IPagination;
  targetUids: string[];
  targetProviderIds: number[];
}

export const directDeliveriesMapper = (
  dto: DirectDeliveryDataDTO
): DirectDeliveriesData => {
  const getProviderIds = (
    directDeliveriesDTO: DirectDeliveryItemDTO[]
  ): number[] => {
    const providerIds = directDeliveriesDTO.map((item) => item.provider_id);

    return providerIds.filter(function (item, index) {
      return providerIds.indexOf(item) === index;
    });
  };

  return {
    stockUpdatedAt: dto.stock_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.stock_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(dto.stock_updated_at)}`
      : '-',
    assemblyNeedsUpdatedAt: dto.assembly_needs_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.assembly_needs_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          dto.assembly_needs_updated_at
        )}`
      : '-',
    bodyworkNeedsUpdatedAt: dto.bodywork_needs_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.bodywork_needs_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          dto.bodywork_needs_updated_at
        )}`
      : '-',
    targetUids: dto.direct_deliveries.map((item) => item.uid) ?? [],
    targetProviderIds: getProviderIds(dto.direct_deliveries) ?? [],
    planningDates: dto.planning_dates ?? [],
    planningColumns: generatePlanningDatesColumns(
      dto.planning_dates,
      dto.direct_deliveries[0]?.planning[0]?.shifts.length ?? 3
    ),
    directDeliveries: dto.direct_deliveries?.map((ddDto) =>
      DirectDelivery.generateFromDTO(ddDto)
    ),
    pagination: Pagination.generateFromDTO(dto.pagination)
  };
};
