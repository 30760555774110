import { createAsyncThunk } from '@reduxjs/toolkit';
import getJitFamilyCoverageService from 'modules/inbound/jit/application/GetJitFamilyCoverageService';

const getJitFamilyCoverage = createAsyncThunk(
  `jitFamilyCoverageData/getJitFamilyCoverage`,
  async () => {
    const response = await getJitFamilyCoverageService.execute();
    return response;
  }
);

export { getJitFamilyCoverage };
