import { IDeliveryTimeRepository } from 'modules/outbound/deliveryTime/domain/IDeliveryTimeRepository';
import DeliveryTimeSlotsRangeDetails from 'modules/outbound/deliveryTime/domain/DeliveryTimeSlotsRangeDetails';
import deliveryTimeRepository from 'modules/outbound/deliveryTime/infrastructure/repositories/DeliveryTimeRepository';

class GetDeliveryTimeSlotsDetailsUseCase {
  private _repository: IDeliveryTimeRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<DeliveryTimeSlotsRangeDetails> {
    return this._repository
      .getDeliveryTimeSlotsRangeDetails({ queryParams })
      .then((res) => DeliveryTimeSlotsRangeDetails.generateFromDTO(res));
  }
}

export default new GetDeliveryTimeSlotsDetailsUseCase({
  repository: deliveryTimeRepository
});

export { GetDeliveryTimeSlotsDetailsUseCase };
