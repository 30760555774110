import EntriesFiltersDTO from '../../infrastructure/dto/NextHoursDTO/EntriesFiltersDTO';

export interface EntriesFilters {
  warehouses: string[];
  supplierNames: string[];
  unloadingPoints: string[];
}

class EntriesFiltersData {
  warehouses: string[];
  supplierNames: string[];
  unloadingPoints: string[];

  constructor({ warehouses, supplierNames, unloadingPoints }: EntriesFilters) {
    this.warehouses = warehouses;
    this.supplierNames = supplierNames;
    this.unloadingPoints = unloadingPoints;
  }

  static generateFromDTO({
    warehouses,
    supplier_names,
    unloading_points
  }: EntriesFiltersDTO): EntriesFilters {
    return new EntriesFiltersData({
      warehouses,
      supplierNames: supplier_names,
      unloadingPoints: unloading_points
    });
  }

  static generateEmpty() {
    return new EntriesFiltersData({
      warehouses: [],
      supplierNames: [],
      unloadingPoints: []
    });
  }
}

export default EntriesFiltersData;
