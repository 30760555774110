import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import localeService, {
  LocaleService
} from 'infrastructure/i18n/LocaleService';

class DownloadGeolocationFinishedGoodsService {
  private _deliveriesGeolocationRepository: ITransitRepository;
  private localeService: LocaleService;

  constructor({ deliveriesGeolocationRepository, localeService }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
    this.localeService = localeService;
  }

  execute({ queryParams }: { queryParams: object }) {
    queryParams = { ...queryParams, lang: this.localeService.getLanguage() };
    return this._deliveriesGeolocationRepository.downloadFinishedGoods!({
      queryParams
    });
  }
}

export default new DownloadGeolocationFinishedGoodsService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository,
  localeService
});

export { DownloadGeolocationFinishedGoodsService };
