import { createSlice } from '@reduxjs/toolkit';
import { BarStatus } from '../NoJitDataReducer/types';
import { getNoJitFilters } from './async';
import { FiltersReducerProps } from './types';

export const initialState: FiltersReducerProps = {
  filters: {
    countries: [],
    lines: [],
    providers: [],
    inbounders: [],
    models: [],
    workshops: [],
    warehouses: []
  },
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedCountries: [],
    selectedLines: [],
    selectedProvidersId: [],
    selectedInbounders: [],
    selectedModels: [],
    selectedWarehouses: []
  },
  search: '',
  chartFilters: {
    selectedCoverageAtRisk: {
      status: '',
      name: ''
    }
  },
  chartFiltersActive: false,
  isFilterSaved: false
};

const NOJIT_FILTERS_STATE_KEY = 'NoJitFiltersState';

const noJitFiltersSlice = createSlice({
  name: 'noJitFilters',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedCountries: (state, action) => {
      state.selectedSubHeaderFilters.selectedCountries = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedInbounders: (state, action) => {
      state.selectedSubHeaderFilters.selectedInbounders = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedProvidersId: (state, action) => {
      state.selectedSubHeaderFilters.selectedProvidersId = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehouses = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedNoJitCoverageAtRisk: (state, action) => {
      state.chartFilters.selectedCoverageAtRisk = action.payload;
      state.chartFiltersActive = !!action.payload;
    },
    resetChartFilters: (state) => {
      if (state.chartFiltersActive) {
        state.selectedSubHeaderFilters.selectedModels =
          initialState.selectedSubHeaderFilters.selectedModels;
        state.chartFilters.selectedCoverageAtRisk =
          initialState.chartFilters.selectedCoverageAtRisk;
        state.chartFiltersActive = initialState.chartFiltersActive;
      }
      state.isFilterSaved = false;
    },
    resetFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      state.chartFilters = initialState.chartFilters;
      state.chartFiltersActive = initialState.chartFiltersActive;
      localStorage.removeItem(`${action.payload}-${NOJIT_FILTERS_STATE_KEY}`);
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${NOJIT_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${NOJIT_FILTERS_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        state.selectedSubHeaderFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;
        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNoJitFilters.fulfilled, (state, action) => {
      state.filters.countries = action.payload.countries;
      state.filters.lines = action.payload.lines;
      state.filters.providers = action.payload.providers;
      state.filters.inbounders = action.payload.inbounders;
      state.filters.models = action.payload.models;
      state.filters.workshops = action.payload.workshops;
      state.filters.warehouses = action.payload.warehouses;
    });
    builder.addCase(getNoJitFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getAllFilters = (state) => state.noJitFiltersState.filters;
export const getSelectedNoJitCoverageAtRisk = (state) =>
  state.noJitFiltersState.chartFilters;
export const getSelectedtNoJitCoverageAtRiskUI = (state) => {
  return {
    top: state.noJitFiltersState.chartFilters.selectedCoverageAtRisk.name,
    color:
      BarStatus[
        state.noJitFiltersState.chartFilters.selectedCoverageAtRisk.status
      ]
  };
};
export const getFilterSearch = (state) => state.noJitFiltersState.search;
export const getSelectedHeaderFilters = (state) =>
  state.noJitFiltersState.selectedSubHeaderFilters;
export const getIsChartFilterActive = (state) =>
  state.noJitFiltersState.chartFiltersActive;
export const getIsFilterSaved = (state) =>
  state.noJitFiltersState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.noJitFiltersState.selectedSubHeaderFilters).some(
    (key) => state.noJitFiltersState.selectedSubHeaderFilters[key].length
  );

export const {
  updateFilterSearch,
  updateSelectedCountries,
  updateSelectedInbounders,
  updateSelectedModels,
  updateSelectedProvidersId,
  updateSelectedLines,
  updateSelectedWarehouses,
  updateSelectedNoJitCoverageAtRisk,
  resetChartFilters,
  resetFilters,
  loadFilters,
  saveFilters
} = noJitFiltersSlice.actions;

export default noJitFiltersSlice;
