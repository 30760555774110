import CoverageAtRisk from 'modules/shared/domain/CoverageAtRisk';
import { DirectDelivery } from 'modules/inbound/directDelivery/domain/DirectDelivery';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { ColumnSelected } from 'modules/shared/layout/components/TableHeader/types';

export enum COLORS {
  RED = 'red700',
  YELLOW = 'yellow700',
  GREEN = 'green700'
}

export enum CoverageStatusType {
  CRITICAL = 'CRITICAL',
  COVERED = 'COVERED',
  SECURITY = 'SECURITY'
}

export const BarStatus = {
  [CoverageStatusType.CRITICAL]: COLORS.RED,
  [CoverageStatusType.COVERED]: COLORS.GREEN,
  [CoverageStatusType.SECURITY]: COLORS.YELLOW
};

export const SelectedStatusColor = {
  [COLORS.RED]: CoverageStatusType.CRITICAL,
  [COLORS.GREEN]: CoverageStatusType.COVERED,
  [COLORS.YELLOW]: CoverageStatusType.SECURITY
};

export interface DirectDeliveryState {
  data: {
    stockUpdatedAt: string;
    assemblyNeedsUpdatedAt: string;
    bodyworkNeedsUpdatedAt: string;
    planningDates: string[];
    directDeliveries: DirectDelivery[];
    coverageAtRisk: CoverageAtRisk;
    uids: string[];
    providerIds: number[];
  };
  columns: ColumnProps[];
  planningColumns: ColumnProps[];
  savedColumns: ColumnProps[];
  requestStatus: string;
  requestCoverageAtRiskStatus: string;
  selectedColumn: ColumnSelected;
  pagination: IPagination;
  selectedDays: number;
  directDeliveryFile: any;
  downloadRequestStatus: string;
}
