import { createAsyncThunk } from '@reduxjs/toolkit';
import getWarehouseCapacityBodyService from 'modules/inbound/maestros/masters/application/GetWarehouseCapacityBodyService';
import getWarehouseCapacityDataService from 'modules/inbound/maestros/masters/application/GetWarehouseCapacityService';
import { getCurrentPage, getResultsPerPage } from '.';
import { WarehouseCapacityItem } from '../../../domain/WarehouseCapacityData';

const getWarehouseCapacityBody = createAsyncThunk(
  `warehouseCapacity/getWarehouseCapacityBody`,
  async () => {
    const response = await getWarehouseCapacityBodyService.execute();
    return response;
  }
);
const getWarehouseCapacityData = createAsyncThunk(
  `warehouseCapacity/getWarehouseCapacityData`,
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const queryParams = {
      page: currentPage,
      size
    };

    const response = await getWarehouseCapacityDataService.execute({
      queryParams
    });
    return response;
  }
);

const updateWarehouseCapacity = createAsyncThunk(
  `warehouseCapacity/updateWarehouseCapacity`,

  async (body: WarehouseCapacityItem) => {
    const camelToSnakeCase = (key) => {
      return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    };
    const parseValue = (key) => {
      let parsedKey = Number(key);
      if (isNaN(parsedKey)) {
        return key;
      }
      return parsedKey;
    };

    let newBody = {};
    Object.keys(body).forEach(
      (key) => (newBody[camelToSnakeCase(key)] = parseValue(body[key]))
    );

    const response = await getWarehouseCapacityDataService.update(newBody);
    return response;
  }
);

export {
  getWarehouseCapacityBody,
  getWarehouseCapacityData,
  updateWarehouseCapacity
};
