import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentPage, getResultsPerPage } from '.';
import GetLineGroupsBodyUseCase from '../../../application/GetLineGroupsBodyUseCase';
import GetLineGroupsUseCase from '../../../application/GetLineGroupsUseCase';

const getLineGroupsBody = createAsyncThunk(
  `lineGroups/getLineGroupsBody`,
  async () => {
    const response = await GetLineGroupsBodyUseCase.execute();
    return response;
  }
);
const getLineGroupsData = createAsyncThunk(
  `lineGroups/getLineGroupsData`,
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const queryParams = {
      page: currentPage,
      size
    };

    const response = await GetLineGroupsUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getLineGroupsBody, getLineGroupsData };
