import { createAsyncThunk } from '@reduxjs/toolkit';
import getFactoryBcnFiltersUseCase from 'modules/inbound/factoryBcn/application/GetFactoryBcnFiltersUseCase';

export const getFactoryBcnFilters = createAsyncThunk(
  'factoryBcnFilters/getFactoryBcnFilters',
  async () => {
    const response = await getFactoryBcnFiltersUseCase.execute();
    return response;
  }
);
