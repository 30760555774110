export interface IForecast {
  weekNumber: number;
  inputs: number;
  outputs: number;
  stock: number;
  saturationPercentage: number;
}
export interface IWarehouseForecastByModel {
  forecast: IForecast[];
  maxCapacity: number;
}

interface IForecastDTO {
  weekNumber: number;
  inputs: number;
  outputs: number;
  stock: number;
}
interface GenerateFromDTOParams {
  forecast: IForecastDTO[];
  maxCapacity: number;
}

class WarehouseForecastByModel implements IWarehouseForecastByModel {
  forecast: IForecast[];
  maxCapacity: number;

  constructor({ forecast, maxCapacity }: WarehouseForecastByModel) {
    this.forecast = forecast;
    this.maxCapacity = maxCapacity;
  }

  static generateFromDTO({
    forecast,
    maxCapacity
  }: GenerateFromDTOParams): WarehouseForecastByModel {
    return new WarehouseForecastByModel({
      forecast: forecast.map((item) => ({
        weekNumber: item.weekNumber,
        inputs: item.inputs,
        outputs: item.outputs,
        stock: item.stock,
        saturationPercentage: Math.round((item.stock / maxCapacity) * 100)
      })),
      maxCapacity
    });
  }

  static generateEmpty() {
    return new WarehouseForecastByModel({
      forecast: [
        {
          weekNumber: 0,
          inputs: 0,
          outputs: 0,
          stock: 0,
          saturationPercentage: 0
        }
      ],
      maxCapacity: 0
    });
  }
}

export default WarehouseForecastByModel;
