import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface FactoryBCNFilters {
  [key: string]: string[];
}

export interface FactoryBCNSearch {
  [key: string]: string[] | string;
}

const isValid = (value) => {
  return Array.isArray(value) && value.length > 0;
};

export const factoryBCNSearchQueryBuilder = (
  search: FactoryBCNSearch
): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.CODE:
          queryString += values
            .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
        case NameValues.DESCRIPTION:
          queryString += values
            .map(
              (value) =>
                `${NameValues.DESCRIPTION}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
      }

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};

export const factoryBCNFiltersQueryBuilder = (
  filters: FactoryBCNFilters
): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      queryString +=
        values.length > 1
          ? `${name}${SearchOperators.IN}(${values.join(';')})`
          : `${name}${SearchOperators.EQUAL}${values[0]}`;
    }

    queryString += '||';
  });
  return queryString.slice(0, -2);
};
