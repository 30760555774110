import { MODELS_AND_BRAND_DICTIONARY } from 'modules/outbound/stock/services/modelAndBrandDictionary';
import { BRANDS_DICTIONARY } from 'modules/outbound/stock/services/brandsDictionary';
import PipelineStockBase from './PipelineStockBase';
export interface IAgingIntervals {
  first: IAgingIntervalsValues;
  second: IAgingIntervalsValues;
  third: IAgingIntervalsValues;
  fourth: IAgingIntervalsValues;
  total: IAgingIntervalsValues;
}
export interface StockStatus {
  valid: boolean;
  color: string;
}
export interface IAgingIntervalsValues {
  value: number;
  percentage: number;
  status: StockStatus;
}

export interface IInTransitAging {
  invoicedIntervalValues: IAgingIntervals;
  logisticIntervalValues: IAgingIntervals;
  totalIntervalValues: IAgingIntervals;
}
export interface IInvoicedAging {
  warehouseIntervalValues: IAgingIntervals;
  dealerIntervalValues: IAgingIntervals;
  restIntervalValues: IAgingIntervals;
  invoicedTotalIntervalValues: IAgingIntervals;
}
export interface ISimpleCoverage {
  invoicedValue: number;
  invoicedPercentage: number;
  logisticValue: number;
  logisticPercentage: number;
  totalValue: number;
  totalPercentage: number;
}
export interface IPipelineStock {
  id: string;
  model: string;
  brand: string;
  stockTotal: number;
  image: { src: string; alt: string };
  inTransitAging: IInTransitAging;
  invoicedAging: IInvoicedAging;
  simpleCoverage: ISimpleCoverage;
}

interface IAgingIntervalsDTO {
  '0to90': number;
  '91to120': number;
  '121to180': number;
  moreThan180: number;
}

interface GenerateFromDTOParams {
  model: string;
  brand: string;
  stockTotal: number;
  inTransitAging: {
    invoicedIntervalValues: IAgingIntervalsDTO;
    logisticIntervalValues: IAgingIntervalsDTO;
    totalIntervalValues: IAgingIntervalsDTO;
  };
  invoicedAging: {
    warehouseIntervalValues: IAgingIntervalsDTO;
    dealerIntervalValues: IAgingIntervalsDTO;
    restIntervalValues: IAgingIntervalsDTO;
    invoicedTotalIntervalValues: IAgingIntervalsDTO;
  };
  simpleCoverage: {
    invoiced: number;
    logistic: number;
    total: number;
  };
}

class PipelineStock extends PipelineStockBase implements IPipelineStock {
  id: string;
  model: string;
  brand: string;
  stockTotal: number;
  image: { src: string; alt: string };
  inTransitAging: {
    logisticIntervalValues: IAgingIntervals;
    invoicedIntervalValues: IAgingIntervals;
    totalIntervalValues: IAgingIntervals;
  };
  invoicedAging: {
    warehouseIntervalValues: IAgingIntervals;
    dealerIntervalValues: IAgingIntervals;
    restIntervalValues: IAgingIntervals;
    invoicedTotalIntervalValues: IAgingIntervals;
  };
  simpleCoverage: {
    invoicedValue: number;
    invoicedPercentage: number;
    logisticValue: number;
    logisticPercentage: number;
    totalValue: number;
    totalPercentage: number;
  };

  constructor({
    id,
    model,
    brand,
    stockTotal,
    image,
    inTransitAging,
    invoicedAging,
    simpleCoverage
  }: PipelineStock) {
    super();
    this.id = id;
    this.model = model;
    this.brand = brand;
    this.stockTotal = stockTotal;
    this.image = image;
    this.inTransitAging = inTransitAging;
    this.invoicedAging = invoicedAging;
    this.simpleCoverage = simpleCoverage;
  }

  static getImageSrcFromModel(id: string) {
    const name = MODELS_AND_BRAND_DICTIONARY[id]?.imageName || 'backup';
    return `${process.env.PUBLIC_URL}/images/cars/${name}.png`;
  }

  static generateFromDTO({
    model,
    brand,
    stockTotal,
    inTransitAging: {
      invoicedIntervalValues,
      logisticIntervalValues,
      totalIntervalValues
    },
    invoicedAging: {
      warehouseIntervalValues,
      dealerIntervalValues,
      restIntervalValues,
      invoicedTotalIntervalValues
    },
    simpleCoverage: { invoiced, logistic, total }
  }: GenerateFromDTOParams): PipelineStock {
    const generateTotal = (object) =>
      Number(
        Object.keys(object).reduce((acumulator, currentvalue) => {
          return acumulator + object[currentvalue];
        }, 0)
      );

    const totalLogisticIntervalValues = generateTotal(logisticIntervalValues);
    const totalInvoicedIntervalValues = generateTotal(invoicedIntervalValues);
    const totalTotalIntervalValues = generateTotal(totalIntervalValues);
    const totalWarehouseIntervalValues = generateTotal(warehouseIntervalValues);
    const totalDealerIntervalValues = generateTotal(dealerIntervalValues);
    const totalRestIntervalValues = generateTotal(restIntervalValues);
    const totalInvoicedTotalIntervalValues = generateTotal(
      invoicedTotalIntervalValues
    );
    const id = `${brand}-${model}`;
    return new PipelineStock({
      id,
      model: MODELS_AND_BRAND_DICTIONARY[id]?.name || model,
      brand: BRANDS_DICTIONARY[brand] || model,
      stockTotal,
      image: {
        src: PipelineStock.getImageSrcFromModel(id),
        alt: MODELS_AND_BRAND_DICTIONARY[id]?.name
      },
      inTransitAging: {
        logisticIntervalValues: {
          first: {
            value: logisticIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: logisticIntervalValues['0to90'],
              total: totalLogisticIntervalValues
            })
          },
          second: {
            value: logisticIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: logisticIntervalValues['91to120'],
              total: totalLogisticIntervalValues
            })
          },
          third: {
            value: logisticIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: logisticIntervalValues['121to180'],
              total: totalLogisticIntervalValues
            })
          },
          fourth: {
            value: logisticIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: logisticIntervalValues.moreThan180,
              total: totalLogisticIntervalValues
            })
          },
          total: {
            value: totalLogisticIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalLogisticIntervalValues,
              total: totalLogisticIntervalValues
            })
          }
        },
        invoicedIntervalValues: {
          first: {
            value: invoicedIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: invoicedIntervalValues['0to90'],
              total: totalInvoicedIntervalValues
            })
          },
          second: {
            value: invoicedIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: invoicedIntervalValues['91to120'],
              total: totalInvoicedIntervalValues
            })
          },
          third: {
            value: invoicedIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: invoicedIntervalValues['121to180'],
              total: totalInvoicedIntervalValues
            })
          },
          fourth: {
            value: invoicedIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: invoicedIntervalValues.moreThan180,
              total: totalInvoicedIntervalValues
            })
          },
          total: {
            value: totalInvoicedIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalInvoicedIntervalValues,
              total: totalInvoicedIntervalValues
            })
          }
        },
        totalIntervalValues: {
          first: {
            value: totalIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: totalIntervalValues['0to90'],
              total: totalTotalIntervalValues
            })
          },
          second: {
            value: totalIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: totalIntervalValues['91to120'],
              total: totalTotalIntervalValues
            })
          },
          third: {
            value: totalIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: totalIntervalValues['121to180'],
              total: totalTotalIntervalValues
            })
          },
          fourth: {
            value: totalIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: totalIntervalValues.moreThan180,
              total: totalTotalIntervalValues
            })
          },
          total: {
            value: totalTotalIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalTotalIntervalValues,
              total: totalTotalIntervalValues
            })
          }
        }
      },
      invoicedAging: {
        warehouseIntervalValues: {
          first: {
            value: warehouseIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: warehouseIntervalValues['0to90'],
              total: totalWarehouseIntervalValues
            })
          },
          second: {
            value: warehouseIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: warehouseIntervalValues['91to120'],
              total: totalWarehouseIntervalValues
            })
          },
          third: {
            value: warehouseIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: warehouseIntervalValues['121to180'],
              total: totalWarehouseIntervalValues
            })
          },
          fourth: {
            value: warehouseIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: warehouseIntervalValues.moreThan180,
              total: totalWarehouseIntervalValues
            })
          },
          total: {
            value: totalWarehouseIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalWarehouseIntervalValues,
              total: totalWarehouseIntervalValues
            })
          }
        },
        dealerIntervalValues: {
          first: {
            value: dealerIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: dealerIntervalValues['0to90'],
              total: totalDealerIntervalValues
            })
          },
          second: {
            value: dealerIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: dealerIntervalValues['91to120'],
              total: totalDealerIntervalValues
            })
          },
          third: {
            value: dealerIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: dealerIntervalValues['121to180'],
              total: totalDealerIntervalValues
            })
          },
          fourth: {
            value: dealerIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: dealerIntervalValues.moreThan180,
              total: totalDealerIntervalValues
            })
          },
          total: {
            value: totalDealerIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalDealerIntervalValues,
              total: totalDealerIntervalValues
            })
          }
        },
        restIntervalValues: {
          first: {
            value: restIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: restIntervalValues['0to90'],
              total: totalRestIntervalValues
            })
          },
          second: {
            value: restIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: restIntervalValues['91to120'],
              total: totalRestIntervalValues
            })
          },

          third: {
            value: restIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: restIntervalValues['121to180'],
              total: totalRestIntervalValues
            })
          },
          fourth: {
            value: restIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: restIntervalValues.moreThan180,
              total: totalRestIntervalValues
            })
          },
          total: {
            value: totalRestIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalRestIntervalValues,
              total: totalRestIntervalValues
            })
          }
        },
        invoicedTotalIntervalValues: {
          first: {
            value: invoicedTotalIntervalValues['0to90'],
            ...this.getPercentageAndStatus({
              key: 'first',
              value: invoicedTotalIntervalValues['0to90'],
              total: totalInvoicedTotalIntervalValues
            })
          },
          second: {
            value: invoicedTotalIntervalValues['91to120'],
            ...this.getPercentageAndStatus({
              key: 'second',
              value: invoicedTotalIntervalValues['91to120'],
              total: totalInvoicedTotalIntervalValues
            })
          },
          third: {
            value: invoicedTotalIntervalValues['121to180'],
            ...this.getPercentageAndStatus({
              key: 'third',
              value: invoicedTotalIntervalValues['121to180'],
              total: totalInvoicedTotalIntervalValues
            })
          },
          fourth: {
            value: invoicedTotalIntervalValues.moreThan180,
            ...this.getPercentageAndStatus({
              key: 'fourth',
              value: invoicedTotalIntervalValues.moreThan180,
              total: totalInvoicedTotalIntervalValues
            })
          },
          total: {
            value: totalInvoicedTotalIntervalValues,
            ...this.getPercentageAndStatus({
              key: 'total',
              value: totalInvoicedTotalIntervalValues,
              total: totalInvoicedTotalIntervalValues
            })
          }
        }
      },
      simpleCoverage: {
        invoicedValue: this._roundToTwoDecimals(invoiced),
        invoicedPercentage: Math.round((invoiced / COVERAGE_RANGE) * 100),
        logisticValue: this._roundToTwoDecimals(logistic),
        logisticPercentage: Math.round((logistic / COVERAGE_RANGE) * 100),
        totalValue: this._roundToTwoDecimals(total),
        totalPercentage: Math.round((total / COVERAGE_RANGE) * 100)
      }
    });
  }

  static generateEmpty() {
    const intervalsValues = {
      value: 0,
      percentage: 0,
      status: { valid: true, color: 'white' }
    };
    const intervals = {
      first: intervalsValues,
      second: intervalsValues,
      third: intervalsValues,
      fourth: intervalsValues,
      total: intervalsValues
    };

    return new PipelineStock({
      id: '',
      model: '',
      brand: '',
      stockTotal: 0,
      image: { src: '', alt: '' },
      inTransitAging: {
        logisticIntervalValues: intervals,
        invoicedIntervalValues: intervals,
        totalIntervalValues: intervals
      },
      invoicedAging: {
        warehouseIntervalValues: intervals,
        dealerIntervalValues: intervals,
        restIntervalValues: intervals,
        invoicedTotalIntervalValues: intervals
      },
      simpleCoverage: {
        invoicedValue: 0,
        invoicedPercentage: 0,
        logisticValue: 0,
        logisticPercentage: 0,
        totalValue: 0,
        totalPercentage: 0
      }
    });
  }

  private static _roundToTwoDecimals(number) {
    return Number(number.toFixed(2));
  }

  private static getPercentageAndStatus({
    key,
    value,
    total
  }: {
    key: string;
    value: number;
    total: number;
  }) {
    const percentage = Math.round((value / total) * 100) || 0;
    const isValid = percentage <= this.stockObjectives[key].percentage;
    const color = this.stockObjectivesWarningColors[key].color;
    return { percentage, status: { valid: isValid, color } };
  }
}

const COVERAGE_RANGE = 12;

export default PipelineStock;
