import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetGlobalDlzSummary {
  private _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<string> {
    return this._repository.getDlzSummary();
  }
}

const getGlobalDlzSummary = new GetGlobalDlzSummary({
  repository: entriesRepository
});

export { GetGlobalDlzSummary, getGlobalDlzSummary };
