import {
  CommentsTargetPanel,
  MessageResponse
} from 'modules/inbound/shared/domain/InboundMessages/InboundMessages';

export enum FeatureType {
  COMMENT = 'COMMENT',
  CONTACT = 'CONTACT',
  NONE = 'NONE'
}
export interface SelectedItem {
  uid: string;
  name: string;
  feature: FeatureType;
}
export interface InboundMessagesProps {
  messagesResponse: MessageResponse[];
  currentSelectedPanel: {
    targetType: CommentsTargetPanel | null;
    targetUids: string[];
  };
  newPostMessage: {
    targetType: CommentsTargetPanel | null;
    targetUid: string;
    content: string;
  };
  requestStatusMessages: string;
  requestStatusPostMessage: string;
  selectedItem: SelectedItem;
  selectedMessageUid: string;
  deleteResponse: any;
  requestStatusDelete: string;
}
