import IEntriesRepository from '../../domain/IEntriesRepository';
import { SaturationUnloadingPointsFilters } from '../../domain/UnloadingPoints/SaturationUnloadingPointsFilters';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetSaturationUnloadingPointsFiltersUseCase {
  private _entriesDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesDataRepository = repository;
  }

  execute(): Promise<SaturationUnloadingPointsFilters> {
    return this._entriesDataRepository.getSaturationUnloadingPointsFilters();
  }
}

const getSaturationUnloadingPointsFiltersUseCase =
  new GetSaturationUnloadingPointsFiltersUseCase({
    repository: entriesRepository
  });

export {
  GetSaturationUnloadingPointsFiltersUseCase,
  getSaturationUnloadingPointsFiltersUseCase
};
