import { UnloadingPointStateType } from 'modules/inbound/entries/infrastructure/dto/types';
import {
  BarColors,
  SaturationDataSet
} from '../../../components/SaturationChart/types';
import { EntriesTranslations } from 'modules/inbound/entries/infrastructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { EntriesText } from 'modules/inbound/entries/infrastructure/i18n/EntriesText';
import { UnloadingMetrics } from 'modules/inbound/entries/infrastructure/dto/UnloadingPointsDTO/UnloadingPointDTO';

const entriesTranslations: EntriesTranslations = {
  ...EntriesText[LocaleService.getLanguage()]
};

type UnloadingPointStateColors = {
  [key in UnloadingPointStateType]: BarColors;
};

type UnloadingPointStateTranslations = {
  [key in UnloadingPointStateType]: string;
};

export const UNLOADING_POINT_STATE_COLORS: UnloadingPointStateColors = {
  BOOKED: 'neutral50',
  COMPLETED: 'gray300',
  UNLOADING: 'orange700',
  ADDITIONAL: 'yellow700',
  IN_PLANT: 'yellow700'
};

export const UNLOADING_POINT_STATE_TRANSLATIONS: UnloadingPointStateTranslations =
  {
    BOOKED: entriesTranslations.planned,
    COMPLETED: entriesTranslations.executed,
    UNLOADING: entriesTranslations.unloading,
    ADDITIONAL: entriesTranslations.inPlant,
    IN_PLANT: entriesTranslations.inPlant
  };

interface TimeSlotSelected {
  time: string;
  date: string;
  unloadingPoint: string;
}

export interface UnloadingPointProps {
  name: string;
  containersUnloadingMetrics: UnloadingMetrics;
  entriesUnloadingMetrics: UnloadingMetrics;
  entriesMaxValue: number;
  containersMaxValue: number;
  entriesData: SaturationDataSet[];
  containersData: SaturationDataSet[];
  onTimeSlotClick?: (timeSlotSelected: TimeSlotSelected) => void;
}
