import { IEntriesRepository } from '../../domain/IEntriesRepository';
import IncomingMaterials from '../../domain/NextHours/IncomingMaterials';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetIncomingMaterialsUseCase {
  private _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<IncomingMaterials> {
    return this._repository
      .getIncomingMaterials({ queryParams })
      .then((res) => IncomingMaterials.generateFromDTO(res));
  }
}

export default new GetIncomingMaterialsUseCase({
  repository: entriesRepository
});

export { GetIncomingMaterialsUseCase };
