import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

class CallOffTrackingByCheckpointDTO {
  name: string;
  type: CheckpointType;
  delayedFinishedGoodsWithCallOff: number;
  totalFinishedGoodsWithCallOff: number;
  totalFinishedGoods: number;

  constructor({
    name,
    type,
    delayedFinishedGoodsWithCallOff,
    totalFinishedGoodsWithCallOff,
    totalFinishedGoods
  }) {
    this.name = name;
    this.type = type;
    this.delayedFinishedGoodsWithCallOff = delayedFinishedGoodsWithCallOff;
    this.totalFinishedGoodsWithCallOff = totalFinishedGoodsWithCallOff;
    this.totalFinishedGoods = totalFinishedGoods;
  }
}

export default CallOffTrackingByCheckpointDTO;
