export interface CriticalStockDetail {
  counter: number;
  status: string;
}

export interface CriticalStockDTO {
  stats: CriticalStockDetail[];
  total: number;
}

class SummaryCriticalStockDTO {
  stats: CriticalStockDetail[];
  total: number;

  constructor({ stats, total }) {
    this.stats = stats;
    this.total = total;
  }
}

export default SummaryCriticalStockDTO;
