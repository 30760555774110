import { ComponentType } from 'react';

/**
 *
 * Retry loading a chunk to avoid chunk load error for out of date code
 * !important This only works if there is only one react lazy import per route
 */
export const lazyRetry = function (
  componentImport: () => Promise<{
    default: ComponentType<any>;
  }>
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(LAZY_REFRESH_KEY) ?? 'false'
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(LAZY_REFRESH_KEY, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(LAZY_REFRESH_KEY, 'true');
          return window.location.reload();
        }
        reject(new Error(error));
      });
  });
};

const LAZY_REFRESH_KEY = 'retry-lazy-refreshed';
