import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

export default styled.button`
  padding: 0.5rem 1rem;
  border-radius: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.borders.extralarge};
  background-color: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.overlays.modal.rgb};
  color: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.colors.black50.hexa};
  font-size: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.fonts.regularSmall.maxSizeAsPixels};
  vertical-align: center;
  display: flex;
  align-items: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 0.8rem;
    position: relative;
  }

  &.basic {
    background-color: transparent;
  }

  &.grey {
    display: inline-block;
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.black.hexa};
    text-align: center;
    min-width: 80px;
  }

  &.active {
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.orange.rgb};
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.white.rgb};
  }

  &.success {
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.green.rgb};
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.white.rgb};
  }

  &.outlined {
    background-color: transparent;
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.orange.rgb};
    border: 1px solid
      ${(props: { theme: ThemeSEATProps }) => props.theme.colors.orange.rgb};
    svg .fillable {
      fill: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.orange.hexa};
    }
  }

  &.round {
    border-radius: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.borders.circle};

    svg {
      margin: 0;
    }
  }
`;
