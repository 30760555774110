import { Translation } from 'infrastructure/i18n/typing';
import { JitTranslations } from './typing';

export const JitText: Translation<JitTranslations> = {
  es: {
    menuTitle: 'JIT',
    partCoverage: 'Cobertura pieza',
    jitFamilyCoverages: 'Coberturas familia JIT',
    familyCoverage: 'Paro línea familia',
    emptyMessage: 'No hay datos disponibles.',
    productionPlanUpdate: 'Act. plan producción:',
    code: 'Clave',
    providerCode: 'Clave proveedor',
    conType: 'Tipo',
    providerName: 'Nombre prov.',
    providerCoverage: 'Cob. N2',
    providerId: 'Código prov.',
    line: 'Línea',
    dateOfForecastStoppage: 'Previsión de paro',
    models: 'Modelo',
    family: 'Familia',
    type: 'Tipo',
    typeTooltip:
      '<b>Tipos de flujos</b> de entrega de materiales <b>JIT</b>.<ul><li><b>Syncro:</b> El mismo T1 monta, secuencia y envía material a SEAT</li><li><b>No Syncro:</b> T1 monta y el Operador logístico secuencia y envía material a SEAT</li></ul>',
    margin: 'Tránsitos T2',
    OLName: 'Nombre OL',
    inbounder: 'Inbounder',
    sequencerCoverage: 'Stock T1/OL',
    unitSinglePiece: 'pza.',
    unitMultiplePieces: 'pzas.',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    modelMultiselectAvailable: 'Modelos disponibles',
    modelMultiselectSelected: 'Modelos seleccionados',
    modelMultiselectEmpty: 'No hay modelos disponibles',
    familyMultiselectAvailable: 'Familias disponibles',
    familyMultiselectSelected: 'Familias seleccionadas',
    familyMultiselectEmpty: 'No hay familias disponibles',
    providerIdsMultiselectAvailable: 'T1 disponibles',
    providerIdsMultiselectSelected: 'T1 seleccionados',
    providerIdsMultiselectEmpty: 'No hay T1 disponibles',
    sequencerCodeMultiselectAvailable: 'OL disponibles',
    sequencerCodeMultiselectSelected: 'OL seleccionados',
    sequencerCodeMultiselectEmpty: 'No hay OL disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionados',
    inbounderMultiselectEmpty: 'No hay inbounders disponibles',
    flowTypes: 'Tipo flujo',
    showMoreFilters: 'Ver más filtros',
    loadFilters: 'Cargar Filtros',
    coverageWithLabel: 'Cobertura con',
    T1Stock2Tooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>T1 stock/Operador logístico</b><ul><li>MAX = producción cubierta > 15 días</li></ul>',
    T2TransitsTooltip:
      'Hasta qué día y hora se <b>cubre</b> la producción con <b>stock del OL + tránsitos del T1 al OL</b><ul><li> MAX = producción cubierta > 15 días</li></ul>',
    all: 'Todos',
    amount: 'Cant.',
    deliveryTime: 'Llegada',
    providerDeliveryNote: 'Albarán',
    plateNumber: 'Matrícula',
    keyUnit: 'Conjunto: ',
    OL: 'OL',
    OLProvider: 'Operador logístico',
    OLProviderTooltip:
      '<b>Nombre del Operador Logístico</b> que secuencia y entrega a Martorell.<br/><br/> En los flujos Syncro no existe Operador logístico.',
    OLStock: 'Stock conjunto',
    OLUpdated: 'Act. Stock',
    OLUpdatedTooltip:
      '<b>Fecha última actualización del stock</b> comunicada por el Operador logístico.',
    T1: 'T1',
    provider: 'Proveedor',
    T1Provider: 'Proveedor T1',
    T1ProviderTooltip:
      '<b>Nombre del proveedor T1</b> que abastece a Martorell.<br/><br/> Si es Syncro secuencia y entrega el material a fábrica.',
    T1Stock: 'Stock conjunto',
    T1Updated: 'Act. Stock',
    T1UpdatedTooltip:
      '<b>Fecha última actualización del stock</b> comunicada por el T1',
    OLCode: 'Código OL'
  },
  en: {
    menuTitle: 'JIT',
    partCoverage: 'Part coverage',
    jitFamilyCoverages: 'JIT Family coverage',
    familyCoverage: 'Family line stop',
    emptyMessage: 'There is no data available.',
    productionPlanUpdate: 'Act. production plan:',
    code: 'Part',
    providerCode: 'Provider part',
    providerId: 'Provider code',
    conType: 'Type',
    providerName: 'Provider name',
    providerCoverage: 'Cov. N2',
    line: 'Line',
    dateOfForecastStoppage: 'Predicted stop date',
    models: 'Model',
    family: 'Family',
    type: 'Type',
    typeTooltip:
      '<b>Types of JIT</b> material delivery <b>flows</b>.<ul><li><b>Syncro</b>: T1 himself assembles, sequences and sends material to SEAT</li><li><b>No Syncro</b>: T1 assembles and the Logistics operator sequences and sends material to SEAT</li></ul>',
    margin: 'T2 transits',
    OLName: 'OL Name',
    inbounder: 'Inbounder',
    sequencerCoverage: 'T1/OL Stock',
    unitSinglePiece: 'pc',
    unitMultiplePieces: 'pcs',
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    modelMultiselectAvailable: 'Models available',
    modelMultiselectSelected: 'Models selected',
    modelMultiselectEmpty: "There aren't models available",
    familyMultiselectAvailable: 'Families available',
    familyMultiselectSelected: 'Families selected',
    familyMultiselectEmpty: "There aren't families available",
    providerIdsMultiselectAvailable: 'T1 available',
    providerIdsMultiselectSelected: 'T1 selected',
    providerIdsMultiselectEmpty: "There aren't T1 available",
    sequencerCodeMultiselectAvailable: 'OL available',
    sequencerCodeMultiselectSelected: 'OL selected',
    sequencerCodeMultiselectEmpty: "There aren't OL available",
    inbounderMultiselectAvailable: 'Inbounders available',
    inbounderMultiselectSelected: 'Inbounders selected',
    inbounderMultiselectEmpty: "There aren't inbounders available",
    flowTypes: 'Flow type',
    showMoreFilters: 'Show more filters',
    loadFilters: 'Load Filters',
    coverageWithLabel: 'Coverage with',
    T1Stock2Tooltip:
      'Until what day and time is production <b>covered</b> with <b>T1 stock / Logistics operator</b><ul><li>MAX = covered production > 15 days</li></ul>',
    T2TransitsTooltip:
      'Until what day and time is production <b>covered</b> with stock from the <b>OL + transits from T1 to OL</b><ul><li>MAX = covered production > 15 days</li></ul>',
    all: 'All',
    amount: 'Amt.',
    deliveryTime: 'Arrival',
    providerDeliveryNote: 'Prov. D. note',
    plateNumber: 'Plate number',
    keyUnit: 'Key unit: ',
    OL: 'LO',
    OLProvider: 'Logistic operator',
    OLProviderTooltip:
      '<b>Name of the Logistics operator</b> who sequences and delivers to Martorell.<br/><br/> In Syncro flows there is no Logistics operator.',
    OLStock: 'Stock key unit',
    OLUpdated: 'Stock upd.',
    OLUpdatedTooltip:
      '<b>Date of the last stock update</b> communicated by the Logistics operator.',
    T1: 'T1',
    provider: 'Provider',
    T1Provider: 'T1 Provider',
    T1ProviderTooltip:
      '<b>Name of the T1 supplier</b> that supplies Martorell.<br/><br/> If it is Syncro, sequence and deliver the material to the factory.',
    T1Stock: 'Key unit stock',
    T1Updated: 'Stock upd.',
    T1UpdatedTooltip: '<b>Date last stock update</b> reported by the T1',
    OLCode: 'OL Code'
  },
  ca: {
    menuTitle: 'JIT',
    partCoverage: 'Cobertura peça',
    jitFamilyCoverages: 'Cobertura família JIT',
    familyCoverage: 'Atur línia família',
    emptyMessage: 'No hi ha dades disponibles.',
    productionPlanUpdate: 'Act. pla producció:',
    code: 'Clau',
    providerCode: 'Clau proveïdor',
    providerId: 'Codi prov.',
    conType: 'Tipus',
    providerName: 'Nom prov.',
    providerCoverage: 'Cob. N2',
    line: 'Línia',
    dateOfForecastStoppage: 'Previsió de parada',
    models: 'Model',
    family: 'Familia',
    type: 'Tipus',
    typeTooltip:
      "<b>Syncro:</b> Proveïdor Tier 2 i Tier 1 són els mateixos, munta i seqüència. <br/><b>No Syncro:</b> Proveïdor Tier 2 munta i l'Operador logístic seqüència.",
    margin: 'Trànsits T2',
    OLName: 'Nom OL',
    inbounder: 'Inbounder',
    sequencerCoverage: 'Stock T1/OL',
    unitSinglePiece: 'peça',
    unitMultiplePieces: 'peces',
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    modelMultiselectAvailable: 'Models disponibles',
    modelMultiselectSelected: 'Models seleccionats',
    modelMultiselectEmpty: 'No hi ha models disponibles',
    familyMultiselectAvailable: 'Famílies disponibles',
    familyMultiselectSelected: 'Famílies seleccionades',
    familyMultiselectEmpty: 'No hi ha familíes disponibles',
    providerIdsMultiselectAvailable: 'T1 disponibles',
    providerIdsMultiselectSelected: 'T1 seleccionades',
    providerIdsMultiselectEmpty: 'No hi ha T1 disponibles',
    sequencerCodeMultiselectAvailable: 'OL disponibles',
    sequencerCodeMultiselectSelected: 'OL seleccionades',
    sequencerCodeMultiselectEmpty: 'No hi ha OL disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionats',
    inbounderMultiselectEmpty: 'No hi ha inbounders disponibles',
    flowTypes: 'Tipus flux',
    showMoreFilters: 'Veure més filtres',
    loadFilters: 'Carregar Filtres',
    coverageWithLabel: 'Cobertura amb',
    T1Stock2Tooltip:
      'Fins que dia i hora es <b>cobreix</b> la producció amb <b>T1 estoc / Operador logístic</b><ul><li> MAX = producció coberta > 15 dies</li></ul>',
    T2TransitsTooltip:
      "Fins a quin dia i hora es <b>cobreix</b> la producció amb estoc de l'<b>OL + trànsits del T1 a l'OL</b><ul><li>MAX = producció coberta > 15 dies</li></ul>",
    all: 'Tots',
    amount: 'Quant.',
    deliveryTime: 'Arribada',
    providerDeliveryNote: 'Albarà',
    plateNumber: 'Matrícula',
    keyUnit: 'Conjunt: ',
    OL: 'OL',
    OLProvider: 'Operador logístic',
    OLProviderTooltip:
      "<b>Nom de l'Operador logístic</b> que seqüencia i lliura a Martorell.<br/><br/>Als fluxos Syncro no hi ha Operador logístic.",
    OLStock: 'Estoc conjunt',
    OLUpdated: 'Act. estoc',
    OLUpdatedTooltip:
      "<b>Data darrera actualització de l'estoc</b> comunicada per l'Operador logístic.",
    T1: 'T1',
    provider: 'Proveïdor',
    T1Provider: 'Proveïdor T1',
    T1ProviderTooltip:
      '<b>Nom del proveïdor T1</b> que abasta Martorell.<br/><br/> Si és Syncro seqüència i lliura el material a fàbrica.',
    T1Stock: 'Estoc conjunt',
    T1Updated: 'Act. estoc',
    T1UpdatedTooltip:
      "<b>Data de la darrera actualització de l'estoc</b> comunicada pel T1",
    OLCode: 'Codi OL'
  }
};
