import { createAsyncThunk } from '@reduxjs/toolkit';
import getGeolocatedTransportsUseCase from 'modules/outbound/fulfillment/application/GetGeolocatedTransportsUseCase';
import getGeolocatedVesselsUseCase from '../../application/GetGeolocatedVesselsUseCase';
import DownloadVesselVinsUseCase from '../../application/DownloadVesselVinsUseCase';
import { getSelectedVessel } from '.';
import localeService from 'infrastructure/i18n/LocaleService';

const getTransports = createAsyncThunk(
  `getGeolocatedTransports/getTransports`,
  async () => {
    return getGeolocatedTransportsUseCase.execute();
  }
);
const getVessels = createAsyncThunk(
  `getGeolocatedVessels/getVessels`,
  async () => {
    return getGeolocatedVesselsUseCase.execute();
  }
);

const downloadVesselVins = createAsyncThunk(
  'getGeolocatedVessels/downloadVesselVins',
  async (_, { getState }) => {
    const appState = getState();
    const selectedVessel = getSelectedVessel(appState);
    const queryParams = {
      imo: selectedVessel.split('-')[0],
      lang: localeService.getLanguage()
    };
    const response = await DownloadVesselVinsUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getTransports, getVessels, downloadVesselVins };
