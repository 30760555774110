import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import callOffRepository from 'modules/outbound/callOff/infrastructure/repositories/CallOffRepository';
import CallOffFinishedGood from 'modules/outbound/callOff/domain/CallOffFinishedGood';
import { ICallOffFinishedGoodDTO } from 'modules/outbound/callOff/infrastructure/dto/CallOffFinishedGoodsListDTO';

class GetCallOffFinishedGoodsByWarehouseService {
  private _callOffRepository: ICallOffRepository;

  constructor({ repository }) {
    this._callOffRepository = repository;
  }

  execute({
    warehouseRef,
    queryParams
  }: {
    warehouseRef: string;
    queryParams: object;
  }) {
    return this._callOffRepository
      .getFinishedGoodsByWarehouse({
        warehouseRef,
        queryParams
      })
      .then((res) => ({
        warehouseRef,
        totalFinishedGoods: res.totalFinishedGoods,
        finishedGoods: res.finishedGoods.map((item) =>
          this._mapToFinishedGood(item)
        )
      }));
  }

  private _mapToFinishedGood(
    finishedGood: ICallOffFinishedGoodDTO
  ): CallOffFinishedGood {
    return CallOffFinishedGood.generateFromDTO(finishedGood);
  }
}

export default new GetCallOffFinishedGoodsByWarehouseService({
  repository: callOffRepository
});

export { GetCallOffFinishedGoodsByWarehouseService };
