import { createSlice } from '@reduxjs/toolkit';
import InboundMessages from 'modules/inbound/shared/domain/InboundMessages/InboundMessages';
import {
  deleteInboundMessage,
  getInboundMessagesByUids,
  postInboundNewMessage
} from './async';
import { FeatureType, InboundMessagesProps } from './types';

export const initialState: InboundMessagesProps = {
  messagesResponse: InboundMessages.generateEmpty().messagesResponse,
  currentSelectedPanel: { targetType: null, targetUids: [] },
  newPostMessage: { targetType: null, targetUid: '', content: '' },
  requestStatusMessages: '',
  requestStatusPostMessage: '',
  selectedItem: { uid: '', name: '', feature: FeatureType.NONE },
  selectedMessageUid: '',
  deleteResponse: null,
  requestStatusDelete: ''
};

const inboundMessagesSlice = createSlice({
  name: 'inboundMessages',
  initialState,
  reducers: {
    updateCurrentSelectedPanel: (state, action) => {
      state.currentSelectedPanel = action.payload;
    },
    updateSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    updateNewPostMessage: (state, action) => {
      state.newPostMessage.targetUid = state.selectedItem.uid;
      state.newPostMessage.targetType = action.payload.targetType;
      state.newPostMessage.content = action.payload.content;
    },
    updateSelectedMessageUid: (state, action) => {
      state.selectedMessageUid = action.payload;
    },
    resetRequestStatus: (state) => {
      state.requestStatusPostMessage = initialState.requestStatusPostMessage;
      state.requestStatusDelete = initialState.requestStatusDelete;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInboundMessagesByUids.fulfilled, (state, action) => {
      state.messagesResponse = action.payload.messagesResponse;
      state.requestStatusMessages = action.meta.requestStatus;
    });
    builder.addCase(getInboundMessagesByUids.pending, (state, action) => {
      state.requestStatusMessages = action.meta.requestStatus;
    });
    builder.addCase(getInboundMessagesByUids.rejected, (state, action) => {
      state.requestStatusMessages = action.meta.requestStatus;
    });
    builder.addCase(postInboundNewMessage.fulfilled, (state, action) => {
      state.requestStatusPostMessage = action.meta.requestStatus;
    });
    builder.addCase(postInboundNewMessage.pending, (state, action) => {
      state.requestStatusPostMessage = action.meta.requestStatus;
    });
    builder.addCase(postInboundNewMessage.rejected, (state, action) => {
      state.requestStatusPostMessage = action.meta.requestStatus;
    });
    builder.addCase(deleteInboundMessage.fulfilled, (state, action) => {
      state.deleteResponse = action.payload;
      state.requestStatusDelete = action.meta.requestStatus;
    });
    builder.addCase(deleteInboundMessage.pending, (state, action) => {
      state.requestStatusDelete = action.meta.requestStatus;
    });
    builder.addCase(deleteInboundMessage.rejected, (state, action) => {
      state.requestStatusDelete = action.meta.requestStatus;
    });
  }
});

export const getInboundMessages = (state) =>
  state.inboundMessagesState.messagesResponse;
export const getInboundMessagesFulfilled = (state) =>
  state.inboundMessagesState.requestStatusMessages === 'fulfilled';
export const getInboundMessagesPending = (state) =>
  state.inboundMessagesState.requestStatusMessages === 'pending';
export const getCurrentSelectedPanel = (state) =>
  state.inboundMessagesState.currentSelectedPanel;
export const getSelectedItem = (state) =>
  state.inboundMessagesState.selectedItem;

export const getAllMessagesByPieceUid = (state) => {
  const selectedUid = state.inboundMessagesState.selectedItem.uid;
  const allMessages = state.inboundMessagesState.messagesResponse;
  const uidMessages =
    allMessages.find((item) => item.targetUid === selectedUid)?.messages || [];
  return uidMessages;
};

export const getPostMessageToSend = (state) =>
  state.inboundMessagesState.newPostMessage;
export const getPostMessageFulfilled = (state) =>
  state.inboundMessagesState.requestStatusPostMessage === 'fulfilled';
export const getPostMessageRejected = (state) =>
  state.inboundMessagesState.requestStatusPostMessage === 'rejected';
export const getSelectedMessageUid = (state) =>
  state.inboundMessagesState.selectedMessageUid;
export const getDeletedMessageFulfilled = (state) =>
  state.inboundMessagesState.requestStatusDelete === 'fulfilled' &&
  state.inboundMessagesState.deleteResponse === 204;
export const getDeleteMessageRejected = (state) =>
  state.inboundMessagesState.requestStatusDelete === 'rejected';

export const {
  updateCurrentSelectedPanel,
  updateSelectedItem,
  updateNewPostMessage,
  resetRequestStatus,
  updateSelectedMessageUid
} = inboundMessagesSlice.actions;
export default inboundMessagesSlice.reducer;
