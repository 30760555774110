export const COUNTRY_DICTIONARY = [
  {
    language: 'en',
    countries: {
      AND: 'Andorra',
      ARG: 'Argentina',
      AUS: 'Australia',
      AUT: 'Austria',
      BEL: 'Belgium',
      BIH: 'Bosnia',
      CHE: 'Switzerland',
      CHL: 'Chile',
      COL: 'Colombia',
      CUW: 'Curaçao',
      CYP: 'Cyprus',
      CZE: 'Czechia',
      DEU: 'Germany',
      DNK: 'Denmark',
      DOM: 'Dominican Republic',
      EGY: 'Egypt',
      ESP: 'Spain',
      EST: 'Estonia',
      FIN: 'Finland',
      FRA: 'France',
      FRO: 'Faroe Islands',
      GBR: 'Great Britain',
      GLP: 'Guadeloupe',
      GRC: 'Greece',
      GTM: 'Guatemala',
      GUF: 'French Guaiana',
      HRV: 'Croatia',
      HUN: 'Hungary',
      IRL: 'Ireland',
      ISL: 'Iceland',
      ISR: 'Israel',
      ITA: 'Italy',
      JPN: 'Japan',
      KWT: 'Kuwait',
      LBN: 'Lebanon',
      LTU: 'Lithuania',
      LUX: 'Luxembourg',
      LVA: 'Latvia',
      MAR: 'Morocco',
      MEX: 'Mexico',
      MKD: 'Macedonia',
      MLT: 'Malta',
      MTQ: 'Martinique',
      MUS: 'Mauritius',
      NLD: 'Netherlands',
      NOR: 'Norway',
      NZL: 'New Zealand',
      PER: 'Peru',
      POL: 'Poland',
      PRT: 'Portugal',
      PSE: 'Palestine',
      REU: 'Réunion',
      ROU: 'Romania',
      SGP: 'Singapore',
      SVK: 'Slovakia',
      SVN: 'Slovenia',
      SWE: 'Sweden',
      THA: 'Thailand',
      TUN: 'Tunisia',
      TUR: 'Turkey',
      TWN: 'Taiwan',
      UKR: 'Ukraine',
      URY: 'Uruguay',
      ZAF: 'South Africa'
    }
  },
  {
    language: 'es',
    countries: {
      AND: 'Andorra',
      ARG: 'Argentina',
      AUS: 'Australia',
      AUT: 'Austria',
      BEL: 'Bélgica',
      BIH: 'Bosnia',
      CHE: 'Suiza',
      CHL: 'Chile',
      COL: 'Colombia',
      CUW: 'Curaçao',
      CYP: 'Chipre',
      CZE: 'República Checa',
      DEU: 'Alemania',
      DNK: 'Dinamarca',
      DOM: 'República Dominicana',
      EGY: 'Egipto',
      ESP: 'España',
      EST: 'Estonia',
      FIN: 'Finlandia',
      FRA: 'Francia',
      FRO: 'Islas Feroe',
      GBR: 'Reino Unido',
      GLP: 'Guadalupe',
      GRC: 'Grecia',
      GTM: 'Guatemala',
      GUF: 'Guayana Francesa',
      HRV: 'Croacia',
      HUN: 'Hungría',
      IRL: 'Irlanda',
      ISL: 'Islandia',
      ISR: 'Israel',
      ITA: 'Italia',
      JPN: 'Japón',
      KWT: 'Kuwait',
      LBN: 'Líbano',
      LTU: 'Lituania',
      LUX: 'Luxemburgo',
      LVA: 'Letonia',
      MAR: 'Marruecos',
      MEX: 'México',
      MKD: 'Macedonia',
      MLT: 'Malta',
      MTQ: 'Martinica',
      MUS: 'Mauricio',
      NLD: 'Países Bajos',
      NOR: 'Noruega',
      NZL: 'Nueva Zelanda',
      PER: 'Perú',
      POL: 'Polonia',
      PRT: 'Portugal',
      PSE: 'Palestina',
      REU: 'Reunión',
      ROU: 'Rumania',
      SGP: 'Singapur',
      SVK: 'Eslovaquia',
      SVN: 'Eslovenia',
      SWE: 'Suecia',
      THA: 'Tailandia',
      TUN: 'Túnez',
      TUR: 'Turquía',
      TWN: 'Taiwan',
      UKR: 'Ucrania',
      URY: 'Uruguay',
      ZAF: 'Sudáfrica'
    }
  },
  {
    language: 'ca',
    countries: {
      AND: 'Andorra',
      ARG: 'Argentina',
      AUS: 'Austràlia',
      AUT: 'Àustria',
      BEL: 'Bèlgica',
      BIH: 'Bòsnia',
      CHE: 'Suissa',
      CHL: 'Xile',
      COL: 'Colòmbia',
      CUW: 'Curaçao',
      CYP: 'Xipre',
      CZE: 'República Txeca',
      DEU: 'Alemanya',
      DNK: 'Dinamarca',
      DOM: 'República Dominicana',
      EGY: 'Egipte',
      ESP: 'Espanya',
      EST: 'Estònia',
      FIN: 'Finlàndia',
      FRA: 'França',
      FRO: 'Illes Fèroe',
      GBR: 'Regne Unit',
      GLP: 'Guadalupe',
      GRC: 'Grècia',
      GTM: 'Guatemala',
      GUF: 'Guayana Francesa',
      HRV: 'Croàcia',
      HUN: 'Hungria',
      IRL: 'Irlanda',
      ISL: 'Islàndia',
      ISR: 'Israel',
      ITA: 'Itàlia',
      JPN: 'Japó',
      KWT: 'Kuwait',
      LBN: 'Líban',
      LTU: 'Lituània',
      LUX: 'Luxemburg',
      LVA: 'Letònia',
      MAR: 'Marroc',
      MEX: 'Mèxic',
      MKD: 'Macedònia',
      MLT: 'Malta',
      MTQ: 'Martinica',
      MUS: 'Maurici',
      NLD: 'Països Baixos',
      NOR: 'Noruega',
      NZL: 'Nova Zelanda',
      PER: 'Perú',
      POL: 'Polònia',
      PRT: 'Portugal',
      PSE: 'Palestina',
      REU: 'Reunió',
      ROU: 'Rumania',
      SGP: 'Singapur',
      SVK: 'Eslovàquia',
      SVN: 'Eslovènia',
      SWE: 'Suècia',
      THA: 'Tailàndia',
      TUN: 'Tuníssia',
      TUR: 'Turquía',
      TWN: 'Taiwan',
      UKR: 'Ucraïna',
      URY: 'Uruguai',
      ZAF: 'Sud-àfrica'
    }
  }
];
