import CountryDeliveryPointsDTO from '../infrastructure/dto/CountryDeliveryPointsDTO';

export interface DeliveryPoint {
  id: string;
  name: string;
}

export interface ICountryDeliveryPoints {
  countryCode: string;
  deliveryPoints: DeliveryPoint[];
}
class CountryDeliveryPoints implements ICountryDeliveryPoints {
  countryCode: string;
  deliveryPoints: DeliveryPoint[];

  constructor({ countryCode, deliveryPoints }) {
    this.countryCode = countryCode;
    this.deliveryPoints = deliveryPoints;
  }

  static generateFromDto({
    isoCode,
    deliveryPoints
  }: CountryDeliveryPointsDTO) {
    return new CountryDeliveryPoints({
      countryCode: isoCode,
      deliveryPoints: deliveryPoints.map(({ id, name }) => ({
        id,
        name: `${id}-${name}`
      }))
    });
  }

  static generateEmpty() {
    return new CountryDeliveryPoints({ countryCode: '', deliveryPoints: [] });
  }
}

export default CountryDeliveryPoints;
