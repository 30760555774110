import { AgingByRangesDTO } from 'modules/outbound/shared/application/stockAgingDTOMapper';

type GenerateFromDTOParams = AgingByRangesDTO;

class StockAging {
  zeroToTwo: number;
  threeToTen: number;
  elevenToNinety: number;
  ninetyOnePlus: number;
  total: number;

  constructor({ zeroToTwo, threeToTen, elevenToNinety, ninetyOnePlus, total }) {
    this.zeroToTwo = zeroToTwo;
    this.threeToTen = threeToTen;
    this.elevenToNinety = elevenToNinety;
    this.ninetyOnePlus = ninetyOnePlus;
    this.total = total;
  }

  static generateFromDto(aging: GenerateFromDTOParams): StockAging {
    const sum = aging['0-2'] + aging['3-10'] + aging['11-90'] + aging['91+'];
    return new StockAging({
      zeroToTwo: aging['0-2'],
      threeToTen: aging['3-10'],
      elevenToNinety: aging['11-90'],
      ninetyOnePlus: aging['91+'],
      total: sum
    });
  }
}

export default StockAging;
