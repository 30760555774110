import { createSlice } from '@reduxjs/toolkit';
import { DeliveriesGeolocationCheckpointsState } from './types';
import { getDeliveriesGeolocationCheckpoints } from './async';
import { IFinishedGoodStatus } from 'modules/outbound/fulfillment/domain/FinishedGoods';

export const initialState: DeliveriesGeolocationCheckpointsState = {
  countryCode: '',
  checkpoints: [],
  selected: '',
  filters: {
    status: IFinishedGoodStatus.UNKNOWN
  },
  requestStatus: ''
};

const deliveriesGeolocationCheckpointsSlice = createSlice({
  name: 'deliveriesGeolocationCheckpoints',
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
    },
    updateStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDeliveriesGeolocationCheckpoints.fulfilled,
      (state, action) => {
        state.countryCode = action.payload.countryCode || '';
        state.checkpoints = action.payload.checkpoints || [];
        state.requestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getDeliveriesGeolocationCheckpoints.pending,
      (state, action) => {
        state.requestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getDeliveriesGeolocationCheckpoints.rejected,
      (state, action) => {
        state.requestStatus = action.meta.requestStatus;
      }
    );
  }
});

//selectors
export const getSelectedCheckpoint = (state) =>
  state.deliveriesGeolocationCheckpointsState.selected;

export const getCheckpoints = (state) =>
  state.deliveriesGeolocationCheckpointsState.checkpoints;

export const getStatusFilter = (state) =>
  state.deliveriesGeolocationCheckpointsState.filters.status;

export const getCheckpointTypeFromCheckpointRef = (state, checkpointRef) => {
  return state.deliveriesGeolocationCheckpointsState.checkpoints.find(
    (item) => item.id === checkpointRef
  )?.type;
};

export const { updateStatusFilter, updateSelected } =
  deliveriesGeolocationCheckpointsSlice.actions;

export default deliveriesGeolocationCheckpointsSlice;
