import noJitRepository from 'modules/inbound/nojit/infrastructure/repositories/NoJitRepository';
import { INoJitRepository } from '../domain/INoJitRepository';
import NoJitFilters from '../domain/NoJitFilters';

class GetNoJitFiltersService {
  private _noJitRepository: INoJitRepository;
  constructor({ repository }) {
    this._noJitRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._noJitRepository
      .getNoJitFilters({ queryParams })
      .then((res) => {
        return NoJitFilters.generateFromDTO(res);
      });
  }
}

export default new GetNoJitFiltersService({
  repository: noJitRepository
});

export { GetNoJitFiltersService };
