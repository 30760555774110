import { createSlice } from '@reduxjs/toolkit';
import { getServiceLevelDashboard } from './async';
import { ServiceLevelState } from './types';

export const initialState: ServiceLevelState = {
  serviceLevelDashboardUrl: null,
  serviceLevelDashboardUrlRequestStatus: ''
};
const serviceLevelSlice = createSlice({
  name: 'capacities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceLevelDashboard.fulfilled, (state, action) => {
      state.serviceLevelDashboardUrl = action.payload;
      state.serviceLevelDashboardUrlRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getServiceLevelDashboard.pending, (state, action) => {
      state.serviceLevelDashboardUrlRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getServiceLevelDashboard.rejected, (state, action) => {
      state.serviceLevelDashboardUrlRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getServiceLevelDashboardUrl = (state) =>
  state.serviceLevelState.serviceLevelDashboardUrl;

export const serviceLevelDashboardUrlRequestStatusIsPending = (state) =>
  state.serviceLevelState.serviceLevelDashboardUrlRequestStatus === 'pending';

export const serviceLevelReducer = serviceLevelSlice.reducer;
