export type ColumnAlign = 'left' | 'center' | 'right';
export interface ColumnInfo {
  uiKey: string;
  key: string;
  value: string;
  sortable: boolean;
  tooltipInfo?: string;
  resize?: boolean;
  draggable?: boolean;
  align?: ColumnAlign;
  hasCopyIcon?: boolean;
}

export interface ColumnProps {
  key: string;
  uiKey?: string;
  value: string;
  sortable?: boolean;
  isVisibleCheck: boolean;
  headerVisible?: boolean;
  isHeaderExpandable?: boolean;
  isHeaderExpanded?: boolean;
  columnVisibleOnHeaderCollapsed?: string;
  headerSpan?: number;
  tooltipInfo?: string;
  columns?: ColumnInfo[];
}

export enum PlanningColumnsKeys {
  alwaysVisible = 'alwaysVisible',
  optionVisible = 'optionVisible'
}
