export interface StockSummaryItemDTO {
  number_of_keys: number;
  value_in_euros: number;
  stock_volume: number;
}

class EndOfProductionSummaryDTO {
  number_of_keys: number;
  value_in_euros: number;
  stock_volume: number;

  constructor({ number_of_keys, value_in_euros, stock_volume }) {
    this.number_of_keys = number_of_keys;
    this.value_in_euros = value_in_euros;
    this.stock_volume = stock_volume;
  }
}

export default EndOfProductionSummaryDTO;
