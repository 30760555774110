import LocaleService from 'infrastructure/i18n/LocaleService';
import {
  ColumnAlign,
  ColumnInfo
} from 'modules/inbound/shared/domain/ColumnsTypes';
import { EntriesText } from '../../infrastructure/i18n/EntriesText';
import { EntriesTranslations } from '../../infrastructure/i18n/typing';
import { EntriesDataItemDTO } from '../../infrastructure/dto/NextHoursDTO/entriesDataDTOMapper';

const entriesTranslations: EntriesTranslations = {
  ...EntriesText[LocaleService.getLanguage()]
};

export interface ColumnsInfo {
  key: string;
  uiKey: string;
  value: string;
  sortable: boolean;
  isVisibleCheck: boolean;
  draggable: boolean;
  align?: ColumnAlign;
}

export const EntriesNextHoursColumnKeys = {
  pieceAlert: 'pieceAlert',
  plateNumber: 'plateNumber',
  transportId: 'transportId',
  carrier: 'carrier',
  supplierName: 'supplierName',
  stops: 'stops',
  windowBooked: 'windowBooked',
  mostCriticalPieceCoverage: 'mostCriticalPieceCoverage',
  warehouses: 'warehouses',
  locations: 'locations',
  unloadingPoints: 'unloadingPoints',
  packages: 'packages'
} as const;

type TColumnKeys =
  (typeof EntriesNextHoursColumnKeys)[keyof typeof EntriesNextHoursColumnKeys];

export interface EntriesNextHoursColumn extends ColumnInfo {
  key: keyof EntriesDataItemDTO | 'piece_alert';
  uiKey: TColumnKeys;
  isVisibleCheck: boolean;
}

const SORTABLE_COLUMNS: TColumnKeys[] = [
  'mostCriticalPieceCoverage',
  'stops',
  'packages',
  'windowBooked'
];

const RIGHT_ALIGNED_COLUMNS: TColumnKeys[] = [
  'mostCriticalPieceCoverage',
  'packages'
];

const getColumnAlignment = (
  column: TColumnKeys
): EntriesNextHoursColumn['align'] => {
  return RIGHT_ALIGNED_COLUMNS.includes(column) ? 'right' : 'left';
};

export const ENTRIES_NEXT_HOURS_COLUMNS: EntriesNextHoursColumn[] = [
  {
    key: 'piece_alert',
    uiKey: 'pieceAlert',
    value: '',
    sortable: false,
    isVisibleCheck: true,
    draggable: false,
    align: getColumnAlignment('pieceAlert')
  },
  {
    key: 'plate_number',
    uiKey: 'plateNumber',
    value: entriesTranslations.plateNumber,
    sortable: SORTABLE_COLUMNS.includes('plateNumber'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('plateNumber')
  },
  {
    key: 'transport_id',
    uiKey: 'transportId',
    value: entriesTranslations.transportId,
    sortable: SORTABLE_COLUMNS.includes('transportId'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('transportId')
  },
  {
    key: 'carrier',
    uiKey: 'carrier',
    value: entriesTranslations.carrier,
    sortable: SORTABLE_COLUMNS.includes('carrier'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('carrier')
  },
  {
    key: 'supplier_name',
    uiKey: 'supplierName',
    value: entriesTranslations.supplierName,
    sortable: SORTABLE_COLUMNS.includes('supplierName'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('supplierName')
  },
  {
    key: 'stops',
    uiKey: 'stops',
    value: entriesTranslations.stops,
    sortable: SORTABLE_COLUMNS.includes('stops'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('stops')
  },
  {
    key: 'window_booked',
    uiKey: 'windowBooked',
    value: entriesTranslations.windowBooked,
    sortable: SORTABLE_COLUMNS.includes('windowBooked'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('windowBooked')
  },
  {
    key: 'most_critical_piece_coverage',
    uiKey: 'mostCriticalPieceCoverage',
    value: entriesTranslations.mostCriticalPieceCoverage,
    sortable: SORTABLE_COLUMNS.includes('mostCriticalPieceCoverage'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('mostCriticalPieceCoverage')
  },
  {
    key: 'warehouses',
    uiKey: 'warehouses',
    value: entriesTranslations.warehouses,
    sortable: SORTABLE_COLUMNS.includes('warehouses'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('warehouses')
  },
  {
    key: 'locations',
    uiKey: 'locations',
    value: entriesTranslations.locations,
    sortable: SORTABLE_COLUMNS.includes('locations'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('locations')
  },
  {
    key: 'unloading_points',
    uiKey: 'unloadingPoints',
    value: entriesTranslations.unloadingPoints,
    sortable: SORTABLE_COLUMNS.includes('unloadingPoints'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('unloadingPoints')
  },
  {
    key: 'packages',
    uiKey: 'packages',
    value: entriesTranslations.numberOfContainers,
    sortable: SORTABLE_COLUMNS.includes('packages'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('packages')
  }
];
