export interface IUserDashboardsDTO {
  id: number;
  board_name: string;
}

class UserDashboardsDTO {
  dashboards: IUserDashboardsDTO[];

  constructor({ dashboards }) {
    this.dashboards = dashboards;
  }
}
export default UserDashboardsDTO;
