import { createSlice } from '@reduxjs/toolkit';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { ResponseText } from 'modules/inbound/shared/infraestructure/i18n/responseText';
import {
  InboundTranslations,
  ResponseTranslations
} from 'modules/inbound/shared/infraestructure/i18n/typing';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { downloadProviderCodes, uploadProviderCodes } from './async';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { ProviderCodesState } from './types';

const inboundText: InboundTranslations & ResponseTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...ResponseText[LocaleService.getLanguage()]
};

export const initialState: ProviderCodesState = {
  uploadFileModalOpen: false,
  providerCodesMessageResponse: '',
  uploadProviderCodesFile: null,
  providerCodesRequestStatus: '',
  providerCodesFile: null,
  providerCodesStatusCodeResponse: 0,
  providerCodesPanel: ''
};

const providerCodesSlice = createSlice({
  name: 'providerCodesData',
  initialState,
  reducers: {
    updateIsFileModalOpen: (state, action) => {
      state.uploadFileModalOpen = action.payload;
      state.providerCodesMessageResponse = '';
      state.providerCodesStatusCodeResponse = 0;
      state.uploadProviderCodesFile = null;
    },
    updateSelectedFile: (state, action) => {
      state.uploadProviderCodesFile = action.payload;
      state.providerCodesMessageResponse = '';
    },
    updateProviderCodesPanel: (state, action) => {
      state.providerCodesPanel = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(downloadProviderCodes.fulfilled, (state, action) => {
      state.providerCodesFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `${
          state.providerCodesPanel
        }_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.providerCodesRequestStatus = action.meta.requestStatus;
      state.providerCodesMessageResponse = inboundText.downloadSuccess;
    });
    builder.addCase(downloadProviderCodes.pending, (state, action) => {
      state.providerCodesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadProviderCodes.rejected, (state, action) => {
      state.providerCodesRequestStatus = action.meta.requestStatus;
      state.providerCodesMessageResponse = inboundText.downloadError;
    });

    builder.addCase(uploadProviderCodes.fulfilled, (state, action) => {
      state.providerCodesRequestStatus =
        action.payload.statusCode === 201
          ? action.meta.requestStatus
          : 'rejected';
      state.providerCodesMessageResponse =
        action.payload.statusCode === 201
          ? inboundText.uploadSuccess
          : action.payload.message;
      state.providerCodesStatusCodeResponse = action.payload.statusCode;
    });
    builder.addCase(uploadProviderCodes.pending, (state, action) => {
      state.providerCodesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(uploadProviderCodes.rejected, (state, action) => {
      state.providerCodesRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getIsUploadFileModalOpen = (state) =>
  state.providerCodesState.uploadFileModalOpen;
export const getProviderCodesExcelFile = (state) =>
  state.providerCodesState.uploadProviderCodesFile;
export const getProviderCodesIsPending = (state) =>
  state.providerCodesState.providerCodesRequestStatus === 'pending';
export const getProviderCodesHasError = (state) =>
  state.providerCodesState.providerCodesRequestStatus === 'rejected';
export const getMessageResponse = (state) =>
  state.providerCodesState.providerCodesMessageResponse;
export const getUploadProviderCodesSuccess = (state) =>
  state.providerCodesState.providerCodesStatusCodeResponse === 201;
export const getProviderCodesCodeResponse = (state) =>
  state.providerCodesState.providerCodesStatusCodeResponse;

export const {
  updateIsFileModalOpen,
  updateSelectedFile,
  updateProviderCodesPanel
} = providerCodesSlice.actions;
export default providerCodesSlice.reducer;
