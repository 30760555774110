import { MessageResponseDTO } from '../../infraestructure/dto/inboundMessagesDTOMapper';
import GetDateFormatedService from '../GetDateFormatedService';

const DIRECT_DELIVERIES = 'direct-deliveries';
export const EXPIRATIONS_PANEL = 'expirations';

export type CommentsTargetPanel =
  | typeof DIRECT_DELIVERIES
  | typeof EXPIRATIONS_PANEL;

export interface Message {
  uid: string;
  author: string;
  content: string;
  createdAt: string;
}

export interface MessageResponse {
  targetUid: string;
  targetType: CommentsTargetPanel;
  messages: Message[];
}
interface GenerateFromDTOParams {
  messages_response: MessageResponseDTO[];
}

class InboundMessages {
  messagesResponse: MessageResponse[];

  constructor({ messagesResponse }: InboundMessages) {
    this.messagesResponse = messagesResponse;
  }

  static generateFromDTO({
    messages_response
  }: GenerateFromDTOParams): InboundMessages {
    let formatMessagesResponse: MessageResponse[] = [];
    let formatMessages: Message[] = [];

    !!messages_response.length &&
      messages_response.forEach((item) => {
        item.messages.forEach((message) => {
          formatMessages.push({
            uid: message.uid,
            author: message.author,
            content: message.content,
            createdAt: message.created_at
              ? `${GetDateFormatedService.getDateFormated(
                  message.created_at,
                  'de-DE'
                )} | ${GetDateFormatedService.getTimeFormated(
                  message.created_at
                )}h`
              : '-'
          });
        });
        formatMessagesResponse.push({
          targetUid: item.target_uid,
          targetType: item.target_type,
          messages: formatMessages
        });
        formatMessages = [];
      });
    return new InboundMessages({
      messagesResponse: formatMessagesResponse
    });
  }

  static generateEmpty() {
    return new InboundMessages({ messagesResponse: [] });
  }
}

export default InboundMessages;
