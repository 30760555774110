import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

const SIDEBAR_DESKTOP_WIDTH = '142px';
const WrapperSidebar = styled.section`
  width: ${SIDEBAR_DESKTOP_WIDTH};
  height: 100%;
  padding: 2.5rem 0;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  box-sizing: border-box;
  background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.overlays.modal.rgb};

  img {
    width: 60px;
  }

  .logo-subtitle {
    margin: 0.5rem 0 3rem;
    display: block;
    font-family: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fontFamily.bold};
    font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fonts.boldMedium.minSizeAsPixels};
    color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.orange.rgb};
  }
`;

export { SIDEBAR_DESKTOP_WIDTH, WrapperSidebar };
