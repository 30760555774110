import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { Urgent } from '../types';
import { EntryDTO } from './EntryDTO';

export interface EntriesDataDTO {
  entries: EntriesDataItemDTO[];
  pagination: IPaginationDTO;
}

export interface EntriesDataItemDTO {
  has_detail: boolean;
  plate_number: string;
  transport_id: number;
  carrier: string;
  estimated_time_arrival: string | null;
  stops: number;
  window_booked: string | null;
  most_critical_piece_coverage: number | null;
  warehouses: string[];
  locations: string[];
  unloading_points: string[];
  packages: number;
  urgent: Urgent;
  supplier_name: string;
  has_critical_pieces: boolean;
  contains_critical_pieces: boolean;
  contains_serious_fault: boolean;
}

const entriesDataDTOMapper = (data: any): EntriesDataDTO => ({
  entries: data.entries?.map((entry: any) => EntryDTO.generateDTO(entry)) || [],
  pagination: {
    page: data.pagination?.page || 1,
    page_count: data.pagination?.page_count || 0,
    size: data.pagination?.size || 50,
    total_count: data.pagination?.total_count || 0
  }
});

export { entriesDataDTOMapper };
