export interface WarehouseCapacityTableDataDTO {
  board: string;
  columns: string[];
}

export interface TypeItemDTO {
  type: string;
  read_only: boolean;
}
export interface WarehouseCapacityTypesDTO {
  id: TypeItemDTO;
  zone: TypeItemDTO;
  warehouse: TypeItemDTO;
  created_at: TypeItemDTO;
  updated_at: TypeItemDTO;
  capacity: TypeItemDTO;
  red: TypeItemDTO;
  green: TypeItemDTO;
  yellow: TypeItemDTO;
}

class WarehouseCapacityBodyDTO {
  data: WarehouseCapacityTableDataDTO;
  types: WarehouseCapacityTypesDTO;

  constructor({ data, types }) {
    this.data = data;
    this.types = types;
  }
}

export default WarehouseCapacityBodyDTO;
