import { SelectItemProps } from 'modules/outbound/fulfillment/redux/FiltersStateReducer/typing';
import {
  ExpirationsFiltersDTO,
  MeasurementUnit
} from '../infrastructure/dto/ExpirationsFiltersDTO';
import { ExpirationTranslations } from '../infrastructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { ExpirationText } from '../infrastructure/i18n/ExpirationText';

const expirationTranslations: ExpirationTranslations = {
  ...ExpirationText[LocaleService.getLanguage()]
};

type MeasurementUnitTranslation = {
  [type in MeasurementUnit]: string;
};

const MEASUREMENT_UNITS_TRANSLATIONS: MeasurementUnitTranslation = {
  KG: expirationTranslations.kilograms,
  L: expirationTranslations.liters,
  M: expirationTranslations.meters,
  ST: expirationTranslations.units
};

class ExpirationFilters {
  inbounders: string[];
  measurementUnits: SelectItemProps[];
  models: string[];
  stockDifferentiations: string[];
  warehouses: string[];

  constructor({
    inbounders,
    measurementUnits,
    models,
    stockDifferentiations,
    warehouses
  }) {
    this.inbounders = inbounders;
    this.measurementUnits = measurementUnits;
    this.models = models;
    this.stockDifferentiations = stockDifferentiations;
    this.warehouses = warehouses;
  }

  static generateFromDTO({
    inbounders,
    measurement_units,
    models,
    stock_differentiations,
    warehouses
  }: ExpirationsFiltersDTO): ExpirationFilters {
    return new ExpirationFilters({
      inbounders,
      measurementUnits: measurement_units.map((unit) => ({
        key: unit,
        text: MEASUREMENT_UNITS_TRANSLATIONS[unit] ?? unit
      })),
      models,
      stockDifferentiations: stock_differentiations,
      warehouses
    });
  }

  static generateEmpty() {
    return new ExpirationFilters({
      inbounders: [],
      measurementUnits: [],
      models: [],
      stockDifferentiations: [],
      warehouses: []
    });
  }
}

export default ExpirationFilters;
