import { ThemeSEATBorderRadius } from './typing';

export const borders: ThemeSEATBorderRadius = {
  small: '5px',
  medium: '10px',
  large: '14px',
  extralarge: '20px',
  circle: '100%',
  round: '50px'
};
