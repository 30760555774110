const lowerCase = /(?=.*[a-z])/;
const upperCase = /(?=.*[A-Z])/;
const number = /(?=.*\D)/;
const specialCharacter = /[^a-zA-Z\d:]/;
const length = /(?=.{8,})/;

const validatePassword = (pwd: string): boolean => {
  return (
    lowerCase.test(pwd) &&
    upperCase.test(pwd) &&
    number.test(pwd) &&
    length.test(pwd) &&
    specialCharacter.test(pwd)
  );
};

export {
  validatePassword,
  lowerCase,
  upperCase,
  number,
  specialCharacter,
  length
};
