import { commonColors, commonOverlays } from './colors';
import ThemeSEATProps from './typing';
import { fontFamily, fonts } from './fonts/index';
import { borders } from './borders/index';

const darkerTheme: ThemeSEATProps = {
  colors: {
    ...commonColors
  },
  overlays: {
    ...commonOverlays
  },
  fonts: {
    ...fonts
  },
  fontFamily: {
    ...fontFamily
  },
  borders: {
    ...borders
  }
};

export default darkerTheme;
