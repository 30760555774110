import IJitRepository from '../domain/IJitRepository';
import jitRepository from '../infrastructure/repositories/JitRepository';

class DownloadJitStockUseCase {
  private _jitRepository: IJitRepository;

  constructor({ jitRepository }) {
    this._jitRepository = jitRepository;
  }

  execute({
    queryParams,
    username
  }: {
    queryParams: object;
    username: string;
  }) {
    return this._jitRepository.downloadJitStock({
      queryParams,
      username
    });
  }
}

export default new DownloadJitStockUseCase({
  jitRepository: jitRepository
});

export { DownloadJitStockUseCase };
