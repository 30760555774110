import { IWarehouseCapacityRepository } from '../domain/IWarehouseCapacityRepository';
import WarehouseCapacityData from '../domain/WarehouseCapacityData';
import WarehouseCapacityRepository from '../infrastructure/repositories/WarehouseCapacityRepository';

class GetWarehouseCapacityService {
  private _warehouseCapacityRepository: IWarehouseCapacityRepository;

  constructor({ warehouseCapacityRepository }) {
    this._warehouseCapacityRepository = warehouseCapacityRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._warehouseCapacityRepository
      .get({ queryParams })
      .then((res) => WarehouseCapacityData.generateFromDTO(res));
  }

  update(body: object) {
    return this._warehouseCapacityRepository
      .updateWarehouseCapacity(body)
      .then((res) => res);
  }
}

export default new GetWarehouseCapacityService({
  warehouseCapacityRepository: WarehouseCapacityRepository
});

export { GetWarehouseCapacityService };
