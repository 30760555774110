import { IProvider } from '../../domain/types';

export interface JitCloseContainerDTO {
  provider: IProvider;
  sequences: number[];
  last_updated: string | null;
  forecast_stoppage_at: string | null;
  on_alert: boolean;
}

export const jitCloseContainersDTOMapper = (
  data: any
): JitCloseContainerDTO[] => {
  const compareDates = (a, b) => {
    if (a.forecast_stoppage_at < b.forecast_stoppage_at) {
      return -1;
    }
    if (a.forecast_stoppage_at > b.forecast_stoppage_at) {
      return 1;
    }
    return 0;
  };

  return (
    data?.sort(compareDates).map((cc, i) => ({
      provider: {
        id: cc?.provider.id,
        name: cc?.provider.name
      },
      sequences: cc?.sequences ?? [],
      last_updated: cc?.last_updated,
      forecast_stoppage_at: cc?.forecast_stoppage_at,
      on_alert: i === 0
    })) || []
  );
};
