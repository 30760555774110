import { CoverageItem } from '../infrastructure/dto/CoverageAtRiskDTO';

interface GenerateFromDTOParams {
  models_risk: CoverageItem[];
  total_risk: CoverageItem;
}

export default class CoverageAtRisk {
  modelsRisk: CoverageItem[];
  totalRisk: CoverageItem;

  constructor({ modelsRisk, totalRisk }) {
    this.modelsRisk = modelsRisk;
    this.totalRisk = totalRisk;
  }

  static generateFromDTO({
    models_risk,
    total_risk
  }: GenerateFromDTOParams): CoverageAtRisk {
    return new CoverageAtRisk({
      modelsRisk: models_risk,
      totalRisk: total_risk
    });
  }

  static generateEmpty() {
    return new CoverageAtRisk({
      modelsRisk: [],
      totalRisk: {
        name: 'Total',
        total: 0,
        stats: []
      }
    });
  }
}
