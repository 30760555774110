import { Translation } from 'infrastructure/i18n/typing';
import { RefillsTranslations } from './typing';

export const RefillsText: Translation<RefillsTranslations> = {
  es: {
    pageTitle: 'Reposiciones',
    menuTitle: 'Reposiciones',
    emptyMessage: 'Aún no hay reposiciones disponibles.',
    emptyMessageStatus: 'El estado de las reposiciones no está disponible',
    code: 'Clave',
    transportOrderNumber: 'Nº OT',
    coverageP17: 'Cobertura en P17',
    stockP17: 'Stock en P17',
    refillTimeInMinutes: 'Tiempo reposición',
    handlingUnit: 'HU',
    sourceP17: 'Origen en ST17',
    status: 'Estado',
    alerts: 'Alertas',
    saturation: 'Saturación en P17',
    transfer: 'A trasvasar',
    directToLine: 'Directo a línea',
    showCompleteBoard: 'Ver tabla completa',
    previousHours: 'H. anteriores',
    now: 'Ahora',
    createdAt: 'H. Creación',
    lastUpdate: 'Actualización de peticiones:',
    sourceLocation: 'Ubic. Proc.'
  },
  en: {
    pageTitle: 'Refills',
    menuTitle: 'Refills',
    emptyMessage: 'There is no available refills.',
    emptyMessageStatus: 'Refills status is not available',
    code: 'Part',
    transportOrderNumber: 'OT Number',
    coverageP17: 'Coverage in P17',
    stockP17: 'Stock in P17',
    refillTimeInMinutes: 'Refill time',
    handlingUnit: 'HU',
    sourceP17: 'Origin in ST17',
    status: 'Status',
    alerts: 'Alerts',
    saturation: 'Saturation in P17',
    transfer: 'To transfer',
    directToLine: 'Direct to line',
    showCompleteBoard: 'Show complete board',
    previousHours: 'Previous Hrs.',
    now: 'Now',
    createdAt: 'H. Creation',
    lastUpdate: 'Requests update:',
    sourceLocation: 'Source loc.'
  },
  ca: {
    pageTitle: 'Reposicions',
    menuTitle: 'Reposicions',
    emptyMessage: 'Encara no hi ha reposicions disponibles.',
    emptyMessageStatus: "L'estat de las reposicions no està disponible",
    code: 'Clau',
    transportOrderNumber: 'Nº OT',
    coverageP17: 'Cobertura a P17',
    stockP17: 'Stock a P17',
    refillTimeInMinutes: 'Temps reposició',
    handlingUnit: 'HU',
    sourceP17: 'Origen a ST17',
    status: 'Estat',
    alerts: 'Alertes',
    saturation: 'Saturació en P17',
    transfer: 'A transvasar',
    directToLine: 'Directe a línia',
    showCompleteBoard: 'Veure taula completa',
    previousHours: 'H. anteriors',
    now: 'Ara',
    createdAt: 'H. Creació',
    lastUpdate: 'Actualització de peticions:',
    sourceLocation: 'Ubic. Proc.'
  }
};
