import { API_GET_OVERVIEW, API_GET_VEHICLES, API_GET_FORECAST } from './urls';
import api from 'modules/shared/infrastructure/api/Api';
import IApi from 'modules/shared/domain/IApi';
import WarehouseStockDTO from 'modules/outbound/stock/infrastructure/dto/WarehouseStockDTO';
import WarehouseStockByModelDTO from 'modules/outbound/stock/infrastructure/dto/WarehouseStockByModelDTO';
import WarehouseForecastByModelDTO from 'modules/outbound/stock/infrastructure/dto/WarehouseForecastByModelDTO';
import IWarehouseStockRepository from 'modules/outbound/stock/domain/IWarehouseStockRepository';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';

class WarehouseStockRepository
  extends BaseRepository
  implements IWarehouseStockRepository
{
  private _api: IApi;
  private _apiGetOverview: string;
  private _apiGetVehicles: string;
  private _apiGetForecast: string;

  constructor({ api, apiGetOverview, apiGetVehicles, apiGetForecast }) {
    super();
    this._api = api;
    this._apiGetOverview = apiGetOverview;
    this._apiGetVehicles = apiGetVehicles;
    this._apiGetForecast = apiGetForecast;
  }

  getOverview({ queryParams }) {
    const url = `${this._apiGetOverview}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => res.map((item) => new WarehouseStockDTO(item)))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getInfoByWarehouse({ warehouseReference, queryParams }) {
    const url = `${
      this._apiGetVehicles
    }/${warehouseReference}${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => res.map((item) => new WarehouseStockByModelDTO(item)))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getForecastByWarehouse({ warehouseReference, queryParams }) {
    const url = `${
      this._apiGetForecast
    }/${warehouseReference}${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => new WarehouseForecastByModelDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }
}

export default new WarehouseStockRepository({
  api: api,
  apiGetOverview: API_GET_OVERVIEW,
  apiGetVehicles: API_GET_VEHICLES,
  apiGetForecast: API_GET_FORECAST
});

export { WarehouseStockRepository };
