import CountryDeliveryPoints from '../domain/CountryDeliveryPoints';
import IDeliveryPointsRepository from '../domain/IDeliveryPointsRepository';
import deliveryPointsRepository from '../infrastructure/repositories/DeliveryPointsRepository';

class GetDeliveryPointsUseCase {
  private _repository: IDeliveryPointsRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<CountryDeliveryPoints[]> {
    return this._repository
      .getDeliveryPoints()
      .then((response) =>
        response.map((countryDeliveryPointDto) =>
          CountryDeliveryPoints.generateFromDto(countryDeliveryPointDto)
        )
      );
  }
}

export default new GetDeliveryPointsUseCase({
  repository: deliveryPointsRepository
});

export { GetDeliveryPointsUseCase };
