export interface IFinishedGoodDTO {
  id: string;
  lastRedistributionWarehouse: string;
  currentCheckpointDate: string;
  nextCheckpointETA: string;
  delayedHours: number;
  status: string;
  geolocationEta: string | undefined;
  cumulativeDelayedHours: number;
}

class FinishedGoodDTO {
  finishedGood: IFinishedGoodDTO;
  currentCheckpoint: string;
  destinationCountry: string;

  constructor({
    id,
    lastRedistributionWarehouse,
    currentCheckpointDate,
    nextCheckpointETA,
    delayedHours,
    status,
    currentCheckpoint,
    destinationCountry,
    geolocationEta,
    cumulativeDelayedHours
  }) {
    this.finishedGood = {
      id,
      lastRedistributionWarehouse,
      currentCheckpointDate,
      nextCheckpointETA,
      delayedHours,
      status,
      geolocationEta,
      cumulativeDelayedHours
    };
    this.currentCheckpoint = currentCheckpoint;
    this.destinationCountry = destinationCountry;
  }
}

export default FinishedGoodDTO;
