import { createSlice } from '@reduxjs/toolkit';
import { FiltersReducerProps } from './types';
import { getTransfersFilters, getTransfersNextHoursFilters } from './async';
import TransfersFiltersData from 'modules/inbound/transfer/domain/TransfersFilters';

export const initialState: FiltersReducerProps = {
  filters: TransfersFiltersData.generateEmpty(),
  requestStatus: ''
};

const transfersFiltersSlice = createSlice({
  name: 'transfersFilters',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.filters = initialState.filters;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransfersFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransfersFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransfersFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransfersNextHoursFilters.fulfilled, (state, action) => {
      state.filters = {
        ...action.payload,
        warehouseTypes: initialState.filters.warehouseTypes
      };
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransfersNextHoursFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransfersNextHoursFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) => state.transfersFiltersState.filters;
export const isLoading = (state) =>
  state.transfersFiltersState.requestStatus === 'pending';
export const hasError = (state) =>
  state.transfersFiltersState.requestStatus === 'rejected';

export const { clearFilters } = transfersFiltersSlice.actions;
export default transfersFiltersSlice.reducer;
