import { EntriesParalyzation } from '../../domain/InPlant/EntriesParalyzation';
import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesParalyzationUseCase {
  private _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<EntriesParalyzation> {
    return this._repository
      .getParalyzationSummary()
      .then((res) => EntriesParalyzation.generateFromDto(res));
  }
}

const getEntriesParalyzationUseCase = new GetEntriesParalyzationUseCase({
  repository: entriesRepository
});

export { GetEntriesParalyzationUseCase, getEntriesParalyzationUseCase };
