import { DirectDeliveryTransport } from 'modules/inbound/directDelivery/domain/DirectDelivery';
import { FactoryBcnTransport } from 'modules/inbound/factoryBcn/domain/FactoryBcn';
import { StickerColor } from '../ui/layout/elements/TransportSticker/types';

export enum ShiftType {
  MORNING = 'M',
  AFTERNOON = 'T',
  NIGHT = 'N'
}

export interface ShiftItem {
  shift: ShiftType;
  amount: string;
  color: StickerColor | undefined;
  stickerAmount: string | null;
  modalData: DirectDeliveryTransport[] | FactoryBcnTransport[];
}
