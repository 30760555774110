import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IFactoryBcnDataRepository from 'modules/inbound/factoryBcn/domain/IFactoryBcnDataRepository';
import {
  API_DOWNLOAD_FACTORY_BCN,
  API_GET_FACTORY_BCN_FILTERS,
  FACTORY_BCN_ENDPOINT
} from './urls';
import FactoryBcnDataDTO from '../dto/FactoryBcnDataDTO';
import FactoryBcnFiltersDTO from '../dto/FactoryBcnFiltersDTO';

class FactoryBcnRepository
  extends BaseRepository
  implements IFactoryBcnDataRepository
{
  private _api: IApi;
  private _apiGetFactoryBcnUrl: string;
  private _apiGetFactoryBcnFilters: string;
  private _apiGetDownloadFactoryBCN: string;

  constructor({
    api,
    apiGetFactoryBcnUrl,
    apiGetFactoryBcnFilters,
    apiGetDownloadFactoryBCN
  }) {
    super();
    this._api = api;
    this._apiGetFactoryBcnUrl = apiGetFactoryBcnUrl;
    this._apiGetFactoryBcnFilters = apiGetFactoryBcnFilters;
    this._apiGetDownloadFactoryBCN = apiGetDownloadFactoryBCN;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetFactoryBcnUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new FactoryBcnDataDTO(res));
  }

  getFactoryBcnFilters() {
    return this._api
      .get(this._apiGetFactoryBcnFilters)
      .then((res) => new FactoryBcnFiltersDTO(res));
  }

  downloadFactoryBCN({ queryParams }) {
    const url = `${this._apiGetDownloadFactoryBCN}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new FactoryBcnRepository({
  api: api,
  apiGetFactoryBcnUrl: FACTORY_BCN_ENDPOINT,
  apiGetFactoryBcnFilters: API_GET_FACTORY_BCN_FILTERS,
  apiGetDownloadFactoryBCN: API_DOWNLOAD_FACTORY_BCN
});

export { FactoryBcnRepository };
