import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface WarehouseCapacityItemDTO {
  id: number;
  zone: string;
  warehouse: string;
  created_at: string;
  updated_at: string;
  capacity: number;
  red: number;
  green: number;
  yellow: number;
}

class WarehouseCapacityDataDTO {
  data: WarehouseCapacityItemDTO[];
  pagination: IPaginationDTO;

  constructor({ data, pagination }) {
    this.data = data;
    this.pagination = pagination;
  }
}

export default WarehouseCapacityDataDTO;
