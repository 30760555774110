import IProviderContactListRepository from '../../domain/ProviderContactList/IProviderContactList';
import ProviderContactListRepository from '../../infraestructure/repositories/ProviderContactListRepository';

class DeleteContactUseCase {
  private _providerContactListRepository: IProviderContactListRepository;

  constructor({ repository }) {
    this._providerContactListRepository = repository;
  }

  execute(providerId: number, contactUid: string) {
    return this._providerContactListRepository
      .deleteContact(providerId, contactUid)
      .then((res) => res);
  }
}

export { DeleteContactUseCase };
export default new DeleteContactUseCase({
  repository: ProviderContactListRepository
});
