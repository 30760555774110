interface GenerateFromDTOParams {
  globalDelay: number;
  checkpointDelay: number;
  total: number;
}

class FactoryGeolocationDelay {
  globalValue: number;
  checkpointValue: number;
  globalPercentage: number;
  checkpointPercentage: number;
  totalAmount: number;

  constructor({
    globalPercentage,
    checkpointPercentage,
    globalValue,
    checkpointValue,
    totalAmount
  }: FactoryGeolocationDelay) {
    this.globalPercentage = globalPercentage;
    this.checkpointPercentage = checkpointPercentage;
    this.checkpointValue = checkpointValue;
    this.globalValue = globalValue;
    this.totalAmount = totalAmount;
  }

  static generateFromDto({
    globalDelay,
    checkpointDelay,
    total
  }: GenerateFromDTOParams): FactoryGeolocationDelay {
    return new FactoryGeolocationDelay({
      globalPercentage: Math.round((globalDelay / total) * 100),
      checkpointPercentage: Math.round((checkpointDelay / total) * 100),
      globalValue: globalDelay,
      checkpointValue: checkpointDelay,
      totalAmount: total
    });
  }
}

export default FactoryGeolocationDelay;
