import IProviderContactListRepository from '../../domain/ProviderContactList/IProviderContactList';
import ProviderContactListRepository from '../../infraestructure/repositories/ProviderContactListRepository';

class EditContactUseCase {
  private _providerContactListRepository: IProviderContactListRepository;

  constructor({ repository }) {
    this._providerContactListRepository = repository;
  }

  execute({
    providerId,
    contactUid,
    body
  }: {
    providerId: number;
    contactUid: string;
    body: {
      name: string;
      telephone: string;
      email: string;
      role: string;
    };
  }) {
    return this._providerContactListRepository
      .putContact({ providerId, contactUid, body })
      .then((res) => res);
  }
}

export { EditContactUseCase };
export default new EditContactUseCase({
  repository: ProviderContactListRepository
});
