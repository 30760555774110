import { createSlice } from '@reduxjs/toolkit';
import { WarehouseCapacityState } from './types';
import {
  getWarehouseCapacityBody,
  getWarehouseCapacityData,
  updateWarehouseCapacity
} from './async';

export const initialState: WarehouseCapacityState = {
  data: [],
  body: {
    board: '',
    columns: [],
    types: {}
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatusData: '',
  requestStatusBody: '',
  requestStatusUpdate: '',
  requestUpdateResponse: ''
};

const warehouseCapacitySlice = createSlice({
  name: 'warehouseCapacity',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWarehouseCapacityBody.fulfilled, (state, action) => {
      state.body.board = action.payload.data.board;
      state.body.columns = action.payload.data.columns;
      state.body.types = action.payload.types;
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseCapacityBody.pending, (state, action) => {
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseCapacityBody.rejected, (state, action) => {
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseCapacityData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.pagination = action.payload.pagination;
      state.requestStatusData = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseCapacityData.pending, (state, action) => {
      state.requestStatusData = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseCapacityData.rejected, (state, action) => {
      state.requestStatusData = action.meta.requestStatus;
    });
    builder.addCase(updateWarehouseCapacity.fulfilled, (state, action) => {
      state.requestUpdateResponse = action.payload;
      state.requestStatusUpdate = action.meta.requestStatus;
    });
    builder.addCase(updateWarehouseCapacity.pending, (state, action) => {
      state.requestStatusUpdate = action.meta.requestStatus;
    });
    builder.addCase(updateWarehouseCapacity.rejected, (state, action) => {
      state.requestStatusUpdate = action.meta.requestStatus;
    });
  }
});

export const getisLoadingStatus = (state) =>
  state.warehouseCapacityState.requestStatusBody === 'pending' ||
  state.warehouseCapacityState.requestStatusData === 'pending';
export const getHasError = (state) =>
  state.warehouseCapacityState.requestStatusBody === 'rejected' ||
  state.warehouseCapacityState.requestStatusData === 'rejected';
export const getTableName = (state) => state.warehouseCapacityState.body.board;
export const getTableData = (state) => state.warehouseCapacityState.data;
export const getColumns = (state) => state.warehouseCapacityState.body.columns;
export const getTypes = (state) => state.warehouseCapacityState.body.types;
export const getTotalPages = (state) =>
  state.warehouseCapacityState.pagination.pageCount;
export const getCurrentPage = (state) =>
  state.warehouseCapacityState.pagination.page;
export const getResultsPerPage = (state) =>
  state.warehouseCapacityState.pagination.size;
export const getIsUpdatePending = (state) =>
  state.warehouseCapacityState.requestStatusUpdate === 'pending';
export const getIsUpdateRejected = (state) =>
  state.warehouseCapacityState.requestStatusUpdate === 'rejected' ||
  state.warehouseCapacityState.requestUpdateResponse.detail;
export const getUpdateRequestResponse = (state) =>
  state.warehouseCapacityState.requestUpdateResponse.detail;

export default warehouseCapacitySlice.reducer;

export const { updateCurrentPage } = warehouseCapacitySlice.actions;
