import { createSlice } from '@reduxjs/toolkit';
import { DeliveryStatusesState } from './types';
import { DeliveryStatusesData } from 'modules/inbound/nojit/domain/DeliveryStatusesData';
import { getDeliveryStatuses } from './async';

export const initialState: DeliveryStatusesState = {
  deliveryStatuses: DeliveryStatusesData.generateEmpty(),
  requestDeliverySummaryStatus: '',
  requestIdDeliverySummaryStatus: '',
  selectedDeliverySummaryStatus: {
    selectedPunctualityStatus: null,
    selectedQuantityStatus: null,
    selectedNoEntriesStatus: null
  }
};

const deliveryStatusesSlice = createSlice({
  name: 'deliveryStatusesData',
  initialState,
  reducers: {
    updateSelectedPunctualityStatus: (state, action) => {
      if (
        state.selectedDeliverySummaryStatus.selectedPunctualityStatus ===
        action.payload
      ) {
        state.selectedDeliverySummaryStatus.selectedPunctualityStatus =
          initialState.selectedDeliverySummaryStatus.selectedPunctualityStatus;
        return;
      }
      state.selectedDeliverySummaryStatus.selectedPunctualityStatus =
        action.payload;
      state.selectedDeliverySummaryStatus.selectedNoEntriesStatus =
        initialState.selectedDeliverySummaryStatus.selectedNoEntriesStatus;
    },
    updateSelectedQuantityStatus: (state, action) => {
      if (
        state.selectedDeliverySummaryStatus.selectedQuantityStatus ===
        action.payload
      ) {
        state.selectedDeliverySummaryStatus.selectedQuantityStatus =
          initialState.selectedDeliverySummaryStatus.selectedQuantityStatus;
        return;
      }
      state.selectedDeliverySummaryStatus.selectedQuantityStatus =
        action.payload;
      state.selectedDeliverySummaryStatus.selectedNoEntriesStatus =
        initialState.selectedDeliverySummaryStatus.selectedNoEntriesStatus;
    },
    updateSelectedNoEntriesStatus: (state, action) => {
      if (state.selectedDeliverySummaryStatus.selectedNoEntriesStatus) {
        state.selectedDeliverySummaryStatus.selectedNoEntriesStatus =
          initialState.selectedDeliverySummaryStatus.selectedNoEntriesStatus;
        return;
      }
      state.selectedDeliverySummaryStatus.selectedNoEntriesStatus =
        action.payload;
      state.selectedDeliverySummaryStatus.selectedPunctualityStatus =
        initialState.selectedDeliverySummaryStatus.selectedPunctualityStatus;
      state.selectedDeliverySummaryStatus.selectedQuantityStatus =
        initialState.selectedDeliverySummaryStatus.selectedQuantityStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeliveryStatuses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.requestIdDeliverySummaryStatus) {
        state.requestDeliverySummaryStatus = action.meta.requestStatus;
        state.deliveryStatuses = action.payload;
      }
    });
    builder.addCase(getDeliveryStatuses.pending, (state, action) => {
      state.requestDeliverySummaryStatus = action.meta.requestStatus;
      state.requestIdDeliverySummaryStatus = action.meta.requestId;
    });
    builder.addCase(getDeliveryStatuses.rejected, (state, action) => {
      if (action.meta.requestId === state.requestIdDeliverySummaryStatus) {
        state.requestDeliverySummaryStatus = action.meta.requestStatus;
      }
    });
  }
});

//selectors
export const getDeliveryStatusesData = (state) =>
  state.deliveryStatusesState.deliveryStatuses;
export const getisLoadingDeliverySummary = (state) =>
  state.deliveryStatusesState.requestDeliverySummaryStatus === 'pending';
export const getHasErrorDeliverySummary = (state) =>
  state.deliveryStatusesState.requestDeliverySummaryStatus === 'rejected';
export const getSelectedDeliverySummaryStatus = (state) =>
  state.deliveryStatusesState.selectedDeliverySummaryStatus;

//actions
export const {
  updateSelectedPunctualityStatus,
  updateSelectedQuantityStatus,
  updateSelectedNoEntriesStatus
} = deliveryStatusesSlice.actions;
export default deliveryStatusesSlice.reducer;
