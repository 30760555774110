const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const JIT_ENDPOINT = '/jit/stock/';
const JIT_FILTERS_ENDPOINT = '/filters/stock/jit';
const JIT_FAMILY_COVERAGE_ENDPOINT = 'family_coverage/providers';
const JIT_FAMILIES_ENDPOINT = 'families';
const API_GET_STOCK_JIT_BY_PROVIDER = (providerName: string) =>
  `${INBOUND_BASE_URL}${JIT_ENDPOINT}${providerName}`;
const API_GET_JIT_FILTERS = `${INBOUND_BASE_URL}${JIT_FILTERS_ENDPOINT}`;
const API_GET_FAMILY_COVERAGE = `${INBOUND_BASE_URL}${JIT_ENDPOINT}${JIT_FAMILY_COVERAGE_ENDPOINT}`;
const API_GET_JIT_FAMILIES = `${INBOUND_BASE_URL}${JIT_ENDPOINT}${JIT_FAMILIES_ENDPOINT}`;
const API_GET_CLOSE_CONTAINER = `${API_GET_JIT_FAMILIES}/cc`;
const API_GET_GENERAL_DATA = `${API_GET_JIT_FAMILIES}/detail`;
const API_GET_JIT_FAMILIES_FILTERS = `${INBOUND_BASE_URL}${JIT_FILTERS_ENDPOINT}/${JIT_FAMILIES_ENDPOINT}`;
const API_DOWNLOAD_JIT_COVERAGE_PARTS = (providerName: string) =>
  `${INBOUND_BASE_URL}${JIT_ENDPOINT}${providerName}/download`;
export {
  API_GET_STOCK_JIT_BY_PROVIDER,
  API_GET_JIT_FILTERS,
  API_GET_FAMILY_COVERAGE,
  API_GET_JIT_FAMILIES,
  API_GET_CLOSE_CONTAINER,
  API_GET_GENERAL_DATA,
  API_GET_JIT_FAMILIES_FILTERS,
  API_DOWNLOAD_JIT_COVERAGE_PARTS
};
