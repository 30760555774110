import {
  ITransferStockDTO,
  ITransferStockItemDTO
} from 'modules/inbound/transfer/infrastructure/dto/TransferStockDTO';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { TransferText } from '../infrastructure/i18n/TransferText';
import { TransferTranslations } from '../infrastructure/i18n/typing';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { CriticalStockType } from 'modules/shared/domain/enums';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

const transferTranslations: TransferTranslations = {
  ...TransferText[LocaleService.getLanguage()]
};

export interface ITransferStock {
  lastUpdate: string;
  stockLocationItems: ITransferStockItem[];
  columns: ColumnsInfo[];
}

export interface ITransferStockItem {
  code: string;
  description: string;
  location: string;
  warehouse: string;
  warehouseType: string;
  handlingUnit: number | string;
  factoryCoverage: string;
  supplyContainer: string;
  transportContainer: string;
  basicContainer: string;
  criticalStockType: CriticalStockType | null;
  coverageWithoutStock: string;
  supplyType: string;
  containerType: string;
  packagingMaterial: string;
  isPlanned: boolean;
}

export interface ColumnsInfo {
  key: string;
  uiKey: string;
  value: string;
  sortable: boolean;
  isVisibleCheck: boolean;
}

export enum TransferStockColumnKeys {
  code = 'code',
  description = 'description',
  location = 'location',
  warehouse = 'warehouse',
  warehouseType = 'warehouseType',
  handlingUnit = 'handlingUnit',
  factoryCoverage = 'factoryCoverage',
  coverageWithoutStock = 'coverageWithoutStock',
  isPlanned = 'isPlanned',
  supplyType = 'supplyType',
  containerType = 'containerType',
  packagingMaterial = 'packagingMaterial',
  basicContainer = 'basicContainer',
  transportContainer = 'transportContainer',
  supplyContainer = 'supplyContainer'
}

const SORTABLE_COLUMNS = [
  TransferStockColumnKeys.code,
  TransferStockColumnKeys.description,
  TransferStockColumnKeys.location,
  TransferStockColumnKeys.warehouse,
  TransferStockColumnKeys.warehouseType,
  TransferStockColumnKeys.handlingUnit,
  TransferStockColumnKeys.factoryCoverage,
  TransferStockColumnKeys.coverageWithoutStock,
  TransferStockColumnKeys.supplyType,
  TransferStockColumnKeys.basicContainer,
  TransferStockColumnKeys.transportContainer,
  TransferStockColumnKeys.supplyContainer,
  TransferStockColumnKeys.containerType,
  TransferStockColumnKeys.packagingMaterial,
  TransferStockColumnKeys.isPlanned
];

const RIGHT_ALIGNED_COLUMNS = [
  TransferStockColumnKeys.coverageWithoutStock,
  TransferStockColumnKeys.factoryCoverage
];

class TransferStock implements ITransferStock {
  lastUpdate: string;
  stockLocationItems: ITransferStockItem[];
  columns: ColumnsInfo[];
  pagination: IPagination;

  constructor({
    lastUpdate,
    stockLocationItems,
    columns,
    pagination
  }: TransferStock) {
    this.lastUpdate = lastUpdate;
    this.stockLocationItems = stockLocationItems;
    this.columns = columns;
    this.pagination = pagination;
  }
  private static _camelToSnakeCase(key) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  /** Get coverage in format 2d 4h 24min */
  private static _getCoverageTimeFormat(coverage) {
    if (coverage !== null && coverage > 0) {
      const days = Math.floor(coverage);
      const hours = Math.round((coverage - Math.floor(coverage)) * 24);
      const remainingHours = (coverage - Math.floor(coverage)) * 24 - hours;
      const minutes = Math.round(remainingHours * 60);
      if (minutes > 0) {
        return `${days}d ${hours}h ${minutes}min`;
      } else if (hours > 0) {
        return `${days}d ${hours}h`;
      }
      return `${days}d`;
    }
    if (coverage === 0) {
      return `0d`;
    }
    return NO_DATA;
  }

  static generateFromDTO({
    last_update,
    stock_location_item,
    pagination
  }: ITransferStockDTO): TransferStock {
    return new TransferStock({
      lastUpdate: last_update
        ? `${GetDateFormatedService.getDateFormated(
            last_update,
            'de-DE'
          )} | ${GetDateFormatedService.getTimeFormated(last_update)}`
        : NO_DATA,
      stockLocationItems: stock_location_item.map(
        ({
          code,
          description,
          location,
          warehouse,
          warehouse_type,
          handling_unit,
          factory_coverage,
          supply_container,
          transport_container,
          basic_container,
          critical_stock_type,
          coverage_without_stock,
          supply_type,
          container_type,
          packaging_material,
          is_planned
        }: ITransferStockItemDTO): ITransferStockItem => ({
          code,
          description: description ?? NO_DATA,
          location,
          warehouse,
          warehouseType: warehouse_type,
          handlingUnit: handling_unit ? Number(handling_unit) : NO_DATA,
          factoryCoverage: this._getCoverageTimeFormat(factory_coverage),
          supplyContainer: supply_container,
          transportContainer: transport_container,
          basicContainer: basic_container,
          criticalStockType: critical_stock_type as CriticalStockType,
          coverageWithoutStock: this._getCoverageTimeFormat(
            coverage_without_stock
          ),
          supplyType: supply_type ?? NO_DATA,
          containerType: container_type ?? NO_DATA,
          packagingMaterial: packaging_material ?? NO_DATA,
          isPlanned: is_planned
        })
      ),
      columns: Object.keys(TransferStockColumnKeys).map((key) => ({
        key: this._camelToSnakeCase(key),
        uiKey: key,
        value: transferTranslations[key] || key,
        sortable: SORTABLE_COLUMNS.includes(TransferStockColumnKeys[key]),
        isVisibleCheck: true,
        tooltipInfo:
          key === TransferStockColumnKeys.coverageWithoutStock &&
          transferTranslations.coverageStoredOutsideTranfer,
        draggable: key !== TransferStockColumnKeys.code,
        align:
          RIGHT_ALIGNED_COLUMNS.includes(TransferStockColumnKeys[key]) &&
          'right'
      })),
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty() {
    return new TransferStock({
      lastUpdate: '',
      stockLocationItems: [],
      columns: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default TransferStock;
