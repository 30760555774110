import IMessagesRepository from '../../domain/InboundMessages/IMessagesRepository';
import InboundMessages from '../../domain/InboundMessages/InboundMessages';
import MessagesRepository from '../../infraestructure/repositories/MessagesRepository';

class GetInboundMessagesUseCase {
  private _inboundMessagesRepository: IMessagesRepository;

  constructor({ repository }) {
    this._inboundMessagesRepository = repository;
  }

  execute({ body }: { body: { target_type: string; target_uids: string[] } }) {
    return this._inboundMessagesRepository.getMessages({ body }).then((res) => {
      return InboundMessages.generateFromDTO(res);
    });
  }
}

export { GetInboundMessagesUseCase };
export default new GetInboundMessagesUseCase({
  repository: MessagesRepository
});
