export const MODELS_DICTIONARY = {
  All: { name: 'All', imageName: 'All', order: 0 },
  KF1: { name: 'Mii', imageName: 'KF1', order: 1 },
  KE1: { name: 'Mii E', imageName: 'KF1', order: 2 },
  KJ1: { name: 'Ibiza', imageName: 'KJ1', order: 3 },
  KJ7: { name: 'Arona', imageName: 'KJ7', order: 4 },
  KL1: { name: 'New Leon', imageName: 'KL1', order: 5 },
  KL8: { name: 'New Leon ST', imageName: 'KL8', order: 6 },
  KHP: { name: 'New Ateca', imageName: 'KHP', order: 7 },
  KN2: { name: 'Tarraco', imageName: 'KN2', order: 8 },
  KM7: { name: 'Formentor', imageName: 'KM7', order: 9 },
  '711': { name: 'Alhambra', imageName: '711', order: 10 },
  K11: { name: 'Born', imageName: 'K11', order: 11 },
  '5F1': { name: 'Leon', imageName: '5F1', order: 12 },
  '5F8': { name: 'Leon ST', imageName: '5F8', order: 13 },
  KH7: { name: 'Ateca', imageName: 'KH7', order: 14 }
};
