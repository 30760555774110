import { AlertsDTO } from '../infrastructure/dto/RefillsAlertsDTO';
import { RefillsStatusType } from '../infrastructure/dto/types';

interface Stats {
  counter: number;
  status: RefillsStatusType;
}

interface AlertsItem {
  total: number;
  stats: Stats[];
}

export interface Alerts {
  transfer: AlertsItem;
  directToLine: AlertsItem;
}
interface GenerateFromDTOParams {
  alerts: AlertsDTO;
}
class RefillsAlertsData {
  alerts: Alerts;

  constructor({ alerts }: RefillsAlertsData) {
    this.alerts = alerts;
  }

  static generateFromDTO({ alerts }: GenerateFromDTOParams): RefillsAlertsData {
    return new RefillsAlertsData({
      alerts: {
        directToLine: {
          total: alerts.direct_to_line.total,
          stats: alerts.direct_to_line.stats
        },
        transfer: {
          total: alerts.transfer.total,
          stats: alerts.transfer.stats
        }
      }
    });
  }
}

export default RefillsAlertsData;
