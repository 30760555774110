import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import {
  API_GET_STOCK_BY_PROVIDER,
  API_GET_NOJIT_FILTERS,
  API_GET_NOJIT_COVERAGE_AT_RISK,
  API_GET_DELIVERY_STATUSES,
  API_DOWNLOAD_NOJIT,
  API_GET_NOJIT_PLANNING,
  API_PUT_COMMENT
} from './urls';
import NoJitDataDTO from 'modules/inbound/nojit/infrastructure/dto/NoJitDataDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { INoJitRepository } from '../../domain/INoJitRepository';
import NoJitFiltersDataDTO from '../dto/NoJitFiltersDTO';
import CoverageAtRiskDTO from 'modules/shared/infrastructure/dto/CoverageAtRiskDTO';
import { deliveryStatusesDTOMapper } from '../dto/deliveryStatusesDTOMapper';
import { DeliveryStatusesData } from '../../domain/DeliveryStatusesData';
import { noJitPlanningDTOMapper } from '../dto/noJitPlanningDTOMapper';

class NoJitRepository extends BaseRepository implements INoJitRepository {
  private _api: IApi;
  private _apiGetStockByProvider: (providerName: string) => string;
  private _apiGetNoJitFilters: string;
  private _apiGetNoJitCoverageAtRisk: string;
  private _apiGetDeliveryStatuses: string;
  private _apiGetDownloadNojit: string;
  private _apiGetNoJitPlanning: string;
  private _apiPutComment: (code: string) => string;

  constructor({
    api,
    apiGetStockByProvider,
    apiGetNoJitFilters,
    apiGetNoJitCoverageAtRisk,
    apiGetDeliveryStatuses,
    apiGetDownloadNojit,
    apiGetNoJitPlanning,
    apiPutComment
  }) {
    super();
    this._api = api;
    this._apiGetStockByProvider = apiGetStockByProvider;
    this._apiGetNoJitFilters = apiGetNoJitFilters;
    this._apiGetNoJitCoverageAtRisk = apiGetNoJitCoverageAtRisk;
    this._apiGetDeliveryStatuses = apiGetDeliveryStatuses;
    this._apiGetDownloadNojit = apiGetDownloadNojit;
    this._apiGetNoJitPlanning = apiGetNoJitPlanning;
    this._apiPutComment = apiPutComment;
  }

  get({ queryParams, username }: { queryParams: object; username: string }) {
    const url = `${this._apiGetStockByProvider(
      username
    )}${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new NoJitDataDTO(res));
  }

  getNoJitFilters({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetNoJitFilters}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new NoJitFiltersDataDTO(res));
  }

  getNoJitCoverageAtRisk({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetNoJitCoverageAtRisk}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new CoverageAtRiskDTO(res));
  }

  getDeliveryStatuses({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetDeliveryStatuses}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => deliveryStatusesDTOMapper(res))
      .then((res) => DeliveryStatusesData.generateFromDto(res));
  }

  downloadNojit({ queryParams }) {
    const url = `${this._apiGetDownloadNojit}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  getNoJitPlanning({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetNoJitPlanning}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => noJitPlanningDTOMapper(res));
  }

  updateComment({ code, body }: { code: string; body: { text: string } }) {
    return this._api
      .put(`${this._apiPutComment(code)}`, body)
      .then((res) => res);
  }
}

export default new NoJitRepository({
  api: api,
  apiGetStockByProvider: API_GET_STOCK_BY_PROVIDER,
  apiGetNoJitFilters: API_GET_NOJIT_FILTERS,
  apiGetNoJitCoverageAtRisk: API_GET_NOJIT_COVERAGE_AT_RISK,
  apiGetDeliveryStatuses: API_GET_DELIVERY_STATUSES,
  apiGetDownloadNojit: API_DOWNLOAD_NOJIT,
  apiGetNoJitPlanning: API_GET_NOJIT_PLANNING,
  apiPutComment: API_PUT_COMMENT
});

export { NoJitRepository };
