export enum COLORS {
  RED = 'red700',
  YELLOW = 'yellow700',
  GREEN = 'green700'
}

export enum Status {
  DELAYED = 'DELAYED',
  ON_TIME = 'ON_TIME',
  NEAR_DELAY = 'NEAR_DELAY'
}

export const BarStatus = {
  [Status.DELAYED]: COLORS.RED,
  [Status.ON_TIME]: COLORS.GREEN,
  [Status.NEAR_DELAY]: COLORS.YELLOW
};

export const SelectedStatusColor = {
  [COLORS.RED]: Status.DELAYED,
  [COLORS.GREEN]: Status.ON_TIME,
  [COLORS.YELLOW]: Status.NEAR_DELAY
};
