import { ExternalRoutes, InboundRoutes, RouteItemProps } from './types';
import {
  WarehouseCapacityMasterPage,
  LineGroupsMasterPage,
  UsersPage,
  NoJitPage,
  JitFamilyCoveragePage,
  DirectDeliveryPage,
  FactoryBcnPage,
  SuppliesPage,
  MaterialInTransferPage,
  TransfersNextHoursPage,
  RefillsPage,
  EndOfProductionDatePage,
  EntriesNextHoursPage,
  EntriesUnloadingPointPage,
  EntriesInPlantPage,
  PartCoveragePage,
  FamiliesCoverage,
  ExpirationPage,
  StocksPage,
  MastersPanelPage,
  StucklisteSuppliersPage
} from './pageComponents';
import { UserDashboards } from 'modules/shared/domain/UserRole';
import { ReactComponent as InhouseIcon } from 'assets/icons/inhouseIcon.svg';
import { ReactComponent as ManagementIcon } from 'assets/icons/settingsIcon.svg';
import { ReactComponent as InboundInhouseIcon } from 'assets/icons/gridThinIcon.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/truckIcon.svg';

export const INBOUND_ROUTES: RouteItemProps[] = [
  {
    name: 'inbound',
    icon: TruckIcon,
    subItems: [
      {
        name: 'analytics',
        id: UserDashboards.ANALYTICS,
        path: ExternalRoutes.PL7_SUPPLY_CHAIN,
        element: undefined
      },
      {
        name: 'directDelivery',
        id: UserDashboards.DD,
        path: InboundRoutes.DIRECT_DELIVERY,
        element: DirectDeliveryPage
      },
      {
        name: 'factoryBCN',
        id: UserDashboards.FACTORYBCN,
        path: InboundRoutes.FACTORY_BCN,
        element: FactoryBcnPage
      },
      {
        name: 'noJit',
        id: UserDashboards.NOJIT,
        path: InboundRoutes.NO_JIT,
        element: NoJitPage
      },
      {
        name: 'stucklisteSuppliers',
        id: UserDashboards.STUCKLISTE_SUPPLIERS,
        path: InboundRoutes.STUCKLISTE_SUPPLIERS,
        element: StucklisteSuppliersPage
      },
      {
        name: 'jit',
        id: UserDashboards.JIT,
        subItems: [
          {
            name: 'jitFamilyCoverages',
            id: UserDashboards.JIT,
            path: InboundRoutes.JIT_FAMILY_COVERAGE,
            element: JitFamilyCoveragePage
          },
          {
            name: 'partCoverage',
            id: UserDashboards.JIT,
            path: InboundRoutes.JIT_PART_COVERAGE,
            element: PartCoveragePage
          },
          {
            name: 'familyCoverage',
            id: UserDashboards.JIT,
            path: InboundRoutes.JIT_FAMILIES,
            element: FamiliesCoverage
          }
        ]
      },
      {
        name: 'stocks',
        id: UserDashboards.STOCKS,
        path: InboundRoutes.STOCKS,
        element: StocksPage
      }
    ]
  },
  {
    name: 'inhouse',
    icon: InhouseIcon,
    subItems: [
      {
        name: 'supplies',
        id: UserDashboards.SUPPLIES,
        path: InboundRoutes.SUPPLIES,
        element: SuppliesPage
      },
      {
        name: 'transfers',
        id: UserDashboards.TRANSFERS,
        subItems: [
          {
            name: 'materialInTransfer',
            id: UserDashboards.TRANSFERS,
            path: InboundRoutes.MATERIAL_IN_TRANSFER,
            element: MaterialInTransferPage
          },
          {
            name: 'transferNextHours',
            id: UserDashboards.TRANSFERS,
            path: InboundRoutes.TRANSFER_NEXT_HOURS,
            element: TransfersNextHoursPage
          }
        ]
      },
      {
        name: 'refills',
        id: UserDashboards.REFILLS,
        path: InboundRoutes.REFILLS,
        element: RefillsPage
      }
    ]
  },
  {
    name: 'inboundInhouse',
    icon: InboundInhouseIcon,
    subItems: [
      {
        name: 'endOfProductionDate',
        id: UserDashboards.ENDOFPRODUCTION,
        path: InboundRoutes.END_OF_PRODUCTION,
        element: EndOfProductionDatePage
      },
      {
        name: 'expiration',
        id: UserDashboards.EXPIRATION,
        path: InboundRoutes.EXPIRATION,
        element: ExpirationPage
      },
      {
        name: 'entries',
        id: UserDashboards.ENTRIES,
        subItems: [
          {
            name: 'entriesNextHours',
            id: UserDashboards.ENTRIES,
            path: InboundRoutes.ENTRIES_NEXT_HOURS,
            element: EntriesNextHoursPage
          },
          {
            name: 'entriesUnloadingPoints',
            id: UserDashboards.ENTRIES,
            path: InboundRoutes.ENTRIES_UNLOADING_POINTS,
            element: EntriesUnloadingPointPage
          },
          {
            name: 'entriesInPlant',
            id: UserDashboards.ENTRIES,
            path: InboundRoutes.ENTRIES_IN_PLANT,
            element: EntriesInPlantPage
          }
        ]
      }
    ]
  },
  {
    name: 'management',
    icon: ManagementIcon,
    subItems: [
      {
        name: 'mastersPanel',
        id: UserDashboards.MAESTROS,
        path: InboundRoutes.MASTERS_PANEL,
        element: MastersPanelPage
      },
      {
        name: 'warehouseCapacity',
        id: UserDashboards.MAESTROS,
        path: InboundRoutes.MASTER_WAREHOUSE_CAPACITY,
        element: WarehouseCapacityMasterPage
      },
      {
        name: 'lineGroups',
        id: UserDashboards.MAESTROS,
        path: InboundRoutes.MASTER_LINE_GROUPS,
        element: LineGroupsMasterPage
      },
      {
        name: 'userManagement',
        id: UserDashboards.USERS,
        path: InboundRoutes.USERS,
        element: UsersPage
      }
    ]
  }
];
