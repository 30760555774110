import { ColumnProps } from '../domain/ColumnsTypes';

interface GetColumnsFromLocalStorageProps {
  username: string;
  localStorageKey: string;
  defaultColumns: ColumnProps[];
}

const areTheSameColumns = (
  parsedColumns: ColumnProps[],
  defaultColumns: ColumnProps[]
) => {
  if (parsedColumns.length !== defaultColumns.length) {
    return false;
  }

  const parsedColumnsKeys: string[] = parsedColumns.map((col) => col.key);
  const parsedColumnsKeysSorted = [...parsedColumnsKeys].sort((a, b) =>
    a.localeCompare(b)
  );

  const defaultColumnsKeys: string[] = defaultColumns.map((col) => col.key);
  const defaultColumnsKeysSorted = [...defaultColumnsKeys].sort((a, b) =>
    a.localeCompare(b)
  );

  return (
    JSON.stringify(parsedColumnsKeysSorted) ===
    JSON.stringify(defaultColumnsKeysSorted)
  );
};

const mapSavedColumns = (
  parsedColumns: ColumnProps[],
  defaultColumns: ColumnProps[]
): ColumnProps[] => {
  let mappedColumns: ColumnProps[] = [];

  parsedColumns.forEach((parsedColumn) => {
    const defaultCol = defaultColumns.find(
      (currentColumn) => currentColumn.key === parsedColumn.key
    );

    if (defaultCol) {
      mappedColumns.push({
        ...defaultCol,
        isVisibleCheck: parsedColumn.isVisibleCheck ?? true
      });
    }
  });

  return mappedColumns;
};

/**
 *
 * @param username
 * @param localStorageKey
 * @param defaultColumns
 * @returns ColumnProps[]
 */
export const getColumnsFromLocalStorage = ({
  username,
  localStorageKey,
  defaultColumns
}: GetColumnsFromLocalStorageProps): ColumnProps[] => {
  let parseColumnsSavedInLocalStorage: ColumnProps[] = [];

  const columnsSavedInLocalStorage = localStorage.getItem(
    `${username}-${localStorageKey}`
  );

  if (columnsSavedInLocalStorage) {
    try {
      const parsedData = JSON.parse(columnsSavedInLocalStorage);

      if (Array.isArray(parsedData)) {
        parseColumnsSavedInLocalStorage = parsedData.filter(
          (column) => column.key
        );
      } else {
        // eslint-disable-next-line no-console
        console.error('Invalid columns data in localStorage:', parsedData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing columns saved in localStorage:', error);
    }
  }

  if (areTheSameColumns(parseColumnsSavedInLocalStorage, defaultColumns)) {
    return mapSavedColumns(parseColumnsSavedInLocalStorage, defaultColumns);
  }

  return [...defaultColumns];
};
