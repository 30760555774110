import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface SuppliesSearch {
  [key: string]: string[] | string | boolean;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

export const suppliesSearchQueryBuilder = (search: SuppliesSearch): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.CODE:
          queryString += values
            .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
        case NameValues.DESCRIPTION:
          queryString += values
            .map(
              (value) =>
                `${NameValues.DESCRIPTION}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.OT_NUMBER:
          queryString += values
            .map(
              (value) => `${NameValues.OT_NUMBER}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.WAREHOUSE_UNIT_NUMBER:
          queryString += values
            .map(
              (value) =>
                `${NameValues.WAREHOUSE_UNIT_NUMBER}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.PROVIDER_DELIVERY_NOTE:
          queryString += values
            .map(
              (value) =>
                `${NameValues.PROVIDER_DELIVERY_NOTE}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
      }

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};
