interface IAgingByCompoundDTO {
  compoundName: string;
  intervals: {
    '0to90': number;
    '91to120': number;
    '121to180': number;
    moreThan180: number;
  };
}

export interface IPipelineDetailedCarInfoDTO {
  totalCars: number;
  invoicedAging: IAgingByCompoundDTO[];
  inTransitAging: IAgingByCompoundDTO[];
}

class PipelineDetailedCarInfoDTO {
  totalCars: number;
  invoicedAging: IAgingByCompoundDTO[];
  inTransitAging: IAgingByCompoundDTO[];

  constructor({ totalCars, invoicedAging, inTransitAging }) {
    this.totalCars = totalCars;
    this.invoicedAging = invoicedAging;
    this.inTransitAging = inTransitAging;
  }
}

export default PipelineDetailedCarInfoDTO;
