import {
  IPagination,
  Pagination
} from 'modules/inbound/shared/domain/IPagination';
import EndOfProductionDateDataDTO from '../infrastructure/dto/EndOfProductionDateDataDTO';
import { EndOfProductionStock } from './EndOfProductionStock';

export interface IEndOfProductionDate {
  endOfStock: EndOfProductionStock[];
  pagination: IPagination;
}

export const endOfProductionDataMapper = (
  dto: EndOfProductionDateDataDTO
): IEndOfProductionDate => {
  return {
    endOfStock: dto.end_of_stock.map((stock) =>
      EndOfProductionStock.generateFromDTO(stock)
    ),
    pagination: Pagination.generateFromDTO(dto.pagination)
  };
};
