const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const SUPPLIES_ENDPOINT = '/supplies';
const SUPPLIES_REQUESTS_STATUS_ENDPOINT = '/supplies/status';
const SUPPLIES_CLOSED_STATUS_ENDPOINT = '/supplies/closed/status';
const WAREHOUSES_TARGET_ENDPOINT = '/supplies/warehouse-target/status';
const DOWNLOAD_SUPPLIES = `${SUPPLIES_ENDPOINT}/download`;
const WAREHOUSES_RANKING_ENDPOINT = '/supplies/warehouse-source/status';
const SUBZONES_RANKING_ENDPOINT = `${SUPPLIES_ENDPOINT}/warehouse-source-type/status`;
const API_GET_SUPPLIES = `${INBOUND_BASE_URL}${SUPPLIES_ENDPOINT}`;
const API_GET_REQUESTS_STATUS = `${INBOUND_BASE_URL}${SUPPLIES_REQUESTS_STATUS_ENDPOINT}`;
const API_GET_CLOSED_STATUS = `${INBOUND_BASE_URL}${SUPPLIES_CLOSED_STATUS_ENDPOINT}`;
const SUPPLIES_FILTER_ENDPOINT = '/supplies/filters';
const API_GET_SUPPLIES_FILTERS = `${INBOUND_BASE_URL}${SUPPLIES_FILTER_ENDPOINT}`;
const SUPPLIES_CRITICAL_STOCK = '/supplies/critical-stock';
const API_GET_SUPPLIES_CRITICAL_STOCK = `${INBOUND_BASE_URL}${SUPPLIES_CRITICAL_STOCK}`;
const API_GET_SUPPLIES_CRITICAL_STOCK_SUMMARY = `${INBOUND_BASE_URL}${SUPPLIES_CRITICAL_STOCK}/summary`;
const API_GET_WAREHOUSES_TARGET = `${INBOUND_BASE_URL}${WAREHOUSES_TARGET_ENDPOINT}`;
const API_DOWNLOAD_SUPPLIES = `${INBOUND_BASE_URL}${DOWNLOAD_SUPPLIES}`;
const API_GET_WAREHOUSES_RANKING = `${INBOUND_BASE_URL}${WAREHOUSES_RANKING_ENDPOINT}`;
const API_GET_SUBZONES_RANKING = `${INBOUND_BASE_URL}${SUBZONES_RANKING_ENDPOINT}`;

export {
  API_GET_SUPPLIES,
  API_GET_SUPPLIES_FILTERS,
  API_GET_REQUESTS_STATUS,
  API_GET_CLOSED_STATUS,
  API_GET_SUPPLIES_CRITICAL_STOCK,
  API_GET_SUPPLIES_CRITICAL_STOCK_SUMMARY,
  API_GET_WAREHOUSES_TARGET,
  API_DOWNLOAD_SUPPLIES,
  API_GET_WAREHOUSES_RANKING,
  API_GET_SUBZONES_RANKING
};
