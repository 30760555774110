import { createSlice } from '@reduxjs/toolkit';
import {
  FiltersReducerProps,
  OrderType,
  DateRangeType,
  GeolocationOrderType
} from './typing';
import getDateRangeService from 'modules/outbound/shared/domain/GetDateRangeService';
import { BrandEnum } from 'modules/shared/domain/enums';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';

export const initialState: FiltersReducerProps = {
  fulfillment: {
    selectedCountries: [],
    selectedCriteria: OrderType.NONE,
    selectedDateRange: DateRangeType.WTD,
    customDateRange: { fromDate: null, toDate: null },
    selectedClientType: [],
    fastLaneSelected: false,
    selectedFactory: [],
    selectedBrands: [BrandEnum.CUPRA, BrandEnum.SEAT, BrandEnum.AUDI],
    logisticObjectiveSelected: false,
    commercialPackageSelected: [],
    viewRoutes: false,
    versandtreueSelected: true
  },
  geolocation: {
    selectedCountries: [],
    selectedCriteria: GeolocationOrderType.NONE,
    fastLaneSelected: false,
    selectedClientType: [],
    selectedFactory: [],
    selectedBrands: [BrandEnum.CUPRA, BrandEnum.SEAT, BrandEnum.AUDI],
    logisticObjectiveSelected: false,
    commercialPackageSelected: [],
    deliveryPointsSelected: []
  }
};

export const FILTERS_STATE_KEY = 'FiltersState';

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateCountries: (state, action) => {
      state.fulfillment.selectedCountries = action.payload;
    },
    updateGeolocationCountries: (state, action) => {
      state.geolocation.selectedCountries = action.payload;
    },
    updateCriteria: (state, action) => {
      if (Object.values(OrderType).includes(action.payload)) {
        state.fulfillment.selectedCriteria = action.payload;
      }
    },
    updateGeolocationCriteria: (state, action) => {
      if (Object.values(GeolocationOrderType).includes(action.payload)) {
        state.geolocation.selectedCriteria = action.payload;
      }
    },
    updateClientType: (state, action) => {
      state.fulfillment.selectedClientType = action.payload;
    },
    updateGeolocationClientType: (state, action) => {
      state.geolocation.selectedClientType = action.payload;
    },
    selectFastLane: (state, action) => {
      state.fulfillment.fastLaneSelected = action.payload;
    },
    selectGeolocationFastLane: (state, action) => {
      state.geolocation.fastLaneSelected = action.payload;
    },
    selectLogisticObjective: (state, action) => {
      state.fulfillment.logisticObjectiveSelected = action.payload;
    },
    selectGeolocationLogisticObjective: (state, action) => {
      state.geolocation.logisticObjectiveSelected = action.payload;
    },
    updateSelectedFactory: (state, action) => {
      state.fulfillment.selectedFactory = action.payload;
    },
    updateGeolocationFactory: (state, action) => {
      state.geolocation.selectedFactory = action.payload;
    },
    updateSelectedBrands: (state, action) => {
      state.fulfillment.selectedBrands = action.payload;
    },
    updateGeolocationSelectedBrands: (state, action) => {
      state.geolocation.selectedBrands = action.payload;
    },
    selectVersandtreue: (state, action) => {
      state.fulfillment.versandtreueSelected = action.payload;
    },
    updateDateRange: (state, action) => {
      if (Object.values(DateRangeType).includes(action.payload.type)) {
        state.fulfillment.selectedDateRange = action.payload.type;
        state.fulfillment.customDateRange =
          action.payload.value || initialState.fulfillment.customDateRange;
      }
    },
    updateSelectedCommercialPackages: (state, action) => {
      state.fulfillment.commercialPackageSelected = action.payload;
    },
    updateGeolocationSelectedCommercialPackages: (state, action) => {
      state.geolocation.commercialPackageSelected = action.payload;
    },
    updateGeolocationSelectedDeliveryPoints: (state, action) => {
      state.geolocation.deliveryPointsSelected = action.payload;
    },
    selectViewRoutes: (state, action) => {
      state.fulfillment.viewRoutes = action.payload;
    },
    saveFilters: (state) => {
      localStorage.setItem(FILTERS_STATE_KEY, JSON.stringify(state));
    },
    resetFilters: (state) => {
      state.fulfillment = initialState.fulfillment;
    },
    loadFilters: (state) => {
      const localStoragefilters = getLocalStorage<FiltersReducerProps>(
        FILTERS_STATE_KEY,
        initialState
      );
      state.fulfillment = localStoragefilters.fulfillment;
    },
    updateDateSavedFilters: (_, action) => {
      const filters = localStorage.getItem(FILTERS_STATE_KEY);
      if (filters) {
        const parsedFilters = JSON.parse(filters);

        localStorage.setItem(
          FILTERS_STATE_KEY,
          JSON.stringify({
            ...parsedFilters,
            fulfillment: {
              ...parsedFilters.fulfillment,
              selectedDateRange: action.payload
            }
          })
        );
      }
    }
  }
});

//selectors
export const getSelectedClientType = (state) =>
  state.filtersState.fulfillment.selectedClientType;

export const getSelectedClientTypeInPlainFormat = (state) =>
  state.filtersState.fulfillment.selectedClientType.map((item) => item.key);

export const getSelectedGeolocationClientType = (state) =>
  state.filtersState.geolocation.selectedClientType;

export const getSelectedGeolocationClientTypeInPlainFormat = (state) =>
  state.filtersState.geolocation.selectedClientType.map((item) => item.key);

export const getSelectedDateRange = (state) =>
  state.filtersState.fulfillment.selectedDateRange;

export const getSelectedDateRangeFormated = (state) => {
  if (
    state.filtersState.fulfillment.selectedDateRange === DateRangeType.Custom
  ) {
    return getDateRangeService.formatDateFromDates(
      state.filtersState.fulfillment.customDateRange.fromDate,
      state.filtersState.fulfillment.customDateRange.toDate
    );
  }

  return getDateRangeService.formatDateFromRange(
    state.filtersState.fulfillment.selectedDateRange
  );
};

export const getDateSelectorsList = () =>
  Object.keys(DateRangeType).map((key) => ({ key, text: DateRangeType[key] }));

export const getFormatedCustomDateForTheUI = (state) => {
  const { fromDate, toDate } = getSelectedDateRangeFormated(state);
  return `${getOrderedDateForTheUI(fromDate)} - ${getOrderedDateForTheUI(
    toDate
  )}`;
};

const getOrderedDateForTheUI = (date) => {
  const newDate = date.split('-');
  return newDate[2] + '/' + newDate[1] + '/' + newDate[0].substring(2);
};

export const getSelectedCountries = (state) =>
  state.filtersState.fulfillment.selectedCountries;

export const getSelectedCriteria = (state) =>
  state.filtersState.fulfillment.selectedCriteria;

export const getSelectedCountriesISO = (state) =>
  state.filtersState.fulfillment.selectedCountries.map((item) => item.key);

export const getGeolocationSelectedCountries = (state) =>
  state.filtersState.geolocation.selectedCountries;

export const getGeolocationSelectedCountriesISO = (state) =>
  state.filtersState.geolocation.selectedCountries.map((item) => item.key);

export const getSelectedCustomDateRange = (state) =>
  state.filtersState.fulfillment.customDateRange;

export const getFastLaneSelected = (state) =>
  state.filtersState.fulfillment.fastLaneSelected;

export const getGeolocationFastLaneSelected = (state) =>
  state.filtersState.geolocation.fastLaneSelected;

export const getLogisticObjectiveSelected = (state) =>
  state.filtersState.fulfillment.logisticObjectiveSelected;

export const getGeolocationLogisticObjectiveSelected = (state) =>
  state.filtersState.geolocation.logisticObjectiveSelected;

export const getSelectedFactoryInPlainFormat = (state) =>
  state.filtersState.fulfillment.selectedFactory.map((item) => item.key);

export const getSelectedFactory = (state) =>
  state.filtersState.fulfillment.selectedFactory;

export const getSelectedGeolocationFactoryInPlainFormat = (state) =>
  state.filtersState.geolocation.selectedFactory.map((item) => item.key);

export const getGeolocationFactory = (state) =>
  state.filtersState.geolocation.selectedFactory;

export const getSelectedBrands = (state) =>
  state.filtersState.fulfillment.selectedBrands;

export const getGeolocationSelectedBrands = (state) =>
  state.filtersState.geolocation.selectedBrands;

export const getSelectedCommercialPackages = (state) =>
  state.filtersState.fulfillment.commercialPackageSelected;

export const getGeolocationSelectedCommercialPackages = (state) =>
  state.filtersState.geolocation.commercialPackageSelected;

export const getSelectedCommercialPackagesInPlainFormat = (state) =>
  state.filtersState.fulfillment.commercialPackageSelected.map(
    (item) => item.key
  );

export const getGeolocationSelectedCommercialPackagesInPlainFormat = (state) =>
  state.filtersState.geolocation.commercialPackageSelected.map(
    (item) => item.key
  );

export const getGeolocationSelectedDeliveryPoints = (state) =>
  state.filtersState.geolocation.deliveryPointsSelected;

export const getGeolocationSelectedDeliveryPointsInPlainFormat = (state) =>
  state.filtersState.geolocation.deliveryPointsSelected.map((item) => item.key);

export const getGeolocationSelectedCriteria = (state) =>
  state.filtersState.geolocation.selectedCriteria;

export const getSelectedViewRoutes = (state) =>
  state.filtersState.fulfillment.viewRoutes;

export const getVersandtreueSelected = (state) =>
  state.filtersState.fulfillment.versandtreueSelected;

//actions
export const {
  updateGeolocationCriteria,
  updateClientType,
  updateGeolocationClientType,
  selectFastLane,
  selectGeolocationFastLane,
  updateSelectedFactory,
  updateGeolocationFactory,
  updateSelectedBrands,
  updateGeolocationSelectedBrands,
  selectLogisticObjective,
  selectGeolocationLogisticObjective,
  updateSelectedCommercialPackages,
  updateGeolocationSelectedCommercialPackages,
  updateGeolocationSelectedDeliveryPoints,
  updateDateRange,
  updateGeolocationCountries,
  selectViewRoutes,
  selectVersandtreue,
  updateDateSavedFilters
} = filtersSlice.actions;

export default filtersSlice;
