import PipelineStockDTO from 'modules/outbound/stock/infrastructure/dto/PipelineStockDTO';

class PipelineStockResponseDTO {
  summary: PipelineStockDTO;
  models: PipelineStockDTO[];

  constructor({ summary, models }) {
    this.summary = new PipelineStockDTO(summary);
    this.models = models.map((model) => new PipelineStockDTO(model));
  }
}

export default PipelineStockResponseDTO;
