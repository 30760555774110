import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface NojitFilters {
  [key: string]: string[] | string | boolean;
}

export interface NojitSearch {
  [key: string]: string[] | string | boolean;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

export const nojitSearchQueryBuilder = (search: NojitSearch): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.CODE:
          queryString += values
            .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
        case NameValues.NAME:
          queryString += values
            .map((value) => `${NameValues.NAME}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
      }

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};

export const nojitFiltersQueryBuilder = (filters: NojitFilters): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      const multiple = values.length > 1;
      switch (name) {
        case NameValues.LINES:
          queryString += `${NameValues.LINES}${
            SearchOperators.LK
          }(${values.join(';')})`;
          break;
        case NameValues.MODELS:
          queryString += `${NameValues.MODELS}${
            SearchOperators.LK
          }(${values.join(';')})`;
          break;
        default:
          queryString += multiple
            ? `${name}${SearchOperators.IN}(${values.join(';')})`
            : `${name}${SearchOperators.EQUAL}${values[0]}`;
          break;
      }
    }

    queryString += '||';
  });
  return queryString.slice(0, -2);
};
