export const MODELS_AND_BRAND_DICTIONARY = {
  'All-All': { name: 'All', imageName: 'All', order: 0 },
  'S-KF1': { name: 'Mii', imageName: 'KF1', order: 1 },
  'S-KE1': { name: 'Mii E', imageName: 'KF1', order: 2 },
  'S-KJ1': { name: 'Ibiza', imageName: 'KJ1', order: 3 },
  'S-KJ7': { name: 'Arona', imageName: 'KJ7', order: 4 },
  'S-KL1': { name: 'New Leon', imageName: 'KL1', order: 5 },
  'C-KL1': { name: 'New Leon', imageName: 'C-KL1', order: 6 },
  'S-KL8': { name: 'New Leon ST', imageName: 'KL8', order: 7 },
  'C-KL8': { name: 'New Leon ST', imageName: 'C-KL8', order: 8 },
  'S-KHP': { name: 'New Ateca', imageName: 'KHP', order: 9 },
  'C-KHP': { name: 'New Ateca', imageName: 'C-KHP', order: 10 },
  'S-KN2': { name: 'Tarraco', imageName: 'KN2', order: 11 },
  'C-KM7': { name: 'Formentor', imageName: 'KM7', order: 12 },
  'S-711': { name: 'Alhambra', imageName: '711', order: 13 },
  'C-K11': { name: 'Born', imageName: 'K11', order: 14 },
  'S-5F1': { name: 'Leon', imageName: '5F1', order: 15 },
  'C-5F1': { name: 'Leon', imageName: 'C-5F1', order: 16 },
  'S-5F8': { name: 'Leon ST', imageName: '5F8', order: 17 },
  'S-KH7': { name: 'Ateca', imageName: 'KH7', order: 18 }
};
