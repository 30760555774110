export enum SuppliesStatusType {
  CLOSED = 'CLOSED',
  DELAYED = 'DELAYED',
  ON_TIME = 'ON_TIME',
  NEAR_DELAY = 'NEAR_DELAY'
}

export enum SearchModality {
  TO = 'TO',
  BETWEEN = 'BETWEEN',
  FROM = 'FROM'
}

export interface StatsItem {
  status: SuppliesStatusType;
  counter: number;
}

export type CriticalStockType =
  | 'IN_TRANSIT'
  | 'RECEPTION'
  | 'ST17'
  | 'TRANSFER'
  | 'NO_TRANSIT';

export type MaterialIndicator = 'FG' | 'PC';
