export const CLIENT_TYPE_DICTIONARY = [
  {
    language: 'en',
    clientType: {
      stock: 'Stock',
      fleet: 'Fleet',
      client: 'Client'
    }
  },
  {
    language: 'es',
    clientType: {
      stock: 'Stock',
      fleet: 'Flota',
      client: 'Cliente'
    }
  },
  {
    language: 'ca',
    clientType: {
      stock: 'Stock',
      fleet: 'Flota',
      client: 'Client'
    }
  }
];
