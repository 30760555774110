import { createAsyncThunk } from '@reduxjs/toolkit';
import getUsersDataService from 'modules/inbound/users/application/GetUsersDataService';
import downloadUsersDataService from 'modules/inbound/users/application/DownloadUsersDataService';
import createUsersDataService from 'modules/inbound/users/application/CreateUserDataService';
import resetUsersDataService from 'modules/inbound/users/application/ResetUsersDataService';
import deleteUsersDataService from 'modules/inbound/users/application/DeleteUsersDataService';
import updateUsersDataService from 'modules/inbound/users/application/UpdateUserDataService';
import {
  getCurrentPage,
  getFilterSearch,
  getResultsPerPage,
  getSelectedColumn,
  getSelectedUser
} from 'modules/inbound/users/ui/redux/UsersDataReducer';
import { SelectedUser } from './types';
import getUserDashboardsDataService from 'modules/inbound/users/application/GetUserDashboardsDataService';
import { UserDTO } from 'modules/inbound/users/infrastructure/dto/UsersDTO';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { UserRole, UserVisibility } from 'modules/shared/domain/UserRole';
import {
  Sort,
  ColumnSelected
} from 'modules/shared/layout/components/TableHeader/types';

const getUsersData = createAsyncThunk(
  'usersData/getUsersData',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const searchValue = getFilterSearch(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;
    const search =
      searchValue &&
      `{${NameValues.USER}:LK:${searchValue}|OR|${NameValues.EMAIL}:LK:${searchValue}}`;

    const queryParams = {
      page: currentPage,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: search
    };
    const response = await getUsersDataService.execute({ queryParams });
    return response;
  }
);

const downloadUsers = createAsyncThunk('usersData/downloadUsers', async () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const queryParams = {
    tz: timeZone
  };
  const response = await downloadUsersDataService.execute({
    queryParams
  });
  return response;
});

const createUserData = createAsyncThunk(
  'usersData/createUserData',
  async (_, { getState }) => {
    const appState = getState();
    const selectedUser: SelectedUser = getSelectedUser(appState);
    const userData: UserDTO = {
      email: selectedUser.email,
      username: selectedUser.username,
      groups: selectedUser.groups?.map((item) => item.key as UserVisibility),
      role: selectedUser.role.key as UserRole,
      provider_ids: selectedUser.providers.map((provider) => +provider.key),
      dashboards_ids: selectedUser.dashboards.map((dashboard) => +dashboard.key)
    };
    const response = await createUsersDataService.execute(userData);
    return response;
  }
);

const updateUserData = createAsyncThunk(
  'usersData/updateUserData',
  async (_, { getState }) => {
    const appState = getState();
    const selectedUser: SelectedUser = getSelectedUser(appState);
    const userData: UserDTO = {
      id: selectedUser.id,
      email: selectedUser.email,
      username: selectedUser.username,
      groups: selectedUser.groups?.map((item) => item.key as UserVisibility),
      role: selectedUser.role.key as UserRole,
      provider_ids: selectedUser.providers.map((provider) => +provider.key),
      dashboards_ids: selectedUser.dashboards.map((dashboard) => +dashboard.key)
    };
    const response = await updateUsersDataService.execute(userData);
    return response;
  }
);

const resetUserPassword = createAsyncThunk(
  'usersData/resetUserPassword',
  async (userId: string) => {
    const response = await resetUsersDataService.execute(userId);
    return response;
  }
);

const deleteUser = createAsyncThunk(
  'usersData/deleteUser',
  async (userId: string) => {
    const response = await deleteUsersDataService.execute(userId);
    return response;
  }
);

const getUserDashboardsData = createAsyncThunk(
  'usersData/getUserDashboardsData',
  async () => {
    const response = await getUserDashboardsDataService.execute();
    return response;
  }
);

export {
  getUsersData,
  downloadUsers,
  createUserData,
  updateUserData,
  resetUserPassword,
  deleteUser,
  getUserDashboardsData
};
