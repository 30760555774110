const DEFAULT_LANGUAGE = 'es';
const AVAILABLE_LANGUAGES = ['en', 'es', 'ca'];

class LocaleService {
  getLanguage(): string {
    return localStorage.getItem('Language') ?? DEFAULT_LANGUAGE;
  }

  setLanguage(lang: string) {
    localStorage.setItem('Language', lang);
  }
  getAvailableLanguages = () => {
    return AVAILABLE_LANGUAGES;
  };
}

export { LocaleService };
export default new LocaleService();
