import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import WarehouseCapacityBodyDTO from '../dto/WarehouseCapacityBodyDTO';
import WarehouseCapacityDataDTO from '../dto/WarehouseCapacityDataDTO';
import {
  WAREHOUSE_CAPACITY_BODY_ENDPOINT,
  WAREHOUSE_CAPACITY_ENDPOINT
} from './urls';

class WarehouseCapacityRepository extends BaseRepository {
  private _api: IApi;
  private _apiGetWarehouseCapacityData: string;
  private _apiGetWarehouseCapacityBody: string;

  constructor({
    api,
    apiGetWarehouseCapacityData,
    apiGetWarehouseCapacityBody
  }) {
    super();
    this._api = api;
    this._apiGetWarehouseCapacityData = apiGetWarehouseCapacityData;
    this._apiGetWarehouseCapacityBody = apiGetWarehouseCapacityBody;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetWarehouseCapacityData}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new WarehouseCapacityDataDTO(res));
  }

  getTableBody() {
    return this._api
      .get(this._apiGetWarehouseCapacityBody)
      .then((res) => new WarehouseCapacityBodyDTO(res));
  }

  updateWarehouseCapacity(body: object) {
    return this._api
      .put(this._apiGetWarehouseCapacityData, body)
      .then((res) => res);
  }
}

export default new WarehouseCapacityRepository({
  api: api,
  apiGetWarehouseCapacityData: WAREHOUSE_CAPACITY_ENDPOINT,
  apiGetWarehouseCapacityBody: WAREHOUSE_CAPACITY_BODY_ENDPOINT
});

export { WarehouseCapacityRepository };
