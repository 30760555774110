import { createSlice } from '@reduxjs/toolkit';
import EntriesFiltersData from 'modules/inbound/entries/domain/NextHours/EntriesFilters';
import { getEntriesFilters } from './async';

import { FiltersState } from './types';

export const initialState: FiltersState = {
  filters: EntriesFiltersData.generateEmpty(),
  requestStatus: ''
};

const EntriesFiltersSlice = createSlice({
  name: 'entriesFilters',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.filters = initialState.filters;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntriesFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) => state.entriesFiltersState.filters;
export const { clearFilters } = EntriesFiltersSlice.actions;
export default EntriesFiltersSlice.reducer;
