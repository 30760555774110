import { createSlice } from '@reduxjs/toolkit';
import ExpirationFilters from 'modules/inbound/expiration/domain/ExpirationFilters';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { getExpirationFilters } from './async';
import { ExpirationFiltersState } from './types';

const initialState: ExpirationFiltersState = {
  filters: ExpirationFilters.generateEmpty(),
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedInbounders: [],
    selectedWarehouses: [],
    selectedModels: [],
    selectedStockDiferentiations: [],
    selectedMeasurementUnits: [],
    selectedDateRange: { fromDate: null, toDate: null }
  },
  search: '',
  isFilterSaved: false,
  selectedExpirationStatus: null
};

const EXPIRATION_FILTERS_STATE_KEY = 'ExpirationFilters';

const expirationFiltersSlice = createSlice({
  name: 'expirationFiltersData',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedInbounders: (state, action) => {
      state.selectedSubHeaderFilters.selectedInbounders = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehouses = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedStockDiferentiations: (state, action) => {
      state.selectedSubHeaderFilters.selectedStockDiferentiations =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedMesurementUnits: (state, action) => {
      state.selectedSubHeaderFilters.selectedMeasurementUnits = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedDateRange: (state, action) => {
      state.selectedSubHeaderFilters.selectedDateRange = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedExpirationStatus: (state, action) => {
      state.selectedExpirationStatus =
        state.selectedExpirationStatus === action.payload
          ? null
          : action.payload;
    },
    resetFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${EXPIRATION_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${EXPIRATION_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${EXPIRATION_FILTERS_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        const savedSelecteFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;

        state.selectedSubHeaderFilters = {
          ...savedSelecteFilters,
          selectedDateRange: parsedFilters.selectedDateRange.fromDate
            ? {
                fromDate: new Date(parsedFilters.selectedDateRange.fromDate),
                toDate: new Date(parsedFilters.selectedDateRange.toDate)
              }
            : initialState.selectedSubHeaderFilters.selectedDateRange
        };

        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getExpirationFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getExpirationFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getFilters = (state) => state.expirationFiltersData.filters;
export const isLoading = (state) =>
  state.expirationFiltersData.requestStatus === 'pending';
export const hasError = (state) =>
  state.expirationFiltersData.requestStatus === 'rejected';
export const getFilterSearch = (state) => state.expirationFiltersData.search;
export const getSelectedFilters = (state) =>
  state.expirationFiltersData.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.expirationFiltersData.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.expirationFiltersData.selectedSubHeaderFilters).some(
    (key) => state.expirationFiltersData.selectedSubHeaderFilters[key].length
  ) ||
  state.expirationFiltersData.selectedSubHeaderFilters.selectedDateRange
    .fromDate;
export const getFormatedCustomDateForUI = (state) => {
  const { selectedDateRange } = getSelectedFilters(state);
  const fromDate = GetDateFormatedService.getDateFormated(
    selectedDateRange.fromDate,
    []
  );
  const toDate = GetDateFormatedService.getDateFormated(
    selectedDateRange.toDate,
    []
  );
  return selectedDateRange.fromDate ? `${fromDate} - ${toDate}` : null;
};
export const getFormatedCustomDate = (state) => {
  return GetDateFormatedService.formatDateRanges(
    state.expirationFiltersData.selectedSubHeaderFilters.selectedDateRange
      .fromDate,
    state.expirationFiltersData.selectedSubHeaderFilters.selectedDateRange
      .toDate
  );
};
export const getSelectedExpirationStatus = (state) =>
  state.expirationFiltersData.selectedExpirationStatus;

export const {
  updateFilterSearch,
  updateSelectedInbounders,
  updateSelectedWarehouses,
  updateSelectedModels,
  updateSelectedStockDiferentiations,
  updateSelectedMesurementUnits,
  updateSelectedDateRange,
  updateSelectedExpirationStatus,
  resetFilters,
  saveFilters,
  loadFilters
} = expirationFiltersSlice.actions;
export default expirationFiltersSlice.reducer;
