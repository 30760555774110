import { INoJitRepository } from '../domain/INoJitRepository';
import nojitRepository from '../infrastructure/repositories/NoJitRepository';

class DownloadNojitUseCase {
  private _nojitRepository: INoJitRepository;

  constructor({ nojitRepository }) {
    this._nojitRepository = nojitRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._nojitRepository.downloadNojit({
      queryParams
    });
  }
}

export default new DownloadNojitUseCase({
  nojitRepository: nojitRepository
});

export { DownloadNojitUseCase };
