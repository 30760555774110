import React from 'react';
import { WrapperWelcome } from './styles';
import backgroundImage from 'assets/images/loginBackground.png';
import seatCodePoweredLogo from 'assets/logos/seat-code-powered.svg';
import scctLogo from 'assets/logos/scct-small.svg';

export const WelcomeMessage = () => {
  return (
    <WrapperWelcome>
      <img
        className="background"
        src={backgroundImage}
        alt="Login background"
      />
      <div>
        <img src={scctLogo} alt="Control tower logo" />
        <h4>Customer Driven Supply Chain</h4>
        <h3>Control Tower</h3>
      </div>
      <img
        className="seat-code-logo"
        src={seatCodePoweredLogo}
        alt="powered by seat:code"
      />
    </WrapperWelcome>
  );
};
