import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { UserText } from '../infrastructure/i18n/UserText';
import { UserTranslations } from '../infrastructure/i18n/typing';

const userTranslations: UserTranslations = {
  ...UserText[LocaleService.getLanguage()]
};

export enum ColumnKeys {
  user = 'user',
  email = 'email',
  groups = 'groups',
  role = 'role',
  dashboards = 'dashboards',
  providers = 'providers',
  lastConnectionDate = 'lastConnectionDate',
  action = 'action'
}

export const USERS_COLUMNS: ColumnProps[] = [
  {
    uiKey: ColumnKeys.user,
    key: NameValues.USER,
    value: userTranslations.user,
    isVisibleCheck: true,
    sortable: true
  },
  {
    uiKey: ColumnKeys.email,
    key: NameValues.EMAIL,
    value: userTranslations.email,
    isVisibleCheck: true,
    sortable: true
  },
  {
    uiKey: ColumnKeys.groups,
    key: NameValues.VISIBILITY,
    value: userTranslations.group,
    isVisibleCheck: true,
    sortable: false,
    tooltipInfo: userTranslations.visibilityGroupsTooltip
  },
  {
    uiKey: ColumnKeys.role,
    key: NameValues.ROLE,
    value: userTranslations.role,
    isVisibleCheck: true,
    sortable: false,
    tooltipInfo: userTranslations.roleTooltip
  },
  {
    uiKey: ColumnKeys.dashboards,
    key: NameValues.DASHBOARDS,
    value: userTranslations.dashboards,
    isVisibleCheck: true,
    sortable: false
  },
  {
    uiKey: ColumnKeys.providers,
    key: NameValues.PROVIDERS,
    value: userTranslations.providers,
    isVisibleCheck: true,
    sortable: false
  },
  {
    uiKey: ColumnKeys.lastConnectionDate,
    key: NameValues.LAST_CONNECTION_DATE,
    value: userTranslations.lastConnectionDate,
    isVisibleCheck: true,
    sortable: true
  },
  {
    uiKey: ColumnKeys.action,
    key: NameValues.ACTION,
    value: userTranslations.action,
    isVisibleCheck: false,
    sortable: false
  }
];
