class CallOffTrackingByWarehouseDTO {
  id: string;
  name: string;
  totalCars: number;
  delayedCars: number;

  constructor({ id, name, totalCars, delayedCars }) {
    this.id = id;
    this.name = name;
    this.totalCars = totalCars;
    this.delayedCars = delayedCars;
  }
}

export default CallOffTrackingByWarehouseDTO;
