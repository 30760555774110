import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { UrgentTruckDTO } from '../../infrastructure/dto/NextHoursDTO/urgentIncomingTrucksDTOMapper';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};
class UrgentTruck {
  plateNumber: string;
  mostCriticalPiece: string;
  mostCriticalPieceMinimumCoverage: number | string;
  estimatedTimeArrival: string;

  constructor({
    plateNumber,
    mostCriticalPiece,
    mostCriticalPieceMinimumCoverage,
    estimatedTimeArrival
  }) {
    this.plateNumber = plateNumber;
    this.mostCriticalPiece = mostCriticalPiece;
    this.mostCriticalPieceMinimumCoverage = mostCriticalPieceMinimumCoverage;
    this.estimatedTimeArrival = estimatedTimeArrival;
  }

  private static _formatThousandsDot(quantity) {
    if (quantity === null) {
      return '-';
    }
    return quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });
  }

  private static _formatDaysUnits(days: number): string {
    const { unitSingleDay, unitMultipleDays } = inboundTranslations;

    if (days === 1) {
      return `${this._formatThousandsDot(days)} ${unitSingleDay}`;
    }
    return `${this._formatThousandsDot(days)} ${unitMultipleDays}`;
  }

  static generateFromDto({
    plate_number,
    most_critical_piece,
    most_critical_piece_coverage,
    estimated_time_arrival
  }: UrgentTruckDTO): UrgentTruck {
    return new UrgentTruck({
      plateNumber: plate_number ?? '-',
      mostCriticalPiece: most_critical_piece ?? '-',
      mostCriticalPieceMinimumCoverage:
        most_critical_piece_coverage !== null
          ? `${this._formatDaysUnits(most_critical_piece_coverage)}`
          : '-',
      estimatedTimeArrival: estimated_time_arrival
        ? `${GetDateFormatedService.getShortHourLocale(
            estimated_time_arrival
          )}h`
        : '-'
    });
  }

  static generateEmpty() {
    return new UrgentTruck({
      plateNumber: '-',
      mostCriticalPiece: '-',
      mostCriticalPieceMinimumCoverage: '-',
      estimatedTimeArrival: '-'
    });
  }
}

export { UrgentTruck };
