import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDeliveryStatusesUseCase } from 'modules/inbound/nojit/application/GetDeliveryStatusesUseCase';
import { getSelectedTemporalHorizon } from '../NoJitDataReducer';
import { getFilterSearch } from '../NoJitFiltersStateReducer';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';
import { nojitSearchQueryBuilder } from 'modules/inbound/nojit/application/NojitQueryStringBuilder';
import { getFiltersQuery } from '../NoJitDataReducer/async';

const getDeliveryStatuses = createAsyncThunk(
  'deliveryStatusesData/getDeliveryStatuses',
  async (_, { getState }) => {
    const appState = getState();
    const selectedTemporalHorizon = getSelectedTemporalHorizon(appState);
    const searchValue = getFilterSearch(appState);
    const queryFiltersString = getFiltersQuery(appState);

    const search = {
      [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
      [NameValues.NAME]: splitStringByAGivenCharacter(searchValue, '+')
    };

    const querySearchString = nojitSearchQueryBuilder(search);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`,
      planning: selectedTemporalHorizon.key
    };

    const response = await getDeliveryStatusesUseCase.execute({ queryParams });
    return response;
  }
);

export { getDeliveryStatuses };
