export interface StockAgingDTO {
  total: AgingByRangesDTO;
  blocked: AgingByRangesDTO;
  notBlocked: AgingByRangesDTO;
}

export interface AgingByRangesDTO {
  '0-2': number;
  '3-10': number;
  '11-90': number;
  '91+': number;
}

export const stockAgingDTOMapper = (data: any): StockAgingDTO => {
  const parsedTotalStock = {
    '0-2': data?.total?.['0-2'] || 0,
    '3-10': data?.total?.['3-10'] || 0,
    '11-90': data?.total?.['11-90'] || 0,
    '91+': data?.total?.['91+'] || 0
  };
  const parsedBlockedStock = {
    '0-2': data?.blocked?.['0-2'] || 0,
    '3-10': data?.blocked?.['3-10'] || 0,
    '11-90': data?.blocked?.['11-90'] || 0,
    '91+': data?.blocked?.['91+'] || 0
  };

  return {
    total: parsedTotalStock,
    blocked: parsedBlockedStock,
    notBlocked: {
      '0-2': parsedTotalStock['0-2'] - parsedBlockedStock['0-2'],
      '3-10': parsedTotalStock['3-10'] - parsedBlockedStock['3-10'],
      '11-90': parsedTotalStock['11-90'] - parsedBlockedStock['11-90'],
      '91+': parsedTotalStock['91+'] - parsedBlockedStock['91+']
    }
  };
};
