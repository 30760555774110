import LocaleService from 'infrastructure/i18n/LocaleService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import {
  UserDashboards,
  UserRole,
  UserRoleText,
  UserVisibilityText
} from 'modules/shared/domain/UserRole';
import { UserDTO } from '../infrastructure/dto/UsersDTO';
import { UserTranslations } from '../infrastructure/i18n/typing';
import { UserText } from '../infrastructure/i18n/UserText';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { USERS_COLUMNS } from './UsersColumns';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';

const userTranslations: UserTranslations = {
  ...UserText[LocaleService.getLanguage()]
};

export const Dashboards = {
  [UserDashboards.NOJIT]: 'noJit',
  [UserDashboards.ENDOFPRODUCTION]: 'endOfProduction',
  [UserDashboards.JIT]: 'jit',
  [UserDashboards.FAMILYCOVERAGE]: 'familyCoverage',
  [UserDashboards.DD]: 'dd',
  [UserDashboards.FACTORYBCN]: 'factoryBCN',
  [UserDashboards.SUPPLIES]: 'supplies',
  [UserDashboards.REFILLS]: 'refills',
  [UserDashboards.TRANSFERS]: 'transfers',
  [UserDashboards.ENTRIES]: 'entries',
  [UserDashboards.MAESTROS]: 'masters',
  [UserDashboards.USERS]: 'users',
  [UserDashboards.LANDING]: 'landing',
  [UserDashboards.EXPIRATION]: 'expirations',
  [UserDashboards.STOCKS]: 'stocks',
  [UserDashboards.STUCKLISTE_SUPPLIERS]: 'stucklisteSuppliers'
};

export interface User {
  id: string;
  username: string;
  providers: number[];
  dashboards: number[];
  dashboardsNames: string[];
  role: string;
  email: string;
  groups: string[];
  lastConnectionDate: string;
}

interface GenerateFromDTOParams {
  data: UserDTO[];
  pagination: IPaginationDTO;
}

class UsersData {
  data: User[];
  columns: ColumnProps[];
  pagination: IPagination;

  constructor({ data, columns, pagination }: UsersData) {
    this.data = data;
    this.columns = columns;
    this.pagination = pagination;
  }

  private static _formatRoleText(role) {
    if (
      role === UserRole.INBOUND_PROVIDER_NOT_JIT ||
      role === UserRole.INBOUND_PROVIDER_DD ||
      role === UserRole.INBOUND_PROVIDER_JIT ||
      role === UserRole.INBOUND_PROVIDER
    ) {
      return UserRoleText.PROVIDER;
    }
    if (role === UserRole.INBOUND_SUPER_ADMIN) {
      return UserRoleText.SUPER_ADMIN;
    }
    if (role === UserRole.INBOUND_SEAT) {
      return UserRoleText.SEAT;
    }
  }

  static generateFromDTO({
    data,
    pagination
  }: GenerateFromDTOParams): UsersData {
    let formatUserList: User[] = [];
    if (data.length) {
      data.forEach((userItem) => {
        let formatDashboardsList: string[] = [];
        let formatVisibilityGroupList: string[] = [];
        if (userItem.dashboards_ids.length > 0) {
          userItem.dashboards_ids.forEach((dashboard) => {
            formatDashboardsList.push(
              userTranslations[Dashboards[dashboard]] ?? '-'
            );
          });
        }
        if (userItem.groups.length) {
          userItem.groups?.forEach((group) => {
            formatVisibilityGroupList.push(UserVisibilityText[group]);
          });
        }
        formatUserList.push({
          id: userItem.id!,
          username: userItem.username,
          providers: userItem.provider_ids,
          dashboards: userItem.dashboards_ids,
          dashboardsNames: formatDashboardsList,
          role: this._formatRoleText(userItem.role) ?? '',
          email: userItem.email,
          groups: formatVisibilityGroupList,
          lastConnectionDate: userItem.last_connection_date
            ? GetDateFormatedService.getDateFormated(
                userItem.last_connection_date,
                'ES'
              )
            : NO_DATA
        });
      });
    }

    return new UsersData({
      data: formatUserList,
      columns: USERS_COLUMNS,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty(): UsersData {
    return new UsersData({
      data: [],
      columns: [],
      pagination: {
        page: 1,
        size: 50,
        pageCount: 0,
        totalCount: 0
      }
    });
  }
}

export default UsersData;
