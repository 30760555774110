export const SLOTS_RANGE_DICTIONARY = {
  bankOrder: {
    startSlot: 'bankOrder',
    endSlot: 'bankOrder'
  },
  weekOrdersProductionToOrdersManufacturing: {
    startSlot: 'weekOrdersProduction',
    endSlot: 'ordersManufacturing'
  },
  carsBetweenFactoryAndRedistributionWarehouse: {
    startSlot: 'carsBetweenFactoryAndRedistributionWarehouse',
    endSlot: 'carsBetweenFactoryAndRedistributionWarehouse'
  }
};
