import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { ShiftType } from 'modules/inbound/shared/domain/ShiftTypes';
import { MovementsLineDTO } from 'modules/inbound/shared/infraestructure/dto/MovementsLineDTO';

interface CoverageDTO {
  covered: number;
  security: number;
  critical: number;
}

export interface PlanningDirectDeliveryDTO {
  date: string;
  lines: string[];
  shifts: ShiftItemDTO[];
}

interface ShiftItemDTO {
  shift: ShiftType;
  amount: number;
  remaining_amount: number;
  transits: DirectDeliveryTransportDTO[];
}

export interface DirectDeliveryTransportDTO {
  code: string;
  provider_id: number;
  plate_number: string;
  delivery_datetime: string;
  container_labels: string[];
  amount: number;
  provider_delivery_note: string;
}

export interface DirectDeliveryItemDTO {
  uid: string;
  code: string;
  provider_code: string;
  description: string;
  warehouse_id: string;
  models: string[];
  provider_id: number;
  stock: number;
  containers_at_marquee: number;
  container_id: number;
  containers_in_cycle: number;
  planning: PlanningDirectDeliveryDTO[];
  final_stock_quantity: number;
  final_stock_datetime: string;
  coverage_in_seconds: number | string | null;
  average_coverage_in_seconds: number | string | null;
  coverage: CoverageDTO;
  last_line_movement: MovementsLineDTO;
  average_consumption: number;
  status: boolean;
}

class DirectDeliveryDataDTO {
  stock_updated_at: string;
  assembly_needs_updated_at: string;
  bodywork_needs_updated_at: string;
  planning_dates: string[];
  direct_deliveries: DirectDeliveryItemDTO[];
  pagination: IPaginationDTO;

  constructor({
    stock_updated_at,
    assembly_needs_updated_at,
    bodywork_needs_updated_at,
    planning_dates,
    direct_deliveries,
    pagination
  }) {
    this.stock_updated_at = stock_updated_at;
    this.assembly_needs_updated_at = assembly_needs_updated_at;
    this.bodywork_needs_updated_at = bodywork_needs_updated_at;
    this.planning_dates = planning_dates;
    this.direct_deliveries = direct_deliveries;
    this.pagination = pagination;
  }
}

export default DirectDeliveryDataDTO;
