import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from '../infrastructure/repositories/DeliveriesGeolocationRepository';
import GeolocatedVessel from '../domain/GeolocatedVessel';

class GetGeolocatedVesselsUseCase {
  repository: ITransitRepository;

  constructor({ repository }) {
    this.repository = repository;
  }

  execute() {
    return this.repository.getGeolocatedVessels!().then(({ vessels }) =>
      vessels.map((vessel) => GeolocatedVessel.generateFromDTO(vessel))
    );
  }
}

export default new GetGeolocatedVesselsUseCase({
  repository: deliveriesGeolocationRepository
});

export { GetGeolocatedVesselsUseCase };
