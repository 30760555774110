import { createAsyncThunk } from '@reduxjs/toolkit';
import getDeliveryTimeSlotsUseCase from 'modules/outbound/deliveryTime/application/GetDeliveryTimeSlotsUseCase';
import getDeliveryTimeSlotsDetailsUseCase from 'modules/outbound/deliveryTime/application/GetDeliveryTimeSlotsDetailsUseCase';
import { getSelectedCountriesISO } from 'modules/outbound/deliveryTime//ui/redux/DeliveryTimeReducer';
import { SLOTS_RANGE_DICTIONARY } from 'modules/outbound/deliveryTime/domain/slotsRangeDictionary';

const getDeliveryTimeSlots = createAsyncThunk(
  'deliveryTime/getDeliveryTimeSlots',
  (_, { getState }) => {
    const appState = getState();
    const selectedCountries = getSelectedCountriesISO(appState);
    const queryParams = { countries: selectedCountries };
    return getDeliveryTimeSlotsUseCase.execute({ queryParams });
  }
);

const getDeliveryTimeSlotsDetail = createAsyncThunk<
  { slotsRangeDetails: any; slotsRangeName: string },
  string,
  { rejectedMeta: object }
>(
  `deliveryTime/getDeliveryTimeSlotsDetail`,
  async (slotsRangeName: string, { rejectWithValue, getState }) => {
    const appState = getState();
    const selectedCountries = getSelectedCountriesISO(appState);
    let slotsRange = {};
    if (slotsRangeName.length) {
      slotsRange = SLOTS_RANGE_DICTIONARY[slotsRangeName] || '';
    }
    try {
      const response = await getDeliveryTimeSlotsDetailsUseCase.execute({
        queryParams: { ...slotsRange, countries: selectedCountries }
      });
      return {
        slotsRangeDetails: response,
        slotsRangeName
      };
    } catch (error) {
      return rejectWithValue(error, {
        slotsRangeName
      });
    }
  },
  {
    getPendingMeta: ({ arg }) => ({
      slotsRangeName: arg
    })
  }
);

export { getDeliveryTimeSlots, getDeliveryTimeSlotsDetail };
