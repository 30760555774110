import FinishedGoodTransportDetails from '../domain/FinishedGoodTransportDetails';
import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from '../infrastructure/repositories/DeliveriesGeolocationRepository';

class GetTransportDetailsByIdUseCase {
  repository: ITransitRepository;

  constructor({ repository }) {
    this.repository = repository;
  }

  execute({ transportId }: { transportId: string }) {
    return this.repository.getTransportDetailsById!({ transportId }).then(
      (transportData) =>
        FinishedGoodTransportDetails.generateFromDTO(transportData)
    );
  }
}

export default new GetTransportDetailsByIdUseCase({
  repository: deliveriesGeolocationRepository
});

export { GetTransportDetailsByIdUseCase };
