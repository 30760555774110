import { MastersDataDTO } from '../infrastructure/dto/mastersDataDTOMapper';
import MasterDataPanel from './MasterDataPanel';

export interface MastersData {
  mastersData: MasterDataPanel[];
}

const mastersDataMapper = (dto: MastersDataDTO): MastersData => ({
  mastersData: dto.masters_data.map((master) =>
    MasterDataPanel.generateFromDTO(master)
  )
});

export { mastersDataMapper };
