import { createSlice } from '@reduxjs/toolkit';
import { AchievementReducerProps } from './typing';
import {
  downloadFulfillmentFinishedGoods,
  getAchievements,
  getRoutesByCountry,
  getTransitFulfillmentCheckpointsByCountry,
  getGlobalFulfillmentByDateRange
} from './async';
import TransitFulfillment from 'modules/outbound/fulfillment/domain/TransitFulfillment';
import CountryRoutes from 'modules/outbound/fulfillment/domain/CountryRoutes';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { DateRangeType } from 'modules/outbound/shared/domain/GetDateRangeService';

export const FILTER_ORDER = 'filterOrder';
export const FILTER_COUNTRY = 'filterCountry';
export const FILTER_DELAY_POSITION_KEY = 'delayPosition';
export const FILTER_ACHIEVEMENT_POSITION_KEY = 'achievementPosition';
export const initialState: AchievementReducerProps = {
  globalAccomplishedPercentage: 0,
  lastWeekGlobalAccomplishedPercentage: 0,
  currentWeekGlobalAccomplishedPercentage: 0,
  items: {
    mainItems: [],
    otherItems: []
  },
  filtersStatus: {},
  selectedCountry: '',
  checkpoints: [],
  routes: CountryRoutes.generateEmpty(),
  file: null,
  requestStatus: '',
  checkpointsRequestStatus: '',
  routesRequestStatus: '',
  downloadRequestStatus: '',
  getGlobalFulfillmentRequestStatus: ''
};

const achievementSlice = createSlice({
  name: 'achievement',
  initialState,
  reducers: {
    selectCountry: (state, action) => {
      state.selectedCountry = action.payload || '';
      state.checkpoints = initialState.checkpoints;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAchievements.fulfilled, (state, action) => {
      state.items.mainItems = action.payload.mainAchievements || [];
      state.items.otherItems = action.payload.otherAchievements || [];
      state.globalAccomplishedPercentage =
        action.payload.globalAccomplishedPercentage || 0;
      state.requestStatus = action.meta.requestStatus;
      if (
        !action.payload.mainAchievements.find(
          (item) => item.country.iso === state.selectedCountry
        ) &&
        !action.payload.otherAchievements.find(
          (item) => item.country.iso === state.selectedCountry
        )
      ) {
        state.selectedCountry = initialState.selectedCountry;
        state.checkpoints = initialState.checkpoints;
      }
    });
    builder.addCase(getAchievements.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getAchievements.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getTransitFulfillmentCheckpointsByCountry.fulfilled,
      (state, action) => {
        state.checkpoints = action.payload.checkpoints || [];
        state.selectedCountry = action.payload.countryCode;
        state.checkpointsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getTransitFulfillmentCheckpointsByCountry.pending,
      (state, action) => {
        state.checkpointsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getTransitFulfillmentCheckpointsByCountry.rejected,
      (state, action) => {
        state.checkpointsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(getRoutesByCountry.fulfilled, (state, action) => {
      if (action.payload.countryCode === state.selectedCountry) {
        state.routes = action.payload;
        state.routesRequestStatus = action.meta.requestStatus;
      }
    });
    builder.addCase(getRoutesByCountry.pending, (state, action) => {
      state.routesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRoutesByCountry.rejected, (state, action) => {
      state.routesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      downloadFulfillmentFinishedGoods.fulfilled,
      (state, action) => {
        state.file = action.payload;
        downloadXlsxFile({
          file: action.payload,
          fileName: 'past_fulfillment_details'
        });
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadFulfillmentFinishedGoods.pending,
      (state, action) => {
        state.file = null;
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadFulfillmentFinishedGoods.rejected,
      (state, action) => {
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getGlobalFulfillmentByDateRange.fulfilled,
      (state, action) => {
        if (action.payload.dateRange === DateRangeType.WTD) {
          state.lastWeekGlobalAccomplishedPercentage =
            action.payload.value || 0;
        } else if (action.payload.dateRange === DateRangeType.CWTD) {
          state.currentWeekGlobalAccomplishedPercentage =
            action.payload.value || 0;
        }
        state.getGlobalFulfillmentRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getGlobalFulfillmentByDateRange.pending,
      (state, action) => {
        state.getGlobalFulfillmentRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getGlobalFulfillmentByDateRange.rejected,
      (state, action) => {
        state.getGlobalFulfillmentRequestStatus = action.meta.requestStatus;
      }
    );
  }
});

// selectors
export const getSelectedCountryAchievement = (state) => {
  return (
    state.achievementState.items?.mainItems.find(
      (item) => item.country.iso === state.achievementState.selectedCountry
    ) ||
    state.achievementState.items?.otherItems.find(
      (item) => item.country.iso === state.achievementState.selectedCountry
    ) ||
    TransitFulfillment.generateEmpty()
  );
};

export const getSelectedCountryCheckpoints = (state) =>
  state.achievementState.checkpoints;

export const getSelectedCountryCheckpointsWithThreshold = (state) => {
  let thresholdReached = false;
  const threshold = getSelectedCountryCheckpointsThreshold(state);

  if (
    state.achievementState.checkpoints.some((item) => item.id === threshold)
  ) {
    return state.achievementState.checkpoints.map((item) => {
      const checkpointInfo = {
        ...item,
        objectivesThreshold: !thresholdReached
      };
      if (threshold === item.id) {
        thresholdReached = true;
      }
      return checkpointInfo;
    });
  }
  return state.achievementState.checkpoints;
};

export const getSelectedCountry = (state) =>
  state.achievementState.selectedCountry;

export const getCheckpointsRequestStatusIsPending = (state) =>
  state.achievementState.checkpointsRequestStatus === 'pending';

export const getSelectedCountryCheckpointsThreshold = (state) => {
  return (
    state.achievementState.items?.mainItems.find(
      (item) => item.country.iso === state.achievementState.selectedCountry
    ) ||
    state.achievementState.items?.otherItems.find(
      (item) => item.country.iso === state.achievementState.selectedCountry
    ) ||
    TransitFulfillment.generateEmpty()
  ).checkpointThreshold;
};

export const getRoutes = (state) => state.achievementState.routes;
export const getRoutesIsPending = (state) =>
  state.achievementState.routesRequestStatus === 'pending';

export const getExportableFile = (state) => state.achievementState.file;

export const getDownloadRequestStatusIsPending = (state) =>
  state.achievementState.downloadRequestStatus === 'pending';

export const getLastWeekGlobalAccomplishedPercentage = (state) =>
  state.achievementState.lastWeekGlobalAccomplishedPercentage;

export const getCurrentWeekGlobalAccomplishedPercentage = (state) =>
  state.achievementState.currentWeekGlobalAccomplishedPercentage;

export const getGlobalFulfillmentRequestStatusIsPending = (state) =>
  state.achievementState.getGlobalFulfillmentRequestStatus === 'pending';

//actions
export const { selectCountry } = achievementSlice.actions;

export default achievementSlice;
