import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { GET_SERVICE_LEVEL_DASHBOARD } from './urls';
import { IServiceLevelRepository } from 'modules/outbound/serviceLevel/domain/IServiceLevelRepository';

class ServiceLevelRepository
  extends BaseRepository
  implements IServiceLevelRepository
{
  private _api: IApi;
  private _getServiceLevelDashboard: string;

  constructor({ api, getServiceLevelDashboard }) {
    super();
    this._api = api;
    this._getServiceLevelDashboard = getServiceLevelDashboard;
  }
  getServiceLevelDashboardUrl() {
    return this._api
      .get(this._getServiceLevelDashboard)
      .then((response) => response.url);
  }
}

export const serviceLevelRepository = new ServiceLevelRepository({
  api,
  getServiceLevelDashboard: GET_SERVICE_LEVEL_DASHBOARD
});

export { ServiceLevelRepository };
