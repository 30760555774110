import { UnloadingPointDTO } from './UnloadingPointDTO';

export interface SaturationUnloadingPointsDTO {
  saturation: UnloadingPointDTO[];
}

const unloadingPointsDTOMapper = (data: any): SaturationUnloadingPointsDTO => ({
  saturation: data?.saturation?.map((saturationPoint) =>
    UnloadingPointDTO.generateDTO(saturationPoint)
  )
});

export { unloadingPointsDTOMapper };
