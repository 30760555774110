import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnAlign } from 'modules/inbound/shared/domain/ColumnsTypes';
import { ExpirationText } from '../infrastructure/i18n/ExpirationText';
import { ExpirationTranslations } from '../infrastructure/i18n/typing';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';

const expirationsTranslations: InboundTranslations & ExpirationTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...ExpirationText[LocaleService.getLanguage()]
};

export interface ColumnsInfo {
  key: string;
  uiKey: string;
  value: string;
  sortable: boolean;
  isVisibleCheck: boolean;
  draggable: boolean;
  tooltipInfo?: string;
  align?: ColumnAlign;
}

const camelToSnakeCase = (word: string) => {
  return word.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const ExpirationColumnKeys = {
  expirationStatus: 'expirationStatus',
  code: 'code',
  name: 'name',
  handlingUnit: 'handlingUnit',
  expirationDate: 'expirationDate',
  daysForExpiration: 'daysForExpiration',
  stockDifferentiation: 'stockDifferentiation',
  models: 'models',
  quantity: 'quantity',
  measurementUnit: 'measurementUnit',
  stockCost: 'stockCost',
  coverage: 'coverage',
  supplyPoints: 'supplyPoints',
  warehouse: 'warehouse',
  warehouseType: 'warehouseType',
  location: 'location',
  inbounders: 'inbounders',
  providerNames: 'providerNames',
  providerDeliveryNote: 'providerDeliveryNote',
  providerDeliveryNoteDate: 'providerDeliveryNoteDate'
} as const;

const generateExpirationTableColumnsInfo = (): ColumnsInfo[] => {
  const TooltipKeys = {
    models: expirationsTranslations.modelCarRelation,
    expirationStatus: expirationsTranslations.expirationStatusTooltip,
    stockDifferentiation: expirationsTranslations.stockDifferentiationTooltip,
    measurementUnit: expirationsTranslations.measurementUnitTooltip,
    daysForExpiration: expirationsTranslations.expirationDaysTooltip
  };

  type TColumnKeys =
    (typeof ExpirationColumnKeys)[keyof typeof ExpirationColumnKeys];

  const SORTABLE_COLUMNS: TColumnKeys[] = [
    'code',
    'quantity',
    'expirationDate',
    'daysForExpiration',
    'stockCost',
    'supplyPoints',
    'coverage'
  ];

  const RIGHT_ALIGNED_COLUMNS: TColumnKeys[] = [
    'quantity',
    'stockCost',
    'supplyPoints',
    'coverage',
    'daysForExpiration'
  ];

  return Object.keys(ExpirationColumnKeys).map((key) => ({
    key: camelToSnakeCase(key),
    uiKey: key,
    value: expirationsTranslations[key] ?? '',
    sortable: SORTABLE_COLUMNS.includes(ExpirationColumnKeys[key]),
    isVisibleCheck: true,
    draggable: true,
    tooltipInfo: TooltipKeys[key],
    align: RIGHT_ALIGNED_COLUMNS.includes(ExpirationColumnKeys[key])
      ? 'right'
      : 'left'
  }));
};

export { generateExpirationTableColumnsInfo };
