export const CHECKPOINTS_DICTIONARY = [
  {
    language: 'en',
    checkpoints: {
      factoryWarehouseOutput: 'Factory',
      originPortInput: 'Transit',
      originPortOutput: 'Port',
      transitPortInput: 'Transit',
      transitPortOutput: 'Port',
      destinationPortInput: 'Transit',
      destinationPortOutput: 'Port',
      redistributionWarehouseInput: 'Transit',
      redistributionWarehouseOutput: 'Compound',
      dealerInput: 'Transit',
      dealerOutput: 'Dealer'
    }
  },
  {
    language: 'es',
    checkpoints: {
      factoryWarehouseOutput: 'Fábrica',
      originPortInput: 'Tránsito',
      originPortOutput: 'Puerto',
      destinationPortInput: 'Tránsito',
      destinationPortOutput: 'Puerto',
      transitPortInput: 'Tránsito',
      transitPortOutput: 'Puerto',
      redistributionWarehouseInput: 'Tránsito',
      redistributionWarehouseOutput: 'Campa',
      dealerInput: 'Tránsito',
      dealerOutput: 'Concesionario'
    }
  },
  {
    language: 'ca',
    checkpoints: {
      factoryWarehouseOutput: 'Fàbrica',
      originPortInput: 'Trànsit',
      originPortOutput: 'Port',
      transitPortInput: 'Trànsit',
      transitPortOutput: 'Port',
      destinationPortInput: 'Trànsit',
      destinationPortOutput: 'Port',
      redistributionWarehouseInput: 'Trànsit',
      redistributionWarehouseOutput: 'Campa',
      dealerInput: 'Trànsit',
      dealerOutput: 'Concessionari'
    }
  }
];

export const DETAILED_CHECKPOINTS_DICTIONARY = [
  {
    language: 'en',
    checkpoints: {
      factoryWarehouseInput: 'Factory compound',
      factoryWarehouseOutput: 'Transit from factory',
      originPortInput: 'Origin Port',
      originPortOutput: 'Transit from origin port',
      transitPortInput: 'Transit Port',
      transitPortOutput: 'Transit from transit port',
      destinationPortInput: 'Destination Port',
      destinationPortOutput: 'Transit from destination port',
      redistributionWarehouseInput: 'Compound',
      redistributionWarehouseOutput: 'Transit from compound',
      dealerInput: 'Dealer'
    }
  },
  {
    language: 'es',
    checkpoints: {
      factoryWarehouseInput: 'Campa fábrica',
      factoryWarehouseOutput: 'Tránsito desde fábrica',
      originPortInput: 'Puerto de origen',
      originPortOutput: 'Tránsito desde puerto de origen',
      transitPortInput: 'Puerto de Tránsito',
      transitPortOutput: 'Tránsito desde puerto de tránsito',
      destinationPortInput: 'Puerto de destino',
      destinationPortOutput: 'Tránsito desde puerto de destino',
      redistributionWarehouseInput: 'Campa',
      redistributionWarehouseOutput: 'Tránsito desde campa',
      dealerInput: 'Concesionario'
    }
  },
  {
    language: 'ca',
    checkpoints: {
      factoryWarehouseInput: 'Campa fàbrica',
      factoryWarehouseOutput: 'Trànsit des de fàbrica',
      originPortInput: "Port d'origen",
      originPortOutput: "Trànsit des de port d'orígen",
      transitPortInput: 'Port de Trànsit',
      transitPortOutput: 'Trànsit des de port de trànsit',
      destinationPortInput: 'Port de destí',
      destinationPortOutput: 'Trànsit des de port de destí',
      redistributionWarehouseInput: 'Campa',
      redistributionWarehouseOutput: 'Trànsit des de campa',
      dealerInput: 'Concessionari'
    }
  }
];
