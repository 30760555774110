import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { CriticalStockType } from 'modules/shared/domain/enums';
import { SuppliesDTO } from '../infrastructure/dto/SuppliesDataDTO';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { SUPPLY_COLUMNS } from './SuppliesColumns';

export interface Supplies {
  code: string;
  lines: string;
  description: string;
  materialIndicator: string;
  status: string;
  supplyType: string;
  orderRequest: string;
  transportRequest: number;
  warehouseUnitNumber: string;
  providerDeliveryNote: string;
  warehouseSourceType: string;
  createdAt: string;
  lineGroup: string;
  confirmationDate: string;
  preparationDate: string;
  lineLocation: string;
  manufacturingSection: string;
  warehouseSource: string;
  delayTimeInMinutes: string;
  criticalStockType: string;
  warehouseTarget: string;
}

interface GenerateFromDTOParams {
  supplies_without_stock_last_update: string | null;
  supplies_with_stock_last_update: string | null;
  ot_orders: SuppliesDTO[];
  pagination: IPaginationDTO;
}

class SuppliesData {
  lastUpdate: {
    suppliesWithoutStockLastUpdate: string;
    suppliesWithStockLastUpdate: string;
  };
  transportOrders: Supplies[];
  columns: ColumnProps[];
  pagination: IPagination;

  constructor({
    transportOrders,
    columns,
    pagination,
    lastUpdate
  }: SuppliesData) {
    this.transportOrders = transportOrders;
    this.columns = columns;
    this.pagination = pagination;
    this.lastUpdate = lastUpdate;
  }

  static generateFromDTO({
    supplies_without_stock_last_update,
    supplies_with_stock_last_update,
    ot_orders,
    pagination
  }: GenerateFromDTOParams): SuppliesData {
    let formatOtOrders: Supplies[] = [];

    if (ot_orders.length) {
      ot_orders.forEach((item) => {
        formatOtOrders.push({
          code: item.code,
          lines: item.lines.length ? item.lines.join(' | ') : '-',
          description: item.description ?? '-',
          materialIndicator: item.material_indicator || '-',
          status: item.status,
          supplyType: item.supply_type,
          orderRequest: item.order_request,
          transportRequest: item.transport_request,
          warehouseUnitNumber: item.warehouse_unit_number ?? '-',
          providerDeliveryNote: item.provider_delivery_note ?? '-',
          warehouseSourceType: item.warehouse_source_type ?? '-',
          createdAt: GetDateFormatedService.getShortDateTime(item.created_at),
          lineGroup: item.line_group,
          confirmationDate: item.confirmation_date
            ? GetDateFormatedService.getShortDateTime(item.confirmation_date)
            : '-',
          preparationDate: item.preparation_date
            ? GetDateFormatedService.getShortDateTime(item.preparation_date)
            : '-',
          lineLocation: item.line_location ?? '-',
          manufacturingSection: item.manufacturing_section ?? '-',
          warehouseSource: item.warehouse_source ?? '-',
          delayTimeInMinutes:
            !isNaN(item.delay_time_in_minutes) &&
            item.delay_time_in_minutes !== null
              ? GetDateFormatedService.formatTimeToHoursAndMinutes(
                  item.delay_time_in_minutes
                )
              : '-',
          criticalStockType:
            (item.critical_stock_type as CriticalStockType) ?? null,
          warehouseTarget: item.warehouse_target ?? '-'
        });
      });
    }

    return new SuppliesData({
      lastUpdate: {
        suppliesWithoutStockLastUpdate:
          supplies_without_stock_last_update ?? '-',
        suppliesWithStockLastUpdate: supplies_with_stock_last_update ?? '-'
      },
      transportOrders: formatOtOrders,
      columns: SUPPLY_COLUMNS,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty(): SuppliesData {
    return new SuppliesData({
      lastUpdate: {
        suppliesWithoutStockLastUpdate: '-',
        suppliesWithStockLastUpdate: '-'
      },
      transportOrders: [],
      columns: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default SuppliesData;
