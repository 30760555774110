import React from 'react';
import { useDispatch } from 'react-redux';
import { useField } from 'modules/shared/hooks/useField';
import { FormInput } from '../../elements/Inputs/FormInput';
import { Link, Button } from '../../pages/LoginPage/styles';
import { login } from 'modules/shared/infrastructure/redux/UserReducer/async';
import { useNavigate } from 'react-router-dom';
import { LoginTranslations } from 'modules/shared/infrastructure/i18n/Login/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { LoginText } from 'modules/shared/infrastructure/i18n/Login';

const loginTranslations: LoginTranslations = {
  ...LoginText[LocaleService.getLanguage()]
};
interface LoginFormProps {
  handleForgotPassword(): void;
}

export const LoginForm = ({ handleForgotPassword }: LoginFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const username = useField({ type: 'text' });
  const password = useField({ type: 'password' });

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(login({ username: username.value, password: password.value }));
    navigate('/');
  };

  return (
    <>
      <h3>{loginTranslations.welcome}</h3>
      <p>{loginTranslations.login}</p>
      <form onSubmit={handleLogin}>
        <FormInput
          {...username}
          name="username"
          label={loginTranslations.username}
          required
        />
        <FormInput
          {...password}
          name="password"
          label={loginTranslations.password}
          required
        />
        <Link type="button" onClick={handleForgotPassword}>
          {loginTranslations.forgottenPassword}
        </Link>
        <Button
          type="submit"
          disabled={!username.value.length || !password.value.length}
        >
          Login
        </Button>
      </form>
    </>
  );
};
