import { Translation } from 'infrastructure/i18n/typing';
import { ResponseTranslations } from './typing';

export const ResponseText: Translation<ResponseTranslations> = {
  es: {
    downloadSuccess: 'El archivo se ha descargado correctamente.',
    downloadError:
      'Hubo un error en la descarga del fichero, por favor vuelve a probar más tarde.',
    uploadSuccess: 'Claves actualizadas con éxito.',
    unauthorizedUser: 'Solo los proveedores pueden realizar esta acción.',
    missingCols: 'Faltan columnas en el archivo.',
    missingValues: 'Faltan valores en alguna(s) fila(s).',
    otherCauses: 'Error por otra causa.',
    tooLarge:
      'Hay errores en formatos: Longitud de carácteres máxima superada.',
    codeNotFound:
      'Faltan claves de proveedores en la subida. Por favor, añada todas las claves existentes basandose en la plantilla y vuelva a subir la información.',
    keysWithoutProviderCode:
      'Hay claves con el código de proveedor incorrecto en el sistema. Por favor, edite el archivo utilizando la plantilla y vuelva a subir la información.',
    duplicateKeys:
      'Claves duplicadas en uno o varios proveedores. Por favor, suba códigos de claves no repetidos en el sistema y vuelva a subir la información.',
    unauthorizedProviders: (providerIds) =>
      `El usuario no está autorizado a subir claves para los proveedores (${providerIds.join(
        ', '
      )}). Por favor, edite el archivo utilizando la plantilla y vuelva a subir la información.`,
    fileErrorMessage:
      'Ups algo no ha ido bien. Por favor, refresque la página e inténtelo de nuevo en unos minutos',
    fileUploadSuccessful: (fileName) =>
      `El Maestro  ${fileName} se ha cargado correctamente. Los datos se reflejaran en el panel durante los siguientes minutos.`,
    fileDownloadedSuccessfully: 'El archivo se ha descargado correctamente.',
    successDeleteMsg: 'Mensaje eliminado correctamente',
    failedDeleteMsg: 'El mensaje no ha podido ser eliminado',
    successPostMsg: 'El mensaje se ha añadido correctamente',
    failedPostMsg: 'El mensaje no ha podido ser añadido',
    createdContactSuccess:
      'La información de contacto ha sido creada correctamente',
    deleteContactSuccess:
      'La información de contacto ha sido borrada correctamente',
    updateContactSuccess:
      'La información de contacto ha sido actualizada correctamente',
    actionFailed:
      'La acción no ha podido completarse. Por favor vuelve a intentarlo más adelante',
    fileDownloading: 'El archivo se está descargando...',
    fileUploading: 'El archivo se está cargando...',
    maxCharacters: 'Máximo 50 carácteres',
    invalidName: 'Formato de nombre no válido',
    invalidEmail: 'Formato de correo no válido',
    editResponsibleSuccess:
      'El responsable del maestro ha sido actualizado con éxito',
    editResponsibleFailed:
      'El responsable del maestro no ha podido ser actualizado. Por favor intentelo de nuevo más tarde.'
  },
  en: {
    downloadSuccess: 'The file has been downloaded successfully.',
    downloadError:
      'There was an error downloading the file, please try again later.',
    uploadSuccess: 'Keys updated successfully.',
    unauthorizedUser: 'Only providers can be perform this action.',
    missingCols: 'Missing columns in the file.',
    missingValues: 'Missing values in some row(s).',
    otherCauses: 'Fail by another cause.',
    tooLarge: 'There are errors in formats: Maximum character length exceeded',
    codeNotFound:
      'Missing supplier material keys in the upload. Please, add all existing material keys in the upload file based on the template and upload the information again.',
    keysWithoutProviderCode:
      'There are material keys with incorrect supplier codes in the system. Please edit the file using the template and upload the information again.',
    duplicateKeys:
      'Duplicated material keys in one or multiple suppliers. Please upload non-repeated material key codes into the system.',
    unauthorizedProviders: (providerIds) =>
      `The user is not authorized to upload keys for providers (${providerIds.join(
        ', '
      )}). Please edit the file using the template and upload the information again.`,
    fileErrorMessage:
      'Oops something went wrong. Please, refresh the page and try again in a few minutes',
    fileUploadSuccessful: (fileName) =>
      `The Master  ${fileName} has been loaded successfully. The data will be reflected in the panel during the following minutes.`,
    fileDownloadedSuccessfully: 'The file has been downloaded successfully.',
    successDeleteMsg: 'Message deleted successfully',
    failedDeleteMsg: "The message couldn't be deleted",
    successPostMsg: 'The message has been added successfully',
    failedPostMsg: 'The message could not be added',
    createdContactSuccess: 'Contact information has been created correctly',
    deleteContactSuccess: 'Contact information has been successfully deleted',
    updateContactSuccess: 'Contact information has been updated correctly',
    actionFailed: 'The action could not be completed. Please try again later',
    fileDownloading: 'The file is downloading...',
    fileUploading: 'The file is uploading...',
    maxCharacters: 'Maximum 50 characters',
    invalidName: 'Invalid name format',
    invalidEmail: 'Invalid email format',
    editResponsibleSuccess:
      'The master responsible has been successfully updated',
    editResponsibleFailed:
      "The master's responsible could not be updated. Please try again later."
  },
  ca: {
    downloadSuccess: "El fitxer s'ha baixat correctament.",
    downloadError:
      'Hi va haver un error en la descàrrega del fitxer, si us plau torna a provar més tard.',
    uploadSuccess: 'Claus actualitzades amb èxit.',
    unauthorizedUser: 'Només els proveïdors poden realitzar aquesta acció',
    missingCols: "Falten columnes a l'arxiu.",
    missingValues: 'Manquen valors en alguna/es fila/es.',
    otherCauses: 'Errada per una altra causa.',
    tooLarge: 'Hi ha errors en formats: Longitud de caràcters màxima superada.',
    codeNotFound:
      'Falten claus de proveïdors a la pujada. Si us plau, afegiu totes les claus existents basant-vos en la plantilla i torneu a pujar la informació.',
    keysWithoutProviderCode:
      'Hi ha claus amb el codi de proveïdor incorrecte al sistema. Si us plau, editeu el fitxer utilitzant la plantilla i torneu a pujar la informació.',
    duplicateKeys:
      'Claus duplicades en un o diversos proveïdors. Si us plau, pugeu codis de claus no repetits al sistema i torneu a pujar la informació.',
    unauthorizedProviders: (providerIds) =>
      `L'usuari no està autoritzat a pujar claus per als proveïdors (${providerIds.join(
        ', '
      )}). Si us plau, editeu el fitxer utilitzant la plantilla i torneu a pujar la informació.`,
    fileErrorMessage:
      'Ups alguna cosa no ha anat bé. Si us plau, refresqueu la pàgina i intenteu-ho de nou en uns minuts',
    fileUploadSuccessful: (fileName) =>
      `El Mestre  ${fileName} s'ha carregat correctament. Les dades es reflectiran al panell durant els següents minuts.`,
    fileDownloadedSuccessfully: "El fitxer s'ha descarregat correctament",
    successDeleteMsg: 'Missatge eliminat correctament',
    failedDeleteMsg: "El missatge no s'ha pogut eliminar",
    successPostMsg: "El missatge s'ha afegit correctament",
    failedPostMsg: "El missatge no s'ha pogut afegir",
    createdContactSuccess:
      'La informació de contacte ha estat creada correctament',
    deleteContactSuccess:
      'La informació de contacte ha estat esborrada correctament',
    updateContactSuccess:
      'La informació de contacte ha estat actualitzada correctament',
    actionFailed:
      "L'acció no s'ha pogut completar. Si us plau torna a intentar-ho més endavant",
    fileDownloading: "El fitxer s'està descarregant...",
    fileUploading: "El fitxer s'està pujant...",
    maxCharacters: 'Màxim 50 caràcters',
    invalidName: 'Format de nom no vàlid',
    invalidEmail: 'Format de correu no vàlid',
    editResponsibleSuccess:
      'El responsable del mestre ha estat actualitzat amb èxit',
    editResponsibleFailed:
      "El responsable del mestre no s'ha pogut actualitzar. Si us plau proveu-ho de nou més tard."
  }
};
