import { lazyRetry } from 'modules/shared/hooks/lazyRetry';
import { lazy } from 'react';

export const FulfillmentPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/fulfillment/layout/pages/FulfillmentPage')
  )
);

export const LandingPage = lazy(() =>
  lazyRetry(() => import('modules/outbound/shared/ui/layout/pages/LandingPage'))
);

export const MidTermCapacitiesPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/outbound/capacities/ui/layout/pages/MidTermCapacitiesPage'
      )
  )
);

export const LongTermCapacitiesPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/outbound/capacities/ui/layout/pages/LongTermCapacitiesPage'
      )
  )
);

export const NoCreditCarsLoanPage = lazy(() =>
  lazyRetry(
    () =>
      import('modules/outbound/analytics/ui/layout/pages/NoCreditCarsLoanPage')
  )
);

export const GeolocationPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/fulfillment/layout/pages/GeolocationPage')
  )
);

export const GeolocationMapPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/fulfillment/layout/pages/GeolocationMapPage')
  )
);

export const WarehouseStockPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/stock/layout/pages/WarehouseStockPage')
  )
);

export const PipelineStockPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/stock/layout/pages/PipelineStockPage')
  )
);

export const CallOffPage = lazy(() =>
  lazyRetry(() => import('modules/outbound/callOff/layout/pages/CallOffPage'))
);

export const StockLocationPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/analytics/ui/layout/pages/StockLocationPage')
  )
);

export const DataQualityPage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/analytics/ui/layout/pages/DataQualityPage')
  )
);

export const CampaMRTStatePage = lazy(() =>
  lazyRetry(
    () => import('modules/outbound/analytics/ui/layout/pages/CampaMRTStatePage')
  )
);

export const DeliveryTimePage = lazy(() =>
  lazyRetry(
    () =>
      import('modules/outbound/deliveryTime/ui/layout/pages/DeliveryTimePage')
  )
);

export const InboundLandingPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/shared/ui/layout/pages/InboundLandingPage')
  )
);

export const NoJitPage = lazy(() =>
  lazyRetry(() => import('modules/inbound/nojit/ui/layout/pages/NoJitPage'))
);

export const SuppliesPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/supplies/ui/layout/pages/SuppliesPage')
  )
);

export const PartCoveragePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/jit/ui/layout/pages/PartsCoveragePage/PartsCoveragePage'
      )
  )
);

export const JitFamilyCoveragePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/jit/ui/layout/pages/JitFamilyCoveragePage/JitFamilyCoveragePage'
      )
  )
);

export const FamiliesCoverage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/jit/ui/layout/pages/FamiliesCoveragePage/FamiliesCoveragePage'
      )
  )
);

export const RefillsPage = lazy(() =>
  lazyRetry(() => import('modules/inbound/refills/ui/layout/pages/RefillsPage'))
);

export const EndOfProductionDatePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/endOfProductionDate/ui/layout/pages/EndOfProductionDatePage'
      )
  )
);

export const WarehouseCapacityMasterPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/maestros/masters/ui/layout/pages/MasterWarehouseCapacityPage'
      )
  )
);

export const LineGroupsMasterPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/maestros/masters/ui/layout/pages/LineGroupsMasterPage'
      )
  )
);

export const UsersPage = lazy(() =>
  lazyRetry(() => import('modules/inbound/users/ui/layout/pages/UsersPage'))
);

export const DirectDeliveryPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/directDelivery/ui/layout/pages/DirectDeliveryPage'
      )
  )
);

export const StocksPage = lazy(() =>
  lazyRetry(() => import('modules/inbound/stocks/ui/layout/pages/StocksPage'))
);

export const MaterialInTransferPage = lazy(() =>
  lazyRetry(
    () =>
      import('modules/inbound/transfer/ui/layout/pages/MaterialInTransferPage')
  )
);

export const TransfersNextHoursPage = lazy(() =>
  lazyRetry(
    () =>
      import('modules/inbound/transfer/ui/layout/pages/TransfersNextHoursPage')
  )
);

export const EntriesNextHoursPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/entries/ui/layout/pages/EntriesNextHoursPage')
  )
);

export const EntriesUnloadingPointPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/entries/ui/layout/pages/EntriesUnloadingPointPage'
      )
  )
);

export const EntriesInPlantPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/entries/ui/layout/pages/EntriesInPlantPage')
  )
);

export const FactoryBcnPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/factoryBcn/ui/layout/pages/FactoryBcnPage')
  )
);

export const ExpirationPage = lazy(() =>
  lazyRetry(
    () => import('modules/inbound/expiration/ui/layout/pages/ExpirationPage')
  )
);

export const ServiceLevelPage = lazy(() =>
  lazyRetry(
    () =>
      import('modules/outbound/serviceLevel/ui/layout/pages/ServiceLevelPage')
  )
);

export const MastersPanelPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/maestros/masters/ui/layout/pages/MastersPanelPage'
      )
  )
);

export const StucklisteSuppliersPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        'modules/inbound/stucklisteSuppliers/ui/layout/pages/StucklisteSuppliersPage'
      )
  )
);
