import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

class CheckpointDTO {
  name: string;
  type: CheckpointType;
  globalDelayed: number;
  checkpointDelayed: number;
  totalFinishedGoods: number;

  constructor({
    name,
    type,
    globalDelayed,
    checkpointDelayed,
    totalFinishedGoods
  }) {
    this.name = name;
    this.type = type;
    this.globalDelayed = globalDelayed;
    this.checkpointDelayed = checkpointDelayed;
    this.totalFinishedGoods = totalFinishedGoods;
  }
}

export default CheckpointDTO;
