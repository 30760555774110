export enum RefillsStatusType {
  DELAYED = 'DELAYED',
  ON_TIME = 'ON_TIME',
  NEAR_DELAY = 'NEAR_DELAY'
}

export enum SearchModality {
  TO = 'TO',
  BETWEEN = 'BETWEEN',
  FROM = 'FROM'
}
