import { FullWidthWrapper, WrapperExtensibleSidebar } from './styles';
import { ExtensibleSidebarProps } from './typing';
import LogoWithSubtitle from 'modules/shared/layout/elements/Logos/LogoWithSubtitle/index';
import { SidebarTranslations } from 'modules/shared/infrastructure/i18n/Sidebar/typing';
import { SidebarTexts } from 'modules/shared/infrastructure/i18n/Sidebar/index';
import localeService from 'infrastructure/i18n/LocaleService';
import scctLogo from 'assets/logos/scct-small.svg';
import { ReactComponent as DoubleArrow } from 'assets/icons/doubleArrowIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/cancelIcon.svg';
import { ReactComponent as Logout } from 'assets/icons/logoutIcon.svg';
import ExtensibleMenu from '../../elements/ExtensibleMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsMenuOpened,
  setIsMenuOpened
} from 'modules/shared/infrastructure/redux/SharedReducer';
import Button from '../../elements/Buttons/Button';
import useBreakpoints from 'modules/shared/hooks/useBreakpoints';
import { useRef } from 'react';
import useOutsideAlerter from 'modules/outbound/fulfillment/hooks/useOutsiderAlert';
import { authenticationService } from 'modules/shared/services/AuthenticationService';
import { logout } from 'modules/shared/infrastructure/redux/UserReducer';

const sidebarTranslations: SidebarTranslations = {
  ...SidebarTexts[localeService.getLanguage()]
};

const ExtensibleSidebar = ({
  items,
  outboundUserRole
}: ExtensibleSidebarProps) => {
  const dispatch = useDispatch();
  const { isTablet } = useBreakpoints();
  const isMenuOpened = useSelector(getIsMenuOpened);
  const refComponent = useRef(null);

  const handleLogout = () =>
    authenticationService.logout().then(() => {
      dispatch(logout());
    });
  const handleArrowClick = () => {
    dispatch(setIsMenuOpened(!isMenuOpened));
  };

  const handleClose = () => {
    dispatch(setIsMenuOpened(false));
  };

  useOutsideAlerter(refComponent, handleClose);
  return (
    <WrapperExtensibleSidebar ref={refComponent} isOpened={isMenuOpened}>
      <FullWidthWrapper>
        {!isTablet ? (
          <LogoWithSubtitle
            subtitle={sidebarTranslations.appName}
            src={scctLogo}
          />
        ) : (
          <Button className="close-sidebar" onClick={handleClose}>
            <CloseIcon data-testid="expanding-menu-arrow" />
          </Button>
        )}
        <ExtensibleMenu items={items} outboundUserRole={outboundUserRole} />
      </FullWidthWrapper>
      {!isTablet ? (
        <Button className="basic" onClick={handleArrowClick}>
          <DoubleArrow
            className="extensible-arrow"
            data-testid="expanding-menu-arrow"
          />
        </Button>
      ) : (
        <Button onClick={handleLogout} className="basic logout">
          <Logout data-test-id="logout" />
          <p>{sidebarTranslations.logout}</p>
        </Button>
      )}
    </WrapperExtensibleSidebar>
  );
};

export default ExtensibleSidebar;
