import { BrandEnum } from 'modules/shared/domain/enums';

export enum OrderType {
  ACHIEVEMENT_DESC = 'AchievementDescendent',
  ACHIEVEMENT_ASC = 'AchievementAscendent',
  DELAY_DESC = 'DelayTimeDescendent',
  DELAY_ASC = 'DelayTimeAscendent',
  NONE = ''
}
export enum GeolocationOrderType {
  GLOBAL_DELAY_DESC = 'GlobalDelayDescendent',
  GLOBAL_DELAY_ASC = 'GlobalDelayAscendent',
  SECTION_DELAY_DESC = 'SectionDelayDescendent',
  SECTION_DELAY_ASC = 'SectionDelayAscendent',
  NONE = ''
}

export enum ClientType {
  CLIENT = 'client_order',
  IMPORTER = 'importer_order',
  FLEET = 'stock_order',
  STOCK = 'fleet_order',
  NONE = ''
}

export enum FactoryEnum {
  Wolfsburg = '11',
  Zwickau = '28',
  Kvasiny = '33',
  Bratislava = '37',
  Martorell = '49',
  Palmela = '43',
  NONE = ''
}

export enum DateRangeType {
  MTD = 'MTD',
  WTD = 'WTD',
  CWTD = 'CWTD',
  Custom = 'Custom'
}

export interface SelectItemProps {
  key: string;
  text: string;
}

export interface SelectClientTypeProps {
  key: ClientType;
  text: string;
}

export interface SelectFactoryIdProps {
  key: FactoryEnum;
  text: string;
}

export interface SelectDateRangeListProps {
  key: DateRangeType;
  text: string;
}
export interface ICustomDateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

export interface FiltersReducerProps {
  fulfillment: {
    selectedCountries: SelectItemProps[];
    selectedCriteria: OrderType;
    selectedDateRange: DateRangeType;
    customDateRange: ICustomDateRange;
    selectedClientType: SelectClientTypeProps[];
    fastLaneSelected: boolean;
    selectedFactory: SelectFactoryIdProps[];
    selectedBrands: BrandEnum[];
    logisticObjectiveSelected: boolean;
    commercialPackageSelected: SelectItemProps[];
    viewRoutes: boolean;
    versandtreueSelected: boolean;
  };
  geolocation: {
    selectedCountries: SelectItemProps[];
    selectedCriteria: GeolocationOrderType;
    fastLaneSelected: boolean;
    selectedClientType: SelectClientTypeProps[];
    selectedFactory: SelectFactoryIdProps[];
    selectedBrands: BrandEnum[];
    logisticObjectiveSelected: boolean;
    commercialPackageSelected: SelectItemProps[];
    deliveryPointsSelected: SelectItemProps[];
  };
}
