import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';
import styled from 'styled-components';

const WrapperLanguageSelector = styled.section<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 7.7rem;
  color: ${(props: { theme: ThemeSEATProps }) => props.theme.colors.white.hexa};
  font-size: ${(props) => props.theme.fonts.regularSmall.minSizeAsPixels};
  background-color: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.colors.modalBlack.hexa};
  border-radius: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.borders.extralarge};
  border-bottom-left-radius: ${(props: { isActive: boolean }) =>
    props.isActive && 'unset'};
  border-bottom-right-radius: ${(props: { isActive: boolean }) =>
    props.isActive && 'unset'};
  z-index: 1;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
  span {
    margin: 0 5px;
  }
`;

const WrapperSelectedLanguage = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  .arrow-icon {
    transform: ${(props: { isActive: boolean }) =>
      props.isActive ? 'rotate(90deg)' : 'rotate(270deg)'};
    margin-top: 2px;
  }
`;

const WrapperFlags = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 7.7rem;
  top: 2.8rem;
  font-size: ${(props) => props.theme.fonts.regularExtraSmall.minSizeAsPixels};
  background-color: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.colors.modalBlack.rgb};
  border-bottom-left-radius: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.borders.extralarge};
  border-bottom-right-radius: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.borders.extralarge};

  button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 0.5rem;
    border-radius: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.borders.large};
    padding: 5px;
    &:last-child {
      margin: 2px 0.5rem 0.5rem;
    }

    &:hover {
      background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.colors.gray300.rgb};
    }

    img {
      width: 22px;
      height: 22px;
    }
  }
`;

export { WrapperSelectedLanguage, WrapperFlags };
export default WrapperLanguageSelector;
