import {
  IPagination,
  Pagination
} from 'modules/inbound/shared/domain/IPagination';
import NoJitDataDTO, {
  NoJitStockDTO
} from '../infrastructure/dto/NoJitDataDTO';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { NOJIT_COLUMNS } from './NoJitColumns';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NoJitStock } from './NoJitStock';

interface NoJitData {
  planningDates: string[];
  stock: NoJitStock[];
  targetCodes: string[];
  updateStockDate: string;
  updateStockTime: string;
  updatePlanningDate: string;
  updatePlanningTime: string;
  pagination: IPagination;
  columns: ColumnProps[];
}

export const noJitDataMapper = (dto: NoJitDataDTO): NoJitData => {
  const updateStockDate = GetDateFormatedService.dateFromISO8601(
    dto.update_stock
  );
  const updatePlanningDate = dto.update_planning
    ? GetDateFormatedService.dateFromISO8601(dto.update_planning)
    : '';

  const getNoJitCodes = (noJitStock: NoJitStockDTO[]) => {
    return noJitStock.map((item) => item.code);
  };

  return {
    updateStockDate: GetDateFormatedService.getDateFormated(
      updateStockDate,
      'de-DE'
    ),
    updateStockTime: GetDateFormatedService.getTimeFormated(updateStockDate),
    updatePlanningDate: updatePlanningDate
      ? GetDateFormatedService.getDateFormated(updatePlanningDate, 'de-DE')
      : '',
    updatePlanningTime: updatePlanningDate
      ? GetDateFormatedService.getTimeFormated(updatePlanningDate)
      : '',
    planningDates: dto.planning_dates,
    stock:
      dto.stock.map((stockItem) => NoJitStock.generateFromDto(stockItem)) || [],
    targetCodes: getNoJitCodes(dto.stock),
    columns: NOJIT_COLUMNS,
    pagination: Pagination.generateFromDTO(dto.pagination)
  };
};
