import { NoJitItemPlanning } from '../domain/NoJitPlanning';
import NoJitTransportsDetail from '../domain/NoJitTransportsDetail';

const GetNoJitTransportsDetailsService = (
  noJitPlanningByPiece: NoJitItemPlanning[]
) => {
  const piecePlanning = {
    planning: noJitPlanningByPiece
  };

  return NoJitTransportsDetail.generateFromDTO(piecePlanning);
};

export default GetNoJitTransportsDetailsService;
