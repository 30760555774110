import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { RefillsStatusType } from './types';

export interface IRefillsDTO {
  refills: RefillsDTO[];
  pagination: IPaginationDTO;
  last_update: string | null;
}
export interface RefillsDTO {
  code: string;
  transport_order_number: number;
  p17_coverage: number;
  stock: number;
  warehouse_unit_target: string;
  p17_stock: number | null;
  refill_time_in_minutes: number;
  delayed: boolean;
  warehouse_unit_source: string | null;
  p17_source: string | null;
  is_transfer: boolean;
  status: RefillsStatusType;
  type: string;
  handling_unit: string;
  created_at: string | null;
  source_location: string;
}
class RefillsDataDTO {
  refills: RefillsDTO[];
  pagination: IPaginationDTO;
  last_update: string | null;

  constructor({ refills, pagination, last_update }) {
    this.refills = refills;
    this.pagination = pagination;
    this.last_update = last_update;
  }
}

export default RefillsDataDTO;
