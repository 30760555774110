import IEndOfProductionDateRepository from '../domain/IEndOfProductionDateRepository';
import endOfProductionDateRepository from '../infrastructure/repositories/EndOfProductionDateRepository';

class DownloadEndOfProductionDateUseCase {
  private _endOfProductionDateRepository: IEndOfProductionDateRepository;

  constructor({ endOfProductionRepository }) {
    this._endOfProductionDateRepository = endOfProductionRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._endOfProductionDateRepository.downloadEndOfProductionDate({
      queryParams
    });
  }
}

export default new DownloadEndOfProductionDateUseCase({
  endOfProductionRepository: endOfProductionDateRepository
});

export { DownloadEndOfProductionDateUseCase };
