import { Translation } from 'infrastructure/i18n/typing';
import { UserTranslations } from './typing';

export const UserText: Translation<UserTranslations> = {
  es: {
    pageTitle: 'Gestión de usuarios',
    menuTitle: 'Usuarios',
    emptyMessage: 'No hay usuarios disponibles',
    emptyMessageUser: 'Ha ocurrido un errror en el servidor',
    user: 'Usuario',
    email: 'Email',
    role: 'Rol',
    roleTooltip:
      'Los Roles disponibles en el sistema permiten: <ul> <li> <b>SUPER-ADMIN</b> <ul> <li> <b>Acceder a los paneles</b> asignados </li> <li> <b>Editar los maestros</b> usados en el sistema </li> <li> <b>Ver todas las claves</b> de materiales de SEAT Martorell </li> </ul> </li> <br /> <li> <b>SEAT</b> <ul> <li> <b>Acceder a los paneles</b> asignados </li> <li> <b>Ver todas las claves</b> de materiales de SEAT Martorell </li> </ul> </li> <br /> <li> <b>Provider</b> <ul> <li> <b>Acceder a los paneles</b> asignados </li> <li> <b>Ver las claves</b> de materiales de los <b>proveedores asignados</b> </li> <li> <b>Traducir el código de claves</b> de material a código proveedor </li> </ul> </li> </ul>',
    action: 'Acción',
    providers: 'Proveedores',
    dashboards: 'Paneles',
    dashboardsPlaceholder: 'Selecciona paneles de usuario',
    createNewUser: 'Nuevo usuario',
    createUser: 'Crear usuario',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    userEmail: 'Introduce dirección de email',
    userName: 'Nombre de usuario',
    userNamePlaceholder: 'Introduce un nombre de usuario',
    errorEmail: 'Email inválido',
    rolePlaceholder: 'Selecciona rol',
    errorUser: 'Espacios en blanco no admitidos',
    providerPlaceholder: 'Busca y selecciona por nombre o código',
    availableProviders: 'Proveedores disponibles',
    selectedProviders: 'Proveedores seleccionados',
    emptyProviders: 'No hay proveedores seleccionados',
    successUserCreate: 'Usuario creado correctamente',
    failedUserCreate: 'El usuario introducido ya existe',
    editUser: 'Editar usuario',
    titleReset: 'Resetear Contraseña',
    titleDisable: 'Deshabilitar Usuario',
    titleDelete: 'Borrar Usuario',
    deleteMsgInfo: 'Se dispone a borrar el siguiente usuario',
    resetMsgInfo: 'Se dispone a resetear la contraseña del siguiente usuario',
    disableMsgInfo: 'Se dispone ha deshabilitar al siguiente usuario',
    delete: 'Eliminar',
    reset: 'Resetear',
    edit: 'Editar',
    disable: 'Deshabilitar',
    actionSuccess: 'Operación realizada correctamente',
    actionFailed: 'Ha ocurrido un error realizando la operación',
    dashboardPlaceholder: 'Paneles',
    availableDashboards: 'Paneles disponibles',
    selectedDashboards: 'Paneles seleccionados',
    emptyDashboards: 'No hay paneles disponibles',
    noJit: 'NOJIT',
    endOfProduction: 'Cese',
    jit: 'JIT',
    familyCoverage: 'JIT Cobertura Familias',
    dd: 'DD',
    factoryBCN: 'Factoría BCN',
    supplies: 'Suministros',
    refills: 'Reposiciones',
    transfers: 'Trasvases',
    entries: 'Entradas',
    warehouse: 'Almacenes',
    masters: 'Maestros',
    users: 'Usuarios',
    landing: 'Landing',
    expirations: 'Caducidades',
    stocks: 'Stocks',
    download: 'Descargar lista usuarios',
    usernameModificationWarning:
      'La modificación del nombre de usuario implica reseteo de contraseña mediante correo',
    userUpdatedSuccessfully: 'Usuario actualizado correctamente',
    searcherUsernameOrEmail: 'Búsqueda por usuario o email',
    group: 'Visibilidad',
    selectGroup: 'Selecciona visibilidad',
    visibilityGroups: 'Grupos de visibilidad',
    visibilityGroupsTooltip:
      'Visibilidad asignada al usuario.<ul><li>Con <b>INBOUND-INHOUSE</b>: Puede dar acceso a los paneles de entrada a fábrica y logística interna.</li><br/><li>Con <b>OUTBOUND</b>: Puede dar acceso a los paneles de salidas de producto acabado en la fábrica.</li></ul>',
    theUser: 'El usuario ',
    alreadyExists: ' ya existe.',
    createUserInstructions:
      'Rellena los campos que hay a continuación para crear un nuevo usuario.',
    userEdition: 'Edición usuario',
    providersOrDashboardsError:
      'La información de proveedores/paneles no se ha cargado correctamente. Vuelva a intentarlo más tarde.',
    lastConnectionDate: 'Fecha de última conexión',
    stucklisteSuppliers: 'Stuckliste proveedores'
  },
  en: {
    pageTitle: 'User management',
    menuTitle: 'Users',
    emptyMessage: 'There aren`t users available',
    emptyMessageUser: 'An error has occurred on the server',
    user: 'User',
    email: 'Email',
    role: 'Role',
    roleTooltip:
      'The Roles available in the system allow: <ul> <li> <b>SUPER-ADMIN</b> <ul> <li> <b>Access the assigned panels</b> </li> <li> <b>Edit the masters</b> used in the system </li> <li> <b>View all keys</b> for SEAT Martorell materials </li> </ul> </li> <br/> <li> <b>SEAT</b> <ul> <li> <b>Access the assigned panels</b> </li> <li> <b>See all the keys</b> of SEAT Martorell materials </li> </ul> </li> <br/> <li> <b>Provider</b> <ul> <li> <b>Access the assigned panels</b> </li> <li> <b>View the material keys</b> of the <b>assigned suppliers</b> </li> <li> <b>Translate the material key code</b> to supplier code </li> </ul> </li> </ul>',
    action: 'Action',
    providers: 'Providers',
    dashboards: 'Dashboards',
    dashboardsPlaceholder: 'Select user panels',
    createNewUser: 'New user',
    createUser: 'Create user',
    cancel: 'Cancel',
    accept: 'Accept',
    userEmail: 'Enter an email address',
    userName: 'User Name',
    userNamePlaceholder: 'Enter a username',
    errorEmail: 'Invalid email',
    rolePlaceholder: 'Select role',
    errorUser: 'Unsupported blanks',
    providerPlaceholder: 'Search and select by name or provider',
    availableProviders: 'Available providers',
    selectedProviders: 'Selected providers',
    emptyProviders: "There aren't selected providers",
    successUserCreate: 'User created successfully',
    failedUserCreate: 'User provided already exists',
    editUser: 'Edit user',
    titleReset: 'Reset Password',
    titleDisable: 'Disable User',
    titleDelete: 'Delete User',
    deleteMsgInfo: 'You are about to delete the following user',
    resetMsgInfo: 'You are about to reset the password of the following user',
    disableMsgInfo: 'It is ready to disable the following user',
    delete: 'Delete',
    reset: 'Reset',
    edit: 'Update',
    disable: 'Disable',
    actionSuccess: 'Operation successful',
    actionFailed: 'An error occurred while performing the operation',
    dashboardPlaceholder: 'Assign panel/s',
    availableDashboards: 'Available panels',
    selectedDashboards: 'Selected panels',
    emptyDashboards: "There aren't available panels",
    noJit: 'NOJIT',
    endOfProduction: 'End of Production',
    jit: 'JIT',
    familyCoverage: 'JIT Family Coverage',
    dd: 'DD',
    factoryBCN: 'Factory BCN',
    supplies: 'Supplies',
    refills: 'Refills',
    transfers: 'Transfers',
    entries: 'Entries',
    warehouse: 'Warehouses',
    masters: 'Masters',
    users: 'Users',
    landing: 'Landing',
    expirations: 'Expirations',
    stocks: 'Stocks',
    download: 'Download user list',
    usernameModificationWarning:
      'The modification of the username implies a password reset by email',
    userUpdatedSuccessfully: 'User updated successfully',
    searcherUsernameOrEmail: 'Search by user or email',
    group: 'Visibility',
    selectGroup: 'Select visibility',
    visibilityGroups: 'Visibility groups',
    visibilityGroupsTooltip:
      'Visibility assigned to the user.<ul><li>With <b>INBOUND-INHOUSE</b>: It can give access to the factory entrance and internal logistics panels.</li><br/><li>With <b>OUTBOUND</b>: It can give access to the finished product output panels in the factory.</li></ul>',
    theUser: 'The user ',
    alreadyExists: ' already exists.',
    createUserInstructions: 'Fill in the fields below to create a new user.',
    userEdition: 'User edition',
    providersOrDashboardsError:
      "Suppliers/panels information hasn't been obtained correctly. Try it again later.",
    lastConnectionDate: 'Last connection date',
    stucklisteSuppliers: 'Stuckliste suppliers'
  },
  ca: {
    pageTitle: "Gestió d'usuaris",
    menuTitle: 'Usuaris',
    emptyMessage: 'No hi ha usuaris disponibles',
    emptyMessageUser: 'Hi ha hagut un error al servidor',
    user: 'Usuari',
    email: 'Email',
    role: 'Rol',
    roleTooltip:
      'Els rols disponibles al sistema permeten: <ul> <li> <b>SUPER-ADMIN</b> <ul> <li> <b>Accedir als panells </b> assignats </li> <li> <b>Editar els mestres</b> usats al sistema </li> <li> <b>Veure totes les claus</b> de materials de SEAT Martorell </li> </ul> </li> <br/> <li> <b>SEAT</b> <ul> <li> <b>Accedir als panells</b> assignats </li> <li> <b>Veure totes les claus</b> de materials de SEAT Martorell </li> </ul> </li> <br/> <li> <b>Provider</b> <ul> <li> <b>Accedir als panells </b> assignats </li> <li> <b>Veure les claus</b> de materials dels <b>proveïdors assignats</b> </li> <li> <b>Traduir el codi de claus</b> de material a codi proveïdor </li> </ul> </li> </ul>',
    action: 'Acció',
    providers: 'Proveïdors',
    dashboards: 'Panels',
    dashboardsPlaceholder: "Selecciona panels d'usuari",
    createNewUser: 'Nou usuari',
    createUser: 'Crear usuari',
    cancel: 'Cancel·lar',
    accept: 'Acceptar',
    userEmail: 'Introdueix adreça de correu electrònic',
    userName: "Nom d'usuari",
    userNamePlaceholder: "Introduïu un nom d'usuari",
    errorEmail: 'Email invàlid',
    rolePlaceholder: 'Selecciona rol',
    errorUser: 'Espais en blanc no admesos',
    providerPlaceholder: 'Cerca i selecciona per nom o codi',
    availableProviders: 'Proveïdors disponibles',
    selectedProviders: 'Proveïdors seleccionats',
    emptyProviders: 'No hi ha proveïdors seleccionats',
    successUserCreate: 'Usuari creat correctament',
    failedUserCreate: "L'usuari introduït ja existeix",
    editUser: 'Editar usuari',
    titleReset: 'Resetear Contrasenya',
    titleDisable: 'Deshabilitar Usuari',
    titleDelete: 'Esborrar Usuari',
    deleteMsgInfo: 'Es disposa a esborrar al següent usuari',
    resetMsgInfo: 'Es disposa a reiniciar la contrasenya del següent usuari',
    disableMsgInfo: 'Es disposa a deshabilitar al següent usuari',
    delete: 'Esborrar',
    reset: 'Reset',
    edit: 'Actualitzar',
    disable: 'Deshabilitar',
    actionSuccess: 'Operació realitzada correctament',
    actionFailed: "Ha passat un error fent l'operació",
    dashboardPlaceholder: 'Assigna panel/s',
    availableDashboards: 'Panels disponibles',
    selectedDashboards: 'Panels seleccionats',
    emptyDashboards: 'No hi ha panels disponibles',
    noJit: 'NOJIT',
    endOfProduction: 'Cessament',
    jit: 'JIT',
    familyCoverage: 'JIT Cobertura Famílies',
    dd: 'DD',
    factoryBCN: 'Factoria BCN',
    supplies: 'Suministres',
    refills: 'Reposicions',
    transfers: 'Transvasaments',
    entries: 'Entries',
    warehouse: 'Magatzems',
    masters: 'Mestres',
    users: 'Usuaris',
    landing: 'Landing',
    expirations: 'Caducitats',
    stocks: 'Estocs',
    download: 'Descarregar llista usuaris',
    usernameModificationWarning:
      "La modificació del nom d'usuari implica reset de contrasenya mitjançant correu",
    userUpdatedSuccessfully: 'Usuari actualiztat correctament',
    searcherUsernameOrEmail: 'Cerca per usuari o email',
    group: 'Visibilitat',
    selectGroup: 'Selecciona visibilitat',
    visibilityGroups: 'Grups de visibilitat',
    visibilityGroupsTooltip:
      "Visibilitat assignada a l'usuari.<ul><li>Amb <b>INBOUND-INHOUSE</b>: Podeu accedir als panells d'entrada a fàbrica i logística interna.</li><br/><li>Amb <b>OUTBOUND</b>: Podeu donar accés als panells de sortides de producte acabat a la fàbrica.</li></ul>",
    theUser: "L'usuari ",
    alreadyExists: ' ja existeix.',
    createUserInstructions: 'Omple els camps següents per crear un nou usuari.',
    userEdition: 'Edició usuari',
    providersOrDashboardsError:
      "La informació dels proveïdors/panels no s'ha obtingut correctament. Torna-ho a provar més tard.",
    lastConnectionDate: "Data d'última connexió",
    stucklisteSuppliers: 'Stuckliste proveïdors'
  }
};
