import {
  WarehouseCapacityTableDataDTO,
  WarehouseCapacityTypesDTO
} from '../infrastructure/dto/WarehouseCapacityBodyDTO';

interface ComponentParams {
  type: {
    multiSelect: boolean;
    inputType?: string;
    optionsData?: string[];
  };
  readOnly: boolean;
}

interface WarehouseCapacityBodyData {
  board: string;
  columns: string[];
}
export interface WarehouseCapacityTypes {
  zone: ComponentParams;
  warehouse: ComponentParams;
  createdAt: ComponentParams;
  updatedAt: ComponentParams;
  capacity: ComponentParams;
  red: ComponentParams;
  green: ComponentParams;
  yellow: ComponentParams;
}

interface GenerateFromDTOParams {
  data: WarehouseCapacityTableDataDTO;
  types: WarehouseCapacityTypesDTO;
}

class WarehouseCapacityBody {
  data: WarehouseCapacityBodyData;
  types: WarehouseCapacityTypes;
  constructor({ data, types }: WarehouseCapacityBody) {
    this.data = data;
    this.types = types;
  }
  private static _generateComponentParams(type) {
    if (type === 'string') {
      return { multiSelect: false, inputType: 'text' };
    }
    if (type === 'float' || type === 'integer' || type === 'number') {
      return { multiSelect: false, inputType: 'number' };
    }
    if (Array.isArray(type)) {
      return { multiSelect: true, optionsData: type };
    }
    return { multiSelect: false, inputType: 'text' };
  }
  private static _toCamelCase(str) {
    str = str.replace(/[-_\s]+(.)?/g, (match, ch) =>
      ch ? ch.toUpperCase() : ''
    );

    return str.substr(0, 1).toLowerCase() + str.substr(1);
  }

  static generateFromDTO({
    data,
    types
  }: GenerateFromDTOParams): WarehouseCapacityBody {
    return new WarehouseCapacityBody({
      data: {
        board: this._toCamelCase(data.board),
        columns: data.columns.map((item) => this._toCamelCase(item))
      },
      types: {
        capacity: {
          type: this._generateComponentParams(types.capacity.type),
          readOnly: types.capacity.read_only
        },
        warehouse: {
          type: this._generateComponentParams(types.warehouse.type),
          readOnly: types.warehouse.read_only
        },
        zone: {
          type: this._generateComponentParams(types.zone.type),
          readOnly: types.zone.read_only
        },
        green: {
          type: this._generateComponentParams(types.green.type),
          readOnly: types.green.read_only
        },
        yellow: {
          type: this._generateComponentParams(types.yellow.type),
          readOnly: types.yellow.read_only
        },
        red: {
          type: this._generateComponentParams(types.red.type),
          readOnly: types.red.read_only
        },
        createdAt: {
          type: this._generateComponentParams(types.created_at.type),
          readOnly: types.created_at.read_only
        },
        updatedAt: {
          type: this._generateComponentParams(types.updated_at.type),
          readOnly: types.updated_at.read_only
        }
      }
    });
  }
}

export default WarehouseCapacityBody;
