import { Translation } from 'infrastructure/i18n/typing';
import { GeolocationTranslations } from './typing';

export const GeolocationTexts: Translation<GeolocationTranslations> = {
  es: {
    ranking: 'Ranking retraso por países',
    sectionDelay: 'Retrasos en tramo',
    globalDelay: 'Retrasos globales',
    cummulativeDelay: 'Retrasos acumulados',
    carNumber: 'Nº coches',
    noResults: 'No se han econtrado resultados',
    checkpoint: 'Tramo de ruta',
    delayedCars: 'Coches retrasados',
    cumulative: 'Acumulado',
    global: 'Global',
    noCountrySelected: 'No hay ningún país seleccionado',
    unknown: 'Desconocido',
    noCheckpoints: 'No se han encontrado resultados',
    vin: 'Nº Bastidor',
    lastRedistributionWarehouse: 'Destino',
    currentCheckpointDate: 'Fecha entrada en campa',
    nextCheckpointETA: 'Objetivo tramo',
    delayedTime: 'Retraso Vs Obj. tramo',
    cumulativeDelayedTime: 'Retraso acumulado',
    status: 'Status',
    geolocationEta: 'Geo ETA',
    statusFilter: {
      critical: 'Retrasado globalmente',
      danger: 'Retraso acumulado',
      warning: 'Retrasado en tramo',
      ok: 'A tiempo'
    },
    deleteFilters: 'Borrar Filtros',
    vinSearch: 'Busca por bastidor',
    vinImoNameSearch: 'Busca por bastidor, IMO o nombre de barco',
    total: 'Total',
    route: 'Ruta',
    carrierName: 'Nombre transportista',
    section: 'Tramo',
    locationDate: 'Fecha localización',
    departureDate: 'Fecha salida',
    currentLocation: 'Ubicación',
    noGeoMessage: 'No hay geolocalización para este coche.',
    frameNumbers: 'Bastidores',
    cityOriginPort: 'País / Último puerto',
    cityNextPort: 'País / Próximo puerto',
    atd: 'ATD (Última fecha desde el último puerto)',
    updateDateMarineTraffic: 'Última actualización:',
    totalCarsTransported: 'Total coches transportados',
    downloadExcelVins: 'Descargar excel VINS',
    downloadExcelVinsSuccess:
      'El archivo de VINS se ha descargado correctamente',
    downloadExcelVinsPending: 'El archivo de VINS se está descargando...',
    downloadExcelVinsError:
      'El archivo de VINS no se ha podido descargar. Intentelo de nuevo más tarde.',
    ata: 'ATA (Fecha de llegada al puerto actual)',
    cityCurrentPort: 'País / Puerto actual',
    pendingData: 'Pendiente datos',
    sailing: 'Navegando'
  },
  en: {
    ranking: 'Delay ranking by country',
    sectionDelay: 'Section delays',
    globalDelay: 'Global delays',
    cummulativeDelay: 'Cummulative delays',
    carNumber: 'No. cars',
    noResults: 'Results not found',
    checkpoint: 'Checkpoint',
    delayedCars: 'Delayed cars',
    cumulative: 'Cumulative',
    cumulativeDelayedTime: 'Cummulative delay',
    global: 'Global',
    noCountrySelected: 'There is no country selected',
    unknown: 'Unknown',
    noCheckpoints: 'There were no results',
    vin: 'No. Bastidor',
    lastRedistributionWarehouse: 'Destination',
    currentCheckpointDate: 'Enter in warehouse date',
    nextCheckpointETA: 'Section target',
    delayedTime: 'Delay Vs Sec. target',
    status: 'Status',
    geolocationEta: 'Geo ETA',
    statusFilter: {
      critical: 'Globaly delayed',
      danger: 'Cummulative delay',
      warning: 'Section delayed',
      ok: 'On time'
    },
    deleteFilters: 'Reset Filters',
    vinSearch: 'Search by frame number',
    vinImoNameSearch: 'Search by frame number, IMO or boat name',
    total: 'Total',
    route: 'Route',
    carrierName: 'Carrier name',
    section: 'Section',
    locationDate: 'Location date',
    departureDate: 'Departure date',
    currentLocation: 'Location',
    noGeoMessage: 'There is no geolocalization for this car.',
    frameNumbers: 'Frame numbers',
    cityOriginPort: 'Country / Last port',
    cityNextPort: 'Country / Next port',
    atd: 'ATD (Last date from last port)',
    updateDateMarineTraffic: 'Last update:',
    totalCarsTransported: 'Total cars transported',
    downloadExcelVins: 'Download excel VINS',
    downloadExcelVinsSuccess: 'The VINS file has been downloaded successfully',
    downloadExcelVinsPending: 'The VINS file is downloading...',
    downloadExcelVinsError:
      'The VINS file could not be downloaded. Try it again later.',
    ata: 'ATA (Arrival date at current port)',
    cityCurrentPort: 'Current Country/Port',
    pendingData: 'Pending data',
    sailing: 'Sailing'
  },
  ca: {
    ranking: 'Ranking retràs per país',
    sectionDelay: 'Retards en tram',
    globalDelay: 'Retards globals',
    cummulativeDelay: 'Retards acumulats',
    cumulativeDelayedTime: 'Retard acumulat',
    carNumber: 'Núm. cotxes',
    noResults: "No s'han trobat resultats",
    checkpoint: 'Tram de ruta',
    delayedCars: 'Cotxes retardats',
    cumulative: 'Acumulat',
    global: 'Global',
    noCountrySelected: 'No hi ha cap país seleccionat',
    unknown: 'Desconegut',
    noCheckpoints: "No s'han trobat resultats",
    vin: 'Núm. Bastidor',
    lastRedistributionWarehouse: 'Destí',
    currentCheckpointDate: "Data d'entrada a campa",
    nextCheckpointETA: 'Objectiu tram',
    delayedTime: 'Retard Vs Obj. tram',
    status: 'Status',
    geolocationEta: 'Geo ETA',
    statusFilter: {
      critical: 'Retrassat globalment',
      danger: 'Retard acumulat',
      warning: 'Retrassat en tram',
      ok: 'A temps'
    },
    deleteFilters: 'Borrar Filtres',
    vinSearch: 'Busca per bastidor',
    vinImoNameSearch: 'Busca per bastidor, IMO o nom del vaixell',
    total: 'Total',
    route: 'Ruta',
    carrierName: 'Nom transportista',
    section: 'Tram',
    locationDate: 'Data localizació',
    departureDate: 'Data sortida',
    currentLocation: 'Ubicació',
    noGeoMessage: 'No hi ha geolocalització per aquest cotxe.',
    frameNumbers: 'Bastidors',
    cityOriginPort: 'País / Últim port',
    cityNextPort: 'País / Proper port',
    atd: "ATD (última data des de l'últim port)",
    updateDateMarineTraffic: 'Última actualització:',
    totalCarsTransported: 'Total cotxes transportats',
    downloadExcelVins: 'Descarregar excel VINS',
    downloadExcelVinsSuccess: "El fitxer de VINS s'ha baixat correctament",
    downloadExcelVinsPending: "El fitxer de VINS s'està descarregant...",
    downloadExcelVinsError:
      "El fitxer de VINS no s'ha pogut baixar. Intenteu-ho de nou més tard.",
    ata: "ATA (Data d'arribada al port actual)",
    cityCurrentPort: 'País / Port actual',
    pendingData: 'Pendent dades',
    sailing: 'Navegant'
  }
};
