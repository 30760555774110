import { createAsyncThunk } from '@reduxjs/toolkit';
import getRefillsAlertsService from 'modules/inbound/refills/application/GetRefillsAlertsService';
import getRefillsDataService from 'modules/inbound/refills/application/GetRefillsDataService';
import getRefillsStatusDataService from 'modules/inbound/refills/application/GetRefillsStatusDataService';
import getRefillsSaturationService from 'modules/inbound/refills/application/GetRefillsSaturationService';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import {
  getCurrentPage,
  getResultsPerPage,
  getFilterSearch,
  getSelectedAlert,
  getSelectedStatus,
  getSelectedColumn
} from '.';
import { AlertType, FiltersItem } from './types';
import { SearchModality } from '../../../infrastructure/dto/types';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getRefillsData = createAsyncThunk(
  `refillsData/getRefillsData`,
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const searchValue = getFilterSearch(appState);
    const selectedAlert = getSelectedAlert(appState);
    const selectedStatus = getSelectedStatus(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;

    const queryFilters: FiltersItem[] = [];
    let querySearch: string[] = [];

    searchValue &&
      querySearch.push(
        `{${NameValues.CODE}:LK:${searchValue}|OR|${NameValues.OT_NUMBER}:LK:${searchValue}|OR|${NameValues.HANDLING_UNIT}:LK:${searchValue}}`
      );

    selectedAlert.type &&
      queryFilters.push({
        name:
          selectedAlert.type === AlertType.TRANSFER
            ? NameValues.IS_TRANSFER
            : NameValues.TYPE,
        value: selectedAlert.type === AlertType.TRANSFER ? 1 : AlertType.LINE
      });
    selectedAlert.status &&
      queryFilters.push({
        name: NameValues.STATUS,
        value: selectedAlert.status
      });

    queryFilters.forEach((filter) => {
      querySearch.push(`${filter.name}:=:${filter.value}`);
    });

    if (selectedStatus.status) {
      if (selectedStatus.searchModality === SearchModality.TO) {
        querySearch.push(
          `${NameValues.CREATED_AT}:<=:${selectedStatus.toDateTime}`
        );
      } else if (selectedStatus.searchModality === SearchModality.FROM) {
        querySearch.push(
          `${NameValues.CREATED_AT}:>=:${selectedStatus.fromDateTime}`
        );
      } else if (selectedStatus.searchModality === SearchModality.BETWEEN) {
        querySearch.push(
          `${NameValues.CREATED_AT}:>=:${selectedStatus.fromDateTime}`
        );
        querySearch.push(
          `${NameValues.CREATED_AT}:<:${selectedStatus.toDateTime}`
        );
      }

      querySearch.push(`${NameValues.STATUS}:=:${selectedStatus.status}`);
    }

    const query = querySearch.join('||');

    const queryParams = {
      page: currentPage,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: query && `{${query}}`
    };

    const response = await getRefillsDataService.execute({
      queryParams
    });
    return response;
  }
);

const getRefillsStatus = createAsyncThunk(
  `refillsData/getRefillsStatus`,
  async () => {
    const response = await getRefillsStatusDataService.execute();
    return response;
  }
);

const getRefillsAlerts = createAsyncThunk(
  `refillsData/getRefillsAlerts`,
  async () => {
    const response = await getRefillsAlertsService.execute();
    return response;
  }
);

const getRefillsSaturation = createAsyncThunk(
  `refillsData/getRefillsSaturation`,
  async () => {
    const response = await getRefillsSaturationService.execute();
    return response;
  }
);

export {
  getRefillsData,
  getRefillsStatus,
  getRefillsAlerts,
  getRefillsSaturation
};
