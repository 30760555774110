interface GenerateFromDTOParams {
  provider_inbounders: string[];
  status: string[];
  warehouses_codes: string[];
  models: string[];
}

class EndOfProductionFilters {
  providerInbounders: string[];
  status: string[];
  warehousesCodes: string[];
  models: string[];

  constructor({ providerInbounders, status, warehousesCodes, models }) {
    this.providerInbounders = providerInbounders;
    this.status = status;
    this.warehousesCodes = warehousesCodes;
    this.models = models;
  }

  static generateFromDTO({
    provider_inbounders,
    status,
    warehouses_codes,
    models
  }: GenerateFromDTOParams): EndOfProductionFilters {
    return new EndOfProductionFilters({
      providerInbounders: provider_inbounders,
      status: status,
      warehousesCodes: warehouses_codes,
      models: models
    });
  }

  static generateEmpty() {
    return new EndOfProductionFilters({
      providerInbounders: [],
      status: [],
      warehousesCodes: [],
      models: []
    });
  }
}

export default EndOfProductionFilters;
