import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import ProviderDTO from '../dto/AllProvidersDTO';
import { API_GET_ALL_PROVIDERS } from './url';
import IProvidersRepository from '../../domain/AllProviders/IAllProviders';

class ProvidersRepository
  extends BaseRepository
  implements IProvidersRepository
{
  private readonly _api: IApi;
  private readonly _apiGetAllProviders: string;

  constructor({ api, apiGetAllProviders }) {
    super();
    this._api = api;
    this._apiGetAllProviders = apiGetAllProviders;
  }

  getAllProviders() {
    const url = this._apiGetAllProviders;
    return this._api.get(url).then((res) => new ProviderDTO(res));
  }
}

export default new ProvidersRepository({
  api: api,
  apiGetAllProviders: API_GET_ALL_PROVIDERS
});

export { ProvidersRepository };
