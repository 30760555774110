const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const ENTRIES = '/entries';
const IN_PLANT = '/in-plant';
const DOWNLOAD = '/download';
const ENTRIES_FILTERS = `${ENTRIES}/filters`;
const API_GET_ENTRIES_STOCK = `${INBOUND_BASE_URL}${ENTRIES}`;
const INCOMING_MATERIALS_ENDPOINT = `${API_GET_ENTRIES_STOCK}/next`;
const API_GET_ENTRIES_IN_PLANT = `${API_GET_ENTRIES_STOCK}${IN_PLANT}`;
const API_GET_ENTRIES_FILTERS = `${INBOUND_BASE_URL}${ENTRIES_FILTERS}`;
const API_GET_ENTRIES_PARALYZATION_SUMMARY = `${API_GET_ENTRIES_IN_PLANT}/paralyzation/summary`;
const API_GET_ENTRIES_IN_PLANT_FILTERS = `${API_GET_ENTRIES_STOCK}${IN_PLANT}/filters`;
const API_GET_ENTRIES_SUMMARY = `${API_GET_ENTRIES_STOCK}/summary`;
const API_GET_GLOBAL_DLZ_SUMMARY = `${API_GET_ENTRIES_IN_PLANT}/warehouse-dlz/url`;
const API_GET_SATURATION_UNLOADING_POINTS = `${INBOUND_BASE_URL}/unloading-points-saturation`;
const API_GET_ENTRIES_BY_SLOT_AND_SATURATION_POINT = `${API_GET_SATURATION_UNLOADING_POINTS}${ENTRIES}`;
const API_GET_SATURATION_UNLOADING_POINTS_FILTERS = `${API_GET_SATURATION_UNLOADING_POINTS}/filters`;
const API_DOWNLOAD_ENTRIES = `${API_GET_ENTRIES_STOCK}${DOWNLOAD}`;
const API_DOWNLOAD_ENTRIES_IN_PLANT = `${API_GET_ENTRIES_IN_PLANT}${DOWNLOAD}`;
const API_GET_URGENT_TRUCKS = `${API_GET_ENTRIES_STOCK}/urgent/landing`;

export {
  INCOMING_MATERIALS_ENDPOINT,
  API_GET_ENTRIES_STOCK,
  API_GET_ENTRIES_IN_PLANT,
  API_GET_ENTRIES_FILTERS,
  API_GET_ENTRIES_PARALYZATION_SUMMARY,
  API_GET_ENTRIES_IN_PLANT_FILTERS,
  API_GET_ENTRIES_SUMMARY,
  API_GET_GLOBAL_DLZ_SUMMARY,
  API_GET_SATURATION_UNLOADING_POINTS,
  API_GET_ENTRIES_BY_SLOT_AND_SATURATION_POINT,
  API_GET_SATURATION_UNLOADING_POINTS_FILTERS,
  API_DOWNLOAD_ENTRIES,
  API_DOWNLOAD_ENTRIES_IN_PLANT,
  API_GET_URGENT_TRUCKS
};
