import { createAsyncThunk } from '@reduxjs/toolkit';
import getEndOfProductionFiltersService from 'modules/inbound/endOfProductionDate/application/GetEndOfProductionFiltersService';

export const getEndOfProductionFilters = createAsyncThunk(
  'endOfProductionFiltersData/getEndOfProductionFilters',
  async () => {
    const response = await getEndOfProductionFiltersService.execute();
    return response;
  }
);
