import { createSlice } from '@reduxjs/toolkit';
import { WarehouseStockState } from './types';
import { getWarehouseStockOverview } from './async';

export const initialState: WarehouseStockState = {
  warehousesOverview: [],
  selected: '',
  filters: {
    fastLaneSelected: false
  },
  requestStatus: ''
};

const warehouseStockSlice = createSlice({
  name: 'warehouseStock',
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
    },
    selectFastLane: (state, action) => {
      state.filters.fastLaneSelected = action.payload;
    },
    restartRequestStatus: (state) => {
      state.requestStatus = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWarehouseStockOverview.fulfilled, (state, action) => {
      state.warehousesOverview = action.payload || [];
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseStockOverview.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseStockOverview.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

// selectors
export const getWarehousesOverview = (state) =>
  state.warehousesOverviewState.warehousesOverview;

export const getRequestStatus = (state) =>
  state.warehousesOverviewState.requestStatus;

export const getSelected = (state) => state.warehousesOverviewState.selected;

export const getFastLaneSelected = (state) =>
  state.warehousesOverviewState.filters.fastLaneSelected;

export const getAllWarehousesTotalStock = (state) => {
  const reducer = (accumulator, warehouse) => accumulator + warehouse.stock;
  return state.warehousesOverviewState.warehousesOverview.reduce(reducer, 0);
};

export const getWarehouseStock = (state, warehouseReference) => {
  if (!warehouseReference) {
    return getAllWarehousesTotalStock(state);
  }
  return state.warehousesOverviewState.warehousesOverview.find(
    (item) => item.id === warehouseReference
  )?.stock;
};

//actions creators
export const { updateSelected, selectFastLane, restartRequestStatus } =
  warehouseStockSlice.actions;

export default warehouseStockSlice.reducer;
