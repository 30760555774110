import { Translation } from 'infrastructure/i18n/typing';
import { NoJitTranslations } from './typing';

export const NoJitText: Translation<NoJitTranslations> = {
  es: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage: 'No hay stock disponible para el proveedor.',
    stockUpdate: 'Act. stock:',
    productionPlanUpdate: 'Act. plan producción:',
    code: 'Clave',
    providerCode: 'Clave proveedor',
    name: 'Descripción',
    lines: 'Lineas',
    models: 'Modelos',
    stock: 'Stock en almacén',
    factoryCoverage: 'Stock',
    tierStock: 'Stock T1',
    stockReportedBySupplier: 'Stock reportado por el proveedor',
    endProductionInDays: 'Cese',
    inbounder: 'Inbounder',
    providerName: 'Proveedores',
    providerId: 'Código proveedor',
    country: 'País',
    workshops: 'Talleres',
    countriesMultiselectAvailable: 'Paises disponibles',
    countriesMultiselectSelected: 'Paises seleccionados',
    countriesMultiselectEmpty: 'No hay paises disponibles',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    suppliersMultiselectAvailable: 'Proveedores disponibles',
    suppliersMultiselectSelected: 'Proveedores seleccionados',
    suppliersMultiselectEmpty: 'No hay proveedores disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionados',
    inbounderMultiselectEmpty: 'No hay inbounders disponibles',
    modelMultiselectAvailable: 'Modelos disponibles',
    modelMultiselectSelected: 'Modelos seleccionados',
    modelMultiselectEmpty: 'No hay modelos disponibles',
    workshopMultiselectAvailable: 'Talleres disponibles',
    workshopMultiselectSelected: 'Talleres seleccionados',
    workshopMultiselectEmpty: 'No hay talleres disponibles',
    warehousesMultiselectAvailable: 'Almacenes disponibles',
    warehousesMultiselectSelected: 'Almacenes seleccionados',
    warehousesMultiselectEmpty: 'No hay Almacenes disponibles',
    geolocated: 'Geolocalizado',
    mrp: 'Pedido',
    mrps: 'Pedidos',
    more: '...más',
    pastLoads: 'Info soporte de tránsitos',
    pastLoadsTooltip:
      'Datos de ASN inesperados: <ul><li>Agrupación de transportes con fecha prevista de ASN en el pasado.</li> <li>Agrupación de transportes sin confirmación de llegada.</li></ul>',
    coverageAtRisk: 'Coberturas en riesgo',
    coverageAtRiskOK: 'Cob OK',
    coverageAtRiskSecurity: 'Riesgo stock de seguridad',
    coverageAtRiskCritical: 'Cob crítica',
    searcherPartOrNamePlaceholder: 'Búsqueda por clave o descripción',
    plateCopiedText: 'Número de matrícula copiado',
    plateEmptyText: 'No existe número de matrícula para copiar',
    calculationTooltip:
      'El cálculo considera hasta 15 días, todo lo demás se refleja como MAX',
    coverageDateTimeTooltip:
      'Fecha y hora cuando el stock de SEAT no llega a cubrir consumos. Actualmente no cuenta el stock en línea',
    warehouse: 'Almacén',
    viewTransportsAndOrders: 'Visualiza los transportes y pedidos existentes',
    type: 'Tipo',
    amount: 'Cantidad',
    providerDeliveryNote: 'Albarán',
    plateNumber: 'Matrícula tractora',
    transporter: 'Transportista',
    leadtime: 'Leadtime',
    transit: 'Tránsito',
    transits: 'Tránsitos',
    transportsNoJit: 'Plan de entradas',
    nonGeolocatedTransport: 'T. No geolocalizado',
    deliveryStatus: 'Estado cumplimiento pedidos',
    deliveryQuantity: 'Llenado de pedidos planificados',
    deliveryQuantityTooltip:
      'Indicadores para identificar el <b>estado de cantidad entregada</b> de los pedidos llegando a fábrica.',
    deliveryPunctuality: 'Puntualidad de pedidos planificados',
    deliveryPunctualityTooltip:
      '<b>Indicadores</b> para identificar el <b>estado de puntualidad de los pedidos</b> llegando a fábrica.',
    complete: 'Completos',
    leftover: 'Sobrantes',
    riskOfOvertake: 'Riesgo de adelanto',
    riskOfDelay: 'Riesgo de atraso',
    onTime: 'Puntual',
    unknow: '-',
    missing: 'Faltantes',
    factoryCoverageTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>stock en almacén</b>:<ul><li>En corto plazo: MAX (dd/mm/yy, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    coverageWithLabel: 'Cobertura con',
    coverageOrdersTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>SEAT stock + Programas (planes de entrega)</b>:<ul><li>En corto plazo: MAX (dd/mm/yy, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    orders: 'Pedidos',
    viewOrders: 'Visualiza los pedidos existentes',
    urgentTransit: 'Tránsito urgente',
    others: 'Validación de datos',
    othersTooltip:
      'Agrupación de pedidos con posibles <b>incoherencias de datos</b>.',
    noEntries: 'Sin entradas',
    noOrders: 'Sin pedidos',
    transitsCoverage: 'Tránsitos',
    transitsCoverageTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>SEAT stock + Tránsitos en curso</b>: <ul><li>En corto plazo: MAX (dd/mm/yy, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    deliveryOrders: 'Entrega pedidos',
    windowBooked: 'Ventana horaria',
    estimatedTime: 'Estimada modelo',
    estimatedTimeTitle: 'Fecha estimada',
    supplierDate: 'Fecha proveedor',
    provDeliveryTimeTooltip:
      'Fecha estimada de llegada según ASN del proveedor',
    leadtimeExpectedDate: 'Fecha lead time',
    providerExpectedDate: 'Proveedor',
    provDeliveryTime: 'Fecha proveedor',
    statusTooltip:
      '<b>Estado tránsitos:</b><ul><li><b>Atrasado</b>: Tiene fecha prevista a pasado</li><li><b>En consolidación</b>: Tiene el estado TP</li><li><b>En expedición</b>: Tiene el estado TR</li><li><b>Descargando planta</b>: Tiene el estado EP</li></ul><b>Estado pedidos:</b><ul><li><b>Puntualidad</b> (Puntual, Riesgo atraso, Riesgo adelanto)</li><li><b>Cantidad</b> (Completo, Incompleto, Sobrante)</li></ul>',
    departureDate: 'Fecha salida',
    departureDateTooltip: 'Fecha salida según ASN del proveedor',
    kccTransit: 'Tránsito consolidación',
    leadtimeTooltip: 'Tiempo pactado de expedición con cada proveedor',
    officialDeliveryTime: 'Fecha oficial sist.',
    officialDeliveryTimeTooltip:
      '<b>En los tránsitos:</b><ul><li>Muestra la fecha de llegada más precisa del sistema.</li></ul> <i>(La fecha oficial se usa para el cálculo de cobertura con tránsitos y ubica el día de llegada del transporte en el calendario. La fecha es dinámica y cambia de tipo durante el tiempo.)</i><br /><br/><b>En los pedidos:</b><ul><li>Es la fecha de planificada del pedido.</li></ul>',
    transitOfficialDeliveryTimeTooltip:
      'Muestra la fecha de llegada más precisa que tiene el sistema. Es la misma fecha que la mostrada en la columna de días.',
    mrpOfficialDeliveryTimeTooltip:
      'Fecha acordada en la que el pedido debe llegar a SEAT.',
    dateType: 'Tipo fecha oficial',
    dateTypeTooltip:
      'Tipos de fechas en tránsitos (por orden de precisión sobre disponibilidad):<ul><li><b> Ventana horaria:</b> Fecha reservada desde Martorell para la entrada a planta del transporte (LKW)</li><li><b> Estimada modelo:</b> Fecha calculada mediante un modelo predictivo basandose en históricos de llegada</li><li><b> Fecha proveedor:</b> Fecha notificada por parte del proveedor a fábrica mediante ASN</li><li><b> Fecha lead time:</b> Fecha de salida del transporte + lead time contratado</li></ul>',
    tierStockUpdatedAt: 'Fecha act. stock T1',
    tierStockUpdatedAtTooltip: 'Fecha de actualización del stock del Tier 1',
    coverages: 'Coberturas',
    withStock: 'Con stock',
    withOrders: 'Con pedidos',
    coverageLessZero: 'Cobertura < 0',
    withTransits: 'Con tránsito',
    withoutNeeds: 'Sin necesidades',
    shortTerm: 'Horizonte corto plazo',
    longTerm: 'Horizonte largo plazo',
    shortTermExtended: 'Corto plazo (próximos 15 días)',
    longTermExtended: 'Largo plazo (próximas 11/12 semanas)',
    multiple: 'Múltiples',
    entryTypes: 'Tipos de entrada',
    geolocationEta: 'ETA geolocalizada',
    geolocation: 'Geolocalización',
    dateTypeModalTransport: 'Tipo fecha',
    firm: 'Firme',
    delay: 'Atraso',
    forecast: 'Previsión',
    serviceLevel: 'Nivel de servicio',
    serviceLevelTooltip:
      'Indicadores de estado de cumplimientos de entrega. <br/><br/><b>Más información</b> sobre los estado de cumplimiento en el componente <b>"Estados cumplimiento pedidos"</b>.',
    incomplete: 'Incompletos',
    onTimeTooltip:
      '<b>Entrega completamente la cantidad</b> solicitada <b>el día previsto</b> de recepción o aún <b>hay tiempo para iniciar el transporte</b> y llegar el día pactado.',
    riskOfDelayTooltip:
      'El pedido tiene una fecha solicitada no asumible, ya que el <b>transporte llega tarde o no hay transporte iniciado</b> y ha pasado el tiempo límite para iniciar la expedición.',
    riskOfOvertakeTooltip:
      'El pedido se cuenta si <b>entrega completamente la cantidad solicitada antes del día</b> previsto de recepción.',
    completeTooltip:
      'El pedido solicitado tiene el <b>total de la cantidad entregada o en camino</b> a la fecha acordada.',
    incompleteTooltip:
      'El pedido solicitado tiene <b>cantidades no cubiertas o llegando días después</b> de la fecha de solicitud.',
    leftoverTooltip:
      'El <b>plan de entrega</b> para esa clave (total pedidos solicitados) tiene <b>más entregas totales de las solicitadas</b>.',
    noEntriesTooltip:
      'Pedidos que no tienen <b>ningún transporte llegando</b> a fábrica para <b>todo el plan de entrega</b> (total pedidos solicitados).',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Coberturas en riesgo + Estados de pedidos',
    comments: 'Comentarios',
    editComment: 'Clica en el icono para editar el comentario',
    removeComment: 'Clica en el icono para borrar el comentario',
    updateComment: 'Clica en el icono para actualizar el comentario',
    commentsTooltip:
      'Los comentarios son solamente visibles para los usuarios de aprovisionamiento, los proveedores no ven esta información',
    commentUpdatedSuccess: 'El comentario se ha actualizado con éxito',
    commentRemovedSuccess: 'El comentario se ha eliminado correctamente',
    commentUpdatedFailed:
      'El comentario no se ha podido actualizar, pruebe pasados unos minutos.'
  },
  en: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage: 'There is no available stock yet for this provider.',
    stockUpdate: 'Stock upd.:',
    productionPlanUpdate: 'Act. production plan:',
    code: 'Parts',
    providerCode: 'Provider part',
    name: 'Description',
    lines: 'Lines',
    models: 'Models',
    stock: 'Stock in warehouse',
    factoryCoverage: 'Stock',
    tierStock: 'T1 Stock',
    stockReportedBySupplier: 'Stock reported by supplier',
    endProductionInDays: 'EOP',
    inbounder: 'Inbounder',
    providerName: 'Suppliers',
    providerId: 'Supplier code',
    country: 'Country',
    workshops: 'Workshops',
    countriesMultiselectAvailable: 'Countries available',
    countriesMultiselectSelected: 'Countries selected',
    countriesMultiselectEmpty: "There aren't countries available",
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    suppliersMultiselectAvailable: 'Suppliers available',
    suppliersMultiselectSelected: 'Suppliers selected',
    suppliersMultiselectEmpty: "There aren't suppliers available",
    inbounderMultiselectAvailable: 'Inbounders available',
    inbounderMultiselectSelected: 'Inbounders selected',
    inbounderMultiselectEmpty: "There aren't inbounders available",
    modelMultiselectAvailable: 'Models available',
    modelMultiselectSelected: 'Models selected',
    modelMultiselectEmpty: "There aren't models available",
    workshopMultiselectAvailable: 'Workshops availables',
    workshopMultiselectSelected: 'Workshops selected',
    workshopMultiselectEmpty: "There aren't workshops available",
    warehousesMultiselectAvailable: 'Warehouses available',
    warehousesMultiselectSelected: 'Warehouses selected',
    warehousesMultiselectEmpty: "There aren't Warehouses",
    geolocated: 'Geolocated',
    mrp: 'Order',
    mrps: 'Orders',
    more: '...more',
    pastLoads: 'Transit support info',
    pastLoadsTooltip:
      'Unexpected ASN data: <ul><li>Grouping of transports with expected date of ASN in the past.</li><li>Transport grouping without confirmation of arrival.</li></ul>',
    coverageAtRisk: 'Coverage at risk',
    coverageAtRiskOK: 'Cov OK',
    coverageAtRiskSecurity: 'Security stock risk',
    coverageAtRiskCritical: 'Cov critical',
    searcherPartOrNamePlaceholder: 'Search by part or description',
    plateCopiedText: 'Plate number copied',
    plateEmptyText: 'There is no plate number to copy',
    calculationTooltip:
      'Calculation considers up to 15 days, anything further is reflected as MAX',
    coverageDateTimeTooltip:
      "Date and time when the SEAT stock doesn't cover consumption. Currently doesn't count inline stock",
    warehouse: 'Warehouse',
    viewTransportsAndOrders: 'View existing transports and orders',
    type: 'Type',
    amount: 'Quantity',
    providerDeliveryNote: 'Delivery note',
    plateNumber: 'Tractor plate number',
    transporter: 'Transporter',
    leadtime: 'Leadtime',
    transit: 'Transit',
    transits: 'Transits',
    transportsNoJit: 'Entries plan',
    nonGeolocatedTransport: 'Non-geolocated transit',
    deliveryStatus: 'Order fulfillment status',
    deliveryQuantity: 'Fulfillment of Planned Orders',
    deliveryQuantityTooltip:
      'Indicators to identify the <b>status of the delivered quantity</b> of orders arriving at the factory.',
    deliveryPunctuality: 'Puntuality of planned orders',
    deliveryPunctualityTooltip:
      '<b>Indicators</b> to identify the <b>punctuality status of orders</b> arriving at the factory.',
    complete: 'Complete',
    leftover: 'Leftover',
    riskOfOvertake: 'Risk of overtake',
    riskOfDelay: 'With risk of delay',
    onTime: 'On time',
    unknow: '-',
    missing: 'Missing',
    factoryCoverageTooltip:
      'Until what day and time is production <b>covered</b> with <b>warehouse stock</b>: <ul><li>In the short term: MAX (dd/mm/yy, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    coverageWithLabel: 'Coverage with',
    coverageOrdersTooltip:
      'Until what day and time is production <b>covered</b> with <b>SEAT stock + Programs (delivery plans)</b>: <ul><li>In the short term: MAX (dd/mm/yy, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    orders: 'Orders',
    viewOrders: 'View existing orders',
    urgentTransit: 'Urgent transit',
    others: 'Data validation',
    othersTooltip:
      'Grouping of orders with possible <b>data inconsistencies</b>.',
    noEntries: 'No entries',
    noOrders: 'No orders',
    transitsCoverage: 'Transits',
    transitsCoverageTooltip:
      'Until what day and time is production <b>covered</b> with <b>SEAT stock + Transits in progress</b>:<ul><li>In the short term: MAX (dd/mm/yy, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    deliveryOrders: 'Delivery orders',
    windowBooked: 'Windows booked',
    estimatedTimeTitle: 'Estimated date: ',
    estimatedTime: 'Model estimation',
    supplierDate: 'Supplier date',
    provDeliveryTimeTooltip:
      "Estimated arrival date according to supplier's ASN",
    leadtimeExpectedDate: 'Lead time date',
    providerExpectedDate: 'Supplier',
    provDeliveryTime: 'Supplier date',
    statusTooltip:
      '<b>Transit status:</b><ul><li><b>Delayed</b>: It has a scheduled date that has passed</li><li><b>In consolidation</b>: It has the status TP</li><li><b>In shipment</b>: It has the status TR</li><li><b>Unloading plant</b>: It has the status EP</li></ul><b>Order status:</b><ul><li><b>Puntuality</b> (On Time, Risk of delay, Risk of overtake)</li><li><b>Amount</b> (Complete, Incomplete, Leftover)</li></ul>',
    departureDate: 'Departure date',
    departureDateTooltip: "Departure date according to supplier's ASN",
    kccTransit: 'Transit consolidation',
    leadtimeTooltip: 'Agreed delivery time with each supplier',
    officialDeliveryTime: 'Official date syst.',
    officialDeliveryTimeTooltip:
      '<b>In transits:</b><ul><li>Shows the most precise arrival date in the system.</li></ul><i>(The official date is used to calculate coverage with transits and locates the transportation arrival day on the calendar. The date is dynamic and changes type over time.)</i><br/><br/><b>In orders:</b> <ul><li>It is the planned date of the order.</li></ul>',
    transitOfficialDeliveryTimeTooltip:
      'Displays the most accurate arrival date in the system. It is the same date shown in the days column.',
    mrpOfficialDeliveryTimeTooltip:
      'Agreed date on which the order must arrive at SEAT.',
    dateType: 'Official date type',
    dateTypeTooltip:
      'Types of dates in transit (in order of precision on availability):<ul><li><b>Time window:</b> Date reserved from Martorell for entry to the transport plant (LKW)</li><li><b>Estimated model:</b> Date calculated using a predictive model based on arrival history</li><li><b>Supplier date:</b> Date notified by the supplier to the factory through ASN</li><li><b>Lead time date:</b> Transport departure date + contracted lead time</li></ul>',
    geolocation: 'Geolocation',
    tierStockUpdatedAt: 'Date upd. T1 stock',
    tierStockUpdatedAtTooltip: 'Tier 1 stock update date',
    coverages: 'Coverages',
    withStock: 'With stock',
    withOrders: 'With orders',
    coverageLessZero: 'Coverage < 0',
    withTransits: 'With transit',
    withoutNeeds: 'Without needs',
    shortTerm: 'Short term horizon',
    longTerm: 'Long term horizon',
    shortTermExtended: 'Short term (next 15 days)',
    longTermExtended: 'Long term (next 11/12 weeks)',
    multiple: 'Multiple',
    entryTypes: 'Entry types',
    geolocationEta: 'Geolocated ETA',
    dateTypeModalTransport: 'Date type',
    firm: 'Firm',
    delay: 'Delay',
    forecast: 'Forecast',
    serviceLevel: 'Service level',
    serviceLevelTooltip:
      'Delivery compliance status indicators.<br/><br/><b>More information</b> about the fulfillment statuses in the <b>"Order fulfillment statuses"</b> component.',
    incomplete: 'Incomplete..',
    onTimeTooltip:
      '<b>Completely deliver the requested quantity</b> on the scheduled day of receipt or there is <b>still time to start transportation</b> and arrive on the agreed day.',
    riskOfDelayTooltip:
      'The order has a requested date that is not acceptable, since the <b>transport arrives late or there is no transport started</b> and the deadline to start the shipment has already passed.',
    riskOfOvertakeTooltip:
      'The order is counted if <b>it delivers completely the requested quantity before the expected day</b> of receipt.',
    completeTooltip:
      'The requested order has the <b>total quantity delivered or on the way</b> to the agreed date.',
    incompleteTooltip:
      'The requested order has <b>quantities not covered or arriving days after</b> the request date.',
    leftoverTooltip:
      'The <b>delivery plan</b> for that key (total orders requested) has <b>more total deliveries than requested</b>.',
    noEntriesTooltip:
      'Orders that <b>do not have any transportation arriving</b> at the factory for the <b>entire delivery plan</b> (total orders requested).',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Coverage at risk + Order statuses',
    comments: 'Comments',
    editComment: 'Click on the icon to edit the comment',
    removeComment: 'Click on the icon to remove the comment',
    updateComment: 'Click on the icon to update the comment',
    commentsTooltip:
      'Comments are only visible to provisioning users, suppliers do not see this information',
    commentUpdatedSuccess: 'Comment has been updated successfully',
    commentRemovedSuccess: 'Comment has been successfully deleted',
    commentUpdatedFailed:
      'The comment could not be updated, try after a few minutes.'
  },
  ca: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage:
      'Encara no hi ha existències disponibles per a aquest proveïdor.',
    stockUpdate: 'Act. estoc:',
    productionPlanUpdate: 'Act. pla producció:',
    code: 'Clau',
    providerCode: 'Clau proveïdor',
    name: 'Descripció',
    lines: 'Línies',
    models: 'Models',
    stock: 'Estoc al magatzem',
    factoryCoverage: 'Stock',
    tierStock: 'Stock T1',
    stockReportedBySupplier: 'Estoc reportat pel proveïdor',
    endProductionInDays: 'Cessament',
    inbounder: 'Inbounder',
    providerName: 'Proveïdors',
    providerId: 'Codi Proveïdor',
    country: 'País',
    workshops: 'Tallers',
    countriesMultiselectAvailable: 'Països disponibles',
    countriesMultiselectSelected: 'Països seleccionats',
    countriesMultiselectEmpty: 'No hi ha països disponibles',
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    suppliersMultiselectAvailable: 'Proveïdors disponibles',
    suppliersMultiselectSelected: 'Proveïdors seleccionats',
    suppliersMultiselectEmpty: 'No hi ha proveïdors disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionats',
    inbounderMultiselectEmpty: 'No hi ha inbounders disponibles',
    modelMultiselectAvailable: 'Models disponibles',
    modelMultiselectSelected: 'Models seleccionats',
    modelMultiselectEmpty: 'No hi ha models disponibles',
    workshopMultiselectAvailable: 'Tallers disponibles',
    workshopMultiselectSelected: 'Tallers seleccionats',
    workshopMultiselectEmpty: 'No hi ha tallers disponibles',
    warehousesMultiselectAvailable: 'Magatzems disponibles',
    warehousesMultiselectSelected: 'Magatzems seleccionats',
    warehousesMultiselectEmpty: 'No hi ha Magatzems disponibles',
    geolocated: 'Geolocalitzat',
    mrp: 'Comanda',
    mrps: 'Comandes',
    more: '...més',
    pastLoads: 'Info suport de trànsits',
    pastLoadsTooltip:
      "Dades d'ASN inesperades: <ul><li>Agrupació de transports amb data prevista d'ASN al passat.</li><li>Agrupació de transports sense confirmació d'arribada.</li></ul>",
    coverageAtRisk: 'Cobertures en risc',
    coverageAtRiskOK: 'Cob OK',
    coverageAtRiskSecurity: 'Risc estoc de seguretat',
    coverageAtRiskCritical: 'Cob crítica',
    searcherPartOrNamePlaceholder: 'Cerca per clau o descripció',
    plateCopiedText: 'Número de matricula copiat',
    plateEmptyText: 'No hi ha número de matrícula per copiar',
    calculationTooltip:
      'El càlcul té en compte fins a 15 dies, qualsevol altra cosa es reflecteix com a MAX.',
    coverageDateTimeTooltip:
      "Data i hora quan l'estoc de SEAT no arriba a cobrir consums. Actualment no compta l'estoc en línia.",
    warehouse: 'Magatzem',
    viewTransportsAndOrders:
      'Visualitza els transports i les comandes existents',
    type: 'Tipus',
    amount: 'Quantitat',
    providerDeliveryNote: 'Albarà',
    plateNumber: 'Matrícula tractora',
    transporter: 'Transportista',
    leadtime: 'Leadtime',
    transit: 'Trànsit',
    transits: 'Trànsits',
    transportsNoJit: "Pla d'entrades",
    nonGeolocatedTransport: 'T. No geolocalitzat',
    deliveryStatus: 'Estat compliment comandes',
    deliveryQuantity: 'Ompliment de comandes planificades',
    deliveryQuantityTooltip:
      "Indicadors per identificar l'<b>estat de la quantitat entregada</b> de les comandes arribant a fàbrica.",
    deliveryPunctuality: 'Puntualitat de comandes planificades',
    deliveryPunctualityTooltip:
      "<b>Indicadors</b> per identificar l'<b>estat de puntualitat de les comandes</b> arribant a fàbrica.",
    complete: 'Complets',
    leftover: 'Sobrants',
    riskOfOvertake: "Risc d'avenç",
    riskOfDelay: "Risc d'endarreriment",
    onTime: 'Puntual',
    unknow: '-',
    missing: 'Faltants',
    factoryCoverageTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>stock del magatzem</b>: <ul><li>A curt termini: MAX (dd/mm/yy, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    coverageWithLabel: 'Cobertura amb',
    coverageOrdersTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>SEAT stock + Programes (plans de lliurament)</b>: <ul><li> A curt termini: MAX (dd/mm/yy, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    orders: 'Comandes',
    viewOrders: 'Visualitza les comandes existents',
    urgentTransit: 'Transit urgent',
    others: 'Validació de dades',
    othersTooltip:
      'Agrupació de comandes amb possibles <b>incoherències de dades</b>.',
    noEntries: 'Sense entrades',
    noOrders: 'Sense comandes',
    transitsCoverage: 'Trànsits',
    transitsCoverageTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>SEAT stock + Trànsits en curs</b>: <ul><li> A curt termini: MAX (dd/mm/yy, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    deliveryOrders: 'Lliurament comandes',
    windowBooked: 'Finestra horària',
    estimatedTimeTitle: 'Data estimada',
    estimatedTime: 'Estimada model',
    supplierDate: 'Data proveïdor',
    provDeliveryTimeTooltip:
      "Data estimada d'arribada segons ASN del proveïdor",
    leadtimeExpectedDate: 'Data lead time',
    providerExpectedDate: 'Proveïdor',
    provDeliveryTime: 'Data proveïdor',
    statusTooltip:
      "<b>Estat trànsits:</b><ul><li><b>Endarrerit</b>: Té data prevista a passat</li><li><b>En consolidació</b>: Té l'estat TP</li><li><b>En expedició</b>: Té l'estat TR</li><li><b>Descarregant planta</b>: Té l'estat EP</li></ul><b>Estat comandes:</b><ul><li><b>Puntualitat</b> (Puntual, Risc endarreriment, Risc avenç)</li><li><b>Quantitat</b> (Complet, Incomplet, Sobrant)</li></ul>",
    departureDate: 'Data sortida',
    departureDateTooltip: 'Data sortida segons ASN del proveïdor',
    kccTransit: 'Transit consolidació',
    leadtimeTooltip: 'Temps pactat dexpedició amb cada proveïdor',
    officialDeliveryTime: 'Data oficial sist.',
    officialDeliveryTimeTooltip:
      "<b>Als trànsits:</b><ul><li>Mostra la data d'arribada més precisa del sistema.</li></ul><i>(La data oficial s'usa per al càlcul de cobertura amb trànsits i ubica el dia d'arribada del transport al calendari. La data és dinàmica i canvia de tipus durant el temps.)</i><br/><br/><b>Per a les comandes:</b> <ul><li>És la data de planificada de la comanda.</li></ul>",
    transitOfficialDeliveryTimeTooltip:
      "Mostra la data d'arribada més precisa que té el sistema. És la mateixa data que es mostra a la columna de dies.",
    mrpOfficialDeliveryTimeTooltip:
      "Data acordada en què la comanda ha d'arribar a SEAT.",
    dateType: 'Tipus data oficial',
    dateTypeTooltip:
      "Tipus de dates en trànsits (per ordre de precisió sobre disponibilitat):<ul><li><b>Finestra horària:</b> Data reservada des de Martorell per a l'entrada a la planta del transport (LKW)</li><li><b>Estimada model:</b> Data calculada mitjançant un model predictiu basant-se en històrics d'arribada</li><li><b>Data proveïdor:</b> Data notificada per part del proveïdor a fàbrica mitjançant ASN</li><li><b>Data lead time:</b> Data de sortida del transport + lead time contractat</li></ul>",
    geolocation: 'Geolocalització',
    tierStockUpdatedAt: 'Data act. estoc T1',
    tierStockUpdatedAtTooltip: "Data d'actualització de l'estoc del Tier 1",
    coverages: 'Cobertures',
    withStock: 'Amb stock',
    withOrders: 'Amb comandes',
    coverageLessZero: 'Cobertura <b 0',
    withTransits: 'Amb trànsit',
    withoutNeeds: 'Sense necessitats',
    shortTerm: 'Horitzó curt termini',
    longTerm: 'Horitzó llarg termini',
    shortTermExtended: 'Curt termini (pròxims 15 dies)',
    longTermExtended: 'Llarg termini (pròximes 11/12 setmanes)',
    multiple: 'Múltiples',
    entryTypes: "Tipus d'entrada",
    geolocationEta: 'ETA geolocalitzada',
    dateTypeModalTransport: 'Tipus data',
    firm: 'Ferm',
    delay: 'Endarreriment',
    forecast: 'Previsió',
    serviceLevel: 'Nivell de servei',
    serviceLevelTooltip: `Indicadors d'estat de compliment de lliurament.<br/><br/><b>Més informació</b> sobre els estats de compliment al component <b>"Estats compliment comandes"</b>.`,
    incomplete: 'Incomplets',
    onTimeTooltip:
      '<b>Lliura completament la quantitat</b> sol·licitada <b>el dia previst</b> de recepció o encara <b>hi ha temps per iniciar el transport</b> i arribar el dia pactat.',
    riskOfDelayTooltip:
      "La comanda té una data sol·licitada no assumible, ja que el <b>transport arriba tard o no hi ha transport iniciat</b> i ja ha passat temps límit per iniciar l'expedició.",
    riskOfOvertakeTooltip:
      'La comanda es compta si <b>entrega completament la quantitat sol·licitada abans del dia</b> previst de recepció.',
    completeTooltip:
      'La comanda sol·licitada té el <b>total de la quantitat lliurada o en camí</b> a la data acordada.',
    incompleteTooltip:
      'La comanda sol·licitada té <b>quantitats no cobertes o arriba dies després</b> de la data de sol·licitud.',
    leftoverTooltip:
      'El <b>pla de lliurament</b> per a aquesta clau (total comandes sol·licitades) té <b>més lliuraments totals de les sol·licitades</b>.',
    noEntriesTooltip:
      'Comandes que no tenen <b>cap transport arribant</b> a fàbrica per a <b>tot el pla de lliurament</b> (total comandes sol·licitades).',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Cobertures en risc + Estats de comandes',
    comments: 'Comentaris',
    editComment: 'Clica a la icona per editar el comentari',
    removeComment: 'Clica a la icona per esborrar el comentari',
    updateComment: 'Clica a la icona per actualitzar el comentari',
    commentsTooltip:
      'Els comentaris són només visibles per als usuaris de proveïment, els proveïdors no veuen aquesta informació',
    commentUpdatedSuccess: 'El comentari ha estat actualitzat amb èxit',
    commentRemovedSuccess: "El comentari s'ha eliminat correctament",
    commentUpdatedFailed:
      "El comentari no s'ha pogut actualitzar, proveu passats uns minuts."
  }
};
