import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { API_GET_LOADS_STATUS } from './urls';
import ILoadsRepository from 'modules/outbound/shared/domain/ILoadsRepository';
import { loadsStatusDTOMapper } from 'modules/outbound/shared/application/loadsStatusDTOMapper';
import { todayLoadsDTOMapper } from '../../application/todayLoadsDTOMapper';

class LoadsRepository extends BaseRepository implements ILoadsRepository {
  private _api: IApi;
  private _apiGetLoadsStatus: string;

  constructor({ api, apiGetLoadsStatus }) {
    super();
    this._api = api;
    this._apiGetLoadsStatus = apiGetLoadsStatus;
  }
  getLoadsStatus() {
    return this._api.get(this._apiGetLoadsStatus).then((response) => ({
      loadsStatus: loadsStatusDTOMapper(response),
      todayLoads: todayLoadsDTOMapper(response)
    }));
  }
}

export const loadsRepository = new LoadsRepository({
  api,
  apiGetLoadsStatus: API_GET_LOADS_STATUS
});

export { LoadsRepository };
