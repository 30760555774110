import { SearchModality, StatsItem } from './types';

export interface StatusItemDTO {
  total: number;
  from_datetime: string;
  to_datetime: string;
  search_modality: SearchModality;
  stats: StatsItem[];
}

class SuppliesStatusDTO {
  previous_days: StatusItemDTO[];
  previous_hours: StatusItemDTO[];
  hours: StatusItemDTO[];

  constructor({ previous_days, previous_hours, hours }) {
    this.previous_days = previous_days;
    this.previous_hours = previous_hours;
    this.hours = hours;
  }
}

export default SuppliesStatusDTO;
