import UploadMasterResponse from '../../shared/domain/UploadMasterResponse';
import { IMasterRepository } from '../domain/IMastersRepository';
import masterRepository from '../infrastructure/repositories/MastersRepository';

class UploadMasterUseCase {
  private _masterRepository: IMasterRepository;

  constructor({ masterRepository }) {
    this._masterRepository = masterRepository;
  }

  execute({ file, manageMasterUrl }) {
    return this._masterRepository
      .uploadMaster(file, manageMasterUrl)
      .then((res) => UploadMasterResponse.generateFromDTO(res));
  }
}

export default new UploadMasterUseCase({
  masterRepository: masterRepository
});

export { UploadMasterUseCase };
