import IDataResourcesRepository from '../domain/IDataResourcesRepository';
import dataResourcesRepository from '../infrastructure/repositories/DataResourcesRepository';

class GetLastReceivedHifaFileDateUseCase {
  private _repository: IDataResourcesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute() {
    return this._repository.getLastReceivedHifaFileDate();
  }
}

export default new GetLastReceivedHifaFileDateUseCase({
  repository: dataResourcesRepository
});

export { GetLastReceivedHifaFileDateUseCase };
