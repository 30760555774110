import { createSlice } from '@reduxjs/toolkit';
import {
  getJitCloseContainer,
  getFamiliesCoverage,
  getJitGeneralData
} from './async';
import { FamiliesCoverageState } from './types';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';

export const initialState: FamiliesCoverageState = {
  data: {
    families: [],
    closeContainers: [],
    generalData: {
      workshop: '',
      marquee: '',
      branch: '',
      lisons: '',
      branchPosition: 0,
      lineSequence: '',
      lineSequenceUpdatingDateAt: ''
    }
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: NameValues.FORECAST_STOPPAGE_AT,
    isAscending: true
  },
  familySelected: null,
  requestStatus: '',
  closeContainerStatus: '',
  generalDataStatus: ''
};

const FamiliesCoverageSlice = createSlice({
  name: 'familiesCoverageData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateFamilySelected: (state, action) => {
      state.familySelected = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFamiliesCoverage.fulfilled, (state, action) => {
      state.data.families = action.payload.families;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFamiliesCoverage.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFamiliesCoverage.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitCloseContainer.fulfilled, (state, action) => {
      state.data.closeContainers = action.payload;
      state.closeContainerStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitCloseContainer.pending, (state, action) => {
      state.closeContainerStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitCloseContainer.rejected, (state, action) => {
      state.closeContainerStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitGeneralData.fulfilled, (state, action) => {
      state.data.generalData = action.payload;
      state.generalDataStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitGeneralData.pending, (state, action) => {
      state.generalDataStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitGeneralData.rejected, (state, action) => {
      state.generalDataStatus = action.meta.requestStatus;
    });
  }
});
//selectors
export const getFamilies = (state) => state.familiesCoverageState.data.families;

export const getCloseContainers = (state) =>
  state.familiesCoverageState.data.closeContainers;
export const getGeneralData = (state) =>
  state.familiesCoverageState.data.generalData;
export const getisLoadingStatus = (state) =>
  state.familiesCoverageState.requestStatus === 'pending';
export const getLoadingCloseContainerStatus = (state) =>
  state.familiesCoverageState.closeContainerStatus === 'pending';
export const getLoadingGeneralDataStatus = (state) =>
  state.familiesCoverageState.generalDataStatus === 'pending';
export const getHasError = (state) =>
  state.familiesCoverageState.requestStatus === 'rejected';
export const getHasErrorCloseContainer = (state) =>
  state.familiesCoverageState.closeContainerStatus === 'rejected';
export const getHasErrorGeneralData = (state) =>
  state.familiesCoverageState.generalDataStatus === 'rejected';
export const getPagination = (state) => state.familiesCoverageState.pagination;
export const getSelectedColumn = (state) =>
  state.familiesCoverageState.selectedColumn;
export const getFamilySelected = (state) =>
  state.familiesCoverageState.familySelected;

export const { updateCurrentPage, updateFamilySelected, updateSelectedColumn } =
  FamiliesCoverageSlice.actions;

export default FamiliesCoverageSlice.reducer;
