import CallOffFinishedGood from 'modules/outbound/callOff/domain/CallOffFinishedGood';
import { ICallOffTrackingByCheckpoint } from 'modules/outbound/callOff/domain/CallOffTrackingByCheckpoint';
import Checkpoint from 'modules/outbound/callOff/domain/Checkpoint';
import { ITimelineItem } from 'modules/outbound/fulfillment/domain/FinishedGoodCheckpointsTimeline';

interface ITrackingData {
  totalCars: number;
  onTimeCarsPercentage: number;
  onTimeCars: number;
}
interface ITrackingByWarehouses {
  [key: string]: {
    name: string;
    defaultRangeTrackingByWarehouses?: ITrackingData;
    lastMonthTrackingByWarehouses?: ITrackingData;
    lastSevenDaysTrackingByWarehouses?: ITrackingData;
    actualMonthTrackingByWarehouses?: ITrackingData;
    lastYearTrackingByWarehouses?: ITrackingData;
    actualYearTrackingByWarehouses?: ITrackingData;
  };
}
export interface CallOffTrackingState {
  trackingByWarehouses: ITrackingByWarehouses | {};
  defaultRangeTrackingByWarehousesRequestStatus: string;
  lastMonthTrackingByWarehousesRequestStatus: string;
  actualMonthTrackingByWarehousesRequestStatus: string;
  lastYearTrackingByWarehousesRequestStatus: string;
  actualYearTrackingByWarehousesRequestStatus: string;
  lastSevenDaysTrackingByWarehousesRequestStatus: string;
  selectedWarehouse: string;
  selectedBar: string;
  checkpoints: Checkpoint[];
  selectedCheckpoint: string;
  finishedGoodsByWarehouse: CallOffFinishedGood[];
  totalFinishedGoods: number;
  filters: { page: number; resultsPerPage: number };
  finishedGoodsRequestStatus: string;
  file: any;
  downloadRequestStatus: string;
  checkpointsRequestStatus: string;
  selectedVin: string;
  vinCheckpoints: {
    vin: string;
    actualTimeline: ITimelineItem[];
    expectedTimeline: ITimelineItem[];
    actualTimelineTotalDays: number;
    expectedTimelineTotalDays: number;
  };
  detailedCheckpointsRequest: string;
  callOffTrackingDataByCheckpoint: ICallOffTrackingByCheckpoint;
  callOffTrackingByCheckpointRequest: string;
}

export enum TimelineSectionColors {
  factoryWarehouseInput = 'yellow',
  factoryWarehouseOutput = 'orange',
  originPortInput = 'red',
  originPortOutput = 'green',
  transitPortInput = 'purple',
  transitPortOutput = 'lightBlue',
  destinationPortInput = 'blue',
  destinationPortOutput = 'extraBlack',
  redistributionWarehouseInput = 'black50',
  redistributionWarehouseOutput = 'fill',
  dealerInput = 'white',
  default = 'darkGreen'
}
