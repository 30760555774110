import IJitRepository from '../domain/IJitRepository';
import JitFamiliesFilters from 'modules/inbound/jit/domain/FamiliesCoverageFilters';
import jitRepository from '../infrastructure/repositories/JitRepository';

class GetJitFamiliesFiltersService {
  private _jitRepository: IJitRepository;

  constructor({ repository }) {
    this._jitRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._jitRepository
      .getJitFamiliesFilters({ queryParams })
      .then((res) => {
        return JitFamiliesFilters.generateFromDTO(res);
      });
  }
}

export default new GetJitFamiliesFiltersService({
  repository: jitRepository
});

export { GetJitFamiliesFiltersService };
