import { stockRepository } from 'modules/outbound/shared/infrastructure/repositories/StockRepository';
import IStockRepository from 'modules/outbound/shared/domain/IStockRepository';
import OccupationStock, {
  GenerateFromDTOParams
} from '../domain/OccupationStock';

class GetStockOccupationUseCase {
  private _repository: IStockRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute() {
    return this._repository
      .getStockOccupation()
      .then((res) =>
        OccupationStock.generateFromDTO(res as GenerateFromDTOParams)
      );
  }
}

export default new GetStockOccupationUseCase({
  repository: stockRepository
});

export { GetStockOccupationUseCase };
