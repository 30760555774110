import { ILineGroupsRepository } from '../domain/ILineGroupsRepository';
import LineGroupsBody from '../domain/LineGroupsBody';
import lineGroupsRepository from '../infrastructure/repositories/LineGroupsRepository';

class GetLineGroupsBodyUseCase {
  private _lineGroupsRepository: ILineGroupsRepository;

  constructor({ lineGroupsRepository }) {
    this._lineGroupsRepository = lineGroupsRepository;
  }

  execute() {
    return this._lineGroupsRepository
      .getTableBody()
      .then((res) => LineGroupsBody.generateFromDTO(res));
  }
}

export default new GetLineGroupsBodyUseCase({
  lineGroupsRepository: lineGroupsRepository
});

export { GetLineGroupsBodyUseCase };
