export interface ProviderContactListDTO {
  contact_list: ContactListResponseDTO[];
}
interface ContactItemDTO {
  id: string;
  name: string;
  role: string;
  telephone: string;
  email: string;
}

export interface ContactListResponseDTO {
  id: number;
  name: string;
  contacts: ContactItemDTO[];
}

export const providerContactListDTOMapper = (
  data: any
): ProviderContactListDTO => ({
  contact_list:
    data.map((provider) => ({
      id: provider.id,
      name: provider.name,
      contacts: provider.contacts.map((contact) => ({
        id: contact.id,
        name: contact.name !== '' ? contact.name : '-',
        role: contact.role !== '' ? contact.role : '-',
        telephone: contact.telephone !== '' ? contact.telephone : '-',
        email: contact.email !== '' ? contact.email : '-'
      }))
    })) || []
});

class ContactManagmentResponse {
  statusCode: number;
  message?: string;

  constructor({ statusCode, message }) {
    this.statusCode = statusCode;
    this.message = message;
  }
}

export default ContactManagmentResponse;
