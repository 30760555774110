const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const DIRECT_DELIVER = '/direct-deliveries';
const DIRECT_DELIVER_STOCK = `${DIRECT_DELIVER}/stock`;
const TRANSITS = `${DIRECT_DELIVER}/transits`;
const DOWNLOAD_UPCOMING_TRANSITS = `${TRANSITS}/download`;
const DD_COVERAGE_AT_RISK_ENDOINT = `${DIRECT_DELIVER}/coverage/models`;
const DIRECT_DELIVERY_FILTERS = `${DIRECT_DELIVER}/filters`;
const API_GET_DIRECT_DELIVERY = `${INBOUND_BASE_URL}${DIRECT_DELIVER_STOCK}`;
const API_GET_UPCOMING_TRANSITS = `${INBOUND_BASE_URL}${TRANSITS}`;
const API_DOWNLOAD_UPCOMING_TRANSITS = `${INBOUND_BASE_URL}${DOWNLOAD_UPCOMING_TRANSITS}`;
const API_GET_DIRECT_DELIVERY_FILTERS = `${INBOUND_BASE_URL}${DIRECT_DELIVERY_FILTERS}`;
const API_GET_DD_COVERAGE_AT_RISK = `${INBOUND_BASE_URL}${DD_COVERAGE_AT_RISK_ENDOINT}`;
const API_DOWNLOAD_DIRECT_DELIVER = `${INBOUND_BASE_URL}${DIRECT_DELIVER}/download`;
const API_DOWNLOAD_HISTORY = `${INBOUND_BASE_URL}${TRANSITS}/history/download`;

export {
  API_GET_DIRECT_DELIVERY,
  API_GET_UPCOMING_TRANSITS,
  API_DOWNLOAD_UPCOMING_TRANSITS,
  API_GET_DIRECT_DELIVERY_FILTERS,
  API_GET_DD_COVERAGE_AT_RISK,
  API_DOWNLOAD_DIRECT_DELIVER,
  API_DOWNLOAD_HISTORY
};
