import { Translation } from 'infrastructure/i18n/typing';
import { SuppliesTranslations } from './typing';

export const SuppliesText: Translation<SuppliesTranslations> = {
  es: {
    pageTitle: 'Suministros',
    menuTitle: 'Suministros',
    requestsUpdatedAt: 'Act. de peticiones: ',
    otRequestsUpdatedAt: 'Act. de peticiones ot: ',
    code: 'Clave',
    description: 'Descripción',
    supplyType: 'Tipo suministro',
    orderRequest: 'Nº petición',
    transportRequest: 'Nº OT',
    warehouseUnitNumber: 'HU',
    warehouseSourceType: 'Area almacén origen',
    createdAt: 'Hora creación',
    lineGroup: 'Grupo línea',
    confirmationDate: 'H.confirmación',
    preparationDate: 'H.preparación',
    lineLocation: 'Ubicación linea',
    manufacturingSection: 'Sección fabricación',
    warehouseSource: 'Almacén de origen',
    warehouseRanking: 'Ranking almacenes',
    closedRequests: 'Peticiones cerradas (hora de entrega)',
    closedRequestsTooltip:
      '<b>Puntual:</b> El suministro ha llegado antes de la hora de necesidad.<br/> <b>Atrasado:</b> El suministro ha llegado después de la hora de necesidad.<br/><br/> <i>Se eliminan 8 horas después de confirmadas.</i>',
    closed: 'Cerradas',
    previousHours: 'H. anteriores',
    previousDays: 'D. anteriores',
    now: 'Ahora',
    delayTimeInMinutes: 'Tiempo desde creación',
    lineGroupMultiselectAvailable: 'Grupos lineas disponibles',
    lineGroupMultiselectSelected: 'Grupos lineas seleccionadas',
    lineGroupMultiselectEmpty: 'No hay grupos lineas disponibles',
    supplyTypeMultiselectAvailable: 'Tipos suministro disponibles',
    supplyTypeMultiselectSelected: 'Tipos suministro seleccionados',
    supplyTypeMultiselectEmpty: 'No hay tipos suministro disponibles',
    manufacturingSectionMultiselectAvailable:
      'Secciones fabricación diponibles',
    manufacturingSectionMultiselectSelected: 'Sección fabricación seleccionada',
    manufacturingSectionMultiselectEmpty:
      'No hay sección fabricación disponibles',
    warehouseSourceTypeMultiselectAvailable: 'Areas almacén disponibles',
    warehouseSourceTypeMultiselectSelected: 'Areas almacén seleccionadas',
    warehouseSourceTypeMultiselectEmpty: 'No hay areas almacén disponibles',
    warehouseSourceMultiselectAvailable: 'Almacén de origen disponibles',
    warehouseSourceMultiselectSelected: 'Almacén de origen seleccionados',
    warehouseSourceMultiselectEmpty: 'No hay almacenes de origen disponibles',
    openRequests: 'Peticiones abiertas (hora de creación)',
    openRequestsTooltip:
      '<b>Puntual:</b> El suministro llega antes de la hora de necesidad. <br/> <b>A punto de demorar:</b> El suministro llega entre 30 minutos antes de la hora de necesidad. <br/> <b>Atrasado:</b> El suministro llega después de la hora de necesidad. <br/><br/> <i>Las peticiones sin confirmar se eliminan en 3 días, excepto si son PC o FG.</i>',
    reception: 'Recepción',
    st17: 'Directos ST17',
    noTransit: 'Sin tránsito',
    materialIndicator: 'Indicador material',
    materialIndicatorTooltip:
      '<b>FG:</b> Falta grave <br/><b>NR:</b> No relevante <br/><b>PC:</b> Pieza crítica <br/><b>FA:</b> Falta almacén',
    destinationRanking: 'Ranking de destinos',
    warehouseTarget: 'Almacén de destino',
    subzones: 'Subzonas',
    withoutIndicator: 'Sin Indicador',
    materialIndicatorMultiselectAvailable:
      'Indicadores de material disponibles',
    materialIndicatorMultiselectSelected:
      'Indicadores de material seleccionados',
    materialIndicatorMultiselectEmpty:
      'No hay indicadores de material disponibles',
    phases: 'Status',
    phaseMultiselectAvailable: 'Status disponibles',
    phaseMultiselectSelected: 'Status seleccionados',
    phaseMultiselectEmpty: 'Status vacios',
    opened: 'Abiertas',
    prepared: 'Preparadas',
    transfer: 'Directo de trasvase a línea',
    inTransit: 'En tránsito',
    directToLineInTransit: 'Directo a línea en tránsito',
    alerts: 'Alertas claves',
    delayedTimeTooltip: 'Un archivo no se ha recibido correctamente',
    criticalPiece: 'Pieza crítica',
    seriousFault: 'Falta grave',
    directToLine: 'Directo a línea',
    suppliesWithCriticalParts: 'Suministros con piezas críticas',
    state: 'Estado',
    searcherPlaceholder: 'Búsqueda por clave, descripción, albarán, nº OT o HU',
    lines: 'Lineas',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    warehousesTargetMultiselectAvailable: 'Almacenes de destino disponibles',
    warehousesTargetMultiselectSelected: 'Almacenes de destino seleccionados',
    warehousesTargetMultiselectEmpty: 'No hay almacenes de destino disponibles',
    providerDeliveryNote: 'Albarán'
  },
  en: {
    pageTitle: 'Supplies',
    menuTitle: 'Supplies',
    requestsUpdatedAt: 'Requests upd.: ',
    otRequestsUpdatedAt: 'Ot Requests upd.: ',
    code: 'Part',
    description: 'Description',
    supplyType: 'Supply type',
    orderRequest: 'Request order',
    transportRequest: 'Transport request',
    warehouseUnitNumber: 'WU',
    warehouseSourceType: 'Origin warehouse area',
    createdAt: 'Created at',
    lineGroup: 'Line group',
    confirmationDate: 'H.confirmation',
    preparationDate: 'H.preparation',
    lineLocation: 'Line location',
    manufacturingSection: 'Manufacturing section',
    warehouseSource: 'Warehouse origin',
    warehouseRanking: 'Warehouse ranking',
    closedRequests: 'Requests closed (delivery time)',
    closedRequestsTooltip:
      '<b>On time:</b> The supply has arrived before the time of need.<br/> <b>Delayed:</b> The supply has arrived after the time of need.<br/><br/> <i>They are deleted 8 hours after confirmation.</i>',
    closed: 'Closed',
    previousHours: 'Previous Hrs.',
    previousDays: 'Previous days',
    now: 'Now',
    delayTimeInMinutes: 'Time since creation',
    lineGroupMultiselectAvailable: 'Line groups available',
    lineGroupMultiselectSelected: 'Line groups selected',
    lineGroupMultiselectEmpty: "There aren't  available",
    supplyTypeMultiselectAvailable: 'Supply types available',
    supplyTypeMultiselectSelected: 'Supply types selected',
    supplyTypeMultiselectEmpty: "There aren't supply types available",
    manufacturingSectionMultiselectAvailable:
      'Manufacturing sections available',
    manufacturingSectionMultiselectSelected: 'Manufacturing sections selected',
    manufacturingSectionMultiselectEmpty:
      "There aren't manufacturing sections available",
    warehouseSourceTypeMultiselectAvailable: 'Origin warehouse areas available',
    warehouseSourceTypeMultiselectSelected: 'Origin warehouse areas selected',
    warehouseSourceTypeMultiselectEmpty:
      "There aren't origin warehouse areas available",
    warehouseSourceMultiselectAvailable: 'Warehouse origins available',
    warehouseSourceMultiselectSelected: 'Warehouse origins selected',
    warehouseSourceMultiselectEmpty: "There aren't warehouse origins available",
    openRequests: 'Open requests (creation time)',
    openRequestsTooltip:
      '<b>On time:</b> The supply arrives before the time of need. <br/> <b>Near delay:</b> The supply arrives 30 minutes before the time of need. <br/> <b>Delayed:</b> The supply arrives after the time of need. <br/><br/> <i>Unconfirmed requests are deleted within 3 days, unless they are PC or FG.</i>',
    reception: 'Reception',
    st17: 'ST17 direct',
    noTransit: 'Without transit',
    materialIndicator: 'Material indicator',
    materialIndicatorTooltip:
      '<b>FG:</b> Serious fault <br/><b>NR:</b> Not relevant <br/><b>PC:</b> Critical piece <br/><b>FA:</b> Warehouse missing',
    destinationRanking: 'Destination ranking',
    warehouseTarget: 'Warehouse destination',
    subzones: 'Subzones',
    withoutIndicator: 'Without Indicator',
    materialIndicatorMultiselectAvailable: 'Available Material Indicators',
    materialIndicatorMultiselectSelected: 'Selected material indicators',
    materialIndicatorMultiselectEmpty: 'No material indicators Available',
    phases: 'Status',
    phaseMultiselectAvailable: 'Available Status',
    phaseMultiselectSelected: 'Selected Status',
    phaseMultiselectEmpty: 'No Status available',
    opened: 'Opened',
    prepared: 'Prepared',
    transfer: 'Direct transfer to line',
    inTransit: 'In transit',
    directToLineInTransit: 'Direct to line in transit',
    alerts: 'Code alerts',
    delayedTimeTooltip: 'A file has not been received correctly',
    criticalPiece: 'Critical piece',
    seriousFault: 'Serious fault',
    directToLine: 'Direct to line',
    suppliesWithCriticalParts: 'Supplies with critical parts',
    state: 'State',
    searcherPlaceholder:
      'Search by part, description, delivery note, OT number or HU',
    lines: 'Lines',
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    warehousesTargetMultiselectAvailable: 'Warehouses target available',
    warehousesTargetMultiselectSelected: 'Warehouses target selected',
    warehousesTargetMultiselectEmpty: "There aren't warehouses target",
    providerDeliveryNote: 'Prov. D. Note'
  },
  ca: {
    pageTitle: 'Suministres',
    menuTitle: 'Suministres',
    requestsUpdatedAt: 'Act. de peticions: ',
    otRequestsUpdatedAt: 'Act. de peticions ot: ',
    code: 'Clau',
    description: 'Descripció',
    supplyType: 'Tipus suministre',
    orderRequest: 'Nº petició',
    transportRequest: 'Nº OT',
    warehouseUnitNumber: 'HU',
    warehouseSourceType: 'Àrea magatzem origen',
    createdAt: 'Hora creació',
    lineGroup: 'Grup linia',
    confirmationDate: 'H.confirmació',
    preparationDate: 'H.preparació',
    lineLocation: 'Ubicació línea',
    manufacturingSection: 'Secció fabricació',
    warehouseSource: "Magatzem d'origen",
    warehouseRanking: 'Ranking magatzems',
    closedRequests: 'Peticions tancades (hora de lliurament)',
    closedRequestsTooltip:
      "<b>Puntual:</b> El subministrament ha arribat abans de l'hora de necessitat.<br/> <b>Demorada:</b> El subministrament ha arribat després de l'hora de necessitat.<br/><br/> <i>S'eliminen 8 hores després de confirmades.</i>",
    closed: 'Tancades',
    previousHours: 'H. anteriors',
    previousDays: 'Dies anteriors',
    now: 'Ara',
    delayTimeInMinutes: 'Temps des de creació',
    lineGroupMultiselectAvailable: 'Grups linea disponibles',
    lineGroupMultiselectSelected: 'Grups linea seleccionades',
    lineGroupMultiselectEmpty: 'No hi ha  grups linea disponibles',
    supplyTypeMultiselectAvailable: 'Tipus suministre disponibles',
    supplyTypeMultiselectSelected: 'Tipus suministre seleccionados',
    supplyTypeMultiselectEmpty: 'No hi ha tipus suministre disponibles',
    manufacturingSectionMultiselectAvailable: 'Areas Magatzem disponibles',
    manufacturingSectionMultiselectSelected: 'Areas Magatzem seleccionades',
    manufacturingSectionMultiselectEmpty: 'No hi ha Areas Magatzem disponibles',
    warehouseSourceTypeMultiselectAvailable: 'Magatzems de origen disponibles',
    warehouseSourceTypeMultiselectSelected: 'Magatzems de origen seleccionats',
    warehouseSourceTypeMultiselectEmpty: 'No hi ha areas Magatzem disponibles',
    warehouseSourceMultiselectAvailable: "Magatzem d'origen disponibles",
    warehouseSourceMultiselectSelected: "Magatzem d'origen seleccionados",
    warehouseSourceMultiselectEmpty: "No hi ha Magatzem d'origen disponibles",
    openRequests: 'Peticions obertes (hora de creació)',
    openRequestsTooltip:
      "<b>Puntual:</b> El subministrament arriba abans de l'hora de necessitat. <br/> <b>A punt de demorar:</b> El subministrament arriba entre 30 minuts abans de l'hora de necessitat. <br/> <b>Demorada:</b> El subministrament arriba després de l'hora de necessitat. <br/><br/> <i>Les peticions sense confirmar s'eliminen en 3 dies, excepte si són PC o FG.</i>",
    reception: 'Recepció',
    st17: 'Directes ST17',
    noTransit: 'Sense transit',
    materialIndicator: 'Indicador material',
    materialIndicatorTooltip:
      '<b>FG:</b> Falta greu <br/><b>NR:</b> No rellevant <br/><b>PC:</b> Peça crítica <br/><b>FA:</b> Falta magatzem',
    destinationRanking: 'Rànquing de destins',
    warehouseTarget: 'Magatzem de destinació',
    subzones: 'Subzones',
    withoutIndicator: 'Sense Indicador',
    materialIndicatorMultiselectAvailable: 'Indicadors de material disponibles',
    materialIndicatorMultiselectSelected: 'Indicadors de material seleccionats',
    materialIndicatorMultiselectEmpty:
      'No hi ha Indicadors de material disponibles',
    phases: 'Status',
    phaseMultiselectAvailable: 'Status disponibles',
    phaseMultiselectSelected: 'Status seleccionats',
    phaseMultiselectEmpty: 'No hi ha Status disponibles',
    opened: 'Obertes',
    prepared: 'Preparades',
    transfer: 'Directe de transvasament a línia',
    inTransit: 'En trànsit',
    directToLineInTransit: 'Directe a línia en trànsit',
    alerts: 'Alertes claus',
    delayedTimeTooltip: "Un fitxer no s'ha rebut correctament",
    criticalPiece: 'Peça crítica',
    seriousFault: 'Falta greu',
    directToLine: 'Directe a línia',
    suppliesWithCriticalParts: 'Subministraments amb peces crítiques',
    state: 'Estat',
    searcherPlaceholder: 'Cerca per clau, descripció, albarà, núm. OT o HU',
    lines: 'Línies',
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    warehousesTargetMultiselectAvailable: 'Magatzems de destinació disponibles',
    warehousesTargetMultiselectSelected: 'Magatzems de destinació seleccionats',
    warehousesTargetMultiselectEmpty:
      'No hi ha magatzems de destinació disponibles',
    providerDeliveryNote: 'Albarà'
  }
};
