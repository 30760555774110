import IJitRepository from '../domain/IJitRepository';
import { JitCloseContainer } from '../domain/JitCloseContainer';
import JitRepository from '../infrastructure/repositories/JitRepository';

class GetJitCloseContainerService {
  private _jitRepository: IJitRepository;

  constructor({ jitRepository }) {
    this._jitRepository = jitRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._jitRepository
      .getCloseContainer({ queryParams })
      .then((res) => res.map((cc) => JitCloseContainer.generateFromDTO(cc)));
  }
}

export default new GetJitCloseContainerService({
  jitRepository: JitRepository
});

export { GetJitCloseContainerService };
