import { createAsyncThunk } from '@reduxjs/toolkit';
import GetJitCloseContainerService from 'modules/inbound/jit/application/GetJitCloseContainerService';
import GetFamiliesCoverageService from 'modules/inbound/jit/application/GetFamiliesCoverageService';
import GetJitGeneralDataService from 'modules/inbound/jit/application/GetJitGeneralDataService';
import {
  NameValues,
  FiltersItem
} from 'modules/inbound/shared/domain/NameValues';
import { getPagination, getFamilySelected, getSelectedColumn } from '.';
import { getSelectedFilters } from '../FamiliesCoverageFiltersReducer';
import { Family } from 'modules/inbound/jit/domain/FamiliesCoverageData';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getFamiliesCoverage = createAsyncThunk(
  'familiesCoverageData/getFamiliesCoverage',
  async (_, { getState }) => {
    const appState = getState();
    const { size, page } = getPagination(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const defaultSortOrderQuery = `{${NameValues.FORECAST_STOPPAGE_AT}:ASC||${NameValues.FAMILY}:ASC}`;

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;
    const {
      selectedLines,
      selectedTier1ProviderIds,
      selectedLogisticOperatorsIds,
      selectedFlowType,
      selectedInbounders,
      selectedFamilies
    } = getSelectedFilters(appState);

    const getSortOrderQuery = () => {
      if (selectedColumn.key === NameValues.FORECAST_STOPPAGE_AT) {
        return `{${NameValues.FORECAST_STOPPAGE_AT}:ASC||${NameValues.FAMILY}:ASC}`;
      }
      return sort;
    };

    const queryFilters: FiltersItem[] = [];

    queryFilters.push({
      name: NameValues.FAMILY,
      values: selectedFamilies
    });
    queryFilters.push({
      name: NameValues.LINE,
      values: selectedLines
    });
    queryFilters.push({
      name: NameValues.PROVIDER_TIER_1_ID,
      values: selectedTier1ProviderIds.map(({ key, text }) => ({
        key: key.split('-')[0].trim(),
        text: text
      }))
    });
    queryFilters.push({
      name: NameValues.LOGISTIC_OPERATOR_ID,
      values: selectedLogisticOperatorsIds
    });
    queryFilters.push({
      name: NameValues.INBOUNDER,
      values: selectedInbounders
    });
    !!selectedFlowType.key &&
      queryFilters.push({
        name: NameValues.FLOW_TYPE,
        values: [selectedFlowType]
      });

    let querySearch: string[] = [];

    queryFilters.forEach((filter) => {
      const values = filter.values.map((value) => value.key);
      if (filter.values.length === 0) {
        return;
      }

      if (filter.name === NameValues.LINE) {
        querySearch.push(`${filter.name}:IN:(${values.join(';')})`);
      } else {
        querySearch.push(`${filter.name}:=:${values}`);
      }
    });

    const query = querySearch.filter((item) => item !== '').join('||');

    const queryParams = {
      page: page,
      size,
      search: query && `{${query}}`,
      sort: selectedColumn.key.length
        ? getSortOrderQuery()
        : defaultSortOrderQuery
    };

    const response = await GetFamiliesCoverageService.execute({ queryParams });
    return response;
  }
);

const getJitCloseContainer = createAsyncThunk(
  'familiesCoverageData/getJitCloseContainer',
  async (_, { getState }) => {
    const appState = getState();
    const familySelected: Family = getFamilySelected(appState);

    const queryParams = {
      family: familySelected.family,
      line: familySelected.line.slice(-1),
      provider_ids: familySelected.providerId.split('|').join(';')
    };

    const response = await GetJitCloseContainerService.execute({ queryParams });
    return response;
  }
);

const getJitGeneralData = createAsyncThunk(
  'familiesCoverageData/getJitGeneralData',
  async (_, { getState }) => {
    const appState = getState();
    const familySelected: Family = getFamilySelected(appState);

    const queryParams = {
      family: familySelected.family,
      line: familySelected.line.slice(-1)
    };

    const response = await GetJitGeneralDataService.execute({ queryParams });
    return response;
  }
);

export { getFamiliesCoverage, getJitCloseContainer, getJitGeneralData };
