import localeService from 'infrastructure/i18n/LocaleService';
import { CLIENT_TYPE_DICTIONARY } from 'modules/shared/domain/clientTypeDictionary';

export interface ICallOffFinishedGood {
  vin: string;
  clientType: string;
  dealerId: string;
  currentCheckpoint: string;
  factoryWarehouseInputDate: string;
  invoiceDate: string;
  orderDate: string;
  callOffDate: string;
  fastLane: boolean;
  deliveryDays: number;
  deliveryStatus: string;
}

export enum ICallOffFinishedGoodStatus {
  OK = 'OK',
  DANGER = 'DANGER',
  EXCLUDED = 'EXCLUDED',
  UNKNOWN = ''
}

interface GenerateFromDTOParams {
  id: string;
  clientType: string;
  dealerId: string;
  currentCheckpoint: string;
  factoryWarehouseInputDate: string;
  invoiceDate: string;
  orderDate: string;
  callOffDate: string;
  fastLane: boolean;
  deliveryDays: number;
  deliveryStatus: string;
}

class CallOffFinishedGood implements ICallOffFinishedGood {
  clientType: string;
  dealerId: string;
  currentCheckpoint: string;
  vin: string;
  factoryWarehouseInputDate: string;
  invoiceDate: string;
  orderDate: string;
  callOffDate: string;
  fastLane: boolean;
  deliveryDays: number;
  deliveryStatus: string;

  constructor({
    vin,
    clientType,
    dealerId,
    currentCheckpoint,
    factoryWarehouseInputDate,
    invoiceDate,
    orderDate,
    callOffDate,
    fastLane,
    deliveryDays,
    deliveryStatus
  }: CallOffFinishedGood) {
    this.vin = vin;
    this.clientType = clientType;
    this.dealerId = dealerId;
    this.currentCheckpoint = currentCheckpoint;
    this.factoryWarehouseInputDate = factoryWarehouseInputDate;
    this.invoiceDate = invoiceDate;
    this.orderDate = orderDate;
    this.callOffDate = callOffDate;
    this.fastLane = fastLane;
    this.deliveryDays = deliveryDays;
    this.deliveryStatus = deliveryStatus;
  }
  static generateFromDTO({
    id,
    clientType,
    dealerId,
    currentCheckpoint,
    factoryWarehouseInputDate,
    invoiceDate,
    orderDate,
    callOffDate,
    fastLane,
    deliveryDays,
    deliveryStatus
  }: GenerateFromDTOParams): CallOffFinishedGood {
    return new CallOffFinishedGood({
      vin: id,
      clientType: this._getClientTypeNames(clientType),
      dealerId: dealerId || '',
      currentCheckpoint: currentCheckpoint || '',
      factoryWarehouseInputDate: factoryWarehouseInputDate
        ? new Date(factoryWarehouseInputDate).toLocaleDateString()
        : '',
      invoiceDate: invoiceDate
        ? new Date(invoiceDate).toLocaleDateString()
        : '',
      orderDate: orderDate ? new Date(orderDate).toLocaleDateString() : '',
      callOffDate: callOffDate
        ? new Date(callOffDate).toLocaleDateString()
        : '',
      fastLane,
      deliveryDays,
      deliveryStatus: ICallOffFinishedGoodStatus[deliveryStatus]
    });
  }

  static generateEmpty() {
    return new CallOffFinishedGood({
      vin: '',
      clientType: '',
      dealerId: '',
      currentCheckpoint: '',
      factoryWarehouseInputDate: '',
      invoiceDate: '',
      orderDate: '',
      callOffDate: '',
      fastLane: false,
      deliveryDays: 0,
      deliveryStatus: ICallOffFinishedGoodStatus.UNKNOWN
    });
  }

  private static _getClientTypeNames(key: string): string {
    const lang = localeService.getLanguage();
    const group = CLIENT_TYPE_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.clientType[key];
  }
}

export default CallOffFinishedGood;
