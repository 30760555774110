import { createSlice } from '@reduxjs/toolkit';
import { downloadVesselVins, getTransports, getVessels } from './async';
import { GeolocatedTransportsState, VesselType, TransportType } from './types';
import seatFactoryMarker from 'assets/images/seatFactoryMarker.png';
import GeolocatedTransport, {
  LocationStatus
} from '../../domain/GeolocatedTransport';
import yellowTransportMarker from 'assets/images/yellowTruck.png';
import redTransportMarker from 'assets/images/redTruck.png';
import greenTransportMarker from 'assets/images/greenTruck.png';
import orangeTransportMarker from 'assets/images/orangeTruck.png';
import yellowTransportSelected from 'assets/images/yellowTruckSelected.svg';
import redTransportSelected from 'assets/images/redTruckSelected.svg';
import greenTransportSelected from 'assets/images/greenTruckSelected.svg';
import orangeTransportSelected from 'assets/images/orangeTruckSelected.svg';
import greenVesselMarker from 'assets/images/greenVessel.png';
import greenVesselMarkerSelected from 'assets/icons/greenVessel.svg';
import GeolocatedVessel from '../../domain/GeolocatedVessel';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';

export const initialState: GeolocatedTransportsState = {
  transports: [],
  transportsRequestStatus: '',
  vessels: [],
  vesselsRequestStatus: '',
  filters: { search: '', selectedTransportId: '', selectedVessel: '' },
  downloadVesselVinsRequestStatus: '',
  vesselVinsFile: null
};

const geolocatedTransportsSlice = createSlice({
  name: 'geolocatedTransports',
  initialState,
  reducers: {
    updateSearchedValue: (state, action) => {
      state.filters.search = action.payload;
    },
    updateSelectedTransport: (state, action) => {
      state.filters.selectedTransportId = action.payload;
    },
    updateSelectedVesselImo: (state, action) => {
      state.filters.selectedVessel = action.payload;
    },
    restoreFilters: (state) => {
      state.filters = initialState.filters;
    },
    restoreVesselDetails: (state) => {
      state.filters.selectedVessel = initialState.filters.selectedVessel;
    },
    resetDownloadStatus: (state) => {
      state.downloadVesselVinsRequestStatus =
        initialState.downloadVesselVinsRequestStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransports.fulfilled, (state, action) => {
      state.transports = action.payload || [];
      state.transportsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransports.pending, (state, action) => {
      state.transportsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTransports.rejected, (state, action) => {
      state.transportsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getVessels.fulfilled, (state, action) => {
      state.vessels = action.payload || [];
      state.vesselsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getVessels.pending, (state, action) => {
      state.vesselsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getVessels.rejected, (state, action) => {
      state.vesselsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadVesselVins.fulfilled, (state, action) => {
      const selectedVessel = state.filters.selectedVessel;
      state.vesselVinsFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `${selectedVessel.split('-')[0]}_${
          selectedVessel.split('-')[1]
        }_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadVesselVinsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadVesselVins.pending, (state, action) => {
      state.downloadVesselVinsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadVesselVins.rejected, (state, action) => {
      state.downloadVesselVinsRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors

export const getTransportsAsMarkers = (state) => {
  const { transportId } = state.finishedGoodsByCheckpointState.transportDetails;
  return state.geolocatedTransportsState.transports
    .map((transport) => ({
      position: {
        lat: transport.lastLocationCoordinates.latitude,
        lng: transport.lastLocationCoordinates.longitude
      },
      icon: {
        url: getTransportMarkerUrl(
          transport.status,
          transportId === transport.transportId
        )
      },
      title: transport.transportId,
      type: TransportType
    }))
    .concat([
      {
        position: { lat: 41.495962, lng: 1.903637 },
        icon: { url: seatFactoryMarker, title: 'Seat factory' },
        clickable: false
      }
    ]);
};

export const getVesselsAsMarkers = (state) => {
  const selectedVessel = state.geolocatedTransportsState.filters.selectedVessel;
  const searchedValue = getSearchedValue(state);
  return state.geolocatedTransportsState.vessels.map((vessel) => ({
    position: {
      lat: vessel.lastLocationCoordinates.latitude,
      lng: vessel.lastLocationCoordinates.longitude
    },
    icon: {
      url:
        selectedVessel.split('-')[0] === vessel.imo ||
        (searchedValue && vessel.name.toLowerCase().includes(searchedValue)) ||
        (searchedValue && vessel.imo.includes(searchedValue)) ||
        (searchedValue &&
          vessel?.vins.some((vin) => vin.toLowerCase().includes(searchedValue)))
          ? greenVesselMarkerSelected
          : greenVesselMarker
    },
    title: `${vessel.imo}-${vessel.name}`,
    type: VesselType
  }));
};

export const getTransportsGeolocationRequestIsPending = (state) =>
  state.geolocatedTransportsState.transportsRequestStatus === 'pending';

export const getVesselsGeolocationRequestIsPending = (state) =>
  state.geolocatedTransportsState.vesselsRequestStatus === 'pending';

export const getSearchedValue = (state) =>
  state.geolocatedTransportsState.filters.search.toLowerCase();

export const getSelectedTransportId = (state) =>
  state.geolocatedTransportsState.filters.selectedTransportId;

export const getSelectedVesselDetails = (state) => {
  const selectedVesselImo =
    state.geolocatedTransportsState.filters.selectedVessel.split('-')[0];
  const searchedValue = getSearchedValue(state);
  const shipsFound: GeolocatedVessel[] = [];

  state.geolocatedTransportsState.vessels.forEach((vessel) => {
    if (
      vessel.imo === selectedVesselImo ||
      (searchedValue && vessel.name.toLowerCase().includes(searchedValue)) ||
      (searchedValue && vessel.imo.includes(searchedValue)) ||
      (searchedValue &&
        vessel?.vins.some((vin) => vin.toLowerCase().includes(searchedValue)))
    ) {
      shipsFound.push(vessel);
    }
  });
  return shipsFound;
};

export const getHasVesselsDetailError = (state) => {
  const selectedVesselDetails = getSelectedVesselDetails(state);
  return (
    !selectedVesselDetails.length &&
    !!state.geolocatedTransportsState.filters.search
  );
};

const getSelectedTransport = (state): GeolocatedTransport | undefined => {
  const transportDetails =
    state.finishedGoodsByCheckpointState.transportDetails;
  const searchedVin = state.geolocatedTransportsState.filters.search;
  const selectedTransportId =
    state.geolocatedTransportsState.filters.selectedTransportId;
  if (searchedVin.length > 0) {
    return (
      searchedVin === transportDetails.vin &&
      state.geolocatedTransportsState.transports.find(
        (transport: GeolocatedTransport) =>
          transport.transportId === transportDetails.transportId
      )
    );
  }
  return (
    selectedTransportId.length > 0 &&
    selectedTransportId === transportDetails.transportId &&
    state.geolocatedTransportsState.transports.find(
      (transport: GeolocatedTransport) =>
        transport.transportId === transportDetails.transportId
    )
  );
};

export const shouldShowGeolocationCard = (state) => {
  const selectedTransport = getSelectedTransport(state);
  const selectedVessel = getSelectedVesselDetails(state);
  return !!selectedTransport || selectedVessel.length === 1;
};

export const getPositionOfSelectedMarker = (state) => {
  const selectedTransport = getSelectedTransport(state);
  const selectedVessel = getSelectedVesselDetails(state);

  if (selectedTransport) {
    return {
      lat: selectedTransport.lastLocationCoordinates.latitude,
      lng: selectedTransport.lastLocationCoordinates.longitude
    };
  }

  if (selectedVessel.length === 1) {
    return {
      lat: selectedVessel[0].lastLocationCoordinates.latitude,
      lng: selectedVessel[0].lastLocationCoordinates.longitude
    };
  }
  return null;
};

export const getTransportMarkerUrl = (
  state: LocationStatus,
  transportSelected: boolean
): string => {
  let url: string;
  switch (state) {
    case LocationStatus.OK:
      url = transportSelected ? greenTransportSelected : greenTransportMarker;
      break;
    case LocationStatus.WARNING:
      url = transportSelected ? yellowTransportSelected : yellowTransportMarker;
      break;
    case LocationStatus.DANGER:
      url = transportSelected ? orangeTransportSelected : orangeTransportMarker;
      break;
    case LocationStatus.CRITICAL:
      url = transportSelected ? redTransportSelected : redTransportMarker;
      break;
    default:
      url = transportSelected ? greenTransportSelected : greenTransportMarker;
  }
  return url;
};

export const getSelectedVessel = (state) =>
  state.geolocatedTransportsState.filters.selectedVessel;
export const getDownloadVesselVinsSuccessful = (state) =>
  state.geolocatedTransportsState.downloadVesselVinsRequestStatus ===
    'fulfilled' && state.geolocatedTransportsState.vesselVinsFile !== null;
export const getDownloadVesselVinsError = (state) =>
  state.geolocatedTransportsState.downloadVesselVinsRequestStatus ===
  'rejected';
export const getDownloadVesselVinsIsPending = (state) =>
  state.geolocatedTransportsState.downloadVesselVinsRequestStatus === 'pending';

//actions

export const {
  updateSearchedValue,
  updateSelectedTransport,
  updateSelectedVesselImo,
  restoreFilters,
  restoreVesselDetails,
  resetDownloadStatus
} = geolocatedTransportsSlice.actions;

export default geolocatedTransportsSlice.reducer;
