import { CriticalStockType, MaterialIndicator } from './types';

class CriticalPieceDTO {
  critical_stock_type: CriticalStockType;
  code: string;
  material_indicator: MaterialIndicator;

  constructor({ critical_stock_type, code, material_indicator }) {
    this.critical_stock_type = critical_stock_type;
    this.code = code;
    this.material_indicator = material_indicator;
  }

  static generateDTO(data: any): CriticalPieceDTO {
    return new CriticalPieceDTO({
      critical_stock_type: data?.critical_stock_type,
      code: data?.code,
      material_indicator: data?.material_indicator
    });
  }
}

export { CriticalPieceDTO };
