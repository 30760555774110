import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  RefillStatusDTO,
  StatusItem
} from '../infrastructure/dto/RefillsStatusDTO';
import { SearchModality } from '../infrastructure/dto/types';
import { RefillsText } from '../infrastructure/i18n/RefillsText';
import { RefillsTranslations } from '../infrastructure/i18n/typing';

const refillsTranslations: RefillsTranslations = {
  ...RefillsText[LocaleService.getLanguage()]
};

export interface RefillStatus {
  generatedAt: string;
  stats: StatusItem[];
  toDateTime: string | undefined;
  fromDateTime: string | undefined;
  searchModality: SearchModality;
  total: number;
}

interface GenerateFromDTOParams {
  snapshots: RefillStatusDTO[];
}

class RefillsStatusData {
  refillsStatuses: RefillStatus[];

  constructor({ snapshots }) {
    this.refillsStatuses = snapshots;
  }

  private static _formattedGeneratedAtTranslation(item): string {
    if (item.search_modality === SearchModality.FROM) {
      return refillsTranslations.now;
    }
    if (item.search_modality === SearchModality.TO) {
      return refillsTranslations.previousHours;
    }
    return GetDateFormatedService.getShortHourLocale(item.from_datetime);
  }

  static generateFromDTO({
    snapshots
  }: GenerateFromDTOParams): RefillsStatusData {
    let formatRefillsStatuses: RefillStatus[] = [];

    snapshots.forEach((item) => {
      formatRefillsStatuses.push({
        generatedAt: this._formattedGeneratedAtTranslation(item),
        toDateTime: item.to_datetime,
        fromDateTime: item.from_datetime,
        searchModality: item.search_modality,
        total: item.total,
        stats: item.stats
      });
    });

    return new RefillsStatusData({ snapshots: formatRefillsStatuses });
  }
}

export default RefillsStatusData;
