import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NameValues,
  TimeGraphStatus
} from 'modules/inbound/shared/domain/NameValues';
import { getCurrentPage, getResultsPerPage, getSelectedColumn } from '.';
import getFiltersQueryString, {
  cleanUpQueryDuplicatesAndGetString,
  getGraphStatusQuery,
  getTimeGraphQuery
} from 'modules/inbound/shared/domain/GetFiltersQuery';
import getSuppliesDataService from 'modules/inbound/supplies/application/GetSuppliesDataService';
import { Filters } from './types';
import GetSuppliesClosedStatusService from 'modules/inbound/supplies/application/GetSuppliesClosedStatusService';
import GetSuppliesCriticalStockSummaryUseCase from 'modules/inbound/supplies/application/GetSuppliesCriticalStockSummaryUseCase';
import GetSuppliesRequestsStatusService from 'modules/inbound/supplies/application/GetSuppliesRequestsStatusService';
import GetWarehousesRankingService from 'modules/inbound/supplies/application/GetWarehousesRankingService';
import GetWarehousesTargetService from 'modules/inbound/supplies/application/GetWarehousesTargetService';
import DownloadSuppliesService from 'modules/inbound/supplies/application/DownloadSuppliesService';
import {
  getFilterSearch,
  getSelectedClosedStatus,
  getSelectedCriticalStockType,
  getSelectedDestinationRanking,
  getSelectedFilters,
  getSelectedRequestStatus,
  getSelectedStatusType,
  getSelectedSubzoneRanking,
  getSelectedWarehouseRanking
} from '../SuppliesFiltersStateReducer';
import { SelectedFilterKeys } from '../SuppliesFiltersStateReducer/types';
import { getSuppliesCriticalStockUseCase } from 'modules/inbound/supplies/application/GetSuppliesCriticalStockUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import GetSubzonesRankingUseCase from 'modules/inbound/supplies/application/GetSubzonesRankingUseCase';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';
import { suppliesSearchQueryBuilder } from 'modules/inbound/supplies/application/SuppliesSearchQueryBuilder';

const getSuppliesContainersFiltersStatus = (appState) => {
  const selectedClosedStatus: TimeGraphStatus =
    getSelectedClosedStatus(appState);
  const selectedRequestStatus: TimeGraphStatus =
    getSelectedRequestStatus(appState);
  const selectedWarehouseRanking = getSelectedWarehouseRanking(appState);
  const selectedSubzoneRanking = getSelectedSubzoneRanking(appState);
  const selectedDestinationRanking = getSelectedDestinationRanking(appState);
  const selectedCriticalStockType = getSelectedCriticalStockType(appState);
  const selectedFilters = getSelectedFilters(appState);
  const selectedStatusType = getSelectedStatusType(appState);

  const queryFilters: Filters[] = [];

  Object.keys(selectedFilters).forEach((key) => {
    selectedFilters[key].length &&
      queryFilters.push({
        name: SelectedFilterKeys[key],
        values: selectedFilters[key]
      });
  });

  let querySearch: any[] = [];
  /** Subheader filters query */
  querySearch.push(...getFiltersQueryString(queryFilters));

  /** Request status query */
  if (selectedRequestStatus.status) {
    querySearch = querySearch.concat(getTimeGraphQuery(selectedRequestStatus));
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedRequestStatus.status, false)
    );
  }
  if (selectedStatusType) {
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedStatusType, false)
    );
  }

  /** Request closed status query */
  if (selectedClosedStatus.status) {
    querySearch = querySearch.concat(getTimeGraphQuery(selectedClosedStatus));
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedClosedStatus.status, true)
    );
  }

  /** Warehouse Ranking query */
  if (selectedWarehouseRanking.status) {
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedWarehouseRanking.status, false)
    );
  }

  if (selectedSubzoneRanking.status) {
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedSubzoneRanking.status, false)
    );
  }

  /** Warehouse Destination query */
  if (selectedDestinationRanking.status) {
    querySearch = querySearch.concat(
      getGraphStatusQuery(selectedDestinationRanking.status, false)
    );
  }

  /** Critical Stock query */
  selectedCriticalStockType &&
    querySearch.push(
      `${NameValues.CRITICAL_STOCK_TYPE}:=:${selectedCriticalStockType}`
    );

  return querySearch;
};

const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.DESCRIPTION]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.OT_NUMBER]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.WAREHOUSE_UNIT_NUMBER]: splitStringByAGivenCharacter(
      searchValue,
      '+'
    ),
    [NameValues.PROVIDER_DELIVERY_NOTE]: splitStringByAGivenCharacter(
      searchValue,
      '+'
    )
  };

  return suppliesSearchQueryBuilder(search);
};

const getSuppliesData = createAsyncThunk(
  `suppliesData/getSuppliesDataPagination`,
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      page: currentPage,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: queryString && `{${queryString}}`
    };

    const response = await getSuppliesDataService.execute({
      queryParams
    });
    return response;
  }
);

const getRequestsStatus = createAsyncThunk(
  `suppliesData/getRequestsStatus`,
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await GetSuppliesRequestsStatusService.execute({
      queryParams
    });
    return response;
  }
);

const getClosedStatus = createAsyncThunk(
  `suppliesData/getClosedStatus`,
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await GetSuppliesClosedStatusService.execute({
      queryParams
    });
    return response;
  }
);

const getCriticalStockSummary = createAsyncThunk(
  `suppliesData/getCriticalStockSummary`,
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await GetSuppliesCriticalStockSummaryUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getWarehousesTarget = createAsyncThunk(
  `suppliesData/getWarehousesTarget`,
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    /**
     * @queryParams
     * optional top_size (number). By default it will return the top 4.
     */
    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await GetWarehousesTargetService.execute({
      queryParams
    });
    return response;
  }
);

const getWarehousesRanking = createAsyncThunk(
  `suppliesData/getWarehousesRanking`,
  async (_, { getState }) => {
    const appState = getState();
    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };
    const response = await GetWarehousesRankingService.execute({
      queryParams
    });
    return response;
  }
);

const getSubzonesRanking = createAsyncThunk(
  `suppliesData/getSubzonesRanking`,
  async (_, { getState }) => {
    const appState = getState();
    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };
    const response = await GetSubzonesRankingUseCase.execute({
      queryParams
    });
    return response;
  }
);

const downloadSupplies = createAsyncThunk(
  'suppliesData/downloadSupplies',
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = cleanUpQueryDuplicatesAndGetString(
      getSuppliesContainersFiltersStatus(appState)
    );
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadSuppliesService.execute({
      queryParams
    });
    return response;
  }
);

const getCriticalPieces = createAsyncThunk(
  'suppliesData/getCriticalPieces',
  () => getSuppliesCriticalStockUseCase.execute()
);

export {
  getSuppliesData,
  getRequestsStatus,
  getClosedStatus,
  getCriticalStockSummary,
  getWarehousesTarget,
  getWarehousesRanking,
  downloadSupplies,
  getCriticalPieces,
  getSubzonesRanking
};
