import { UserDashboards } from 'modules/shared/domain/UserRole';
import { InboundLandingPage, LandingPage } from './pageComponents';
import { InboundRoutes, RouteItemProps } from './types';
import { ReactComponent as HomeIcon } from 'assets/icons/homeIcon.svg';
import { OutboundPaths } from './outboundRoutes';

export const LANDING_ROUTES: RouteItemProps[] = [
  {
    name: 'landing',
    icon: HomeIcon,
    subItems: [
      {
        name: 'inboundInhouse',
        path: InboundRoutes.LANDING,
        element: InboundLandingPage,
        id: UserDashboards.LANDING
      },
      { name: 'outbound', path: OutboundPaths.landing, element: LandingPage }
    ]
  }
];
