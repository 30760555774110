import { EntriesDataItemDTO } from '../NextHoursDTO/entriesDataDTOMapper';
import { UnloadingPointStateType } from '../types';

type EntryDataDTO = Pick<
  EntriesDataItemDTO,
  | 'urgent'
  | 'plate_number'
  | 'transport_id'
  | 'carrier'
  | 'supplier_name'
  | 'stops'
  | 'packages'
>;

export interface UnloadingPointEntryDTO extends EntryDataDTO {
  unloading_point_booked: boolean;
  executed_state: UnloadingPointStateType;
  start_time: string;
  end_time: string;
  is_critical: boolean;
}

export interface EntriesBySlotAndUnloadingPointDTO {
  entries: UnloadingPointEntryDTO[];
}

export const entriesBySlotAndUnloadingPointDTOMapper = (
  data: any
): EntriesBySlotAndUnloadingPointDTO => {
  return {
    entries:
      data?.entries?.map((entry) => ({
        urgent: entry.urgent,
        is_critical: !!entry.is_critical,
        carrier: entry.carrier ?? '-',
        unloading_point_booked: entry.unloading_point_booked,
        executed_state: entry.executed_state,
        plate_number: entry.plate_number,
        transport_id: entry.transport_id,
        supplier_name: entry.supplier_name ?? '-',
        stops: entry.stops,
        start_time: entry.start_time,
        end_time: entry.end_time,
        packages: entry.packages
      })) ?? []
  };
};
