export const MORNING = 'M';
export const AFTERNOON = 'T';
export const NIGHT = 'N';

export type Shift = typeof MORNING | typeof AFTERNOON | typeof NIGHT;

interface SaturationStatesDTO {
  executed: number;
  planned: number;
  unloading: number;
  in_plant: number;
}

export interface UnloadingMetrics {
  planned: number;
  executed: number;
}

export interface UnloadingPointTimeSlotDTO {
  containers: SaturationStatesDTO;
  entries: SaturationStatesDTO;
  hour: string;
  shift: Shift;
  is_critical: boolean;
  is_last_entries_update_slot: boolean;
  is_urgent: boolean;
}

class UnloadingPointDTO {
  unloading_point: string;
  current_shift_containers: UnloadingMetrics;
  current_shift_entries: UnloadingMetrics;
  slots: UnloadingPointTimeSlotDTO[];

  constructor({
    unloading_point,
    current_shift_containers,
    current_shift_entries,
    slots
  }) {
    this.unloading_point = unloading_point;
    this.current_shift_containers = current_shift_containers;
    this.current_shift_entries = current_shift_entries;
    this.slots = slots;
  }

  private static _generateSaturationStates(
    saturationItem
  ): SaturationStatesDTO {
    return {
      planned: saturationItem?.planned ?? 0,
      unloading: saturationItem?.unloading ?? 0,
      executed: saturationItem?.executed ?? 0,
      in_plant: saturationItem?.in_plant ?? 0
    };
  }

  static generateDTO(data: any): UnloadingPointDTO {
    return new UnloadingPointDTO({
      unloading_point: data?.unloading_point ?? '',
      current_shift_containers: {
        planned: data?.current_shift_containers?.planned ?? 0,
        executed: data?.current_shift_containers?.executed ?? 0
      },
      current_shift_entries: {
        planned: data?.current_shift_entries?.planned ?? 0,
        executed: data?.current_shift_entries?.executed ?? 0
      },
      slots: data?.slots?.map((slotItem) => ({
        containers: this._generateSaturationStates(slotItem?.containers),
        entries: this._generateSaturationStates(slotItem?.entries),
        hour: slotItem.hour,
        shift: slotItem.shift,
        is_critical: slotItem.is_critical ?? false,
        is_last_entries_update_slot:
          slotItem.is_last_entries_update_slot ?? false,
        is_urgent: slotItem.is_urgent ?? false
      }))
    });
  }
}

export { UnloadingPointDTO };
