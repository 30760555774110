import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMidTermCapacitiesDashboardUseCase } from 'modules/outbound/capacities/application/GetMidTermCapacitiesDashboardUseCase';
import { getLongTermCapacitiesDashboardUseCase } from 'modules/outbound/capacities/application/GetLongTermCapacitiesDashboardUseCase';
import { getTopFlowsLackCapacitiesUseCase } from 'modules/outbound/capacities/application/GetTopFlowsLackCapacitiesUseCase';

const getMidTermCapacitiesDashboard = createAsyncThunk(
  'capacities/getMidTermCapacitiesDashboard',
  () => getMidTermCapacitiesDashboardUseCase.execute()
);

const getLongTermCapacitiesDashboard = createAsyncThunk(
  'capacities/getLongTermCapacitiesDashboard',
  () => getLongTermCapacitiesDashboardUseCase.execute()
);

const getTopFlowsLackCapacities = createAsyncThunk(
  'capacities/getTopFlowsLackCapacities',
  () => {
    const queryParams = { sort: 'asc', limit: 5, include: 'negative' };
    return getTopFlowsLackCapacitiesUseCase.execute({
      queryParams
    });
  }
);

export {
  getMidTermCapacitiesDashboard,
  getLongTermCapacitiesDashboard,
  getTopFlowsLackCapacities
};
