class FinishedGoodTransportDetailsDTO {
  transportId: string;
  plate: string;
  originName: string;
  destinationName: string;
  carrier: string;
  departureDate: string;
  lastLocationName: string;
  lastLocationDate: string;
  estimatedArrival: string;
  vins: string[];

  constructor({
    transportId,
    plate,
    originName,
    destinationName,
    carrier,
    departureDate,
    lastLocationName,
    lastLocationDate,
    estimatedArrival,
    vins
  }) {
    this.transportId = transportId;
    this.plate = plate;
    this.originName = originName;
    this.destinationName = destinationName;
    this.carrier = carrier;
    this.departureDate = departureDate;
    this.lastLocationName = lastLocationName;
    this.lastLocationDate = lastLocationDate;
    this.estimatedArrival = estimatedArrival;
    this.vins = vins;
  }
}

export default FinishedGoodTransportDetailsDTO;
