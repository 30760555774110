import IFactoryBcnDataRepository from '../domain/IFactoryBcnDataRepository';
import { FactoryBcnData, factoryBcnMapper } from '../domain/factoryBcnMapper';
import factoryBcnDataRepository from '../infrastructure/repositories/FactoryBCNRepository';
class GetFactoryBcnDataUseCase {
  private _factoryBcnDataRepository: IFactoryBcnDataRepository;

  constructor({ factoryBcnDataRepository }) {
    this._factoryBcnDataRepository = factoryBcnDataRepository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<FactoryBcnData> {
    return this._factoryBcnDataRepository
      .get({ queryParams })
      .then((res) => factoryBcnMapper(res));
  }
}

export default new GetFactoryBcnDataUseCase({
  factoryBcnDataRepository: factoryBcnDataRepository
});

export { GetFactoryBcnDataUseCase };
