import { createSlice } from '@reduxjs/toolkit';
import { WarehouseStockByModelState } from './types';
import { getWarehouseStockByModel } from './async';

export const initialState: WarehouseStockByModelState = {
  stockByModel: [],
  warehouseReference: '',
  requestStatus: ''
};

const deliveriesGeolocationSlice = createSlice({
  name: 'warehouseStockByModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWarehouseStockByModel.fulfilled, (state, action) => {
      state.stockByModel = action.payload.stockByModel || [];
      state.warehouseReference = action.payload.warehouseReference || '';
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseStockByModel.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseStockByModel.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getStockByModel = (state) =>
  state.warehouseStockByModelState.stockByModel;

export const getRequestStatus = (state) =>
  state.warehouseStockByModelState.requestStatus;

export const getWarehouseReference = (state) =>
  state.warehouseStockByModelState.warehouseReference;

export default deliveriesGeolocationSlice;
