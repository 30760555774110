import { createAsyncThunk } from '@reduxjs/toolkit';
import GetEntriesFiltersService from 'modules/inbound/entries/application/NextHours/GetEntriesFiltersService';

const getEntriesFilters = createAsyncThunk(
  'entriesFilters/getEntriesFilters',
  async () => {
    const response = await GetEntriesFiltersService.execute();
    return response;
  }
);

export { getEntriesFilters };
