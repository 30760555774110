import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';

/**@TableHeaderParams */
/** To be able to drag and reorder the columns the next params are needed:
 * - id, should be the columns key (ColumnsProps)
 * - draggable set to true
 * - columnsList
 * - onColumnsDrag, will return an array with the new order after drag and drop
 */

export interface ColumnSelected {
  key: string;
  isAscending: boolean;
}
export interface TableHeaderProps {
  columnInfo: ItemData;
  id?: string;
  onSelectColumn?: ({
    columnKey,
    isAscending
  }: {
    columnKey: string;
    isAscending: boolean;
  }) => void;
  columnSelected?: ColumnSelected;
  textCenter?: boolean;
  className?: string;
  draggable?: boolean;
  resize?: boolean;
  columnsList?: ColumnProps[];
  onColumnsGrag?: (columns: ColumnProps[]) => void;
  selectedColumnCentered?: boolean;
}

export interface ItemData {
  key: string;
  value: string;
  sortable?: boolean;
  tooltipInfo?: string;
}
export interface TableHeaderColorProps {
  color: TableHeaderColor;
}
export enum TableHeaderColor {
  active = 'orange',
  disabled = 'white'
}

export enum Sort {
  DESC = 'DESC',
  ASC = 'ASC'
}

export const IS_STRING = 'string';
