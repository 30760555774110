import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { ShiftNames } from 'modules/inbound/shared/domain/NameValues';
import {
  ColumnProps,
  PlanningColumnsKeys
} from 'modules/inbound/shared/domain/ColumnsTypes';
import { PlanningFactoryBcnDTO } from '../infrastructure/dto/FactoryBcnDataDTO';
import { FactoryBcnText } from '../infrastructure/i18n/FactoryBcnText';
import { FactoryBcnTranslations } from '../infrastructure/i18n/typing';

const inboundTranslations: InboundTranslations & FactoryBcnTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...FactoryBcnText[LocaleService.getLanguage()]
};

export const generatePlanningDatesColumns = (
  planning_dates: string[],
  planning: PlanningFactoryBcnDTO[]
): ColumnProps[] => {
  let generatedPlanningColumns: ColumnProps[] = [];
  planning_dates.forEach((planningDate: string, index) => {
    if (planning_dates[index] === planning[index]?.date) {
      let dayShifts: any[] = planning[index].shifts;

      generatedPlanningColumns.push({
        key:
          index < 1
            ? PlanningColumnsKeys.alwaysVisible
            : PlanningColumnsKeys.optionVisible,
        value: planningDate,
        headerVisible: true,
        isVisibleCheck: index < 1,
        headerSpan: planning[index].shifts.length,
        columns: dayShifts.map((item, shiftIndex) => ({
          uiKey: item.shift,
          key: item.shift,
          value: inboundTranslations[ShiftNames[item.shift]],
          sortable: false,
          tooltipInfo:
            index === 0 && shiftIndex === 0
              ? inboundTranslations.currentShiftDescription
              : undefined
        }))
      });
    }
  });
  return generatedPlanningColumns;
};
