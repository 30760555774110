class SuppliesFiltersDataDTO {
  lines_group: string[];
  warehouses_source_type: string[];
  warehouses_source: string[];
  manufacturings_section: string[];
  supplies_type: string[];
  materials_indicator: string[];
  phases: string[];
  lines: number[];
  warehouses_target: string[];
  constructor({
    lines_group,
    warehouses_source_type,
    warehouses_source,
    manufacturings_section,
    supplies_type,
    materials_indicator,
    phases,
    lines,
    warehouses_target
  }) {
    this.lines_group = lines_group;
    this.warehouses_source_type = warehouses_source_type;
    this.warehouses_source = warehouses_source;
    this.manufacturings_section = manufacturings_section;
    this.supplies_type = supplies_type;
    this.materials_indicator = materials_indicator;
    this.phases = phases;
    this.lines = lines;
    this.warehouses_target = warehouses_target;
  }
}

export default SuppliesFiltersDataDTO;
