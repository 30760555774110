const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const FACTORY_BCN_ENDPOINT = `${INBOUND_BASE_URL}/factory-bcn`;
const API_GET_FACTORY_BCN_FILTERS = `${FACTORY_BCN_ENDPOINT}/filters`;
const API_DOWNLOAD_FACTORY_BCN = `${FACTORY_BCN_ENDPOINT}/download`;

export {
  FACTORY_BCN_ENDPOINT,
  API_GET_FACTORY_BCN_FILTERS,
  API_DOWNLOAD_FACTORY_BCN
};
