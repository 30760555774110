import { createSlice } from '@reduxjs/toolkit';
import { CallOffTrackingState, TimelineSectionColors } from './types';
import {
  downloadCallOffFinishedGoodsByWarehouse,
  getCallOffFinishedGoodsByWarehouse,
  getCallOffTracking,
  getCallOffTrackingByCheckpoint,
  getCheckpoints,
  getFinishedGoodCheckpointsTimeline
} from './async';
import CallOffTrackingByCheckpoint from 'modules/outbound/callOff/domain/CallOffTrackingByCheckpoint';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';
import localeService from 'infrastructure/i18n/LocaleService';
import { ICallOffFinishedGoodStatus } from 'modules/outbound/callOff/domain/CallOffFinishedGood';

export const initialState: CallOffTrackingState = {
  trackingByWarehouses: {},
  defaultRangeTrackingByWarehousesRequestStatus: '',
  lastMonthTrackingByWarehousesRequestStatus: '',
  actualMonthTrackingByWarehousesRequestStatus: '',
  lastYearTrackingByWarehousesRequestStatus: '',
  actualYearTrackingByWarehousesRequestStatus: '',
  lastSevenDaysTrackingByWarehousesRequestStatus: '',
  selectedWarehouse: 'All',
  selectedBar: '',
  checkpoints: [],
  selectedCheckpoint: '',
  finishedGoodsByWarehouse: [],
  totalFinishedGoods: 0,
  filters: {
    page: 1,
    resultsPerPage: 10
  },
  finishedGoodsRequestStatus: '',
  file: null,
  downloadRequestStatus: '',
  checkpointsRequestStatus: '',
  selectedVin: '',
  vinCheckpoints: {
    vin: '',
    actualTimeline: [],
    expectedTimeline: [],
    actualTimelineTotalDays: 0,
    expectedTimelineTotalDays: 0
  },
  detailedCheckpointsRequest: '',
  callOffTrackingDataByCheckpoint: CallOffTrackingByCheckpoint.generateEmpty(),
  callOffTrackingByCheckpointRequest: ''
};

const trackingCallOffDateRangeOptions = {
  default: 'defaultRangeTrackingByWarehouses',
  L7D: 'lastSevenDaysTrackingByWarehouses',
  MTD: 'lastMonthTrackingByWarehouses',
  AMTD: 'actualMonthTrackingByWarehouses',
  YTD: 'lastYearTrackingByWarehouses',
  AYTD: 'actualYearTrackingByWarehouses'
};
const trackingCallOffDateRangeOptionsForRequestStatus = {
  default: 'defaultRangeTrackingByWarehousesRequestStatus',
  L7D: 'lastSevenDaysTrackingByWarehousesRequestStatus',
  MTD: 'lastMonthTrackingByWarehousesRequestStatus',
  AMTD: 'actualMonthTrackingByWarehousesRequestStatus',
  YTD: 'lastYearTrackingByWarehousesRequestStatus',
  AYTD: 'actualYearTrackingByWarehousesRequestStatus'
};

const callOffTrackingSlice = createSlice({
  name: 'callOffTracking',
  initialState,
  reducers: {
    updateSelectedWarehouse: (state, action) => {
      if (
        state.selectedWarehouse === action.payload.id &&
        state.selectedBar === action.payload.bar
      ) {
        state.selectedBar = initialState.selectedBar;
      } else {
        state.selectedBar = action.payload.bar;
      }
      state.selectedWarehouse = action.payload.id;
      state.filters.page = initialState.filters.page;
      if (action.payload !== 'All') {
        state.selectedCheckpoint = initialState.selectedCheckpoint;
      }
    },
    updateCurrentPage: (state, action) => {
      state.filters.page = action.payload;
    },
    updateSelectedCheckpoint: (state, action) => {
      state.selectedCheckpoint = action.payload;
      state.filters.page = initialState.filters.page;
      if (action.payload.length) {
        state.selectedWarehouse = initialState.selectedWarehouse;
        state.selectedBar = initialState.selectedBar;
      }
    },
    selectFinishedGood: (state, action) => {
      state.selectedVin = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCallOffTracking.fulfilled, (state, action) => {
      action.payload.trackingByWarehouses.forEach(
        ({
          warehouseId,
          name,
          totalCars,
          onTimeCarsPercentage,
          onTimeCars
        }) => {
          if (Object.keys(state.trackingByWarehouses).includes(warehouseId)) {
            state.trackingByWarehouses[warehouseId][
              trackingCallOffDateRangeOptions[action.payload.dateRangeType]
            ] = {
              totalCars,
              onTimeCarsPercentage,
              onTimeCars
            };
          } else {
            state.trackingByWarehouses[warehouseId] = {};
            state.trackingByWarehouses[warehouseId].name = name;
            state.trackingByWarehouses[warehouseId][
              trackingCallOffDateRangeOptions[action.payload.dateRangeType]
            ] = {
              totalCars,
              onTimeCarsPercentage,
              onTimeCars
            };
          }
        }
      );
      state[
        trackingCallOffDateRangeOptionsForRequestStatus[
          action.payload.dateRangeType
        ]
      ] = action.meta.requestStatus;
    });
    builder.addCase(getCallOffTracking.pending, (state, action) => {
      state[
        trackingCallOffDateRangeOptionsForRequestStatus[
          action.meta['dateRangeType']
        ]
      ] = action.meta.requestStatus;
    });
    builder.addCase(getCallOffTracking.rejected, (state, action) => {
      state[
        trackingCallOffDateRangeOptionsForRequestStatus[
          action.meta['dateRangeType']
        ]
      ] = action.meta.requestStatus;
    });
    builder.addCase(
      getCallOffFinishedGoodsByWarehouse.fulfilled,
      (state, action) => {
        state.finishedGoodsByWarehouse = action.payload.finishedGoods || [];
        state.totalFinishedGoods = action.payload.totalFinishedGoods;
        state.selectedWarehouse = action.payload.warehouseRef;
        state.finishedGoodsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getCallOffFinishedGoodsByWarehouse.pending,
      (state, action) => {
        state.finishedGoodsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getCallOffFinishedGoodsByWarehouse.rejected,
      (state, action) => {
        state.finishedGoodsRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadCallOffFinishedGoodsByWarehouse.fulfilled,
      (state, action) => {
        state.file = action.payload;
        downloadXlsxFile({
          file: action.payload,
          fileName: 'transit_details'
        });
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadCallOffFinishedGoodsByWarehouse.pending,
      (state, action) => {
        state.file = null;
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadCallOffFinishedGoodsByWarehouse.rejected,
      (state, action) => {
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(getCheckpoints.fulfilled, (state, action) => {
      state.checkpoints = action.payload;
      state.checkpointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCheckpoints.pending, (state, action) => {
      state.checkpointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCheckpoints.rejected, (state, action) => {
      state.checkpointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.fulfilled,
      (state, action) => {
        state.vinCheckpoints.vin = action.payload.vin;
        state.vinCheckpoints.actualTimeline = action.payload.actualTimeline;
        state.vinCheckpoints.expectedTimeline = action.payload.expectedTimeline;
        state.vinCheckpoints.actualTimelineTotalDays =
          action.payload.actualTimelineTotalDays;
        state.vinCheckpoints.expectedTimelineTotalDays =
          action.payload.expectedTimelineTotalDays;
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.pending,
      (state, action) => {
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.rejected,
      (state, action) => {
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getCallOffTrackingByCheckpoint.fulfilled,
      (state, action) => {
        state.callOffTrackingDataByCheckpoint = action.payload;
        state.callOffTrackingByCheckpointRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(getCallOffTrackingByCheckpoint.pending, (state, action) => {
      state.callOffTrackingByCheckpointRequest = action.meta.requestStatus;
    });
    builder.addCase(
      getCallOffTrackingByCheckpoint.rejected,
      (state, action) => {
        state.callOffTrackingByCheckpointRequest = action.meta.requestStatus;
      }
    );
  }
});

// actions
export const {
  updateSelectedWarehouse,
  updateCurrentPage,
  updateSelectedCheckpoint,
  selectFinishedGood
} = callOffTrackingSlice.actions;

//selectors
export const getCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.defaultRangeTrackingByWarehousesRequestStatus ===
  'pending';

export const getCallOffTrackingByWarehouse = (state) =>
  state.callOffTrackingState.trackingByWarehouses;

export const getLastMonthCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.lastMonthTrackingByWarehousesRequestStatus ===
  'pending';

export const getLastSevenDaysCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.lastSevenDaysTrackingByWarehousesRequestStatus ===
  'pending';

export const getActualMonthCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.actualMonthTrackingByWarehousesRequestStatus ===
  'pending';

export const getLastYearCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.lastYearTrackingByWarehousesRequestStatus ===
  'pending';

export const getActualYearCallOffTrackingRequestStatusIsPending = (state) =>
  state.callOffTrackingState.actualYearTrackingByWarehousesRequestStatus ===
  'pending';

export const getSelectedWarehouse = (state) =>
  state.callOffTrackingState.selectedWarehouse;

export const getSelectedBar = (state) => state.callOffTrackingState.selectedBar;

const barColorToStatus = {
  red: ICallOffFinishedGoodStatus.DANGER,
  green: ICallOffFinishedGoodStatus.OK
};

export const getSelectedStatus = (state) =>
  barColorToStatus[state.callOffTrackingState.selectedBar] || '';

export const getCallOffFinishedGoods = (state) =>
  state.callOffTrackingState.finishedGoodsByWarehouse;

export const getCallOffFinishedGoodsKeys = (state) => {
  const finishedGoods = getCallOffFinishedGoods(state);
  return finishedGoods.length ? Object.keys(finishedGoods[0]) : [];
};

export const getCallOffFinishedGoodsRequestStatusIsPending = (state) =>
  state.callOffTrackingState.finishedGoodsRequestStatus === 'pending';

export const getTotalPages = (state) =>
  Math.ceil(
    state.callOffTrackingState.totalFinishedGoods /
      state.callOffTrackingState.filters.resultsPerPage
  );

export const getCurrentPage = (state) =>
  state.callOffTrackingState.filters.page;

export const getResultsPerPage = (state) =>
  state.callOffTrackingState.filters.resultsPerPage;

export const getExportableFile = (state) => state.callOffTrackingState.file;

export const getDownloadRequestStatusIsPending = (state) =>
  state.callOffTrackingState.downloadRequestStatus === 'pending';

export const getCheckpointsForCallOffPage = (state) =>
  state.callOffTrackingState.checkpoints;

export const getCheckpointsRequestStatusIsPending = (state) =>
  state.callOffTrackingState.checkpointsRequestStatus === 'pending';

export const getSelectedCheckpoint = (state) =>
  state.callOffTrackingState.selectedCheckpoint;

export const getSelectedVin = (state) => state.callOffTrackingState.selectedVin;

export const getTimelineCheckpointsRequest = (state) =>
  state.callOffTrackingState.detailedCheckpointsRequest;

export const getCheckpointsInfo = (state) =>
  state.callOffTrackingState.vinCheckpoints;

export const getSectionsToDisplay = (state) => {
  const reducer = (accumulator, currentValue) =>
    accumulator + (currentValue.days || 0.5);

  const expectedTimelineTotalDays =
    state.callOffTrackingState.vinCheckpoints.expectedTimeline.reduce(
      reducer,
      0
    );

  const actualTimelineTotalDays =
    state.callOffTrackingState.vinCheckpoints.actualTimeline.reduce(reducer, 0);

  const largerTotalDays =
    expectedTimelineTotalDays > actualTimelineTotalDays
      ? expectedTimelineTotalDays
      : actualTimelineTotalDays || 1;

  const mapper = (item) => ({
    value: item.days === 0 ? '<1' : String(item.days),
    color:
      TimelineSectionColors[item.checkpointKey] ||
      TimelineSectionColors.default,
    width: String(Math.round(((item.days || 0.5) / largerTotalDays) * 100))
  });

  const lang = localeService.getLanguage();
  const dictionaryGroup = DETAILED_CHECKPOINTS_DICTIONARY.find(
    (group) => group.language === lang
  );

  const legend = Object.keys(TimelineSectionColors)
    .filter((section) => section !== 'default')
    .map((section) => ({
      name: dictionaryGroup?.checkpoints[section] || 'Unknown',
      color: TimelineSectionColors[section]
    }));

  return {
    actualTimeline:
      state.callOffTrackingState.vinCheckpoints.actualTimeline.map(mapper),
    expectedTimeline:
      state.callOffTrackingState.vinCheckpoints.expectedTimeline.map(mapper),
    legend,
    actualTimelineTotalDays:
      state.callOffTrackingState.vinCheckpoints.actualTimelineTotalDays,
    expectedTimelineTotalDays:
      state.callOffTrackingState.vinCheckpoints.expectedTimelineTotalDays,
    vin: state.callOffTrackingState.vinCheckpoints.vin
  };
};

export const getCallOffTrackingByCheckpointRequestIsReady = (state) =>
  state.callOffTrackingState.callOffTrackingByCheckpointRequest !== 'pending' &&
  state.callOffTrackingState.callOffTrackingByCheckpointRequest !==
    'rejected' &&
  !!state.callOffTrackingState.callOffTrackingByCheckpointRequest.length;

export const getCallOffTrackingDataByCheckpoint = (state) =>
  state.callOffTrackingState.callOffTrackingDataByCheckpoint;

export default callOffTrackingSlice.reducer;
