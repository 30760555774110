import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import {
  MasterDataPanelDTO,
  UpdaterDTO
} from '../infrastructure/dto/mastersDataDTOMapper';
import { MastersPanelKeys } from './MastersPanel';
import { MastersTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { MastersText } from 'modules/inbound/shared/infraestructure/i18n/mastersText';

const mastersPanelTranslations: MastersTranslations = {
  ...MastersText[LocaleService.getLanguage()]
};

export interface Responsible {
  name: string;
  email: string;
}

const patternResponsibleName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü ]{1,50}$/;
export const validateResponsibleName = (responsibleName: string): boolean => {
  return patternResponsibleName.test(responsibleName);
};

class MasterDataPanel {
  masterName: MastersPanelKeys;
  lastUpdate: string;
  responsible: Responsible;
  updater: string;

  constructor({
    masterName,
    lastUpdate,
    responsible,
    updater
  }: MasterDataPanel) {
    this.masterName = masterName;
    this.lastUpdate = lastUpdate;
    this.responsible = responsible;
    this.updater = updater;
  }

  private static _formatUpdater(updater: UpdaterDTO): string {
    let formattedUpdater: string = `${mastersPanelTranslations.updatedBy}${updater.name}`;
    if (updater.email) {
      formattedUpdater += ` (${updater.email})`;
    }
    return formattedUpdater;
  }

  static generateFromDTO({
    master_name,
    last_update,
    responsible,
    updater
  }: MasterDataPanelDTO): MasterDataPanel {
    return new MasterDataPanel({
      masterName: master_name as MastersPanelKeys,
      lastUpdate: last_update,
      responsible: {
        name: responsible?.name ?? NO_DATA,
        email: responsible?.email ?? NO_DATA
      },
      updater: updater?.name ? this._formatUpdater(updater) : ''
    });
  }
}

export default MasterDataPanel;
