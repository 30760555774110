import { createAsyncThunk } from '@reduxjs/toolkit';
import getJitDataService from 'modules/inbound/jit/application/GetPartCoverageService';
import { getCurrentPage, getResultsPerPage, getSelectedColumn } from '.';
import {
  NameValues,
  FiltersItem
} from 'modules/inbound/shared/domain/NameValues';
import { getUsername } from 'modules/shared/infrastructure/redux/UserReducer';
import {
  getFilterSearch,
  getSelectedSubheaderFilters
} from '../PartCoverageFiltersReducer';
import DownloadJitStockUseCase from 'modules/inbound/jit/application/DownloadJitStockUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getSelectedFiltersQueryArray = (state) => {
  const {
    selectedFamily,
    selectedLines,
    selectedProvidersTier1Ids,
    selectedLogisticOperatorsIds,
    selectedModels,
    selectedInbounders,
    selectedFlowType
  } = getSelectedSubheaderFilters(state);

  const queryFilters: FiltersItem[] = [
    {
      name: NameValues.FAMILY,
      values: selectedFamily
    },
    {
      name: NameValues.LINE,
      values: selectedLines
    },
    {
      name: NameValues.PROVIDER_TIER_1_ID,
      values: selectedProvidersTier1Ids.map(({ key, text }) => ({
        key: key.split('-')[0].trim(),
        text: text
      }))
    },
    {
      name: NameValues.LOGISTIC_OPERATOR_ID,
      values: selectedLogisticOperatorsIds
    },
    {
      name: NameValues.MODELS,
      values: selectedModels
    },
    {
      name: NameValues.INBOUNDER,
      values: selectedInbounders
    }
  ];

  !!selectedFlowType.key &&
    queryFilters.push({
      name: NameValues.FLOW_TYPE,
      values: [selectedFlowType]
    });

  let querySearch: string[] = [];

  queryFilters.forEach((filter) => {
    const values = filter.values.map((value) => value.key);
    if (filter.name === NameValues.MODELS && !!filter.values.length) {
      querySearch.push(`${filter.name}:LK:(${values.join(';')})`);
    } else {
      if (
        filter.values.length > 1 ||
        (filter.name === NameValues.LINE && !!filter.values.length)
      ) {
        querySearch.push(
          `${filter.name}:IN:(${values
            .map((value) => `"` + value + `"`)
            .join(';')})`
        );
      }
      if (filter.values.length === 1 && filter.name !== NameValues.LINE) {
        querySearch.push(`${filter.name}:=:"${values}"`);
      }
    }
    return '';
  });

  return querySearch;
};

const getPartCoverage = createAsyncThunk(
  'partCoverageData/getPartCoverageData',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const searchValue = getFilterSearch(appState);
    const username = getUsername(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;
    const search = searchValue && `${NameValues.CODE}:LK:${searchValue}`;

    let querySearch: string[] = getSelectedFiltersQueryArray(appState);

    querySearch.push(search);
    const query = querySearch.filter((item) => item !== '').join('||');

    const queryParams = {
      page: currentPage,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: query && `{${query}}`
    };

    const response = await getJitDataService.execute({
      queryParams,
      username
    });
    return response;
  }
);

const downloadJitStock = createAsyncThunk(
  'partCoverageData/downloadJitStock',
  async (_, { getState }) => {
    const appState = getState();
    const username = getUsername(appState);
    const searchValue = getFilterSearch(appState);
    const search = searchValue && `{${NameValues.CODE}:LK:${searchValue}}`;

    let querySearch: string[] = getSelectedFiltersQueryArray(appState);

    querySearch.push(search);
    const query = querySearch.filter((item) => item !== '').join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: query && `{${query}}`
    };
    const response = await DownloadJitStockUseCase.execute({
      queryParams,
      username
    });
    return response;
  }
);

export { getPartCoverage, downloadJitStock };
