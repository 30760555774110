import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';
import { StatusTypeEnum } from '../infrastructure/dto/DirectDeliveryFiltersDTO';

export interface DirectDeliveryFilters {
  [key: string]: string[] | string;
}

export interface DirectDeliverySearch {
  [key: string]: string[] | string;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

export const ddSearchQueryBuilder = (search: DirectDeliverySearch): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.CODE:
          queryString += values
            .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
        case NameValues.DESCRIPTION:
          queryString += values
            .map(
              (value) =>
                `${NameValues.DESCRIPTION}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.PROVIDER_CODE:
          queryString += values
            .map(
              (value) =>
                `${NameValues.PROVIDER_CODE}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
      }

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};

export const ddFiltersQueryBuilder = (
  filters: DirectDeliveryFilters
): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      const multiple = values.length > 1;
      switch (name) {
        case NameValues.MODELS_LKDD:
        case NameValues.LINES_LKDD:
          queryString += `{${name}:LK:(${values.join(';')})|OR|${
            name.split('_')[0]
          }:LK:(${values.join(';')})}`;
          break;
        default:
          queryString += multiple
            ? `${name}${SearchOperators.IN}(${values.join(';')})`
            : `${name}${SearchOperators.EQUAL}${values[0]}`;
          break;
      }
    }
    if (!Array.isArray(values) && name === NameValues.STATUS) {
      queryString += `${NameValues.STATUS}${SearchOperators.EQUAL}${
        values === StatusTypeEnum.OK ? 1 : 0
      }`;
    }
    if (!Array.isArray(values) && name === NameValues.COVERAGE_STATUS) {
      queryString += `${NameValues.COVERAGE_STATUS}${SearchOperators.EQUAL}${values}`;
    }

    queryString += '||';
  });
  return queryString.slice(0, -2);
};
