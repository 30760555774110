import { createSlice } from '@reduxjs/toolkit';
import { getContainerNeedsData, getContainerNeedsDataNextHours } from './async';
import { ContainerNeedsReducerProps } from './types';

export const initialState: ContainerNeedsReducerProps = {
  containersNeeded: [],
  requestStatus: '',
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  }
};

const containerNeedsSlice = createSlice({
  name: 'containerNeedsData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getContainerNeedsData.fulfilled, (state, action) => {
      state.containersNeeded = action.payload.containersNeeded;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getContainerNeedsData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getContainerNeedsData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getContainerNeedsDataNextHours.fulfilled,
      (state, action) => {
        state.containersNeeded = action.payload.containersNeeded;
        state.pagination = action.payload.pagination;
        state.requestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(getContainerNeedsDataNextHours.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getContainerNeedsDataNextHours.rejected,
      (state, action) => {
        state.requestStatus = action.meta.requestStatus;
      }
    );
  }
});

export const getContainerNeeds = (state) =>
  state.containerNeedsState.containersNeeded;
export const getisLoadingStatus = (state) =>
  state.containerNeedsState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.containerNeedsState.requestStatus === 'rejected' ||
  !state.containerNeedsState.containersNeeded.length;
export const getCurrentPage = (state) =>
  state.containerNeedsState.pagination.page;
export const getTotalPages = (state) =>
  state.containerNeedsState.pagination.pageCount;
export const getResultsPerPage = (state) =>
  state.containerNeedsState.pagination.size;
export const getTotalCount = (state) =>
  state.containerNeedsState.pagination.totalCount;

export default containerNeedsSlice.reducer;
export const { updateCurrentPage } = containerNeedsSlice.actions;
