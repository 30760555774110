import {
  BCNItemDTO,
  MRTItemDTO
} from '../infrastructure/dto/FactoryBcnFiltersDTO';

interface GenerateFromDTOParams {
  lines: number[];
  models: string[];
  MRT: MRTItemDTO;
  BCN: BCNItemDTO;
}

class FactoryBcnFilters {
  lines: string[];
  models: string[];
  MRTWarehouses: string[];
  BCNWarehouses: string[];

  constructor({
    lines,
    models,
    MRTWarehouses,
    BCNWarehouses
  }: FactoryBcnFilters) {
    this.lines = lines;
    this.models = models;
    this.MRTWarehouses = MRTWarehouses;
    this.BCNWarehouses = BCNWarehouses;
  }

  static generateFromDTO({
    lines,
    models,
    MRT,
    BCN
  }: GenerateFromDTOParams): FactoryBcnFilters {
    let linesFormated: string[] = [];
    lines.forEach((line) => {
      linesFormated.push(line.toString());
    });
    return new FactoryBcnFilters({
      lines: linesFormated,
      models: models,
      MRTWarehouses: MRT.warehouses,
      BCNWarehouses: BCN.warehouses
    });
  }

  static generateEmpty() {
    return new FactoryBcnFilters({
      lines: [],
      models: [],
      MRTWarehouses: [],
      BCNWarehouses: []
    });
  }
}

export default FactoryBcnFilters;
