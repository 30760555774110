import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { MovementsLineDTO } from 'modules/inbound/shared/infraestructure/dto/MovementsLineDTO';

export interface PlanningFactoryBcnDTO {
  date: string;
  shifts: ShiftItemDTO[];
}

interface CoverageDTO {
  covered: number;
  security: number;
  critical: number;
}
interface ShiftItemDTO {
  shift: string;
  amount: number;
  remaining_amount: number;
  transits: FactoryBcnTransportDTO[];
}
interface MaterialToDeliverDTO {
  in_time: number;
  in_pieces: number;
  in_containers: number;
}

export interface FactoryBcnTransportDTO {
  provider_id: number;
  plate_number: string;
  delivery_time: string;
  amount: number;
  type: string;
  estimated_time_arrival: string | null;
  is_delayed?: boolean;
  planned_date: string | null;
  transport_type: string;
}

interface LocationDTO {
  stock: number;
  location: string;
}

interface GonvautoTier2DTO {
  stock: number;
  updated_at: string;
}

interface MRTDTO {
  stock_coverage: string | number | null;
  stock: number;
  locked_stock: number;
  warehouse: string;
  margin: string | number | null;
}

interface BCNDTO {
  stock_listo: number;
  stock_bloqueado: number;
  locations: LocationDTO[];
  stock_in_review: number;
  warehouse: string;
}

export interface FactoryBcnItemDTO {
  code: string;
  models: string[];
  description: string;
  supply_container: string;
  supply_container_capacity: number;
  lines: number[];
  coverage: CoverageDTO;
  planning: PlanningFactoryBcnDTO[];
  MRT: MRTDTO;
  BCN: BCNDTO;
  material_to_deliver: MaterialToDeliverDTO;
  last_line_movement: MovementsLineDTO;
  gonvauto_tier2: GonvautoTier2DTO;
}

class FactoryBcnDataDTO {
  bodywork_needs_updated_at: string;
  stock_mrt_updated_at: string;
  stock_bcn_updated_at: string;
  bodywork_pieces: FactoryBcnItemDTO[];
  planning_dates: string[];
  pagination: IPaginationDTO;

  constructor({
    bodywork_needs_updated_at,
    stock_mrt_updated_at,
    stock_bcn_updated_at,
    bodywork_pieces,
    planning_dates,
    pagination
  }) {
    this.bodywork_needs_updated_at = bodywork_needs_updated_at;
    this.stock_mrt_updated_at = stock_mrt_updated_at;
    this.stock_bcn_updated_at = stock_bcn_updated_at;
    this.bodywork_pieces = bodywork_pieces;
    this.planning_dates = planning_dates;
    this.pagination = pagination;
  }
}

export default FactoryBcnDataDTO;
