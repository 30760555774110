import { CommentsTargetPanel } from '../../domain/InboundMessages/InboundMessages';

export interface InboundMessagesDTO {
  messages_response: MessageResponseDTO[];
}
interface MessageItemDTO {
  uid: string;
  author: string;
  content: string;
  created_at: string;
}

export interface MessageResponseDTO {
  target_uid: string;
  target_type: CommentsTargetPanel;
  messages: MessageItemDTO[];
}
const inboundMessagesDTOMapper = (data: any): InboundMessagesDTO => {
  return { messages_response: data || [] };
};

export { inboundMessagesDTOMapper };
