import { TodayLoadsDTO } from '../application/todayLoadsDTOMapper';

type GenerateFromDTOParams = TodayLoadsDTO;

class TodayLoads {
  SHP: number;
  expectedToBeSHP: number;

  constructor({ SHP, expectedToBeSHP }: TodayLoads) {
    this.SHP = SHP;
    this.expectedToBeSHP = expectedToBeSHP;
  }

  static generateFromDto({
    SHP,
    expectedToBeSHP
  }: GenerateFromDTOParams): TodayLoads {
    return new TodayLoads({
      SHP: SHP,
      expectedToBeSHP: expectedToBeSHP
    });
  }
}

export default TodayLoads;
