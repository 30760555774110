import styled from 'styled-components';

const WrapperTags = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export { WrapperTags };
