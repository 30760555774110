import { IDeliveryTimeSlotDTO } from 'modules/outbound/deliveryTime/infrastructure/dto/DeliveryTimeSlotDTO';
import { DELIVERY_TIME_CHECKPOINTS_DICTIONARY } from './deliveryTimeCheckpointsDictionary';

export interface IDeliveryTimeSlot {
  name: string;
  initialCheckpointName: string;
  totalCars: number;
}

class DeliveryTimeSlot implements IDeliveryTimeSlot {
  initialCheckpointName: string;
  name: string;
  totalCars: number;

  constructor({ name, initialCheckpointName, totalCars }: DeliveryTimeSlot) {
    this.name = name;
    this.initialCheckpointName = initialCheckpointName;
    this.totalCars = totalCars;
  }

  static generateFromDTO({
    name,
    totalCars
  }: IDeliveryTimeSlotDTO): DeliveryTimeSlot {
    return new DeliveryTimeSlot({
      name,
      initialCheckpointName: this._getInitialCheckpointName(name),
      totalCars
    });
  }

  static generateEmpty() {
    return new DeliveryTimeSlot({
      name: '',
      initialCheckpointName: '',
      totalCars: 0
    });
  }

  private static _getInitialCheckpointName(apiName: string) {
    return DELIVERY_TIME_CHECKPOINTS_DICTIONARY[apiName] || '';
  }
}

export default DeliveryTimeSlot;
