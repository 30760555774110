import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { JitFamiliesText } from '../infrastructure/i18n/JitFamiliesText';
import { JitFamiliesTranslations } from '../infrastructure/i18n/typing';
import { JitCloseContainerDTO } from '../infrastructure/dto/JitCloseContainerDTO';

const jitFamiliesTranslations: JitFamiliesTranslations = {
  ...JitFamiliesText[LocaleService.getLanguage()]
};

export class JitCloseContainer {
  provider: string;
  sequence: string;
  lastUpdated: string;
  forecastStoppageAt: string;
  onAlert: boolean;

  constructor({
    provider,
    sequence,
    lastUpdated,
    forecastStoppageAt,
    onAlert
  }: JitCloseContainer) {
    this.provider = provider;
    this.sequence = sequence;
    this.lastUpdated = lastUpdated;
    this.forecastStoppageAt = forecastStoppageAt;
    this.onAlert = onAlert;
  }

  static generateFromDTO({
    provider,
    sequences,
    last_updated,
    forecast_stoppage_at,
    on_alert
  }: JitCloseContainerDTO): JitCloseContainer {
    return new JitCloseContainer({
      provider: `${provider.id} - ${provider.name}`,
      sequence: sequences
        ? sequences.join(' | ')
        : jitFamiliesTranslations.emptyMessage,
      lastUpdated: last_updated
        ? GetDateFormatedService.getFullDateTime(last_updated)
        : jitFamiliesTranslations.emptyMessage,
      forecastStoppageAt: forecast_stoppage_at
        ? GetDateFormatedService.getFullDateTime(forecast_stoppage_at)
        : jitFamiliesTranslations.emptyMessage,
      onAlert: on_alert
    });
  }
}
