import WarehouseCapacityBody from '../domain/WarehouseCapacityBody';
import WarehouseCapacityRepository from '../infrastructure/repositories/WarehouseCapacityRepository';
import { IWarehouseCapacityRepository } from '../domain/IWarehouseCapacityRepository';

class GetWarehouseCapacityBodyService {
  private _warehouseCapacityRepository: IWarehouseCapacityRepository;

  constructor({ warehouseCapacityRepository }) {
    this._warehouseCapacityRepository = warehouseCapacityRepository;
  }

  execute() {
    return this._warehouseCapacityRepository
      .getTableBody()
      .then((res) => WarehouseCapacityBody.generateFromDTO(res));
  }
}

export default new GetWarehouseCapacityBodyService({
  warehouseCapacityRepository: WarehouseCapacityRepository
});

export { GetWarehouseCapacityBodyService };
