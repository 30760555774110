import { HandlingUnitItemDTO } from '../infrastructure/dto/HandlingUnitsDTO';

interface GenerateFromDtoParams {
  handling_units: {
    expired: HandlingUnitItemDTO;
    risk_of_expiration: HandlingUnitItemDTO;
  };
}

export interface HandlingUnitItem {
  cost: number | string;
  quantity: number | string;
}

class HandlingUnits {
  handlingUnits: {
    expired: HandlingUnitItem;
    riskOfExpiration: HandlingUnitItem;
  };

  constructor({ handlingUnits }: HandlingUnits) {
    this.handlingUnits = handlingUnits;
  }

  private static _formatThousandsDotEuros(quantity) {
    const numberWithDots = quantity.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    return numberWithDots.replace(',00', '');
  }

  static generateFromDto({
    handling_units
  }: GenerateFromDtoParams): HandlingUnits {
    return new HandlingUnits({
      handlingUnits: {
        expired: {
          cost:
            handling_units.expired.cost !== null
              ? this._formatThousandsDotEuros(handling_units.expired.cost)
              : '-',
          quantity: handling_units.expired.quantity
        },
        riskOfExpiration: {
          cost:
            handling_units.risk_of_expiration.cost !== null
              ? this._formatThousandsDotEuros(
                  handling_units.risk_of_expiration.cost
                )
              : '-',
          quantity: handling_units.risk_of_expiration.quantity
        }
      }
    });
  }

  static generateEmpty() {
    return new HandlingUnits({
      handlingUnits: {
        expired: {
          cost: '-',
          quantity: '-'
        },
        riskOfExpiration: {
          cost: '-',
          quantity: '-'
        }
      }
    });
  }
}

export { HandlingUnits };
