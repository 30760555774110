export interface IGlobalFulfillmentValueObject {
  globalAccomplishedPercentage: number;
}

interface GenerateFromDTOParams {
  globalKapDelayedCars: number;
  globalKapTotalCars: number;
}

class GlobalFulfillmentValueObject implements IGlobalFulfillmentValueObject {
  globalAccomplishedPercentage: number;

  constructor({ globalAccomplishedPercentage }: IGlobalFulfillmentValueObject) {
    this.globalAccomplishedPercentage = globalAccomplishedPercentage;
  }

  static generateFromDTO({
    globalKapDelayedCars,
    globalKapTotalCars
  }: GenerateFromDTOParams) {
    return new GlobalFulfillmentValueObject({
      globalAccomplishedPercentage: Math.round(
        (1 - globalKapDelayedCars / globalKapTotalCars) * 100
      )
    });
  }

  static generateEmpty() {
    return new GlobalFulfillmentValueObject({
      globalAccomplishedPercentage: 0
    });
  }
}

export default GlobalFulfillmentValueObject;
