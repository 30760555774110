import { createAsyncThunk } from '@reduxjs/toolkit';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import getNoJitDataService from 'modules/inbound/nojit/application/GetNoJitDataService';
import {
  getPagination,
  getSelectedPiece,
  getNoJitTargetCodes,
  getNoJitPlanning,
  getSelectedTemporalHorizon,
  getSelectedColumn
} from '.';
import getNoJitCoverageAtRiskService from 'modules/inbound/nojit/application/GetNoJitCoverageAtRiskService';
import getNoJitTransportsDetailsService from 'modules/inbound/nojit/application/GetNoJitTransportsDetailsService';
import { getUsername } from 'modules/shared/infrastructure/redux/UserReducer';
import {
  getFilterSearch,
  getSelectedHeaderFilters,
  getSelectedNoJitCoverageAtRisk
} from '../NoJitFiltersStateReducer';
import { SelectedSubHeaderFilters } from '../NoJitFiltersStateReducer/types';
import DownloadNojitUseCase from 'modules/inbound/nojit/application/DownloadNojitUseCase';
import { getSelectedDeliverySummaryStatus } from '../DeliveryStatusesReducer';
import GetNoJitPlanningUseCase from 'modules/inbound/nojit/application/GetNoJitPlanningUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import {
  nojitFiltersQueryBuilder,
  nojitSearchQueryBuilder
} from 'modules/inbound/nojit/application/NojitQueryStringBuilder';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';
import EditCommentUseCase from 'modules/inbound/nojit/application/EditCommentUseCase';

export const getFiltersQuery = (state) => {
  const {
    selectedCountries,
    selectedLines,
    selectedInbounders,
    selectedModels,
    selectedProvidersId,
    selectedWarehouses
  }: SelectedSubHeaderFilters = getSelectedHeaderFilters(state);

  const filters = {
    [NameValues.COUNTRY]: selectedCountries.map((country) => country.key),
    [NameValues.LINES]: selectedLines.map((line) => line.key),
    [NameValues.INBOUNDER]: selectedInbounders.map(
      (inbounder) => inbounder.key
    ),
    [NameValues.MODELS]: selectedModels.map((model) => model.key),
    [NameValues.PROVIDER_ID]: selectedProvidersId.map(
      (providerId) => providerId.key
    ),
    [NameValues.WAREHOUSES]: selectedWarehouses.map(
      (warehouse) => warehouse.key
    )
  };

  return nojitFiltersQueryBuilder(filters);
};

const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.NAME]: splitStringByAGivenCharacter(searchValue, '+')
  };

  return nojitSearchQueryBuilder(search);
};

const getNoJitData = createAsyncThunk(
  `noJitData/getNoJitData`,
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPagination(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    let queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);
    const defaultSortOrderQuery = `{${NameValues.FACTORY_COVERAGE_END_DATETIME}:ASC||${NameValues.TRANSIT_COVERAGE_END_DATETIME}:ASC||${NameValues.MARGIN_END_DATETIME}:ASC}`;

    const username = getUsername(appState);
    const selectedTemporalHorizon = getSelectedTemporalHorizon(appState);
    const {
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    } = getSelectedDeliverySummaryStatus(appState);

    const { selectedCoverageAtRisk } = getSelectedNoJitCoverageAtRisk(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = selectedColumn.key
      ? `{${selectedColumn.key}:${sortOrder}}`
      : defaultSortOrderQuery;

    const getSortOrderQuery = () => {
      if (selectedColumn.key === NameValues.FACTORY_COVERAGE_END_DATETIME) {
        return `{${NameValues.FACTORY_COVERAGE_END_DATETIME}:${sortOrder}||${NameValues.TRANSIT_COVERAGE_END_DATETIME}:${sortOrder}||${NameValues.MARGIN_END_DATETIME}:${sortOrder}}`;
      }
      if (selectedColumn.key === NameValues.TRANSIT_COVERAGE) {
        return `{${NameValues.TRANSIT_COVERAGE_END_DATETIME}:${sortOrder}||${NameValues.FACTORY_COVERAGE_END_DATETIME}:${sortOrder}||${NameValues.MARGIN_END_DATETIME}:${sortOrder}}`;
      }
      return sort;
    };

    if (selectedCoverageAtRisk.status) {
      queryFiltersString += `||${NameValues.SECURITY_MARGIN}:=:${selectedCoverageAtRisk.status}`;
    }

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const selectedDeliverySummaryStatus = [
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    ]
      .filter((item) => item !== null)
      .join(',');

    const queryParams = {
      page: page,
      size,
      sort: selectedColumn.key ? getSortOrderQuery() : defaultSortOrderQuery,
      search: queryString && `{${queryString}}`,
      delivery_statuses_type: selectedDeliverySummaryStatus,
      planning: selectedTemporalHorizon.key
    };
    const response = await getNoJitDataService.execute({
      queryParams,
      username: username ?? ''
    });
    return response;
  }
);

const getNoJitCoverageAtRisk = createAsyncThunk(
  'noJitData/getNoJitCoverageAtRisk',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    } = getSelectedDeliverySummaryStatus(appState);
    const selectedTemporalHorizon = getSelectedTemporalHorizon(appState);
    const selectedDeliverySummaryStatus = [
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    ]
      .filter((item) => item !== null)
      .join(',');

    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');
    const queryParams = {
      search: queryString && `{${queryString}}`,
      delivery_statuses_type: selectedDeliverySummaryStatus,
      planning: selectedTemporalHorizon.key
    };

    const response = await getNoJitCoverageAtRiskService.execute({
      queryParams
    });
    return response;
  }
);

const getNoJitTransportsByPiece = createAsyncThunk(
  'noJitData/getNoJitTransportsByPiece',
  async (_, { getState }) => {
    const appState = getState();
    const selectedPiece = getSelectedPiece(appState);
    const nojitPlanning = getNoJitPlanning(appState);
    const response = getNoJitTransportsDetailsService(
      nojitPlanning[selectedPiece].currentPlanning
    );
    return response;
  }
);

const downloadNojit = createAsyncThunk(
  'noJitData/downloadNojit',
  async (_, { getState }) => {
    const appState = getState();

    const { selectedCoverageAtRisk } = getSelectedNoJitCoverageAtRisk(appState);
    const selectedTemporalHorizon = getSelectedTemporalHorizon(appState);
    const {
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    } = getSelectedDeliverySummaryStatus(appState);
    const selectedDeliverySummaryStatus = [
      selectedPunctualityStatus,
      selectedQuantityStatus,
      selectedNoEntriesStatus
    ]
      .filter((item) => item !== null)
      .join(',');

    let queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    if (selectedCoverageAtRisk.status) {
      queryFiltersString += `||${NameValues.SECURITY_MARGIN}:=:${selectedCoverageAtRisk.status}`;
    }

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`,
      delivery_statuses_type: selectedDeliverySummaryStatus ?? '',
      planning: selectedTemporalHorizon.key
    };
    const response = await DownloadNojitUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getNoJitPlanningData = createAsyncThunk(
  'noJitData/getNoJitPlanningData',
  async (_, { getState }) => {
    const appState = getState();
    const targetCodes = getNoJitTargetCodes(appState);
    const selectedTemporalHorizon = getSelectedTemporalHorizon(appState);
    const querySearch: string[] = targetCodes.join(';');
    const queryParams = {
      search: `{code:IN:(${querySearch})}`,
      planning: selectedTemporalHorizon.key
    };
    const response = await GetNoJitPlanningUseCase.execute({
      queryParams
    });

    return response;
  }
);

const sendComment = createAsyncThunk(
  'noJitData/sendComment',
  async (commentData: { code: string; text: string }) => {
    const response = await EditCommentUseCase.execute({
      code: commentData.code,
      body: {
        text: commentData.text
      }
    });
    return response;
  }
);

export {
  getNoJitData,
  getNoJitCoverageAtRisk,
  getNoJitTransportsByPiece,
  downloadNojit,
  getNoJitPlanningData,
  sendComment
};
