import { Urgent } from '../types';
import { EntriesDataItemDTO } from './entriesDataDTOMapper';

class EntryDTO implements EntriesDataItemDTO {
  has_detail: boolean;
  plate_number: string;
  transport_id: number;
  carrier: string;
  estimated_time_arrival: string | null;
  stops: number;
  window_booked: string | null;
  most_critical_piece_coverage: number | null;
  warehouses: string[];
  locations: string[];
  unloading_points: string[];
  packages: number;
  urgent: Urgent;
  supplier_name: string;
  has_critical_pieces: boolean;
  contains_critical_pieces: boolean;
  contains_serious_fault: boolean;

  constructor({
    has_detail,
    plate_number,
    transport_id,
    carrier,
    estimated_time_arrival,
    stops,
    window_booked,
    most_critical_piece_coverage,
    warehouses,
    locations,
    unloading_points,
    packages,
    urgent,
    supplier_name,
    has_critical_pieces,
    contains_critical_pieces,
    contains_serious_fault
  }) {
    this.has_detail = has_detail;
    this.plate_number = plate_number;
    this.transport_id = transport_id;
    this.carrier = carrier;
    this.estimated_time_arrival = estimated_time_arrival;
    this.stops = stops;
    this.window_booked = window_booked;
    this.most_critical_piece_coverage = most_critical_piece_coverage;
    this.warehouses = warehouses;
    this.locations = locations;
    this.unloading_points = unloading_points;
    this.packages = packages;
    this.urgent = urgent;
    this.supplier_name = supplier_name;
    this.has_critical_pieces = has_critical_pieces;
    this.contains_critical_pieces = contains_critical_pieces;
    this.contains_serious_fault = contains_serious_fault;
  }

  static generateDTO(data: any): EntryDTO {
    return new EntryDTO({
      has_detail: !!data.has_detail,
      plate_number: data?.plate_number,
      transport_id: data?.transport_id,
      carrier: data?.carrier,
      estimated_time_arrival: data?.estimated_time_arrival ?? null,
      stops: data?.stops,
      window_booked: data?.window_booked,
      most_critical_piece_coverage: data?.most_critical_piece_coverage,
      warehouses: data?.warehouses ?? [],
      locations: data?.locations ?? [],
      unloading_points: data?.unloading_points ?? [],
      packages: data?.packages,
      urgent: data?.urgent,
      supplier_name: data?.supplier_name ?? '-',
      has_critical_pieces: data?.has_critical_pieces,
      contains_critical_pieces: data?.contains_critical_pieces,
      contains_serious_fault: data?.contains_serious_fault
    });
  }
}

export { EntryDTO };
