import { createAsyncThunk } from '@reduxjs/toolkit';
import { entriesQueryStringBuilder } from 'modules/inbound/entries/application/EntriesQueryStringBuilder';
import getEntriesDataUseCase from 'modules/inbound/entries/application/NextHours/GetEntriesDataUseCase';
import { getEntriesSummaryUseCase } from 'modules/inbound/entries/application/NextHours/GetEntriesSummaryUseCase';
import { getUrgentIncomingTrucksUseCase } from 'modules/inbound/entries/application/NextHours/GetUrgentIncomingTrucksUseCase';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import {
  getFilterByCriticalPieces,
  getFilterByUrgentTrucks,
  getFilterSearch,
  getPageInfo,
  getSelectedColumn,
  getSelectedEntry,
  getSelectedFilters
} from '.';
import { getSelectedHour } from '../DischargeDocksReducer';
import { SelectedHour } from '../DischargeDocksReducer/types';
import { SelectedSubHeaderFilters } from './types';
import { getLastUpdateUseCase } from 'modules/inbound/shared/application/LastUpdateUseCases/GetLastUpdateUseCase';
import { LastUpdateType } from 'modules/inbound/shared/domain/LastUpdate/types';
import DownloadEntriesUseCase from 'modules/inbound/entries/application/NextHours/DownloadEntriesUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getFiltersQuery = (state) => {
  const searchValue: string = getFilterSearch(state);
  const filterByUrgentTrucks: boolean = getFilterByUrgentTrucks(state);
  const filterByCriticalPiece: boolean = getFilterByCriticalPieces(state);
  const { utcDate }: SelectedHour = getSelectedHour(state);
  const {
    selectedWarehouses,
    selectedSuppliers,
    selectedUnloadingPoints
  }: SelectedSubHeaderFilters = getSelectedFilters(state);

  const filters = {
    [NameValues.SUPPLIER_NAME]: selectedSuppliers.map(
      (supplier) => supplier.key
    ),
    [NameValues.WAREHOUSE]: selectedWarehouses.map((supplier) => supplier.key),
    [NameValues.UNLOADING_POINT]: selectedUnloadingPoints.map(
      (unloadingPoint) => unloadingPoint.key
    ),
    [NameValues.CODE]: searchValue,
    [NameValues.URGENT]: filterByUrgentTrucks,
    [NameValues.HAS_CRITICAL_PIECES]: filterByCriticalPiece,
    [NameValues.DATE]: utcDate
  };

  return entriesQueryStringBuilder(filters);
};

const getEntriesData = createAsyncThunk(
  'entries/getEntriesData',
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPageInfo(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;

    const queryString = getFiltersQuery(appState);

    const queryParams = {
      page: page,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: !!queryString.length && `{${queryString}}`
    };

    const response = await getEntriesDataUseCase.execute({ queryParams });
    return response;
  }
);

const getEntryDetailData = createAsyncThunk(
  'entries/getEntryDetailData',
  async (_, { getState }) => {
    const appState = getState();
    const { transportId } = getSelectedEntry(appState);

    const response = await getEntriesDataUseCase.getDetail({
      transportId: transportId
    });

    return response;
  }
);

const getUrgentIncomingTrucks = createAsyncThunk(
  'entries/getUrgentIncomingTrucks',
  async () => getUrgentIncomingTrucksUseCase.execute()
);

const getEntriesSummaryData = createAsyncThunk(
  'entries/getEntriesSummaryData',
  async (_, { getState }) => {
    const appState = getState();

    const queryString = getFiltersQuery(appState);

    const queryParams = {
      search: !!queryString.length && `{${queryString}}`
    };

    const response = await getEntriesSummaryUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getEntriesNextHoursLastUpdateData = createAsyncThunk(
  'entries/getEntriesNextHoursLastUpdateData',
  () =>
    getLastUpdateUseCase.execute({
      queryParams: { key: LastUpdateType.entriesIncoming }
    })
);

const downloadEntries = createAsyncThunk(
  'entries/downloadEntries',
  async (_, { getState }) => {
    const appState = getState();
    const queryString = getFiltersQuery(appState);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadEntriesUseCase.execute({
      queryParams
    });
    return response;
  }
);

export {
  getEntriesData,
  getEntriesNextHoursLastUpdateData,
  getEntryDetailData,
  getUrgentIncomingTrucks,
  getEntriesSummaryData,
  downloadEntries
};
