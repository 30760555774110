import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface FamilyDTO {
  family: string;
  line: string;
  forecast_stoppage_at: string;
  provider_id: string;
  provider_name: string;
  logistic_operator_id: string;
  logistic_operator_name: string;
  on_alert: boolean;
}

class FamiliesCoverageDTO {
  families: FamilyDTO[];
  pagination: IPaginationDTO;

  constructor({ families, pagination }) {
    this.families = families;
    this.pagination = pagination;
  }
}

export default FamiliesCoverageDTO;
