import { createSlice } from '@reduxjs/toolkit';
import { StucklisteSuppliersState } from './types';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { downloadStucklisteSuppliers } from './async';

export const initialState: StucklisteSuppliersState = {
  downloadRequestStatus: '',
  stucklisteSuppliersFile: null
};

const stucklisteSuppliersSlice = createSlice({
  name: 'stucklisteSuppliers',
  initialState,
  reducers: {
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(downloadStucklisteSuppliers.fulfilled, (state, action) => {
      state.stucklisteSuppliersFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'P_Stuckliste_Suppliers'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadStucklisteSuppliers.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadStucklisteSuppliers.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getDownloadStucklisteSuppliersSuccessful = (state) =>
  state.stucklisteSuppliersState.downloadRequestStatus === 'fulfilled' &&
  state.stucklisteSuppliersState.stucklisteSuppliersFile !== null;
export const getDownloadStucklisteSuppliersError = (state) =>
  state.stucklisteSuppliersState.downloadRequestStatus === 'rejected';
export const getDownloadStucklisteSuppliersIsPending = (state) =>
  state.stucklisteSuppliersState.downloadRequestStatus === 'pending';

export const { resetDownloadStatus } = stucklisteSuppliersSlice.actions;
export default stucklisteSuppliersSlice.reducer;
