export enum OccupationState {
  OK = 'OK',
  DANGER = 'DANGER',
  UNKNOWN = ''
}
interface OccupationBrokenDown {
  withoutDestination: number;
  faucoError: number;
  blocked: number;
  expeditable: number;
}
interface ComplexStockOccupation extends SimpleStockOccupation {
  brokenDown: OccupationBrokenDown;
}
export interface SimpleStockOccupation {
  totalCars: number;
  warehouseCapacity: number;
  occupationPercentage: number;
  totalCarsOccupationState: OccupationState;
}

export interface IStockOccupation {
  martorell: ComplexStockOccupation;
  darsenaSur: SimpleStockOccupation;
  zalII: SimpleStockOccupation;
}

export interface GenerateFromDTOParams {
  martorell: ComplexStockOccupation;
  darsenaSur: SimpleStockOccupation;
  zalII: SimpleStockOccupation;
}

class OccupationStock implements IStockOccupation {
  martorell: ComplexStockOccupation;
  darsenaSur: SimpleStockOccupation;
  zalII: SimpleStockOccupation;

  constructor({ martorell, darsenaSur, zalII }: IStockOccupation) {
    this.martorell = {
      totalCars: martorell.totalCars,
      warehouseCapacity: martorell.warehouseCapacity,
      occupationPercentage:
        martorell.occupationPercentage ||
        OccupationStock.calculateOccupationPercentage({
          cars: martorell.totalCars,
          total: martorell.warehouseCapacity
        }),
      totalCarsOccupationState: martorell.totalCarsOccupationState,
      brokenDown: martorell.brokenDown
    };
    this.darsenaSur = {
      totalCars: darsenaSur.totalCars,
      warehouseCapacity: darsenaSur.warehouseCapacity,
      occupationPercentage: OccupationStock.calculateOccupationPercentage({
        cars: darsenaSur.totalCars,
        total: darsenaSur.warehouseCapacity
      }),
      totalCarsOccupationState: OccupationState.UNKNOWN
    };
    this.zalII = {
      totalCars: zalII.totalCars,
      warehouseCapacity: zalII.warehouseCapacity,
      occupationPercentage: OccupationStock.calculateOccupationPercentage({
        cars: zalII.totalCars,
        total: zalII.warehouseCapacity
      }),
      totalCarsOccupationState: OccupationState.UNKNOWN
    };
  }

  static generateFromDTO(params: GenerateFromDTOParams): OccupationStock {
    const {
      martorell: {
        totalCars,
        warehouseCapacity,
        totalCarsOccupationState,
        brokenDown
      },
      darsenaSur,
      zalII
    } = params;
    const occupationPercentage = OccupationStock.calculateOccupationPercentage({
      cars: totalCars,
      total: warehouseCapacity
    });

    return new OccupationStock({
      martorell: {
        totalCars,
        warehouseCapacity,
        occupationPercentage,
        totalCarsOccupationState:
          OccupationState[totalCarsOccupationState] || OccupationState.UNKNOWN,
        brokenDown: {
          withoutDestination: OccupationStock.calculateOccupationPercentage({
            cars: brokenDown.withoutDestination,
            total: totalCars
          }),
          faucoError: OccupationStock.calculateOccupationPercentage({
            cars: brokenDown.faucoError,
            total: totalCars
          }),
          blocked: OccupationStock.calculateOccupationPercentage({
            cars: brokenDown.blocked,
            total: totalCars
          }),
          expeditable: OccupationStock.calculateOccupationPercentage({
            cars: brokenDown.expeditable,
            total: totalCars
          })
        }
      },
      darsenaSur,
      zalII
    });
  }

  static generateEmpty(): OccupationStock {
    return new OccupationStock({
      martorell: {
        totalCars: 0,
        warehouseCapacity: 0,
        occupationPercentage: 0,
        totalCarsOccupationState: OccupationState.UNKNOWN,
        brokenDown: {
          withoutDestination: 0,
          faucoError: 0,
          blocked: 0,
          expeditable: 0
        }
      },
      darsenaSur: {
        totalCars: 0,
        warehouseCapacity: 0,
        occupationPercentage: 0,
        totalCarsOccupationState: OccupationState.UNKNOWN
      },
      zalII: {
        totalCars: 0,
        warehouseCapacity: 0,
        occupationPercentage: 0,
        totalCarsOccupationState: OccupationState.UNKNOWN
      }
    });
  }

  private static calculateOccupationPercentage({
    cars,
    total
  }: {
    cars: number;
    total: number;
  }) {
    return Math.round((cars / total) * 100) || 0;
  }
}

export default OccupationStock;
