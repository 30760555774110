import { createSlice } from '@reduxjs/toolkit';
import { DeliveriesGeolocationState } from './types';
import {
  downloadGeolocationFinishedGoods,
  getDeliveriesGeolocation,
  getFinishedGood
} from './async';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';

export const initialState: DeliveriesGeolocationState = {
  countries: [],
  requestStatus: '',
  vinSearchRequestStatus: '',
  selected: '',
  countriesVisible: true,
  filters: {
    vin: '',
    vinResult: {
      finishedGoods: [],
      checkpointRef: ''
    }
  },
  error: '',
  file: null,
  downloadRequestStatus: ''
};

const deliveriesGeolocationSlice = createSlice({
  name: 'deliveriesGeolocation',
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
      state.filters.vin = '';
      state.filters.vinResult.finishedGoods = [];
      state.filters.vinResult.checkpointRef = '';
    },
    updateCountriesVisible: (state, action) => {
      state.countriesVisible = action.payload;
    },
    updateSelectedVin: (state, action) => {
      state.filters.vin = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeliveriesGeolocation.fulfilled, (state, action) => {
      state.countries = action.payload || [];
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveriesGeolocation.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
      state.error = '';
      state.filters.vinResult.finishedGoods = [];
      state.filters.vinResult.checkpointRef = '';
      state.filters.vin = '';
    });
    builder.addCase(getDeliveriesGeolocation.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFinishedGood.fulfilled, (state, action) => {
      state.filters.vinResult.finishedGoods =
        action.payload.finishedGoods || [];
      state.filters.vinResult.checkpointRef =
        action.payload.checkpointRef || '';
      state.selected = action.payload.countryCode || '';
      state.error = '';
      state.vinSearchRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFinishedGood.pending, (state, action) => {
      state.error = '';
      state.filters.vinResult.finishedGoods = [];
      state.filters.vinResult.checkpointRef = '';
      state.selected = '';
      state.vinSearchRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFinishedGood.rejected, (state, action) => {
      if (action.error.message === 'vinError') {
        state.error = action.error.message;
        state.filters.vin = '';
      }
      state.vinSearchRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      downloadGeolocationFinishedGoods.fulfilled,
      (state, action) => {
        state.file = action.payload;
        downloadXlsxFile({
          file: action.payload,
          fileName: 'geolocation_details'
        });
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadGeolocationFinishedGoods.pending,
      (state, action) => {
        state.file = null;
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
    builder.addCase(
      downloadGeolocationFinishedGoods.rejected,
      (state, action) => {
        state.downloadRequestStatus = action.meta.requestStatus;
      }
    );
  }
});

//selectors
export const getTotalCountries = (state) =>
  state.deliveriesGeolocationState.countries;

export const getSelectedCountry = (state) =>
  state.deliveriesGeolocationState.selected;

export const getCountriesVisible = (state) =>
  state.deliveriesGeolocationState.countriesVisible;

export const getSelectedVin = (state) =>
  state.deliveriesGeolocationState.filters.vin;

export const getVinError = (state) => state.deliveriesGeolocationState.error;

export const getVinSearchRequestStatus = (state) =>
  state.deliveriesGeolocationState.vinSearchRequestStatus;

export const getVinResult = (state) => {
  if (
    state.deliveriesGeolocationState.filters.vinResult.checkpointRef.length &&
    state.deliveriesGeolocationState.filters.vinResult.finishedGoods.length
  ) {
    return state.deliveriesGeolocationState.filters.vinResult;
  }
};

export const getExportableFile = (state) =>
  state.deliveriesGeolocationState.file;

export const getDownloadRequestStatusIsPending = (state) =>
  state.deliveriesGeolocationState.downloadRequestStatus === 'pending';

//actions
export const { updateCountriesVisible, updateSelected, updateSelectedVin } =
  deliveriesGeolocationSlice.actions;
export default deliveriesGeolocationSlice;
