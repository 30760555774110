import IApi from 'modules/shared/domain/IApi';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { LastUpdateDate } from '../../domain/LastUpdate/LastUpdateDate';
import api from 'modules/shared/infrastructure/api/Api';
import { API_LAST_UPDATES } from './url';
import { ILastUpdateRepository } from '../../domain/LastUpdate/ILastUpdateRepository';

class LastUpdateRepository
  extends BaseRepository
  implements ILastUpdateRepository
{
  private _api: IApi;
  private _apiLastUpdate: string;

  constructor({ api, apiLastUpdate }) {
    super();
    this._api = api;
    this._apiLastUpdate = apiLastUpdate;
  }

  getLastUpdate({ queryParams }: { queryParams: object }) {
    const url = `${this._apiLastUpdate}${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => ({ last_update: res.last_update ?? null }))
      .then((dto) => LastUpdateDate.generateFromDTO(dto));
  }
}

const lastUpdateRepository = new LastUpdateRepository({
  api: api,
  apiLastUpdate: API_LAST_UPDATES
});

export { LastUpdateRepository, lastUpdateRepository };
