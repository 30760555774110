interface IEntriesInPlantFilters {
  warehouses: string[];
  lastPositions: string[];
}

class EntriesInPlantFilters implements IEntriesInPlantFilters {
  warehouses: string[];
  lastPositions: string[];

  constructor({ warehouses, lastPositions }: IEntriesInPlantFilters) {
    this.warehouses = warehouses || [];
    this.lastPositions = lastPositions || [];
  }

  static generateEmpty() {
    return new EntriesInPlantFilters({
      warehouses: [],
      lastPositions: []
    });
  }
}

export { EntriesInPlantFilters };
