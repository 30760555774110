import { UserRole } from 'modules/shared/domain/UserRole';

class UserInboundDTO {
  username: string;
  providers: string[];
  role: UserRole;
  dashboards: number[];
  features: string[];

  constructor({ username, providers, role, dashboards, features }) {
    this.username = username;
    this.providers = providers;
    this.role = role;
    this.dashboards = dashboards;
    this.features = features;
  }
}

export default UserInboundDTO;
