interface GenerateFromDTOParams {
  id: string;
  lastRedistributionWarehouse: string;
  currentCheckpointDate: string;
  nextCheckpointETA: string;
  delayedHours: number;
  status: string;
  geolocationEta: string | undefined;
  cumulativeDelayedHours: number;
}

interface IDelayedTime {
  days: number;
  hours: number;
}

export interface IFinishedGoods {
  vin: string;
  lastRedistributionWarehouse: string;
  currentCheckpointDate: string;
  nextCheckpointETA: string;
  delayedTime: IDelayedTime;
  status: string;
  geolocationEta: string;
  cumulativeDelayedTime: IDelayedTime;
}

export enum IFinishedGoodStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  CRITICAL = 'CRITICAL',
  UNKNOWN = ''
}
class FinishedGoods implements IFinishedGoods {
  vin: string;
  lastRedistributionWarehouse: string;
  currentCheckpointDate: string;
  nextCheckpointETA: string;
  delayedTime: IDelayedTime;
  status: string;
  geolocationEta: string;
  cumulativeDelayedTime: IDelayedTime;

  constructor({
    vin,
    lastRedistributionWarehouse,
    currentCheckpointDate,
    nextCheckpointETA,
    delayedTime,
    status,
    geolocationEta,
    cumulativeDelayedTime
  }: FinishedGoods) {
    this.vin = vin;
    this.lastRedistributionWarehouse = lastRedistributionWarehouse;
    this.currentCheckpointDate = currentCheckpointDate;
    this.nextCheckpointETA = nextCheckpointETA;
    this.delayedTime = delayedTime;
    this.status = status;
    this.geolocationEta = geolocationEta;
    this.cumulativeDelayedTime = cumulativeDelayedTime;
  }

  static generateFromDTO({
    id,
    lastRedistributionWarehouse,
    currentCheckpointDate,
    nextCheckpointETA,
    delayedHours,
    status,
    geolocationEta,
    cumulativeDelayedHours
  }: GenerateFromDTOParams): FinishedGoods {
    return new FinishedGoods({
      vin: id,
      lastRedistributionWarehouse,
      currentCheckpointDate: currentCheckpointDate.length
        ? new Date(currentCheckpointDate).toLocaleDateString()
        : '',
      nextCheckpointETA: nextCheckpointETA.length
        ? new Date(nextCheckpointETA).toLocaleDateString()
        : '',
      delayedTime: {
        days: Math.floor(delayedHours / 24),
        hours: delayedHours % 24
      },
      status: IFinishedGoodStatus[status],
      geolocationEta: geolocationEta
        ? new Date(geolocationEta).toLocaleDateString()
        : 'No Geo',
      cumulativeDelayedTime: {
        days: Math.floor(cumulativeDelayedHours / 24),
        hours: cumulativeDelayedHours % 24
      }
    });
  }

  static generateEmpty() {
    return new FinishedGoods({
      vin: '',
      lastRedistributionWarehouse: '',
      currentCheckpointDate: '',
      nextCheckpointETA: '',
      delayedTime: {
        days: 0,
        hours: 0
      },
      status: IFinishedGoodStatus.UNKNOWN,
      geolocationEta: '',
      cumulativeDelayedTime: {
        days: 0,
        hours: 0
      }
    });
  }
}

export default FinishedGoods;
