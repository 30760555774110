import { createAsyncThunk } from '@reduxjs/toolkit';
import deliveriesGeolocationService from 'modules/outbound/fulfillment/services/DeliveriesGeolocationService';
import getFinishedGoodService from 'modules/outbound/fulfillment/services/FinishedGoodService';
import downloadGeolocationFinishedGoodsService from 'modules/outbound/fulfillment/services/DownloadGeolocationFinishedGoodsService';
import {
  getSelectedCountry,
  getSelectedVin
} from 'modules/outbound/fulfillment/redux/DeliveriesGeolocationReducer';
import {
  getGeolocationFastLaneSelected,
  getGeolocationLogisticObjectiveSelected,
  getGeolocationSelectedBrands,
  getGeolocationSelectedCommercialPackagesInPlainFormat,
  getGeolocationSelectedCountriesISO,
  getGeolocationSelectedDeliveryPointsInPlainFormat,
  getSelectedGeolocationClientTypeInPlainFormat,
  getSelectedGeolocationFactoryInPlainFormat
} from '../FiltersStateReducer';
import {
  getSelectedCheckpoint,
  getStatusFilter
} from '../DeliveriesGeolocationCheckpointsReducer';

const getDeliveriesGeolocation = createAsyncThunk(
  `deliveriesGeolocation/getDeliveriesGeolocation`,
  async (_, { getState }) => {
    const appState: any = getState();
    const fastLaneSelected = getGeolocationFastLaneSelected(appState);
    const selectedClientType =
      getSelectedGeolocationClientTypeInPlainFormat(appState);
    const selectedFactory =
      getSelectedGeolocationFactoryInPlainFormat(appState);
    const selectedBrands = getGeolocationSelectedBrands(appState);
    const commercialPackagesSelected =
      getGeolocationSelectedCommercialPackagesInPlainFormat(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const deliverypointsSelected =
      getGeolocationSelectedDeliveryPointsInPlainFormat(appState);
    const queryParams = {
      clientType: selectedClientType,
      factoryId: selectedFactory,
      fastLane: fastLaneSelected,
      brands: selectedBrands,
      commercialPackages: commercialPackagesSelected,
      logisticObjective: logisticObjectiveSelected,
      deliveryPoints: deliverypointsSelected
    };
    const response = await deliveriesGeolocationService.getAll({ queryParams });
    return response;
  }
);

const getFinishedGood = createAsyncThunk(
  `deliveriesGeolocation/getFinishedGood`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedVin = getSelectedVin(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const queryParams = {
      vin: selectedVin,
      logisticObjective: logisticObjectiveSelected
    };
    const response = await getFinishedGoodService.get({
      queryParams
    });
    return response;
  }
);

const downloadGeolocationFinishedGoods = createAsyncThunk(
  `deliveriesGeolocation/downloadGeolocationFinishedGoods`,
  async (_, { getState }) => {
    const appState: any = getState();
    const fastLaneSelected = getGeolocationFastLaneSelected(appState);
    const selectedClientType =
      getSelectedGeolocationClientTypeInPlainFormat(appState);
    const selectedFactory =
      getSelectedGeolocationFactoryInPlainFormat(appState);
    const selectedBrands = getGeolocationSelectedBrands(appState);
    const commercialPackagesSelected =
      getGeolocationSelectedCommercialPackagesInPlainFormat(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const deliverypointsSelected =
      getGeolocationSelectedDeliveryPointsInPlainFormat(appState);
    const selectedCheckpoint = getSelectedCheckpoint(appState);
    const selectedStatus = getStatusFilter(appState);
    const selectedCountry = getSelectedCountry(appState);
    const selectedCountries = getGeolocationSelectedCountriesISO(appState);

    const queryParams = {
      countries: selectedCountry.length ? selectedCountry : selectedCountries,
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: selectedBrands,
      logisticObjective: logisticObjectiveSelected,
      commercialPackages: commercialPackagesSelected,
      deliveryPoints: deliverypointsSelected,
      checkpoints: selectedCheckpoint,
      status: selectedStatus
    };
    const response = await downloadGeolocationFinishedGoodsService.execute({
      queryParams
    });
    return response;
  }
);

export {
  downloadGeolocationFinishedGoods,
  getDeliveriesGeolocation,
  getFinishedGood
};
