export enum LastUpdateType {
  handlingUnits = 'handling-units',
  stockLocations = 'stock-locations',
  stockLocationsZF = 'stock-locations-zf',
  entriesInPlant = 'entries-in-plant',
  entriesIncoming = 'entries-incoming',
  parasum = 'parasumm',
  stockCoverage = 'stock-coverage',
  needs = 'needs',
  stuecklisteVW = 'stueckliste-vw'
}
