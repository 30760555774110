import CoverageAtRisk from '../../../shared/domain/CoverageAtRisk';
import { INoJitRepository } from '../domain/INoJitRepository';
import noJitRepository from '../infrastructure/repositories/NoJitRepository';

class GetNoJitCoverageAtRiskService {
  private _noJitRepository: INoJitRepository;

  constructor({ noJitRepository }) {
    this._noJitRepository = noJitRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._noJitRepository
      .getNoJitCoverageAtRisk({ queryParams })
      .then((res) => {
        return CoverageAtRisk.generateFromDTO(res);
      });
  }
}

export default new GetNoJitCoverageAtRiskService({
  noJitRepository: noJitRepository
});

export { GetNoJitCoverageAtRiskService };
