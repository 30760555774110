import { createSlice } from '@reduxjs/toolkit';
import { getLineGroupsBody, getLineGroupsData } from './async';
import { LineGroupsState } from './types';

export const initialState: LineGroupsState = {
  data: [],
  body: {
    columns: [],
    types: {}
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatusData: '',
  requestStatusBody: '',
  lastUpdate: ''
};

const lineGroupsSlice = createSlice({
  name: 'lineGroups',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLineGroupsBody.fulfilled, (state, action) => {
      state.body.columns = action.payload.data.columns;
      state.body.types = action.payload.types;
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getLineGroupsBody.pending, (state, action) => {
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getLineGroupsBody.rejected, (state, action) => {
      state.requestStatusBody = action.meta.requestStatus;
    });
    builder.addCase(getLineGroupsData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.lastUpdate = action.payload.lastUpdate;
      state.pagination = action.payload.pagination;
      state.requestStatusData = action.meta.requestStatus;
    });
    builder.addCase(getLineGroupsData.pending, (state, action) => {
      state.requestStatusData = action.meta.requestStatus;
    });
    builder.addCase(getLineGroupsData.rejected, (state, action) => {
      state.requestStatusData = action.meta.requestStatus;
    });
  }
});

export const getisLoadingStatus = (state) =>
  state.lineGroupsState.requestStatusBody === 'pending' ||
  state.lineGroupsState.requestStatusData === 'pending';
export const getHasError = (state) =>
  state.lineGroupsState.requestStatusBody === 'rejected' ||
  state.lineGroupsState.requestStatusData === 'rejected';
export const getTableName = (state) => state.lineGroupsState.body.board;
export const getTableData = (state) => state.lineGroupsState.data;
export const getColumns = (state) => state.lineGroupsState.body.columns;
export const getTypes = (state) => state.lineGroupsState.body.types;
export const getTotalPages = (state) =>
  state.lineGroupsState.pagination.pageCount;
export const getCurrentPage = (state) => state.lineGroupsState.pagination.page;
export const getResultsPerPage = (state) =>
  state.lineGroupsState.pagination.size;
export const getLastUpdate = (state) => state.lineGroupsState.lastUpdate;

export default lineGroupsSlice.reducer;

export const { updateCurrentPage } = lineGroupsSlice.actions;
