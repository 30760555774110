const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const COMMERCIAL_PACKAGES_ENDPOINT = '/outbound/car/commercialPackages';
const API_GET_COMMERCIAL_PACKAGES = BASE_URL + COMMERCIAL_PACKAGES_ENDPOINT;
const LAST_RECEIVED_FILE_ENDPOINT = '/outbound/lastReceivedFile';
const API_GET_LAST_RECEIVED_FILE = BASE_URL + LAST_RECEIVED_FILE_ENDPOINT;
const DELIVERY_POINTS_ENDPOINT = '/outbound/deliveryPoints';
const API_GET_DELIVERY_POINTS = BASE_URL + DELIVERY_POINTS_ENDPOINT;
const GLOBAL_DELAY_ENDPOINT = '/outbound/transit/global/delay';
const API_GET_GLOBAL_DELAY = BASE_URL + GLOBAL_DELAY_ENDPOINT;
const FACTORY_DELAY_ENDPOINT = '/outbound/transit/global/factory-delay';
const LOADS_STATUS_ENDPOINT = '/outbound/load/status';
const API_GET_FACTORY_DELAY = BASE_URL + FACTORY_DELAY_ENDPOINT;
const API_GET_LOADS_STATUS = BASE_URL + LOADS_STATUS_ENDPOINT;
const STOCK_AGING_ENDPOINT = '/outbound/stock/aging';
const API_GET_STOCK_AGING = BASE_URL + STOCK_AGING_ENDPOINT;
const STOCK_OCCUPATION_ENDPOINT = '/outbound/stock/occupation';
const API_GET_STOCK_OCCUPATION = BASE_URL + STOCK_OCCUPATION_ENDPOINT;
const STOCK_SATURATION_ENDPOINT = '/outbound/stock/saturation';
const API_GET_STOCK_SATURATION_OCCUPATION =
  BASE_URL + STOCK_SATURATION_ENDPOINT;

export {
  API_GET_COMMERCIAL_PACKAGES,
  API_GET_LAST_RECEIVED_FILE,
  API_GET_DELIVERY_POINTS,
  API_GET_GLOBAL_DELAY,
  API_GET_FACTORY_DELAY,
  API_GET_LOADS_STATUS,
  API_GET_STOCK_AGING,
  API_GET_STOCK_OCCUPATION,
  API_GET_STOCK_SATURATION_OCCUPATION
};
