import LocaleService from 'infrastructure/i18n/LocaleService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { PartCoverageDTO } from '../infrastructure/dto/PartCoverageDTO';
import { PlanningItem } from 'modules/inbound/shared/domain/PlanningTransports';
import { CoverageStatus } from 'modules/inbound/shared/domain/CoverageStatusTypes';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { PART_COVERAGE_COLUMNS } from './PartCoverageColumns';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};

const PIECE = 'PIECE',
  DAY = 'DAY';

enum ProviderTypeOutput {
  SYNCRO = 'Syncro',
  NO_SYNCRO = 'No syncro'
}

export interface JitStockPlanning {
  [key: string]: PlanningItem;
}

export interface JitStock {
  code: string;
  providerCode: string;
  date: string;
  conType: string;
  providerCoverage: string;
  lines: string[];
  models: string;
  families: string[];
  sequencerCoverage: string;
  sequencerCoverageStatus: CoverageStatus;
  providerType: string;
  margin: string;
  planning: JitStockPlanning;
  criticalThresholdDays: number;
  securityThresholdDays: number;
  OLProvider: string;
  OLStock: string;
  OLUpdated: string;
  T1Provider: string;
  T1Stock: string;
  T1Updated: string;
}

interface GenerateFromDTOParams {
  planning_dates: string[];
  stock: PartCoverageDTO[];
  pagination: IPaginationDTO;
  update_planning: string | null;
}

class PartCoverageData {
  planningDates: string[];
  stock: JitStock[];
  updatePlanning: string;
  columns: ColumnProps[];
  pagination: IPagination;

  constructor({
    planningDates,
    stock,
    updatePlanning,
    columns,
    pagination
  }: PartCoverageData) {
    this.planningDates = planningDates;
    this.stock = stock;
    this.updatePlanning = updatePlanning;
    this.columns = columns;
    this.pagination = pagination;
  }

  private static _formatPlannedDate(isoDateString) {
    const REMOVE_SECONDS_AND_GMT = -7;
    return GetDateFormatedService.dateFromISO8601(isoDateString)
      .toUTCString()
      .slice(0, REMOVE_SECONDS_AND_GMT);
  }

  private static _formatThousandsDot(quantity, unitType) {
    if (quantity === null) {
      return '-';
    }

    if (quantity === 'MAX') {
      return 'MAX';
    }

    let numberWithDots;
    if (typeof quantity === 'number') {
      numberWithDots = quantity.toLocaleString('de-DE');
    }
    if (typeof quantity === 'string') {
      numberWithDots = quantity.replace('.', ',').replace(/,0+$/, '');
    }

    if (numberWithDots === '1') {
      return unitType === PIECE
        ? `${numberWithDots} ${inboundTranslations.unitSinglePiece}`
        : `${numberWithDots} ${inboundTranslations.unitSingleDay}`;
    }
    return unitType === PIECE
      ? `${numberWithDots} ${inboundTranslations.unitMultiplePieces}`
      : `${numberWithDots} ${inboundTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    planning_dates,
    stock,
    pagination,
    update_planning
  }: GenerateFromDTOParams): PartCoverageData {
    let formatStock: JitStock[] = [];

    if (stock.length) {
      stock.forEach((stockItem) => {
        const formatPlanning: JitStockPlanning = {};

        if (stockItem.planning) {
          stockItem.planning.forEach((planningItem) => {
            formatPlanning[planningItem.date] = {
              date: planningItem.date,
              amount: planningItem.amount,
              loads: planningItem.loads.map((load) => {
                return {
                  providerId: stockItem.provider_tier_1.id,
                  amount: load.amount,
                  deliveryTime: `${GetDateFormatedService.getTimeFormated(
                    load.delivery_time
                  )}h`,
                  providerDeliveryNote:
                    load.provider_delivery_note !== ''
                      ? load.provider_delivery_note
                      : '-',
                  plateNumber: load.plate_number
                };
              }),
              coverageSegments: planningItem.coverage_segments
            };
          });
        }

        formatStock.push({
          code: stockItem.code,
          providerCode:
            stockItem.provider_code ?? inboundTranslations.withoutReference,
          date: stockItem.date ? this._formatPlannedDate(stockItem.date) : '',
          conType: stockItem.con_type,
          providerCoverage: this._formatThousandsDot(
            stockItem.provider_coverage,
            DAY
          ),
          lines: stockItem.lines || '',
          models: stockItem.models
            ? stockItem.models.replaceAll('|', ' | ')
            : '-',
          families: stockItem.families,
          sequencerCoverage:
            stockItem.sequencer_coverage === 'MAX'
              ? stockItem.sequencer_coverage
              : this._formatThousandsDot(stockItem.sequencer_coverage, DAY),
          sequencerCoverageStatus: stockItem.sequencer_coverage_status,
          providerType: ProviderTypeOutput[stockItem.provider_type],
          margin: this._formatThousandsDot(stockItem.margin, DAY),
          planning: formatPlanning,
          criticalThresholdDays: stockItem.critical_threshold_days,
          securityThresholdDays: stockItem.security_threshold_days,
          OLProvider:
            stockItem.logistic_operator.id && stockItem.logistic_operator.name
              ? `${stockItem.logistic_operator.id} - ${stockItem.logistic_operator.name}`
              : inboundTranslations.notApply,
          OLStock: this._formatThousandsDot(
            stockItem.logistic_operator.stock,
            PIECE
          ),
          OLUpdated: stockItem.logistic_operator.last_updated
            ? GetDateFormatedService.getFullDateTime(
                stockItem.logistic_operator.last_updated
              )
            : '-',
          T1Provider: `${stockItem.provider_tier_1.id} - ${stockItem.provider_tier_1.name}`,
          T1Stock: this._formatThousandsDot(
            stockItem.provider_tier_1.stock,
            PIECE
          ),
          T1Updated: stockItem.provider_tier_1.last_updated
            ? GetDateFormatedService.getFullDateTime(
                stockItem.provider_tier_1.last_updated
              )
            : '-'
        });
      });
    }

    const updatePlanningToUCT = update_planning
      ? GetDateFormatedService.dateFromISO8601(update_planning)
      : '';

    const updatePlanningDate = updatePlanningToUCT
      ? GetDateFormatedService.getDateFormated(updatePlanningToUCT, 'de-DE')
      : '';
    const updatePlanningTime = updatePlanningToUCT
      ? GetDateFormatedService.getTimeFormated(updatePlanningToUCT)
      : '';

    return new PartCoverageData({
      planningDates: planning_dates,
      stock: formatStock,
      updatePlanning: update_planning
        ? `${updatePlanningDate} | ${updatePlanningTime}`
        : '-',
      columns: PART_COVERAGE_COLUMNS,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty(): PartCoverageData {
    return new PartCoverageData({
      planningDates: [],
      stock: [],
      columns: [],
      updatePlanning: '-',
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default PartCoverageData;
