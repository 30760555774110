export interface HandlingUnitItemDTO {
  cost: number;
  quantity: number;
}

class HandlingUnitsDTO {
  handling_units: {
    expired: HandlingUnitItemDTO;
    risk_of_expiration: HandlingUnitItemDTO;
  };

  constructor({ handling_units }) {
    this.handling_units = handling_units;
  }
}

export { HandlingUnitsDTO };
