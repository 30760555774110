import { createSlice } from '@reduxjs/toolkit';
import {
  NameValues,
  StatusTypes
} from 'modules/inbound/shared/domain/NameValues';
import localeService from 'infrastructure/i18n/LocaleService';
import { SuppliesText } from 'modules/inbound/supplies/infrastructure/i18n/SupplyText';
import { SuppliesTranslations } from 'modules/inbound/supplies/infrastructure/i18n/typing';
import { BarStatus } from '../../layout/pages/types';
import { getSuppliesFilters } from './async';
import { FiltersReducerProps } from './types';
import { CriticalTypes } from 'modules/shared/domain/enums';

const suppliesTranslations: SuppliesTranslations = {
  ...SuppliesText[localeService.getLanguage()]
};

export const initialState: FiltersReducerProps = {
  filters: {
    linesGroup: [],
    warehousesSourceType: [],
    warehousesSource: [],
    manufacturingsSection: [],
    suppliesType: [],
    materialsIndicator: [],
    phases: [],
    lines: [],
    warehousesTarget: []
  },
  requestStatus: '',
  search: '',
  selectedRequestStatus: {
    generatedAt: '',
    fromDateTime: '',
    toDateTime: '',
    searchModality: undefined,
    status: null,
    searchBy: NameValues.CREATED_AT
  },
  selectedClosedStatus: {
    generatedAt: '',
    fromDateTime: '',
    toDateTime: '',
    searchModality: undefined,
    status: null,
    searchBy: NameValues.CONFIRMATION_DATE
  },
  selectedWarehouseRanking: {
    status: null,
    name: ''
  },
  selectedSubzone: {
    status: null,
    name: ''
  },
  selectedDestinationRanking: {
    status: null,
    name: ''
  },
  selectedStatusType: null,
  selectedCriticalStockType: '',
  selectedSubHeaderFilters: {
    selectedLinesGroup: [],
    selectedWarehousesSourceType: [],
    selectedWarehousesSource: [],
    selectedManufacturingsSection: [],
    selectedSuppliesType: [],
    selectedMaterialsIndicator: [],
    selectedPhases: [],
    selectedLines: [],
    selectedWarehousesTarget: []
  },
  blockedSubheaderFilters: {
    warehousesSourceType: false,
    warehousesSource: false,
    warehousesTarget: false,
    phase: false
  },
  isFilterSaved: false
};

const SUPPLIES_FILTERS_STATE_KEY = 'SuppliesFiltersState';

const suppliesFiltersSlice = createSlice({
  name: 'suppliesFilters',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedRequestStatus: (state, action) => {
      state.selectedRequestStatus = {
        ...state.selectedRequestStatus,
        ...action.payload
      };
      state.blockedSubheaderFilters.phase =
        action.payload.status === StatusTypes.CLOSED;
    },
    updateSelectedStatusType: (state, action) => {
      state.selectedStatusType =
        state.selectedStatusType === action.payload ? null : action.payload;
    },
    updateSelectedClosedStatus: (state, action) => {
      state.selectedClosedStatus = {
        ...state.selectedClosedStatus,
        ...action.payload
      };
      state.blockedSubheaderFilters.phase = true;
    },
    updateSelectedSubzoneStatus: (state, action) => {
      state.selectedSubzone = action.payload;
      state.blockedSubheaderFilters.warehousesSourceType = true;
      state.selectedSubHeaderFilters.selectedWarehousesSourceType = [
        {
          key: action.payload.name,
          text: action.payload.name
        }
      ];
    },
    updateSelectedWarehouseStatus: (state, action) => {
      const warehouseSource = action.payload.name;
      state.selectedWarehouseRanking = action.payload;
      state.blockedSubheaderFilters.warehousesSource = true;

      if (warehouseSource !== 'Total') {
        state.selectedSubHeaderFilters.selectedWarehousesSource = [
          {
            key: warehouseSource,
            text: warehouseSource
          }
        ];
      }
    },
    updateSelectedDestinationRanking: (state, action) => {
      const warehouseTarget = action.payload.name;
      state.selectedDestinationRanking = action.payload;
      state.blockedSubheaderFilters.warehousesTarget = true;

      if (warehouseTarget !== 'Total') {
        state.selectedSubHeaderFilters.selectedWarehousesTarget = [
          {
            key: warehouseTarget,
            text: warehouseTarget
          }
        ];
      }
    },
    updateSelectedLinesGroup: (state, action) => {
      state.selectedSubHeaderFilters.selectedLinesGroup = action.payload;
    },
    updateSelectedWarehousesSourceType: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehousesSourceType =
        action.payload;
    },
    updateSelectedWarehousesSource: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehousesSource = action.payload;
    },
    updateSelectedManufacturingsSection: (state, action) => {
      state.selectedSubHeaderFilters.selectedManufacturingsSection =
        action.payload;
    },
    updateSelectedSuppliesType: (state, action) => {
      state.selectedSubHeaderFilters.selectedSuppliesType = action.payload;
    },
    updateSelectedMaterialsIndicator: (state, action) => {
      state.selectedSubHeaderFilters.selectedMaterialsIndicator =
        action.payload;
    },
    updateSelectedPhases: (state, action) => {
      state.selectedSubHeaderFilters.selectedPhases = action.payload;
    },
    updateSelectedCriticalStockType: (state, action) => {
      state.selectedCriticalStockType = action.payload;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
    },
    updateSelectedWarehousesTarget: (state, action) => {
      state.selectedSubHeaderFilters.selectedWarehousesTarget = action.payload;
    },
    resetWarehouseRankingFilters: (state) => {
      state.selectedWarehouseRanking = initialState.selectedWarehouseRanking;
      state.selectedSubzone = initialState.selectedSubzone;
      state.selectedSubHeaderFilters.selectedWarehousesSourceType =
        initialState.selectedSubHeaderFilters.selectedWarehousesSourceType;
      state.selectedSubHeaderFilters.selectedWarehousesSource =
        initialState.selectedSubHeaderFilters.selectedWarehousesSource;
      state.blockedSubheaderFilters.warehousesSourceType = false;
      state.blockedSubheaderFilters.warehousesSource = false;
    },
    resetWarehouseTargetFilters: (state) => {
      state.selectedDestinationRanking =
        initialState.selectedDestinationRanking;
      state.selectedSubHeaderFilters.selectedWarehousesTarget =
        initialState.selectedSubHeaderFilters.selectedWarehousesTarget;
      state.blockedSubheaderFilters.warehousesTarget = false;
    },
    resetRequestStatusChartFilters: (state) => {
      state.selectedRequestStatus = initialState.selectedRequestStatus;
      state.selectedStatusType = initialState.selectedStatusType;
      state.blockedSubheaderFilters.phase = false;
    },
    resetCloseStatusChartFilters: (state) => {
      state.selectedClosedStatus = initialState.selectedClosedStatus;
      state.blockedSubheaderFilters.phase = false;
      state.selectedSubHeaderFilters.selectedPhases =
        initialState.selectedSubHeaderFilters.selectedPhases;
    },
    resetFilters: (state, action) => {
      if (
        state.blockedSubheaderFilters.warehousesSourceType ||
        state.blockedSubheaderFilters.phase
      ) {
        if (state.blockedSubheaderFilters.warehousesSourceType) {
          state.selectedSubHeaderFilters.selectedPhases =
            initialState.selectedSubHeaderFilters.selectedPhases;
        }
        if (state.blockedSubheaderFilters.phase) {
          state.selectedSubHeaderFilters.selectedWarehousesSourceType =
            initialState.selectedSubHeaderFilters.selectedWarehousesSourceType;
        }
        state.selectedSubHeaderFilters.selectedLinesGroup =
          initialState.selectedSubHeaderFilters.selectedLinesGroup;
        state.selectedSubHeaderFilters.selectedMaterialsIndicator =
          initialState.selectedSubHeaderFilters.selectedMaterialsIndicator;
        state.selectedSubHeaderFilters.selectedManufacturingsSection =
          initialState.selectedSubHeaderFilters.selectedManufacturingsSection;
        state.selectedSubHeaderFilters.selectedSuppliesType =
          initialState.selectedSubHeaderFilters.selectedSuppliesType;
        state.selectedSubHeaderFilters.selectedWarehousesSource =
          initialState.selectedSubHeaderFilters.selectedWarehousesSource;
        state.selectedSubHeaderFilters.selectedLines =
          initialState.selectedSubHeaderFilters.selectedLines;
        state.selectedSubHeaderFilters.selectedWarehousesTarget =
          initialState.selectedSubHeaderFilters.selectedWarehousesTarget;
      } else {
        state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      }
      localStorage.removeItem(
        `${action.payload}-${SUPPLIES_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${SUPPLIES_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = !!localStorage.getItem(
        `${action.payload}-${SUPPLIES_FILTERS_STATE_KEY}`
      );
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${SUPPLIES_FILTERS_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        state.selectedSubHeaderFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;
        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSuppliesFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuppliesFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuppliesFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) => state.suppliesFiltersState.filters;

/** Selected filters */
export const getFilterSearch = (state) => state.suppliesFiltersState.search;
export const getSelectedRequestStatus = (state) =>
  state.suppliesFiltersState.selectedRequestStatus;
export const getSelectedRequestStatusUI = (state) => {
  return {
    top: state.suppliesFiltersState.selectedRequestStatus.generatedAt,
    color: BarStatus[state.suppliesFiltersState.selectedRequestStatus.status]
  };
};
export const getSelectedStatusType = (state) =>
  state.suppliesFiltersState.selectedStatusType;
export const getSelectedClosedStatus = (state) =>
  state.suppliesFiltersState.selectedClosedStatus;
export const getSelectedClosedStatusUI = (state) => {
  return {
    top: state.suppliesFiltersState.selectedClosedStatus.generatedAt,
    color: BarStatus[state.suppliesFiltersState.selectedClosedStatus.status]
  };
};
export const getSelectedWarehouseStatusUI = (state) => {
  return {
    left: state.suppliesFiltersState.selectedWarehouseRanking.name,
    color: BarStatus[state.suppliesFiltersState.selectedWarehouseRanking.status]
  };
};
export const getSelectedSubzoneStatusUI = (state) => {
  return {
    left: state.suppliesFiltersState.selectedSubzone.name,
    color: BarStatus[state.suppliesFiltersState.selectedSubzone.status]
  };
};

export const getSelectedDestinationStatusUI = (state) => {
  return {
    left: state.suppliesFiltersState.selectedDestinationRanking.name,
    color:
      BarStatus[state.suppliesFiltersState.selectedDestinationRanking.status]
  };
};

export const getSelectedWarehouseRanking = (state) =>
  state.suppliesFiltersState.selectedWarehouseRanking;
export const getSelectedSubzoneRanking = (state) =>
  state.suppliesFiltersState.selectedSubzone;
export const getSelectedDestinationRanking = (state) =>
  state.suppliesFiltersState.selectedDestinationRanking;
export const getSelectedWarehousesSourceType = (state) =>
  state.suppliesFiltersState.selectedSubHeaderFilters
    .selectedWarehousesSourceType;
export const getIsChartFilterActive = (state) =>
  state.suppliesFiltersState.filtersActive;
export const getSelectedFilters = (state) =>
  state.suppliesFiltersState.selectedSubHeaderFilters;
export const getBlockedFilters = (state) =>
  state.suppliesFiltersState.blockedSubheaderFilters;
export const getIsFilterSaved = (state) =>
  state.suppliesFiltersState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.suppliesFiltersState.selectedSubHeaderFilters).some(
    (key) => state.suppliesFiltersState.selectedSubHeaderFilters[key].length
  );
export const getSelectedCriticalStockTypeUI = (state) => {
  const selectedCriticalStock =
    state.suppliesFiltersState.selectedCriticalStockType;
  return selectedCriticalStock
    ? suppliesTranslations[CriticalTypes[selectedCriticalStock]]
    : '';
};
export const getSelectedCriticalStockType = (state) =>
  state.suppliesFiltersState.selectedCriticalStockType;

export const {
  updateFilterSearch,
  updateSelectedLinesGroup,
  updateSelectedWarehousesSourceType,
  updateSelectedWarehousesSource,
  updateSelectedManufacturingsSection,
  updateSelectedSuppliesType,
  updateSelectedMaterialsIndicator,
  updateSelectedPhases,
  updateSelectedRequestStatus,
  updateSelectedStatusType,
  updateSelectedClosedStatus,
  updateSelectedSubzoneStatus,
  updateSelectedWarehouseStatus,
  updateSelectedDestinationRanking,
  updateSelectedCriticalStockType,
  updateSelectedLines,
  updateSelectedWarehousesTarget,
  resetWarehouseRankingFilters,
  resetWarehouseTargetFilters,
  resetRequestStatusChartFilters,
  resetCloseStatusChartFilters,
  resetFilters,
  loadFilters,
  saveFilters
} = suppliesFiltersSlice.actions;

export default suppliesFiltersSlice;
