class UploadProviderCodesResponseDTO {
  statusCode: number;
  errorCode: number;
  message: string;
  providerIds?: number[];

  constructor({ statusCode, errorCode, message, providerIds }) {
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.message = message;
    this.providerIds = providerIds;
  }
}

export default UploadProviderCodesResponseDTO;
