import { IFinishedGoods } from 'modules/outbound/fulfillment/domain/FinishedGoods';
import { ITimelineItem } from 'modules/outbound/fulfillment/domain/FinishedGoodCheckpointsTimeline';
import FinishedGoodTransportDetails from '../../domain/FinishedGoodTransportDetails';

export interface FinishedGoodTransportDetailsAndVin
  extends FinishedGoodTransportDetails {
  vin: string;
}
export interface FinishedGoodsByCheckpointState {
  finishedGoods: IFinishedGoods[];
  totalFinishedGoods: number;
  checkpointRef: string;
  countryCode: string;
  selectedVin: string;
  timelineDetailsSelected: boolean;
  transportDetailsSelected: boolean;
  transportDetails: FinishedGoodTransportDetailsAndVin;
  transportDetailsError: string;
  checkpoints: {
    vin: string;
    actualTimeline: ITimelineItem[];
    expectedTimeline: ITimelineItem[];
    actualTimelineTotalDays: number;
    expectedTimelineTotalDays: number;
  };
  filters: {
    page: number;
    resultsPerPage: number;
  };
  requestStatus: string;
  detailedCheckpointsRequest: string;
  detailedTransportRequest: string;
}

export enum TimelineSectionColors {
  factoryWarehouseInput = 'yellow',
  factoryWarehouseOutput = 'orange',
  originPortInput = 'red',
  originPortOutput = 'green',
  transitPortInput = 'purple',
  transitPortOutput = 'lightBlue',
  destinationPortInput = 'blue',
  destinationPortOutput = 'extraBlack',
  redistributionWarehouseInput = 'black50',
  redistributionWarehouseOutput = 'fill',
  dealerInput = 'white',
  default = 'darkGray'
}

export enum FinishedGoodsByCheckpointSort {
  asc = 'asc',
  desc = 'desc'
}

export enum FinishedGoodsByCheckpointSortTypes {
  factoryWarehouseInputDate = 'factoryWarehouseInputDate'
}
