import { FlowTypes } from './JitFlowTypesDTO';

interface IdAndNameDTO {
  id: number;
  name: string;
}

type IProviderDTO = IdAndNameDTO;
type ISequencerDTO = IdAndNameDTO;
export interface FamiliesCoverageFiltersDTO {
  families: string[];
  lines: number[];
  tier_1_provider_ids: IProviderDTO[];
  logistic_operators_ids: ISequencerDTO[];
  flow_types: FlowTypes;
  inbounders: string[];
}

class FamiliesCoverageFiltersDataDTO {
  families: string[];
  lines: number[];
  tier_1_provider_ids: IProviderDTO[];
  logistic_operators_ids: ISequencerDTO[];
  flow_types: FlowTypes;
  inbounders: string[];

  constructor({
    lines,
    tier_1_provider_ids,
    logistic_operators_ids,
    flow_types,
    inbounders,
    families
  }) {
    this.lines = lines;
    this.tier_1_provider_ids = tier_1_provider_ids;
    this.logistic_operators_ids = logistic_operators_ids;
    this.flow_types = flow_types || [];
    this.inbounders = inbounders;
    this.families = families;
  }
}

export default FamiliesCoverageFiltersDataDTO;
