import { useEffect, useState } from 'react';

export type FormInputType = 'password' | 'text' | 'number' | 'email';

export interface UseFieldProps {
  type: FormInputType;
  defaultValue?: string;
  validation?: {
    validate(value: string): boolean;
    errorMessage: string;
  };
}

export const useField = ({ type, defaultValue, validation }: UseFieldProps) => {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onChange = (event) => {
    setValue(event.target.value);

    if (validation && !validation.validate(event.target.value)) {
      setError(validation.errorMessage);
    } else {
      setError('');
    }
  };

  const reset = () => {
    setValue('');
  };

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return {
    type,
    value,
    error,
    onChange,
    reset
  };
};
