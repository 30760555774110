import { createAsyncThunk } from '@reduxjs/toolkit';
import getWarehouseForecastByModelService from 'modules/outbound/stock/services/GetWarehouseForecastByModelService';
import { getFastLaneSelected, getSelected } from '../WarehouseStockReducer';

const getWarehouseForecastByModel = createAsyncThunk(
  `warehouseForecastByModel/getWarehouseForecastByModel`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selected = getSelected(appState);
    const fastLaneSelected = getFastLaneSelected(appState);

    const queryParams = { fastLane: fastLaneSelected };
    const response = await getWarehouseForecastByModelService.get({
      warehouseReference: selected,
      queryParams
    });
    return response;
  }
);

export { getWarehouseForecastByModel };
