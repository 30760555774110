import { createSlice } from '@reduxjs/toolkit';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { TransitsHistoryState } from './types';
import { downloadTransitsHistory } from './async';

export const initialState: TransitsHistoryState = {
  transitsHistoryFile: null,
  downloadRequestStatus: ''
};

const transitsHistorySlice = createSlice({
  name: 'transitsHistoryData',
  initialState,
  reducers: {
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(downloadTransitsHistory.fulfilled, (state, action) => {
      state.transitsHistoryFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'Transits_History'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadTransitsHistory.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadTransitsHistory.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getDownloadTransitsHistorySuccessful = (state) =>
  state.transitsHistoryState.downloadRequestStatus === 'fulfilled' &&
  state.transitsHistoryState.transitsHistoryFile !== null;
export const getTransitsHistoryError = (state) =>
  state.transitsHistoryState.downloadRequestStatus === 'rejected';
export const getDownloadTransitsHistoryIsPending = (state) =>
  state.transitsHistoryState.downloadRequestStatus === 'pending';
export const getDownloadTransitsHistoryFile = (state) =>
  state.transitsHistoryState.transitsHistoryFile;

export const { resetDownloadStatus } = transitsHistorySlice.actions;
export default transitsHistorySlice.reducer;
