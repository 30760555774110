import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface ITransferStockDTO {
  last_update: string;
  stock_location_item: ITransferStockItemDTO[];
  pagination: IPaginationDTO;
}

export interface ITransferStockItemDTO {
  code: string;
  description: string;
  location: string;
  warehouse: string;
  warehouse_type: string;
  handling_unit: string | null;
  factory_coverage: number;
  supply_container: string;
  transport_container: string;
  basic_container: string;
  critical_stock_type: string | null;
  coverage_without_stock: number;
  supply_type: string;
  container_type: string | null;
  packaging_material: string | null;
  is_planned: boolean;
}

class TransferStockDTO implements ITransferStockDTO {
  last_update: string;
  stock_location_item: ITransferStockItemDTO[];
  pagination: IPaginationDTO;

  constructor({ stock_location_item, pagination, last_update }) {
    this.stock_location_item = stock_location_item;
    this.pagination = pagination;
    this.last_update = last_update;
  }
}

export default TransferStockDTO;
