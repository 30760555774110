import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

const Tag = styled.div<{
  success?: boolean;
  error?: boolean;
  warning?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.fonts.regularExtraSmall.minSizeAsPixels};
  border-radius: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.borders.small};
  color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.black.rgb};
  background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.colors.mediumGray.rgb};

  /** success styles */
  color: ${(props: { theme: ThemeSEATProps; success?: boolean }) =>
    props.success && props.theme.colors.green900.rgb};
  background-color: ${(props: { theme: ThemeSEATProps; success?: boolean }) =>
    props.success && props.theme.colors.green50.rgb};

  /** error styles */
  color: ${(props: { theme: ThemeSEATProps; error?: boolean }) =>
    props.error && props.theme.colors.red700.rgb};
  background-color: ${(props: { theme: ThemeSEATProps; error?: boolean }) =>
    props.error && props.theme.colors.red50.rgb};

  /** warning styles */
  color: ${(props: { theme: ThemeSEATProps; warning?: boolean }) =>
    props.warning && props.theme.colors.yellow900.rgb};
  background-color: ${(props: { theme: ThemeSEATProps; warning?: boolean }) =>
    props.warning && props.theme.colors.yellow50.rgb};
`;

export { Tag };
