import { IPipelineDetailedCarInfoDTO } from '../infrastructure/dto/PipelineDetailedCarInfoDTO';
import { IAgingIntervalsDTO } from '../infrastructure/dto/PipelineStockDTO';
import { IAgingIntervals } from './PipelineStock';
import PipelineStockBase from './PipelineStockBase';

export interface IPipelineDetailedCarInfo {
  totalCars: number;
  invoicedAging: IAgingByCompound[];
  inTransitAging: IAgingByCompound[];
}

interface IAgingByCompound {
  compoundName: string;
  intervals: IAgingIntervals;
}

class PipelineDetailedCarInfo
  extends PipelineStockBase
  implements IPipelineDetailedCarInfo
{
  totalCars: number;
  invoicedAging: IAgingByCompound[];
  inTransitAging: IAgingByCompound[];

  constructor({
    totalCars,
    invoicedAging,
    inTransitAging
  }: PipelineDetailedCarInfo) {
    super();
    this.totalCars = totalCars;
    this.invoicedAging = invoicedAging;
    this.inTransitAging = inTransitAging;
  }

  static generateFromDTO({
    totalCars,
    invoicedAging,
    inTransitAging
  }: IPipelineDetailedCarInfoDTO): PipelineDetailedCarInfo {
    return new PipelineDetailedCarInfo({
      totalCars,
      invoicedAging: invoicedAging.map((compoundAging) => ({
        ...compoundAging,
        intervals: {
          first: {
            value: compoundAging.intervals['0to90'],
            ...this.getPercentageAndStatus(
              'first',
              compoundAging.intervals['0to90'],
              compoundAging.intervals
            )
          },
          second: {
            value: compoundAging.intervals['91to120'],
            ...this.getPercentageAndStatus(
              'second',
              compoundAging.intervals['91to120'],
              compoundAging.intervals
            )
          },
          third: {
            value: compoundAging.intervals['121to180'],
            ...this.getPercentageAndStatus(
              'third',
              compoundAging.intervals['121to180'],
              compoundAging.intervals
            )
          },
          fourth: {
            value: compoundAging.intervals.moreThan180,
            ...this.getPercentageAndStatus(
              'fourth',
              compoundAging.intervals.moreThan180,
              compoundAging.intervals
            )
          },
          total: {
            value: this.getIntervalsSum(compoundAging.intervals),
            percentage: 100,
            status: { valid: true, color: 'white' }
          }
        }
      })),
      inTransitAging: inTransitAging.map((compoundAging) => ({
        ...compoundAging,
        intervals: {
          first: {
            value: compoundAging.intervals['0to90'],
            ...this.getPercentageAndStatus(
              'first',
              compoundAging.intervals['0to90'],
              compoundAging.intervals
            )
          },
          second: {
            value: compoundAging.intervals['91to120'],
            ...this.getPercentageAndStatus(
              'second',
              compoundAging.intervals['91to120'],
              compoundAging.intervals
            )
          },
          third: {
            value: compoundAging.intervals['121to180'],
            ...this.getPercentageAndStatus(
              'third',
              compoundAging.intervals['121to180'],
              compoundAging.intervals
            )
          },
          fourth: {
            value: compoundAging.intervals.moreThan180,
            ...this.getPercentageAndStatus(
              'fourth',
              compoundAging.intervals.moreThan180,
              compoundAging.intervals
            )
          },
          total: {
            value: this.getIntervalsSum(compoundAging.intervals),
            percentage: 100,
            status: { valid: true, color: 'white' }
          }
        }
      }))
    });
  }

  static generateEmpty() {
    return new PipelineDetailedCarInfo({
      totalCars: 0,
      inTransitAging: [],
      invoicedAging: []
    });
  }

  private static getIntervalsSum(intervals) {
    return Number(
      Object.keys(intervals).reduce((acumulator, currentvalue) => {
        return acumulator + intervals[currentvalue];
      }, 0)
    );
  }

  private static getPercentageAndStatus(
    key: string,
    value: number,
    intervals: IAgingIntervalsDTO
  ) {
    const total = this.getIntervalsSum(intervals);
    const percentage = Math.round((value / total) * 100);
    const isValid = percentage <= this.stockObjectives[key].percentage;
    const color = this.stockObjectivesWarningColors[key].color;
    return { percentage, status: { valid: isValid, color } };
  }
}

export default PipelineDetailedCarInfo;
