import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface LineGroupsItemDTO {
  id: number;
  s_fab: number;
  g_lin: string;
  description_g_lin: string;
  line: string;
  warehouse: string;
  created_at: string;
  updated_at: string;
}

class LineGroupsDataDTO {
  last_update: string;
  data: LineGroupsItemDTO[];
  pagination: IPaginationDTO;

  constructor({ last_update, data, pagination }) {
    this.last_update = last_update;
    this.data = data;
    this.pagination = pagination;
  }
}

export default LineGroupsDataDTO;
