import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import {
  ExpirationDataItemDTO,
  ExpirationStatusType
} from '../infrastructure/dto/expirationsDataDTOMapper';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};
export interface Expiration {
  uid: string;
  expirationStatus: ExpirationStatusType;
  code: string;
  name: string;
  handlingUnit: string;
  quantity: number;
  warehouse: string;
  expirationDate: string;
  measurementUnit: string;
  stockCost: string;
  warehouseType: string;
  location: string;
  stockDifferentiation: string;
  models: string;
  daysForExpiration: number;
  inbounders: string;
  providerNames: string[];
  supplyPoints: number | string;
  coverage: string;
  providerDeliveryNote: string;
  providerDeliveryNoteDate: string;
}

class ExpirationData implements Expiration {
  uid: string;
  expirationStatus: ExpirationStatusType;
  code: string;
  name: string;
  handlingUnit: string;
  quantity: number;
  warehouse: string;
  expirationDate: string;
  measurementUnit: string;
  stockCost: string;
  warehouseType: string;
  location: string;
  stockDifferentiation: string;
  models: string;
  daysForExpiration: number;
  inbounders: string;
  providerNames: string[];
  supplyPoints: number | string;
  coverage: string;
  providerDeliveryNote: string;
  providerDeliveryNoteDate: string;

  constructor({
    uid,
    expirationStatus,
    code,
    name,
    handlingUnit,
    quantity,
    warehouse,
    expirationDate,
    measurementUnit,
    stockCost,
    warehouseType,
    location,
    stockDifferentiation,
    models,
    daysForExpiration,
    inbounders,
    providerNames,
    supplyPoints,
    coverage,
    providerDeliveryNote,
    providerDeliveryNoteDate
  }: Expiration) {
    this.uid = uid;
    this.expirationStatus = expirationStatus;
    this.code = code;
    this.name = name;
    this.handlingUnit = handlingUnit;
    this.quantity = quantity;
    this.warehouse = warehouse;
    this.expirationDate = expirationDate;
    this.measurementUnit = measurementUnit;
    this.stockCost = stockCost;
    this.warehouseType = warehouseType;
    this.location = location;
    this.stockDifferentiation = stockDifferentiation;
    this.models = models;
    this.daysForExpiration = daysForExpiration;
    this.inbounders = inbounders;
    this.providerNames = providerNames;
    this.supplyPoints = supplyPoints;
    this.coverage = coverage;
    this.providerDeliveryNote = providerDeliveryNote;
    this.providerDeliveryNoteDate = providerDeliveryNoteDate;
  }

  private static _formatThousandsDotEuros(quantity) {
    const numberWithDots = quantity.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    return numberWithDots.replace(',00', '');
  }

  private static _formatThousandsDotDays(quantity) {
    if (quantity === null) {
      return '-';
    }
    const numberWithDots = quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });

    if (numberWithDots === '1') {
      return `${numberWithDots} ${inboundTranslations.unitSingleDay}`;
    }
    return `${numberWithDots} ${inboundTranslations.unitMultipleDays}`;
  }

  private static _formatThousandsDot(quantity) {
    return quantity.toLocaleString('de-DE');
  }

  private static _orderProvidersByAssignement(providers) {
    return providers.sort(
      (a, b) => b.percentage_assignement - a.percentage_assignement
    );
  }

  static generateFromDto({
    uid,
    expiration_status,
    code,
    name,
    handling_unit,
    quantity,
    warehouse,
    expiration_date,
    measurement_unit,
    stock_cost,
    warehouse_type,
    location,
    stock_differentiation,
    models,
    days_for_expiration,
    inbounder,
    providers,
    supply_points,
    coverage,
    provider_delivery_note
  }: ExpirationDataItemDTO) {
    return new ExpirationData({
      uid,
      expirationStatus: expiration_status,
      code,
      name: name || '-',
      handlingUnit: handling_unit,
      quantity: this._formatThousandsDot(quantity),
      warehouse,
      expirationDate: GetDateFormatedService.getDateFormated(
        expiration_date,
        'ES'
      ),
      measurementUnit: measurement_unit,
      stockCost:
        stock_cost !== null ? this._formatThousandsDotEuros(stock_cost) : '-',
      warehouseType: warehouse_type,
      location,
      stockDifferentiation: stock_differentiation.length
        ? stock_differentiation
        : '-',
      models: models.join(' | ') || '-',
      daysForExpiration: this._formatThousandsDot(days_for_expiration),
      inbounders: inbounder ?? '-',
      providerNames: this._orderProvidersByAssignement(providers).map(
        (provider) => `${provider.name} - ${provider.percentage_assignement}`
      ),
      supplyPoints: supply_points ?? '-',
      coverage: this._formatThousandsDotDays(coverage),
      providerDeliveryNote: provider_delivery_note.code,
      providerDeliveryNoteDate: provider_delivery_note.date
        ? `${GetDateFormatedService.getDateFormated(
            provider_delivery_note.date,
            'ES'
          )}`
        : '-'
    });
  }
}

export { ExpirationData };
