import LocaleService from 'infrastructure/i18n/LocaleService';
import { NoJitTranslations } from '../infrastructure/i18n/typing';
import { NoJitText } from '../infrastructure/i18n/NoJitText';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';

const noJitTranslations: NoJitTranslations = {
  ...NoJitText[LocaleService.getLanguage()]
};

export enum PlanningOptions {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS'
}

export const TEMPORAL_HORIZON_SHORT_TERM = {
  key: PlanningOptions.DAYS,
  text: noJitTranslations.shortTermExtended,
  placeholder: noJitTranslations.shortTerm
};

export const TEMPORAL_HORIZON_LONG_TERM = {
  key: PlanningOptions.WEEKS,
  text: noJitTranslations.longTermExtended,
  placeholder: noJitTranslations.longTerm
};

export const TEMPORAL_HORIZON_ITEMS: SelectItemProps[] = [
  TEMPORAL_HORIZON_SHORT_TERM,
  TEMPORAL_HORIZON_LONG_TERM
];
