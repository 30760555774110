import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import {
  IEntriesInPlantDataDTO,
  IEntriesInPlantDataItemDTO,
  ParalyzationStateType
} from '../../infrastructure/dto/InPlantDTO/EntriesInPlantDataDTO';
import { EntriesText } from '../../infrastructure/i18n/EntriesText';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { EntriesTranslations } from '../../infrastructure/i18n/typing';
import { PieceAlert } from '../types';
import {
  ENTRIES_IN_PLANT_COLUMNS,
  EntriesInPlantColumns
} from './EntriesInPlantColumns';

const entriesTranslations: EntriesTranslations & InboundTranslations = {
  ...EntriesText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export interface IEntriesInPlantData {
  entries: IEntriesInPlantItem[];
  columns: EntriesInPlantColumns[];
}

export interface GlobalDlzInfo {
  globalDlzTime: string;
  paralyzationState: ParalyzationStateType;
  paralyzationTime: string;
  paralyzationRight: boolean;
}

export interface IEntriesInPlantItem {
  plateNumber: string;
  transportId: number | string;
  carrier: string;
  windowBooked: string;
  entryTime: string;
  stops: string;
  lastPosition: number | string;
  mostCriticalPieceCoverage: string;
  packages: number | string;
  globalDlz: GlobalDlzInfo;
  pieceAlert: PieceAlert;
  stopType: string;
  supplierName: string;
}

class EntriesInPlantData implements IEntriesInPlantData {
  entries: IEntriesInPlantItem[];
  columns: EntriesInPlantColumns[];
  pagination: IPagination;

  constructor({ entries, columns, pagination }: EntriesInPlantData) {
    this.entries = entries;
    this.columns = columns;
    this.pagination = pagination;
  }

  private static _formatThousandsDotDays(quantity) {
    if (quantity === null) {
      return '-';
    }
    const numberWithDots = quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });
    if (numberWithDots === '1') {
      return `${numberWithDots} ${entriesTranslations.unitSingleDay}`;
    }
    return `${numberWithDots} ${entriesTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    entries,
    pagination
  }: IEntriesInPlantDataDTO): EntriesInPlantData {
    return new EntriesInPlantData({
      entries: entries.map(
        ({
          plate_number,
          stop_type,
          transport_id,
          carrier,
          supplier,
          window_booked,
          entry_time,
          stops,
          last_position,
          stops_completed,
          most_critical_piece_coverage,
          packages,
          global_dlz,
          paralyzation_state,
          paralyzation_time,
          paralyzation_right,
          urgent,
          has_critical_pieces,
          contains_critical_pieces,
          contains_serious_fault
        }: IEntriesInPlantDataItemDTO): IEntriesInPlantItem => ({
          plateNumber: plate_number,
          stopType: stop_type.split('_').join(' / '),
          transportId: transport_id || '-',
          carrier,
          supplierName: supplier,
          windowBooked: window_booked
            ? GetDateFormatedService.getShortDateTime(window_booked)
            : '-',
          entryTime: entry_time
            ? GetDateFormatedService.getShortDateTime(entry_time)
            : '-',
          globalDlz: {
            globalDlzTime:
              GetDateFormatedService.formatSecondsToMinutes(global_dlz),
            paralyzationTime:
              GetDateFormatedService.formatSecondsToMinutes(paralyzation_time),
            paralyzationState: paralyzation_state,
            paralyzationRight: !!paralyzation_right
          },
          stops: `${stops_completed}/${stops}`,
          lastPosition: last_position || '-',
          mostCriticalPieceCoverage: this._formatThousandsDotDays(
            most_critical_piece_coverage
          ),
          packages: packages
            ? packages.toLocaleString('de-DE', {
                maximumFractionDigits: 2
              })
            : '-',
          pieceAlert: {
            urgent: !!urgent,
            hasCriticalPieces: has_critical_pieces,
            containsCriticalPieces: contains_critical_pieces,
            containsSeriousFault: contains_serious_fault
          }
        })
      ),
      columns: ENTRIES_IN_PLANT_COLUMNS,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty() {
    return new EntriesInPlantData({
      entries: [],
      columns: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default EntriesInPlantData;
