export class SaturationUnloadingPointsFiltersDTO {
  carrier: string[];
  supplier: string[];
  unloading_point: string[];
  warehouse: string[];

  constructor({ carrier, supplier, unloading_point, warehouse }) {
    this.carrier = carrier;
    this.supplier = supplier;
    this.unloading_point = unloading_point;
    this.warehouse = warehouse;
  }
}
