import { createSlice } from '@reduxjs/toolkit';
import {
  getEntriesData,
  getEntryDetailData,
  getUrgentIncomingTrucks,
  getEntriesSummaryData,
  getEntriesNextHoursLastUpdateData,
  downloadEntries
} from './async';
import EntryDetail from 'modules/inbound/entries/domain/NextHours/EntriesDetail';
import { EntriesDataState, SelectedSubHeaderFilters } from './types';
import { ENTRIES_NEXT_HOURS_COLUMNS } from 'modules/inbound/entries/domain/NextHours/EntriesNextHoursColumns';
import { EntriesSummary } from 'modules/inbound/entries/domain/NextHours/entriesSummaryMapper';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { getLocalStorage } from '../../../../../shared/utils/getLocalStorage';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';

export const initialState: EntriesDataState = {
  entries: [],
  columns: ENTRIES_NEXT_HOURS_COLUMNS,
  urgentTrucks: {
    total: 0,
    entries: []
  },
  savedColumns: [],
  entriesRequestStatus: '',
  urgentTrucksRequestStatus: '',
  lastUpdate: '-',
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: '',
    isAscending: false
  },
  filters: {
    selectedSubHeaderFilters: {
      selectedWarehouses: [],
      selectedSuppliers: [],
      selectedUnloadingPoints: []
    },
    search: '',
    urgentTrucks: false,
    criticalPieces: false
  },
  isFilterSaved: false,
  selectedEntry: null,
  entryDetailRequestStatus: '',
  entryDetail: EntryDetail.generateEmpty(),
  summary: {
    hasCriticalPieces: 0,
    urgents: 0
  },
  entriesFile: null,
  downloadRequestStatus: ''
};

export const ENTRIES_FILTERS_STATE_KEY = 'EntriesFiltersState';
const ENTRIES_NEXT_HOURS_COLUMNS_VIEW_STATE_KEY = 'EntriesColumnsView';

const entriesSlice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateFilterSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    updateSelectedWarehouses: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedWarehouses =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedUnloadingPoints: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedUnloadingPoints =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedSuppliers: (state, action) => {
      state.filters.selectedSubHeaderFilters.selectedSuppliers = action.payload;
      state.isFilterSaved = false;
    },
    updateFilterByUrgentrucks: (state) => {
      state.filters.urgentTrucks = !state.filters.urgentTrucks;
    },
    updateFilterByCriticalPieces: (state) => {
      state.filters.criticalPieces = !state.filters.criticalPieces;
    },
    resetFilters: (state, action) => {
      state.filters.selectedSubHeaderFilters =
        initialState.filters.selectedSubHeaderFilters;
      localStorage.removeItem(`${action.payload}-${ENTRIES_FILTERS_STATE_KEY}`);
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${ENTRIES_FILTERS_STATE_KEY}`,
        JSON.stringify(state.filters.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const localStoragefilters = getLocalStorage<SelectedSubHeaderFilters>(
        `${action.payload}-${ENTRIES_FILTERS_STATE_KEY}`,
        { ...state.filters.selectedSubHeaderFilters }
      );

      state.filters.selectedSubHeaderFilters = {
        selectedSuppliers: localStoragefilters.selectedSuppliers || [],
        selectedUnloadingPoints:
          localStoragefilters.selectedUnloadingPoints || [],
        selectedWarehouses: localStoragefilters.selectedWarehouses || []
      };
      state.isFilterSaved = Object.keys(localStoragefilters).some(
        (key) => localStoragefilters[key].length
      );
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${ENTRIES_NEXT_HOURS_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: ENTRIES_NEXT_HOURS_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateSelectedEntry: (state, action) => {
      state.selectedEntry = action.payload;
    },
    updateColumnsOrder: (state, action) => {
      state.savedColumns = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntriesData.fulfilled, (state, action) => {
      state.entries = action.payload.entries || [];
      state.entriesRequestStatus = action.meta.requestStatus;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getEntriesData.pending, (state, action) => {
      state.entriesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesData.rejected, (state, action) => {
      state.entriesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getEntriesNextHoursLastUpdateData.fulfilled,
      (state, action) => {
        state.lastUpdate = action.payload.lastUpdate;
      }
    );
    builder.addCase(getEntryDetailData.fulfilled, (state, action) => {
      state.entryDetail = action.payload;
      state.entryDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntryDetailData.pending, (state, action) => {
      state.entryDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntryDetailData.rejected, (state, action) => {
      state.entryDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUrgentIncomingTrucks.fulfilled, (state, action) => {
      state.urgentTrucks = action.payload;
      state.urgentTrucksRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUrgentIncomingTrucks.pending, (state, action) => {
      state.urgentTrucksRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUrgentIncomingTrucks.rejected, (state, action) => {
      state.urgentTrucksRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesSummaryData.fulfilled, (state, action) => {
      state.summary = action.payload;
    });
    builder.addCase(downloadEntries.fulfilled, (state, action) => {
      state.entriesFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_Next_24_hours_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEntries.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEntries.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getEntries = (state) => state.entriesDataState.entries;
export const getColumns = (state) => state.entriesDataState.savedColumns;
export const getPageInfo = (state) => state.entriesDataState.pagination;
export const getIncomingEntriesLastUpdate = (state) =>
  state.entriesDataState.lastUpdate;
export const getUrgentTrucks = (state) => state.entriesDataState.urgentTrucks;

export const getEntriesDataIsPending = (state) =>
  state.entriesDataState.entriesRequestStatus === 'pending';
export const getEntriesDataHasError = (state) =>
  state.entriesDataState.entriesRequestStatus === 'rejected';
export const getIsPendingUrgentTrucks = (state) =>
  state.entriesDataState.urgentTrucksRequestStatus === 'pending';
export const getHasErrorUrgentTrucks = (state) =>
  state.entriesDataState.urgentTrucksRequestStatus === 'rejected';
export const getSelectedColumn = (state) =>
  state.entriesDataState.selectedColumn;
export const getFilterSearch = (state) => state.entriesDataState.filters.search;
export const getFilterByUrgentTrucks = (state) =>
  state.entriesDataState.filters.urgentTrucks;
export const getFilterByCriticalPieces = (state) =>
  state.entriesDataState.filters.criticalPieces;
export const getSelectedFilters = (state) =>
  state.entriesDataState.filters.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) => state.entriesDataState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.entriesDataState.filters.selectedSubHeaderFilters).some(
    (key) => state.entriesDataState.filters.selectedSubHeaderFilters[key].length
  );
export const getSelectedEntry = (state) => state.entriesDataState.selectedEntry;
export const getEntryDetailIsPending = (state) =>
  state.entriesDataState.entryDetailRequestStatus === 'pending';
export const getEntryDetailHasError = (state) =>
  state.entriesDataState.entryDetailRequestStatus === 'rejected';
export const getEntryDetails = (state) => state.entriesDataState.entryDetail;
export const getEntriesSummary = (state): EntriesSummary =>
  state.entriesDataState.summary;
export const getDownloadEntriesIsPending = (state) =>
  state.entriesDataState.downloadRequestStatus === 'pending';
export const getDownloadEntriesSuccessful = (state) =>
  state.entriesDataState.downloadRequestStatus === 'fulfilled' &&
  state.entriesDataState.entriesFile !== null;
export const getDownloadEntriesError = (state) =>
  state.entriesDataState.downloadRequestStatus === 'rejected';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateFilterSearch,
  updateFilterByUrgentrucks,
  updateFilterByCriticalPieces,
  updateSelectedWarehouses,
  updateSelectedSuppliers,
  updateSelectedUnloadingPoints,
  updateSelectedEntry,
  resetFilters,
  loadFilters,
  saveFilters,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  updateColumnsOrder,
  resetDownloadStatus
} = entriesSlice.actions;
export default entriesSlice.reducer;
