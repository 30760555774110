import { RefillsStatusType, SearchModality } from './types';

export interface StatusItem {
  status: RefillsStatusType;
  counter: number;
}

export interface RefillStatusDTO {
  from_datetime: string;
  to_datetime: string;
  search_modality: SearchModality;
  stats: StatusItem[];
  total: number;
}

class RefillsStatusesDTO {
  snapshots: RefillStatusDTO[];

  constructor({ snapshots }) {
    this.snapshots = snapshots;
  }
}

export default RefillsStatusesDTO;
