import ISuppliesRepository from '../domain/ISuppliesRepository';
import { warehouseRankingMapper } from '../domain/warehouseRankingMapper';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetWarehousesRankingService {
  private _suppliesRepository: ISuppliesRepository;

  constructor({ suppliesRepository }) {
    this._suppliesRepository = suppliesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._suppliesRepository
      .getWarehousesOriginRanking({ queryParams })
      .then((res) => warehouseRankingMapper(res));
  }
}

export default new GetWarehousesRankingService({
  suppliesRepository: suppliesRepository
});

export { GetWarehousesRankingService };
