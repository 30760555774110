export enum StatusTypeEnum {
  OK = 'OK',
  NO_OK = 'NO OK',
  NONE = 'NONE'
}
export type StatusTypes = [
  StatusTypeEnum.NONE,
  StatusTypeEnum.OK,
  StatusTypeEnum.NO_OK
];

export interface DirectDeliveryFiltersDTO {
  warehouse_codes: string[];
  lines: number[];
  provider_ids: number[];
  models: string[];
  status: StatusTypes;
}

class DirectDeliveryDataDTO {
  warehouse_codes: string[];
  lines: number[];
  provider_ids: number[];
  models: string[];
  status: StatusTypes;

  constructor({ warehouse_codes, lines, provider_ids, models, status }) {
    this.warehouse_codes = warehouse_codes;
    this.lines = lines;
    this.provider_ids = provider_ids;
    this.models = models;
    this.status = status;
  }
}

export default DirectDeliveryDataDTO;
