import localeService from 'infrastructure/i18n/LocaleService';
import { CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';
import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

export interface ITransitFulfillmentCheckpoint {
  id: string;
  name: string;
  percentage: number;
  type: CheckpointType;
}

interface GenerateFromDTOParams {
  name: string;
  percentage: number;
  type: CheckpointType;
}

class TransitFulfillmentCheckpoint implements ITransitFulfillmentCheckpoint {
  id: string;
  name: string;
  percentage: number;
  type: CheckpointType;

  constructor({ id, name, type, percentage }: ITransitFulfillmentCheckpoint) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.percentage = percentage;
  }

  static getImageSrcFromCountryName(countryName: string) {
    return `${process.env.PUBLIC_URL}/images/flags-circle/${countryName}.svg`;
  }

  private static _getCheckpointNames(key) {
    const lang = localeService.getLanguage();
    const group = CHECKPOINTS_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.checkpoints[key];
  }

  static generateFromDTO({ name, type, percentage }: GenerateFromDTOParams) {
    return new TransitFulfillmentCheckpoint({
      id: name,
      name: this._getCheckpointNames(name),
      percentage: Math.round(percentage),
      type: type
    });
  }

  static generateEmpty() {
    return new TransitFulfillmentCheckpoint({
      id: '',
      name: '',
      percentage: 0,
      type: CheckpointType.NONE
    });
  }
}

export default TransitFulfillmentCheckpoint;
