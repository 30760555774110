import { Translation } from 'infrastructure/i18n/typing';
import { EntriesTranslations } from './typing';

export const EntriesText: Translation<EntriesTranslations> = {
  es: {
    alertsTable: 'Tabla alertas',
    entries: 'Entradas',
    entriesNextHours: 'Próximas 24 horas',
    nextDays: 'Próximos 8 días',
    entriesInPlant: 'Actualmente en planta',
    trucks: 'Camiones',
    plateNumber: 'Matrícula',
    transportId: 'Id Transporte',
    transportCodes: 'Claves transporte',
    carrier: 'Transportista',
    carrierMultiselectAvailable: 'Transportistas disponibles',
    carrierMultiselectSelected: 'Transportistas seleccionados',
    carrierMultiselectEmpty: 'No hay transportistas disponibles',
    estimatedTimeArrival: 'ETA',
    estimatedArrival: 'L. esperada',
    mostCriticalPieceCoverage: 'Cob. pza. más crítica',
    numberOfContainers: 'Nº Contenedores',
    warehouses: 'Almacenes',
    warehousesMultiselectAvailable: 'Almacén de origen disponibles',
    warehousesMultiselectSelected: 'Almacén de origen seleccionados',
    warehousesMultiselectEmpty: 'No hay almacenes de origen disponibles',
    searcherPartOrPlatePlaceholder: 'Búsqueda por clave o matrícula',
    entryTime: 'Hora entrada',
    lastPosition: 'Última posición',
    unloadingPoints: 'Punto de descarga',
    stops: 'Nº Paradas',
    windowBooked: 'V. horaria',
    code: 'Clave',
    coverage: 'Cobertura',
    warehouse: 'Almacén',
    container: 'Contenedor',
    containerType: 'Tipo contenedor',
    globalDlz: 'DLZ (minutos)',
    material: 'Material',
    minimumCoverage: 'Cob. mín.',
    status: 'Status',
    transportStops: 'Paradas transporte',
    remainingStops: 'Paradas restantes',
    totalDuration: 'Duración total',
    paralyzed: 'Paralizado',
    entitledToParalization: 'Con derecho a paralización',
    nearParalyzation: 'Próximo a paralización',
    urgent: 'Urgente',
    urgentTooltip: 'Transporte marcado como urgente por aprovisionador',
    criticalPiece: 'Pieza crítica',
    criticalPieceTooltip:
      'Transporte sin stock en almacén  marcado como pieza crítica',
    criticalPieceInfoTooltip: 'Pieza Crítica (PC): Pieza sin stock en almacen',
    seriousFaultInfoTooltip:
      'Falta Grave (FG): Pieza sin stock en almacén y necesidad de línea',
    paralyzations: 'Paralizaciones',
    stopType: 'Tipo de acciones',
    supplierName: 'Nombre de proveedor',
    lastPositionMultiselectAvailable: 'Últimas posiciones disponibles',
    lastPositionMultiselectSelected: 'Últimas posiciones seleccionadas',
    lastPositionMultiselectEmpty: 'No hay últimas posiciones disponibles',
    urgentArrivals: 'Llegadas urgentes',
    topThreeTrucks: 'Top 3 camiones',
    supplier: 'Proveedor',
    suppliersMultiselectAvailable: 'Proveedores disponibles',
    suppliersMultiselectSelected: 'Proveedores seleccionados',
    suppliersMultiselectEmpty: 'No hay proveedores disponibles',
    weeklyDlz: 'DLZ Semanal',
    unloadingPointsMultiselectAvailable: 'Puntos descarga disponibles',
    unloadingPointsMultiselectSelected: 'Puntos descarga seleccionados',
    unloadingPointsMultiselectEmpty: 'No hay puntos de descarga disponibles',
    planned: 'Planificado',
    unloading: 'Descargando',
    executed: 'Ejecutado',
    executedState: 'Estado ejecución',
    entriesUnloadingPoints: 'Por punto de descarga',
    morningShift: 'Turno mañana',
    afternoonShift: 'Turno tarde',
    nightShift: 'Turno noche',
    inPlant: 'En planta',
    reservedWindowDataUpdate: 'Act. ventanas reservadas',
    updateExecutedWindows: 'Act. ventanas ejecutadas',
    bookedState: 'Tipo reserva',
    startTime: 'Hora inicio',
    endTime: 'Hora fin',
    reserved: 'Reservado',
    unreserved: 'No reservado',
    transports: 'Transportes',
    transportationOverview: 'Resumen transporte',
    checkInTimeAtTCC: 'Hora de registro en TCC',
    currentShiftContainers: 'Contenedores turno actual',
    currentShiftTrucks: 'Camiones turno actual',
    locations: 'Ubicaciones'
  },
  en: {
    alertsTable: 'Alerts table',
    entries: 'Entries',
    entriesNextHours: 'Next 24 hours',
    nextDays: 'Next 8 days',
    entriesInPlant: 'Currently in plant',
    trucks: 'Trucks',
    plateNumber: 'Plate number',
    transportId: 'Transport Id',
    transportCodes: 'Transport codes',
    carrier: 'Carrier',
    carrierMultiselectAvailable: 'Carriers available',
    carrierMultiselectSelected: 'Carriers selected',
    carrierMultiselectEmpty: "There aren't carriers available",
    estimatedTimeArrival: 'ETA',
    estimatedArrival: 'E. arrival',
    mostCriticalPieceCoverage: 'Most critical piece cov.',
    warehouses: 'Warehouse',
    numberOfContainers: 'Num. Containers',
    warehousesMultiselectAvailable: 'Warehouse origins available',
    warehousesMultiselectSelected: 'Warehouse origins selected',
    warehousesMultiselectEmpty: 'There aren´t warehouse origins available',
    searcherPartOrPlatePlaceholder: 'Search by part or platenumber',
    unloadingPoints: 'Unload spot',
    entryTime: 'Entry time',
    lastPosition: 'Last position',
    stops: 'No Stops',
    windowBooked: 'T. window',
    code: 'Part',
    coverage: 'Coverage',
    warehouse: 'Warehouse',
    container: 'Container',
    containerType: 'Container type',
    globalDlz: 'DLZ (minutes)',
    material: 'Material',
    minimumCoverage: 'Min. cov.',
    status: 'Status',
    transportStops: 'Transport stops',
    remainingStops: 'Remaining stops',
    totalDuration: 'Total duration',
    paralyzed: 'Paralyzed',
    entitledToParalization: 'Entitled to paralization',
    nearParalyzation: 'Near paralization',
    urgent: 'Urgent',
    urgentTooltip: 'Transport marked as urgent by supplier',
    criticalPiece: 'Critical piece',
    criticalPieceTooltip:
      'Transport without stock in warehouse marked as critical piece',
    criticalPieceInfoTooltip:
      'Critical Piece (PC): Piece without stock in the warehouse',
    seriousFaultInfoTooltip:
      'Serious Fault (FG): Piece without stock in warehouse and line need',
    paralyzations: 'Paralyzations',
    stopType: 'Type of actions',
    supplierName: 'Supplier name',
    lastPositionMultiselectAvailable: 'Last positions available',
    lastPositionMultiselectSelected: 'Last positions selected',
    lastPositionMultiselectEmpty: "There aren't positions available",
    urgentArrivals: 'Urgent arrivals',
    topThreeTrucks: 'Top 3 trucks',
    supplier: 'Supplier',
    suppliersMultiselectAvailable: 'Suppliers available',
    suppliersMultiselectSelected: 'Suppliers selected',
    suppliersMultiselectEmpty: "There aren't suppliers available",
    weeklyDlz: 'Weekly DLZ',
    unloadingPointsMultiselectAvailable: 'Unload points available',
    unloadingPointsMultiselectSelected: 'Unload points selected',
    unloadingPointsMultiselectEmpty: "There aren't unload points available",
    planned: 'Planned',
    unloading: 'Unloading',
    executed: 'Executed',
    executedState: 'Execution status',
    entriesUnloadingPoints: 'By unloading point',
    morningShift: 'Morning shift',
    afternoonShift: 'Afternoon shift',
    nightShift: 'Night shift',
    inPlant: 'In plant',
    reservedWindowDataUpdate: 'Reserved windows upd.',
    updateExecutedWindows: 'Upd. executed windows',
    bookedState: 'Booked state',
    startTime: 'Start time',
    endTime: 'End time',
    reserved: 'Reserved',
    unreserved: 'Unreserved',
    transports: 'Transports',
    transportationOverview: 'Transportation overview',
    checkInTimeAtTCC: 'Check-in time at TCC',
    currentShiftContainers: 'Current shift containers',
    currentShiftTrucks: 'Current shift trucks',
    locations: 'Locations'
  },
  ca: {
    alertsTable: 'Taula alertes',
    entries: 'Entrades',
    entriesNextHours: 'Properes 24 hores',
    nextDays: 'Propers 8 dies',
    entriesInPlant: 'Actualment a la planta',
    trucks: 'Camions',
    plateNumber: 'Matricula',
    transportId: 'Id Transport',
    transportCodes: 'Claus transport',
    carrier: 'Transportista',
    carrierMultiselectAvailable: 'Transportistes disponibles',
    carrierMultiselectSelected: 'Transportistes seleccionats',
    carrierMultiselectEmpty: 'No hi ha transportistes disponibles',
    estimatedTimeArrival: 'ETA',
    estimatedArrival: 'A. esperada',
    mostCriticalPieceCoverage: 'Cob. peça més crítica',
    numberOfContainers: 'Nº Contenidors',
    warehouses: 'Magatzem',
    warehousesMultiselectAvailable: "Magatzems d'origen disponibles",
    warehousesMultiselectSelected: "Magatzems d'origen seleccionats",
    warehousesMultiselectEmpty: "No hi ha Magatzems d'origen disponibles",
    searcherPartOrPlatePlaceholder: 'Cerca per clau o matrícula',
    entryTime: 'Hora entrada',
    lastPosition: 'Última posició',
    unloadingPoints: 'Punt de descàrrega',
    stops: 'Nº Parades',
    windowBooked: 'F. horària',
    code: 'Clau',
    coverage: 'Cobertura',
    warehouse: 'Magatzem',
    container: 'Contenidor',
    containerType: 'Tipus contenidor',
    globalDlz: 'DLZ (minuts)',
    material: 'Material',
    minimumCoverage: 'Cob. mín.',
    status: 'Status',
    transportStops: 'Parades transport',
    remainingStops: 'Parades restants',
    totalDuration: 'Durada total',
    paralyzed: 'Paralitzat',
    entitledToParalization: 'Amb dret a paralització',
    nearParalyzation: 'Pròxim a paralització',
    urgent: 'Urgent',
    urgentTooltip: 'Transport marcat com a urgent per aprovisionador',
    criticalPiece: 'Clau crítica',
    criticalPieceTooltip:
      'Transport sense estoc al magatzem marcat com a peça crítica',
    criticalPieceInfoTooltip: 'Peça Crítica (PC): Peça sense estoc al magatzem',
    seriousFaultInfoTooltip:
      'Falla Greu (FG): Peça sense estoc al magatzem i necessitat de línia',
    paralyzations: 'Paralitzacions',
    stopType: "Tipus d'accions",
    supplierName: 'Nom proveïdor',
    lastPositionMultiselectAvailable: 'Últimes posicions disponibles',
    lastPositionMultiselectSelected: 'Últimes posicions seleccionades',
    lastPositionMultiselectEmpty: 'No hi ha últimes posicions disponibles',
    urgentArrivals: 'Arribades urgents',
    topThreeTrucks: 'Top 3 camions',
    supplier: 'Proveïdor',
    suppliersMultiselectAvailable: 'Proveïdors disponibles',
    suppliersMultiselectSelected: 'Proveïdors seleccionados',
    suppliersMultiselectEmpty: 'No hi ha proveïdors disponibles',
    weeklyDlz: 'DLZ Setmanal',
    unloadingPointsMultiselectAvailable: 'Punts descàrrega disponibles',
    unloadingPointsMultiselectSelected: 'Punts descàrrega seleccionats',
    unloadingPointsMultiselectEmpty: 'No hi ha punts de descàrrega disponibles',
    planned: 'Planificat',
    unloading: 'Descarregant',
    executed: 'Executat',
    executedState: 'Estat execució',
    entriesUnloadingPoints: 'Per punt de descàrrega',
    morningShift: 'Torn matí',
    afternoonShift: 'Torn tarda',
    nightShift: 'Torn nit',
    inPlant: 'En planta',
    reservedWindowDataUpdate: 'Act. finestres reservades',
    updateExecutedWindows: 'Act. finestres executades',
    bookedState: 'Tipus de reserva',
    startTime: 'Hora inici',
    endTime: 'Hora fi',
    reserved: 'Reservat',
    unreserved: 'No reservat',
    transports: 'Transports',
    transportationOverview: 'Resum transport',
    checkInTimeAtTCC: 'Hora de registre a TCC',
    currentShiftContainers: 'Contenidors torn actual',
    currentShiftTrucks: 'Camions torn actual',
    locations: 'Ubicacions'
  }
};
