import {
  API_DOWNLOAD_END_OF_PRODUCTION_DATE,
  API_GET_STOCK_END_OF_PRODUCTION_DATE,
  API_GET_STOCK_END_OF_PRODUCTION_FILTERS,
  API_GET_STOCK_END_OF_PRODUCTION_SUMMARY
} from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import EndOfProductionDateDataDTO from '../dto/EndOfProductionDateDataDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IEndOfProductionDateRepository from '../../domain/IEndOfProductionDateRepository';
import EndOfProductionFiltersDTO from '../dto/EndOfProductionFiltersDTO';
import EndOfProductionSummaryDTO from '../dto/EndOfProductionSummaryDTO';

class EndOfProductionDateRepository
  extends BaseRepository
  implements IEndOfProductionDateRepository
{
  private _api: IApi;
  private _apiGetEndOfProductionDateStock: string;
  private _apiGetEndOfProductionFilters: string;
  private _apiGetEndOfProductionSummary: string;
  private _apiGetDownloadEndOfProductionDate: string;

  constructor({
    api,
    apiGetEndOfProductionDateStock,
    apiGetEndOfProductionFilters,
    apiGetEndOfProductionSummary,
    apiGetDownloadEndOfProductionDate
  }) {
    super();
    this._api = api;
    this._apiGetEndOfProductionDateStock = apiGetEndOfProductionDateStock;
    this._apiGetEndOfProductionFilters = apiGetEndOfProductionFilters;
    this._apiGetEndOfProductionSummary = apiGetEndOfProductionSummary;
    this._apiGetDownloadEndOfProductionDate = apiGetDownloadEndOfProductionDate;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${
      this._apiGetEndOfProductionDateStock
    }${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) => new EndOfProductionDateDataDTO(res));
  }

  getFilters() {
    return this._api
      .get(this._apiGetEndOfProductionFilters)
      .then((res) => new EndOfProductionFiltersDTO(res));
  }

  getSummary({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetEndOfProductionSummary}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new EndOfProductionSummaryDTO(res));
  }

  downloadEndOfProductionDate({ queryParams }) {
    const url = `${
      this._apiGetDownloadEndOfProductionDate
    }${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new EndOfProductionDateRepository({
  api: api,
  apiGetEndOfProductionDateStock: API_GET_STOCK_END_OF_PRODUCTION_DATE,
  apiGetEndOfProductionFilters: API_GET_STOCK_END_OF_PRODUCTION_FILTERS,
  apiGetEndOfProductionSummary: API_GET_STOCK_END_OF_PRODUCTION_SUMMARY,
  apiGetDownloadEndOfProductionDate: API_DOWNLOAD_END_OF_PRODUCTION_DATE
});

export { EndOfProductionDateRepository };
