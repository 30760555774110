import { createSlice } from '@reduxjs/toolkit';
import { SHIFT_TRANSLATIONS, SaturationUnloadingPointsState } from './types';
import {
  getSaturationUnloadingPoints,
  getSaturationUnloadingPointsDetail,
  getUnloadingPointPiecesDetail
} from './async';
import {
  SaturationDataByShift,
  UnloadingPoint
} from 'modules/inbound/entries/domain/UnloadingPoints/UnloadingPoint';
import {
  UNLOADING_POINT_STATE_COLORS,
  UnloadingPointProps
} from '../../layout/contaniers/SaturationUnloadingPoints/UploadingPoint/types';
import { EntriesTranslations } from 'modules/inbound/entries/infrastructure/i18n/typing';
import { EntriesText } from 'modules/inbound/entries/infrastructure/i18n/EntriesText';
import LocaleService from 'infrastructure/i18n/LocaleService';

const entriesTranslations: EntriesTranslations = {
  ...EntriesText[LocaleService.getLanguage()]
};

export const initialState: SaturationUnloadingPointsState = {
  saturation: [],
  unloadingPointsRequestStatus: '',
  entriesBySlotAndUnladingPoint: [],
  piecesByEntryAndUnladingPoint: [],
  entriesBySlotAndUnladingPointRequestStatus: '',
  piecesByEntryAndUnladingPointRequestStatus: '',
  selectedUnloadingPointSlot: {
    time: '',
    date: '',
    unloadingPoint: ''
  },
  selectedTransportId: null,
  unloadinPointsWithPackagesChartSelected: []
};

const saturationUnloadingPointsSlice = createSlice({
  name: 'saturationUnloadingPoints',
  initialState,
  reducers: {
    updateSelectedUnloadingPointSlot: (state, action) => {
      state.selectedUnloadingPointSlot = action.payload;
    },
    updateSelectedTransportId: (state, action) => {
      state.selectedTransportId = action.payload;
    },
    updateUnloadinPointsWithPackagesChartSelected: (state, action) => {
      const selectedChart = action.payload;
      const updatedArray =
        state.unloadinPointsWithPackagesChartSelected.slice();

      const selectedIndex = updatedArray.indexOf(selectedChart);
      if (selectedIndex > -1) {
        updatedArray.splice(selectedIndex, 1);
      } else {
        updatedArray.push(selectedChart);
      }

      state.unloadinPointsWithPackagesChartSelected = updatedArray;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSaturationUnloadingPoints.fulfilled, (state, action) => {
      state.saturation = action.payload.saturation;
      state.unloadingPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSaturationUnloadingPoints.pending, (state, action) => {
      state.unloadingPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getSaturationUnloadingPoints.rejected, (state, action) => {
      state.unloadingPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getSaturationUnloadingPointsDetail.fulfilled,
      (state, action) => {
        state.entriesBySlotAndUnladingPoint = action.payload.entries;
        state.entriesBySlotAndUnladingPointRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(
      getSaturationUnloadingPointsDetail.pending,
      (state, action) => {
        state.entriesBySlotAndUnladingPointRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(
      getSaturationUnloadingPointsDetail.rejected,
      (state, action) => {
        state.entriesBySlotAndUnladingPointRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(
      getUnloadingPointPiecesDetail.fulfilled,
      (state, action) => {
        state.piecesByEntryAndUnladingPoint = action.payload.pieces;
        state.piecesByEntryAndUnladingPointRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getUnloadingPointPiecesDetail.pending, (state, action) => {
      state.piecesByEntryAndUnladingPointRequestStatus =
        action.meta.requestStatus;
    });
    builder.addCase(getUnloadingPointPiecesDetail.rejected, (state, action) => {
      state.piecesByEntryAndUnladingPointRequestStatus =
        action.meta.requestStatus;
    });
  }
});

export const getIsSaturationPending = (state) =>
  state.saturationUnloadingPointsState.unloadingPointsRequestStatus ===
  'pending';

export const getIsSaturationRejected = (state) =>
  state.saturationUnloadingPointsState.unloadingPointsRequestStatus ===
  'pending';

const getSlotState = (shiftIndex, slotsState) => {
  return slotsState
    .map((slotState) => {
      if (shiftIndex !== 0) {
        return slotState
          .filter(({ state }) => state === 'BOOKED')
          .map(({ state, value }) => ({
            color: UNLOADING_POINT_STATE_COLORS[state],
            value: value
          }));
      }
      return slotState.map(({ state, value }) => ({
        color: UNLOADING_POINT_STATE_COLORS[state],
        value: value
      }));
    })
    .filter((arr) => arr.length);
};

const formatUnloadingPointData = (data: SaturationDataByShift[]) => {
  return data.map(({ shift, slots }, shiftIndex) => ({
    label: entriesTranslations[SHIFT_TRANSLATIONS[shift]].toUpperCase(),
    chartDataset: slots.map(
      ({ hour, date, slotsState, isLastUpdate, isCritical, isUrgent }) => ({
        label: hour,
        barSetKey: date,
        isCurrent: isLastUpdate,
        isCritic: isCritical,
        isUrgent,
        datasets: getSlotState(shiftIndex, slotsState)
      })
    )
  }));
};

export const getSaturationUnloadingPointsUI = (state) => {
  const unloadingPoints: UnloadingPoint[] =
    state.saturationUnloadingPointsState.saturation;

  const unloadingPointsUi: UnloadingPointProps[] = unloadingPoints.map(
    (point) => ({
      name: point.unloadingPoint,
      containersUnloadingMetrics: point.containersUnloadingMetrics,
      entriesUnloadingMetrics: point.entriesUnloadingMetrics,
      entriesMaxValue: point.entriesMaxValue,
      containersMaxValue: point.containersMaxValue,
      entriesData: formatUnloadingPointData(point.entriesDataByShift),
      containersData: formatUnloadingPointData(point.containersDataByShift)
    })
  );

  return unloadingPointsUi;
};

export const getSelectedUnloadingPointSlot = (state) =>
  state.saturationUnloadingPointsState.selectedUnloadingPointSlot;
export const getEntriesBySlotAndUnladingPoint = (state) =>
  state.saturationUnloadingPointsState.entriesBySlotAndUnladingPoint;
export const getPiecesByEntryAndUnloadingPoint = (state) =>
  state.saturationUnloadingPointsState.piecesByEntryAndUnladingPoint;
export const getSelectedTransportId = (state) =>
  state.saturationUnloadingPointsState.selectedTransportId;
export const getHasPackagesChartSelected = (state, unloadingPoint) =>
  state.saturationUnloadingPointsState.unloadinPointsWithPackagesChartSelected.includes(
    unloadingPoint
  );

export const isEntriesBySlotAndUnladingPointRequestStatusPending = (state) =>
  state.saturationUnloadingPointsState
    .entriesBySlotAndUnladingPointRequestStatus === 'pending';
export const isEntriesBySlotAndUnladingPointRequestStatusRejected = (state) =>
  state.saturationUnloadingPointsState
    .entriesBySlotAndUnladingPointRequestStatus === 'rejected';

export const isPiecesDetailPending = (state) =>
  state.saturationUnloadingPointsState
    .piecesByEntryAndUnladingPointRequestStatus === 'pending';
export const isPiecesDetailRejected = (state) =>
  state.saturationUnloadingPointsState
    .piecesByEntryAndUnladingPointRequestStatus === 'rejected';

export const {
  updateSelectedUnloadingPointSlot,
  updateSelectedTransportId,
  updateUnloadinPointsWithPackagesChartSelected
} = saturationUnloadingPointsSlice.actions;

export default saturationUnloadingPointsSlice.reducer;
