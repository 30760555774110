import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';
import { TABLET_BREAKPOINT } from 'modules/shared/hooks/useBreakpoints';

const SIDEBAR_DESKTOP_WIDTH = '124px';
const SIDEBAR_DESKTOP_EXTENDED_WIDTH = '270px';

const WrapperExtensibleSidebar = styled.section<{
  isOpened: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${SIDEBAR_DESKTOP_EXTENDED_WIDTH};
  height: 100%;
  padding: 2.5rem 0;
  position: fixed;
  right: ${(props: { isOpened: boolean }) => (props.isOpened ? '0' : '-400px')};
  top: 0;
  text-align: center;
  box-sizing: border-box;
  background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.colors.neutral900.hexa};
  z-index: 20;
  border-right: ${(props: { theme: ThemeSEATProps; isOpened: boolean }) =>
    props.isOpened
      ? `1px solid ${props.theme.colors.neutral50.rgb}`
      : '1px solid transparent'};

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    left: 0;
    width: ${(props: { isOpened: boolean }) =>
      props.isOpened ? SIDEBAR_DESKTOP_EXTENDED_WIDTH : SIDEBAR_DESKTOP_WIDTH};
  }

  img {
    width: 60px;
  }

  .logo-subtitle {
    margin: 0.5rem 0 3rem;
    display: block;
    font-family: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fontFamily.bold};
    font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fonts.regularSmall.minSizeAsPixels};
    color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.orange.rgb};
  }

  button {
    padding: 0;
  }

  & svg.extensible-arrow {
    width: 28px;
    margin: 0;
    transform: ${(props: { isOpened: boolean }) =>
      !props.isOpened && 'rotate(180deg)'};
  }

  .logout {
    margin-left: 2rem;
    align-self: start;
    svg {
      margin-right: 1rem;
    }
  }
`;

const FullWidthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  overflow: hidden;

  .close-sidebar {
    align-self: flex-end;
    margin: 0 2rem 2rem 0;
    padding: 5px;
    background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.colors.black.rgb};
    border-radius: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.borders.circle};

    svg {
      margin: 0;
    }
  }

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 0;
  }
`;

export { SIDEBAR_DESKTOP_WIDTH, WrapperExtensibleSidebar, FullWidthWrapper };
