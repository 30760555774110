import { Statistic } from '../infrastructure/dto/Statistic';
import { WarehouseRankingDTO } from '../infrastructure/dto/warehouseRankingDTOMapper';

export interface WarehouseRanking {
  totalStatistics: Statistic;
  statistics: Statistic[];
}

export const WAREHOUSE_RANKING_EMPTY: WarehouseRanking = {
  totalStatistics: {
    name: '',
    stats: [],
    total: 0
  },
  statistics: []
};

export const warehouseRankingMapper = (
  dto: WarehouseRankingDTO
): WarehouseRanking => {
  return {
    totalStatistics: dto?.total_statistics,
    statistics: dto?.statistics
  };
};
