import React from 'react';
import { useField } from 'modules/shared/hooks/useField';
import { FormInput } from '../../elements/Inputs/FormInput';
import { Tag } from '../../elements/Tag';
import { Button } from '../../pages/LoginPage/styles';
import { NewPasswordFormProps } from './types';
import {
  lowerCase,
  number,
  specialCharacter,
  upperCase,
  validatePassword,
  length
} from './PasswordValidation';
import { WrapperTags } from './styles';
import { LoginTranslations } from 'modules/shared/infrastructure/i18n/Login/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { LoginText } from 'modules/shared/infrastructure/i18n/Login';

const loginTranslations: LoginTranslations = {
  ...LoginText[LocaleService.getLanguage()]
};

export const NewPasswordForm = ({
  hanldeNewPassword
}: NewPasswordFormProps) => {
  const password = useField({
    type: 'password',
    validation: {
      validate: validatePassword,
      errorMessage: loginTranslations.invalidPassword
    }
  });
  const repeatedPassword = useField({
    type: 'password',
    validation: {
      validate: validatePassword,
      errorMessage: '*'
    }
  });

  const handleNewPassword = (event) => {
    event.preventDefault();
    hanldeNewPassword({
      password: password.value
    });
  };

  return (
    <>
      <h3>{loginTranslations.passwordRecovery}</h3>
      <p>{loginTranslations.enterNewPassword}</p>
      <form onSubmit={handleNewPassword} data-cy="new-password-form">
        <FormInput {...password} name="password" label="Password" required />
        <WrapperTags>
          <Tag success={upperCase.test(password.value)}>
            <span>{loginTranslations.oneCapitalLetter}</span>
          </Tag>
          <Tag success={lowerCase.test(password.value)}>
            <span>{loginTranslations.oneLowercase}</span>
          </Tag>
          <Tag success={specialCharacter.test(password.value)}>
            <span>{loginTranslations.oneSpecialChar}</span>
          </Tag>
          <Tag success={number.test(password.value)}>
            <span>{loginTranslations.oneNumber}</span>
          </Tag>
          <Tag success={length.test(password.value)}>
            <span>{loginTranslations.lengthChars}</span>
          </Tag>
        </WrapperTags>
        <FormInput
          {...repeatedPassword}
          name="repeatedPassword"
          label="Repeat password"
          required
        />
        <WrapperTags>
          <Tag
            success={
              !!password.value.length &&
              password.value === repeatedPassword.value
            }
          >
            <span>{loginTranslations.passwordsMatch}</span>
          </Tag>
        </WrapperTags>
        <Button
          type="submit"
          disabled={
            !validatePassword(password.error) &&
            password.value !== repeatedPassword.value
          }
        >
          {loginTranslations.changePassword}
        </Button>
      </form>
    </>
  );
};
