import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import directDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class DownloadUpcomingTransitsService {
  private _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ directDeliveryRepository }) {
    this._directDeliveryRepository = directDeliveryRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._directDeliveryRepository.downloadUpcomingTransits({
      queryParams
    });
  }
}

export default new DownloadUpcomingTransitsService({
  directDeliveryRepository: directDeliveryRepository
});

export { DownloadUpcomingTransitsService };
