export enum NameValues {
  ACTION = 'action',
  AMOUNT = 'amount',
  AMOUNT_ORDERED = 'amount_ordered',
  ARRIVAL_DATE = 'arrival_date',
  ASN_CONTAINER = 'asn_container',
  AVERAGE_CONSUMPTION = 'average_consumption',
  AVERAGE_COVERAGE_IN_SECONDS = 'average_coverage_in_seconds',
  BASIC_CONTAINER = 'basic_container',
  BCN_BLOCK = 'label_bcn',
  BLOCKED_MATERIAL = 'blocked_material',
  CARRIER = 'carrier',
  CODE = 'code',
  COMMENT = 'comment',
  CONFIRMATION_DATE = 'confirmation_date',
  CONSUMPTION = 'consumption',
  CONTAINERS_AT_MARQUEE = 'containers_at_marquee',
  CONTAINERS_IN_CYCLE = 'containers_in_cycle',
  CONTAINER_ID = 'container_id',
  COST = 'cost',
  COUNTRIES = 'countries',
  COUNTRY = 'country',
  COVERAGE_IN_SECONDS = 'coverage_in_seconds',
  COVERAGE_STATUS = 'coverage_status',
  COVERAGE_WITH = 'coverage_with',
  CREATED_AT = 'created_at',
  CRITICAL_STOCK_TYPE = 'critical_stock_type',
  DASHBOARDS = 'dashboards',
  DATE = 'date',
  DATE_TYPE = 'date_type',
  DELAY_TIME_IN_MINUTES = 'delay_time_in_minutes',
  DELIVERY_STATUSES_TYPE = 'delivery_statuses_type',
  DELIVERY_TIME = 'delivery_time',
  DEPARTURE_DATE = 'departure_date',
  DESCRIPTION = 'description',
  EMAIL = 'email',
  END_OF_CONSUMPTION_DATE = 'end_of_consumption_date',
  END_OF_STOCK_TYPE = 'end_of_stock_type',
  END_PRODUCTION_DATE = 'end_production_in_days',
  EXCESS_POTENCIAL = 'excess_potencial',
  EXPIRATION_DATE = 'expiration_date',
  EXPIRATION_STATUS = 'expiration_status',
  FACTORY_COVERAGE = 'factory_coverage',
  FACTORY_COVERAGE_END_DATETIME = 'factory_coverage_end_datetime',
  FAMILIES = 'families',
  FAMILY = 'family',
  FINAL_STOCK_DATETIME = 'final_stock_datetime',
  FINAL_STOCK_QUANTITY = 'final_stock_quantity',
  FLOW_TYPE = 'flow_type',
  FORECAST_STOPPAGE_AT = 'forecast_stoppage_at',
  GONVAUTO_T2 = 'gonvauto_t2',
  GONVAUTO_TIER2_STOCK = 'gonvauto_tier2.stock',
  GONVAUTO_TIER2_UPDATED_AT = 'gonvauto_tier2.updated_at',
  HANDLING_UNIT = 'handling_unit',
  HANDLING_UNIT_CONTAINER = 'handling_unit_container',
  HAS_CRITICAL_PIECES = 'has_critical_pieces',
  INBOUNDER = 'inbounder',
  INBOUNDERS = 'inbounders',
  IN_CONTAINERS = 'material_to_deliver.in_containers',
  IN_PIECES = 'material_to_deliver.in_pieces',
  IN_TIME = 'material_to_deliver.in_time',
  IS_CLOSED = 'is_closed',
  IS_PLANNED = 'is_planned',
  IS_TRANSFER = 'is_transfer',
  KEY_TYPE = 'key_type',
  LAST_CONNECTION_DATE = 'last_connection_date',
  LAST_ENTRY = 'last_entry',
  LAST_POSITION = 'last_position',
  LAST_UPDATED = 'last_updated',
  LEADTIME = 'leadtime',
  LEFTOVER_STOCK = 'leftover_stock',
  LEFTOVER_STOCK_COST = 'leftover_stock_cost',
  LINE = 'line',
  LINES = 'lines',
  LINES_LKDD = 'lines_lkdd',
  LINE_GROUP = 'line_group',
  LINE_LOCATION = 'line_location',
  LOCATION_BCN = 'BCN.location',
  LOCKED_STOCK_BCN = 'BCN.stock_bloqueado',
  LOCKED_STOCK_MRT = 'MRT.locked_stock',
  LOGISTIC_OPERATOR = 'logistic_operator',
  LOGISTIC_OPERATOR_ID = 'logistic_operator_id',
  MANUFACTURING_SECTION = 'manufacturing_section',
  MARGIN = 'margin',
  MARGIN_END_DATETIME = 'margin_end_datetime',
  MARQUEE = 'marquee',
  MATERIAL_INDICATOR = 'material_indicator',
  MATERIAL_IN_TRANSIT = 'material_in_transit',
  MATERIAL_TO_DELIVER = 'material_to_deliver',
  MEASUREMENT_UNITS = 'measurement_units',
  MODEL = 'model',
  MODELS = 'models',
  MODELS_LKDD = 'models_lkdd',
  MOVEMENTS_LINE = 'movements_line',
  MOVEMENT_AMOUNT = 'movement_amount',
  MOVEMENT_DATE = 'movement_date',
  MOVEMENT_TYPE = 'movement_type',
  MRT_MARGIN = 'MRT.margin',
  MRT_STOCK_COVERAGE = 'MRT.stock_coverage',
  NAME = 'name',
  OFFICIAL_DATE = 'official_date',
  ORDER_REQUEST = 'order_request',
  OT_NUMBER = 'ot_number',
  P17_COVERAGE = 'p17_coverage',
  P17_SOURCE = 'p17_source',
  P17_STOCK = 'p17_stock',
  PARALYZATION_RIGHT = 'paralyzation_right',
  PARALYZATION_STATE = 'paralyzation_state',
  PAST_LOADS = 'past_loads',
  PHASE = 'phase',
  PLATE_NUMBER = 'plate_number',
  PREPARATION_DATE = 'preparation_date',
  PROVIDERS = 'providers',
  PROVIDER_CODE = 'provider_code',
  PROVIDER_COVERAGE = 'provider_coverage',
  PROVIDER_DELIVERY_NOTE = 'provider_delivery_note',
  PROVIDER_ID = 'provider_id',
  PROVIDER_IDS = 'provider_ids',
  PROVIDER_INBOUNDERS = 'provider_inbounders',
  PROVIDER_NAME = 'provider_name',
  PROVIDER_NAMES = 'provider_names',
  PROVIDER_PERCENTAGE = 'percentage_asignements',
  PROVIDER_STOCK = 'provider_stock',
  PROVIDER_T1 = 'provider_t1',
  PROVIDER_T2 = 'provider_t2',
  PROVIDER_TIER_1 = 'provider_tier_1',
  PROVIDER_TIER_1_ID = 'provider_tier_1_id',
  PROVIDER_TYPE = 'provider_type',
  READY_STOCK = 'BCN.stock_listo',
  REFILL_TIME_IN_MINUTES = 'refill_time_in_minutes',
  ROLE = 'role',
  SECURITY_MARGIN = 'security_margin',
  SEQUENCER_CODE = 'sequencer_code',
  SEQUENCER_CODES = 'sequencer_codes',
  SEQUENCER_COVERAGE = 'sequencer_coverage',
  SEQUENCER_NAME = 'sequencer_name',
  SEQUENCER_STOCK = 'sequencer_stock',
  SOURCE_LOCATION = 'source_location',
  STATUS = 'status',
  STOCK = 'stock',
  STOCK_BLOCK = 'label_stock',
  STOCK_COVERAGE_MRT = 'stock_coverage_mrt',
  STOCK_DIFFERENTIATIONS = 'stock_differentiations',
  STOCK_IN_REVIEW_BCN = 'BCN.stock_in_review',
  STOCK_MRT = 'MRT.stock',
  STOCK_VOLUME = 'stock_volume',
  STUCKLISTE_MODELS = 'stuckliste_models',
  SUPPLIER = 'supplier',
  SUPPLIER_DATE = 'supplier_date',
  SUPPLIER_NAME = 'supplier_name',
  SUPPLY_CONTAINER = 'supply_container',
  SUPPLY_CONTAINER_CAPACITY = 'supply_container_capacity',
  SUPPLY_TYPE = 'supply_type',
  TERMINS = 'termins',
  TIER_STOCK = 'tier_stock',
  TIER_STOCK_UPDATE_AT = 'tier_stock_updated_at',
  TRAILER_PLATE_NUMBER = 'trailer_plate_number',
  TRANSIT_COVERAGE = 'transit_coverage',
  TRANSIT_COVERAGE_END_DATETIME = 'transit_coverage_end_datetime',
  TRANSPORTER = 'transporter',
  TRANSPORT_CONTAINER = 'transport_container',
  TRANSPORT_ORDER_NUMBER = 'transport_order_number',
  TRANSPORT_REQUEST = 'transport_request',
  TYPE = 'type',
  UNLOADING_POINT = 'unloading_point',
  URGENT = 'urgent',
  USER = 'user',
  VEHICLE_PLATE_NUMBER = 'vehicle_plate_number',
  VISIBILITY = 'visibility',
  WAREHOUSE = 'warehouse',
  WAREHOUSES = 'warehouses',
  WAREHOUSE_BCN = 'BCN.warehouse',
  WAREHOUSE_CODES = 'warehouse_codes',
  WAREHOUSE_ID = 'warehouse_id',
  WAREHOUSE_MRT = 'MRT.warehouse',
  WAREHOUSE_SOURCE = 'warehouse_source',
  WAREHOUSE_SOURCE_TYPE = 'warehouse_source_type',
  WAREHOUSE_TARGET = 'warehouse_target',
  WAREHOUSE_TYPE = 'warehouse_type',
  WAREHOUSE_UNIT_NUMBER = 'warehouse_unit_number',
  WAREHOUSE_UNIT_TARGET = 'warehouse_unit_target',
  WINDOW_BOOKED = 'window_booked',
  WORKSHOPS = 'workshops'
}

export enum StatusTypes {
  DELAYED = 'DELAYED',
  NEAR_DELAY = 'NEAR_DELAY',
  ON_TIME = 'ON_TIME',
  CLOSED = 'CLOSED'
}

export enum SearchOperators {
  EQUAL = ':=:',
  LK = ':LK:',
  OR = '|OR|',
  AND = '||',
  IN = ':IN:',
  SMALLER_EQUAL = ':<=:',
  BIGGER_EQUAL = ':>=:',
  SMALLER = ':<:'
}

export enum ShiftNames {
  T = 'afternoon',
  M = 'morning',
  N = 'night'
}

export interface SelectedItemsProps {
  key: string;
  text: string;
}

export interface FiltersItem {
  name: string;
  values: SelectedItemsProps[];
}

export interface TimeGraphStatus {
  generatedAt: string;
  fromDateTime: string;
  toDateTime: string;
  searchModality: SearchModality | undefined;
  status: StatusTypes | null;
  searchBy: NameValues.CREATED_AT | NameValues.CONFIRMATION_DATE;
}

export enum SearchModality {
  TO = 'TO',
  BETWEEN = 'BETWEEN',
  FROM = 'FROM'
}

export const NO_DATA: string = '-';
