import { IDeliveryTimeSlotsRangeDetailsDTO } from 'modules/outbound/deliveryTime/infrastructure/dto/DeliveryTimeSlotsRangeDetailsDTO';

export interface IDeliveryTimeSlotsRangeDetails {
  averageTime: { days: number; hours: number };
}

export enum DeliveryTimeSlotsRangeNames {
  OB = 'bankOrder',
  FUToA500 = 'weekOrdersProductionToOrdersManufacturing',
  Zp8 = 'carsBetweenFactoryAndRedistributionWarehouse'
}

class DeliveryTimeSlotsRangeDetails implements IDeliveryTimeSlotsRangeDetails {
  averageTime: { days: number; hours: number };

  constructor({ averageTime }: DeliveryTimeSlotsRangeDetails) {
    this.averageTime = averageTime;
  }

  static generateFromDTO({
    averageTime
  }: IDeliveryTimeSlotsRangeDetailsDTO): DeliveryTimeSlotsRangeDetails {
    return new DeliveryTimeSlotsRangeDetails({
      averageTime: {
        days: Math.trunc(averageTime),
        hours: Math.trunc((averageTime % 1) * 24)
      }
    });
  }

  static generateEmpty() {
    return new DeliveryTimeSlotsRangeDetails({
      averageTime: { days: 0, hours: 0 }
    });
  }
}

export default DeliveryTimeSlotsRangeDetails;
