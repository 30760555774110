import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NameValues,
  FiltersItem
} from 'modules/inbound/shared/domain/NameValues';
import getNoJitFiltersService from 'modules/inbound/nojit/application/GetNoJitFiltersService';
import { getSelectedHeaderFilters } from '.';

const getNoJitFilters = createAsyncThunk(
  `noJitFilters/getNoJitFilters`,
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedCountries,
      selectedLines,
      selectedInbounders,
      selectedModels,
      selectedWarehouses
    } = getSelectedHeaderFilters(appState);

    const queryFilters: FiltersItem[] = [];

    queryFilters.push({
      name: NameValues.COUNTRIES,
      values: selectedCountries
    });
    queryFilters.push({
      name: NameValues.LINES,
      values: selectedLines
    });
    queryFilters.push({
      name: NameValues.INBOUNDER,
      values: selectedInbounders
    });
    queryFilters.push({
      name: NameValues.MODELS,
      values: selectedModels
    });
    queryFilters.push({
      name: NameValues.WAREHOUSE_CODES,
      values: selectedWarehouses
    });

    let querySearch: string[] = [];

    queryFilters.forEach((filter) => {
      const values = filter.values.map((value) => value.key);
      if (
        (filter.name === NameValues.LINES ||
          filter.name === NameValues.MODELS) &&
        !!filter.values.length
      ) {
        querySearch.push(`${filter.name}:LK:(${values.join(';')})`);
      } else {
        if (filter.values.length > 1) {
          querySearch.push(`${filter.name}:IN:(${values.join(';')})`);
        }
        if (filter.values.length === 1) {
          querySearch.push(`${filter.name}:=:${values}`);
        }
      }
      return '';
    });

    const query = querySearch.filter((item) => item !== '').join('||');
    const queryParams = {
      search: query && `{${query}}`
    };
    const response = await getNoJitFiltersService.execute({ queryParams });
    return response;
  }
);

export { getNoJitFilters };
