import { createSlice } from '@reduxjs/toolkit';
import { getPartCoverageFilters } from './async';
import { FiltersState } from './types';
import { FlowTypeEnum } from 'modules/inbound/jit/infrastructure/dto/JitFlowTypesDTO';

export const initialState: FiltersState = {
  filters: {
    family: [],
    lines: [],
    providersTier1Ids: [],
    logisticOperatorsIds: [],
    models: [],
    inbounders: [],
    flowTypes: []
  },
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedFamily: [],
    selectedLines: [],
    selectedProvidersTier1Ids: [],
    selectedLogisticOperatorsIds: [],
    selectedModels: [],
    selectedInbounders: [],
    selectedFlowType: { key: '', text: '' }
  },
  search: '',
  isFilterSaved: false
};

export const PART_COVERAGE_FILTERS_STATE_KEY = 'PartCoverageState';

const PartCoverageFiltersSlice = createSlice({
  name: 'partCoverageFilters',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedFamily: (state, action) => {
      state.selectedSubHeaderFilters.selectedFamily = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedProvidersTier1Ids: (state, action) => {
      state.selectedSubHeaderFilters.selectedProvidersTier1Ids = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLogisticOperatorsIds: (state, action) => {
      state.selectedSubHeaderFilters.selectedLogisticOperatorsIds =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedInbounders: (state, action) => {
      state.selectedSubHeaderFilters.selectedInbounders = action.payload;
    },
    updateSelectedFlowType: (state, action) => {
      state.selectedSubHeaderFilters.selectedFlowType = action.payload;
      state.isFilterSaved = false;
    },
    resetFilters: (state) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
    },
    removeFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${PART_COVERAGE_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${PART_COVERAGE_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${PART_COVERAGE_FILTERS_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        state.selectedSubHeaderFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;
        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPartCoverageFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartCoverageFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartCoverageFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getFamily = (state) =>
  state.partCoverageFiltersState.filters.family;
export const getLines = (state) => state.partCoverageFiltersState.filters.lines;
export const getProvidersTier1Ids = (state) =>
  state.partCoverageFiltersState.filters.providersTier1Ids;
export const getLogisticOperatorsIds = (state) =>
  state.partCoverageFiltersState.filters.logisticOperatorsIds;
export const getModels = (state) =>
  state.partCoverageFiltersState.filters.models;
export const getInbounders = (state) =>
  state.partCoverageFiltersState.filters.inbounders;
export const getFlowTypes = (state) =>
  state.partCoverageFiltersState.selectedSubHeaderFilters.selectedFlowType.key
    ? [FlowTypeEnum.NONE, FlowTypeEnum.SYNCRO, FlowTypeEnum.NO_SYNCRO]
    : state.partCoverageFiltersState.filters.flowTypes;
export const getFilterSearch = (state) => state.partCoverageFiltersState.search;
export const getSelectedSubheaderFilters = (state) =>
  state.partCoverageFiltersState.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.partCoverageFiltersState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  !!state.partCoverageFiltersState.selectedSubHeaderFilters.selectedFlowType
    .key ||
  Object.keys(state.partCoverageFiltersState.selectedSubHeaderFilters).some(
    (key) => state.partCoverageFiltersState.selectedSubHeaderFilters[key].length
  );

export const {
  updateFilterSearch,
  updateSelectedFamily,
  updateSelectedLines,
  updateSelectedProvidersTier1Ids,
  updateSelectedLogisticOperatorsIds,
  updateSelectedModels,
  updateSelectedInbounders,
  updateSelectedFlowType,
  resetFilters,
  removeFilters,
  saveFilters,
  loadFilters
} = PartCoverageFiltersSlice.actions;

export default PartCoverageFiltersSlice.reducer;
