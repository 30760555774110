import React from 'react';
import { useField } from 'modules/shared/hooks/useField';
import { FormInput } from '../../elements/Inputs/FormInput';
import { Button, Link } from '../../pages/LoginPage/styles';
import { RecoverPasswordFormProps } from './types';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { LoginTranslations } from 'modules/shared/infrastructure/i18n/Login/typing';
import { LoginText } from 'modules/shared/infrastructure/i18n/Login';

const loginTranslations: LoginTranslations = {
  ...LoginText[LocaleService.getLanguage()]
};

export const RecoverPasswordForm = ({
  handleBackToLogin,
  handleRecoverPassword
}: RecoverPasswordFormProps) => {
  const username = useField({ type: 'text' });

  const handleSendRecoveryCode = (event) => {
    event.preventDefault();
    handleRecoverPassword({
      username: username.value
    });
  };

  return (
    <>
      <h3>{loginTranslations.passwordRecovery}</h3>
      <p>{loginTranslations.enterUsername}</p>
      <form onSubmit={handleSendRecoveryCode} data-cy="recover-password-form">
        <FormInput
          {...username}
          name="username"
          label={loginTranslations.username}
          required
        />
        <Link type="button" onClick={handleBackToLogin}>
          {loginTranslations.returnToLogin}
        </Link>
        <Button type="submit" disabled={!username.value.length}>
          {loginTranslations.sendCode}
        </Button>
      </form>
    </>
  );
};
