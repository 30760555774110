import IApi from 'modules/shared/domain/IApi';
import IAuthenticationService from 'modules/shared/domain/IAuthenticationService';
import { authenticationService } from 'modules/shared/services/AuthenticationService';

class Api implements IApi {
  private _authenticationService: IAuthenticationService;
  constructor({ authenticationService }) {
    this._authenticationService = authenticationService;
  }

  get(url) {
    return this._authenticationService
      .checkSession()
      .then(() => {
        const headers = {
          Authorization: this._authenticationService.getIdToken()
        };
        return fetch(url, {
          headers
        });
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`${res.status}`);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  getBlob({ url, headers }) {
    return this._authenticationService
      .checkSession()
      .then(() => {
        let constructedHeaders = {
          Authorization: this._authenticationService.getIdToken()
        };
        if (Object.keys(headers).length) {
          constructedHeaders = { ...constructedHeaders, ...headers };
        }

        return fetch(url, {
          headers: constructedHeaders
        });
      })
      .then((res) => {
        if (res.ok) {
          return res.body;
        }
        throw new Error(`${res.status}`);
      })
      .then((body) => {
        const response = new Response(body);
        return response.blob();
      })
      .then((blob) => new Blob([blob]))
      .catch((err) => {
        throw new Error(err);
      });
  }

  post(url, body) {
    return this._authenticationService
      .checkSession()
      .then(() => {
        const headers = {
          Authorization: this._authenticationService.getIdToken(),
          'Content-Type': 'application/json'
        };

        return fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        });
      })
      .then((res) => res.text())
      .then((text) => (text.length ? JSON.parse(text) : null))
      .catch((err) => {
        throw new Error(err);
      });
  }

  put(url, body) {
    return this._authenticationService
      .checkSession()
      .then(() => {
        const headers = {
          Authorization: this._authenticationService.getIdToken(),
          'Content-Type': 'application/json'
        };

        return fetch(url, {
          method: 'PUT',
          headers,
          body: JSON.stringify(body)
        });
      })
      .then((res) => res.json())
      .catch((err) => {
        throw new Error(err);
      });
  }

  delete(url) {
    return this._authenticationService
      .checkSession()
      .then(() => {
        const headers = {
          Authorization: this._authenticationService.getIdToken(),
          'Content-Type': 'application/json'
        };

        return fetch(url, {
          method: 'DELETE',
          headers
        });
      })
      .then((res) => {
        if (res.status === 204) {
          return res.status;
        }
        return res.json();
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
}

export default new Api({ authenticationService: authenticationService });

export { Api };
