import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { API_GET_GLOBAL_DELAY } from './urls';
import IGlobalDelayRepository from '../../domain/IGlobalDelayRepository';
import GlobalDelayDTO from '../dto/GlobalDelayDTO';

class GlobalDelayRepository
  extends BaseRepository
  implements IGlobalDelayRepository
{
  private _api: IApi;
  private _apiGetGlobalDelay: string;

  constructor({ api, apiGetGlobalDelay }) {
    super();
    this._api = api;
    this._apiGetGlobalDelay = apiGetGlobalDelay;
  }

  getGlobalDelay(): Promise<GlobalDelayDTO> {
    return this._api
      .get(this._apiGetGlobalDelay)
      .then(
        (response) => new GlobalDelayDTO(response.globalGeolocationDeliveries)
      );
  }
}

export default new GlobalDelayRepository({
  api: api,
  apiGetGlobalDelay: API_GET_GLOBAL_DELAY
});

export { GlobalDelayRepository };
