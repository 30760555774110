import { createSlice } from '@reduxjs/toolkit';

import {
  checkUser,
  forgotPassword,
  forgotPasswordSubmit,
  login,
  resetPassword,
  getUserGroup
} from './async';
import { UserState } from './types';
import { AppState } from 'infrastructure/redux';
import { InitSessionOutput } from 'modules/shared/domain/IAuthenticationService';
import { ConfirmSignInOutput } from 'aws-amplify/auth';

const NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED';
const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';
const CODE_EXPIRED_EXCEPTION = 'ExpiredCodeException';

export const initialState: UserState = {
  isSignIn: false,
  username: null,
  loginRequestStatus: '',
  checkUserRequestStatus: '',
  forgotPasswordRequestStatus: '',
  forgotPasswordSubmitRequestStatus: '',
  newPasswordRequired: false,
  resetPasswordRequestStatus: '',
  userGroup: [],
  getUserGroupRequestStatus: '',
  forgotPasswordCodeError: false
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isSignIn = false;
      state.username = initialState.username;
      state.loginRequestStatus = initialState.loginRequestStatus;
      state.checkUserRequestStatus = initialState.checkUserRequestStatus;
      state.forgotPasswordRequestStatus =
        initialState.forgotPasswordRequestStatus;
      state.forgotPasswordSubmitRequestStatus =
        initialState.forgotPasswordSubmitRequestStatus;
      state.newPasswordRequired = initialState.newPasswordRequired;
      state.resetPasswordRequestStatus =
        initialState.resetPasswordRequestStatus;
      state.userGroup = initialState.userGroup;
      state.getUserGroupRequestStatus = initialState.getUserGroupRequestStatus;
    },
    resetRequestStatus: (state) => {
      state.resetPasswordRequestStatus =
        initialState.resetPasswordRequestStatus;
      state.forgotPasswordCodeError = initialState.forgotPasswordCodeError;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { signInOutput, username }: InitSessionOutput = action.payload;
      if (signInOutput.nextStep.signInStep === NEW_PASSWORD_REQUIRED) {
        state.newPasswordRequired = true;
      } else {
        state.isSignIn = signInOutput.isSignedIn;
        state.username = username;
        state.newPasswordRequired = false;
      }
      state.loginRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(login.pending, (state, action) => {
      state.loginRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const confirmSignInOutput: ConfirmSignInOutput = action.payload;
      state.isSignIn = confirmSignInOutput.isSignedIn;
      state.newPasswordRequired = false;
      state.resetPasswordRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resetPasswordRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(checkUser.fulfilled, (state, action) => {
      state.isSignIn = !!action.payload;
      state.username = action.payload || null;
      state.checkUserRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(checkUser.pending, (state, action) => {
      state.checkUserRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(checkUser.rejected, (state, action) => {
      state.loginRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPasswordRequestStatus = action.meta.requestStatus;
      state.loginRequestStatus = initialState.loginRequestStatus;
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.forgotPasswordRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPasswordRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(forgotPasswordSubmit.fulfilled, (state, action) => {
      state.forgotPasswordSubmitRequestStatus = action.meta.requestStatus;
      state.forgotPasswordCodeError = false;
    });
    builder.addCase(forgotPasswordSubmit.pending, (state, action) => {
      state.forgotPasswordSubmitRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(forgotPasswordSubmit.rejected, (state, action) => {
      if (
        action.error.message === CODE_MISMATCH_EXCEPTION ||
        action.error.message === CODE_EXPIRED_EXCEPTION
      ) {
        state.forgotPasswordCodeError = true;
      }
      state.forgotPasswordSubmitRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserGroup.fulfilled, (state, action) => {
      state.userGroup = action.payload;
      state.getUserGroupRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserGroup.pending, (state, action) => {
      state.getUserGroupRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserGroup.rejected, (state, action) => {
      state.getUserGroupRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getUsername = (state) => state.userState.username;

export const getLoginRequestStatusRejected = (state: AppState) =>
  state.userState.loginRequestStatus === 'rejected';

export const getIsUserLoggedIn = (state: AppState) => state.userState.isSignIn;

export const getCheckUserRequestStatusFulfilled = (state: AppState) =>
  state.userState.checkUserRequestStatus === 'fulfilled';

export const getNewPasswordRequired = (state: AppState) =>
  state.userState.newPasswordRequired;

export const getForgotPasswordCodeError = (state: AppState) =>
  state.userState.forgotPasswordCodeError;

export const getForgotPasswordRequestStatusFulfilled = (state: AppState) =>
  state.userState.forgotPasswordSubmitRequestStatus === 'fulfilled';

export const getForgotPasswordRequestStatusRejected = (state: AppState) =>
  state.userState.forgotPasswordSubmitRequestStatus === 'rejected';

export const getLogedUserGroup = (state: AppState) => state.userState.userGroup;

export const { logout, resetRequestStatus } = UserSlice.actions;

export const userReducer = UserSlice.reducer;
