interface IForecast {
  weekNumber: number;
  inputs: number;
  outputs: number;
  stock: number;
}
class WarehouseForecastByModelDTO {
  forecast: IForecast[];
  maxCapacity: number;

  constructor({ forecast, maxCapacity }) {
    this.forecast = forecast;
    this.maxCapacity = maxCapacity;
  }
}

export default WarehouseForecastByModelDTO;
