import { createSlice } from '@reduxjs/toolkit';
import {
  getCommercialPackages,
  getDeliveryPoints,
  getLastReceivedDateHifaFile,
  getGlobalDelay,
  getFactoryDelay,
  getLoadsStatus,
  getStockAging,
  getStockOccupation,
  getStockSaturation
} from './async';
import { SharedDataState } from './types';
import localeService from 'infrastructure/i18n/LocaleService';
import CountryDeliveryPoints from 'modules/outbound/shared/domain/CountryDeliveryPoints';
import LoadsStatus from 'modules/outbound/shared/domain/LoadsStatus';
import OccupationStock from 'modules/outbound/shared/domain/OccupationStock';

export const initialState: SharedDataState = {
  commercialPackages: [],
  requestStatus: '',
  lastReceivedHifaFileDate: null,
  lastReceivedHifaFileDateRequestStatus: '',
  deliveryPoints: [],
  deliveryPointsRequestStatus: '',
  globalDelay: null,
  globalDelayRequestStatus: '',
  factoryDelay: null,
  factoryDelayRequestStatus: '',
  loadsStatus: null,
  todayLoads: null,
  loadsStatusRequestStatus: '',
  stockAging: { total: null, blocked: null, notBlocked: null },
  stockAgingRequestStatus: '',
  occupationStock: OccupationStock.generateEmpty(),
  occupationStockRequestStatus: '',
  daysForSaturation: 0,
  stockSaturationRequestStatus: ''
};
const sharedDataSlice = createSlice({
  name: 'sharedData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommercialPackages.fulfilled, (state, action) => {
      state.commercialPackages = action.payload || [];
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCommercialPackages.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCommercialPackages.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLastReceivedDateHifaFile.fulfilled, (state, action) => {
      state.lastReceivedHifaFileDate = action.payload || null;
      state.lastReceivedHifaFileDateRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLastReceivedDateHifaFile.pending, (state, action) => {
      state.lastReceivedHifaFileDateRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLastReceivedDateHifaFile.rejected, (state, action) => {
      state.lastReceivedHifaFileDateRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryPoints.fulfilled, (state, action) => {
      state.deliveryPoints = action.payload || [];
      state.deliveryPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryPoints.pending, (state, action) => {
      state.deliveryPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDeliveryPoints.rejected, (state, action) => {
      state.deliveryPointsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDelay.fulfilled, (state, action) => {
      state.globalDelay = action.payload || null;
      state.globalDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDelay.pending, (state, action) => {
      state.globalDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDelay.rejected, (state, action) => {
      state.globalDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryDelay.fulfilled, (state, action) => {
      state.factoryDelay = action.payload || null;
      state.factoryDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryDelay.pending, (state, action) => {
      state.factoryDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryDelay.rejected, (state, action) => {
      state.factoryDelayRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLoadsStatus.fulfilled, (state, action) => {
      state.loadsStatus = action.payload.loadsStatus || null;
      state.todayLoads = action.payload.todayLoads || null;
      state.loadsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLoadsStatus.pending, (state, action) => {
      state.loadsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getLoadsStatus.rejected, (state, action) => {
      state.loadsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockAging.fulfilled, (state, action) => {
      state.stockAging = action.payload || initialState.stockAging;
      state.stockAgingRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockAging.pending, (state, action) => {
      state.stockAgingRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockAging.rejected, (state, action) => {
      state.stockAgingRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockOccupation.fulfilled, (state, action) => {
      state.occupationStock = action.payload;
      state.occupationStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockOccupation.pending, (state, action) => {
      state.occupationStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockOccupation.rejected, (state, action) => {
      state.occupationStockRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockSaturation.fulfilled, (state, action) => {
      state.daysForSaturation = action.payload.daysForSaturation;
      state.stockSaturationRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockSaturation.pending, (state, action) => {
      state.stockSaturationRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockSaturation.rejected, (state, action) => {
      state.stockSaturationRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getCommercialPackagesList = (state) =>
  state.sharedDataState.commercialPackages;

export const getLastReceivedHifaFileDate = (state) => {
  if (state.sharedDataState.lastReceivedHifaFileDate instanceof Date !== true) {
    return null;
  }

  return state.sharedDataState.lastReceivedHifaFileDate;
};

export const getLastReceivedHifaFileDateFormated = (state) => {
  if (state.sharedDataState.lastReceivedHifaFileDate instanceof Date !== true) {
    return null;
  }

  const language = localeService.getLanguage();

  const dateLocale = {
    es: 'es-ES',
    ca: 'ca-ES',
    en: 'en-GB'
  };

  const formatedDate = new Intl.DateTimeFormat(dateLocale[language], {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(state.sharedDataState.lastReceivedHifaFileDate);

  const formatedTime = new Intl.DateTimeFormat(dateLocale[language], {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Madrid'
  }).format(state.sharedDataState.lastReceivedHifaFileDate);

  return `${formatedDate} | ${formatedTime}`;
};

export const getLastReceivedHifaFileDateIsPending = (state) =>
  state.sharedDataState.lastReceivedHifaFileDateRequestStatus === 'pending';

export const getDeliveryPointsList = (state) => {
  const selectedCountries = state.filtersState.geolocation.selectedCountries;
  return state.sharedDataState.deliveryPoints
    .filter(({ countryCode }) => {
      if (!selectedCountries.length) return true;
      return selectedCountries.some((element) => element.key === countryCode);
    })
    .map((country: CountryDeliveryPoints) =>
      country.deliveryPoints.map((deliveryPoint) => deliveryPoint)
    )
    .flat();
};

export const getDeliveriesGlobalDelay = (state) =>
  state.sharedDataState.globalDelay;

export const getGlobalDelayStatusIsPending = (state) =>
  state.sharedDataState.globalDelayRequestStatus === 'pending';

export const getDeliveriesFactoryDelay = (state) =>
  state.sharedDataState.factoryDelay;

export const getFactoryDelayStatusIsPending = (state) =>
  state.sharedDataState.factoryDelayRequestStatus === 'pending';

export const getTodayLoadsData = (state) => state.sharedDataState.todayLoads;

export const getLoadsStatusData = (state) => state.sharedDataState.loadsStatus;

export const getLoadsStatusDataForProgressBar = (state): LoadsStatus => {
  const loadsStatus = state.sharedDataState.loadsStatus;
  if (!loadsStatus) {
    return {
      ACP: 0,
      PND: 0,
      DLY: 0,
      RQS: 0
    };
  }
  const totalValue =
    loadsStatus.ACP + loadsStatus.PND + loadsStatus.DLY + loadsStatus.RQS;
  const getPercentage = (value) => Math.round((value * 100) / totalValue);
  return {
    ACP: getPercentage(
      loadsStatus.ACP + loadsStatus.PND + loadsStatus.DLY + loadsStatus.RQS
    ),
    PND: getPercentage(loadsStatus.PND + loadsStatus.DLY + loadsStatus.RQS),
    DLY: getPercentage(loadsStatus.DLY + loadsStatus.RQS),
    RQS: getPercentage(loadsStatus.RQS)
  };
};

export const getLoadsStatusRequestIsPending = (state) =>
  state.sharedDataState.loadsStatusRequestStatus === 'pending';

export const getStockAgingForBarchart = (state) => {
  const totalStock = state.sharedDataState.stockAging.total;
  const blockedStock = state.sharedDataState.stockAging.blocked;
  const notBlockedStock = state.sharedDataState.stockAging.notBlocked;
  if (!totalStock) return null;
  return {
    total: [
      {
        stackedBars: [
          { number: blockedStock.total, color: 'red' },
          { number: notBlockedStock.total, color: 'green' }
        ],
        xAxisLabels: { left: 'Total', right: totalStock.total.toString() }
      }
    ],
    ranges: [
      {
        stackedBars: [
          { number: blockedStock.zeroToTwo, color: 'red' },
          { number: notBlockedStock.zeroToTwo, color: 'green' }
        ],
        xAxisLabels: { left: '0-2', right: totalStock.zeroToTwo.toString() }
      },
      {
        stackedBars: [
          { number: blockedStock.threeToTen, color: 'red' },
          { number: notBlockedStock.threeToTen, color: 'green' }
        ],
        xAxisLabels: { left: '3-10', right: totalStock.threeToTen.toString() }
      },
      {
        stackedBars: [
          { number: blockedStock.elevenToNinety, color: 'red' },
          { number: notBlockedStock.elevenToNinety, color: 'green' }
        ],
        xAxisLabels: {
          left: '11-90',
          right: totalStock.elevenToNinety.toString()
        }
      },
      {
        stackedBars: [
          { number: blockedStock.ninetyOnePlus, color: 'red' },
          { number: notBlockedStock.ninetyOnePlus, color: 'green' }
        ],
        xAxisLabels: { left: '91+', right: totalStock.ninetyOnePlus.toString() }
      }
    ],
    totalStock: totalStock.total
  };
};

export const getStockAgingRequestIsPending = (state) =>
  state.sharedDataState.stockAgingRequestStatus === 'pending';

export const getOccupationStock = (state) =>
  state.sharedDataState.occupationStock;

export const getGetOccupationStockRequestIsPending = (state) =>
  state.sharedDataState.occupationStockRequestStatus === 'pending';

export const getOccupationStockBrokenDown = (state) =>
  state.sharedDataState.occupationStock.martorell.brokenDown;

export const getDaysForSaturation = (state) =>
  state.sharedDataState.daysForSaturation;

export const getStockSaturationRequestStatusIsPending = (state) =>
  state.sharedDataState.stockSaturationRequestStatus === 'pending';

export default sharedDataSlice.reducer;
