import EndOfProductionSummary from '../domain/EndOfProductionSummary';
import IEndOfProductionDateRepository from '../domain/IEndOfProductionDateRepository';
import EndOfProductionDateRepository from '../infrastructure/repositories/EndOfProductionDateRepository';

class GetEndOfProductionSummaryService {
  private _endOfProductionRepository: IEndOfProductionDateRepository;

  constructor({ endOfProductionRepository }) {
    this._endOfProductionRepository = endOfProductionRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._endOfProductionRepository
      .getSummary({ queryParams })
      .then((res) => EndOfProductionSummary.generateFromDTO(res));
  }
}

export default new GetEndOfProductionSummaryService({
  endOfProductionRepository: EndOfProductionDateRepository
});
export { GetEndOfProductionSummaryService };
