import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import ITransferRepository from 'modules/inbound/transfer/domain/ITransferRepository';
import TransferStockDTO from 'modules/inbound/transfer/infrastructure/dto/TransferStockDTO';
import {
  API_GET_TRANSFERS_FILTERS,
  API_GET_TRANSFER_CRITICAL_STOCK,
  TRANSFER_NEXT_HOURS_ENDPOINT,
  TRANSFER_ENDPOINT,
  API_GET_TRANSFERS_NEXT_HOURS_FILTERS,
  API_GET_CONTAINER_NEEDS,
  API_GET_CONTAINER_NEEDS_NEXT_HOURS,
  API_GET_INCOMING_CRITICAL_STOCK
} from './urls';
import IncomingTransfersDTO from '../dto/IncomingTransfersDTO';
import { IncomingTransfersTimeRange } from '../../domain/IncomingTransfers';
import SummaryCriticalStockDTO from 'modules/inbound/shared/infraestructure/dto/SummaryCriticalStockDTO';
import TransfersFiltersDataDTO from '../dto/TransfersFiltersDTO';
import TransferStockNextHoursDTO from '../dto/TransferStockNextHoursDTO';
import ContainerNeedsDTO from '../dto/ContainerNeedsDTO';

class TransferRepository extends BaseRepository implements ITransferRepository {
  private _api: IApi;
  private _getTransferUrl: string;
  private _getIncomingMaterialsUrl: string;
  private _getCriticalStock: string;
  private _getTransfersFilters: string;
  private _getTransfersNextHoursFilters: string;
  private _getContainerNeeds: string;
  private _getContainerNeedsNextHours: string;
  private _getIncomingMaterialsCriticalStock: string;

  constructor({
    api,
    getTransferUrl,
    getIncomingMaterialsUrl,
    getCriticalStock,
    getTransfersFilters,
    getTransfersNextHoursFilters,
    getContainerNeeds,
    getContainerNeedsNextHours,
    getIncomingMaterialsCriticalStock
  }) {
    super();
    this._api = api;
    this._getTransferUrl = getTransferUrl;
    this._getIncomingMaterialsUrl = getIncomingMaterialsUrl;
    this._getCriticalStock = getCriticalStock;
    this._getTransfersFilters = getTransfersFilters;
    this._getTransfersNextHoursFilters = getTransfersNextHoursFilters;
    this._getContainerNeeds = getContainerNeeds;
    this._getContainerNeedsNextHours = getContainerNeedsNextHours;
    this._getIncomingMaterialsCriticalStock = getIncomingMaterialsCriticalStock;
  }

  getTransferStock({ queryParams }: { queryParams: object }) {
    const url = `${this._getTransferUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new TransferStockDTO(res));
  }

  getTransferNextHoursStock({ queryParams }: { queryParams: object }) {
    const url = `${this._getIncomingMaterialsUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new TransferStockNextHoursDTO(res));
  }

  getIncomingTransfers({ timeRange, queryParams }) {
    const url = `${this._getIncomingMaterialsUrl}${
      timeRange === IncomingTransfersTimeRange.days
        ? '/next_days'
        : '/next_hours'
    }${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new IncomingTransfersDTO(res));
  }

  getCriticalStock({ queryParams }) {
    const url = `${this._getCriticalStock}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new SummaryCriticalStockDTO(res));
  }

  getIncomingMaterialsCriticalStock({ queryParams }) {
    const url = `${
      this._getIncomingMaterialsCriticalStock
    }${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new SummaryCriticalStockDTO(res));
  }

  getFilters(isTransferFilters) {
    return this._api
      .get(
        isTransferFilters
          ? this._getTransfersFilters
          : this._getTransfersNextHoursFilters
      )
      .then((res) => new TransfersFiltersDataDTO(res));
  }

  getContainerNeeds(
    isNextHours: boolean,
    { queryParams }: { queryParams: object }
  ) {
    const url = `${
      isNextHours ? this._getContainerNeedsNextHours : this._getContainerNeeds
    }${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new ContainerNeedsDTO(res));
  }
}

export default new TransferRepository({
  api: api,
  getTransferUrl: TRANSFER_ENDPOINT,
  getIncomingMaterialsUrl: TRANSFER_NEXT_HOURS_ENDPOINT,
  getCriticalStock: API_GET_TRANSFER_CRITICAL_STOCK,
  getTransfersFilters: API_GET_TRANSFERS_FILTERS,
  getTransfersNextHoursFilters: API_GET_TRANSFERS_NEXT_HOURS_FILTERS,
  getContainerNeeds: API_GET_CONTAINER_NEEDS,
  getContainerNeedsNextHours: API_GET_CONTAINER_NEEDS_NEXT_HOURS,
  getIncomingMaterialsCriticalStock: API_GET_INCOMING_CRITICAL_STOCK
});

export { TransferRepository };
