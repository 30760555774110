import { createSlice } from '@reduxjs/toolkit';
import { AllProvidersState } from './types';
import { getAllProvidersData } from './async';

export const initialState: AllProvidersState = {
  providers: [],
  allProvidersRequestStatus: ''
};

const allProvidersDataSlice = createSlice({
  name: 'allProvidersData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProvidersData.fulfilled, (state, action) => {
      state.providers = action.payload.providers;
      state.allProvidersRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getAllProvidersData.pending, (state, action) => {
      state.allProvidersRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getAllProvidersData.rejected, (state, action) => {
      state.allProvidersRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllProviders = (state) => state.allProvidersState.providers;
export const getHasErrorFetchingSuppliers = (state) =>
  state.allProvidersState.allProvidersRequestStatus === 'rejected';

export default allProvidersDataSlice.reducer;
