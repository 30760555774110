import getDateRangeService, {
  DateRangeType,
  GetDateRangeService
} from 'modules/outbound/shared/domain/GetDateRangeService';
import { BrandEnum } from 'modules/shared/domain/enums';
import GlobalFulfillmentValueObject from '../domain/GlobalFulfillmentValueObject';
import ITransitRepository from '../domain/ITransitRepository';
import fulfillmentRepository from '../infrastructure/repositories/FulfillmentRepository';

class GetGlobalTransitFulfillmentUseCase {
  repository: ITransitRepository;
  dateRangeService: GetDateRangeService;

  constructor({ repository, dateRangeService }) {
    this.repository = repository;
    this.dateRangeService = dateRangeService;
  }
  execute({
    dateRangeKey,
    brands,
    versandtreue
  }: {
    dateRangeKey: DateRangeType;
    brands: BrandEnum[];
    versandtreue: boolean;
  }): Promise<{
    requestedDateGlobalFulfillmentPercentage: GlobalFulfillmentValueObject;
  }> {
    const dateRange = this.dateRangeService.formatDateFromRange(dateRangeKey);

    return this.repository.getGlobalKAPFulfillment!({
      queryParams: { ...dateRange, brands, versandtreue }
    }).then((globalFulfillment) => ({
      requestedDateGlobalFulfillmentPercentage:
        GlobalFulfillmentValueObject.generateFromDTO({
          globalKapDelayedCars:
            globalFulfillment.requestedDatesGlobalKap.delayedCars,
          globalKapTotalCars:
            globalFulfillment.requestedDatesGlobalKap.totalCars
        })
    }));
  }
}

export default new GetGlobalTransitFulfillmentUseCase({
  repository: fulfillmentRepository,
  dateRangeService: getDateRangeService
});

export { GetGlobalTransitFulfillmentUseCase };
