import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { ShiftItem } from 'modules/inbound/shared/domain/ShiftTypes';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import {
  DirectDeliveryItemDTO,
  DirectDeliveryTransportDTO
} from '../infrastructure/dto/DirectDeliveryDataDTO';
import { DirectDeliveryText } from '../infrastructure/i18n/DirectDeliveryText';
import { DirectDeliveryTranslations } from '../infrastructure/i18n/typing';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import { StickerColor } from 'modules/inbound/shared/ui/layout/elements/TransportSticker/types';

const directDeliveryTranslations: DirectDeliveryTranslations &
  InboundTranslations = {
  ...DirectDeliveryText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export interface DirectDeliveryTransport {
  code: string;
  providerId: number;
  plateNumber: string;
  deliveryTime: string;
  containerLabels: string[];
  amount: number;
  providerDeliveryNote: string;
}

interface PlanningDirectDelivery {
  date: string;
  shifts: ShiftItem[];
}

export interface DirectDeliveryPlanning {
  [key: string]: PlanningDirectDelivery;
}

export interface Coverage {
  covered: number;
  security: number;
  critical: number;
}

class DirectDelivery {
  uid: string;
  code: string;
  providerCode: string;
  description: string;
  warehouseId: string;
  models: string;
  providerId: number;
  stock: string;
  containersAtMarquee: number;
  containerId: number;
  containersInCycle: number;
  planning: DirectDeliveryPlanning;
  lines: string[][];
  finalStockQuantity: string;
  finalStockDatetime: string;
  coverageInHours: string;
  coverage: Coverage;
  lineMovementType: string;
  lineMovementAmount: string;
  lineMovementDate: string;
  averageConsumption: string | number;
  averageCoverageInHours: string;
  status: boolean;

  constructor({
    uid,
    code,
    providerCode,
    description,
    warehouseId,
    models,
    providerId,
    stock,
    containersAtMarquee,
    containerId,
    containersInCycle,
    planning,
    lines,
    finalStockQuantity,
    finalStockDatetime,
    coverageInHours,
    coverage,
    lineMovementType,
    lineMovementAmount,
    lineMovementDate,
    averageConsumption,
    averageCoverageInHours,
    status
  }: DirectDelivery) {
    this.uid = uid;
    this.code = code;
    this.providerCode = providerCode;
    this.description = description;
    this.warehouseId = warehouseId;
    this.models = models;
    this.providerId = providerId;
    this.stock = stock;
    this.containersAtMarquee = containersAtMarquee;
    this.containerId = containerId;
    this.containersInCycle = containersInCycle;
    this.planning = planning;
    this.lines = lines;
    this.finalStockQuantity = finalStockQuantity;
    this.finalStockDatetime = finalStockDatetime;
    this.coverageInHours = coverageInHours;
    this.coverage = coverage;
    this.lineMovementType = lineMovementType;
    this.lineMovementAmount = lineMovementAmount;
    this.lineMovementDate = lineMovementDate;
    this.averageConsumption = averageConsumption;
    this.averageCoverageInHours = averageCoverageInHours;
    this.status = status;
  }

  private static _formatThousandsDot(quantity) {
    if (quantity === null) {
      return NO_DATA;
    }
    const numberWithDots = quantity.toLocaleString('de-DE');
    if (numberWithDots === '1') {
      return `${numberWithDots} ${directDeliveryTranslations.unitSinglePiece}`;
    }
    return `${numberWithDots} ${directDeliveryTranslations.unitMultiplePieces}`;
  }

  private static _formatCoverage({ covered, critical, security }: Coverage) {
    if (covered === 0) {
      return {
        covered: 0,
        security: 0.1,
        critical: 100
      };
    }

    if (covered < critical) {
      return {
        covered: covered,
        security: 0.1,
        critical: 100
      };
    }

    if (covered > critical && covered < security) {
      return {
        covered: covered,
        security: 100,
        critical: 0.1
      };
    }

    if (covered > security) {
      return {
        covered: covered,
        security: 0.1,
        critical: 0.1
      };
    }

    return {
      covered: 0,
      security: 0,
      critical: 0
    };
  }

  private static _formatShifts(
    transit: DirectDeliveryTransportDTO
  ): DirectDeliveryTransport {
    return {
      code: transit.code,
      providerId: transit.provider_id,
      amount: transit.amount,
      deliveryTime: transit.delivery_datetime
        ? `${GetDateFormatedService.getTimeFormated(
            transit.delivery_datetime
          )}h`
        : NO_DATA,
      providerDeliveryNote: transit.provider_delivery_note || NO_DATA,
      plateNumber: transit.plate_number ?? NO_DATA,
      containerLabels: transit.container_labels ?? []
    };
  }

  private static _formatPlanning(planning): DirectDeliveryPlanning {
    let formatPlanning: DirectDeliveryPlanning = {};

    planning.forEach((planningItem) => {
      formatPlanning[planningItem.date] = {
        date: planningItem.date,
        shifts: planningItem.shifts.map((shift) => {
          return {
            shift: shift.shift,
            amount:
              shift.amount === shift.remaining_amount
                ? `${shift.amount}`
                : `${shift.amount} | ${
                    shift.remaining_amount >= 0 &&
                    Math.round(shift.remaining_amount)
                  }`,
            stickerAmount:
              shift.transits.length > 1
                ? undefined
                : `${shift.transits[0]?.amount}`,
            color: shift.transits.some(
              (transit) => new Date(transit.delivery_datetime) > new Date()
            )
              ? StickerColor.WHITE
              : StickerColor.DARKERGRAY,
            modalData: shift.transits.map((transit) => {
              return this._formatShifts(transit);
            })
          };
        })
      };
    });

    return formatPlanning;
  }

  private static _getLinesByPiece(planning) {
    let allPlanningLines: string[][] = [];
    planning.forEach((planningItem) => {
      allPlanningLines.push(planningItem.lines);
    });
    return allPlanningLines;
  }

  static generateFromDTO(dto: DirectDeliveryItemDTO): DirectDelivery {
    return new DirectDelivery({
      uid: dto.uid,
      code: dto.code,
      providerCode:
        dto.provider_code ?? directDeliveryTranslations.withoutReference,
      description: dto.description,
      warehouseId: dto.warehouse_id,
      models: dto.models.join(' | ') || NO_DATA,
      providerId: dto.provider_id,
      stock: this._formatThousandsDot(dto.stock),
      containersAtMarquee: dto.containers_at_marquee,
      containerId: dto.container_id,
      containersInCycle: dto.containers_in_cycle,
      planning: this._formatPlanning(dto.planning),
      lines: this._getLinesByPiece(dto.planning),
      finalStockQuantity: dto.final_stock_quantity
        ? this._formatThousandsDot(dto.final_stock_quantity)
        : `0 ${directDeliveryTranslations.unitSinglePiece}`,
      finalStockDatetime: dto.final_stock_datetime
        ? GetDateFormatedService.getShortDateTime(dto.final_stock_datetime)
        : NO_DATA,
      coverageInHours:
        dto.coverage_in_seconds === 'MAX'
          ? dto.coverage_in_seconds
          : GetDateFormatedService.formatSecondsToHours(
              dto.coverage_in_seconds
            ),
      coverage: this._formatCoverage(dto.coverage),
      lineMovementType: dto.last_line_movement.type.length
        ? dto.last_line_movement.type.join(' | ')
        : NO_DATA,
      lineMovementAmount: dto.last_line_movement.amount.length
        ? dto.last_line_movement.amount.join(' | ')
        : NO_DATA,
      lineMovementDate: dto.last_line_movement.date
        ? GetDateFormatedService.getShortDateTime(dto.last_line_movement.date)
        : NO_DATA,
      averageConsumption: this._formatThousandsDot(dto.average_consumption),
      averageCoverageInHours:
        dto.average_coverage_in_seconds === 'MAX'
          ? dto.average_coverage_in_seconds
          : GetDateFormatedService.formatSecondsToHours(
              dto.average_coverage_in_seconds
            ),
      status: dto.status
    });
  }
}

export { DirectDelivery };
