import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadProviderCodesUseCase from 'modules/inbound/shared/application/ProviderCodesUseCases/DownloadProviderCodesUseCase';
import UploadProviderCodesUseCase from 'modules/inbound/shared/application/ProviderCodesUseCases/UploadProviderCodesUseCase';
import convertToBase64 from 'modules/inbound/shared/domain/ConvertToBase64';
import { getProviderCodesExcelFile } from '.';

const downloadProviderCodes = createAsyncThunk(
  'providerCodes/downloadProviderCodes',
  async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone
    };
    const response = await DownloadProviderCodesUseCase.execute({
      queryParams
    });
    return response;
  }
);

const uploadProviderCodes = createAsyncThunk(
  `providerCodes/uploadProviderCodes`,
  async (_, { getState }) => {
    const appState = getState();
    const file: File = getProviderCodesExcelFile(appState);

    const base64File = await convertToBase64(file);
    const response = await UploadProviderCodesUseCase.execute({
      file: base64File
    });
    return response;
  }
);

export { downloadProviderCodes, uploadProviderCodes };
