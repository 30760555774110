import LocaleService from 'infrastructure/i18n/LocaleService';
import { ValidationError } from '../infrastructure/dto/UploadMasterResponseDTO';
import { MastersTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { MastersText } from 'modules/inbound/shared/infraestructure/i18n/mastersText';

const mastersTranslations: MastersTranslations = {
  ...MastersText[LocaleService.getLanguage()]
};

interface GenerateFromDTOParams {
  detail: {
    fields: string[];
  } | null;
  error: ValidationError;
}

class UploadMasterResponse {
  hasError: boolean;
  errorMessage: string | null;
  constructor({ hasError, errorMessage }) {
    this.hasError = hasError;
    this.errorMessage = errorMessage;
  }

  private static _getErrorMessage(
    error: ValidationError,
    fields: string[]
  ): string {
    switch (error) {
      case -1:
        return mastersTranslations.fieldsNotFound(fields);
      case -2:
        return mastersTranslations.haveNullValues(fields);
      case -3:
        return mastersTranslations.haveInvalidValues(fields);
      case -4:
        return mastersTranslations.haveInvalidEnumValues(fields);
      case -5:
        return mastersTranslations.haveTooLongValues(fields);
      case -6:
        return mastersTranslations.haveRepeatedValues(fields);
      default:
        return mastersTranslations.updateRejected;
    }
  }

  static generateFromDTO({
    detail,
    error
  }: GenerateFromDTOParams): UploadMasterResponse {
    if (detail?.fields) {
      return new UploadMasterResponse({
        hasError: true,
        errorMessage: this._getErrorMessage(error, detail.fields)
      });
    }

    return new UploadMasterResponse({
      hasError: false,
      errorMessage: null
    });
  }
}

export default UploadMasterResponse;
