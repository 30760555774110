import { createAsyncThunk } from '@reduxjs/toolkit';
import getTransfersFiltersUseCase from 'modules/inbound/transfer/application/GetTransfersFiltersUseCase';

export const getTransfersFilters = createAsyncThunk(
  'transfersFilters/getTransfersFilters',
  async () => {
    const response = await getTransfersFiltersUseCase.execute({
      isTransferFilters: true
    });
    return response;
  }
);

export const getTransfersNextHoursFilters = createAsyncThunk(
  'transfersFilters/getTransfersNextHoursFilters',
  async () => {
    const response = await getTransfersFiltersUseCase.execute({
      isTransferFilters: false
    });
    return response;
  }
);
