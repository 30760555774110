import {
  IPagination,
  Pagination
} from 'modules/inbound/shared/domain/IPagination';
import FactoryBcn from './FactoryBcn';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import FactoryBcnDataDTO from '../infrastructure/dto/FactoryBcnDataDTO';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { generatePlanningDatesColumns } from './generatePlanningDatesColumns';

export interface FactoryBcnData {
  bodyworkNeedsUpdatedAt: string;
  stockMrtUpdatedAt: string;
  stockBcnUpdatedAt: string;
  bodyworkPieces: FactoryBcn[];
  planningDates: string[];
  pagination: IPagination;
  planningColumns: ColumnProps[];
}

export const factoryBcnMapper = (dto: FactoryBcnDataDTO): FactoryBcnData => {
  return {
    bodyworkNeedsUpdatedAt: dto.bodywork_needs_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.bodywork_needs_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          dto.bodywork_needs_updated_at
        )}`
      : '-',
    stockMrtUpdatedAt: dto.stock_mrt_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.stock_mrt_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          dto.stock_mrt_updated_at
        )}`
      : '-',
    stockBcnUpdatedAt: dto.stock_bcn_updated_at
      ? `${GetDateFormatedService.getDateFormated(
          dto.stock_bcn_updated_at,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          dto.stock_bcn_updated_at
        )}`
      : '-',
    planningDates: dto.planning_dates ?? [],
    planningColumns: generatePlanningDatesColumns(
      dto.planning_dates,
      dto.bodywork_pieces[0]?.planning
    ),
    bodyworkPieces: dto.bodywork_pieces?.map((piece) =>
      FactoryBcn.generateFromDTO(piece)
    ),
    pagination: Pagination.generateFromDTO(dto.pagination)
  };
};
