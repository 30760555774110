import { createAsyncThunk } from '@reduxjs/toolkit';
import getEntriesInPlantDataUseCase from 'modules/inbound/entries/application/InPlant/GetEntriesInPlantDataUseCase';
import { getEntriesParalyzationUseCase } from 'modules/inbound/entries/application/InPlant/GetEntriesParalyzationUseCase';
import getFiltersQueryString from 'modules/inbound/shared/domain/GetFiltersQuery';
import {
  FiltersItem,
  NameValues
} from 'modules/inbound/shared/domain/NameValues';
import {
  getFilterSearch,
  getPageInfo,
  getSelectedEntryInPlant,
  getSelectedFilters,
  getSelectedParalyzationStatus,
  getSelectedColumn
} from '.';
import { getGlobalDlzSummary } from 'modules/inbound/entries/application/InPlant/GetGlobalDlzSummary';
import { getLastUpdateUseCase } from 'modules/inbound/shared/application/LastUpdateUseCases/GetLastUpdateUseCase';
import { LastUpdateType } from 'modules/inbound/shared/domain/LastUpdate/types';
import DownloadEntriesInPlantUseCase from 'modules/inbound/entries/application/InPlant/DownloadEntriesInPlantUseCase';
import { SelectedInPlantFilters } from './types';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getEntriesInPlantData = createAsyncThunk(
  'entriesInPlant/getEntriesInPlant',
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPageInfo(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const selectedParalyzationStatus = getSelectedParalyzationStatus(appState);
    const { warehouses, lastPositions }: SelectedInPlantFilters =
      getSelectedFilters(appState);
    const searchValue = getFilterSearch(appState);

    const queryFilters: FiltersItem[] = [
      { name: NameValues.WAREHOUSE, values: warehouses },
      { name: NameValues.LAST_POSITION, values: lastPositions }
    ];
    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;

    let querySearch: string[] = [];
    querySearch = getFiltersQueryString(queryFilters);
    searchValue &&
      querySearch.push(
        `${NameValues.CODE}:LK:${searchValue}|OR|${NameValues.VEHICLE_PLATE_NUMBER}:LK:${searchValue}|OR|${NameValues.TRAILER_PLATE_NUMBER}:LK:${searchValue}`
      );
    selectedParalyzationStatus && querySearch.push(selectedParalyzationStatus);

    const querySearchString = querySearch.join('||');

    const queryParams = {
      page: page,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: querySearchString && `{${querySearchString}}`
    };

    const response = await getEntriesInPlantDataUseCase.execute({
      queryParams
    });

    return response;
  }
);

const getEntryInPlantDetailData = createAsyncThunk(
  'entriesInPlant/getEntryInPlantDetailData',
  async (_, { getState }) => {
    const appState = getState();
    const { transportId } = getSelectedEntryInPlant(appState);

    const response = await getEntriesInPlantDataUseCase.getDetailInPlant({
      transportId: transportId
    });

    return response;
  }
);

const getWarehouseDlzDetail = createAsyncThunk(
  'entriesInPlant/getWarehouseDlzDetail',
  async (_, { getState }) => {
    const appState = getState();
    const { transportId } = getSelectedEntryInPlant(appState);

    const response =
      await getEntriesInPlantDataUseCase.getEntryWarehouseDlzDetail({
        transportId: transportId
      });

    return response;
  }
);

const getParalyzationSummary = createAsyncThunk(
  'entriesInPlant/getParalyzationSummary',
  () => getEntriesParalyzationUseCase.execute()
);

const getGlobalDlzSummaryUrl = createAsyncThunk(
  'entriesInPlant/getGlobalDlzSummaryUrl',
  () => getGlobalDlzSummary.execute()
);

const getEntriesInPlantLastUpdateData = createAsyncThunk(
  'entriesInPlant/getEntriesInPlantLastUpdateData',
  () =>
    getLastUpdateUseCase.execute({
      queryParams: { key: LastUpdateType.entriesInPlant }
    })
);

const downloadEntriesInPlant = createAsyncThunk(
  'entries/downloadEntriesInPlant',
  async (_, { getState }) => {
    const appState = getState();
    const selectedParalyzationStatus = getSelectedParalyzationStatus(appState);
    const { warehouses, lastPositions }: SelectedInPlantFilters =
      getSelectedFilters(appState);
    const searchValue = getFilterSearch(appState);

    let queryFilters: FiltersItem[] = [
      {
        name: NameValues.WAREHOUSE,
        values: warehouses
      },
      {
        name: NameValues.LAST_POSITION,
        values: lastPositions
      }
    ];

    let querySearch: string[] = [];
    querySearch = getFiltersQueryString(queryFilters);
    searchValue &&
      querySearch.push(
        `${NameValues.CODE}:LK:${searchValue}|OR|${NameValues.VEHICLE_PLATE_NUMBER}:LK:${searchValue}|OR|${NameValues.TRAILER_PLATE_NUMBER}:LK:${searchValue}`
      );
    selectedParalyzationStatus && querySearch.push(selectedParalyzationStatus);

    const querySearchString = querySearch.join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: querySearchString && `{${querySearchString}}`
    };
    const response = await DownloadEntriesInPlantUseCase.execute({
      queryParams
    });
    return response;
  }
);

export {
  getEntriesInPlantData,
  getEntriesInPlantLastUpdateData,
  getEntryInPlantDetailData,
  getWarehouseDlzDetail,
  getParalyzationSummary,
  getGlobalDlzSummaryUrl,
  downloadEntriesInPlant
};
