import { EntriesSummaryDTO } from '../../infrastructure/dto/NextHoursDTO/entriesSummaryDTOMapper';

export interface EntriesSummary {
  hasCriticalPieces: number;
  urgents: number;
}

export const entriesSummaryMapper = (
  dto: EntriesSummaryDTO
): EntriesSummary => {
  return {
    hasCriticalPieces: dto.entries.has_critical_pieces,
    urgents: dto.entries.urgents
  };
};
