import { createAsyncThunk } from '@reduxjs/toolkit';
import deliveriesGeolocationCheckpointsService from 'modules/outbound/fulfillment/services/DeliveriesGeolocationCheckpointsService';
import {
  getGeolocationFastLaneSelected,
  getGeolocationLogisticObjectiveSelected,
  getGeolocationSelectedBrands,
  getGeolocationSelectedCommercialPackagesInPlainFormat,
  getGeolocationSelectedDeliveryPointsInPlainFormat,
  getSelectedGeolocationClientTypeInPlainFormat,
  getSelectedGeolocationFactoryInPlainFormat
} from '../FiltersStateReducer';

const getDeliveriesGeolocationCheckpoints = createAsyncThunk(
  `deliveriesGeolocationCheckpoints/getDeliveriesGeolocationCheckpoints`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedCountryCode = appState.deliveriesGeolocationState.selected;
    const fastLaneSelected = getGeolocationFastLaneSelected(appState);
    const selectedClientType =
      getSelectedGeolocationClientTypeInPlainFormat(appState);
    const selectedFactory =
      getSelectedGeolocationFactoryInPlainFormat(appState);
    const selectedBrands = getGeolocationSelectedBrands(appState);
    const commercialPackagesSelected =
      getGeolocationSelectedCommercialPackagesInPlainFormat(appState);
    const logisticObjectiveSelected =
      getGeolocationLogisticObjectiveSelected(appState);
    const deliverypointsSelected =
      getGeolocationSelectedDeliveryPointsInPlainFormat(appState);
    const queryParams = {
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: selectedBrands,
      commercialPackages: commercialPackagesSelected,
      logisticObjective: logisticObjectiveSelected,
      deliveryPoints: deliverypointsSelected
    };
    const response = await deliveriesGeolocationCheckpointsService.get({
      countryCode: selectedCountryCode,
      queryParams
    });
    return response;
  }
);

export { getDeliveriesGeolocationCheckpoints };
