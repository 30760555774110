import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IMessagesRepository from '../../domain/InboundMessages/IMessagesRepository';
import { inboundMessagesDTOMapper } from '../dto/inboundMessagesDTOMapper';
import { API_GET_INBOUND_MESSAGES, API_POST_MESSAGE } from './url';

class MessagesRepository extends BaseRepository implements IMessagesRepository {
  private _api: IApi;
  private _apiInboundMessages: string;
  private _apiPostMessage: string;

  constructor({ api, apiInboundMessages, apiPostMessage }) {
    super();
    this._api = api;
    this._apiInboundMessages = apiInboundMessages;
    this._apiPostMessage = apiPostMessage;
  }

  getMessages({
    body
  }: {
    body: { target_type: string; target_uids: string[] };
  }) {
    return this._api
      .post(this._apiInboundMessages, body)
      .then((res) => inboundMessagesDTOMapper(res));
  }

  postMessage({
    body
  }: {
    body: { target_type: string; target_uid: string; content: string };
  }) {
    return this._api.post(this._apiPostMessage, body).then((res) => res);
  }

  deleteMessage(uid: string) {
    const url = `${this._apiPostMessage}/${uid}`;
    return this._api.delete(url).then((res) => res);
  }
}

export default new MessagesRepository({
  api: api,
  apiInboundMessages: API_GET_INBOUND_MESSAGES,
  apiPostMessage: API_POST_MESSAGE
});
export { MessagesRepository };
