import TransferStock from 'modules/inbound/transfer/domain/TransferStock';
import ITransferRepository from '../domain/ITransferRepository';
import transferRepository from '../infrastructure/repositories/TransferRepository';

class GetTransferStockUseCase {
  private _repository: ITransferRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<TransferStock> {
    return this._repository
      .getTransferStock({ queryParams })
      .then((res) => TransferStock.generateFromDTO(res));
  }
}

export default new GetTransferStockUseCase({
  repository: transferRepository
});

export { GetTransferStockUseCase };
