import GeolocatedTransport from '../domain/GeolocatedTransport';
import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from '../infrastructure/repositories/DeliveriesGeolocationRepository';

class GetGeolocatedTransportsUseCase {
  repository: ITransitRepository;

  constructor({ repository }) {
    this.repository = repository;
  }

  execute() {
    return this.repository.getGeolocatedTransports!().then(({ transports }) =>
      transports.map((transport) =>
        GeolocatedTransport.generateFromDTO(transport)
      )
    );
  }
}

export default new GetGeolocatedTransportsUseCase({
  repository: deliveriesGeolocationRepository
});

export { GetGeolocatedTransportsUseCase };
