import { createSlice } from '@reduxjs/toolkit';
import { downloadJitStock, getPartCoverage } from './async';
import { PartCoverageDataState } from './types';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';

export const initialState: PartCoverageDataState = {
  data: {
    planningDates: [],
    stock: [],
    updatePlanningDate: '-'
  },
  columns: [],
  savedColumns: [],
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  jitFile: null,
  downloadRequestStatus: ''
};

const PART_COVERAGE_COLUMNS_VIEW_STATE_KEY = 'PartCoverageColumnsView';

const PartCoverageSlice = createSlice({
  name: 'PartCoverage',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateColumnsList: (state, action) => {
      state.savedColumns = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${PART_COVERAGE_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: PART_COVERAGE_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPartCoverage.fulfilled, (state, action) => {
      state.data.planningDates = action.payload.planningDates;
      state.data.stock = action.payload.stock;
      state.columns = action.payload.columns || [];
      state.data.updatePlanningDate = action.payload.updatePlanning;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartCoverage.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartCoverage.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadJitStock.fulfilled, (state, action) => {
      state.jitFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'P_Jit_Part_Coverage'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadJitStock.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadJitStock.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getJitDataState = (state) => state.partCoverageState.data;
export const getColumns = (state) => state.partCoverageState.savedColumns;
export const getisLoadingStatus = (state) =>
  state.partCoverageState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.partCoverageState.requestStatus === 'rejected';
export const getCurrentPage = (state) =>
  state.partCoverageState.pagination.page;
export const getResultsPerPage = (state) =>
  state.partCoverageState.pagination.size;
export const getSelectedColumn = (state) =>
  state.partCoverageState.selectedColumn;
export const getTotalPages = (state) =>
  state.partCoverageState.pagination.pageCount;
export const getTotalCount = (state) =>
  state.partCoverageState.pagination.totalCount;
export const getDownloadJitStockSuccessful = (state) =>
  state.partCoverageState.downloadRequestStatus === 'fulfilled' &&
  state.partCoverageState.jitFile !== null;
export const getDownloadJitStockError = (state) =>
  state.partCoverageState.downloadRequestStatus === 'rejected';
export const getDownloadJitStockIsPending = (state) =>
  state.partCoverageState.downloadRequestStatus === 'pending';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateColumnsList,
  resetDownloadStatus,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView
} = PartCoverageSlice.actions;

export default PartCoverageSlice.reducer;
