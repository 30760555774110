import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface UnloadingPointsFiltersProps {
  [NameValues.UNLOADING_POINT]: string[];
  [NameValues.CARRIER]: string[];
  [NameValues.SUPPLIER]: string[];
  [NameValues.WAREHOUSE]: string[];
}

const isValid = (value: unknown): boolean => {
  return Array.isArray(value) && value.length > 0;
};

export const unloadingPointsQueryStringBuilder = (
  filters: UnloadingPointsFiltersProps
): string => {
  const filtersCopy = { ...filters };

  Object.entries(filtersCopy).forEach(([key, values]) => {
    if (!isValid(values)) {
      delete filtersCopy[key];
    }
  });

  return Object.keys(filtersCopy)
    .map((key) => `${key}${SearchOperators.IN}(${filtersCopy[key].join(';')})`)
    .join(SearchOperators.AND);
};
