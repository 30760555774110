import { createSlice } from '@reduxjs/toolkit';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { getUpcomingTransitsData, downloadUpcomingTransits } from './async';
import { UpcomingTransitsState } from './types';

export const initialState: UpcomingTransitsState = {
  data: {
    upcomingTransit: []
  },
  upcomingTransitFile: null,
  downloadRequestStatus: '',
  requestStatus: '',
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  }
};

const upcomingTransitsSlice = createSlice({
  name: 'upcomingTransitsData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getUpcomingTransitsData.fulfilled, (state, action) => {
      state.data.upcomingTransit = action.payload.upcomingTransit;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUpcomingTransitsData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUpcomingTransitsData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadUpcomingTransits.fulfilled, (state, action) => {
      state.upcomingTransitFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'Upcoming_transits'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadUpcomingTransits.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadUpcomingTransits.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getUpcomingTransits = (state) =>
  state.upcomingTransitsState.data.upcomingTransit;
export const getisLoadingStatus = (state) =>
  state.upcomingTransitsState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.upcomingTransitsState.requestStatus === 'rejected' ||
  !state.upcomingTransitsState.data.upcomingTransit.length;
export const getCurrentPage = (state) =>
  state.upcomingTransitsState.pagination.page;
export const getTotalPages = (state) =>
  state.upcomingTransitsState.pagination.pageCount;
export const getResultsPerPage = (state) =>
  state.upcomingTransitsState.pagination.size;
export const getTotalCount = (state) =>
  state.upcomingTransitsState.pagination.totalCount;
export const getDownloadUpcomingTransitsSuccessful = (state) =>
  state.upcomingTransitsState.downloadRequestStatus === 'fulfilled' &&
  state.upcomingTransitsState.endOfProductionDateFile !== null;
export const getUpcomingTransitsError = (state) =>
  state.upcomingTransitsState.downloadRequestStatus === 'rejected';
export const getDownloadUpcomingTransitsIsPending = (state) =>
  state.upcomingTransitsState.downloadRequestStatus === 'pending';
export const getDownloadUpcomingTransitsFile = (state) =>
  state.upcomingTransitsState.upcomingTransitFile;

export const { updateCurrentPage, resetDownloadStatus } =
  upcomingTransitsSlice.actions;
export default upcomingTransitsSlice.reducer;
