import { EntriesSummary } from '../../domain/NextHours/entriesSummaryMapper';
import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesSummaryUseCase {
  private _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<EntriesSummary> {
    return this._repository.getEntriesSummary({ queryParams });
  }
}

const getEntriesSummaryUseCase = new GetEntriesSummaryUseCase({
  repository: entriesRepository
});

export { GetEntriesSummaryUseCase, getEntriesSummaryUseCase };
