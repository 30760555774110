import { useState, useEffect } from 'react';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

interface Breakpoints {
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopLarge: boolean;
  isScreenLarge: boolean;
}

export const TABLET_BREAKPOINT = 960;
export const LAPTOP_BREAKPOINT = 1450;
export const DESKTOP_LARGE_BREAKPOINT = 1920;
export const SCREEN_LARGE_BREAKPOINT = 2956;

function useBreakpoints(): Breakpoints {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isTablet: windowSize.width ? windowSize.width < TABLET_BREAKPOINT : false,
    isDesktop: windowSize.width
      ? windowSize.width > TABLET_BREAKPOINT &&
        windowSize.width <= LAPTOP_BREAKPOINT
      : false,
    isDesktopLarge: windowSize.width
      ? windowSize.width > LAPTOP_BREAKPOINT
      : false,
    isScreenLarge: windowSize.width
      ? windowSize.width >= SCREEN_LARGE_BREAKPOINT
      : false
  };
}

export default useBreakpoints;
