/**
 * @ParalizationRightType
 * 0 -> Has no right for paralyzation
 * 1 -> Has the right of paralyzation
 *
 * @Urgent
 * 1 -> urgent
 * 0 -> not urgent
 *  */

export type ParalyzationRight = 0 | 1;
export type Urgent = 0 | 1;

export const ALL_UNLOADING_POINTS_STATE = [
  'BOOKED',
  'UNLOADING',
  'COMPLETED',
  'ADDITIONAL',
  'IN_PLANT'
] as const;

type UnloadingPointsStateTuple = typeof ALL_UNLOADING_POINTS_STATE;

export type UnloadingPointStateType = UnloadingPointsStateTuple[number];

export type UnknownUnloadingPointState = 'UNKNOWN';

export type AllUnloadingPointStateTypes =
  | UnloadingPointStateType
  | UnknownUnloadingPointState;
