import { createAsyncThunk } from '@reduxjs/toolkit';
import getEndOfProductionDateDataService from 'modules/inbound/endOfProductionDate/application/GetEndOfProductionDateDataService';
import getEndOfProductionSummaryService from 'modules/inbound/endOfProductionDate/application/GetEndOfProductionSummaryService';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { getPagination, getSelectedColumn } from '.';
import DownloadEndOfProductionDateUseCase from 'modules/inbound/endOfProductionDate/application/DownloadEndOfProductionDateUseCase';
import { EndOfStockSummaryType } from 'modules/inbound/endOfProductionDate/domain/EndOfProductionSummary';
import {
  getFilterSearch,
  getFormatedCustomDate,
  getSelectedEndOfStockSummary,
  getSelectedFilters
} from '../EndOfProductionFiltersReducer';
import { getLastUpdateUseCase } from 'modules/inbound/shared/application/LastUpdateUseCases/GetLastUpdateUseCase';
import { LastUpdateType } from 'modules/inbound/shared/domain/LastUpdate/types';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import {
  eopFiltersQueryBuilder,
  eopSearchQueryBuilder
} from 'modules/inbound/endOfProductionDate/application/EOPQueryStringBuilder';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';
import { IDateRange } from 'modules/inbound/endOfProductionDate/domain/IEndOfProductionDateRepository';

const getFiltersQuery = (state) => {
  const {
    selectedProviderInbounders,
    selectedStatus,
    selectedWarehouseCodes,
    selectedModels
  } = getSelectedFilters(state);
  const selectedEndOfStockSummary = getSelectedEndOfStockSummary(state);
  const expirationDate: IDateRange = getFormatedCustomDate(state);

  const filters = {
    [NameValues.PROVIDER_INBOUNDERS]: selectedProviderInbounders.map(
      (inbounder) => inbounder.key
    ),
    [NameValues.STATUS]: selectedStatus.map((status) => status.key),
    [NameValues.WAREHOUSE_CODES]: selectedWarehouseCodes.map(
      (warehouse) => warehouse.key
    ),
    [NameValues.MODELS]: selectedModels.map((model) => model.key),
    [NameValues.END_OF_STOCK_TYPE]: selectedEndOfStockSummary,
    [NameValues.EXPIRATION_DATE]: expirationDate
  };

  return eopFiltersQueryBuilder(filters);
};

const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+')
  };

  return eopSearchQueryBuilder(search);
};

const getEndOfProductionDateData = createAsyncThunk(
  'endOfProductionDateData/getEndOfProductionDateData',
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPagination(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;
    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      page: page,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: queryString && `{${queryString}}`
    };

    const response = await getEndOfProductionDateDataService.execute({
      queryParams
    });
    return response;
  }
);

const getEndOfStockSummaryData = createAsyncThunk(
  'endOfProductionDateData/getEndOfStockSummaryData',
  async () => {
    const queryParams = {
      end_of_stock_type: EndOfStockSummaryType.END_OF_STOCK
    };
    const response = await getEndOfProductionSummaryService.execute({
      queryParams
    });
    return response;
  }
);

const getLeftoverStockSummaryData = createAsyncThunk(
  'endOfProductionDateData/getLeftoverStockSummaryData',
  async () => {
    const queryParams = {
      end_of_stock_type: EndOfStockSummaryType.LEFTOVER_STOCK
    };
    const response = await getEndOfProductionSummaryService.execute({
      queryParams
    });
    return response;
  }
);

const getSleepersNoNeedsData = createAsyncThunk(
  'endOfProductionDateData/getSleepersNoNeedsData',
  async () => {
    const queryParams = {
      end_of_stock_type: EndOfStockSummaryType.SLEEPER_NO_NEEDS
    };
    const response = await getEndOfProductionSummaryService.execute({
      queryParams
    });
    return response;
  }
);

const getSleepersWithNeedsData = createAsyncThunk(
  'endOfProductionDateData/getSleepersWithNeedsData',
  async () => {
    const queryParams = {
      end_of_stock_type: EndOfStockSummaryType.SLEEPER_WITH_NEEDS
    };
    const response = await getEndOfProductionSummaryService.execute({
      queryParams
    });
    return response;
  }
);

const getInconsistenciesSummaryData = createAsyncThunk(
  'endOfProductionDateData/getInconsistenciesSummaryData',
  async () => {
    const queryParams = {
      end_of_stock_type: EndOfStockSummaryType.INCOHERENT
    };
    const response = await getEndOfProductionSummaryService.execute({
      queryParams
    });
    return response;
  }
);

const downloadEndOfProductionDate = createAsyncThunk(
  'endOfProductionDateData/downloadEndOfProductionDate',
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadEndOfProductionDateUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getStockCoverageUpdateDate = createAsyncThunk(
  'endOfProductionDateData/getStockCoverageUpdateDate',
  () =>
    getLastUpdateUseCase.execute({
      queryParams: { key: LastUpdateType.stockCoverage }
    })
);

const getNeedsUpdateDate = createAsyncThunk(
  'endOfProductionDateData/getNeedsUpdateDate',
  () =>
    getLastUpdateUseCase.execute({
      queryParams: { key: LastUpdateType.needs }
    })
);

const getStuecklisteVWUpdateDate = createAsyncThunk(
  'endOfProductionDateData/getStuecklisteVWUpdateDate',
  () =>
    getLastUpdateUseCase.execute({
      queryParams: { key: LastUpdateType.stuecklisteVW }
    })
);

export {
  getEndOfProductionDateData,
  getEndOfStockSummaryData,
  getLeftoverStockSummaryData,
  getSleepersNoNeedsData,
  getSleepersWithNeedsData,
  getInconsistenciesSummaryData,
  downloadEndOfProductionDate,
  getStockCoverageUpdateDate,
  getNeedsUpdateDate,
  getStuecklisteVWUpdateDate
};
