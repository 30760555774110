interface IProvidersDTO {
  id: number;
  name: string;
}

class NoJitFiltersDataDTO {
  countries: string[];
  lines: string[];
  providers: IProvidersDTO[];
  inbounders: string[];
  models: string[];
  workshops: string[];
  warehouses: string[];
  constructor({
    countries,
    lines,
    providers,
    inbounders,
    models,
    workshops,
    warehouses
  }) {
    this.countries = countries;
    this.lines = lines;
    this.providers = providers;
    this.inbounders = inbounders;
    this.models = models;
    this.workshops = workshops;
    this.warehouses = warehouses;
  }
}

export default NoJitFiltersDataDTO;
