import { SaturationUnloadingPointsDTO } from '../../infrastructure/dto/UnloadingPointsDTO/unloadingPointsDTOMapper';
import { UnloadingPoint } from './UnloadingPoint';

export interface SaturationUnloadingPoints {
  saturation: UnloadingPoint[];
}

const unloadingPointsMapper = (
  dto: SaturationUnloadingPointsDTO
): SaturationUnloadingPoints => ({
  saturation:
    dto.saturation?.map((saturationPoint) =>
      UnloadingPoint.generateFromDTO(saturationPoint)
    ) ?? []
});

export { unloadingPointsMapper };
