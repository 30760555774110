import IJitRepository from '../domain/IJitRepository';
import JitFamilyCoverageData from '../domain/JitFamilyCoverageData';
import jitRepository from '../infrastructure/repositories/JitRepository';

class GetJitFamilyCoverageService {
  private _jitRepository: IJitRepository;

  constructor({ repository }) {
    this._jitRepository = repository;
  }

  execute() {
    return this._jitRepository
      .getFamilyCoverage()
      .then((res) => JitFamilyCoverageData.generateFromDTO(res));
  }
}

export default new GetJitFamilyCoverageService({
  repository: jitRepository
});

export { GetJitFamilyCoverageService };
