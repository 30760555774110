import localeService from 'infrastructure/i18n/LocaleService';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';

interface GenerateFromDTOParams {
  vin: string;
  actualTimeline: ITimelineItemDTO[];
  expectedTimeline: ITimelineItemDTO[];
}

interface ITimelineItemDTO {
  checkpointName: string;
  days: number;
  date: ITimeLineDate;
}

export interface ITimelineItem {
  checkpointName: string;
  checkpointKey: string;
  days: number;
  date: IDateRange;
}
interface IDateRange {
  fromDate: string;
  toDate: string;
}
interface ITimeLineDate {
  start: string;
  end: string;
}

export interface IFinishedGoodCheckpointsTimeline {
  vin: string;
  actualTimeline: ITimelineItem[];
  actualTimelineTotalDays: number;
  expectedTimeline: ITimelineItem[];
  expectedTimelineTotalDays: number;
}

class FinishedGoodCheckpointsTimeline
  implements IFinishedGoodCheckpointsTimeline
{
  vin: string;
  actualTimeline: ITimelineItem[];
  actualTimelineTotalDays: number;
  expectedTimeline: ITimelineItem[];
  expectedTimelineTotalDays: number;

  constructor({
    vin,
    actualTimeline,
    expectedTimeline,
    actualTimelineTotalDays,
    expectedTimelineTotalDays
  }: FinishedGoodCheckpointsTimeline) {
    this.vin = vin;
    this.actualTimeline = actualTimeline;
    this.expectedTimeline = expectedTimeline;
    this.actualTimelineTotalDays = actualTimelineTotalDays;
    this.expectedTimelineTotalDays = expectedTimelineTotalDays;
  }

  static generateFromDTO({
    vin,
    actualTimeline,
    expectedTimeline
  }: GenerateFromDTOParams): FinishedGoodCheckpointsTimeline {
    return new FinishedGoodCheckpointsTimeline({
      vin,
      actualTimeline: actualTimeline.map((item) => ({
        ...item,
        checkpointName: this._getCheckpointNames(item.checkpointName),
        checkpointKey: item.checkpointName,
        date: {
          fromDate: item.date.start.length
            ? this._getDateFormated(item.date.start)
            : '',
          toDate: item.date.end.length
            ? this._getDateFormated(item.date.end)
            : ''
        }
      })),
      expectedTimeline: expectedTimeline.map((item) => ({
        ...item,
        checkpointName: this._getCheckpointNames(item.checkpointName),
        checkpointKey: item.checkpointName,
        date: {
          fromDate: item.date.start.length
            ? this._getDateFormated(item.date.start)
            : '',
          toDate: item.date.end.length
            ? this._getDateFormated(item.date.end)
            : ''
        }
      })),
      actualTimelineTotalDays: actualTimeline.reduce(
        (accumulator, currentValue) => accumulator + currentValue.days,
        0
      ),
      expectedTimelineTotalDays: expectedTimeline.reduce(
        (accumulator, currentValue) => accumulator + currentValue.days,
        0
      )
    });
  }

  static generateEmpty() {
    return new FinishedGoodCheckpointsTimeline({
      vin: '',
      actualTimeline: [],
      expectedTimeline: [],
      actualTimelineTotalDays: 0,
      expectedTimelineTotalDays: 0
    });
  }

  private static _getCheckpointNames(key) {
    const lang = localeService.getLanguage();
    const group = DETAILED_CHECKPOINTS_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.checkpoints[key];
  }

  private static _getDateFormated(date) {
    return new Date(date).toLocaleDateString('es-ES', {
      day: 'numeric',
      month: '2-digit',
      year: '2-digit'
    });
  }
}

export default FinishedGoodCheckpointsTimeline;
