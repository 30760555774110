export interface PieceDTO {
  code: string;
  coverage: number;
  warehouse: string;
  packages: number;
  container_type: string;
  container: string;
  is_critical: boolean;
}

export interface EntryDetailDTO {
  pieces: PieceDTO[];
}

const entryDetailDTOMapper = (data: any): EntryDetailDTO => ({
  pieces:
    data.pieces?.map((piece) => ({
      code: piece.code,
      coverage: piece.coverage,
      warehouse: piece.warehouse,
      packages: piece.packages,
      container_type: piece.container_type,
      container: piece.container,
      is_critical: piece.is_critical
    })) || []
});

export { entryDetailDTOMapper };
