export interface ProviderDTO {
  id: number;
  name: string;
}

class AllProvidersDTO {
  providers: ProviderDTO[];

  constructor({ providers }) {
    this.providers = providers;
  }
}
export default AllProvidersDTO;
