class WarehouseStockDTO {
  id: string;
  name: string;
  stock: number;
  capacity: number;
  invoiced: number;
  clientStock: number;
  importStock: number;
  fleetStock: number;
  fastLane: number;

  constructor({
    id,
    name,
    stock,
    capacity,
    invoiced,
    clientStock,
    importStock,
    fleetStock,
    fastLane
  }) {
    this.id = id;
    this.name = name;
    this.stock = stock;
    this.capacity = capacity;
    this.invoiced = invoiced;
    this.clientStock = clientStock;
    this.importStock = importStock;
    this.fleetStock = fleetStock;
    this.fastLane = fastLane;
  }
}

export default WarehouseStockDTO;
