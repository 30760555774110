import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NewPasswordForm } from './NewPasswordForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { RecoverPasswordForm } from './RecoverPasswordForm';
import {
  forgotPassword,
  forgotPasswordSubmit,
  resetPassword
} from 'modules/shared/infrastructure/redux/UserReducer/async';
import { PasswordRecoveryProps } from './types';

export const PasswordRecovery = ({
  handleBackToLogin,
  newPasswordRequired
}: PasswordRecoveryProps) => {
  const dispatch = useDispatch();

  const [codeSent, setCodeSent] = useState<boolean>(false);

  const handleRecoverPassword = ({ username }: { username: string }) => {
    dispatch(forgotPassword({ username }));
    setCodeSent(true);
  };

  const handleRecoveryPassword = ({
    username,
    code,
    password
  }: {
    username: string;
    code: string;
    password: string;
  }) => {
    dispatch(forgotPasswordSubmit({ username, code, password }));
  };

  const hanldeNewPassword = ({ password }) => {
    dispatch(resetPassword({ password }));
    handleBackToLogin();
  };

  const handleResendCodeClick = () => {
    setCodeSent(false);
  };

  return (
    <>
      {!codeSent && !newPasswordRequired && (
        <RecoverPasswordForm
          handleRecoverPassword={handleRecoverPassword}
          handleBackToLogin={handleBackToLogin}
        />
      )}
      {codeSent && !newPasswordRequired && (
        <ForgotPasswordForm
          handleResendCode={handleResendCodeClick}
          handleRecoveryPassword={handleRecoveryPassword}
          handleBackToLogin={handleBackToLogin}
        />
      )}
      {newPasswordRequired && (
        <NewPasswordForm hanldeNewPassword={hanldeNewPassword} />
      )}
    </>
  );
};
