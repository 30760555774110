import { FlowTypes } from './JitFlowTypesDTO';

interface IdAndNameDTO {
  id: number;
  name: string;
}

type ISequencerDTO = IdAndNameDTO;
type IProviderDTO = IdAndNameDTO;

export interface PartCoverageFiltersDTO {
  family: string[];
  lines: number[];
  tier_1_provider_ids: IProviderDTO[];
  logistic_operators_ids: ISequencerDTO[];
  models: string[];
  inbounders: string[];
  flow_types: FlowTypes;
}

class PartCoverageFiltersDataDTO {
  family: string[];
  lines: number[];
  tier_1_provider_ids: IProviderDTO[];
  logistic_operators_ids: ISequencerDTO[];
  models: string[];
  inbounders: string[];
  flow_types: FlowTypes;

  constructor({
    family,
    lines,
    tier_1_provider_ids,
    logistic_operators_ids,
    models,
    inbounders,
    flow_types
  }) {
    this.family = family;
    this.lines = lines;
    this.tier_1_provider_ids = tier_1_provider_ids;
    this.logistic_operators_ids = logistic_operators_ids;
    this.models = models;
    this.inbounders = inbounders;
    this.flow_types = flow_types || [];
  }
}

export default PartCoverageFiltersDataDTO;
