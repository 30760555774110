import { Translation } from 'infrastructure/i18n/typing';
import { InboundTranslations } from './typing';

export const InboundText: Translation<InboundTranslations> = {
  es: {
    more: '[...más]',
    filterVisibility: 'Visibilidad columnas',
    excel: 'Excel',
    showCompleteBoard: 'Eliminar filtros del gráfico',
    modelCarRelation:
      '<b>Modelo  -  Coche relación</b><ul><li>GBS - A1/AU270</li> <li>KJ1 - Ibiza/SE270</li> <li>KJ7 - Arona/SE216</li><li>KM7 - Formentor/SE316</li> <li>KL1 - Leon 5P/SE380 SEAT</li> <li>KL8 - Leon ST/SE382 SEAT</li> <li>KU1 – Leon 5P SE380 CUPRA</li> <li>KU8 – Leon ST SE382 CUPRA</li> <li>KMP/5FF - Formentor PA/SE316 PA</li> <li>KLG/5FN - Leon 5P/SE380 SEAT PA</li> <li>KLD/5FN - Leon ST/SE382 SEAT PA</li> <li>KUG/5FN - Leon 5P SE380 CUPRA PA</li> <li>KUD/5FN - Leon ST SE382 CUPRA PA</li></ul>',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    emptyMessage: 'Aún no hay datos disponibles.',
    alerts: 'Alertas',
    saveFilters: 'Guardar Filtros',
    savedFilters: 'Filtros Guardados',
    deleteFilters: 'Borrar Filtros',
    total: 'Total',
    searcherPlaceholder: 'Búsqueda por clave',
    download: 'Descargar',
    transfer: 'Directo de trasvase a línea',
    inTransit: 'En tránsito',
    delayed: 'Atrasado',
    nearDelay: 'A punto de demorar',
    onTime: 'Puntual',
    arrivalsNextDays: 'Llegadas próximos 8 días',
    arrivalsNextHours: 'Llegadas próximas 8h',
    currentShiftDescription:
      'Necesidad planificada | Necesidad restante <ul><li><b>Necesidad planificada:</b> Necesidad planificada en las 8h del turno según programa.</li><li><b>Necesidad restante:</b> Necesidad restante en el turno actual en función del tiempo transcurrido.</li>',
    selectTheFileToUpload: 'Seleccione el archivo a subir',
    toUploadAMasterFileRemember: '(i) Para actualizar el maestro recuerde:',
    upload: 'Cargar',
    uploadMasterInstructions: [
      'Subir un único archivo con una sola pestaña.',
      'Las cabeceras deben estar informadas en la primera fila informando todos los campos necesarios.',
      'Puede usar un archivo descargado como plantilla para realizar la actualización.',
      'Tenga en cuenta que el tamaño máximo del fichero es 10MB'
    ],
    filters: 'Filtros',
    movementLabel: 'Mov. a linea',
    movementType: 'Tipo mvt',
    movementAmount: 'Cantidad',
    movementDate: 'Fecha mvt',
    movementsLineTooltip:
      'Movimiento tipo: <br/><b>H:</b> Entrada <br/><b>S:</b> Devolución de linea',
    selectDays: 'Necesidades días',
    lastUpdate: 'Act. de datos: ',
    detail: 'Detalle',
    showDetail: 'Ver detalle',
    leaveComment: 'Deja un comentario',
    internalCommunication: 'Comunicación interna',
    noComments: 'No hay mensajes relativos a esta clave.',
    deleteMsg: '¿Quiere eliminar el mensaje seleccionado?',
    unitSingleDay: 'día',
    unitMultipleDays: 'días',
    unitSinglePiece: 'pza.',
    unitMultiplePieces: 'pzas.',
    timerTooltip: 'Activa para actualizar datos automáticamente cada ',
    minutes: 'minutos',
    listMaterialKeys: 'Relación claves de material',
    uploadKeysTitle:
      'Descargar plantilla y subir archivo con relación clave proveedor y SEAT',
    uploadKeysSubtitle: 'Para actualizar el Maestro recuerde:',
    donwloadTemplate: 'Descargar plantilla',
    selectFileFrom: 'Selecciona o arrastra el archivo de tu ordenador.',
    maximumSizeExcelFile: 'Tiene que ser un archivo .xlsx de máximo 10MB',
    startingUpload: 'Iniciando carga de archivo',
    providerPartTooltipInfo:
      'Por favor, en el caso de tener nomenclatura de claves de material distintas a SEAT, añada las claves de su compañía mediante el botón <b>Relación claves de material</b>.',
    selectFile: 'Seleccionar archivo',
    withoutReference: 'Sin referencia',
    contactName: 'Nombre de contacto',
    role: 'Rol',
    telephone: 'Número de teléfono',
    email: 'Email de contacto',
    contactInfo: 'Info contacto ',
    copyEmail: 'Clica para copiar la dirección de email',
    copyToClipboardSuccess: 'Elemento copiado al portapapeles',
    copyToClipboardFail: 'No hay elemento para copiar al portapapeles',
    copyElement: 'Clica para copiar elemento',
    date: 'Fecha: ',
    code: 'Clave',
    notApply: 'No aplica',
    addContact: 'Añadir contacto',
    maxCharacters: 'Máx 50 carácteres',
    confirm: 'Confirmar',
    emailError: 'El email de contacto no es válido',
    manageContacts: 'Gestionar contactos',
    deleteContact: 'Eliminar contacto',
    sureToDelete: 'Estás seguro que quieres eliminar al contacto',
    actionCannotBeUndone: 'Esta acción no se podrá deshacer',
    editContact: 'Editar contacto',
    save: 'Guardar',
    mandatoryPhoneOrEmail:
      '*Es obligatorio añadir un número de teléfono o email',
    multisearchUsageInfo:
      'Separe los valores usando el carácter + para realizar una búsqueda múltiple. <b>Pulsa Enter para buscar.</b>',
    gotIt: 'Entendido',
    scrollTop: 'Clica para subir al inicio del panel',
    scrollBottom: 'Clica para bajar al final del panel',
    newFunctionality: 'Nueva funcionalidad:',
    navigationByDraggingTheTable: 'Navegación arrastrando por la tabla',
    draggingNavigationFunctionalityDescription:
      'Ahora, disfruta de una experiencia de navegación más fluida y natural en nuestra tabla de necesidades y coberturas. Simplemente mantén presionado el ratón y arrastra para desplazarte horizontal y verticalmente con más facilidad.',
    prov: 'Prov.',
    status: 'Estado',
    inConsolidation: 'En consolidación',
    inExpedition: 'En expedición',
    unloadingInPlant: 'Descargando en planta',
    qty: 'Cant.',
    plateNumberModalTransport: 'Matrícula',
    tp: '(TP)',
    tr: '(TR)',
    ep: '(EP)',
    arrivalDate: 'Fecha llegada'
  },
  en: {
    more: '[...more]',
    filterVisibility: 'Columns visibility',
    excel: 'Excel',
    showCompleteBoard: 'Remove chart filters',
    modelCarRelation:
      '<b>Model  -  Car relation</b> <ul><li>GBS - A1/AU270</li> <li>KJ1 - Ibiza/SE270</li> <li>KJ7 - Arona/SE216</li><li>KM7 - Formentor/SE316</li> <li>KL1 - Leon 5P/SE380 SEAT</li> <li>KL8 - Leon ST/SE382 SEAT</li> <li>KU1 – Leon 5P SE380 CUPRA</li> <li>KU8 – Leon ST SE382 CUPRA</li> <li>KMP/5FF - Formentor PA/SE316 PA</li> <li>KLG/5FN - Leon 5P/SE380 SEAT PA</li> <li>KLD/5FN - Leon ST/SE382 SEAT PA</li> <li>KUG/5FN - Leon 5P SE380 CUPRA PA</li> <li>KUD/5FN - Leon ST SE382 CUPRA PA</li></ul>',
    cancel: 'Cancel',
    accept: 'Accept',
    emptyMessage: 'There is no available data.',
    alerts: 'Alerts',
    saveFilters: 'Save Filters',
    savedFilters: 'Filters Saved',
    deleteFilters: 'Reset Filters',
    total: 'Total',
    searcherPlaceholder: 'Search by part',
    download: 'Download',
    transfer: 'Direct transfer to line',
    inTransit: 'On route',
    delayed: 'Delayed',
    nearDelay: 'Near delay',
    onTime: 'On time',
    arrivalsNextDays: 'Next 8 days arrivals',
    arrivalsNextHours: 'Next 8 hours arrivals',
    currentShiftDescription:
      'Planned need | Remaining need<ul><li><b>Planned need:</b> Planned need in the 8 hours of the shift according to the schedule.</li><li><b>Remaining need:</b> Remaining need in the current shift based on elapsed time.</li></ul>',
    selectTheFileToUpload: 'Select the file to upload',
    upload: 'Upload',
    toUploadAMasterFileRemember: '(i) To update the master remember:',
    uploadMasterInstructions: [
      'Upload a single file with a single tab.',
      'The headers must be informed in the first row, informing all the necessary fields.',
      'You can use a downloaded file as a template to perform the update.',
      'Please note that the maximum file size is 10MB'
    ],
    filters: 'Filters',
    movementLabel: 'Mov. to line',
    movementType: 'Mvt type',
    movementAmount: 'Amount',
    movementDate: 'Date mvt',
    movementsLineTooltip:
      'Movement type: <br/><b>H:</b> Entrance <br/><b>S:</b> Line return',
    selectDays: 'Needs days',
    lastUpdate: 'Data upd.: ',
    detail: 'Detail',
    showDetail: 'Show detail',
    leaveComment: 'Leave a comment',
    internalCommunication: 'Internal Communication',
    noComments: 'There are no messages related to this key.',
    deleteMsg: 'Do you want to delete the selected message?',
    unitSingleDay: 'day',
    unitMultipleDays: 'days',
    unitSinglePiece: 'pc',
    unitMultiplePieces: 'pcs',
    timerTooltip: 'Activate to update data automatically every ',
    minutes: 'minutes',
    listMaterialKeys: 'List of material keys',
    uploadKeysTitle:
      'Download template and upload file with key supplier and SEAT relationship',
    uploadKeysSubtitle: 'To update the Master remember:',
    donwloadTemplate: 'Download template',
    selectFileFrom: 'Select or drag the file from your computer.',
    maximumSizeExcelFile: 'It has to be an .xlsx file of maximum 10MB',
    startingUpload: 'Starting file upload',
    providerPartTooltipInfo:
      "Please, in the case of having material code nomenclature other than SEAT, add your company's codes using the <b>List of material keys</b> button.",
    selectFile: 'Select file',
    withoutReference: 'Without reference',
    contactName: 'Contact contactName',
    role: 'Role',
    telephone: 'Phone number',
    email: 'Contact email',
    contactInfo: 'Contact info ',
    copyEmail: 'Click to copy the email address',
    copyToClipboardSuccess: 'Item copied to clipboard',
    copyToClipboardFail: 'No item to copy to clipboard',
    copyElement: 'Click to copy element',
    date: 'Date: ',
    code: 'Part',
    notApply: "Doesn't apply",
    addContact: 'Add contact',
    maxCharacters: 'Màx 50 caràcters',
    confirm: 'Confirm',
    emailError: 'The contact email is not valid',
    manageContacts: 'Manage contacts',
    deleteContact: 'Delete contact',
    sureToDelete: 'Are you sure you want to delete the contact',
    actionCannotBeUndone: 'This action can not be undone',
    editContact: 'Edit contact',
    save: 'Save',
    mandatoryPhoneOrEmail: '*It is mandatory to add a phone number or email',
    multisearchUsageInfo:
      'Separate the values using the + character to perform a multiple search. <b>Press Enter to search.</b>',
    gotIt: 'Got it',
    scrollTop: 'Click to go to the beginning of the panel',
    scrollBottom: 'Click to go down to the end of the panel',
    newFunctionality: 'New functionality',
    navigationByDraggingTheTable: 'Navigation by dragging through the table',
    draggingNavigationFunctionalityDescription:
      'Now, enjoy a more fluid and natural browsing experience in our needs and coverage table. Simply hold down your mouse and drag to scroll horizontally and vertically more easily.',
    prov: 'Prov.',
    status: 'Status',
    inConsolidation: 'In consolidation',
    inExpedition: 'In expedition',
    unloadingInPlant: 'Unloading in plant',
    qty: 'Qty.',
    plateNumberModalTransport: 'Plate number',
    tp: '(TP)',
    tr: '(TR)',
    ep: '(EP)',
    arrivalDate: 'Arrival date'
  },
  ca: {
    more: '[...més]',
    filterVisibility: 'Visibilitat columnes',
    excel: 'Excel',
    showCompleteBoard: 'Eliminar filtres del gràfic',
    modelCarRelation:
      '<b>Model  -  Cotxe relació</b> <ul><li>GBS - A1/AU270</li> <li>KJ1 - Ibiza/SE270</li> <li>KJ7 - Arona/SE216</li><li>KM7 - Formentor/SE316</li> <li>KL1 - Leon 5P/SE380 SEAT</li> <li>KL8 - Leon ST/SE382 SEAT</li> <li>KU1 – Leon 5P SE380 CUPRA</li> <li>KU8 – Leon ST SE382 CUPRA</li> <li>KMP/5FF - Formentor PA/SE316 PA</li> <li>KLG/5FN - Leon 5P/SE380 SEAT PA</li> <li>KLD/5FN - Leon ST/SE382 SEAT PA</li> <li>KUG/5FN - Leon 5P SE380 CUPRA PA</li> <li>KUD/5FN - Leon ST SE382 CUPRA PA</li></ul>',
    cancel: 'Cancelar',
    accept: 'Acceptar',
    emptyMessage: 'Encara no hi ha dades disponibles.',
    alerts: 'Alertes',
    saveFilters: 'Desar Filtres',
    savedFilters: 'Filtres Desats',
    deleteFilters: 'Esborrar Filtres',
    total: 'Total',
    searcherPlaceholder: 'Cerca per clau',
    download: 'Descarregar',
    transfer: 'Directe de transvasament a línia',
    inTransit: 'En transit',
    delayed: 'Demorada',
    nearDelay: 'A punt de demorar',
    onTime: 'Puntual',
    arrivalsNextDays: 'Arrivades pròxims 8 dies',
    arrivalsNextHours: 'Arrivades pròximes 8h',
    currentShiftDescription:
      'Necessitat planificada | Necessitat restant<ul><li><b>Necessitat planificada</b>: Necessitat planificada a les 8h del torn segons programa.</li><li><b>Necessitat restant</b>: Necessitat restant al torn actual en funció del temps transcorregut.</li></ul>',
    selectTheFileToUpload: 'Seleccioneu el fitxer a carregar',
    upload: 'Carregar',
    toUploadAMasterFileRemember: '(i) Per actualitzar el mestre recordeu:',
    uploadMasterInstructions: [
      'Pujar un únic fitxer amb una sola pestanya.',
      "Les capçaleres han d'estar informades a la primera fila informant tots els camps necessaris.",
      "Podeu utilitzar un fitxer descarregat com a plantilla per a l'actualització.",
      'Tingueu en compte que la mida màxima del fitxer és 10MB'
    ],
    filters: 'Filtres',
    movementLabel: 'Mov. a línia',
    movementType: 'Tipus mvt',
    movementAmount: 'Quantitat',
    movementDate: 'Data mvt',
    movementsLineTooltip:
      'Moviment tipus: <br/><b>H:</b> Entrada <br/><b>S:</b> Devolució de línia',
    selectDays: 'Necessitats dies',
    lastUpdate: 'Act. de dades: ',
    detail: 'Detalls',
    showDetail: 'Mostrar detall',
    leaveComment: 'Deixa un comentari',
    internalCommunication: 'Comunicació interna',
    noComments: 'No hi ha missatges relatius a aquesta clau.',
    deleteMsg: 'Voleu suprimir el missatge seleccionat?',
    unitSingleDay: 'dia',
    unitMultipleDays: 'dies',
    unitSinglePiece: 'peça',
    unitMultiplePieces: 'peces',
    timerTooltip: 'Activa per actualitzar dades automàticament cada ',
    minutes: 'minuts',
    listMaterialKeys: 'Relació claus de material',
    uploadKeysTitle:
      'Descarregar plantilla i pujar fitxer amb relació clau proveïdor i SEAT',
    uploadKeysSubtitle: 'Per actualitzar el Mestre recordeu:',
    donwloadTemplate: 'Descarregar plantilla',
    selectFileFrom: 'Seleccioneu o arrossegueu el fitxer del vostre ordinador.',
    maximumSizeExcelFile: 'Ha de ser un fitxer .xlsx de màxim 10MB',
    startingUpload: 'Iniciant càrrega de fitxer',
    providerPartTooltipInfo:
      'Si us plau, en el cas de tenir nomenclatura de claus de material diferents de SEAT, afegiu les claus de la vostra companyia mitjançant el botó <b>Relació claus de material</b>.',
    selectFile: 'Seleccionar fitxer',
    withoutReference: 'Sense referència',
    contactName: 'Nom de contacte',
    role: 'Rol',
    telephone: 'Número de telèfon',
    email: 'Email de contacte',
    contactInfo: 'Info contacte ',
    copyEmail: "Clica per copiar l'adreça de correu electrònic",
    copyToClipboardSuccess: 'Element copiat al porta-retalls',
    copyToClipboardFail: 'No hi ha cap element per copiar al porta-retalls',
    copyElement: 'Clica per copiar element',
    date: 'Data: ',
    code: 'Clau',
    notApply: 'No aplica',
    addContact: 'Afegir contacte',
    maxCharacters: 'Màx 50 caràcters',
    confirm: 'Confirma',
    emailError: 'El correu electrònic de contacte no és vàlid',
    manageContacts: 'Gestionar contactes',
    deleteContact: 'Eliminar contacte',
    sureToDelete: 'Estàs segur que vols eliminar el contacte',
    actionCannotBeUndone: 'Aquesta acció no es podrà desfer',
    editContact: 'Editar contacte',
    save: 'Desa',
    mandatoryPhoneOrEmail: '*És obligatori afegir un número de telèfon o email',
    multisearchUsageInfo:
      'Separeu els valors usant el caràcter + per fer una cerca múltiple. <b>Clica Enter per cercar.</b>',
    gotIt: 'Entès',
    scrollTop: "Clica per pujar a l'inici del panell",
    scrollBottom: 'Clica per baixar al final del panell',
    newFunctionality: 'Nova funcionalitat:',
    navigationByDraggingTheTable: 'Navegació arrossegant per la taula',
    draggingNavigationFunctionalityDescription:
      "Ara, gaudeix d'una experiència de navegació més fluida i natural a la nostra taula de necessitats i cobertures. Simplement mantingues pressionat el ratolí i arrossega per desplaçar-te horitzontal i verticalment amb més facilitat.",
    prov: 'Prov.',
    status: 'Estat',
    inConsolidation: 'En consolidació',
    inExpedition: 'En expedició',
    unloadingInPlant: 'Descarregant en planta',
    qty: 'Quant.',
    plateNumberModalTransport: 'Matrícula',
    tp: '(TP)',
    tr: '(TR)',
    ep: '(EP)',
    arrivalDate: 'Data arribada'
  }
};
