import { createAsyncThunk } from '@reduxjs/toolkit';
import getTransferStockUseCase from 'modules/inbound/transfer/application/GetTransferStockUseCase';
import getIncomingTransfersUseCase from 'modules/inbound/transfer/application/GetIncomingTransfersUseCase';
import { IncomingTransfersTimeRange } from 'modules/inbound/transfer/domain/IncomingTransfers';
import {
  getCurrentPage,
  getFilterSearch,
  getResultsPerPage,
  getSelectedColumn,
  getSelectedCriticalStockType,
  getSelectedFilters,
  getSelectedNextHoursTransfers
} from '.';
import {
  FiltersItem,
  NameValues
} from 'modules/inbound/shared/domain/NameValues';
import getFiltersQueryString, {
  cleanUpQueryDuplicatesAndGetString
} from 'modules/inbound/shared/domain/GetFiltersQuery';
import getTransferCriticalStockUseCase from 'modules/inbound/transfer/application/GetTransferCriticalStockUseCase';
import getTransferStockNextHoursUseCase from 'modules/inbound/transfer/application/GetTransferStockNextHoursUseCase';
import {
  PlanningTypeBooleans,
  StatusType,
  SelectedSubHeaderFilters
} from './types';
import getIncomingMaterialsCriticalStockUseCase from 'modules/inbound/transfer/application/GetIncomingMaterialsCriticalStockUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';

const getTransferStock = createAsyncThunk(
  'transfer/getTransferStock',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const searchValue = getFilterSearch(appState);
    const selectedCriticalStockType = getSelectedCriticalStockType(appState);
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouseTypes,
      selectedWarehouses,
      selectedPlanningType,
      selectedSupplyTypes
    }: SelectedSubHeaderFilters = getSelectedFilters(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;

    const tableColumn =
      selectedColumn.key === NameValues.ASN_CONTAINER
        ? NameValues.HANDLING_UNIT_CONTAINER
        : selectedColumn.key;

    const sort = `{${tableColumn}:${sortOrder}}`;

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE_TYPE,
        values: selectedWarehouseTypes
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      },
      {
        name: NameValues.IS_PLANNED,
        values: selectedPlanningType.map((type) => {
          return {
            ...type,
            key: PlanningTypeBooleans[type.key]
          };
        })
      },
      {
        name: NameValues.SUPPLY_TYPE,
        values: selectedSupplyTypes
      }
    ];

    let querySearch: string[] = getFiltersQueryString(queryFilters);

    /** Searcher query */
    searchValue &&
      querySearch.push(
        `{${NameValues.CODE}:LK:${searchValue}|OR|${NameValues.WAREHOUSE_UNIT_NUMBER}:LK:${searchValue}|OR|${NameValues.SUPPLY_CONTAINER}:LK:${searchValue}|OR|${NameValues.TRANSPORT_CONTAINER}:LK:${searchValue}|OR|${NameValues.BASIC_CONTAINER}:LK:${searchValue}|OR|${NameValues.ASN_CONTAINER}:LK:${searchValue}|OR|${NameValues.DESCRIPTION}:LK:${searchValue}}`
      );

    /** Critical Stock query */
    selectedCriticalStockType &&
      querySearch.push(
        `${NameValues.CRITICAL_STOCK_TYPE}:=:${selectedCriticalStockType}`
      );

    const queryParams = {
      page: currentPage,
      size,
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`,
      sort: selectedColumn.key.length ? sort : ''
    };

    const response = await getTransferStockUseCase.execute({ queryParams });
    return response;
  }
);

const getTransferStockNextHours = createAsyncThunk(
  'transfer/getTransferStockNextHours',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const searchValue = getFilterSearch(appState);
    const selectedCriticalStockType = getSelectedCriticalStockType(appState);
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses,
      selectedPlanningType,
      selectedSupplyTypes
    }: SelectedSubHeaderFilters = getSelectedFilters(appState);

    const selectedNextHoursTransfers = getSelectedNextHoursTransfers(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;

    const getTableColumnKey = (columnName: string): string => {
      switch (columnName) {
        case NameValues.ASN_CONTAINER:
          return NameValues.HANDLING_UNIT_CONTAINER;
        case NameValues.IS_PLANNED:
          return NameValues.TYPE;
        default:
          return columnName;
      }
    };

    const tableColumnKey = getTableColumnKey(selectedColumn.key);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      },
      {
        name: NameValues.TYPE,
        values: selectedPlanningType.map((type) => {
          return {
            ...type,
            key: StatusType[type.key]
          };
        })
      },
      {
        name: NameValues.SUPPLY_TYPE,
        values: selectedSupplyTypes
      }
    ];

    let querySearch: string[] = getFiltersQueryString(queryFilters);

    /** Next Hours chart query */
    if (selectedNextHoursTransfers.status) {
      querySearch = querySearch.concat(
        `${NameValues.DELIVERY_TIME}:>=:${selectedNextHoursTransfers.from}||${NameValues.DELIVERY_TIME}:<=:${selectedNextHoursTransfers.to}`
      );
      querySearch = querySearch.concat(
        `${NameValues.TYPE}:=:${selectedNextHoursTransfers.status}`
      );
    }

    /** Searcher query */
    searchValue &&
      querySearch.push(
        `{${NameValues.CODE}:LK:${searchValue}|OR|${NameValues.WAREHOUSE_UNIT_NUMBER}:LK:${searchValue}|OR|${NameValues.SUPPLY_CONTAINER}:LK:${searchValue}|OR|${NameValues.TRANSPORT_CONTAINER}:LK:${searchValue}|OR|${NameValues.BASIC_CONTAINER}:LK:${searchValue}|OR|${NameValues.ASN_CONTAINER}:LK:${searchValue}|OR|${NameValues.DESCRIPTION}:LK:${searchValue}}`
      );

    /** Critical Stock query */
    selectedCriticalStockType &&
      querySearch.push(
        `${NameValues.CRITICAL_STOCK_TYPE}:=:${selectedCriticalStockType}`
      );

    const queryParams = {
      page: currentPage,
      size,
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`,
      sort: tableColumnKey.length ? `{${tableColumnKey}:${sortOrder}}` : ''
    };
    const response = await getTransferStockNextHoursUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getIncomingTransfersNextDays = createAsyncThunk(
  'transfer/getIncomingTransfersNextDays',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses,
      selectedPlanningType,
      selectedSupplyTypes
    }: SelectedSubHeaderFilters = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      },
      {
        name: NameValues.TYPE,
        values: selectedPlanningType.map((type) => {
          return {
            ...type,
            key: StatusType[type.key]
          };
        })
      },
      {
        name: NameValues.SUPPLY_TYPE,
        values: selectedSupplyTypes
      }
    ];

    const querySearch: string[] = getFiltersQueryString(queryFilters);

    const queryParams = {
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };

    const response = await getIncomingTransfersUseCase.execute({
      timeRange: IncomingTransfersTimeRange.days,
      queryParams
    });
    return response;
  }
);

const getIncomingTransfersNextHours = createAsyncThunk(
  'transfer/getIncomingTransfersNextHours',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses,
      selectedPlanningType,
      selectedSupplyTypes
    }: SelectedSubHeaderFilters = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      },
      {
        name: NameValues.TYPE,
        values: selectedPlanningType.map((type) => {
          return {
            ...type,
            key: StatusType[type.key]
          };
        })
      },
      {
        name: NameValues.SUPPLY_TYPE,
        values: selectedSupplyTypes
      }
    ];

    let querySearch: string[] = getFiltersQueryString(queryFilters);
    const queryParams = {
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };

    const response = await getIncomingTransfersUseCase.execute({
      timeRange: IncomingTransfersTimeRange.hours,
      queryParams
    });

    return response;
  }
);

const getCriticalStock = createAsyncThunk(
  `transfer/getCriticalStock`,
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses
    } = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      }
    ];

    const querySearch: string[] = getFiltersQueryString(queryFilters);
    const queryParams = {
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };
    const response = await getTransferCriticalStockUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getIncomingMaterialsCriticalStock = createAsyncThunk(
  `transfer/getIncomingMaterialsCriticalStock`,
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses
    } = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.BASIC_CONTAINER,
        values: selectedBasicContainers
      },
      {
        name: NameValues.SUPPLY_CONTAINER,
        values: selectedSupplyContainers
      },
      {
        name: NameValues.WAREHOUSE,
        values: selectedWarehouses
      }
    ];

    const querySearch: string[] = getFiltersQueryString(queryFilters);
    const queryParams = {
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };
    const response = await getIncomingMaterialsCriticalStockUseCase.execute({
      queryParams
    });
    return response;
  }
);

export {
  getTransferStock,
  getTransferStockNextHours,
  getIncomingTransfersNextDays,
  getIncomingTransfersNextHours,
  getCriticalStock,
  getIncomingMaterialsCriticalStock
};
