import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { SelectedItemsProps } from 'modules/inbound/shared/domain/NameValues';
import { CriticalStockData } from 'modules/inbound/shared/domain/SummaryCriticalStock';
import IncomingTransfers from 'modules/inbound/transfer/domain/IncomingTransfers';
import {
  ITransferStockItem,
  ColumnsInfo
} from 'modules/inbound/transfer/domain/TransferStock';
import { ITransferStockNextHoursItem } from 'modules/inbound/transfer/domain/TransferStockNextHours';
import { ColumnSelected } from 'modules/shared/layout/components/TableHeader/types';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';

enum TRANSFER_COLORS {
  YELLOW = 'yellow700',
  GREEN = 'green700'
}
export interface Filters {
  name: string;
  values: SelectedItemsProps[];
}

export enum SearchModality {
  TO = 'TO',
  BETWEEN = 'BETWEEN',
  FROM = 'FROM'
}

export enum StatusType {
  PLANNED = 'PLANNED',
  NOT_PLANNED = 'NOT_PLANNED'
}

export const StatusColor = {
  [StatusType.NOT_PLANNED]: TRANSFER_COLORS.YELLOW,
  [StatusType.PLANNED]: TRANSFER_COLORS.GREEN
};

export const TransferStatus = {
  [TRANSFER_COLORS.GREEN]: StatusType.PLANNED,
  [TRANSFER_COLORS.YELLOW]: StatusType.NOT_PLANNED
};

export const PlanningTypeBooleans = {
  [StatusType.PLANNED]: 1,
  [StatusType.NOT_PLANNED]: 0
} as const;

export interface SelectedNextHoursProps {
  date: string;
  top: string;
  status: string | null;
}

export interface SelectedSubHeaderFilters {
  selectedBasicContainers: SelectItemProps[];
  selectedSupplyContainers: SelectItemProps[];
  selectedWarehouseTypes: SelectItemProps[];
  selectedWarehouses: SelectItemProps[];
  selectedPlanningType: SelectItemProps[];
  selectedSupplyTypes: SelectItemProps[];
}

export interface TransferState {
  lastUpdate: string;
  transferStock: ITransferStockItem[] | ITransferStockNextHoursItem[];
  columns: ColumnsInfo[];
  savedColumns: ColumnProps[];
  transferStockRequestStatus: string;
  incomingTransfersNextDays: IncomingTransfers;
  incomingTransfersNextHours: IncomingTransfers;
  incomingTransfersNextDaysRequestStatus: string;
  incomingTransfersNextHoursRequestStatus: string;
  pagination: {
    page: number;
    size: number;
    pageCount: number;
    totalCount: number;
  };
  selectedColumn: ColumnSelected;
  criticalStock: CriticalStockData;
  incomingMaterialsCriticalStock: CriticalStockData;
  requestStatusCriticalStock: string;
  requestStatusIncomingMaterialsCriticalStock: string;
  filters: {
    search: string;
    selectedCriticalStockType: string;
    selectedNextHoursArrivals: SelectedNextHoursProps;
    selectedSubHeaderFilters: SelectedSubHeaderFilters;
  };
  isFilterSaved: boolean;
  isTableStockSelected: boolean;
}
