import React from 'react';

export enum StickerColor {
  WHITE = 'white',
  DARKERGRAY = 'darkerGray',
  PURPLE = 'purple'
}

export interface TransportStickerProps {
  isMultiple: boolean;
  icons: React.ReactNode[];
  amount: string | null;
  onStickerClick: (event) => void;
  onMouseEnter: (event) => void;
  onMouseLeave: (event) => void;
  color: StickerColor | undefined;
  isActive: boolean;
}
