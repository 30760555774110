import { TABLET_BREAKPOINT } from 'modules/shared/hooks/useBreakpoints';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';
import WrapperLanguageSelector from 'modules/shared/layout/components/LanguageSelector/styles/WrapperLanguageSelector';
import styled from 'styled-components';

const WrapperPage = styled.section`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }

  h3 {
    margin: 0;
    font-family: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fontFamily.bold};
    font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fonts.h3.minSizeAsPixels};
  }
`;

const WrapperWelcome = styled.div`
  display: none;
  justify-content: center;
  position: relative;

  .background {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 430px;
    gap: 0.5rem;
    color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.white.rgb};
    text-align: center;

    h4 {
      margin-top: 0;
      font-family: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.fontFamily.bold};
      font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.fonts.h4.minSizeAsPixels};
    }

    p {
      font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.fonts.p.intermediateSize};
    }

    img {
      width: 85.95px;
      height: 85.95px;
    }
  }

  .seat-code-logo {
    position: absolute;
    bottom: 50px;
  }
`;

const WrapperLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.colors.white.rgb};
  position: relative;
  gap: 1rem;

  & > img {
    margin-top: auto;
    width: 85.95px;
    height: 85.95px;
    filter: invert(1);
  }

  & > h3 {
    margin-bottom: 2rem;
  }

  & > .seat-code-logo {
    width: 220px;
  }

  ${WrapperLanguageSelector} {
    position: absolute;
    top: 6rem;
    right: 1.5rem;
  }

  .pop-up-message {
    position: absolute;
    top: 40px;
  }

  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    justify-content: center;

    & > img {
      position: absolute;
      width: 40px;
      height: 40px;
      right: 30px;
      top: 30px;
    }

    & > h3 {
      display: none;
    }

    .seat-code-logo {
      display: none;
    }
  }
`;

const WrapperForm = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 350px;
  gap: 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  h3,
  p {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 0.5rem;
  }
`;

const Button = styled.button<{ secondary?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  padding: 0.5rem 1rem;
  border: 0;
  outline: 0;
  border-radius: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.borders.extralarge};
  background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.colors.orange.rgb};
  color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.white.hexa};
  font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.fonts.regularSmall.maxSizeAsPixels};
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background-color: ${(props: {
      theme: ThemeSEATProps;
      secondary?: boolean;
    }) =>
      props.secondary
        ? props.theme.colors.black.rgb
        : props.theme.colors.orange900.rgb};
    color: ${(props: { theme: ThemeSEATProps; secondary?: boolean }) =>
      props.secondary && props.theme.colors.white.hexa};
  }

  :disabled {
    cursor: unset;
    background-color: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.overlays.modal.rgb};
    color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.white.hexa};
  }

  /** secondary styles */
  background-color: ${(props: { theme: ThemeSEATProps; secondary?: boolean }) =>
    props.secondary && props.theme.colors.white.hexa};
  color: ${(props: { theme: ThemeSEATProps; secondary?: boolean }) =>
    props.secondary && props.theme.colors.black.hexa};
  border: ${(props: { theme: ThemeSEATProps; secondary?: boolean }) =>
    props.secondary && `1px solid ${props.theme.colors.black.hexa}`};
`;

const Link = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin: 1rem;
`;

export { WrapperPage, WrapperLogin, WrapperWelcome, WrapperForm, Button, Link };
