import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import callOffRepository from 'modules/outbound/callOff/infrastructure/repositories/CallOffRepository';
import localeService, {
  LocaleService
} from 'infrastructure/i18n/LocaleService';

class DownloadCallOffFinishedGoodsByWarehouseService {
  private _callOffRepository: ICallOffRepository;
  private localeService: LocaleService;

  constructor({ repository, localeService }) {
    this._callOffRepository = repository;
    this.localeService = localeService;
  }

  execute({
    warehouseRef,
    queryParams
  }: {
    warehouseRef: string;
    queryParams: object;
  }) {
    queryParams = { ...queryParams, lang: this.localeService.getLanguage() };
    return this._callOffRepository.downloadFinishedGoodsByWarehouse({
      warehouseRef,
      queryParams
    });
  }
}

export default new DownloadCallOffFinishedGoodsByWarehouseService({
  repository: callOffRepository,
  localeService
});

export { DownloadCallOffFinishedGoodsByWarehouseService };
