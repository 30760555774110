export interface IWarehouseStockByModelDTO {
  model: string;
  totalCars: number;
  pastSales: number;
}
class WarehouseStockByModelDTO implements IWarehouseStockByModelDTO {
  model: string;
  totalCars: number;
  pastSales: number;

  constructor({ model, totalCars, pastSales }) {
    this.model = model;
    this.totalCars = totalCars;
    this.pastSales = pastSales;
  }
}

export default WarehouseStockByModelDTO;
