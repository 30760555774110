import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { ParalyzationRight, Urgent } from '../types';

export interface IEntriesInPlantDataDTO {
  entries: IEntriesInPlantDataItemDTO[];
  pagination: IPaginationDTO;
}

export const ParalyzationState = {
  NOT_PARALYZED: 'NOT_PARALYZED',
  NEAR_PARALYZATION: 'NEAR_PARALYZATION',
  PARALYZED: 'PARALYZED'
} as const;

export type StopType = 'LOAD_UNLOAD' | 'LOAD' | 'UNLOAD';

export type ParalyzationStateType = keyof typeof ParalyzationState;
export interface IEntriesInPlantDataItemDTO {
  plate_number: string;
  transport_id: number;
  carrier: string;
  window_booked: string;
  entry_time: string;
  stops: number;
  last_position: number;
  packages: number;
  most_critical_piece_coverage: number;
  global_dlz: number;
  paralyzation_time: number;
  paralyzation_state: ParalyzationStateType;
  paralyzation_right: ParalyzationRight;
  urgent: Urgent;
  has_critical_pieces: boolean;
  contains_critical_pieces: boolean;
  contains_serious_fault: boolean;
  stops_completed: number;
  stop_type: StopType;
  supplier: string;
}

class EntriesInPlantDataDTO implements IEntriesInPlantDataDTO {
  entries: IEntriesInPlantDataItemDTO[];
  pagination: IPaginationDTO;

  constructor({ entries, pagination }) {
    this.entries = entries;
    this.pagination = pagination;
  }
}

export default EntriesInPlantDataDTO;
