import { Translation } from 'infrastructure/i18n/typing';
import { JitFamiliesTranslations } from './typing';

export const JitFamiliesText: Translation<JitFamiliesTranslations> = {
  es: {
    emptyMessage: 'No hay datos disponibles',
    closeContainer: 'Cierre Contenedor',
    lastSequenceInContainerClosure: 'Última secuencia en Cierre Contenedor',
    dateOfLastDESADVShipment: 'Fecha último DESADV coincidente',
    sequence: 'secuencia',
    generalData: 'Datos generales',
    workshop: 'Taller',
    marquee: 'Marquesina',
    branch: 'Ramal',
    lisons: 'Lison',
    station: 'Tacto',
    sequenceInStation: 'Secuencia en tacto',
    lineSequenceUpdatingDateAt: 'Fecha y hora de actualización',
    dateOfForecastStoppage: 'Fecha previsión de paro'
  },
  en: {
    emptyMessage: "There's no data available",
    closeContainer: 'Container Closure',
    lastSequenceInContainerClosure: 'Last sequence in Container Closure',
    dateOfLastDESADVShipment: 'Last matching DESADV date',
    sequence: 'sequence',
    generalData: 'General data',
    workshop: 'Workshop',
    marquee: 'Marquee',
    branch: 'Branch',
    lisons: 'Lison',
    station: 'Station',
    sequenceInStation: 'Sequence in station',
    lineSequenceUpdatingDateAt: 'Update date and time',
    dateOfForecastStoppage: 'Predicted stop date time'
  },
  ca: {
    emptyMessage: 'No hi ha dades disponibles',
    closeContainer: 'Tancament Contenidor',
    lastSequenceInContainerClosure: 'Darrera seqüència en Tancament Contenidor',
    dateOfLastDESADVShipment: 'Data últim DESADV coincident',
    sequence: 'seqüència',
    generalData: 'Dades generals',
    workshop: 'Workshop',
    marquee: 'Marquesina',
    branch: 'Ramal',
    lisons: 'Lison',
    station: 'Tacte',
    sequenceInStation: 'Sequència en tacte',
    lineSequenceUpdatingDateAt: "Data i hora d'actualització",
    dateOfForecastStoppage: 'Data previsió de parada'
  }
};
