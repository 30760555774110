import callOffRepository from 'modules/outbound/callOff/infrastructure/repositories/CallOffRepository';
import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import CallOffTrackingByCheckpoint from 'modules/outbound/callOff/domain/CallOffTrackingByCheckpoint';

class GetCallOffTrackingDataByCheckpointUseCase {
  private _callOffRepository: ICallOffRepository;
  constructor({ repository }) {
    this._callOffRepository = repository;
  }

  execute({ checkpointType }: { checkpointType: string }) {
    return this._callOffRepository
      .getTrackingByCheckpointType({
        checkpointType
      })
      .then((res) => CallOffTrackingByCheckpoint.generateFromDTO(res));
  }
}

export default new GetCallOffTrackingDataByCheckpointUseCase({
  repository: callOffRepository
});

export { GetCallOffTrackingDataByCheckpointUseCase };
