export interface RefillsSaturationDTO {
  status: string;
  percentage: number;
}

class RefillsSaturationDataDTO {
  saturation: RefillsSaturationDTO;

  constructor({ saturation }) {
    this.saturation = saturation;
  }
}

export default RefillsSaturationDataDTO;
