interface GenerateFromDTOParams {
  cumulativeDelay: number;
  globalDelayed: number;
  total: number;
}

class GlobalGeolocationDelay {
  cumulativeValue: number;
  globalValue: number;
  cumulativePercentage: number;
  globalPercentage: number;
  totalAmount: number;

  constructor({
    cumulativePercentage,
    globalPercentage,
    cumulativeValue,
    globalValue,
    totalAmount
  }: GlobalGeolocationDelay) {
    this.cumulativePercentage = cumulativePercentage;
    this.globalPercentage = globalPercentage;
    this.globalValue = globalValue;
    this.cumulativeValue = cumulativeValue;
    this.totalAmount = totalAmount;
  }

  static generateFromDto({
    cumulativeDelay,
    globalDelayed,
    total
  }: GenerateFromDTOParams): GlobalGeolocationDelay {
    return new GlobalGeolocationDelay({
      cumulativePercentage: Math.round((cumulativeDelay / total) * 100),
      globalPercentage: Math.round((globalDelayed / total) * 100),
      cumulativeValue: cumulativeDelay,
      globalValue: globalDelayed,
      totalAmount: total
    });
  }
}

export default GlobalGeolocationDelay;
