class FactoryDelayDTO {
  total: number;
  globalDelay: number;
  checkpointDelay: number;

  constructor({ total, globalDelay, checkpointDelay }) {
    this.total = total;
    this.globalDelay = globalDelay;
    this.checkpointDelay = checkpointDelay;
  }
}

export default FactoryDelayDTO;
