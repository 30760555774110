/**
 *
 * @param key Local storage key to retrieve the data
 * @param initialValue Value that will be returned in case of error
 * @returns Parsed localStorage data or initialValue
 */
export const getLocalStorage = <T>(key: string, initialValue: T): T => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};
