import { ThemeSEATFontFamily, ThemeSEATFonts } from './typing';

export class SEATFont {
  minSize: number;
  maxSize: number;

  constructor(minSize: number, maxSize: number) {
    this.minSize = minSize;
    this.maxSize = maxSize;
  }

  get minSizeAsPixels() {
    return `${this.minSize}px`;
  }

  get maxSizeAsPixels() {
    return `${this.maxSize}px`;
  }

  get intermediateSize() {
    return (this.minSize + this.maxSize) / 2;
  }

  get intermediateSizeAsPixels() {
    return `${this.intermediateSize}px`;
  }
}

export const fonts: ThemeSEATFonts = {
  p: new SEATFont(15, 22),
  h1: new SEATFont(40, 58),
  h2: new SEATFont(32, 40),
  h3: new SEATFont(27, 32),
  h4: new SEATFont(19, 24),
  boldLarge: new SEATFont(19, 19),
  boldMedium: new SEATFont(16, 16),
  boldSmall: new SEATFont(13, 16),
  regularLarge: new SEATFont(19, 19),
  regularMedium: new SEATFont(16, 16),
  regularSmall: new SEATFont(13, 13),
  regularExtraSmall: new SEATFont(11, 11),
  regularDoubleExtraSmall: new SEATFont(9, 9)
};

export const fontFamily: ThemeSEATFontFamily = {
  regular: 'SeatBcn-Regular',
  medium: 'SeatBcn-Medium',
  bold: 'SeatBcn-Bold',
  black: 'SeatBcn-Black'
};
