import localeService from 'infrastructure/i18n/LocaleService';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';
import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

interface GenerateFromDTOParams {
  name: string;
  type: CheckpointType;
  cumulativeDelay: number;
  checkpointDelayed: number;
  totalFinishedGoods: number;
}

export interface IDeliveriesGeolocationCheckpoint {
  id: string;
  name: string;
  type: CheckpointType;
  cumulativeDelay: number;
  checkpointDelayed: number;
  checkpointDelayedPercentage: number;
  cumulativeDelayPercentage: number;
}

class DeliveriesGeolocationCheckpoint
  implements IDeliveriesGeolocationCheckpoint
{
  id: string;
  name: string;
  type: CheckpointType;
  cumulativeDelay: number;
  checkpointDelayed: number;
  checkpointDelayedPercentage: number;
  cumulativeDelayPercentage: number;

  constructor({
    id,
    name,
    type,
    cumulativeDelay,
    checkpointDelayed,
    checkpointDelayedPercentage,
    cumulativeDelayPercentage
  }: DeliveriesGeolocationCheckpoint) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.cumulativeDelay = cumulativeDelay;
    this.checkpointDelayed = checkpointDelayed;
    this.checkpointDelayedPercentage = checkpointDelayedPercentage;
    this.cumulativeDelayPercentage = cumulativeDelayPercentage;
  }

  static generateFromDTO({
    name,
    type,
    cumulativeDelay,
    checkpointDelayed,
    totalFinishedGoods
  }: GenerateFromDTOParams): DeliveriesGeolocationCheckpoint {
    return new DeliveriesGeolocationCheckpoint({
      id: name,
      name: this._getCheckpointNames(name),
      type,
      cumulativeDelay,
      checkpointDelayed,
      checkpointDelayedPercentage: Math.round(
        (checkpointDelayed / totalFinishedGoods) * 100
      ),
      cumulativeDelayPercentage: Math.round(
        (cumulativeDelay / totalFinishedGoods) * 100
      )
    });
  }

  static generateEmpty() {
    return new DeliveriesGeolocationCheckpoint({
      id: '',
      name: '',
      type: CheckpointType.NONE,
      cumulativeDelay: 0,
      checkpointDelayed: 0,
      checkpointDelayedPercentage: 0,
      cumulativeDelayPercentage: 0
    });
  }

  private static _getCheckpointNames(key) {
    const lang = localeService.getLanguage();
    const group = DETAILED_CHECKPOINTS_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.checkpoints[key];
  }
}

export default DeliveriesGeolocationCheckpoint;
