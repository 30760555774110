import localeService from 'infrastructure/i18n/LocaleService';
import { GeolocationTexts } from '../infrastructure/i18n/geolocation';
import { GeolocationTranslations } from '../infrastructure/i18n/typing';
import { LocationCoordinates, LocationStatus } from './GeolocatedTransport';

const geolocationTranslation: GeolocationTranslations = {
  ...GeolocationTexts[localeService.getLanguage()]
};
class GeolocatedVessel {
  imo: string;
  name: string;
  lastLocationCoordinates: LocationCoordinates;
  origin: string;
  originCountry: string;
  originPortExitDate: string;
  destination: string;
  destinationCountry: string;
  eta: string;
  updateDate: string;
  status: LocationStatus;
  vins: string[];
  portArrivalInfo: string;
  currentCountryPort: string;

  constructor({
    imo,
    name,
    lastLocationCoordinates,
    origin,
    originCountry,
    originPortExitDate,
    destination,
    destinationCountry,
    eta,
    updateDate,
    status,
    vins,
    portArrivalInfo,
    currentCountryPort
  }) {
    this.imo = imo;
    this.name = name;
    this.lastLocationCoordinates = lastLocationCoordinates;
    this.origin = origin;
    this.originCountry = originCountry;
    this.originPortExitDate = originPortExitDate;
    this.destination = destination;
    this.destinationCountry = destinationCountry;
    this.eta = eta;
    this.updateDate = updateDate;
    this.status = status;
    this.vins = vins;
    this.portArrivalInfo = portArrivalInfo;
    this.currentCountryPort = currentCountryPort;
  }

  static generateFromDTO({
    imo,
    name,
    lastLocationCoordinates,
    origin,
    originCountry,
    originPortExitDate,
    destination,
    destinationCountry,
    eta,
    updateDate,
    status,
    vins,
    portArrivalInfo,
    currentCountry,
    currentPort
  }) {
    return new GeolocatedVessel({
      imo,
      name,
      lastLocationCoordinates,
      origin,
      originCountry,
      originPortExitDate: this._formatDate(originPortExitDate),
      destination,
      destinationCountry,
      eta: this._formatDate(eta),
      updateDate: `${this._getDateFormated(
        updateDate,
        'de-DE'
      )} | ${this._getTimeFormated(updateDate)}`,
      status,
      vins: vins,
      portArrivalInfo: this._getPortArrivalInfo(portArrivalInfo),
      currentCountryPort:
        currentPort && currentCountry
          ? `${[currentCountry]} ${currentPort}`
          : geolocationTranslation.sailing
    });
  }

  static generateEmpty() {
    return new GeolocatedVessel({
      imo: '',
      name: '',
      lastLocationCoordinates: { longitude: 0, latitude: 0 },
      origin: '',
      originCountry: '',
      originPortExitDate: '',
      destination: '',
      destinationCountry: '',
      eta: '',
      updateDate: '',
      status: '',
      vins: [],
      portArrivalInfo: '',
      currentCountryPort: ''
    });
  }

  private static _getPortArrivalInfo(portArrivalInfo) {
    if (portArrivalInfo === 'SAILING') {
      return geolocationTranslation.sailing;
    }
    if (portArrivalInfo === null) {
      return geolocationTranslation.pendingData;
    }
    return this._formatDate(portArrivalInfo);
  }

  private static _formatDate(ISODate) {
    const date = new Date(ISODate);

    const day = this._padTo2Digits(date.getDate());
    const month = this._padTo2Digits(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = this._padTo2Digits(date.getHours());
    const minutes = this._padTo2Digits(date.getMinutes());

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  private static _padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  private static _getDateFormated(date, locale) {
    return new Date(date).toLocaleDateString(locale, {
      day: 'numeric',
      month: '2-digit',
      year: '2-digit'
    });
  }

  private static _getTimeFormated(date: string | Date) {
    return new Date(date).toLocaleTimeString(['es-ES'], {
      hour: '2-digit',
      minute: '2-digit'
    });
  }
}

export default GeolocatedVessel;
