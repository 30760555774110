import { createSlice } from '@reduxjs/toolkit';
import { PipelineDetailedCarInfoState } from './types';
import { getPipelineDetailedCarInfo } from './async';
import PipelineDetailedCarInfo from 'modules/outbound/stock/domain/PipelineDetailedCarInfo';

export const initialState: PipelineDetailedCarInfoState = {
  detailedCarInfo: PipelineDetailedCarInfo.generateEmpty(),
  carReference: '',
  requestStatus: ''
};

const pipelineDetailedCarInfoSlice = createSlice({
  name: 'pipelineDetailedCarInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPipelineDetailedCarInfo.fulfilled, (state, action) => {
      state.detailedCarInfo =
        action.payload.detailedCarInfo ||
        PipelineDetailedCarInfo.generateEmpty();
      state.carReference = action.payload.reference;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPipelineDetailedCarInfo.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPipelineDetailedCarInfo.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

//selectors

export const getDetailedCarInfoIsFulfilled = (state) =>
  state.pipelineDetailedCarInfoState.requestStatus === 'fulfilled';

export const getDetailedCarInfoIsPending = (state) =>
  state.pipelineDetailedCarInfoState.requestStatus === 'pending';

export const getDetailedCarInvoicedAgingInfoFormated = (state) =>
  state.pipelineDetailedCarInfoState.detailedCarInfo.invoicedAging
    .map((compoundAging) => ({
      [compoundAging.compoundName]: compoundAging.intervals
    }))
    .reduce((acc, value) => {
      return { ...acc, ...value };
    }, {});

export const getDetailedCarInTransitAgingInfoFormated = (state) =>
  state.pipelineDetailedCarInfoState.detailedCarInfo.inTransitAging
    .map((compoundAging) => ({
      [compoundAging.compoundName]: compoundAging.intervals
    }))
    .reduce((acc, value) => {
      return { ...acc, ...value };
    }, {});

export default pipelineDetailedCarInfoSlice;
