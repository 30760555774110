import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { expirationsDataMapper } from '../../domain/expirationsDataMapper';
import { IExpirationRepository } from '../../domain/IExpirationRepository';
import { expirationsDataDTOMapper } from '../dto/expirationsDataDTOMapper';
import { ExpirationsFiltersDTO } from '../dto/ExpirationsFiltersDTO';
import { HandlingUnitsDTO } from '../dto/HandlingUnitsDTO';
import {
  API_GET_EXPIRATION_FILTERS,
  API_GET_EXPIRATION_STOCK,
  API_GET_EXPIRATIONS_DOWNLOAD,
  API_GET_HANDLING_UNITS
} from './urls';

class ExpirationRepository
  extends BaseRepository
  implements IExpirationRepository
{
  private _api: IApi;
  private _apiGetExpirationsUrl: string;
  private _apiGetExpirationsFiltersUrl: string;
  private _apiGetExpirationsHandlingUnitsUrl: string;
  private _apiGetExpirationsDownloadUrl: string;

  constructor({
    api,
    apiGetExpirations,
    apiGetExpirationsFilters,
    apiGetExpirationsHandlingUnitsUrl,
    apiGetExpirationsDownload
  }) {
    super();
    this._api = api;
    this._apiGetExpirationsUrl = apiGetExpirations;
    this._apiGetExpirationsFiltersUrl = apiGetExpirationsFilters;
    this._apiGetExpirationsHandlingUnitsUrl = apiGetExpirationsHandlingUnitsUrl;
    this._apiGetExpirationsDownloadUrl = apiGetExpirationsDownload;
  }

  getExpirationsStock({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetExpirationsUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => expirationsDataDTOMapper(res))
      .then((res) => expirationsDataMapper(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getExpirationsFilters() {
    return this._api
      .get(this._apiGetExpirationsFiltersUrl)
      .then((res) => new ExpirationsFiltersDTO(res));
  }

  getHandlingUnits({ queryParams }: { queryParams: object }) {
    const url = `${
      this._apiGetExpirationsHandlingUnitsUrl
    }${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) => new HandlingUnitsDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getExpirationsDownload({ queryParams }) {
    const url = `${this._apiGetExpirationsDownloadUrl}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new ExpirationRepository({
  api: api,
  apiGetExpirations: API_GET_EXPIRATION_STOCK,
  apiGetExpirationsFilters: API_GET_EXPIRATION_FILTERS,
  apiGetExpirationsHandlingUnitsUrl: API_GET_HANDLING_UNITS,
  apiGetExpirationsDownload: API_GET_EXPIRATIONS_DOWNLOAD
});

export { ExpirationRepository };
