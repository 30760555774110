import { createAsyncThunk } from '@reduxjs/toolkit';
import getPipelineStockService from 'modules/outbound/stock/services/GetPipelineStockService';
import downloadPipelineStockUseCase from 'modules/outbound/stock/application/DownloadPipelineStockUseCase';
import {
  getSelectedBrands,
  getSelectedFastLane,
  getSelectedRegionsCode
} from 'modules/outbound/stock/redux/PipelineStockReducer';

const getPipelineStock = createAsyncThunk(
  `pipelineStock/getPipelineStock`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedFastLane = getSelectedFastLane(appState);
    const selectedBrands = getSelectedBrands(appState);
    const selectedRegions = getSelectedRegionsCode(appState);
    const queryParams = {
      fastLane: selectedFastLane,
      brands: selectedBrands,
      warehouseIds: selectedRegions
    };
    const response = await getPipelineStockService.getAll(queryParams);
    return response;
  }
);

const downloadPipelineStock = createAsyncThunk(
  `pipelineStock/downloadPipelineStock`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedFastLane = getSelectedFastLane(appState);
    const selectedBrands = getSelectedBrands(appState);
    const selectedRegions = getSelectedRegionsCode(appState);
    const queryParams = {
      fastLane: selectedFastLane,
      brands: selectedBrands,
      warehouseIds: selectedRegions
    };
    const response = await downloadPipelineStockUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getPipelineStock, downloadPipelineStock };
