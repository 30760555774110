export interface UnloadingPointPieceDTO {
  code: string;
  coverage: number;
  warehouse: string;
  packages: number;
  container: number;
  container_type: string;
  is_critical: boolean;
}

export interface UnloadingPointPiecesDTO {
  pieces: UnloadingPointPieceDTO[];
}

export const unloadingPointPiecesDTOMapper = (
  data: any
): UnloadingPointPiecesDTO => {
  return {
    pieces:
      data?.pieces?.map((piece) => ({
        code: piece.code ?? '',
        coverage: piece.coverage ?? 0,
        warehouse: piece.warehouse ?? '',
        packages: piece.packages ?? 0,
        container: piece.container ?? 0,
        container_type: piece.container_type ?? '',
        is_critical: !!piece.is_critical
      })) ?? []
  };
};
