export interface IDeliveryTimeSlotsRangeDetailsDTO {
  averageTime: number;
}

class DeliveryTimeSlotsRangeDetailsDTO
  implements IDeliveryTimeSlotsRangeDetailsDTO
{
  averageTime: number;

  constructor({ averageTime }) {
    this.averageTime = averageTime;
  }
}

export default DeliveryTimeSlotsRangeDetailsDTO;
