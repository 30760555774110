export interface IIncomingMaterialsDTO {
  next_entries: IIncomingMaterialItemDTO[];
}

export interface IIncomingMaterialItemDTO {
  date: string;
  amount: number;
}

class IncomingMaterialsDTO implements IIncomingMaterialsDTO {
  next_entries: IIncomingMaterialItemDTO[];

  constructor({ next_entries }) {
    this.next_entries = next_entries;
  }
}

export default IncomingMaterialsDTO;
