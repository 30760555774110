import { createSlice } from '@reduxjs/toolkit';
import { ContactFormProps } from './types';
import { editContact, postNewContact } from './async';

export const initialState: ContactFormProps = {
  isOpenContactForm: false,
  createProviderContactRequestStatus: '',
  createProviderContactResponse: { statusCode: 0, message: '' },
  isEditContact: false,
  editProviderContactRequestStatus: '',
  editProviderContactResponse: { statusCode: 0, message: '' }
};

const contactFormSlice = createSlice({
  name: 'contactForm',
  initialState,
  reducers: {
    updateIsOpenContactForm: (state, action) => {
      state.isOpenContactForm = action.payload;
      state.createProviderContactRequestStatus = '';
      state.createProviderContactResponse = { statusCode: 0, message: '' };
      state.editProviderContactResponse = { statusCode: 0, message: '' };
      state.editProviderContactRequestStatus = '';
    },
    updateIsEditContact: (state, action) => {
      state.isEditContact = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postNewContact.fulfilled, (state, action) => {
      state.createProviderContactResponse = action.payload;
      state.createProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(postNewContact.pending, (state, action) => {
      state.createProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(postNewContact.rejected, (state, action) => {
      state.createProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editContact.fulfilled, (state, action) => {
      state.editProviderContactResponse = action.payload;
      state.editProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editContact.pending, (state, action) => {
      state.editProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editContact.rejected, (state, action) => {
      state.editProviderContactRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getisOpenContactForm = (state) =>
  state.contactFormState.isOpenContactForm;
export const getCreateProviderContactFulfilled = (state) =>
  state.contactFormState.createProviderContactRequestStatus === 'fulfilled' &&
  state.contactFormState.createProviderContactResponse.statusCode === 201;
export const getCreateProviderContactRejected = (state) =>
  state.contactFormState.createProviderContactRequestStatus === 'rejected' ||
  (state.contactFormState.createProviderContactRequestStatus === 'fulfilled' &&
    state.contactFormState.createProviderContactResponse.statusCode !== 201);
export const getIsEditContact = (state) => state.contactFormState.isEditContact;
export const getEditProviderContactFulfilled = (state) =>
  state.contactFormState.editProviderContactRequestStatus === 'fulfilled' &&
  state.contactFormState.editProviderContactResponse.statusCode === 200;
export const getEditProviderContactRejected = (state) =>
  state.contactFormState.editProviderContactRequestStatus === 'rejected' ||
  (state.contactFormState.editProviderContactRequestStatus === 'fulfilled' &&
    state.contactFormState.editProviderContactResponse.statusCode !== 200);

export const { updateIsOpenContactForm, updateIsEditContact } =
  contactFormSlice.actions;
export default contactFormSlice.reducer;
