import React from 'react';
import { Theme, ToastContainer, toast } from 'react-toastify';
import { WrapperToastify } from './styles/WrapperToastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastThemeColored: Theme = 'colored';

const Toastify = () => {
  return (
    <WrapperToastify>
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        theme={ToastThemeColored}
      />
    </WrapperToastify>
  );
};

export default Toastify;
