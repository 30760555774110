import ExpirationFilters from '../domain/ExpirationFilters';
import { IExpirationRepository } from '../domain/IExpirationRepository';
import expirationRepository from '../infrastructure/repositories/ExpirationRepository';

class GetExpirationFiltersUseCase {
  private _expirationRepository: IExpirationRepository;

  constructor({ repository }) {
    this._expirationRepository = repository;
  }

  execute() {
    return this._expirationRepository
      .getExpirationsFilters()
      .then((res) => ExpirationFilters.generateFromDTO(res));
  }
}

export { GetExpirationFiltersUseCase };
export default new GetExpirationFiltersUseCase({
  repository: expirationRepository
});
