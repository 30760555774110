import LocaleService from 'infrastructure/i18n/LocaleService';
import { ContactRolesText } from 'modules/inbound/directDelivery/infrastructure/i18n/DirectDeliveryText';
import { ContactRolesTranslations } from 'modules/inbound/directDelivery/infrastructure/i18n/typing';

const contactRolesTranslations: ContactRolesTranslations = {
  ...ContactRolesText[LocaleService.getLanguage()]
};

export const ContactRole = {
  LOGISTICS_MANAGER: 'LOGISTICS_MANAGER',
  EXPEDITIONS_24_HOURS: 'EXPEDITIONS_24_HOURS',
  RESPONSIBLE_EXPEDITION: 'RESPONSIBLE_EXPEDITION',
  LOGISTICS_DIRECTOR: 'LOGISTICS_DIRECTOR',
  GENERIC_EMAIL: 'GENERIC_EMAIL',
  COMMERCIAL_GROUP: 'COMMERCIAL_GROUP',
  LOGISTICS_DISPONENT: 'LOGISTICS_DISPONENT'
} as const;

export const CONTACT_ROLE_LIST = [
  {
    key: ContactRole.LOGISTICS_MANAGER,
    text: contactRolesTranslations.logisticsManager
  },
  {
    key: ContactRole.EXPEDITIONS_24_HOURS,
    text: contactRolesTranslations.expeditions24Hours
  },
  {
    key: ContactRole.RESPONSIBLE_EXPEDITION,
    text: contactRolesTranslations.responsibleExpedition
  },
  {
    key: ContactRole.LOGISTICS_DIRECTOR,
    text: contactRolesTranslations.logisticsDirector
  },
  {
    key: ContactRole.GENERIC_EMAIL,
    text: contactRolesTranslations.genericEmail
  },
  {
    key: ContactRole.COMMERCIAL_GROUP,
    text: contactRolesTranslations.commercialGroup
  },
  {
    key: ContactRole.LOGISTICS_DISPONENT,
    text: contactRolesTranslations.logisticsDisponent
  }
];
