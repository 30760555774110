import {
  LoadTransportDTO,
  PlanningDTO
} from 'modules/inbound/shared/infraestructure/dto/PlanningTransportsDTO';
import { NoJitKeyPlanningDTO } from './NoJitKeyPlanningDTO';

export interface NoJitPlanningDTO {
  [key: string]: {
    planning: PlanningDTO<LoadTransportDTO>[];
    past_loads: LoadTransportDTO[];
  };
}

const noJitPlanningDTOMapper = (data: any): NoJitPlanningDTO => {
  const dataKeys = Object.keys(data);

  const noJitFormattedPlanning = {};

  dataKeys.forEach((planningKey) => {
    noJitFormattedPlanning[planningKey] = NoJitKeyPlanningDTO.generateDTO({
      planning: data[planningKey].planning,
      past_loads: data[planningKey].past_loads
    });
  });

  return noJitFormattedPlanning;
};

export { noJitPlanningDTOMapper };
