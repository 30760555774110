import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface ExpirationsFilters {
  [key: string]: string[] | string | boolean;
}

export interface ExpirationsSearch {
  [key: string]: string[] | string | boolean;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

export const expirationsSearchQueryBuilder = (
  search: ExpirationsSearch
): string => {
  let queryString = '';

  Object.entries(search).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete search[name];
    }
  });

  Object.entries(search).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.CODE:
          queryString += values
            .map((value) => `${NameValues.CODE}${SearchOperators.LK}${value}`)
            .join(SearchOperators.OR);
          break;
        case NameValues.DESCRIPTION:
          queryString += values
            .map(
              (value) =>
                `${NameValues.DESCRIPTION}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.PROVIDER_DELIVERY_NOTE:
          queryString += values
            .map(
              (value) =>
                `${NameValues.PROVIDER_DELIVERY_NOTE}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
      }

      queryString += SearchOperators.OR;
    }
  });

  return queryString.slice(0, -4);
};

export const expirationsFiltersQueryBuilder = (
  filters: ExpirationsFilters
): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (!isValid(values)) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    if (Array.isArray(values)) {
      switch (name) {
        case NameValues.INBOUNDERS:
          queryString += `${NameValues.INBOUNDERS}${
            SearchOperators.IN
          }(${values.join(';')})`;
          break;
        case NameValues.WAREHOUSES:
          queryString += `${NameValues.WAREHOUSES}${
            SearchOperators.IN
          }(${values.join(';')})`;
          break;
        case NameValues.STOCK_DIFFERENTIATIONS:
          queryString += values
            .map(
              (value) =>
                `${NameValues.STOCK_DIFFERENTIATIONS}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
          break;
        case NameValues.MODELS:
          queryString += `${NameValues.MODELS}${
            SearchOperators.IN
          }(${values.join(';')})`;
          break;
        case NameValues.MEASUREMENT_UNITS:
          queryString += `${NameValues.MEASUREMENT_UNITS}${
            SearchOperators.IN
          }(${values.join(';')})`;
          break;
        case NameValues.EXPIRATION_DATE:
          queryString += `${NameValues.EXPIRATION_DATE}:>=:${values[0]}||${NameValues.EXPIRATION_DATE}:<=:${values[1]}`;
          break;
      }
    }

    if (name === NameValues.EXPIRATION_STATUS) {
      queryString += `${NameValues.EXPIRATION_STATUS}:=:${values}`;
    }

    queryString += '||';
  });
  return queryString.slice(0, -2);
};
