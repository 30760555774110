import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadHistoryUseCase from 'modules/inbound/directDelivery/application/DownloadHistoryUseCase';

const downloadTransitsHistory = createAsyncThunk(
  'transitsHistoryData/downloadTransitsHistory',
  async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone
    };
    const response = await DownloadHistoryUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { downloadTransitsHistory };
