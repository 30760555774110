import { CoverageStatus } from 'modules/inbound/shared/domain/CoverageStatusTypes';
import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import {
  LoadTransportJitDTO,
  PlanningDTO
} from 'modules/inbound/shared/infraestructure/dto/PlanningTransportsDTO';

interface Supplier {
  id: number;
  name: string;
  last_updated: string;
  stock: number;
}
export interface PartCoverageDTO {
  code: string;
  provider_code: string;
  date: string;
  con_type: string;
  provider_coverage: number | null;
  sequencer_coverage_status: CoverageStatus;
  lines: string[];
  models: string | null;
  families: string[];
  sequencer_coverage: string | number | null;
  provider_type: string;
  margin: string;
  planning: PlanningDTO<LoadTransportJitDTO>[];
  critical_threshold_days: number;
  security_threshold_days: number;
  logistic_operator: Supplier;
  provider_tier_1: Supplier;
}

class PartCoverageDataDTO {
  planning_dates: string[];
  stock: PartCoverageDTO[];
  pagination: IPaginationDTO;
  update_planning: string | null;

  constructor({ planning_dates, stock, pagination, update_planning }) {
    this.planning_dates = planning_dates;
    this.stock = stock;
    this.pagination = pagination;
    this.update_planning = update_planning;
  }
}

export default PartCoverageDataDTO;
