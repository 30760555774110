import { createAsyncThunk } from '@reduxjs/toolkit';
import getPipelineDetailedCarInfoService from 'modules/outbound/stock/services/GetPipelineDetailedCarInfoService';
import {
  getSelectedCard,
  getResumeCardSelected,
  getSelectedRegionsCode,
  getSelectedFastLane
} from 'modules/outbound/stock/redux/PipelineStockReducer';

const getPipelineDetailedCarInfo = createAsyncThunk(
  `pipelineDetailedCarInfo/getPipelineDetailedCarInfo`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedReference = getSelectedCard(appState);
    const resumeCardSelectedReference = getResumeCardSelected(appState);
    const fastLaneSelected = getSelectedFastLane(appState);
    const selectedRegions = getSelectedRegionsCode(appState);
    const queryParams = {
      fastLane: fastLaneSelected,
      warehouseIds: selectedRegions
    };
    const response = await getPipelineDetailedCarInfoService.get({
      reference: selectedReference.length
        ? selectedReference
        : resumeCardSelectedReference,
      queryParams
    });
    return response;
  }
);

export { getPipelineDetailedCarInfo };
