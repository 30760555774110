import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { WarehouseCapacityItemDTO } from '../infrastructure/dto/WarehouseCapacityDataDTO';

export interface WarehouseCapacityItem {
  id?: number;
  zone: string;
  warehouse: string;
  createdAt: string;
  updatedAt: string;
  capacity: number;
  red: number;
  green: number;
  yellow: number;
}

interface GenerateFromDTOParams {
  data: WarehouseCapacityItemDTO[];
  pagination: IPaginationDTO;
}

class WarehouseCapacityData {
  data: WarehouseCapacityItem[];
  pagination: IPagination;

  constructor({ data, pagination }: WarehouseCapacityData) {
    this.data = data;
    this.pagination = pagination;
  }

  static generateFromDTO({ data, pagination }: GenerateFromDTOParams) {
    let formatWarehouseCapacity: WarehouseCapacityItem[] = [];

    if (data.length) {
      data.forEach((item) => {
        formatWarehouseCapacity.push({
          id: item.id,
          zone: item.zone,
          warehouse: item.warehouse,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          capacity: item.capacity,
          red: item.red,
          green: item.green,
          yellow: item.yellow
        });
      });
    }

    return new WarehouseCapacityData({
      data: formatWarehouseCapacity,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }
}

export default WarehouseCapacityData;
