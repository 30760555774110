export interface LocationCoordinates {
  longitude: number;
  latitude: number;
}

export enum LocationStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  CRITICAL = 'CRITICAL',
  NONE = 'NONE'
}

class GeolocatedTransport {
  transportId: string;
  lastLocationCoordinates: LocationCoordinates;
  status: LocationStatus;
  constructor({ transportId, lastLocationCoordinates, status }) {
    this.transportId = transportId;
    this.lastLocationCoordinates = lastLocationCoordinates;
    this.status = status;
  }

  static generateFromDTO({ transportId, lastLocationCoordinates, status }) {
    return new GeolocatedTransport({
      transportId,
      lastLocationCoordinates,
      status
    });
  }
  static generateEmpty() {
    return new GeolocatedTransport({
      transportId: '',
      lastLocationCoordinates: { longitude: 0, latitude: 0 },
      status: ''
    });
  }
}

export default GeolocatedTransport;
