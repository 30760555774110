import { createAsyncThunk } from '@reduxjs/toolkit';
import getUserInboundUseCase from 'modules/inbound/shared/application/UserInboundUseCases/GetUserInboundUseCase';
import postLoginWebUseCase from 'modules/inbound/shared/application/UserInboundUseCases/PostLoginWebUseCase';

export const getUserInbound = createAsyncThunk(
  'userInbound/getUserInbound',
  async () => {
    const response = await getUserInboundUseCase.execute();
    return response;
  }
);

export const postLoginWeb = createAsyncThunk(
  'userInbound/postLoginWeb',
  async () => {
    const response = await postLoginWebUseCase.execute();
    return response;
  }
);
