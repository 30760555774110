import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { RefillsText } from '../infrastructure/i18n/RefillsText';
import { RefillsTranslations } from '../infrastructure/i18n/typing';

const refillsTranslations: RefillsTranslations = {
  ...RefillsText[LocaleService.getLanguage()]
};

export enum ColumnKeys {
  code = 'code',
  transportOrderNumber = 'transportOrderNumber',
  p17Coverage = 'p17Coverage',
  stock = 'stock',
  warehouseUnitTarget = 'warehouseUnitTarget',
  p17Stock = 'p17Stock',
  refillTimeInMinutes = 'refillTimeInMinutes',
  delayed = 'delayed',
  warehouseUnitSource = 'warehouseUnitSource',
  p17Source = 'p17Source',
  status = 'status',
  type = 'type',
  isTransfer = 'isTransfer',
  handlingUnit = 'handlingUnit',
  createdAt = 'createdAt',
  sourceLocation = 'sourceLocation'
}

export const REFILLS_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: refillsTranslations.code,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.code,
        key: NameValues.CODE,
        value: refillsTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.HANDLING_UNIT,
    value: refillsTranslations.handlingUnit,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.handlingUnit,
        key: NameValues.HANDLING_UNIT,
        value: refillsTranslations.handlingUnit,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.TRANSPORT_ORDER_NUMBER,
    value: refillsTranslations.transportOrderNumber,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.transportOrderNumber,
        key: NameValues.TRANSPORT_ORDER_NUMBER,
        value: refillsTranslations.transportOrderNumber,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.P17_SOURCE,
    value: refillsTranslations.sourceP17,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.p17Source,
        key: NameValues.P17_SOURCE,
        value: refillsTranslations.sourceP17,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.SOURCE_LOCATION,
    value: refillsTranslations.sourceLocation,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.sourceLocation,
        key: NameValues.SOURCE_LOCATION,
        value: refillsTranslations.sourceLocation,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.P17_COVERAGE,
    value: refillsTranslations.coverageP17,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.p17Coverage,
        key: NameValues.P17_COVERAGE,
        value: refillsTranslations.coverageP17,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.P17_STOCK,
    value: refillsTranslations.stockP17,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.p17Stock,
        key: NameValues.P17_STOCK,
        value: refillsTranslations.stockP17,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  },

  {
    key: NameValues.CREATED_AT,
    value: refillsTranslations.createdAt,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.createdAt,
        key: NameValues.CREATED_AT,
        value: refillsTranslations.createdAt,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.REFILL_TIME_IN_MINUTES,
    value: refillsTranslations.refillTimeInMinutes,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.refillTimeInMinutes,
        key: NameValues.REFILL_TIME_IN_MINUTES,
        value: refillsTranslations.refillTimeInMinutes,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  }
];
