import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPageInfo, getSelectedColumn } from '.';
import getExpirationDataUseCase from 'modules/inbound/expiration/application/GetExpirationDataUseCase';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import {
  expirationsFiltersQueryBuilder,
  expirationsSearchQueryBuilder
} from 'modules/inbound/expiration/application/ExpirationsQueryStringBuilder';
import {
  getFilterSearch,
  getFormatedCustomDate,
  getSelectedExpirationStatus,
  getSelectedFilters
} from '../ExpirationFiltersReducer';
import { getHandlingUnitsUseCase } from 'modules/inbound/expiration/application/GetHandlingUnitsUseCase';
import DownloadExpirationUseCase from 'modules/inbound/expiration/application/DownloadExpirationUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';
import { SelectedExpirationsFilters } from '../ExpirationFiltersReducer/types';

const getFiltersQuery = (state) => {
  const {
    selectedInbounders,
    selectedWarehouses,
    selectedModels,
    selectedStockDiferentiations,
    selectedMeasurementUnits
  }: SelectedExpirationsFilters = getSelectedFilters(state);
  const { fromDate, toDate } = getFormatedCustomDate(state);
  const selectedExpirationStatus = getSelectedExpirationStatus(state);

  const filters = {
    [NameValues.INBOUNDERS]: selectedInbounders.map(
      (inbounder) => inbounder.key
    ),
    [NameValues.WAREHOUSES]: selectedWarehouses.map(
      (warehouse) => warehouse.key
    ),
    [NameValues.STOCK_DIFFERENTIATIONS]: selectedStockDiferentiations.map(
      (differentiation) => differentiation.key
    ),
    [NameValues.MODELS]: selectedModels.map((model) => model.key),
    [NameValues.EXPIRATION_DATE]: fromDate && [fromDate, toDate],
    [NameValues.EXPIRATION_STATUS]: selectedExpirationStatus,
    [NameValues.MEASUREMENT_UNITS]: selectedMeasurementUnits.map(
      (unit) => unit.key
    )
  };

  return expirationsFiltersQueryBuilder(filters);
};

const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.DESCRIPTION]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.PROVIDER_DELIVERY_NOTE]: splitStringByAGivenCharacter(
      searchValue,
      '+'
    )
  };

  return expirationsSearchQueryBuilder(search);
};

const getExpirationsData = createAsyncThunk(
  'expirations/getExpirationsData',
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPageInfo(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);
    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = `{${selectedColumn.key}:${sortOrder}}`;

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      page: page,
      size,
      sort: selectedColumn.key.length ? sort : '',
      search: queryString && `{${queryString}}`
    };

    const response = await getExpirationDataUseCase.execute({ queryParams });
    return response;
  }
);

const getHandlingUnits = createAsyncThunk(
  'expirations/getHandlingUnits',
  async (_, { getState }) => {
    const appState = getState();
    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await getHandlingUnitsUseCase.execute({ queryParams });
    return response;
  }
);

const getExpirationsDownload = createAsyncThunk(
  'expirations/getExpirationsDownload',
  async (_, { getState }) => {
    const appState = getState();
    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadExpirationUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getExpirationsData, getHandlingUnits, getExpirationsDownload };
