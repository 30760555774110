import { CriticalPieceDTO } from '../infrastructure/dto/CriticalPieceDTO';
import {
  CriticalStockType,
  MaterialIndicator
} from '../infrastructure/dto/types';

class CriticalPiece {
  criticalStockType: CriticalStockType;
  code: string;
  materialIndicator: MaterialIndicator;

  constructor({ criticalStockType, code, materialIndicator }) {
    this.criticalStockType = criticalStockType;
    this.code = code;
    this.materialIndicator = materialIndicator;
  }

  static generateFromDTO(dto: CriticalPieceDTO): CriticalPiece {
    return new CriticalPiece({
      criticalStockType: dto?.critical_stock_type,
      code: dto?.code,
      materialIndicator: dto?.material_indicator
    });
  }
}

export { CriticalPiece };
