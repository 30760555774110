import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadUpcomingTransitsService from 'modules/inbound/directDelivery/application/DownloadUpcomingTransitsService';
import GetUpcomingTransitsService from 'modules/inbound/directDelivery/application/GetUpcomingTransitsService';
import getFiltersQueryString from 'modules/inbound/shared/domain/GetFiltersQuery';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { getCurrentPage, getResultsPerPage } from '.';
import { getSelectedFilters } from '../DirectDeliveryFiltersReducer';
import { Filters } from './types';

const getUpcomingTransitsData = createAsyncThunk(
  'upcomingTransitsData/getUpcomingTransitsData',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);

    const { selectedProviderIds } = getSelectedFilters(appState);
    const queryFilters: Filters[] = [];

    queryFilters.push({
      name: NameValues.PROVIDER_IDS,
      values: selectedProviderIds
    });

    let querySearch: string[] = [];
    querySearch = getFiltersQueryString(queryFilters);

    const query = querySearch.filter((item) => item !== '').join('||');

    const queryParams = {
      page: currentPage,
      size,
      search: query && `{${query}}`
    };

    const response = await GetUpcomingTransitsService.execute({ queryParams });
    return response;
  }
);

const downloadUpcomingTransits = createAsyncThunk(
  'upcomingTransitsData/downloadUpcomingTransits',
  async (_, { getState }) => {
    const appState = getState();
    const { selectedProviderIds } = getSelectedFilters(appState);
    const queryFilters: Filters[] = [];
    queryFilters.push({
      name: NameValues.PROVIDER_IDS,
      values: selectedProviderIds
    });
    let querySearch: any[] = [];
    querySearch = getFiltersQueryString(queryFilters);
    const query = querySearch.filter((item) => item !== '').join('||');
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: query && `{${query}}`
    };
    const response = await DownloadUpcomingTransitsService.execute({
      queryParams
    });
    return response;
  }
);

export { getUpcomingTransitsData, downloadUpcomingTransits };
