import { createSlice } from '@reduxjs/toolkit';
import IncomingMaterials from 'modules/inbound/entries/domain/NextHours/IncomingMaterials';
import {
  getIncomingMaterialsNextDays,
  getIncomingMaterialsNextHours
} from './async';
import { DischargeDocksState, EntryType } from './types';

export const GREEN = 'green700';

export const initialState: DischargeDocksState = {
  incomingMaterialsNextDays: IncomingMaterials.generateEmpty(),
  incomingMaterialsNextHours: IncomingMaterials.generateEmpty(),
  incomingMaterialsNextDaysRequestStatus: '',
  incomingMaterialsNextHoursRequestStatus: '',
  selectedHoursEntryType: EntryType.PACKAGES,
  selectedDaysEntryType: EntryType.PACKAGES,
  selectedHour: {
    top: '',
    color: '',
    utcDate: ''
  }
};

const dischargeDocksSlice = createSlice({
  name: 'dischargeDocks',
  initialState,
  reducers: {
    updateSelectedHourEntryType: (state, action) => {
      state.selectedHoursEntryType = action.payload;
    },
    updateSelectedDaysEntryType: (state, action) => {
      state.selectedDaysEntryType = action.payload;
    },
    updateSelectedHour: (state, action) => {
      state.selectedHour = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getIncomingMaterialsNextDays.fulfilled, (state, action) => {
      state.incomingMaterialsNextDays = action.payload || [];
      state.incomingMaterialsNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingMaterialsNextDays.pending, (state, action) => {
      state.incomingMaterialsNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingMaterialsNextDays.rejected, (state, action) => {
      state.incomingMaterialsNextDaysRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getIncomingMaterialsNextHours.fulfilled,
      (state, action) => {
        state.incomingMaterialsNextHours = action.payload || [];
        state.incomingMaterialsNextHoursRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getIncomingMaterialsNextHours.pending, (state, action) => {
      state.incomingMaterialsNextHoursRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getIncomingMaterialsNextHours.rejected, (state, action) => {
      state.incomingMaterialsNextHoursRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getSelectedHoursEntryType = (state) =>
  state.dischargeDocksState.selectedHoursEntryType;

export const getSelectedHour = (state) =>
  state.dischargeDocksState.selectedHour;

export const getSelectedDaysEntryType = (state) =>
  state.dischargeDocksState.selectedDaysEntryType;

export const getIncomingMaterialsForNextsDaysFormated = (state) =>
  state.dischargeDocksState.incomingMaterialsNextDays.nextEntries.map(
    ({ date, amount }) => ({
      stackedBars: [{ color: GREEN, number: amount }],
      xAxisLabels: { top: date, bottom: amount.toString() }
    })
  ) || [];

export const getIncomingMaterialsForNextsHoursFormated = (state) =>
  state.dischargeDocksState.incomingMaterialsNextHours.nextEntries.map(
    ({ hours, amount, utcDate }) => ({
      utcDate,
      stackedBars: [{ color: GREEN, number: amount }],
      xAxisLabels: { top: hours, bottom: amount.toString() }
    })
  ) || [];

export const getIncomingMaterialsForNextsHoursMaxValueLegend = (state) => {
  const maxValue =
    Math.ceil(
      Math.max(
        ...state.dischargeDocksState.incomingMaterialsNextHours.nextEntries.map(
          ({ amount }) => amount
        )
      ) / 4
    ) * 4;
  return maxValue;
};

export const getIncomingMaterialsForNextsDaysMaxValueLegend = (state) => {
  const maxValue =
    Math.ceil(
      Math.max(
        ...state.dischargeDocksState.incomingMaterialsNextDays.nextEntries.map(
          ({ amount }) => amount
        )
      ) / 4
    ) * 4;

  return maxValue;
};

export const getIncomingMaterialsForNextsHoursLegend = (state) => {
  return Array(...Array(5)).map(
    (_, i) => (i * getIncomingMaterialsForNextsHoursMaxValueLegend(state)) / 4
  );
};

export const getIncomingMaterialsForNextsDaysLegend = (state) => {
  return Array(...Array(5)).map(
    (_, i) => (i * getIncomingMaterialsForNextsDaysMaxValueLegend(state)) / 4
  );
};

export const getIncomingMaterialsForNextsDaysIsPending = (state) =>
  state.dischargeDocksState.incomingMaterialsNextDaysRequestStatus ===
  'pending';

export const getIncomingMaterialsForNextsHoursIsPending = (state) =>
  state.dischargeDocksState.incomingMaterialsNextHoursRequestStatus ===
  'pending';

export const {
  updateSelectedHourEntryType,
  updateSelectedDaysEntryType,
  updateSelectedHour
} = dischargeDocksSlice.actions;
export default dischargeDocksSlice.reducer;
