import { Translation } from 'infrastructure/i18n/typing';
import { FactoryBcnTranslations } from './typing';

export const FactoryBcnText: Translation<FactoryBcnTranslations> = {
  es: {
    pageTitle: 'Factoría BCN',
    menuTitle: 'Factoría BCN',
    emptyMessage: 'Aún no hay datos de Factoría BCN disponibles',
    code: 'Clave',
    amount: 'Cantidad',
    models: 'Modelos',
    description: 'Descripción',
    supplyContainer: 'Contenedor',
    supplyContainerCapacity: 'Capacidad',
    warehouse: 'Almacen',
    mrt: 'MRT',
    stock: 'Stock',
    lockedStock: 'Stock Bloq.',
    lines: 'Líneas',
    morning: 'Mañana',
    afternoon: 'Tarde',
    night: 'Noche',
    coverageMRT: 'Cobertura MRT',
    coverageMRTDescription:
      'Hasta que día y hora se <b>cubre</b> la producción con el <b>stock en MRT</b><br/><br/>MAX = necesidades cubiertas > 10 días<br/><br/>Las necesidades provienen del Programa de Chapa.',
    bcn: 'BCN',
    readyStock: 'Stock Listo',
    location: 'Ubicación',
    stockInReview: 'Stock Repaso',
    warehousesMultiselectAvailable: 'Almacenes disponibles',
    warehousesMultiselectSelected: 'Almacenes seleccionados',
    warehousesMultiselectEmpty: 'No hay almacenes disponibles',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    modelsMultiselectAvailable: 'Modelos disponibles',
    modelsMultiselectSelected: 'Modelos seleccionados',
    modelsMultiselectEmpty: 'No hay modelos disponibles',
    MRTWarehouse: 'Almacen MRT',
    BCNWarehouse: 'Almacen BCN',
    bodyworkNeedsUpdatedAt: 'Act. plan producción chapistería: ',
    stockMrtUpdatedAt: 'Act. stock MRT: ',
    stockBcnUpdatedAt: 'Act. stock BCN: ',
    truck: 'Camión',
    train: 'Tren',
    margin: 'Cob. MRT con transporte',
    T2: 'T2',
    stockT2: 'Stock T2',
    stockT2UpdatedAt: 'F. Act. stock T2',
    materialToDeliver: 'Material a expedir',
    inTime: 'A tiempo',
    inPieces: 'Piezas entrantes',
    inContainers: 'Contenedores entrantes',
    modelTooltip: '\n  La fuente de información es Maestro Factory BCN.',
    warehouseTooltip:
      'Almacén de las unidades de stock almacenadas en BCN. <br/><br/>La fuente de origen es el Maestro Clave Taller.',
    inTimeTooltip:
      '<b>Material a expedir</b> a la fábrica para <b>llegar a la cobertura de seguridad</b>.<ul><li>En horas</li></ul>',
    inPiecesTooltip:
      '<b>Material a expedir</b> a la fábrica para <b>llegar a la cobertura de seguridad</b>.<ul><li>En unidades</li></ul>',
    inContainersTooltip:
      '<b>Material a expedir</b> a la fábrica para <b>llegar a la cobertura de seguridad</b>.<ul><li>En contenedores</li></ul>',
    searchPlaceholder: 'Búsqueda por clave o descripción',
    lockedStockBCN: 'Stock bloqueado',
    lockedStockBCNTooltip:
      '<b>Bloqueos por calidad:</b> Material bloqueado por defectos de calidad en el cual se están analizando el lote de las piezas para ver si hay imperfecciones, prevenciones. <br /><b>Bloqueos por repasos:</b> Piezas fabricadas por SEAT que se tienen que re-trabajar por imperfecciones.'
  },
  en: {
    pageTitle: 'Factory BCN',
    menuTitle: 'Factory BCN',
    emptyMessage: 'There is no Factory Bcn data available',
    code: 'Part',
    amount: 'Amount',
    models: 'Models',
    description: 'Description',
    supplyContainer: 'Container',
    supplyContainerCapacity: 'Capacity',
    warehouse: 'Warehouse',
    mrt: 'MRT',
    stock: 'Stock',
    lockedStock: 'Lock stock',
    lines: 'Line',
    morning: 'Morning',
    afternoon: 'Afternoon',
    night: 'Night',
    coverageMRT: 'Coverage MRT',
    coverageMRTDescription:
      'Until what day and time is production <b>covered</b> with the <b>stock in MRT</b><br/><br/>MAX = needs covered > 10 days<br/><br/ >The needs come from the Bodyshop Program.',
    bcn: 'BCN',
    readyStock: 'Ready Stock',
    location: 'Location',
    stockInReview: 'Stock Review',
    warehousesMultiselectAvailable: 'Warehouses available',
    warehousesMultiselectSelected: 'Warehouses selected',
    warehousesMultiselectEmpty: "There aren't warehouses available",
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    modelsMultiselectAvailable: 'Models available',
    modelsMultiselectSelected: 'Models selected',
    modelsMultiselectEmpty: "There aren't models available",
    MRTWarehouse: 'Warehouse MRT',
    BCNWarehouse: 'Warehouse BCN',
    bodyworkNeedsUpdatedAt: 'Sheet metal production plan upd.: ',
    stockMrtUpdatedAt: 'Stock MRT upd.: ',
    stockBcnUpdatedAt: 'Stock BCN upd.: ',
    truck: 'Truck',
    train: 'Train',
    margin: 'Cov. MRT with transport',
    T2: 'T2',
    stockT2: 'Stock T2',
    stockT2UpdatedAt: 'Upd. date stock T2',
    materialToDeliver: 'Material to be shipped',
    inTime: 'In Time',
    inPieces: 'In pieces',
    inContainers: 'In containers',
    modelTooltip: '\n\n  The source of the information is Maestro Factory BCN.',
    warehouseTooltip:
      'Warehouse of stock units stored in BCN. <br/><br/>The source of origin is the Master Code Workshop.',
    inTimeTooltip:
      '<b>Material to be sent</b> to the factory to <b>reach security coverage</b>.<ul><li>In hours</li></ul>',
    inPiecesTooltip:
      '<b>Material to be sent</b> to the factory to <b>reach security coverage</b>.<ul><li>In units</li></ul>',
    inContainersTooltip:
      '<b>Material to be sent</b> to the factory to <b>reach security coverage</b>.<ul><li>In containers</li></ul>',
    searchPlaceholder: 'Search by part or description',
    lockedStockBCN: 'Locked stock',
    lockedStockBCNTooltip:
      '<b>Blocks due to quality:</b> Material blocked due to quality defects in which the batch of parts is being analyzed to see if there are imperfections, preventions. <br /><b>Blockages due to revisions:</b> Parts manufactured by SEAT that have to be reworked due to imperfections.'
  },
  ca: {
    pageTitle: 'Factoria BCN',
    menuTitle: 'Factoria BCN',
    emptyMessage: 'No hi ha dades de Factoria BCN disponibles',
    code: 'Clau',
    amount: 'Quantitat',
    models: 'Models',
    description: 'Descripció',
    supplyContainer: 'Contenidor',
    supplyContainerCapacity: 'Capacitat',
    warehouse: 'Magatzem',
    mrt: 'MRT',
    stock: 'Stock',
    lockedStock: 'Stock Bloq.',
    lines: 'Línia',
    morning: 'Matí',
    afternoon: 'Tarda',
    night: 'Nit',
    coverageMRT: 'Cobertura MRT',
    coverageMRTDescription:
      "Fins que dia i hora es <b>cobreix</b> la producció amb l'<b>stock a MRT</b><br/><br/>MAX = necessitats cobertes > 10 dies<br/><br/>Les necessitats provenen del Programa de Xapa.",
    bcn: 'BCN',
    readyStock: 'Stock Llest',
    location: 'Ubicació',
    stockInReview: 'Stock Repàs',
    warehousesMultiselectAvailable: 'Magatzems disponibles',
    warehousesMultiselectSelected: 'Magatzems seleccionats',
    warehousesMultiselectEmpty: 'No hi ha Magatzems disponibles',
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    modelsMultiselectAvailable: 'Models disponibles',
    modelsMultiselectSelected: 'Models seleccionats',
    modelsMultiselectEmpty: 'No hi ha models disponibles',
    MRTWarehouse: 'Magatzem MRT',
    BCNWarehouse: 'Magatzem BCN',
    bodyworkNeedsUpdatedAt: 'Act. pla producció xapisteria: ',
    stockMrtUpdatedAt: 'Act. stock MRT: ',
    stockBcnUpdatedAt: 'Act. stock BCN: ',
    truck: 'Camió',
    train: 'Tren',
    margin: 'Cob. MRT amb transport',
    T2: 'T2',
    stockT2: 'Stock T2',
    stockT2UpdatedAt: 'D. Act. stock T2',
    materialToDeliver: 'Material a expedir',
    inTime: 'A temps',
    inPieces: 'Peces entrants',
    inContainers: 'Contenidors entrants',
    modelTooltip: '\n\n  La font de la informació és Maestro Factory BCN.',
    warehouseTooltip:
      "Magatzem de les unitats d'estoc emmagatzemades a BCN. <br/><br/>La font d'origen és el Mestre Clau Taller.",
    inTimeTooltip:
      '<b>Material a expedir</b> a la fàbrica per <b>arribar a la cobertura de seguretat</b>.<ul><li>En hores</li></ul>',
    inPiecesTooltip:
      '<b>Material a expedir</b> a la fàbrica per <b>arribar a la cobertura de seguretat</b>.<ul><li>En unitats</li></ul>',
    inContainersTooltip:
      '<b>Material a expedir</b> a la fàbrica per <b>arribar a la cobertura de seguretat</b>.<ul><li>En contenidors</li></ul>',
    searchPlaceholder: 'Cerca per clau o descripció',
    lockedStockBCN: 'Estoc bloquejat',
    lockedStockBCNTooltip:
      "<b>Bloquejos per qualitat:</b> Material bloquejat per defectes de qualitat en què s'estan analitzant el lot de les peces per veure si hi ha imperfeccions, prevencions. <br /><b>Bloquejos per repassos:</b> Peces fabricades per SEAT que s'han de re-treballar per imperfeccions."
  }
};
