import { createAsyncThunk } from '@reduxjs/toolkit';
import { entriesQueryStringBuilder } from 'modules/inbound/entries/application/EntriesQueryStringBuilder';
import getIncomingMaterialsUseCase from 'modules/inbound/entries/application/NextHours/GetIncomingMaterialsUseCase';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { getSelectedHoursEntryType, getSelectedDaysEntryType } from '.';
import {
  getFilterByCriticalPieces,
  getFilterByUrgentTrucks,
  getFilterSearch,
  getSelectedFilters
} from '../EntriesDataReducer';
import { GroupBy } from './types';

const getIncomingMaterialsNextDays = createAsyncThunk(
  'dischargeDocks/getIncomingMaterialsNextDays',
  async (_, { getState }) => {
    const appState = getState();
    const selectedDaysEntryType = getSelectedDaysEntryType(appState);
    const searchValue = getFilterSearch(appState);
    const { selectedWarehouses } = getSelectedFilters(appState);

    const filters = {
      [NameValues.WAREHOUSE]: selectedWarehouses.map(
        (supplier) => supplier.key
      ),
      [NameValues.CODE]: searchValue
    };

    const queryString = entriesQueryStringBuilder(filters);

    const queryParams = {
      hours: 192, // To show 8 days need to send 8*24h
      type: selectedDaysEntryType,
      group_by: GroupBy.DAYS,
      search: !!queryString.length && `{${queryString}}`
    };
    const response = await getIncomingMaterialsUseCase.execute({ queryParams });
    return response;
  }
);

const getIncomingMaterialsNextHours = createAsyncThunk(
  'dischargeDocks/getIncomingMaterialsNextHours',
  async (_, { getState }) => {
    const appState = getState();
    const selectedHoursEntryType = getSelectedHoursEntryType(appState);
    const searchValue = getFilterSearch(appState);
    const { selectedWarehouses, selectedSuppliers, selectedUnloadingPoints } =
      getSelectedFilters(appState);
    const filterByUrgentTrucks = getFilterByUrgentTrucks(appState);
    const filterByCriticalPiece: boolean = getFilterByCriticalPieces(appState);

    const filters = {
      [NameValues.SUPPLIER_NAME]: selectedSuppliers.map(
        (supplier) => supplier.key
      ),
      [NameValues.WAREHOUSE]: selectedWarehouses.map(
        (supplier) => supplier.key
      ),
      [NameValues.UNLOADING_POINT]: selectedUnloadingPoints.map(
        (unloadingPoint) => unloadingPoint.key
      ),
      [NameValues.CODE]: searchValue,
      [NameValues.URGENT]: filterByUrgentTrucks,
      [NameValues.HAS_CRITICAL_PIECES]: filterByCriticalPiece
    };

    const queryString = entriesQueryStringBuilder(filters);

    const queryParams = {
      hours: 24,
      type: selectedHoursEntryType,
      group_by: GroupBy.HOURS,
      search: !!queryString.length && `{${queryString}}`
    };
    const response = await getIncomingMaterialsUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getIncomingMaterialsNextDays, getIncomingMaterialsNextHours };
