import fulfillmentRepository from 'modules/outbound/fulfillment/infrastructure/repositories/FulfillmentRepository';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import localeService, {
  LocaleService
} from 'infrastructure/i18n/LocaleService';

class DownloadFulfillmentFinishedGoodsService {
  private _fulfillmentRepository: ITransitRepository;
  private localeService: LocaleService;

  constructor({ fulfillmentRepository, localeService }) {
    this._fulfillmentRepository = fulfillmentRepository;
    this.localeService = localeService;
  }

  execute({ queryParams }: { queryParams: object }) {
    queryParams = { ...queryParams, lang: this.localeService.getLanguage() };
    return this._fulfillmentRepository.downloadFinishedGoods!({
      queryParams
    });
  }
}

export default new DownloadFulfillmentFinishedGoodsService({
  fulfillmentRepository: fulfillmentRepository,
  localeService
});

export { DownloadFulfillmentFinishedGoodsService };
