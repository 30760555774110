export interface IDeliveryTimeSlotDTO {
  name: string;
  totalCars: number;
}

class DeliveryTimeSlotDTO implements IDeliveryTimeSlotDTO {
  name: string;
  totalCars: number;

  constructor({ name, totalCars }) {
    this.name = name;
    this.totalCars = totalCars;
  }
}

export default DeliveryTimeSlotDTO;
