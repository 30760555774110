import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCurrentSelectedProviderId,
  getProviderIdsCurrentPage,
  getSelectedContact
} from '.';
import GetProviderContactListUseCase from 'modules/inbound/shared/application/ProviderContactListUseCases/GetProviderContactListUseCase';
import DeleteContactUseCase from 'modules/inbound/shared/application/ProviderContactListUseCases/DeleteContactUseCase';

export const getProviderContactList = createAsyncThunk(
  'contactList/getProviderContactList',
  async (_, { getState }) => {
    const appState = getState();
    const providerIdsCurrentPage = getProviderIdsCurrentPage(appState);
    const querySearch: number[] = providerIdsCurrentPage.join(';');

    const queryParams = {
      search: `{provider_ids:IN:(${querySearch})}`
    };
    const response = await GetProviderContactListUseCase.execute({
      queryParams
    });
    return response;
  }
);

export const deleteContact = createAsyncThunk(
  'contactList/deleteContact',
  async (_, { getState }) => {
    const appState = getState();
    const selectedProvider = getCurrentSelectedProviderId(appState);
    const contact = getSelectedContact(appState);
    const response = await DeleteContactUseCase.execute(
      selectedProvider,
      contact.id
    );
    return response;
  }
);
