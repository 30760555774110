import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

class DeliveriesGeolocationCheckpointDTO {
  name: string;
  type: CheckpointType;
  cumulativeDelay: number;
  checkpointDelayed: number;
  totalFinishedGoods: number;

  constructor({
    name,
    type,
    cumulativeDelay,
    checkpointDelayed,
    totalFinishedGoods
  }) {
    this.name = name;
    this.type = type;
    this.cumulativeDelay = cumulativeDelay;
    this.checkpointDelayed = checkpointDelayed;
    this.totalFinishedGoods = totalFinishedGoods;
  }
}

export default DeliveriesGeolocationCheckpointDTO;
