export type MeasurementUnit = 'ST' | 'M' | 'KG' | 'L';

class ExpirationsFiltersDTO {
  inbounders: string[];
  measurement_units: MeasurementUnit[];
  models: string[];
  stock_differentiations: string[];
  warehouses: string[];

  constructor({
    inbounders,
    measurement_units,
    models,
    stock_differentiations,
    warehouses
  }) {
    this.inbounders = inbounders;
    this.measurement_units = measurement_units;
    this.models = models;
    this.stock_differentiations = stock_differentiations;
    this.warehouses = warehouses;
  }
}

export { ExpirationsFiltersDTO };
