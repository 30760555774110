import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface ContainerNeedsItemDTO {
  basic_container: string;
  supply_container: string;
  amount: number;
}

class ContainerNeedsDTO {
  containers_needed: ContainerNeedsItemDTO[];
  pagination: IPaginationDTO;

  constructor({ containers_needed, pagination }) {
    this.containers_needed = containers_needed;
    this.pagination = pagination;
  }
}

export default ContainerNeedsDTO;
