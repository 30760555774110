import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadFactoryBCNUseCase from 'modules/inbound/factoryBcn/application/DownloadFactoryBCNUseCase';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { getCurrentPage, getResultsPerPage, getSelectedColumn } from '.';
import getFactoryBcnDataUseCase from '../../../application/GetFactoryBcnDataUseCase';
import {
  getFilterSearch,
  getSelectedFilters
} from '../FactoryBcnFiltersReducer';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import {
  factoryBCNFiltersQueryBuilder,
  factoryBCNSearchQueryBuilder
} from 'modules/inbound/factoryBcn/application/FactoryBCNQueryStringBuilder';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';

const getFiltersQuery = (state) => {
  const {
    selectedMRTWarehouses,
    selectedBCNWarehouses,
    selectedLines,
    selectedModels
  } = getSelectedFilters(state);

  const selectedFilters = {
    [NameValues.LINE]: selectedLines.map((line) => line.key),
    [NameValues.MODEL]: selectedModels.map((model) => model.key),
    [NameValues.WAREHOUSE_MRT]: selectedMRTWarehouses.map(
      (warehouseMRT) => warehouseMRT.key
    ),
    [NameValues.WAREHOUSE_BCN]: selectedBCNWarehouses.map(
      (warehouseBCN) => warehouseBCN.key
    )
  };

  return factoryBCNFiltersQueryBuilder(selectedFilters);
};

const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.DESCRIPTION]: splitStringByAGivenCharacter(searchValue, '+')
  };

  return factoryBCNSearchQueryBuilder(search);
};

const getFactoryBcnData = createAsyncThunk(
  'factoryBcn/getFactoryBcnData',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = selectedColumn.key
      ? `{${selectedColumn.key}:${sortOrder}}`
      : `{${NameValues.MRT_STOCK_COVERAGE}:${Sort.ASC}}`;

    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      page: currentPage,
      size,
      sort: sort,
      search: queryString && `{${queryString}}`
    };

    const response = await getFactoryBcnDataUseCase.execute({
      queryParams
    });

    return response;
  }
);

const downloadFactoryBCN = createAsyncThunk(
  'factoryBcn/downloadFactoryBCN',
  async (_, { getState }) => {
    const appState = getState();

    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadFactoryBCNUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getFactoryBcnData, downloadFactoryBCN };
