import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { FamilyDTO } from '../infrastructure/dto/FamiliesCoverageDTO';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';

const jitTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};
export interface Family {
  family: string;
  line: string;
  forecastStoppageAt: string;
  providerId: string;
  providerName: string;
  logisticOperatorId: string;
  logisticOperatorName: string;
  onAlert: boolean;
}

interface GenerateFromDTOParams {
  families: FamilyDTO[];
  pagination: IPaginationDTO;
}

class FamiliesCoverage {
  families: Family[];
  pagination: IPagination;

  constructor({ families, pagination }: FamiliesCoverage) {
    this.families = families;
    this.pagination = pagination;
  }

  static generateFromDTO({
    families,
    pagination
  }: GenerateFromDTOParams): FamiliesCoverage {
    return new FamiliesCoverage({
      families:
        families.map((family) => ({
          family: family.family,
          line: family.line,
          forecastStoppageAt: family.forecast_stoppage_at
            ? GetDateFormatedService.getFullDateTime(
                family.forecast_stoppage_at
              )
            : NO_DATA,
          providerId: family.provider_id
            ? family.provider_id.split('|').join(' | ')
            : NO_DATA,
          providerName: family.provider_name || NO_DATA,
          logisticOperatorId: family.logistic_operator_id
            .replaceAll('|', ' | ')
            .replaceAll(NO_DATA, jitTranslations.notApply),
          logisticOperatorName: family.logistic_operator_name
            .replaceAll('|', ' | ')
            .replaceAll(NO_DATA, jitTranslations.notApply),
          onAlert: family.forecast_stoppage_at ? family.on_alert : false
        })) || [],
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }
}

export default FamiliesCoverage;
