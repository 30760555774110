import { StockSaturationDTO } from '../application/stockSaturationDTOMapper';

type GenerateFromDTOParams = StockSaturationDTO;

class StockSaturation {
  daysForSaturation: number;

  constructor({ daysForSaturation }: StockSaturation) {
    this.daysForSaturation = daysForSaturation;
  }

  static generateFromDTO({
    daysForSaturation
  }: GenerateFromDTOParams): StockSaturation {
    return new StockSaturation({
      daysForSaturation: daysForSaturation
    });
  }
}

export default StockSaturation;
