import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { FactoryBcnText } from '../infrastructure/i18n/FactoryBcnText';
import { FactoryBcnTranslations } from '../infrastructure/i18n/typing';

const factoryBcnTranslations: InboundTranslations & FactoryBcnTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...FactoryBcnText[LocaleService.getLanguage()]
};

enum FactoryBcnColumnKeys {
  code = 'code',
  description = 'description',
  lines = 'lines',
  models = 'models',
  providerId = 'providerId',
  supplyContainer = 'supplyContainer',
  supplyContainerCapacity = 'supplyContainerCapacity',
  labelStock = 'labelStock',
  stock = 'stock',
  warehouseMRT = 'warehouseMRT',
  lockedStock = 'lockedStock',
  stockCoverageMRT = 'stockCoverageMRT',
  margin = 'margin',
  labelStockBCN = 'labelStockBCN',
  readyStock = 'readyStock',
  lockedStockBCN = 'lockedStockBCN',
  locations = 'locations',
  stockInReview = 'stockInReview',
  warehouseBCN = 'warehouseBCN',
  inTime = 'inTime',
  inPieces = 'inPieces',
  inContainer = 'inContainer',
  lineMovementType = 'lineMovementType',
  lineMovementAmount = 'lineMovementAmount',
  lineMovementDate = 'lineMovementDate',
  stockT2 = 'stockT2',
  stockT2UpdatedAt = 'stockT2UpdatedAt'
}

export const FACTORY_BCN_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: factoryBcnTranslations.code,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.code,
        key: NameValues.CODE,
        value: factoryBcnTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.MODELS,
    value: factoryBcnTranslations.models,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.models,
        key: NameValues.MODELS,
        value: factoryBcnTranslations.models,
        sortable: true,
        tooltipInfo: `${factoryBcnTranslations.modelCarRelation}${factoryBcnTranslations.modelTooltip}`,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.LINES,
    value: factoryBcnTranslations.lines,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.lines,
        key: NameValues.LINES,
        value: factoryBcnTranslations.lines,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.DESCRIPTION,
    value: factoryBcnTranslations.description,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.description,
        key: NameValues.DESCRIPTION,
        value: factoryBcnTranslations.description,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.SUPPLY_CONTAINER,
    value: factoryBcnTranslations.supplyContainer,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.supplyContainer,
        key: NameValues.SUPPLY_CONTAINER,
        value: factoryBcnTranslations.supplyContainer,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.SUPPLY_CONTAINER_CAPACITY,
    value: factoryBcnTranslations.supplyContainerCapacity,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.supplyContainerCapacity,
        key: NameValues.SUPPLY_CONTAINER_CAPACITY,
        value: factoryBcnTranslations.supplyContainerCapacity,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.STOCK_COVERAGE_MRT,
    value: factoryBcnTranslations.coverageMRT,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.stockCoverageMRT,
        key: NameValues.MRT_STOCK_COVERAGE,
        value: factoryBcnTranslations.coverageMRT,
        sortable: true,
        draggable: true,
        resize: true,
        tooltipInfo: factoryBcnTranslations.coverageMRTDescription,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.MRT_MARGIN,
    value: factoryBcnTranslations.margin,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.margin,
        key: NameValues.MRT_MARGIN,
        value: factoryBcnTranslations.margin,
        sortable: true,
        draggable: true,
        resize: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.STOCK_BLOCK,
    value: factoryBcnTranslations.mrt,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.STOCK_MRT,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.stock,
        key: NameValues.STOCK_MRT,
        value: factoryBcnTranslations.stock,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.warehouseMRT,
        key: NameValues.WAREHOUSE_MRT,
        value: factoryBcnTranslations.warehouse,
        sortable: true,
        draggable: true
      },
      {
        uiKey: FactoryBcnColumnKeys.lockedStock,
        key: NameValues.LOCKED_STOCK_MRT,
        value: factoryBcnTranslations.lockedStock,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.MOVEMENTS_LINE,
    value: factoryBcnTranslations.movementLabel,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.lineMovementType,
        key: NameValues.MOVEMENT_TYPE,
        value: factoryBcnTranslations.movementType,
        sortable: false,
        draggable: true,
        tooltipInfo: factoryBcnTranslations.movementsLineTooltip
      },
      {
        uiKey: FactoryBcnColumnKeys.lineMovementAmount,
        key: NameValues.MOVEMENT_AMOUNT,
        value: factoryBcnTranslations.movementAmount,
        sortable: false,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.lineMovementDate,
        key: NameValues.MOVEMENT_DATE,
        value: factoryBcnTranslations.movementDate,
        sortable: false,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.BCN_BLOCK,
    value: factoryBcnTranslations.bcn,
    headerVisible: true,
    headerSpan: 5,
    isVisibleCheck: true,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.READY_STOCK,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.readyStock,
        key: NameValues.READY_STOCK,
        value: factoryBcnTranslations.readyStock,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.lockedStockBCN,
        key: NameValues.LOCKED_STOCK_BCN,
        value: factoryBcnTranslations.lockedStockBCN,
        sortable: true,
        draggable: true,
        align: 'right',
        tooltipInfo: factoryBcnTranslations.lockedStockBCNTooltip
      },
      {
        uiKey: FactoryBcnColumnKeys.locations,
        key: NameValues.LOCATION_BCN,
        value: factoryBcnTranslations.location,
        sortable: false,
        draggable: true
      },
      {
        uiKey: FactoryBcnColumnKeys.warehouseBCN,
        key: NameValues.WAREHOUSE_BCN,
        value: factoryBcnTranslations.warehouse,
        sortable: true,
        tooltipInfo: factoryBcnTranslations.warehouseTooltip,
        draggable: true
      },
      {
        uiKey: FactoryBcnColumnKeys.stockInReview,
        key: NameValues.STOCK_IN_REVIEW_BCN,
        value: factoryBcnTranslations.stockInReview,
        sortable: true,
        draggable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.MATERIAL_TO_DELIVER,
    value: factoryBcnTranslations.materialToDeliver,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.IN_PIECES,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.inTime,
        key: NameValues.IN_TIME,
        value: factoryBcnTranslations.inTime,
        tooltipInfo: factoryBcnTranslations.inTimeTooltip,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.inPieces,
        key: NameValues.IN_PIECES,
        value: factoryBcnTranslations.inPieces,
        tooltipInfo: factoryBcnTranslations.inPiecesTooltip,
        sortable: true,
        draggable: true,
        resize: false,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.inContainer,
        key: NameValues.IN_CONTAINERS,
        value: factoryBcnTranslations.inContainers,
        tooltipInfo: factoryBcnTranslations.inContainersTooltip,
        sortable: true,
        draggable: true,
        resize: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.GONVAUTO_T2,
    value: factoryBcnTranslations.T2,
    headerVisible: true,
    headerSpan: 2,
    isVisibleCheck: true,
    isHeaderExpandable: true,
    isHeaderExpanded: false,
    columnVisibleOnHeaderCollapsed: NameValues.GONVAUTO_TIER2_STOCK,
    columns: [
      {
        uiKey: FactoryBcnColumnKeys.stockT2,
        key: NameValues.GONVAUTO_TIER2_STOCK,
        value: factoryBcnTranslations.stockT2,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: FactoryBcnColumnKeys.stockT2UpdatedAt,
        key: NameValues.GONVAUTO_TIER2_UPDATED_AT,
        value: factoryBcnTranslations.stockT2UpdatedAt,
        sortable: true,
        draggable: true
      }
    ]
  }
];
