import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEntriesInPlantFiltersUseCase } from 'modules/inbound/entries/application/InPlant/GetEntriesInPlantFiltersUseCase';

const getEntriesInPlantFilters = createAsyncThunk(
  'entriesInPlantFilters/getEntriesInPlantFilters',
  async () => {
    const response = await getEntriesInPlantFiltersUseCase.execute();
    return response;
  }
);

export { getEntriesInPlantFilters };
