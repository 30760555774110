import { EntriesBySlotAndUnloadingPointDTO } from '../../infrastructure/dto/UnloadingPointsDTO/entriesBySlotAndUnloadingPointDTOMapper';
import { UnloadingPointEntry } from './UnloadingPointEntry';

export interface EntriesBySlotAndUnloadingPoint {
  entries: UnloadingPointEntry[];
}

export const entriesBySlotAndUnloadingPointMapper = (
  dto: EntriesBySlotAndUnloadingPointDTO
): EntriesBySlotAndUnloadingPoint => {
  return {
    entries: dto.entries?.map((entryDTO) =>
      UnloadingPointEntry.generateFromDTO(entryDTO)
    )
  };
};
