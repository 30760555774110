import { Translation } from 'infrastructure/i18n/typing';
import { SidebarTranslations } from './typing';

export const SidebarTexts: Translation<SidebarTranslations> = {
  es: {
    subtitle: 'Outbound',
    appName: 'Control Tower',
    logout: 'Desconectar'
  },
  en: {
    subtitle: 'Outbound',
    appName: 'Control Tower',
    logout: 'Logout'
  },
  ca: {
    subtitle: 'Outbound',
    appName: 'Control Tower',
    logout: 'Desconnectar'
  }
};
