import { Country } from 'modules/shared/domain/Country';
import countriesService from 'modules/shared/services/CountriesService';

export interface IDeliveriesGeolocation {
  country: Country;
  cumulativeValue: number;
  globalValue: number;
  cumulativePercentage: number;
  globalPercentage: number;
  totalAmount: number;
}
interface GenerateFromDTOParams {
  countryCode: string;
  cumulativeDelay: number;
  globalDelayed: number;
  total: number;
}

class DeliveriesGeolocation implements IDeliveriesGeolocation {
  country: Country;
  cumulativeValue: number;
  globalValue: number;
  cumulativePercentage: number;
  globalPercentage: number;
  totalAmount: number;

  constructor({
    country,
    cumulativePercentage,
    globalPercentage,
    cumulativeValue,
    globalValue,
    totalAmount
  }: DeliveriesGeolocation) {
    this.country = country;
    this.cumulativePercentage = cumulativePercentage;
    this.globalPercentage = globalPercentage;
    this.globalValue = globalValue;
    this.cumulativeValue = cumulativeValue;
    this.totalAmount = totalAmount;
  }

  static getImageSrcFromCountryName(countryName: string) {
    return `${process.env.PUBLIC_URL}/images/flags-circle/${countryName}.svg`;
  }

  static generateFromDTO({
    countryCode,
    cumulativeDelay,
    globalDelayed,
    total
  }: GenerateFromDTOParams): DeliveriesGeolocation {
    return new DeliveriesGeolocation({
      country: {
        name: countriesService.getCountryNameByISOCode(countryCode),
        iso: countryCode,
        flag: {
          src: DeliveriesGeolocation.getImageSrcFromCountryName(countryCode),
          alt: countryCode
        },
        key: countryCode.toUpperCase()
      },
      cumulativePercentage: Math.round((cumulativeDelay / total) * 100),
      globalPercentage: Math.round((globalDelayed / total) * 100),
      cumulativeValue: cumulativeDelay,
      globalValue: globalDelayed,
      totalAmount: total
    });
  }

  static generateEmpty() {
    return new DeliveriesGeolocation({
      country: {
        name: '',
        iso: '',
        flag: {
          src: '',
          alt: ''
        },
        key: ''
      },
      cumulativePercentage: 0,
      globalPercentage: 0,
      globalValue: 0,
      cumulativeValue: 0,
      totalAmount: 0
    });
  }
}

export default DeliveriesGeolocation;
