const STOCK_OBJECTIVES = {
  first: { percentage: 55 },
  second: { percentage: 25 },
  third: { percentage: 15 },
  fourth: { percentage: 5 },
  total: { percentage: 100 }
};
const STOCK_OBJECTIVES_WARNING_COLORS = {
  first: { color: 'white' },
  second: { color: 'yellow' },
  third: { color: 'orange' },
  fourth: { color: 'red' },
  total: { color: 'white' }
};

class PipelineStockBase {
  static readonly stockObjectives = STOCK_OBJECTIVES;
  static readonly stockObjectivesWarningColors =
    STOCK_OBJECTIVES_WARNING_COLORS;
}

export default PipelineStockBase;
