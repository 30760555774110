export interface IIncomingTransfersDTO {
  incoming_material_item: IIncomingTransferItemDTO[];
}

export interface IIncomingTransferItemDTO {
  date: string;
  planned_transfers: number;
  unplanned_transfers: number;
}

class IncomingTransfersDTO implements IIncomingTransfersDTO {
  incoming_material_item: IIncomingTransferItemDTO[];

  constructor({ incoming_material_item }) {
    this.incoming_material_item = incoming_material_item;
  }
}

export default IncomingTransfersDTO;
