import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { MovementsLineDTO } from 'modules/inbound/shared/infraestructure/dto/MovementsLineDTO';

export interface NoJitStockDTO {
  code: string;
  comment: string;
  provider_codes: (string | null)[];
  created_at: string;
  factory_coverage: string;
  name: string;
  stock: number;
  tier_stock: number;
  tier_stock_updated_at: string;
  security_margin: string;
  margin: string;
  lines: string;
  models: string;
  end_production_in_days: number | null;
  provider_names: string[];
  provider_countries: string[];
  provider_ids: string[];
  provider_inbounders: string[];
  last_line_movements: MovementsLineDTO[];
  margin_end_datetime: string;
  factory_coverage_end_datetime: string;
  warehouses: string[];
  transit_coverage: string;
  transit_coverage_end_datetime: string;
}

class NoJitDataDTO {
  planning_dates: string[];
  stock: NoJitStockDTO[];
  update_stock: string;
  update_planning: string;
  pagination: IPaginationDTO;
  constructor({
    planning_dates,
    stock,
    update_stock,
    update_planning,
    pagination
  }) {
    this.planning_dates = planning_dates;
    this.stock = stock;
    this.update_stock = update_stock;
    this.update_planning = update_planning;
    this.pagination = pagination;
  }
}
export default NoJitDataDTO;
