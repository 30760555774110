import { EntriesDataDTO } from '../../infrastructure/dto/NextHoursDTO/entriesDataDTOMapper';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import { Entry, EntryData } from './EntryData';

export interface EntriesData {
  pagination: IPagination;
  entries: Entry[];
}

const entriesDataMapper = (dto: EntriesDataDTO): EntriesData => ({
  entries: dto.entries.map((entry) => EntryData.generateFromDto(entry)),
  pagination: {
    page: dto.pagination.page,
    size: dto.pagination.size,
    pageCount: dto.pagination.page_count,
    totalCount: dto.pagination.total_count
  }
});

export { entriesDataMapper };
