import { CriticalPiecesSummaryDTO } from '../infrastructure/dto/criticalPiecesSummaryDTOMapper';
import { CriticalPiece } from './CriticalPiece';

export interface CriticalPiecesSummary {
  criticalSupplies: CriticalPiece[];
}

const criticalPiecesSummaryMapper = (
  data: CriticalPiecesSummaryDTO
): CriticalPiecesSummary => {
  return {
    criticalSupplies: data?.critical_supplies.map((item) =>
      CriticalPiece.generateFromDTO(item)
    )
  };
};

export { criticalPiecesSummaryMapper };
