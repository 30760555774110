export interface IFinishedGoodCheckpointsTimelineDTO {
  actualTimeline: ITimelineItem[];
  expectedTimeline: ITimelineItem[];
}

export interface ITimelineItem {
  checkpointName: string;
  days: number;
  date: ITimeLineDate;
}

interface ITimeLineDate {
  start: string;
  end: string;
}

class FinishedGoodCheckpointsTimelineDTO {
  actualTimeline: ITimelineItem[];
  expectedTimeline: ITimelineItem[];

  constructor({ actualTimeline, expectedTimeline }) {
    this.actualTimeline = actualTimeline;
    this.expectedTimeline = expectedTimeline;
  }
}

export default FinishedGoodCheckpointsTimelineDTO;
