import LocaleService from 'infrastructure/i18n/LocaleService';
import { StatusItemDTO } from '../infrastructure/dto/SuppliesStatusDTO';
import {
  SearchModality,
  StatsItem,
  SuppliesStatusType
} from '../infrastructure/dto/types';
import { SuppliesText } from '../infrastructure/i18n/SupplyText';
import { SuppliesTranslations } from '../infrastructure/i18n/typing';

const suppliesTranslations: SuppliesTranslations = {
  ...SuppliesText[LocaleService.getLanguage()]
};

export interface StatusItem {
  total: number;
  generatedAt: string;
  toDateTime: string | undefined;
  fromDateTime: string | undefined;
  searchModality: SearchModality;
  stats: StatsItem[];
}

interface GenerateFromDTOParams {
  previous_days: StatusItemDTO[];
  previous_hours: StatusItemDTO[];
  hours: StatusItemDTO[];
}

class SuppliesStatus {
  previousDays: StatusItem;
  hours: StatusItem[];
  total: StatsItem[];

  constructor({ previousDays, hours, total }) {
    this.previousDays = previousDays;
    this.hours = hours;
    this.total = total;
  }

  private static _formatPreviousDays(
    previous_days: StatusItemDTO[]
  ): StatusItem {
    if (previous_days.length) {
      return {
        total: previous_days[0].total,
        generatedAt: suppliesTranslations.previousDays,
        toDateTime: previous_days[0].to_datetime ?? '',
        fromDateTime: previous_days[0].from_datetime ?? '',
        searchModality: previous_days[0].search_modality ?? '',
        stats: previous_days[0].stats ?? []
      };
    }

    return {
      total: 0,
      generatedAt: '',
      toDateTime: '',
      fromDateTime: '',
      searchModality: SearchModality.TO,
      stats: []
    };
  }

  private static _formatHours(hours: StatusItemDTO[]) {
    return hours.map((hour, index) => {
      const formatedGeneratedAt =
        index === 0
          ? suppliesTranslations.previousHours
          : new Date(hour.from_datetime).toLocaleTimeString(['es-ES'], {
              timeStyle: 'short'
            });

      return {
        total: hour.total,
        generatedAt: formatedGeneratedAt ?? '',
        toDateTime: hour.to_datetime,
        fromDateTime: hour.from_datetime,
        searchModality: hour.search_modality,
        stats: hour.stats
      };
    });
  }

  private static _getTotalByStatusType(
    allStatusDTO: StatusItemDTO[],
    type: SuppliesStatusType
  ): number {
    return allStatusDTO.reduce((total, status) => {
      return (
        total +
        status.stats.reduce((subTotal, statItem) => {
          return statItem.status === type
            ? subTotal + statItem.counter
            : subTotal;
        }, 0)
      );
    }, 0);
  }

  private static _getTotal(allStatusDTO: StatusItemDTO[]): StatsItem[] {
    return [
      {
        status: SuppliesStatusType.ON_TIME,
        counter: this._getTotalByStatusType(
          allStatusDTO,
          SuppliesStatusType.ON_TIME
        )
      },
      {
        status: SuppliesStatusType.NEAR_DELAY,
        counter: this._getTotalByStatusType(
          allStatusDTO,
          SuppliesStatusType.NEAR_DELAY
        )
      },
      {
        status: SuppliesStatusType.DELAYED,
        counter: this._getTotalByStatusType(
          allStatusDTO,
          SuppliesStatusType.DELAYED
        )
      }
    ];
  }

  static generateFromDTO({
    previous_days,
    previous_hours,
    hours
  }: GenerateFromDTOParams): SuppliesStatus {
    return new SuppliesStatus({
      previousDays: this._formatPreviousDays(previous_days),
      hours: this._formatHours([...previous_hours, ...hours]),
      total: this._getTotal([...previous_days, ...previous_hours, ...hours])
    });
  }

  static generateEmpty(): SuppliesStatus {
    return new SuppliesStatus({
      previousDays: {
        total: 0,
        generatedAt: '',
        toDateTime: '',
        fromDateTime: '',
        searchModality: SearchModality.TO,
        stats: []
      },
      hours: [],
      total: [
        {
          status: SuppliesStatusType.ON_TIME,
          counter: 0
        },
        {
          status: SuppliesStatusType.NEAR_DELAY,
          counter: 0
        },
        {
          status: SuppliesStatusType.DELAYED,
          counter: 0
        }
      ]
    });
  }
}

export default SuppliesStatus;
