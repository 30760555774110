import { ProviderDTO } from '../infraestructure/dto/AllProvidersDTO';

export interface Provider {
  id: number;
  name: string;
}
interface GenerateFromDTOParams {
  providers: ProviderDTO[];
}
class AllProvidersData {
  providers: Provider[];

  constructor({ providers }: AllProvidersData) {
    this.providers = providers;
  }

  static generateFromDTO({
    providers
  }: GenerateFromDTOParams): AllProvidersData {
    let formatProviders: Provider[] = [];
    if (providers.length) {
      providers.forEach((providerItem) => {
        formatProviders.push({
          id: providerItem.id,
          name: providerItem.name
        });
      });
    }
    return new AllProvidersData({
      providers: formatProviders
    });
  }
}

export default AllProvidersData;
