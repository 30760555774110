enum OccupationState {
  OK = 'OK',
  DANGER = 'DANGER'
}

interface OccupationBrokenDown {
  withoutDestination: number;
  faucoError: number;
  blocked: number;
  expeditable: number;
}
interface ComplexStockOccupationDTO {
  totalCars: number;
  warehouseCapacity: number;
  totalCarsOccupationState: OccupationState;
  brokenDown: OccupationBrokenDown;
}

interface SimpleStockOccupationDTO {
  totalCars: number;
  warehouseCapacity: number;
}

export interface StockOccupationDTO {
  martorell: ComplexStockOccupationDTO;
  darsenaSur: SimpleStockOccupationDTO;
  zalII: SimpleStockOccupationDTO;
}

export const stockOccupationDTOMapper = (data: any): StockOccupationDTO => ({
  martorell: {
    totalCars: data[10008].totalCars,
    warehouseCapacity: data[10008].warehouseCapacity,
    totalCarsOccupationState: data[10008].totalCarsOccupationState,
    brokenDown: data[10008].brokenDown
  },
  darsenaSur: {
    totalCars: data[10005].totalCars,
    warehouseCapacity: data[10005].warehouseCapacity
  },
  zalII: {
    totalCars: data[10006].totalCars,
    warehouseCapacity: data[10006].warehouseCapacity
  }
});
