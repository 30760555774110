import { createAsyncThunk } from '@reduxjs/toolkit';
import GetDirectDeliveryService from 'modules/inbound/directDelivery/application/GetDirectDeliveryService';
import GetCoverageAtRiskService from 'modules/inbound/directDelivery/application/GetCoverageAtRiskService';
import { getPagination, getSelectedColumn } from '.';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import {
  getFilterSearch,
  getSelectedCoverageAtRisk,
  getSelectedFilters
} from '../DirectDeliveryFiltersReducer';
import DownloadDirectDeliveryUseCase from 'modules/inbound/directDelivery/application/DownloadDirectDeliveryUseCase';
import {
  ColumnSelected,
  Sort
} from 'modules/shared/layout/components/TableHeader/types';
import {
  ddFiltersQueryBuilder,
  ddSearchQueryBuilder
} from 'modules/inbound/directDelivery/application/DDQueryStringBuilder';
import { splitStringByAGivenCharacter } from 'modules/inbound/shared/utils/splitStringByAGivenCharacter';

export const getFiltersQuery = (state) => {
  const {
    selectedWarehouses,
    selectedLines,
    selectedProviderIds,
    selectedModels,
    selectedStatus
  } = getSelectedFilters(state);
  const { selectedCoverageAtRisk } = getSelectedCoverageAtRisk(state);

  const filters = {
    [NameValues.WAREHOUSE_ID]: selectedWarehouses.map(
      (warehouse) => warehouse.key
    ),
    [NameValues.LINES_LKDD]: selectedLines.map((line) => line.key),
    [NameValues.PROVIDER_IDS]: selectedProviderIds.map(
      (provider) => provider.key
    ),
    [NameValues.MODELS_LKDD]: selectedModels.map((model) => model.key),
    [NameValues.STATUS]: selectedStatus.key,
    [NameValues.COVERAGE_STATUS]: selectedCoverageAtRisk.status
  };

  return ddFiltersQueryBuilder(filters);
};

export const getSearchQuery = (state) => {
  const searchValue = getFilterSearch(state);

  const search = {
    [NameValues.CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.PROVIDER_CODE]: splitStringByAGivenCharacter(searchValue, '+'),
    [NameValues.DESCRIPTION]: splitStringByAGivenCharacter(searchValue, '+')
  };

  return ddSearchQueryBuilder(search);
};

const getDirectDeliveryData = createAsyncThunk(
  'directDeliveryData/getDirectDeliveryData',
  async (_, { getState }) => {
    const appState = getState();
    const { page, size } = getPagination(appState);
    const selectedColumn: ColumnSelected = getSelectedColumn(appState);

    const sortOrder = selectedColumn.isAscending ? Sort.ASC : Sort.DESC;
    const sort = selectedColumn.key
      ? `{${selectedColumn.key}:${sortOrder}}`
      : `{${NameValues.COVERAGE_IN_SECONDS}:${Sort.ASC}}`;

    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const queryParams = {
      page: page,
      size,
      sort: sort,
      search: queryString && `{${queryString}}`
    };

    const response = await GetDirectDeliveryService.execute({ queryParams });
    return response;
  }
);

const getCoverageAtRisk = createAsyncThunk(
  'directDeliveryData/getCoverageAtRisk',
  async (_, { getState }) => {
    const appState = getState();
    const { selectedWarehouses, selectedProviderIds } =
      getSelectedFilters(appState);

    const queryParams = {
      warehouse_ids: selectedWarehouses.map((item) => item.text).join(';'),
      provider_ids: selectedProviderIds.map((item) => item.text).join(';')
    };
    const response = await GetCoverageAtRiskService.execute({ queryParams });
    return response;
  }
);

const downloadDirectDelivery = createAsyncThunk(
  'directDeliveryData/downloadDirectDelivery',
  async (_, { getState }) => {
    const appState = getState();
    const queryFiltersString = getFiltersQuery(appState);
    const querySearchString = getSearchQuery(appState);

    const queryString: string = [queryFiltersString, querySearchString]
      .filter((item) => item !== '')
      .join('||');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone,
      search: queryString && `{${queryString}}`
    };
    const response = await DownloadDirectDeliveryUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { getDirectDeliveryData, getCoverageAtRisk, downloadDirectDelivery };
