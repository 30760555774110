import localeService from 'infrastructure/i18n/LocaleService';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';
import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

interface GenerateFromDTOParams {
  name: string;
  type: CheckpointType;
  globalDelayed: number;
  checkpointDelayed: number;
  totalFinishedGoods: number;
}

export interface ICheckpoint {
  id: string;
  name: string;
  type: CheckpointType;
  globalDelayed: number;
  checkpointDelayed: number;
  checkpointDelayedPercentage: number;
  globalDelayedPercentage: number;
}

class Checkpoint implements ICheckpoint {
  id: string;
  name: string;
  type: CheckpointType;
  globalDelayed: number;
  checkpointDelayed: number;
  checkpointDelayedPercentage: number;
  globalDelayedPercentage: number;

  constructor({
    id,
    name,
    type,
    globalDelayed,
    checkpointDelayed,
    checkpointDelayedPercentage,
    globalDelayedPercentage
  }: Checkpoint) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.globalDelayed = globalDelayed;
    this.checkpointDelayed = checkpointDelayed;
    this.checkpointDelayedPercentage = checkpointDelayedPercentage;
    this.globalDelayedPercentage = globalDelayedPercentage;
  }

  static generateFromDTO({
    name,
    type,
    globalDelayed,
    checkpointDelayed,
    totalFinishedGoods
  }: GenerateFromDTOParams): Checkpoint {
    return new Checkpoint({
      id: name,
      name: this._getCheckpointNames(name),
      type,
      globalDelayed,
      checkpointDelayed,
      checkpointDelayedPercentage: Math.round(
        (checkpointDelayed / totalFinishedGoods) * 100
      ),
      globalDelayedPercentage: Math.round(
        (globalDelayed / totalFinishedGoods) * 100
      )
    });
  }

  static generateEmpty() {
    return new Checkpoint({
      id: '',
      name: '',
      type: CheckpointType.NONE,
      globalDelayed: 0,
      checkpointDelayed: 0,
      checkpointDelayedPercentage: 0,
      globalDelayedPercentage: 0
    });
  }

  private static _getCheckpointNames(key) {
    const lang = localeService.getLanguage();
    const group = DETAILED_CHECKPOINTS_DICTIONARY.find(
      (group) => group.language === lang
    );
    return group?.checkpoints[key];
  }
}

export default Checkpoint;
