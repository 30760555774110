import { datadogRum } from '@datadog/browser-rum';

const shouldLogWithDD = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'prod';
};

export const initLogger = () => {
  if (shouldLogWithDD()) {
    datadogRum.init({
      applicationId: '0dd3dd07-a563-4713-92ba-d47629181177',
      clientToken: process.env.REACT_APP_DD_TOKEN || '',
      site: 'datadoghq.eu',
      service: 'cdsc-web-app',
      env: process.env.REACT_APP_ENVIRONMENT,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      silentMultipleInit: true,
      defaultPrivacyLevel: 'allow',
      beforeSend: (event) => {
        // remove id_token from view url
        event.view.url = event.view.url.replace(
          /id_token=[^&]*/,
          'id_token=REDACTED'
        );
        // remove access_token from view url
        event.view.url = event.view.url.replace(
          /access_token=[^&]*/,
          'access_token=REDACTED'
        );
      }
    });
    datadogRum.startSessionReplayRecording();
  } else {
    console.log(`DATADOG --> initi logger`); // eslint-disable-line no-console
  }
};

export const setUserToLogger = async (name: string) => {
  if (shouldLogWithDD()) {
    datadogRum.setUser({
      name
    });
  } else {
    console.log(`DATADOG --> set user ${name}`); // eslint-disable-line no-console
  }
};

export const setUserRoleToLogger = (role: string) => {
  if (shouldLogWithDD()) {
    datadogRum.addRumGlobalContext('userData', {
      role
    });
  } else {
    console.log(`DATADOG --> set user role ${role}`); // eslint-disable-line no-console
  }
};
