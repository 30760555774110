import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';

export interface EntriesFilters {
  [key: string]: string[] | string | boolean;
}

const isValid = (value) => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.length > 0)
  );
};

const addHourToDate = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate.toISOString();
};

export const entriesQueryStringBuilder = (filters: EntriesFilters): string => {
  let queryString = '';

  Object.entries(filters).forEach(([name, values]) => {
    if (
      (name === NameValues.URGENT || name === NameValues.HAS_CRITICAL_PIECES) &&
      values === true
    ) {
      return;
    }
    if (!isValid(values)) {
      delete filters[name];
    }
  });

  Object.entries(filters).forEach(([name, values]) => {
    switch (name) {
      case NameValues.WAREHOUSE:
        if (Array.isArray(values)) {
          queryString += values
            .map(
              (value) => `${NameValues.WAREHOUSE}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
        }
        break;
      case NameValues.SUPPLIER_NAME:
        if (Array.isArray(values)) {
          queryString += `${NameValues.SUPPLIER_NAME}${
            SearchOperators.IN
          }(${values.join(';')})`;
        }
        break;
      case NameValues.UNLOADING_POINT:
        if (Array.isArray(values)) {
          queryString += values
            .map(
              (value) =>
                `${NameValues.UNLOADING_POINT}${SearchOperators.LK}${value}`
            )
            .join(SearchOperators.OR);
        }
        break;
      case NameValues.CODE:
        queryString += `${NameValues.CODE}${SearchOperators.LK}${values}`;
        queryString += SearchOperators.OR;
        queryString += `${NameValues.PLATE_NUMBER}${SearchOperators.LK}${values}`;
        break;
      case NameValues.URGENT:
        queryString += `${NameValues.URGENT}${SearchOperators.EQUAL}1`;
        break;
      case NameValues.HAS_CRITICAL_PIECES:
        queryString += `${NameValues.HAS_CRITICAL_PIECES}${SearchOperators.EQUAL}1`;
        break;
      case NameValues.DATE:
        queryString += `${NameValues.WINDOW_BOOKED}${SearchOperators.BIGGER_EQUAL}${values}`;
        queryString += '||';
        queryString += `${NameValues.WINDOW_BOOKED}${
          SearchOperators.SMALLER
        }${addHourToDate(values)}`;
        break;
    }
    queryString += '||';
  });
  return queryString.slice(0, -2);
};
