import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { UpcomingTransitsItemDTO } from '../infrastructure/dto/UpcomingTransitsDTO';

export interface UpcomingTransitItem {
  plateNumber: string;
  deliveryDatetime: string;
  countLabelContainer: number;
}

interface GenerateFromDTOParams {
  data: UpcomingTransitsItemDTO[];
  pagination: IPaginationDTO;
}

class UpcomingTransitsData {
  upcomingTransit: UpcomingTransitItem[];
  pagination: IPagination;

  constructor({ upcomingTransit, pagination }) {
    this.upcomingTransit = upcomingTransit;
    this.pagination = pagination;
  }

  static generateFromDTO({
    data,
    pagination
  }: GenerateFromDTOParams): UpcomingTransitsData {
    let formatData: UpcomingTransitItem[] = [];

    data.forEach((item) => {
      formatData.push({
        plateNumber: item.plate_number,
        deliveryDatetime: GetDateFormatedService.getFullDateTime(
          GetDateFormatedService.dateFromISO8601(item.delivery_datetime)
        ),
        countLabelContainer: item.count_label_container
      });
    });

    return new UpcomingTransitsData({
      upcomingTransit: formatData,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }
}

export default UpcomingTransitsData;
