import { createSlice } from '@reduxjs/toolkit';
import UserInboundData from 'modules/inbound/shared/domain/UserInbound/UserInbound';
import { UserDashboards, UserRole } from 'modules/shared/domain/UserRole';
import { getUserInbound } from './async';
import { UserInboundProps } from './types';
import { InboundRoutes } from 'infrastructure/routes/types';

export const initialState: UserInboundProps = {
  userInboundData: UserInboundData.generateEmpty(),
  requestStatus: '',
  selectedHighlightedRow: '',
  isTableMaximize: false,
  selectedPlanningDate: ''
};

const userInboundDataSlice = createSlice({
  name: 'userInbound',
  initialState,
  reducers: {
    updateSelectedHighlightedRow: (state, action) => {
      state.selectedHighlightedRow = action.payload;
    },
    updateIsTableMaximize: (state, action) => {
      state.isTableMaximize = action.payload;
    },
    updateSelectedPlanningDate: (state, action) => {
      state.selectedPlanningDate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInbound.fulfilled, (state, action) => {
      state.userInboundData = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserInbound.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserInbound.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getUserInboundInfo = (state) =>
  state.userInboundState.userInboundData;

export const hasMaestrosPermission = (state) => {
  return state.userInboundState.userInboundData.dashboards.includes(
    UserDashboards.MAESTROS
  );
};

export const getIsProvider = (state) => {
  const role = state.userInboundState.userInboundData.role;
  return (
    role !== UserRole.INBOUND_SEAT &&
    role !== UserRole.INBOUND_SUPER_ADMIN &&
    role !== UserRole.OUTBOUND
  );
};

export const gethasInboundAdminPermisions = (state) => {
  const role = state.userInboundState.userInboundData.role;
  return (
    role === UserRole.INBOUND_SEAT || role === UserRole.INBOUND_SUPER_ADMIN
  );
};

export const getAvailableFeatures = (state) => {
  return state.userInboundState.userInboundData.features;
};

export const getRedirectingRoute = (state) => {
  const { dashboards } = state.userInboundState.userInboundData;

  if (dashboards.includes(UserDashboards.LANDING)) {
    return InboundRoutes.LANDING;
  }
  if (dashboards.includes(UserDashboards.NOJIT)) {
    return InboundRoutes.NO_JIT;
  }
  if (dashboards.includes(UserDashboards.JIT)) {
    return InboundRoutes.JIT_PART_COVERAGE;
  }
  if (dashboards.includes(UserDashboards.DD)) {
    return InboundRoutes.DIRECT_DELIVERY;
  }
  if (dashboards.includes(UserDashboards.FACTORYBCN)) {
    return InboundRoutes.FACTORY_BCN;
  }
  if (dashboards.includes(UserDashboards.USERS)) {
    return InboundRoutes.USERS;
  }
  if (dashboards.includes(UserDashboards.MAESTROS)) {
    return InboundRoutes.MASTER_CALENDAR;
  }
  if (dashboards.includes(UserDashboards.ENDOFPRODUCTION)) {
    return InboundRoutes.END_OF_PRODUCTION;
  }
  if (dashboards.includes(UserDashboards.ENTRIES)) {
    return InboundRoutes.ENTRIES_NEXT_HOURS;
  }
  if (dashboards.includes(UserDashboards.SUPPLIES)) {
    return InboundRoutes.SUPPLIES;
  }

  return InboundRoutes.HOME;
};

export const getSelectedHighlightedRow = (state) =>
  state.userInboundState.selectedHighlightedRow;
export const getIsTableMaximize = (state) =>
  state.userInboundState.isTableMaximize;
export const getSelectedPlanningDate = (state) =>
  state.userInboundState.selectedPlanningDate;

export const {
  updateSelectedHighlightedRow,
  updateIsTableMaximize,
  updateSelectedPlanningDate
} = userInboundDataSlice.actions;
export default userInboundDataSlice.reducer;
