import { createSlice } from '@reduxjs/toolkit';
import { getUserInboundInfo } from 'modules/inbound/shared/ui/redux/UserInboundReducer';
import CoverageAtRisk from 'modules/shared/domain/CoverageAtRisk';
import { UserRole } from 'modules/shared/domain/UserRole';
import {
  downloadNojit,
  sendComment,
  getNoJitCoverageAtRisk,
  getNoJitData,
  getNoJitPlanningData,
  getNoJitTransportsByPiece
} from './async';
import { BarStatus, NoJitDataState } from './types';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { TEMPORAL_HORIZON_SHORT_TERM } from 'modules/inbound/nojit/domain/NoJitTemporalHorizon';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';

export const initialState: NoJitDataState = {
  data: {
    planningDates: [],
    stock: [],
    targetCodes: [],
    updateStockDate: '',
    updateStockTime: '',
    updatePlanningDate: '',
    updatePlanningTime: '',
    noJitCoverageAtRisk: CoverageAtRisk.generateEmpty()
  },
  columns: [],
  savedColumns: [],
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  requestIdTableDataStatus: '',
  requestNoJitCoverageAtRiskStatus: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  selectedPiece: '',
  selectedPiecePlanningTransports: {
    transportData: {
      loads: [],
      mrps: []
    },
    transportColumns: []
  },
  requestSelectedPiecePlanningStatus: '',
  downloadRequestStatus: '',
  nojitFile: null,
  planning: {
    planning: {},
    KeysContainingTransportsAndMrps: {
      keysWithTransports: [],
      keysWithMrps: []
    }
  },
  requestNoJitPlanningStatus: '',
  requestIdPlanningStatus: '',
  selectedTemporalHorizon: {
    key: TEMPORAL_HORIZON_SHORT_TERM.key,
    text: TEMPORAL_HORIZON_SHORT_TERM.text,
    placeholder: TEMPORAL_HORIZON_SHORT_TERM.placeholder
  },
  selectedLongTermEntry: {
    code: '',
    mrps: [],
    transports: [],
    week: ''
  },
  selectedShortTermEntry: '',
  isTableMaximize: false,
  isEditComment: false,
  editCommentResponse: null,
  editCommentRequestStatus: ''
};

const NOJIT_COLUMNS_VIEW_STATE_KEY = 'NoJitColumnsViewState';

const TOTAL_COLUMNS_PROVIDER = 14;

const noJitDataSlice = createSlice({
  name: 'noJitData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${NOJIT_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    loadColumnsView: (state, action) => {
      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: NOJIT_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateColumnsList: (state, action) => {
      state.savedColumns = action.payload;
    },
    updateSelectedPiece: (state, action) => {
      state.selectedPiece = action.payload;
    },
    updateSelectedTemporalHorizon: (state, action) => {
      state.selectedTemporalHorizon = action.payload;
    },
    updateSelectedLongTermEntry: (state, action) => {
      state.selectedLongTermEntry = action.payload;
    },
    updateSelectedShortTermEntry: (state, action) => {
      state.selectedShortTermEntry = action.payload;
    },
    updateIsTableMaximize: (state) => {
      state.isTableMaximize = !state.isTableMaximize;
    },
    updateComment: (state, action) => {
      state.data.stock = state.data.stock.map((item) =>
        item.code === action.payload.code
          ? { ...item, comment: action.payload.comment }
          : item
      );
    },
    updateIsEditComment: (state, action) => {
      state.isEditComment = action.payload;
    },
    resetUpdateCommentStatus: (state) => {
      state.editCommentRequestStatus = initialState.editCommentRequestStatus;
      state.editCommentResponse = initialState.editCommentResponse;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getNoJitData.fulfilled, (state, action) => {
      if (action.meta.requestId === state.requestIdTableDataStatus) {
        state.data.planningDates = action.payload.planningDates;
        state.data.stock = action.payload.stock;
        state.data.targetCodes = action.payload.targetCodes;
        state.data.updateStockDate = action.payload.updateStockDate;
        state.data.updateStockTime = action.payload.updateStockTime;
        state.data.updatePlanningDate = action.payload.updatePlanningDate;
        state.data.updatePlanningTime = action.payload.updatePlanningTime;
        state.pagination = action.payload.pagination;
        state.columns = action.payload.columns;
        state.requestStatus = action.meta.requestStatus;
        state.requestNoJitPlanningStatus =
          initialState.requestNoJitPlanningStatus;
      }
    });
    builder.addCase(getNoJitData.pending, (state, action) => {
      state.requestIdTableDataStatus = action.meta.requestId;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitData.rejected, (state, action) => {
      if (action.meta.requestId === state.requestIdTableDataStatus) {
        state.requestStatus = action.meta.requestStatus;
      }
    });
    builder.addCase(getNoJitCoverageAtRisk.fulfilled, (state, action) => {
      state.data.noJitCoverageAtRisk = action.payload;
      state.requestNoJitCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitCoverageAtRisk.pending, (state, action) => {
      state.requestNoJitCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitCoverageAtRisk.rejected, (state, action) => {
      state.requestNoJitCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitTransportsByPiece.fulfilled, (state, action) => {
      state.selectedPiecePlanningTransports = action.payload;
      state.requestSelectedPiecePlanningStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitTransportsByPiece.pending, (state, action) => {
      state.requestSelectedPiecePlanningStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitTransportsByPiece.rejected, (state, action) => {
      state.requestSelectedPiecePlanningStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadNojit.fulfilled, (state, action) => {
      state.nojitFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_Nojit_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadNojit.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadNojit.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getNoJitPlanningData.fulfilled, (state, action) => {
      if (action.meta.requestId === state.requestIdPlanningStatus) {
        state.planning = action.payload;
        state.requestNoJitPlanningStatus = action.meta.requestStatus;
        state.data.stock = [
          ...state.data.stock.map((item) => ({
            ...item,
            pastLoads: action.payload.planning[item.code].pastLoads.length
              ? action.payload.planning[item.code].pastLoads
              : ['-']
          }))
        ];
      }
    });
    builder.addCase(getNoJitPlanningData.pending, (state, action) => {
      state.requestNoJitPlanningStatus = action.meta.requestStatus;
      state.requestIdPlanningStatus = action.meta.requestId;
    });
    builder.addCase(getNoJitPlanningData.rejected, (state, action) => {
      if (action.meta.requestId === state.requestIdPlanningStatus) {
        state.requestNoJitPlanningStatus = action.meta.requestStatus;
      }
    });
    builder.addCase(sendComment.fulfilled, (state, action) => {
      state.editCommentRequestStatus = action.meta.requestStatus;
      state.editCommentResponse = action.payload;
    });
    builder.addCase(sendComment.pending, (state, action) => {
      state.editCommentRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(sendComment.rejected, (state, action) => {
      state.editCommentRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getNojitData = (state) => {
  return state.noJitDataState.data;
};
export const getColumns = (state) => state.noJitDataState.savedColumns;
export const getNoJitCoverageAtRiskTotalUI = (state) => {
  const totalAtRisk = state.noJitDataState.data.noJitCoverageAtRisk.totalRisk;
  return [
    {
      xAxisLabels: {
        top: totalAtRisk.name,
        bottom: totalAtRisk.total
      },
      stackedBars: totalAtRisk.stats?.map((item) => {
        return { number: item.counter, color: BarStatus[item.status] };
      })
    }
  ];
};
export const getCheckListColumns = (state) => {
  const columns = getColumns(state);
  const { role } = getUserInboundInfo(state);
  if (columns.length) {
    if (
      role === UserRole.INBOUND_SEAT ||
      role === UserRole.INBOUND_SUPER_ADMIN
    ) {
      return columns;
    }
    const providerColumns = columns
      .filter((item) => item.key !== NameValues.COMMENT)
      .slice(0, TOTAL_COLUMNS_PROVIDER);
    return providerColumns;
  }
  return [];
};
export const getNoJitCoverageAtRiskTotalScale = (state) => {
  const maxValue =
    Math.ceil(
      state.noJitDataState.data.noJitCoverageAtRisk.totalRisk.total / 7
    ) * 7;
  return {
    totalMaxValue: maxValue,
    totalScale: Array(...Array(8)).map((_, i) => (i * maxValue) / 7)
  };
};
export const getNoJitCoverageAtRiskModelsUI = (state) => {
  const modelsAtRisk =
    state.noJitDataState.data.noJitCoverageAtRisk?.modelsRisk?.filter(
      (item) => item.total > 0
    );
  return modelsAtRisk?.map((item) => {
    return {
      xAxisLabels: {
        top: item.name,
        bottom: item.total
      },
      stackedBars: item.stats.map((item) => {
        return { number: item.counter, color: BarStatus[item.status] };
      })
    };
  });
};
export const getNoJitCoverageAtRiskModelsScale = (state) => {
  const models = state.noJitDataState.data.noJitCoverageAtRisk.modelsRisk;
  const maxValue = models
    ? Math.ceil(Math.max(...models.map(({ total }) => total)) / 7) * 7
    : 0;
  return {
    modelsMaxValue: maxValue,
    modelsScale: Array(...Array(8)).map((_, i) => (i * maxValue) / 7)
  };
};
export const getNoJitDataIsFulfilled = (state) =>
  state.noJitDataState.requestStatus === 'fulfilled';
export const getisLoadingStatus = (state) =>
  state.noJitDataState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.noJitDataState.requestStatus === 'rejected';
export const getisLoadingNoJitCoverageAtRisk = (state) =>
  state.noJitDataState.requestNoJitCoverageAtRiskStatus === 'pending';
export const getHasErrorNoJitCoverageAtRisk = (state) =>
  state.noJitDataState.requestNoJitCoverageAtRiskStatus === 'rejected';
export const getPagination = (state) => state.noJitDataState.pagination;
export const getSelectedColumn = (state) => state.noJitDataState.selectedColumn;
export const getSelectedPiece = (state) => state.noJitDataState.selectedPiece;
export const getTransportsByPiece = (state) =>
  state.noJitDataState.selectedPiecePlanningTransports;
export const getUpdateStockDate = (state) =>
  state.noJitDataState.data.updateStockDate;
export const getUpdateStockTime = (state) =>
  state.noJitDataState.data.updateStockTime;
export const getUpdatePlanningDate = (state) =>
  state.noJitDataState.data.updatePlanningDate;
export const getUpdatePlanningTime = (state) =>
  state.noJitDataState.data.updatePlanningTime;
export const getNoJitTargetCodes = (state) =>
  state.noJitDataState.data.targetCodes;
export const getDownloadNojitIsPending = (state) =>
  state.noJitDataState.downloadRequestStatus === 'pending';
export const getDownloadNojitSuccessful = (state) =>
  state.noJitDataState.downloadRequestStatus === 'fulfilled' &&
  state.noJitDataState.nojitFile !== null;
export const getDownloadNojitError = (state) =>
  state.noJitDataState.downloadRequestStatus === 'rejected';
export const getNoJitPlanningIsFulfilled = (state) =>
  state.noJitDataState.requestNoJitPlanningStatus === 'fulfilled';
export const getNoJitPlanning = (state) =>
  state.noJitDataState.planning.planning;
export const getKeysWithPlanning = (state) =>
  state.noJitDataState.planning.KeysContainingTransportsAndMrps;
export const getSelectedTemporalHorizon = (state) =>
  state.noJitDataState.selectedTemporalHorizon;
export const getSelectedLongTermEntry = (state) =>
  state.noJitDataState.selectedLongTermEntry;
export const getSelectedShortTermEntry = (state) =>
  state.noJitDataState.selectedShortTermEntry;
export const getIsTableMaximize = (state) =>
  state.noJitDataState.isTableMaximize;
export const getEditCommentSuccess = (state) =>
  state.noJitDataState.editCommentRequestStatus === 'fulfilled' &&
  state.noJitDataState.editCommentResponse !== null;
export const getEditCommentFailed = (state) =>
  (state.noJitDataState.editCommentRequestStatus === 'fulfilled' &&
    state.noJitDataState.editCommentResponse === null) ||
  state.noJitDataState.editCommentRequestStatus === 'rejected';
export const getIsEditComment = (state) => state.noJitDataState.isEditComment;

//actions
export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateSelectedPiece,
  updateSelectedLongTermEntry,
  updateSelectedShortTermEntry,
  resetDownloadStatus,
  updateVisibleColumns,
  updateColumnsList,
  updateSelectedTemporalHorizon,
  saveColumnsView,
  loadColumnsView,
  updateIsTableMaximize,
  updateIsEditComment,
  updateComment,
  resetUpdateCommentStatus
} = noJitDataSlice.actions;

export default noJitDataSlice.reducer;
