import { createSlice } from '@reduxjs/toolkit';
import { PlanningColumnsKeys } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import { downloadFactoryBCN, getFactoryBcnData } from './async';
import { FactoryBcnState } from './types';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { FACTORY_BCN_COLUMNS } from 'modules/inbound/factoryBcn/domain/FactoryBcnColumns';

export const initialState: FactoryBcnState = {
  bodyworkNeedsUpdatedAt: '',
  stockMrtUpdatedAt: '',
  stockBcnUpdatedAt: '',
  bodyworkPieces: [],
  planningDates: [],
  columns: FACTORY_BCN_COLUMNS,
  planningColumns: [],
  savedColumns: [],
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: '',
    isAscending: false
  },
  requestStatus: '',
  selectedDays: 1,
  downloadRequestStatus: '',
  factoryBCNFile: null
};

const FACTORY_BCN_COLUMNS_VIEW_STATE_KEY = 'FactoryBcnColumnsView';
const FACTORY_BCN_NEEDS_DAYS_NUMBER = 'FactoryBcnNumberOfNeedsDays';

const factoryBcnDataSlice = createSlice({
  name: 'factoryBcn',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateExpandedColumn: (state, action) => {
      state.columns = state.columns.map((itemHeader) =>
        itemHeader.columns?.some((column) => column.key === action.payload)
          ? { ...itemHeader, isHeaderExpanded: true }
          : { ...itemHeader, isHeaderExpanded: false }
      );
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${FACTORY_BCN_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
      localStorage.setItem(
        `${action.payload}-${FACTORY_BCN_NEEDS_DAYS_NUMBER}`,
        JSON.stringify(state.selectedDays)
      );
    },
    loadColumnsView: (state, action) => {
      state.selectedDays = getLocalStorage<number>(
        `${action.payload}-${FACTORY_BCN_NEEDS_DAYS_NUMBER}`,
        1
      );

      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: FACTORY_BCN_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsList: (state, action) => {
      state.savedColumns = action.payload;
    },
    updateSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFactoryBcnData.fulfilled, (state, action) => {
      state.bodyworkNeedsUpdatedAt = action.payload.bodyworkNeedsUpdatedAt;
      state.stockMrtUpdatedAt = action.payload.stockMrtUpdatedAt;
      state.stockBcnUpdatedAt = action.payload.stockBcnUpdatedAt;
      state.bodyworkPieces = action.payload.bodyworkPieces;
      state.planningDates = action.payload.planningDates;
      state.planningColumns = action.payload.planningColumns;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryBcnData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryBcnData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadFactoryBCN.fulfilled, (state, action) => {
      state.factoryBCNFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'P_FactoryBCN'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadFactoryBCN.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadFactoryBCN.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getSelectedDays = (state) => state.factoryBcnState.selectedDays;
export const getFactorybcnData = (state) =>
  state.factoryBcnState.bodyworkPieces;
export const getUpdatedDates = (state) => {
  return {
    bodyworkNeedsUpdatedAt: state.factoryBcnState.bodyworkNeedsUpdatedAt,
    stockMrtUpdatedAt: state.factoryBcnState.stockMrtUpdatedAt,
    stockBcnUpdatedAt: state.factoryBcnState.stockBcnUpdatedAt
  };
};
export const getPlanningDates = (state) => state.factoryBcnState.planningDates;
export const getColumns = (state) => state.factoryBcnState.savedColumns;
export const getPlanningColumns = (state) =>
  state.factoryBcnState.planningColumns;

export const getCheckListColumns = (state) => {
  const columns = getColumns(state);
  if (columns.length) {
    const optionalColumns = columns.filter((column) => {
      if (
        column.key !== NameValues.CODE &&
        column.key !== PlanningColumnsKeys.alwaysVisible &&
        column.key !== PlanningColumnsKeys.optionVisible
      ) {
        return column;
      }
    });

    return optionalColumns;
  }
  return [];
};
export const getVisiblePlanningDates = (state) => {
  const planningDates = getPlanningDates(state);
  const selectedPlanningDays = getSelectedDays(state);
  if (selectedPlanningDays > 1) {
    return planningDates.slice(0, selectedPlanningDays);
  }
  return planningDates.slice(0, 1);
};
export const getFactoryBcnDataPending = (state) =>
  state.factoryBcnState.requestStatus === 'pending';
export const getFactoryBcnDataHasError = (state) =>
  state.factoryBcnState.requestStatus === 'rejected';
export const getCurrentPage = (state) => state.factoryBcnState.pagination.page;
export const getResultsPerPage = (state) =>
  state.factoryBcnState.pagination.size;
export const getTotalPages = (state) =>
  state.factoryBcnState.pagination.pageCount;
export const getTotalCount = (state) =>
  state.factoryBcnState.pagination.totalCount;
export const getSelectedColumn = (state) =>
  state.factoryBcnState.selectedColumn;
export const getDownloadFactoryBCNSuccessful = (state) =>
  state.factoryBcnState.downloadRequestStatus === 'fulfilled' &&
  state.factoryBcnState.factoryBCNFile !== null;
export const getDownloadFactoryBCNError = (state) =>
  state.factoryBcnState.downloadRequestStatus === 'rejected';
export const getDownloadFactoryBCNIsPending = (state) =>
  state.factoryBcnState.downloadRequestStatus === 'pending';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateExpandedColumn,
  resetDownloadStatus,
  updateSelectedDays,
  updateVisibleColumns,
  updateColumnsList,
  saveColumnsView,
  loadColumnsView
} = factoryBcnDataSlice.actions;

export default factoryBcnDataSlice.reducer;
