export interface DeliveryStatusesDTO {
  others: {
    no_entries: number;
  };
  quantity: {
    complete: number;
    incomplete: number;
    leftover: number;
  };
  punctuality: {
    risk_of_delay: number;
    on_time: number;
    risk_of_overtake: number;
  };
}

export const deliveryStatusesDTOMapper = (data: any): DeliveryStatusesDTO => {
  return {
    others: {
      no_entries: data.others?.no_entries ?? 0
    },
    quantity: {
      complete: data.quantity?.complete ?? 0,
      incomplete: data.quantity?.incomplete ?? 0,
      leftover: data.quantity?.leftover ?? 0
    },
    punctuality: {
      risk_of_delay: data.punctuality?.risk_of_delay ?? 0,
      on_time: data.punctuality?.on_time ?? 0,
      risk_of_overtake: data.punctuality?.risk_of_overtake ?? 0
    }
  };
};
