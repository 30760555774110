import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { TableColumnProps } from 'modules/inbound/shared/ui/layout/components/Table/types';
import { NoJitTranslations } from '../infrastructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { NoJitText } from '../infrastructure/i18n/NoJitText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';

const noJitTranslations: NoJitTranslations & InboundTranslations = {
  ...NoJitText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

const TransportsDetailColumnKeys = {
  type: 'type',
  status: 'status',
  amount: 'amount',
  departureDate: 'departureDate',
  leadtime: 'leadtime',
  providerDeliveryNote: 'providerDeliveryNote',
  providerDeliveryTime: 'providerDeliveryTime',
  officialDeliveryTime: 'officialDeliveryTime',
  dateType: 'dateType',
  transporter: 'transporter',
  plateNumber: 'plateNumber'
} as const;

type TColumnKeys =
  (typeof TransportsDetailColumnKeys)[keyof typeof TransportsDetailColumnKeys];

interface TransportDetailsColumns extends TableColumnProps {
  uiKey: TColumnKeys;
}

export const TRANSPORT_DETAILS_COLUMNS: TransportDetailsColumns[] = [
  {
    key: NameValues.TYPE,
    uiKey: 'type',
    value: noJitTranslations.type,
    sortable: false,
    isVisible: true
  },
  {
    key: NameValues.STATUS,
    uiKey: 'status',
    value: noJitTranslations.status,
    sortable: false,
    isVisible: true,
    tooltipInfo: noJitTranslations.statusTooltip
  },
  {
    key: NameValues.AMOUNT,
    uiKey: 'amount',
    value: noJitTranslations.amount,
    sortable: false,
    isVisible: true,
    align: 'right'
  },
  {
    key: NameValues.DEPARTURE_DATE,
    uiKey: 'departureDate',
    value: noJitTranslations.departureDate,
    sortable: false,
    isVisible: true,
    tooltipInfo: noJitTranslations.departureDateTooltip
  },
  {
    key: NameValues.LEADTIME,
    uiKey: 'leadtime',
    value: noJitTranslations.leadtime,
    sortable: false,
    isVisible: true,
    align: 'right',
    tooltipInfo: noJitTranslations.leadtimeTooltip
  },
  {
    key: NameValues.PROVIDER_DELIVERY_NOTE,
    uiKey: 'providerDeliveryNote',
    value: noJitTranslations.providerDeliveryNote,
    sortable: false,
    isVisible: true,
    hasCopyIcon: true
  },
  {
    key: NameValues.SUPPLIER_DATE,
    uiKey: 'providerDeliveryTime',
    value: noJitTranslations.provDeliveryTime,
    sortable: false,
    isVisible: true,
    tooltipInfo: noJitTranslations.provDeliveryTimeTooltip
  },
  {
    key: NameValues.OFFICIAL_DATE,
    uiKey: 'officialDeliveryTime',
    value: noJitTranslations.officialDeliveryTime,
    sortable: true,
    isVisible: true,
    tooltipInfo: noJitTranslations.officialDeliveryTimeTooltip
  },
  {
    key: NameValues.DATE_TYPE,
    uiKey: 'dateType',
    value: noJitTranslations.dateType,
    sortable: false,
    isVisible: true,
    tooltipInfo: noJitTranslations.dateTypeTooltip
  },
  {
    key: NameValues.TRANSPORTER,
    uiKey: 'transporter',
    value: noJitTranslations.transporter,
    sortable: false,
    isVisible: true
  },
  {
    key: NameValues.PLATE_NUMBER,
    uiKey: 'plateNumber',
    value: noJitTranslations.plateNumber,
    sortable: false,
    isVisible: true
  }
];
