import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { EndOfStockSummaryType } from '../../domain/EndOfProductionSummary';

export interface IEndOfProductionDateStockDTO {
  end_of_stock: EndOfProductionDateStockDTO[];
  pagination: IPaginationDTO;
}

interface ProviderDeliveryNoteDTO {
  provider_delivery_note: string;
  date: string;
}
export interface EndOfProductionDateStockDTO {
  amount_ordered: number;
  blocked_material: number | null;
  code: string;
  consumption: number;
  cost: number | null;
  description: string;
  end_of_consumption_date: string | null;
  end_of_stock_types: EndOfStockSummaryType[];
  expiration_date: string;
  last_entry: ProviderDeliveryNoteDTO | null;
  last_order_date: string | null;
  leftover_stock: number;
  leftover_stock_cost: number | null;
  material_in_transit: number | null;
  models: string | null;
  percentage_asignements: string[] | null;
  provider_ids: string[] | null;
  provider_inbounders: string[] | null;
  provider_names: string[] | null;
  status: string[];
  stock: number;
  stock_volume: number;
  stuckliste_models: string[];
  termins: string[];
  warehouse_codes: string[];
}

class EndOfProductionDateDataDTO {
  end_of_stock: EndOfProductionDateStockDTO[];
  pagination: IPaginationDTO;

  constructor({ end_of_stock, pagination }) {
    this.end_of_stock = end_of_stock;
    this.pagination = pagination;
  }
}

export default EndOfProductionDateDataDTO;
