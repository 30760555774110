import { createSlice } from '@reduxjs/toolkit';
import { BarStatus, RefillsDataState } from './types';
import {
  getRefillsAlerts,
  getRefillsData,
  getRefillsSaturation,
  getRefillsStatus
} from './async';
import { RefillsTranslations } from '../../../infrastructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { RefillsText } from '../../../infrastructure/i18n/RefillsText';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';

const refillsTranslations: RefillsTranslations = {
  ...RefillsText[LocaleService.getLanguage()]
};

export const initialState: RefillsDataState = {
  data: {
    refills: [],
    refillsStatus: [],
    refillsAlerts: {
      transfer: {
        total: 0,
        stats: []
      },
      directToLine: {
        total: 0,
        stats: []
      }
    },
    refillsSaturation: {
      status: 'red',
      percentage: 1
    },
    columns: [],
    savedColumns: [],
    lastUpdateDate: '-'
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  refillsStatusRequestStatus: '',
  refillsAlertsStatus: '',
  refillsSaturationRequestStatus: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  filters: {
    search: '',
    selectedAlert: {
      status: '',
      type: ''
    },
    selectedStatus: {
      generatedAt: '',
      fromDateTime: '',
      toDateTime: '',
      searchModality: undefined,
      status: ''
    }
  }
};
const REFILLS_COLUMNS_VIEW_STATE_KEY = 'RefillsColumnsView';
const refillsDataSlice = createSlice({
  name: 'refillsData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateFilterSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    updateSelectedAlert: (state, action) => {
      state.filters.selectedAlert.status = action.payload.status;
      state.filters.selectedAlert.type = action.payload.type;
      state.pagination.page = initialState.pagination.page;
      state.filters.selectedStatus = initialState.filters.selectedStatus;
    },
    updateSelectedStatus: (state, action) => {
      state.filters.selectedStatus = action.payload;
      state.pagination.page = initialState.pagination.page;
      state.filters.selectedAlert = initialState.filters.selectedAlert;
    },
    resetSelectedAlerts: (state) => {
      state.filters.selectedAlert = initialState.filters.selectedAlert;
      state.pagination.page = initialState.pagination.page;
    },
    resetSelectedStatus: (state) => {
      state.filters.selectedStatus = initialState.filters.selectedStatus;
      state.pagination.page = initialState.pagination.page;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${REFILLS_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.data.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.data.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: REFILLS_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.data.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.data.savedColumns = state.data.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsOrder: (state, action) => {
      state.data.savedColumns = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRefillsData.fulfilled, (state, action) => {
      state.data.refills = action.payload.refills;
      state.data.columns = action.payload.columns || [];
      state.data.lastUpdateDate = action.payload.lastUpdate;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsStatus.fulfilled, (state, action) => {
      state.data.refillsStatus = action.payload.refillsStatuses;
      state.refillsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsStatus.pending, (state, action) => {
      state.refillsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsStatus.rejected, (state, action) => {
      state.refillsStatusRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsAlerts.fulfilled, (state, action) => {
      state.data.refillsAlerts = action.payload.alerts;
      state.refillsAlertsStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsAlerts.pending, (state, action) => {
      state.refillsAlertsStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsAlerts.rejected, (state, action) => {
      state.refillsAlertsStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsSaturation.fulfilled, (state, action) => {
      state.data.refillsSaturation = action.payload.saturation;
      state.refillsSaturationRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsSaturation.pending, (state, action) => {
      state.refillsSaturationRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getRefillsSaturation.rejected, (state, action) => {
      state.refillsSaturationRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getRefillDataState = (state) =>
  state.refillsDataState.data.refills;
export const getRefillsTotalCount = (state) =>
  state.refillsDataState.pagination.totalCount;
export const getRefillStatusData = (state) => {
  return state.refillsDataState.data.refillsStatus.map((item) => {
    return {
      fromDateTime: item.fromDateTime,
      toDateTime: item.toDateTime,
      searchModality: item.searchModality,
      xAxisLabels: {
        top: item.generatedAt,
        bottom: item.total
      },
      stackedBars: item.stats.map((statusItem) => {
        return {
          number: statusItem.counter,
          color: BarStatus[statusItem.status]
        };
      })
    };
  });
};
export const getRefillAlertsData = (state) => {
  const chartData = state.refillsDataState.data.refillsAlerts;
  return (
    Object.keys(chartData).map((key) => {
      return {
        type: key,
        xAxisLabels: {
          top: refillsTranslations[key],
          bottom: chartData[key].total
        },
        stackedBars: chartData[key].stats.map((item) => {
          return { number: item.counter, color: BarStatus[item.status] };
        })
      };
    }) || []
  );
};
export const getisLoading = (state) =>
  state.refillsDataState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.refillsDataState.requestStatus === 'rejected';
export const getisLoadingRefillsStatus = (state) =>
  state.refillsDataState.refillsStatusRequestStatus === 'pending';
export const getHasErrorRefillsStatus = (state) =>
  state.refillsDataState.refillsStatusRequestStatus === 'rejected';
export const getisLoadingAlertsStatus = (state) =>
  state.refillsDataState.refillsAlertsStatus === 'pending';
export const getHasErrorAlertsStatus = (state) =>
  state.refillsDataState.refillsAlertsStatus === 'rejected';
export const getRefillSaturationData = (state) =>
  state.refillsDataState.data.refillsSaturation;
export const getisLoadingSaturationStatus = (state) =>
  state.refillsDataState.refillsSaturationRequestStatus === 'pending';
export const getHasErrorSaturationStatus = (state) =>
  state.refillsDataState.refillsSaturationRequestStatus === 'rejected';
export const getCurrentPage = (state) => state.refillsDataState.pagination.page;
export const getResultsPerPage = (state) =>
  state.refillsDataState.pagination.size;
export const getTotalPages = (state) =>
  state.refillsDataState.pagination.pageCount;
export const getSelectedColumn = (state) =>
  state.refillsDataState.selectedColumn;
export const getSelectedAlert = (state) =>
  state.refillsDataState.filters.selectedAlert;
export const getSelectedAlertUI = (state) => {
  return {
    top: refillsTranslations[state.refillsDataState.filters.selectedAlert.type],
    color: BarStatus[state.refillsDataState.filters.selectedAlert.status]
  };
};
export const getSelectedStatus = (state) =>
  state.refillsDataState.filters.selectedStatus;
export const getSelectedStatusUI = (state) => {
  return {
    top: state.refillsDataState.filters.selectedStatus.generatedAt,
    color: BarStatus[state.refillsDataState.filters.selectedStatus.status]
  };
};
export const getFilterSearch = (state) => state.refillsDataState.filters.search;
export const getAreFiltersActive = (state) =>
  state.refillsDataState.filtersActive;
export const getLastUpdateDate = (state) =>
  state.refillsDataState.data.lastUpdateDate;
export const getColumns = (state) => state.refillsDataState.data.savedColumns;

export default refillsDataSlice.reducer;

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateFilterSearch,
  updateSelectedAlert,
  updateSelectedStatus,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  updateColumnsOrder,
  resetSelectedAlerts,
  resetSelectedStatus
} = refillsDataSlice.actions;
