import { UserRole } from 'modules/shared/domain/UserRole';
import UserInboundDTO from '../../infraestructure/dto/UserInboundDTO';

export interface UserInbound {
  username: string;
  providers: string[];
  role: UserRole;
  dashboards: number[];
  features: string[];
}

class UserInboundData {
  username: string;
  providers: string[];
  role: UserRole;
  dashboards: number[];
  features: string[];

  constructor({
    username,
    providers,
    role,
    dashboards,
    features
  }: UserInbound) {
    this.username = username;
    this.providers = providers;
    this.role = role;
    this.dashboards = dashboards;
    this.features = features;
  }
  static generateFromDTO({
    username,
    providers,
    role,
    dashboards,
    features
  }: UserInboundDTO): UserInbound {
    return new UserInboundData({
      username: username ?? '',
      providers: providers ?? [],
      dashboards: dashboards ?? [],
      role:
        role === UserRole.INBOUND_PROVIDER_NOT_JIT ||
        role === UserRole.INBOUND_PROVIDER_DD ||
        role === UserRole.INBOUND_PROVIDER_JIT
          ? UserRole.INBOUND_PROVIDER
          : role ?? UserRole.NONE,
      features: features ?? []
    });
  }

  static generateEmpty() {
    return new UserInboundData({
      username: '',
      providers: [],
      role: UserRole.NONE,
      dashboards: [],
      features: []
    });
  }
}

export default UserInboundData;
