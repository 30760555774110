class LastUpdateDate {
  lastUpdate: string;

  constructor({ lastUpdate }) {
    this.lastUpdate = lastUpdate;
  }

  private static _formatDateFromUTC(utc): string {
    if (utc) {
      const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      };

      const formatedDate = new Date(utc).toLocaleString('de-DE', options);

      return formatedDate.replace(',', ' |');
    }

    return '-';
  }

  static generateFromDTO({
    last_update
  }: {
    last_update: string | null;
  }): LastUpdateDate {
    return new LastUpdateDate({
      lastUpdate: this._formatDateFromUTC(last_update)
    });
  }
}

export { LastUpdateDate };
