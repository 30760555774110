import IProvidersRepository from '../../domain/AllProviders/IAllProviders';
import AllProvidersData from '../../domain/AllProvidersData';
import ProvidersRepository from '../../infraestructure/repositories/ProvidersRepository';

class GetAllProvidersUseCase {
  private readonly _ProvidersRepository: IProvidersRepository;

  constructor({ repository }) {
    this._ProvidersRepository = repository;
  }

  execute() {
    return this._ProvidersRepository
      .getAllProviders()
      .then((res) => AllProvidersData.generateFromDTO(res));
  }
}

export default new GetAllProvidersUseCase({
  repository: ProvidersRepository
});

export { GetAllProvidersUseCase };
