import { createAsyncThunk } from '@reduxjs/toolkit';
import getExpirationFiltersUseCase from 'modules/inbound/expiration/application/GetExpirationFiltersUseCase';

export const getExpirationFilters = createAsyncThunk(
  'expirationFiltersData/getExpirationFilters',
  async () => {
    const response = await getExpirationFiltersUseCase.execute();
    return response;
  }
);
