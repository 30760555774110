import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { UnloadingPointStateType } from '../../infrastructure/dto/types';
import {
  MORNING,
  AFTERNOON,
  NIGHT,
  Shift,
  UnloadingPointTimeSlotDTO,
  UnloadingPointDTO,
  UnloadingMetrics
} from '../../infrastructure/dto/UnloadingPointsDTO/UnloadingPointDTO';

type EntryType = keyof Pick<
  UnloadingPointTimeSlotDTO,
  'containers' | 'entries'
>;

interface SlotState {
  state: UnloadingPointStateType;
  value: number;
}

interface UnloadingPointTimeSlot {
  hour: string;
  date: string;
  isLastUpdate: boolean;
  slotsState: SlotState[][];
  isUrgent: boolean;
  isCritical: boolean;
}

export interface SaturationDataByShift {
  shift: Shift;
  slots: UnloadingPointTimeSlot[];
}

type SlotsGroupedByShift = {
  [MORNING]: UnloadingPointTimeSlotDTO[];
  [AFTERNOON]: UnloadingPointTimeSlotDTO[];
  [NIGHT]: UnloadingPointTimeSlotDTO[];
};

class UnloadingPoint {
  entriesMaxValue: number;
  containersMaxValue: number;
  unloadingPoint: string;
  entriesDataByShift: SaturationDataByShift[];
  containersDataByShift: SaturationDataByShift[];
  containersUnloadingMetrics: UnloadingMetrics;
  entriesUnloadingMetrics: UnloadingMetrics;

  constructor({
    entriesMaxValue,
    containersMaxValue,
    unloadingPoint,
    entriesDataByShift,
    containersDataByShift,
    containersUnloadingMetrics,
    entriesUnloadingMetrics
  }) {
    this.entriesMaxValue = entriesMaxValue;
    this.containersMaxValue = containersMaxValue;
    this.unloadingPoint = unloadingPoint;
    this.entriesDataByShift = entriesDataByShift;
    this.containersDataByShift = containersDataByShift;
    this.containersUnloadingMetrics = containersUnloadingMetrics;
    this.entriesUnloadingMetrics = entriesUnloadingMetrics;
  }

  private static _getMaxValueByEntryType(
    slots: UnloadingPointTimeSlotDTO[],
    entryType: EntryType
  ) {
    const maxPlannedNumber = slots.reduce(
      (acc, cur) =>
        Math.max(acc, cur[entryType].planned + cur[entryType].in_plant),
      0
    );

    const maxUnloadingAndExecutedAtATime = slots.reduce(
      (acc, cur) =>
        Math.max(acc, cur[entryType].unloading + cur[entryType].executed),
      0
    );

    return Math.max(maxPlannedNumber, maxUnloadingAndExecutedAtATime);
  }

  private static _formatDtoSlot(
    slot: UnloadingPointTimeSlotDTO,
    entryType: EntryType
  ): UnloadingPointTimeSlot {
    const { hour, is_last_entries_update_slot, is_critical, is_urgent } = slot;

    return {
      hour: GetDateFormatedService.getTimeFormated(hour),
      date: hour,
      isLastUpdate: is_last_entries_update_slot,
      isUrgent: is_urgent,
      isCritical: is_critical,
      slotsState: [
        [
          {
            state: 'IN_PLANT',
            value: slot[entryType].in_plant
          },
          {
            state: 'BOOKED',
            value: slot[entryType].planned
          }
        ],
        [
          {
            state: 'UNLOADING',
            value: slot[entryType].unloading
          },
          {
            state: 'COMPLETED',
            value: slot[entryType].executed
          }
        ]
      ]
    };
  }

  private static _groupSaturationSlotsByShiftAnType(
    slots: UnloadingPointTimeSlotDTO[],
    type: EntryType
  ): SaturationDataByShift[] {
    const groupedByShift = slots.reduce<SlotsGroupedByShift>(
      (group, slot: UnloadingPointTimeSlotDTO) => {
        group[slot.shift] = group[slot.shift] ?? [];
        group[slot.shift].push(slot);

        return group;
      },
      {} as SlotsGroupedByShift
    );

    return Object.keys(groupedByShift).map((key) => ({
      shift: key as Shift,
      slots: groupedByShift[key].map((dtoSlot) =>
        this._formatDtoSlot(dtoSlot, type)
      )
    }));
  }

  static generateFromDTO({
    slots,
    unloading_point,
    current_shift_containers,
    current_shift_entries
  }: UnloadingPointDTO): UnloadingPoint {
    return new UnloadingPoint({
      entriesMaxValue: this._getMaxValueByEntryType(slots, 'entries'),
      containersMaxValue: this._getMaxValueByEntryType(slots, 'containers'),
      unloadingPoint: unloading_point,
      entriesDataByShift: this._groupSaturationSlotsByShiftAnType(
        slots,
        'entries'
      ),
      containersDataByShift: this._groupSaturationSlotsByShiftAnType(
        slots,
        'containers'
      ),
      containersUnloadingMetrics: current_shift_containers,
      entriesUnloadingMetrics: current_shift_entries
    });
  }
}

export { UnloadingPoint };
