export interface DeliveryPointsDTO {
  id: string;
  name: string;
}

class CountryDeliveryPointsDTO {
  isoCode: string;
  deliveryPoints: DeliveryPointsDTO[];

  constructor({ isoCode, deliveryPoints }) {
    this.isoCode = isoCode;
    this.deliveryPoints = deliveryPoints;
  }
}

export default CountryDeliveryPointsDTO;
