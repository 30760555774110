export interface UrgentTruckDTO {
  plate_number: string;
  most_critical_piece: string;
  most_critical_piece_coverage: number;
  estimated_time_arrival: string;
}

export interface UrgentIncomingTrucksDTO {
  total: number;
  entries: UrgentTruckDTO[];
}

const urgentIncomingTrucksDTOMapper = (data: any): UrgentIncomingTrucksDTO => ({
  total: data.pagination?.total_count || 0,
  entries:
    data.entries?.map((truck) => ({
      plate_number: truck.plate_number,
      most_critical_piece: truck.most_critical_piece,
      most_critical_piece_coverage: truck.most_critical_piece_coverage,
      estimated_time_arrival: truck.estimated_time_arrival
    })) ?? []
});

export { urgentIncomingTrucksDTOMapper };
