import { StatsItem } from './types';

class Statistic {
  total: number;
  name: string;
  stats: StatsItem[];

  constructor({ total, name, stats }) {
    this.total = total;
    this.name = name;
    this.stats = stats;
  }

  static generateDTO(data: any): Statistic {
    return new Statistic({
      name: data?.name ?? '',
      total: data?.total ?? 0,
      stats:
        data?.stats?.map((stat) => ({
          status: stat.status,
          counter: stat.counter
        })) ?? []
    });
  }
}

export { Statistic };
