import {
  ITransferStockNextHoursDTO,
  ITransferStockNextHoursItemDTO
} from 'modules/inbound/transfer/infrastructure/dto/TransferStockNextHoursDTO';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { TransferText } from '../infrastructure/i18n/TransferText';
import { TransferTranslations } from '../infrastructure/i18n/typing';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { CriticalStockType } from 'modules/shared/domain/enums';

const transferTranslations: TransferTranslations = {
  ...TransferText[LocaleService.getLanguage()]
};

export interface ITransferStockNextHours {
  incomingMaterials: ITransferStockNextHoursItem[];
  columns: ColumnsInfo[];
}

export interface ITransferStockNextHoursItem {
  code: string;
  description: string;
  deliveryTime: string;
  warehouse: string;
  providerDeliveryNote: string;
  plateNumber: string;
  numPackages: number;
  asnContainer: string;
  basicContainer: string;
  transportContainer: string;
  supplyContainer: string;
  supplyType: string;
  isPlanned: boolean;
  supplyCriticalType: CriticalStockType | null;
}

export interface ColumnsInfo {
  key: string;
  uiKey: string;
  value: string;
  sortable: boolean;
  isVisibleCheck: boolean;
}

export enum TransferStockNextHoursColumnKeys {
  code = 'code',
  description = 'description',
  deliveryTime = 'deliveryTime',
  warehouse = 'warehouse',
  providerDeliveryNote = 'providerDeliveryNote',
  plateNumber = 'plateNumber',
  numPackages = 'numPackages',
  isPlanned = 'isPlanned',
  supplyType = 'supplyType',
  asnContainer = 'asnContainer',
  basicContainer = 'basicContainer',
  transportContainer = 'transportContainer',
  supplyContainer = 'supplyContainer'
}

const SORTABLE_COLUMNS = [
  TransferStockNextHoursColumnKeys.code,
  TransferStockNextHoursColumnKeys.description,
  TransferStockNextHoursColumnKeys.deliveryTime,
  TransferStockNextHoursColumnKeys.warehouse,
  TransferStockNextHoursColumnKeys.providerDeliveryNote,
  TransferStockNextHoursColumnKeys.plateNumber,
  TransferStockNextHoursColumnKeys.numPackages,
  TransferStockNextHoursColumnKeys.supplyType,
  TransferStockNextHoursColumnKeys.asnContainer,
  TransferStockNextHoursColumnKeys.basicContainer,
  TransferStockNextHoursColumnKeys.transportContainer,
  TransferStockNextHoursColumnKeys.supplyContainer,
  TransferStockNextHoursColumnKeys.isPlanned
];

class TransferStockNextHours implements ITransferStockNextHours {
  incomingMaterials: ITransferStockNextHoursItem[];
  columns: ColumnsInfo[];
  pagination: IPagination;

  constructor({
    incomingMaterials,
    columns,
    pagination
  }: TransferStockNextHours) {
    this.incomingMaterials = incomingMaterials;
    this.columns = columns;
    this.pagination = pagination;
  }
  private static _camelToSnakeCase(key) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  static generateFromDTO({
    incoming_materials,
    pagination
  }: ITransferStockNextHoursDTO): TransferStockNextHours {
    return new TransferStockNextHours({
      incomingMaterials: incoming_materials.map(
        ({
          code,
          description,
          delivery_time,
          warehouse,
          provider_delivery_note,
          plate_number,
          num_packages,
          asn_container,
          basic_container,
          transport_container,
          supply_container,
          supply_type,
          is_planned,
          supply_critical_type
        }: ITransferStockNextHoursItemDTO): ITransferStockNextHoursItem => ({
          code,
          description: description ?? '-',
          deliveryTime: delivery_time
            ? GetDateFormatedService.getShortDateTime(delivery_time)
            : '-',
          warehouse,
          providerDeliveryNote: provider_delivery_note,
          plateNumber: plate_number,
          numPackages: num_packages,
          asnContainer: asn_container,
          basicContainer: basic_container,
          transportContainer: transport_container,
          supplyContainer: supply_container,
          supplyType: supply_type || '-',
          isPlanned: is_planned,
          supplyCriticalType: supply_critical_type ?? null
        })
      ),
      columns: Object.keys(TransferStockNextHoursColumnKeys).map((key) => ({
        key: this._camelToSnakeCase(key),
        uiKey: key,
        value: transferTranslations[key],
        sortable: SORTABLE_COLUMNS.includes(
          TransferStockNextHoursColumnKeys[key]
        ),
        isVisibleCheck: true,
        draggable: key !== TransferStockNextHoursColumnKeys.code
      })),
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty() {
    return new TransferStockNextHours({
      incomingMaterials: [],
      columns: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default TransferStockNextHours;
