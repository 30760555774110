import IEntriesRepository from '../../domain/IEntriesRepository';
import { UrgentIncomingTrucks } from '../../domain/NextHours/UrgentIncomingTrucks';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetUrgentIncomingTrucksUseCase {
  private _entriesDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesDataRepository = repository;
  }

  execute(): Promise<UrgentIncomingTrucks> {
    return this._entriesDataRepository.getUrgentIncomingTrucks();
  }
}

const getUrgentIncomingTrucksUseCase = new GetUrgentIncomingTrucksUseCase({
  repository: entriesRepository
});
export { GetUrgentIncomingTrucksUseCase, getUrgentIncomingTrucksUseCase };
