import IPipelineStockRepository from 'modules/outbound/stock/domain/IPipelineStockRepository';
import pipelineStockRepository from 'modules/outbound/stock/infrastructure/repositories/PipelineStockRepository';
import localeService, {
  LocaleService
} from 'infrastructure/i18n/LocaleService';

class DownloadPipelineStockUseCase {
  private _pipelineStockRepository: IPipelineStockRepository;
  private localeService: LocaleService;

  constructor({ repository, localeService }) {
    this._pipelineStockRepository = repository;
    this.localeService = localeService;
  }

  execute({ queryParams }: { queryParams: object }) {
    queryParams = { ...queryParams, lang: this.localeService.getLanguage() };
    return this._pipelineStockRepository.downloadPipelineStock({
      queryParams
    });
  }
}

export default new DownloadPipelineStockUseCase({
  repository: pipelineStockRepository,
  localeService
});

export { DownloadPipelineStockUseCase };
