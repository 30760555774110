export enum EndOfStockSummaryType {
  END_OF_STOCK = 'END_OF_STOCK',
  LEFTOVER_STOCK = 'LEFTOVER_STOCK',
  SLEEPER_WITH_NEEDS = 'SLEEPER_WITH_NEEDS',
  SLEEPER_NO_NEEDS = 'SLEEPER_NO_NEEDS',
  INCOHERENT = 'INCOHERENT'
}

interface GenerateFromDTOParams {
  number_of_keys: number;
  value_in_euros: number;
  stock_volume: number;
}

class EndOfProductionSummary {
  numberOfKeys: number;
  valueInEuros: string;
  stockVolume: number;

  constructor({ numberOfKeys, valueInEuros, stockVolume }) {
    this.numberOfKeys = numberOfKeys;
    this.valueInEuros = valueInEuros;
    this.stockVolume = stockVolume;
  }

  static generateFromDTO({
    number_of_keys,
    value_in_euros,
    stock_volume
  }: GenerateFromDTOParams): EndOfProductionSummary {
    return new EndOfProductionSummary({
      numberOfKeys: number_of_keys ?? 0,
      valueInEuros: value_in_euros ?? 0,
      stockVolume: stock_volume ?? 0
    });
  }

  static generateEmpty() {
    return new EndOfProductionSummary({
      numberOfKeys: 0,
      valueInEuros: 0,
      stockVolume: 0
    });
  }
}

export default EndOfProductionSummary;
