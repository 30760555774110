import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import {
  FamilyAlarms,
  FamilyCoverageItemDTO
} from '../infrastructure/dto/JitFamilyCoverageDTO';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};
export interface FamilyCoverageItem {
  lines: string[];
  codes: FamilyAlarms;
  coverage: string;
  name: string;
}
interface GenerateFromDTOParams {
  alerts_total: number;
  coverage: number;
  families: FamilyCoverageItemDTO[];
}

class JitFamilyCoverageData {
  alertsTotal: number;
  coverage: number;
  families: FamilyCoverageItem[];

  constructor({ alertsTotal, coverage, families }: JitFamilyCoverageData) {
    this.alertsTotal = alertsTotal;
    this.coverage = coverage;
    this.families = families;
  }

  private static _formatMargin(marginDays) {
    let marginDaysFormatted = marginDays.toString();
    if (marginDaysFormatted === 'MAX') {
      return marginDaysFormatted;
    }
    return marginDaysFormatted === '1'
      ? `${marginDaysFormatted} ${inboundTranslations.unitSingleDay}`
      : `${marginDaysFormatted.replace('.', ',')} ${
          inboundTranslations.unitMultipleDays
        }`;
  }

  static generateFromDTO({
    alerts_total,
    coverage,
    families
  }: GenerateFromDTOParams): JitFamilyCoverageData {
    let formatJitFamilies: FamilyCoverageItem[] = [];
    if (families.length) {
      families.forEach((family) => {
        formatJitFamilies.push({
          lines: family.lines,
          codes: family.codes,
          coverage: this._formatMargin(family.coverage),
          name: family.name
        });
      });
    }
    return new JitFamilyCoverageData({
      alertsTotal: alerts_total,
      coverage: coverage,
      families: formatJitFamilies
    });
  }
}
export default JitFamilyCoverageData;
