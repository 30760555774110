import { createAsyncThunk } from '@reduxjs/toolkit';
import { authenticationService } from 'modules/shared/services/AuthenticationService';
import { UserGroup } from 'modules/shared/domain/UserRole';

const login = createAsyncThunk<
  any,
  {
    username: string;
    password: string;
  }
>('user/login', ({ username, password }) => {
  return authenticationService.initSession({ username, password });
});

const resetPassword = createAsyncThunk<
  any,
  {
    password: string;
  }
>('user/resetPassword', ({ password }) => {
  return authenticationService.completeNewPassword({ newPassword: password });
});

const forgotPassword = createAsyncThunk<
  void,
  {
    username: string;
  }
>('user/forgotPassword', ({ username }) => {
  return authenticationService.forgotPassword({ username });
});

const forgotPasswordSubmit = createAsyncThunk<
  any,
  {
    username: string;
    code: string;
    password: string;
  }
>('user/forgotPasswordSubmit', ({ username, code, password }) => {
  return authenticationService.forgotPasswordSubmit({
    username,
    code,
    password
  });
});

const checkUser = createAsyncThunk<string>('user/checkUser', () => {
  return authenticationService.getUsername();
});

const getUserGroup = createAsyncThunk<UserGroup[]>('user/getUserRole', () => {
  return authenticationService.getUserGroup();
});

export {
  login,
  checkUser,
  forgotPassword,
  forgotPasswordSubmit,
  resetPassword,
  getUserGroup
};
