import { createSlice } from '@reduxjs/toolkit';
import { getFamiliesCoverageFilters } from './async';
import { FiltersState } from './types';
import { FlowTypeEnum } from 'modules/inbound/jit/infrastructure/dto/JitFlowTypesDTO';

export const initialState: FiltersState = {
  filters: {
    families: [],
    lines: [],
    tier1ProviderIds: [],
    logisticOperatorsIds: [],
    flowTypes: [],
    inbounders: []
  },
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedFamilies: [],
    selectedLines: [],
    selectedTier1ProviderIds: [],
    selectedLogisticOperatorsIds: [],
    selectedFlowType: { key: '', text: '' },
    selectedInbounders: []
  },
  isFilterSaved: false
};

export const FAMILIES_COVERAGE_FILTERS_STATE_KEY =
  'FamiliesCoverageFiltersState';

const FamiliesCoverageFiltersSlice = createSlice({
  name: 'familiesCoverageFilters',
  initialState,
  reducers: {
    updateSelectedFamilies: (state, action) => {
      state.selectedSubHeaderFilters.selectedFamilies = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedTier1ProviderIds: (state, action) => {
      state.selectedSubHeaderFilters.selectedTier1ProviderIds = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLogisticOperatorsIds: (state, action) => {
      state.selectedSubHeaderFilters.selectedLogisticOperatorsIds =
        action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedFlowType: (state, action) => {
      state.selectedSubHeaderFilters.selectedFlowType = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedInbounders: (state, action) => {
      state.selectedSubHeaderFilters.selectedInbounders = action.payload;
      state.isFilterSaved = false;
    },
    resetFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${FAMILIES_COVERAGE_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${FAMILIES_COVERAGE_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${FAMILIES_COVERAGE_FILTERS_STATE_KEY}`
      );
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        state.selectedSubHeaderFilters =
          parsedFilters || initialState.selectedSubHeaderFilters;
        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFamiliesCoverageFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFamiliesCoverageFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFamiliesCoverageFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getFamilies = (state) =>
  state.jitFamiliesFiltersState.filters.families;
export const getLines = (state) => state.jitFamiliesFiltersState.filters.lines;
export const getTier1ProviderIds = (state) =>
  state.jitFamiliesFiltersState.filters.tier1ProviderIds;
export const getLogisticOperatorsIds = (state) =>
  state.jitFamiliesFiltersState.filters.logisticOperatorsIds;
export const getFlowTypes = (state) =>
  state.jitFamiliesFiltersState.selectedSubHeaderFilters.selectedFlowType.key
    ? [FlowTypeEnum.NONE, FlowTypeEnum.SYNCRO, FlowTypeEnum.NO_SYNCRO]
    : state.jitFamiliesFiltersState.filters.flowTypes;
export const getInbounders = (state) =>
  state.jitFamiliesFiltersState.filters.inbounders;
export const getSelectedFilters = (state) =>
  state.jitFamiliesFiltersState.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.jitFamiliesFiltersState.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  !!state.jitFamiliesFiltersState.selectedSubHeaderFilters.selectedFlowType
    .key ||
  Object.keys(state.jitFamiliesFiltersState.selectedSubHeaderFilters).some(
    (key) => state.jitFamiliesFiltersState.selectedSubHeaderFilters[key].length
  );

export const {
  updateSelectedFamilies,
  updateSelectedLines,
  updateSelectedTier1ProviderIds,
  updateSelectedLogisticOperatorsIds,
  updateSelectedFlowType,
  updateSelectedInbounders,
  resetFilters,
  saveFilters,
  loadFilters
} = FamiliesCoverageFiltersSlice.actions;

export default FamiliesCoverageFiltersSlice.reducer;
