import CoverageAtRisk from 'modules/shared/domain/CoverageAtRisk';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NoJitStock } from 'modules/inbound/nojit/domain/NoJitStock';
import { INoJitTransportsDetail } from 'modules/inbound/nojit/domain/NoJitTransportsDetail';
import { NoJitPlanning } from 'modules/inbound/nojit/domain/NoJitPlanning';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';
import { ColumnSelected } from 'modules/shared/layout/components/TableHeader/types';
import {
  NoJitTransport,
  NoJitMRP
} from 'modules/inbound/nojit/domain/PlanningTransports';

export enum COLORS {
  RED = 'red700',
  GREEN = 'green700'
}

export const BarStatus = {
  NOT_COVERED: COLORS.RED,
  COVERED: COLORS.GREEN
};

export const SelectedStatusColor = {
  [COLORS.RED]: 'NOT_COVERED',
  [COLORS.GREEN]: 'COVERED'
};

export interface SelectedLongTermEntry {
  code: string;
  week: string;
  transports: NoJitTransport[];
  mrps: NoJitMRP[];
}

export interface NoJitDataState {
  data: {
    planningDates: string[];
    stock: NoJitStock[];
    targetCodes: string[];
    updateStockDate: string;
    updateStockTime: string;
    updatePlanningDate: string;
    updatePlanningTime: string;
    noJitCoverageAtRisk: CoverageAtRisk;
  };
  columns: ColumnProps[];
  savedColumns: ColumnProps[];
  pagination: {
    page: number;
    size: number;
    pageCount: number;
    totalCount: number;
  };
  requestStatus: string;
  requestIdTableDataStatus: string;
  requestNoJitCoverageAtRiskStatus: string;
  selectedColumn: ColumnSelected;
  selectedPiece: string;
  selectedPiecePlanningTransports: INoJitTransportsDetail;
  requestSelectedPiecePlanningStatus: string;
  nojitFile: any;
  downloadRequestStatus: string;
  planning: NoJitPlanning;
  requestNoJitPlanningStatus: string;
  requestIdPlanningStatus: string;
  selectedTemporalHorizon: SelectItemProps;
  selectedLongTermEntry: SelectedLongTermEntry;
  selectedShortTermEntry: string;
  isTableMaximize: boolean;
  isEditComment: boolean;
  editCommentResponse: any;
  editCommentRequestStatus: string;
}
