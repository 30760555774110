import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { JitFamiliesText } from '../infrastructure/i18n/JitFamiliesText';
import { JitFamiliesTranslations } from '../infrastructure/i18n/typing';

const jitFamiliesTranslations: JitFamiliesTranslations = {
  ...JitFamiliesText[LocaleService.getLanguage()]
};

interface GenerateFromDTOParams {
  line_sequence: string;
  workshop: string;
  branch: string;
  branch_position: number;
  line_sequence_updating_date_at: string;
  marquee: string;
  lisons: string[];
}

class JitGeneralData {
  lineSequence: string;
  workshop: string;
  branch: string;
  branchPosition: number | string;
  lineSequenceUpdatingDateAt: string;
  marquee: string;
  lisons: string;
  constructor({
    lineSequence,
    workshop,
    branch,
    branchPosition,
    lineSequenceUpdatingDateAt,
    marquee,
    lisons
  }: JitGeneralData) {
    this.lineSequence = lineSequence;
    this.workshop = workshop;
    this.branch = branch;
    this.branchPosition = branchPosition;
    this.lineSequenceUpdatingDateAt = lineSequenceUpdatingDateAt;
    this.marquee = marquee;
    this.lisons = lisons;
  }
  static generateFromDTO({
    line_sequence,
    workshop,
    branch,
    branch_position,
    line_sequence_updating_date_at,
    marquee,
    lisons
  }: GenerateFromDTOParams): JitGeneralData {
    return new JitGeneralData({
      lineSequence: line_sequence || jitFamiliesTranslations.emptyMessage,
      workshop: workshop ?? jitFamiliesTranslations.emptyMessage,
      branch: branch ?? jitFamiliesTranslations.emptyMessage,
      branchPosition: branch_position ?? jitFamiliesTranslations.emptyMessage,
      lineSequenceUpdatingDateAt: line_sequence_updating_date_at
        ? GetDateFormatedService.getFullDateTime(line_sequence_updating_date_at)
        : jitFamiliesTranslations.emptyMessage,
      marquee: marquee ?? jitFamiliesTranslations.emptyMessage,
      lisons: lisons?.join(' | ') ?? jitFamiliesTranslations.emptyMessage
    });
  }
}

export default JitGeneralData;
