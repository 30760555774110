export interface PieceInPLantDTO {
  code: string;
  coverage: number;
  warehouse: string;
  packages: number;
  status: string;
  container: string;
  container_type: string;
  is_critical: boolean;
}

export interface IEntryInPlantDetailDTO {
  plate_number: string;
  transport_id: number;
  carrier: string;
  stops: number;
  window_booked: string;
  packages: number;
  global_dlz: number;
  pieces: PieceInPLantDTO[];
  has_critical_pieces: boolean;
}

class EntryInPlantDetailDTO implements IEntryInPlantDetailDTO {
  plate_number: string;
  transport_id: number;
  carrier: string;
  stops: number;
  window_booked: string;
  packages: number;
  global_dlz: number;
  pieces: PieceInPLantDTO[];
  has_critical_pieces: boolean;

  constructor({
    plate_number,
    transport_id,
    carrier,
    stops,
    window_booked,
    packages,
    global_dlz,
    pieces,
    has_critical_pieces
  }) {
    this.plate_number = plate_number;
    this.transport_id = transport_id;
    this.carrier = carrier;
    this.stops = stops;
    this.window_booked = window_booked;
    this.packages = packages;
    this.global_dlz = global_dlz;
    this.pieces = pieces;
    this.has_critical_pieces = has_critical_pieces;
  }
}

export default EntryInPlantDetailDTO;
