import { Translation } from 'infrastructure/i18n/typing';
import { LoginTranslations } from './typing';

export const LoginText: Translation<LoginTranslations> = {
  es: {
    welcome: 'Bienvenido',
    login: 'Para empezar a usar la Control Tower loguéate',
    forgottenPassword: '¿Has olvidado la contraseña?',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    passwordRecovery: 'Recuperación de contraseña',
    enterUsername:
      'Introduce tu nombre de usuario y te enviaremos un código por correoelectrónico',
    returnToLogin: 'Volver para iniciar sesión',
    sendCode: 'Enviar código',
    invalidPassword: 'Contraseña inválida',
    invalidCode: 'Código inválido, vuelva a enviar el código',
    errorChangingPassword: 'Ha habido un error al cambiar la contraseña',
    recoverPassword: 'Recuperar contraseña',
    codeReceived: 'Código (recibido en el correo electrónico)',
    oneCapitalLetter: '1 Mayúscula',
    oneLowercase: '1 Minúscula',
    oneSpecialChar: '1 Carácter especial',
    oneNumber: '1 Número',
    lengthChars: 'Longitud 8 caracteres',
    repeatPassword: 'Repite contraseña',
    passwordsMatch: 'Las contraseñas coinciden',
    resubmitCode: 'Volver a enviar el código',
    changePassword: 'Cambiar contraseña',
    invalidUsernameOrPassword: 'Usuario o contraseña inválidos',
    passwordChanged: 'La contraseña ha sido cambiada correctamente',
    enterNewPassword: 'Introduce la nueva contraseña'
  },
  en: {
    welcome: 'Welcome',
    login: 'To start using Control Tower, log in',
    forgottenPassword: 'Have you forgotten the password?',
    username: 'Username',
    password: 'Password',
    passwordRecovery: 'Password recovery',
    enterUsername: 'Enter your username and we will send you a code by email',
    returnToLogin: 'Return to login',
    sendCode: 'Send code',
    invalidPassword: 'Invalid password',
    invalidCode: 'Invalid code, please resend code',
    errorChangingPassword: 'There was an error changing the password',
    recoverPassword: 'Recover password',
    codeReceived: 'Code (received in email)',
    oneCapitalLetter: '1 Capital letter',
    oneLowercase: '1 Lowercase',
    oneSpecialChar: '1 Special character',
    oneNumber: '1 Number',
    lengthChars: 'Length 8 characters',
    repeatPassword: 'Repeat password',
    passwordsMatch: 'Passwords match',
    resubmitCode: 'Resubmit the code',
    changePassword: 'Change Password',
    invalidUsernameOrPassword: 'Invalid username or password',
    passwordChanged: 'Password has been changed successfully',
    enterNewPassword: 'Enter the new password'
  },
  ca: {
    welcome: 'Benvingut',
    login: "Per començar a utilitzar la Control Tower logueja't",
    forgottenPassword: 'Heu oblidat la contrasenya?',
    username: "Nom d'usuari",
    password: 'Contrasenya',
    passwordRecovery: 'Recuperació de contrasenya',
    enterUsername:
      "Introdueix el teu nom d'usuari i t'enviarem un codi per correu electrònic",
    returnToLogin: 'Tornar per iniciar sessió',
    sendCode: 'Enviar codi',
    invalidPassword: 'Contrasenya invàlida',
    invalidCode: 'Codi invàlid, torneu a enviar el codi',
    errorChangingPassword: 'Hi ha hagut un error en canviar la contrasenya',
    recoverPassword: 'Recuperar contrasenya',
    codeReceived: 'Codi (rebut al correu electrònic)',
    oneCapitalLetter: '1 Majúscula',
    oneLowercase: '1 Minúscula',
    oneSpecialChar: '1 Caràcter especial',
    oneNumber: '1 Número',
    lengthChars: 'Longitud 8 caràcters',
    repeatPassword: 'Repeteix contrasenya',
    passwordsMatch: 'Les contrasenyes coincideixen',
    resubmitCode: 'Tornar a enviar el codi',
    changePassword: 'Canvia contrasenya',
    invalidUsernameOrPassword: 'Usuari o contrasenya invàlids',
    passwordChanged: 'La contrasenya ha estat canviada correctament',
    enterNewPassword: 'Introduïu la contrasenya nova'
  }
};
