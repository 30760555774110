import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { DirectDeliveryText } from '../infrastructure/i18n/DirectDeliveryText';
import { DirectDeliveryTranslations } from '../infrastructure/i18n/typing';

const directDeliveryTranslations: DirectDeliveryTranslations &
  InboundTranslations = {
  ...DirectDeliveryText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export enum DDColumnKeys {
  code = 'code',
  providerCode = 'providerCode',
  description = 'description',
  warehouseId = 'warehouseId',
  lines = 'lines',
  models = 'models',
  providerId = 'providerId',
  containersInCycle = 'containersInCycle',
  containerId = 'containerId',
  coverageInHours = 'coverageInHours',
  stock = 'stock',
  containersAtMarquee = 'containersAtMarquee',
  lineMovementType = 'lineMovementType',
  lineMovementAmount = 'lineMovementAmount',
  lineMovementDate = 'lineMovementDate',
  finalStockQuantity = 'finalStockQuantity',
  finalStockDatetime = 'finalStockDatetime',
  averageConsumption = 'averageConsumption',
  averageCoverageInHours = 'averageCoverageInHours',
  status = 'status'
}

export const DIRECT_DELIVERY_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: directDeliveryTranslations.code,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.code,
        key: NameValues.CODE,
        value: directDeliveryTranslations.code,
        sortable: false
      }
    ]
  },
  {
    key: NameValues.PROVIDER_CODE,
    value: directDeliveryTranslations.providerCode,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.providerCode,
        key: NameValues.PROVIDER_CODE,
        value: directDeliveryTranslations.providerCode,
        sortable: false,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.providerPartTooltipInfo
      }
    ]
  },
  {
    key: NameValues.STATUS,
    value: directDeliveryTranslations.status,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.status,
        key: NameValues.STATUS,
        value: directDeliveryTranslations.status,
        sortable: true,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.statusTooltip
      }
    ]
  },
  {
    key: NameValues.DESCRIPTION,
    value: directDeliveryTranslations.description,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.description,
        key: NameValues.DESCRIPTION,
        value: directDeliveryTranslations.description,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE_ID,
    value: directDeliveryTranslations.warehouseId,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.warehouseId,
        key: NameValues.WAREHOUSE_ID,
        value: directDeliveryTranslations.warehouseId,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.LINES,
    value: directDeliveryTranslations.lines,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.lines,
        key: NameValues.LINES,
        value: directDeliveryTranslations.lines,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.MODELS,
    value: directDeliveryTranslations.models,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.models,
        key: NameValues.MODELS,
        value: directDeliveryTranslations.models,
        sortable: true,
        resize: true,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.modelCarRelation
      }
    ]
  },
  {
    key: NameValues.PROVIDER_IDS,
    value: directDeliveryTranslations.providerId,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.providerId,
        key: NameValues.PROVIDER_IDS,
        value: directDeliveryTranslations.providerId,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.CONTAINERS_IN_CYCLE,
    value: directDeliveryTranslations.containerInCycle,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.containersInCycle,
        key: NameValues.CONTAINERS_IN_CYCLE,
        value: directDeliveryTranslations.containerInCycle,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.CONTAINER_ID,
    value: directDeliveryTranslations.containerId,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.containerId,
        key: NameValues.CONTAINER_ID,
        value: directDeliveryTranslations.containerId,
        sortable: true,
        resize: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.CONTAINERS_AT_MARQUEE,
    value: directDeliveryTranslations.marquee,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.coverageInHours,
        key: NameValues.COVERAGE_IN_SECONDS,
        value: directDeliveryTranslations.coverage,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: DDColumnKeys.stock,
        key: NameValues.STOCK,
        value: directDeliveryTranslations.stock,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: DDColumnKeys.containersAtMarquee,
        key: NameValues.CONTAINERS_AT_MARQUEE,
        value: directDeliveryTranslations.containerAtMarquee,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.MOVEMENT_TYPE,
    value: directDeliveryTranslations.movementLabel,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.lineMovementType,
        key: NameValues.MOVEMENT_TYPE,
        value: directDeliveryTranslations.movementType,
        sortable: false,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.movementsLineTooltip
      },
      {
        uiKey: DDColumnKeys.lineMovementAmount,
        key: NameValues.MOVEMENT_AMOUNT,
        value: directDeliveryTranslations.movementAmount,
        sortable: false,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: DDColumnKeys.lineMovementDate,
        key: NameValues.MOVEMENT_DATE,
        value: directDeliveryTranslations.movementDate,
        sortable: false,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.FINAL_STOCK_QUANTITY,
    value: directDeliveryTranslations.providerT1,
    headerVisible: true,
    headerSpan: 2,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.finalStockQuantity,
        key: NameValues.FINAL_STOCK_QUANTITY,
        value: directDeliveryTranslations.finalStockQuantity,
        sortable: true,
        draggable: true,
        align: 'right'
      },
      {
        uiKey: DDColumnKeys.finalStockDatetime,
        key: NameValues.FINAL_STOCK_DATETIME,
        value: directDeliveryTranslations.finalStockDatetime,
        sortable: true,
        draggable: true
      }
    ]
  },
  {
    key: NameValues.AVERAGE_CONSUMPTION,
    value: directDeliveryTranslations.averageConsumption,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.averageConsumption,
        key: NameValues.AVERAGE_CONSUMPTION,
        value: directDeliveryTranslations.averageConsumption,
        sortable: true,
        resize: true,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.averageConsumptionTooltip,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.AVERAGE_COVERAGE_IN_SECONDS,
    value: directDeliveryTranslations.averageCoverage,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: DDColumnKeys.averageCoverageInHours,
        key: NameValues.AVERAGE_COVERAGE_IN_SECONDS,
        value: directDeliveryTranslations.averageCoverage,
        sortable: true,
        resize: true,
        draggable: true,
        tooltipInfo: directDeliveryTranslations.averageCoverageTooltip,
        align: 'right'
      }
    ]
  }
];
