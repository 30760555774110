import IProviderCodesRepository from '../../domain/UploadProviderCodes/IProviderCodes';
import providerCodesRepository from '../../infraestructure/repositories/ProviderCodesRepository';

class DownloadProviderCodesUseCase {
  private _providerCodesRepository: IProviderCodesRepository;

  constructor({ providerCodesRepository }) {
    this._providerCodesRepository = providerCodesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._providerCodesRepository.downloadProviderCodes({
      queryParams
    });
  }
}

export default new DownloadProviderCodesUseCase({
  providerCodesRepository: providerCodesRepository
});

export { DownloadProviderCodesUseCase };
