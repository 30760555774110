import { CriticalPieceDTO } from './CriticalPieceDTO';

export interface CriticalPiecesSummaryDTO {
  critical_supplies: CriticalPieceDTO[];
}

const criticalPiecesSummaryDTOMapper = (
  data: any
): CriticalPiecesSummaryDTO => {
  return {
    critical_supplies:
      data?.critical_supplies?.map((item) =>
        CriticalPieceDTO.generateDTO(item)
      ) || []
  };
};

export { criticalPiecesSummaryDTOMapper };
