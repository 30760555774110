import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface SuppliesDTO {
  code: string;
  lines: number[];
  description: string;
  material_indicator: string;
  status: string;
  supply_type: string;
  order_request: string;
  transport_request: number;
  warehouse_unit_number: string;
  warehouse_source_type: string;
  created_at: string;
  line_group: string;
  confirmation_date: string | null;
  preparation_date: string | null;
  line_location: string;
  manufacturing_section: string;
  warehouse_source: string;
  delay_time_in_minutes: number;
  critical_stock_type: string | null;
  warehouse_target: string;
  provider_delivery_note: string;
}

class SuppliesDataDTO {
  supplies_without_stock_last_update: string | null;
  supplies_with_stock_last_update: string | null;
  ot_orders: SuppliesDTO[];
  pagination: IPaginationDTO;

  constructor({
    supplies_without_stock_last_update,
    supplies_with_stock_last_update,
    ot_orders,
    pagination
  }) {
    this.supplies_without_stock_last_update =
      supplies_without_stock_last_update;
    this.supplies_with_stock_last_update = supplies_with_stock_last_update;
    this.ot_orders = ot_orders;
    this.pagination = pagination;
  }
}

export default SuppliesDataDTO;
