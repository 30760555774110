const FULFILLMENT_ENDPOINT = '/outbound/transit/fulfillment';
const GEOLOCATION_ENDPOINT = '/outbound/transit/delay';
const GEOLOCATION_FINISHED_GOOD_TIMELINE = '/outbound/transit/delay/car';
const SEARCH_FINISHED_GOOD_ENDPOINT = '/outbound/transit/search';
const GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS =
  '/outbound/transit/location/car';
const TRANSPORT_DETAILS_BY_ID = '/outbound/transit/location/transport';
const TRANSPORTS_ENDPOINT = '/outbound/transit/transports';
const VESSELS_ENDPOINT = '/outbound/transit/vessels';
const GLOBAL_FULFILLMENT_ENDPOINT = '/outbound/transit/global/fulfillment';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_GET_FULFILLMENT = BASE_URL + FULFILLMENT_ENDPOINT;
const API_GET_GEOLOCATION = BASE_URL + GEOLOCATION_ENDPOINT;
const API_GET_GEOLOCATION_FINISHED_GOOD_TIMELINE =
  BASE_URL + GEOLOCATION_FINISHED_GOOD_TIMELINE;
const API_GET_GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS =
  BASE_URL + GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS;
const API_GET_TRANSPORT_DETAILS = BASE_URL + TRANSPORT_DETAILS_BY_ID;
const API_GET_SEARCH_FINISHED_GOOD = BASE_URL + SEARCH_FINISHED_GOOD_ENDPOINT;
const API_GET_GEOLOCATED_TRANSPORTS = BASE_URL + TRANSPORTS_ENDPOINT;
const API_GET_GEOLOCATED_VESSELS = BASE_URL + VESSELS_ENDPOINT;
const API_GLOBAL_FULFILLMENT = BASE_URL + GLOBAL_FULFILLMENT_ENDPOINT;

export {
  API_GET_FULFILLMENT,
  API_GET_GEOLOCATION,
  API_GET_SEARCH_FINISHED_GOOD,
  API_GET_GEOLOCATION_FINISHED_GOOD_TIMELINE,
  API_GET_GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS,
  API_GET_TRANSPORT_DETAILS,
  API_GET_GEOLOCATED_TRANSPORTS,
  API_GLOBAL_FULFILLMENT,
  API_GET_GEOLOCATED_VESSELS
};
