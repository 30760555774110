import IUsersRepository from '../domain/IUsersRepository';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class DeleteUsersDataService {
  private _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute(userId) {
    return this._usersRepository.deleteUser(userId).then((res) => res);
  }
}

export default new DeleteUsersDataService({
  repository: usersRepository
});

export { DeleteUsersDataService };
