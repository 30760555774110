import { API_GET_CALL_OFF_TRACKING, API_GET_CHEKPOINTS } from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import CallOffTrackingByWarehouseDTO from 'modules/outbound/callOff/infrastructure/dto/CallOffTrackingByWarehouseDTO';
import CallOffFinishedGoodsListDTO from 'modules/outbound/callOff/infrastructure/dto/CallOffFinishedGoodsListDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import CheckpointDTO from 'modules/outbound/callOff/infrastructure/dto/CheckpointDTO';
import CallOffTrackingByCheckpointDTO from 'modules/outbound/callOff/infrastructure/dto/CallOffTrackingByCheckpointDTO';

class CallOffRepository extends BaseRepository implements ICallOffRepository {
  private _api: IApi;
  private _apiGetCallOff: string;
  private _apiGetCheckpoints: string;

  constructor({ api, apiGetCallOff, apiGetCheckpoints }) {
    super();
    this._api = api;
    this._apiGetCallOff = apiGetCallOff;
    this._apiGetCheckpoints = apiGetCheckpoints;
  }

  getTracking({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetCallOff}${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) =>
        res.map((item) => new CallOffTrackingByWarehouseDTO(item))
      );
  }

  getFinishedGoodsByWarehouse({
    warehouseRef,
    queryParams
  }: {
    warehouseRef: string;
    queryParams: object;
  }) {
    const url = `${
      this._apiGetCallOff
    }/${warehouseRef}${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) => new CallOffFinishedGoodsListDTO(res));
  }

  downloadFinishedGoodsByWarehouse({
    warehouseRef,
    queryParams
  }: {
    warehouseRef: string;
    queryParams: object;
  }) {
    const url = `${
      this._apiGetCallOff
    }/${warehouseRef}/export${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  getCheckpoints({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    const url = `${
      this._apiGetCheckpoints
    }/${countryCode}${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) => res.map((element) => new CheckpointDTO(element)));
  }

  getTrackingByCheckpointType({ checkpointType }: { checkpointType: string }) {
    const url = `${this._apiGetCallOff}/checkpoint/${checkpointType}`;
    return this._api
      .get(url)
      .then((res) => new CallOffTrackingByCheckpointDTO(res));
  }
}

export default new CallOffRepository({
  api: api,
  apiGetCallOff: API_GET_CALL_OFF_TRACKING,
  apiGetCheckpoints: API_GET_CHEKPOINTS
});

export { CallOffRepository };
