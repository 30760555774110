import { CriticalTypes } from 'modules/shared/domain/enums';
import { CriticalStockDetail } from '../infraestructure/dto/SummaryCriticalStockDTO';

type SummaryCriticalTypes = keyof typeof CriticalTypes;

export interface SummaryCriticalDetails {
  counter: number;
  status: SummaryCriticalTypes;
}
export interface CriticalStockData {
  stats: SummaryCriticalDetails[];
  total: number;
}
interface GenerateFromDTOParams {
  stats: CriticalStockDetail[];
  total: number;
}

class SummaryCriticalStockData {
  criticalStock: CriticalStockData;

  constructor({ criticalStock }) {
    this.criticalStock = criticalStock;
  }

  static generateFromDTO({
    stats,
    total
  }: GenerateFromDTOParams): SummaryCriticalStockData {
    let formatSummary: CriticalStockDetail[] = [];

    stats.forEach((item) => {
      item.status &&
        formatSummary.push({
          counter: item.counter,
          status: item.status
        });
    });

    return new SummaryCriticalStockData({
      criticalStock: {
        stats: formatSummary,
        total: total
      }
    });
  }
}

export default SummaryCriticalStockData;
