import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { UnloadingPointPieceDTO } from '../../infrastructure/dto/UnloadingPointsDTO/unloadingPointPiecesDTOMapper';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

export interface IUnloadingPointPiece {
  code: string;
  coverage: string;
  warehouse: string;
  packages: number;
  containerType: string;
  container: number;
  isCritical: boolean;
}

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};

class UnloadingPointPiece implements IUnloadingPointPiece {
  code: string;
  coverage: string;
  warehouse: string;
  packages: number;
  containerType: string;
  container: number;
  isCritical: boolean;

  constructor({
    code,
    coverage,
    warehouse,
    packages,
    containerType,
    container,
    isCritical
  }: UnloadingPointPiece) {
    this.code = code;
    this.coverage = coverage;
    this.warehouse = warehouse;
    this.packages = packages;
    this.containerType = containerType;
    this.container = container;
    this.isCritical = isCritical;
  }

  private static _formatDays(quantity) {
    if (quantity === null) {
      return '-';
    }

    const daysWithTwoDecimals = quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });

    if (daysWithTwoDecimals === '1') {
      return `${daysWithTwoDecimals} ${inboundTranslations.unitSingleDay}`;
    }

    return `${daysWithTwoDecimals} ${inboundTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    code,
    coverage,
    warehouse,
    packages,
    container_type,
    container,
    is_critical
  }: UnloadingPointPieceDTO): UnloadingPointPiece {
    return new UnloadingPointPiece({
      code,
      coverage: this._formatDays(coverage),
      warehouse,
      packages,
      containerType: container_type || NO_DATA,
      container,
      isCritical: is_critical
    });
  }
}

export { UnloadingPointPiece };
