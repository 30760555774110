import {
  DirectDeliveryFiltersDTO,
  StatusTypes
} from 'modules/inbound/directDelivery/infrastructure/dto/DirectDeliveryFiltersDTO';

export interface DirectDeliveryFilters {
  warehouseCodes: string[];
  lines: number[];
  providerIds: number[];
  models: string[];
  status: StatusTypes;
}

class DirectDeliveryFiltersData {
  warehouseCodes: string[];
  lines: number[];
  providerIds: number[];
  models: string[];
  status: StatusTypes;

  constructor({
    warehouseCodes,
    lines,
    providerIds,
    models,
    status
  }: DirectDeliveryFilters) {
    this.warehouseCodes = warehouseCodes;
    this.lines = lines;
    this.providerIds = providerIds;
    this.models = models;
    this.status = status;
  }

  static generateFromDTO({
    warehouse_codes,
    lines,
    provider_ids,
    models,
    status
  }: DirectDeliveryFiltersDTO): DirectDeliveryFilters {
    return new DirectDeliveryFiltersData({
      warehouseCodes: warehouse_codes,
      lines: lines,
      providerIds: provider_ids,
      models: models,
      status: status
    });
  }
}

export default DirectDeliveryFiltersData;
