import IMessagesRepository from '../../domain/InboundMessages/IMessagesRepository';
import MessagesRepository from '../../infraestructure/repositories/MessagesRepository';

class PostInboundMessageUseCase {
  private _inboundMessagesRepository: IMessagesRepository;

  constructor({ repository }) {
    this._inboundMessagesRepository = repository;
  }

  execute({
    body
  }: {
    body: { target_type: string; target_uid: string; content: string };
  }) {
    return this._inboundMessagesRepository
      .postMessage({ body })
      .then((res) => res);
  }
}

export { PostInboundMessageUseCase };
export default new PostInboundMessageUseCase({
  repository: MessagesRepository
});
