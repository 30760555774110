import IUsersRepository from '../domain/IUsersRepository';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class UpdateUserDataService {
  private readonly _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute(body) {
    return this._usersRepository.updateUser(body).then((res) => res);
  }
}

export default new UpdateUserDataService({
  repository: usersRepository
});

export { UpdateUserDataService };
