import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';
import { UserRole, UserVisibility } from 'modules/shared/domain/UserRole';

export interface UserDTO {
  id?: string;
  username: string;
  provider_ids: number[];
  dashboards_ids: number[];
  dashboards_names?: string[];
  role: UserRole;
  email: string;
  groups: UserVisibility[];
  last_connection_date?: string;
}
class UsersDataDTO {
  data: UserDTO[];
  pagination: IPaginationDTO;

  constructor({ data, pagination }) {
    this.data = data;
    this.pagination = pagination;
  }
}

export default UsersDataDTO;
