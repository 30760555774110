import { createSlice } from '@reduxjs/toolkit';
import FactoryBcnFilters from 'modules/inbound/factoryBcn/domain/FactoryBcnFilters';
import { getFactoryBcnFilters } from './async';
import { FactoryBcnFiltersState } from './types';

export const initialState: FactoryBcnFiltersState = {
  filters: FactoryBcnFilters.generateEmpty(),
  requestStatus: '',
  selectedSubHeaderFilters: {
    selectedMRTWarehouses: [],
    selectedBCNWarehouses: [],
    selectedLines: [],
    selectedModels: []
  },
  search: '',
  isFilterSaved: false
};

const FACTORY_BCN_FILTERS_STATE_KEY = 'FactoryBcnFilters';

const factoryBcnFiltersSlice = createSlice({
  name: 'factoryBcnFilters',
  initialState,
  reducers: {
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    updateSelectedMRTWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedMRTWarehouses = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedBCNWarehouses: (state, action) => {
      state.selectedSubHeaderFilters.selectedBCNWarehouses = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedModels: (state, action) => {
      state.selectedSubHeaderFilters.selectedModels = action.payload;
      state.isFilterSaved = false;
    },
    updateSelectedLines: (state, action) => {
      state.selectedSubHeaderFilters.selectedLines = action.payload;
      state.isFilterSaved = false;
    },
    resetFilters: (state, action) => {
      state.selectedSubHeaderFilters = initialState.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${FACTORY_BCN_FILTERS_STATE_KEY}`
      );
      state.isFilterSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${FACTORY_BCN_FILTERS_STATE_KEY}`,
        JSON.stringify(state.selectedSubHeaderFilters)
      );
      state.isFilterSaved = true;
    },
    loadFilters: (state, action) => {
      const filters = localStorage.getItem(
        `${action.payload}-${FACTORY_BCN_FILTERS_STATE_KEY}`
      );
      if (filters) {
        state.selectedSubHeaderFilters = JSON.parse(filters);
        state.isFilterSaved = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFactoryBcnFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryBcnFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFactoryBcnFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) => state.factoryBcnFilters.filters;
export const isLoading = (state) =>
  state.factoryBcnFilters.requestStatus === 'pending';
export const hasError = (state) =>
  state.factoryBcnFilters.requestStatus === 'rejected';
export const getFilterSearch = (state) => state.factoryBcnFilters.search;
export const getSelectedFilters = (state) =>
  state.factoryBcnFilters.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.factoryBcnFilters.isFilterSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.factoryBcnFilters.selectedSubHeaderFilters).some(
    (key) => state.factoryBcnFilters.selectedSubHeaderFilters[key].length
  );

export const {
  updateFilterSearch,
  updateSelectedMRTWarehouses,
  updateSelectedBCNWarehouses,
  updateSelectedLines,
  updateSelectedModels,
  resetFilters,
  saveFilters,
  loadFilters
} = factoryBcnFiltersSlice.actions;

export default factoryBcnFiltersSlice.reducer;
