import { MODELS_DICTIONARY } from 'modules/outbound/stock/services/modelsDictionary';
import { IWarehouseStockByModelDTO } from 'modules/outbound/stock/infrastructure/dto/WarehouseStockByModelDTO';

export interface IWarehouseStockByModel {
  model: string;
  totalCars: number;
  warehousePercentage: number;
  pastSalesPercentage: number;
  image: { src: string; alt: string };
}
interface GenerateFromDTOParams extends IWarehouseStockByModelDTO {
  totalPastSales: number;
  warehouseTotalStock: number;
}

class WarehouseStockByModel implements IWarehouseStockByModel {
  model: string;
  totalCars: number;
  warehousePercentage: number;
  pastSalesPercentage: number;
  image: { src: string; alt: string };

  constructor({
    model,
    totalCars,
    warehousePercentage,
    pastSalesPercentage,
    image
  }: WarehouseStockByModel) {
    this.model = model;
    this.totalCars = totalCars;
    this.warehousePercentage = warehousePercentage;
    this.pastSalesPercentage = pastSalesPercentage;
    this.image = image;
  }

  static generateFromDTO({
    model,
    totalCars,
    warehouseTotalStock,
    pastSales,
    totalPastSales
  }: GenerateFromDTOParams): WarehouseStockByModel {
    return new WarehouseStockByModel({
      model: MODELS_DICTIONARY[model]?.name || model,
      totalCars,
      warehousePercentage: warehouseTotalStock
        ? Math.round((totalCars / warehouseTotalStock) * 100)
        : 0,
      pastSalesPercentage: totalPastSales
        ? Math.round((pastSales / totalPastSales) * 100)
        : 0,
      image: {
        src: WarehouseStockByModel.getImageSrcFromModel(model),
        alt: MODELS_DICTIONARY[model]?.name
      }
    });
  }

  static generateEmpty() {
    return new WarehouseStockByModel({
      model: '',
      totalCars: 0,
      warehousePercentage: 0,
      pastSalesPercentage: 0,
      image: { src: '', alt: '' }
    });
  }

  private static getImageSrcFromModel(model: string) {
    const name = MODELS_DICTIONARY[model]?.imageName || 'backup';
    return `${process.env.PUBLIC_URL}/images/cars/${name}.png`;
  }
}

export default WarehouseStockByModel;
