import { createAsyncThunk } from '@reduxjs/toolkit';
import getFiltersQueryString, {
  cleanUpQueryDuplicatesAndGetString
} from 'modules/inbound/shared/domain/GetFiltersQuery';
import {
  FiltersItem,
  NameValues
} from 'modules/inbound/shared/domain/NameValues';
import GetContainerNeedsUseCase from 'modules/inbound/transfer/application/GetContainerNeedsUseCase';
import { getCurrentPage, getResultsPerPage } from '.';
import { getSelectedFilters } from '../TransferReducer';

export const getContainerNeedsData = createAsyncThunk(
  'containerNeedsData/getContainerNeedsData',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses,
      selectedSupplyTypes
    } = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [];

    queryFilters.push({
      name: NameValues.BASIC_CONTAINER,
      values: selectedBasicContainers
    });
    queryFilters.push({
      name: NameValues.SUPPLY_CONTAINER,
      values: selectedSupplyContainers
    });
    queryFilters.push({
      name: NameValues.WAREHOUSE,
      values: selectedWarehouses
    });
    queryFilters.push({
      name: NameValues.SUPPLY_TYPE,
      values: selectedSupplyTypes
    });

    let querySearch: string[] = [];
    querySearch = getFiltersQueryString(queryFilters);

    const queryParams = {
      page: currentPage,
      size,
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };

    const response = await GetContainerNeedsUseCase.execute(
      {
        isNextHours: false
      },
      { queryParams }
    );
    return response;
  }
);

export const getContainerNeedsDataNextHours = createAsyncThunk(
  'containerNeedsData/getContainerNeedsDataNextHours',
  async (_, { getState }) => {
    const appState = getState();
    const currentPage = getCurrentPage(appState);
    const size = getResultsPerPage(appState);
    const {
      selectedBasicContainers,
      selectedSupplyContainers,
      selectedWarehouses,
      selectedSupplyTypes
    } = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [];

    queryFilters.push({
      name: NameValues.BASIC_CONTAINER,
      values: selectedBasicContainers
    });
    queryFilters.push({
      name: NameValues.SUPPLY_CONTAINER,
      values: selectedSupplyContainers
    });
    queryFilters.push({
      name: NameValues.WAREHOUSE,
      values: selectedWarehouses
    });
    queryFilters.push({
      name: NameValues.SUPPLY_TYPE,
      values: selectedSupplyTypes
    });

    let querySearch: string[] = [];
    querySearch = getFiltersQueryString(queryFilters);

    const queryParams = {
      page: currentPage,
      size,
      search:
        !!querySearch.length &&
        `{${cleanUpQueryDuplicatesAndGetString(querySearch)}}`
    };

    const response = await GetContainerNeedsUseCase.execute(
      {
        isNextHours: true
      },
      { queryParams }
    );
    return response;
  }
);
