import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface ExpirationDataDTO {
  last_update: string;
  expirations: ExpirationDataItemDTO[];
  pagination: IPaginationDTO;
}

interface ProviderItem {
  name: string;
  percentage_assignement: number;
}

export enum ExpirationStatusType {
  EXPIRED = 'EXPIRED',
  RISK_OF_EXPIRATION = 'RISK_OF_EXPIRATION',
  NOT_EXPIRED = 'NOT_EXPIRED'
}

interface ProviderDeliveryNote {
  code: string;
  date: string;
}

export interface ExpirationDataItemDTO {
  uid: string;
  expiration_status: ExpirationStatusType;
  code: string;
  name: string;
  handling_unit: string;
  quantity: number;
  warehouse: string;
  expiration_date: string;
  measurement_unit: string;
  stock_cost: number;
  warehouse_type: string;
  location: string;
  stock_differentiation: string;
  models: string[];
  days_for_expiration: number;
  inbounder: string;
  providers: ProviderItem[];
  supply_points: number;
  coverage: number;
  provider_delivery_note: ProviderDeliveryNote;
}

const expirationsDataDTOMapper = (data: any): ExpirationDataDTO => ({
  last_update: data.last_update || null,
  expirations:
    data.expirations?.map((item: any) => ({
      uid: item.uid,
      expiration_status: item.expiration_status,
      code: item.code,
      name: item.name,
      handling_unit: item.handling_unit,
      quantity: item.quantity,
      warehouse: item.warehouse,
      expiration_date: item.expiration_date,
      measurement_unit: item.measurement_unit,
      stock_cost: item.stock_cost,
      warehouse_type: item.warehouse_type,
      location: item.location,
      stock_differentiation: item.stock_differentiation,
      models: item.models,
      days_for_expiration: item.days_for_expiration,
      inbounder: item.inbounder,
      providers: item.providers,
      supply_points: item.supply_points,
      coverage: item.coverage,
      provider_delivery_note: {
        code: item.provider_delivery_note?.code || '-',
        date: item.provider_delivery_note?.date || null
      }
    })) || [],
  pagination: {
    page: data.pagination?.page || 1,
    page_count: data.pagination?.page_count || 0,
    size: data.pagination?.size || 50,
    total_count: data.pagination?.total_count || 0
  }
});

export { expirationsDataDTOMapper };
