import Button from '../../Buttons/Button';
import { ReactComponent as SeeIcon } from 'assets/icons/seeIcon.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hideIcon.svg';

export const PasswordVisibilityToggle = ({
  isPasswordVisible,
  onVisiblePasswordClick
}: {
  isPasswordVisible: boolean;
  onVisiblePasswordClick: () => void;
}) => {
  return (
    <Button
      type="button"
      onClick={onVisiblePasswordClick}
      className="basic password-visibility-toggle"
    >
      {isPasswordVisible ? <SeeIcon /> : <HideIcon />}
    </Button>
  );
};
