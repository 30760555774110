import { createSlice } from '@reduxjs/toolkit';
import { EntriesInPlantFilters } from 'modules/inbound/entries/domain/InPlant/EntriesInPlantFilters';
import { getEntriesInPlantFilters } from './async';

import { FiltersState } from './types';

export const initialState: FiltersState = {
  filters: EntriesInPlantFilters.generateEmpty(),
  requestStatus: ''
};

const EntriesInPlantFiltersSlice = createSlice({
  name: 'entriesInPlantFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEntriesInPlantFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesInPlantFilters.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesInPlantFilters.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getAllFilters = (state) =>
  state.entriesInPlantFiltersState.filters;

export default EntriesInPlantFiltersSlice.reducer;
