import ISuppliesRepository from '../domain/ISuppliesRepository';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetSuppliesCriticalStockUseCase {
  private _suppliesRepository: ISuppliesRepository;

  constructor({ suppliesRepository }) {
    this._suppliesRepository = suppliesRepository;
  }

  execute() {
    return this._suppliesRepository.getCriticalStock();
  }
}

const getSuppliesCriticalStockUseCase = new GetSuppliesCriticalStockUseCase({
  suppliesRepository: suppliesRepository
});

export { getSuppliesCriticalStockUseCase, GetSuppliesCriticalStockUseCase };
