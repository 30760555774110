export interface EntriesFiltersDTO {
  warehouses: string[];
  supplier_names: string[];
  unloading_points: string[];
}

class EntriesFiltersDataDTO {
  warehouses: string[];
  supplier_names: string[];
  unloading_points: string[];

  constructor({ warehouses, supplier_names, unloading_points }) {
    this.warehouses = warehouses;
    this.supplier_names = supplier_names;
    this.unloading_points = unloading_points;
  }
}

export default EntriesFiltersDataDTO;
