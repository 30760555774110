import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import IncomingMaterialsDTO, {
  IIncomingMaterialItemDTO
} from '../../infrastructure/dto/NextHoursDTO/IncomingMaterialsDTO';

export interface IIncomingMaterials {
  nextEntries: IIncomingMaterialsItem[];
}

export interface IIncomingMaterialsItem {
  utcDate: string;
  date: string;
  hours: string;
  amount: number;
}

class IncomingMaterials implements IIncomingMaterials {
  nextEntries: IIncomingMaterialsItem[];

  constructor({ nextEntries }: IncomingMaterials) {
    this.nextEntries = nextEntries;
  }

  static generateFromDTO({
    next_entries
  }: IncomingMaterialsDTO): IncomingMaterials {
    return new IncomingMaterials({
      nextEntries: next_entries.map(
        ({
          date,
          amount
        }: IIncomingMaterialItemDTO): IIncomingMaterialsItem => ({
          utcDate: date,
          date: GetDateFormatedService.getMonthDayFormated(date, 'es'),
          hours: GetDateFormatedService.getTimeFormated(date),
          amount: amount
        })
      )
    });
  }

  static generateEmpty() {
    return new IncomingMaterials({
      nextEntries: []
    });
  }
}

export default IncomingMaterials;
