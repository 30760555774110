import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundRoutes } from 'infrastructure/routes/types';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import { MastersText } from 'modules/inbound/shared/infraestructure/i18n/mastersText';
import { MastersTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';

const mastersPanelTranslations: MastersTranslations = {
  ...MastersText[LocaleService.getLanguage()]
};

export const LAST_UPDATE = 'LastUpdate';

export enum MastersPanelKeys {
  MASTER_GROUP_LINE = 'master_group_line',
  MASTER_MODEL_LINE_CALENDAR = 'master_model_line_calendar',
  MASTER_MODEL_BODYWORK_ZF = 'master_model_bodywork_zf',
  MASTER_BODYWORK_PROGRAM = 'master_bodywork_program',
  MASTER_PIECE_WAREHOUSE_ZF = 'master_piece_warehouse_zf',
  MASTER_BODYSHOP_LINE_CALENDAR = 'master_bodyshop_line_calendar',
  MASTER_JIT = 'master_jit',
  MASTER_OLD_WAREHOUSE_CAPACITY = 'master_old_warehouse_capacity',
  MASTER_MODEL_LINE_ZF = 'master_model_line_zf',
  MASTER_TACTO_TIME = 'master_tacto_time',
  MASTER_WAREHOUSE_LINE_ZF = 'master_warehouse_line_zf'
}

export enum MastersDownloadNames {
  master_model_line_calendar = 'Calendario',
  master_piece_warehouse_zf = 'ClaveTallerFactoryBCN',
  master_model_bodywork_zf = 'FactoryBarcelona',
  master_jit = 'Maestro_JIT',
  master_bodywork_program = 'Programa_Chapa_DD',
  master_bodyshop_line_calendar = 'Calendario_Chapa',
  master_bodyshop_warehouse_locations = 'Almacenes_Ubicaciones_Chapisteria',
  master_group_line = 'GrupoLinea',
  master_model_line_zf = 'ModeloLinea',
  master_tacto_time = 'TiempoTacto',
  master_warehouse_line_zf = 'AlmacenLineasZF'
}

export enum ManageMastersUrls {
  MASTER_CALENDAR = 'calendar',
  MASTER_CODE_WORKSHOP = 'piece-warehouse-zf',
  MASTER_FACTORY_BCN = 'factory-bcn',
  MASTER_LINE_GROUPS = 'group-line',
  MASTER_JIT = 'masters_jit',
  MASTER_BODYSHOP_DD = 'model-bodywork',
  MASTER_BODYSHOP_CALENDAR = 'bodyshop-calendar',
  MASTER_MODEL_LINE_ZF = 'model-line-zf',
  MASTER_TACTO_TIME = 'tacto-time',
  MASTER_WAREHOUSE_LINE_ZF = 'warehouse-line-zf'
}

export interface MastersPanel {
  key: MastersPanelKeys;
  name: string;
  lastUpdate: string;
  redirectUrl?: InboundRoutes;
  downloadable: boolean;
  updatable: boolean;
  manageMasterUrl?: ManageMastersUrls;
}

export const MASTERS_PANEL_LIST: MastersPanel[] = [
  {
    key: MastersPanelKeys.MASTER_MODEL_LINE_CALENDAR,
    name: mastersPanelTranslations.masterModelLineCalendar,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_CALENDAR
  },
  {
    key: MastersPanelKeys.MASTER_OLD_WAREHOUSE_CAPACITY,
    name: mastersPanelTranslations.masterOldWarehouseCapacity,
    lastUpdate: NO_DATA,
    redirectUrl: InboundRoutes.MASTER_WAREHOUSE_CAPACITY,
    downloadable: false,
    updatable: false
  },
  {
    key: MastersPanelKeys.MASTER_PIECE_WAREHOUSE_ZF,
    name: mastersPanelTranslations.masterPieceWarehouseZf,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_CODE_WORKSHOP
  },
  {
    key: MastersPanelKeys.MASTER_MODEL_BODYWORK_ZF,
    name: mastersPanelTranslations.masterModelBodyworkZf,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_FACTORY_BCN
  },
  {
    key: MastersPanelKeys.MASTER_GROUP_LINE,
    name: mastersPanelTranslations.masterGroupLine,
    lastUpdate: NO_DATA,
    updatable: true,
    downloadable: true,
    redirectUrl: InboundRoutes.MASTER_LINE_GROUPS,
    manageMasterUrl: ManageMastersUrls.MASTER_LINE_GROUPS
  },
  {
    key: MastersPanelKeys.MASTER_JIT,
    name: mastersPanelTranslations.masterJit,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_JIT
  },
  {
    key: MastersPanelKeys.MASTER_BODYWORK_PROGRAM,
    name: mastersPanelTranslations.masterBodyworkProgram,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_BODYSHOP_DD
  },
  {
    key: MastersPanelKeys.MASTER_BODYSHOP_LINE_CALENDAR,
    name: mastersPanelTranslations.masterBodyshopLineCalendar,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_BODYSHOP_CALENDAR
  },
  {
    key: MastersPanelKeys.MASTER_MODEL_LINE_ZF,
    name: mastersPanelTranslations.masterModelLineZf,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_MODEL_LINE_ZF
  },
  {
    key: MastersPanelKeys.MASTER_TACTO_TIME,
    name: mastersPanelTranslations.masterTactoTime,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_TACTO_TIME
  },
  {
    key: MastersPanelKeys.MASTER_WAREHOUSE_LINE_ZF,
    name: mastersPanelTranslations.masterWarehouseLineZf,
    lastUpdate: NO_DATA,
    downloadable: true,
    updatable: true,
    manageMasterUrl: ManageMastersUrls.MASTER_WAREHOUSE_LINE_ZF
  }
];
