import * as React from 'react';
import { CLASS_FOR_LAZYLOADING, ImageProps } from '../typing';

const Image: React.FunctionComponent<ImageProps> = (props: ImageProps) => {
  const { src, alt, lazyloaded } = props;
  const imgClasses: string[] = [];

  if (lazyloaded) {
    imgClasses.push(CLASS_FOR_LAZYLOADING);
  }

  return <img className={imgClasses.join(' ')} src={src} alt={alt} />;
};

export default Image;
