import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

const WrapperFormInput = styled.div<{ isFilled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 8px;
  margin-bottom: 1.2rem;

  label {
    font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fonts.regularSmall.minSizeAsPixels};
    display: block;
  }

  input {
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid
      ${({ theme, isFilled }: { theme: ThemeSEATProps; isFilled: boolean }) =>
        isFilled ? theme.colors.black.hexa : theme.colors.neutral50.hexa};
    border-radius: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.borders.small};
    outline: unset;

    :focus {
      border-color: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.colors.orange.hexa};
    }

    &::placeholder {
      color: ${({ theme }: { theme: ThemeSEATProps }) =>
        theme.colors.neutral50.hexa};
    }
  }

  span {
    position: absolute;
    bottom: -1.2rem;
    font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
      theme.fonts.regularSmall.minSizeAsPixels};
    color: ${({ theme }: { theme: ThemeSEATProps }) => theme.colors.red.rgb};
  }

  .password-visibility-toggle {
    position: absolute;
    right: 0;
    top: 35%;

    .fillable {
      width: 16px;
      height: 16px;
      fill: ${({
        theme,
        isFilled
      }: {
        theme: ThemeSEATProps;
        isFilled: boolean;
      }) => (isFilled ? theme.colors.black.hexa : theme.colors.neutral50.hexa)};
    }
  }
`;

export { WrapperFormInput };
