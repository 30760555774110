import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnInfo } from 'modules/inbound/shared/domain/ColumnsTypes';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { EntriesTranslations } from '../../infrastructure/i18n/typing';
import { EntriesText } from '../../infrastructure/i18n/EntriesText';
import { IEntriesInPlantDataItemDTO } from '../../infrastructure/dto/InPlantDTO/EntriesInPlantDataDTO';

const entriesTranslations: EntriesTranslations & InboundTranslations = {
  ...EntriesText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export const EntriesInPlantColumnKeys = {
  pieceAlert: 'pieceAlert',
  plateNumber: 'plateNumber',
  stopType: 'stopType',
  transportId: 'transportId',
  carrier: 'carrier',
  supplierName: 'supplierName',
  windowBooked: 'windowBooked',
  entryTime: 'entryTime',
  globalDlz: 'globalDlz',
  stops: 'stops',
  lastPosition: 'lastPosition',
  mostCriticalPieceCoverage: 'mostCriticalPieceCoverage',
  packages: 'packages'
} as const;

type TColumnKeys =
  (typeof EntriesInPlantColumnKeys)[keyof typeof EntriesInPlantColumnKeys];

const SORTABLE_COLUMNS: TColumnKeys[] = [
  'windowBooked',
  'entryTime',
  'stops',
  'mostCriticalPieceCoverage',
  'packages',
  'globalDlz'
];

const RIGHT_ALIGNED_COLUMNS: TColumnKeys[] = [
  'mostCriticalPieceCoverage',
  'packages'
];

export interface EntriesInPlantColumns extends ColumnInfo {
  key: keyof IEntriesInPlantDataItemDTO | 'piece_alert';
  uiKey: TColumnKeys;
  isVisibleCheck: boolean;
}

const getColumnAlignment = (
  column: TColumnKeys
): EntriesInPlantColumns['align'] => {
  return RIGHT_ALIGNED_COLUMNS.includes(column) ? 'right' : 'left';
};

export const ENTRIES_IN_PLANT_COLUMNS: EntriesInPlantColumns[] = [
  {
    key: 'piece_alert',
    uiKey: 'pieceAlert',
    value: '',
    sortable: SORTABLE_COLUMNS.includes('pieceAlert'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('pieceAlert')
  },
  {
    key: 'plate_number',
    uiKey: 'plateNumber',
    value: entriesTranslations.plateNumber,
    sortable: SORTABLE_COLUMNS.includes('plateNumber'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('plateNumber')
  },
  {
    key: 'stop_type',
    uiKey: 'stopType',
    value: entriesTranslations.stopType,
    sortable: SORTABLE_COLUMNS.includes('stopType'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('stopType')
  },
  {
    key: 'transport_id',
    uiKey: 'transportId',
    value: entriesTranslations.transportId,
    sortable: SORTABLE_COLUMNS.includes('transportId'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('transportId')
  },
  {
    key: 'carrier',
    uiKey: 'carrier',
    value: entriesTranslations.carrier,
    sortable: SORTABLE_COLUMNS.includes('carrier'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('carrier')
  },
  {
    key: 'supplier',
    uiKey: 'supplierName',
    value: entriesTranslations.supplierName,
    sortable: SORTABLE_COLUMNS.includes('supplierName'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('supplierName')
  },
  {
    key: 'window_booked',
    uiKey: 'windowBooked',
    value: entriesTranslations.windowBooked,
    sortable: SORTABLE_COLUMNS.includes('windowBooked'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('windowBooked')
  },
  {
    key: 'entry_time',
    uiKey: 'entryTime',
    value: entriesTranslations.entryTime,
    sortable: SORTABLE_COLUMNS.includes('entryTime'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('entryTime'),
    tooltipInfo: entriesTranslations.checkInTimeAtTCC
  },
  {
    key: 'global_dlz',
    uiKey: 'globalDlz',
    value: entriesTranslations.globalDlz,
    sortable: SORTABLE_COLUMNS.includes('globalDlz'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('globalDlz')
  },
  {
    key: 'stops',
    uiKey: 'stops',
    value: entriesTranslations.stops,
    sortable: SORTABLE_COLUMNS.includes('stops'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('stops')
  },
  {
    key: 'last_position',
    uiKey: 'lastPosition',
    value: entriesTranslations.lastPosition,
    sortable: SORTABLE_COLUMNS.includes('lastPosition'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('lastPosition')
  },
  {
    key: 'most_critical_piece_coverage',
    uiKey: 'mostCriticalPieceCoverage',
    value: entriesTranslations.mostCriticalPieceCoverage,
    sortable: SORTABLE_COLUMNS.includes('mostCriticalPieceCoverage'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('mostCriticalPieceCoverage')
  },
  {
    key: 'packages',
    uiKey: 'packages',
    value: entriesTranslations.numberOfContainers,
    sortable: SORTABLE_COLUMNS.includes('packages'),
    isVisibleCheck: true,
    draggable: true,
    align: getColumnAlignment('packages')
  }
];
