export enum StatusPlanColors {
  BLUE = 'blue',
  PURPLE = 'purple',
  ORANGE = 'orange',
  WHITE = 'white',
  YELLOW = 'yellow',
  RED = 'red',
  GREEN = 'green'
}

export enum StatusPlanType {
  TransportPending = 'TP+',
  TransportInTransit = 'TR+',
  TransportInPlant = 'EP+',
  MaterialResourcePlan = 'MRP'
}
