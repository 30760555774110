import { UnloadingPoint } from 'modules/inbound/entries/domain/UnloadingPoints/UnloadingPoint';
import { UnloadingPointEntry } from 'modules/inbound/entries/domain/UnloadingPoints/UnloadingPointEntry';
import { IUnloadingPointPiece } from 'modules/inbound/entries/domain/UnloadingPoints/UnloadingPointPiece';
import { Shift } from 'modules/inbound/entries/infrastructure/dto/UnloadingPointsDTO/UnloadingPointDTO';
import { EntriesTranslations } from 'modules/inbound/entries/infrastructure/i18n/typing';

type ShiftTranslations = {
  [key in Shift]: keyof EntriesTranslations;
};

export const SHIFT_TRANSLATIONS: ShiftTranslations = {
  M: 'morningShift',
  T: 'afternoonShift',
  N: 'nightShift'
};

export interface SelectedUnloadingPointSlot {
  time: string;
  date: string;
  unloadingPoint: string;
}

export interface SaturationUnloadingPointsState {
  saturation: UnloadingPoint[];
  unloadingPointsRequestStatus: string;
  entriesBySlotAndUnladingPoint: UnloadingPointEntry[];
  piecesByEntryAndUnladingPoint: IUnloadingPointPiece[];
  entriesBySlotAndUnladingPointRequestStatus: string;
  piecesByEntryAndUnladingPointRequestStatus: string;
  selectedUnloadingPointSlot: SelectedUnloadingPointSlot;
  selectedTransportId: number | null;
  unloadinPointsWithPackagesChartSelected: string[];
}
