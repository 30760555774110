import { createAsyncThunk } from '@reduxjs/toolkit';
import deleteInboundMessageUseCase from 'modules/inbound/shared/application/InboundMessagesUseCases/DeleteInboundMessageUseCase';
import GetInboundMessagesUseCase from 'modules/inbound/shared/application/InboundMessagesUseCases/GetInboundMessagesUseCase';
import PostInboundMessageUseCase from 'modules/inbound/shared/application/InboundMessagesUseCases/PostInboundMessageUseCase';
import {
  getCurrentSelectedPanel,
  getPostMessageToSend,
  getSelectedMessageUid
} from '.';

export const getInboundMessagesByUids = createAsyncThunk(
  'inboundMessages/getInboundMessagesByUids',
  async (_, { getState }) => {
    const appState = getState();
    const selectedCurrentPanel = getCurrentSelectedPanel(appState);
    const response = await GetInboundMessagesUseCase.execute({
      body: {
        target_type: selectedCurrentPanel.targetType,
        target_uids: selectedCurrentPanel.targetUids
      }
    });
    return response;
  }
);

export const postInboundNewMessage = createAsyncThunk(
  'inboundMessages/postInboundNewMessage',
  async (_, { getState }) => {
    const appState = getState();
    const newMessage = getPostMessageToSend(appState);
    const response = await PostInboundMessageUseCase.execute({
      body: {
        target_type: newMessage.targetType,
        target_uid: newMessage.targetUid,
        content: newMessage.content
      }
    });

    return response;
  }
);

export const deleteInboundMessage = createAsyncThunk(
  'inboundMessages/deleteInboundMessage',
  async (_, { getState }) => {
    const appState = getState();
    const selectedMessageUid = getSelectedMessageUid(appState);
    const response = await deleteInboundMessageUseCase.execute(
      selectedMessageUid
    );
    return response;
  }
);
