const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const EXPIRATION_ENDPOINT = '/handling-units/expirations';
const API_GET_EXPIRATION_STOCK = `${INBOUND_BASE_URL}${EXPIRATION_ENDPOINT}`;
const API_GET_EXPIRATION_FILTERS = `${INBOUND_BASE_URL}${EXPIRATION_ENDPOINT}/filters`;
const API_GET_HANDLING_UNITS = `${INBOUND_BASE_URL}${EXPIRATION_ENDPOINT}/summary`;
const API_GET_EXPIRATIONS_DOWNLOAD = `${INBOUND_BASE_URL}${EXPIRATION_ENDPOINT}/download`;
export {
  API_GET_EXPIRATION_STOCK,
  API_GET_EXPIRATION_FILTERS,
  API_GET_HANDLING_UNITS,
  API_GET_EXPIRATIONS_DOWNLOAD
};
