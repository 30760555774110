import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IDataResourcesRepository from 'modules/outbound/shared/domain/IDataResourcesRepository';
import { API_GET_LAST_RECEIVED_FILE } from './urls';

class DataResourcesRepository
  extends BaseRepository
  implements IDataResourcesRepository
{
  private _api: IApi;
  private _apilastReceivedFileUrl: string;

  constructor({ api, apilastReceivedFileUrl }) {
    super();
    this._api = api;
    this._apilastReceivedFileUrl = apilastReceivedFileUrl;
  }

  getLastReceivedHifaFileDate() {
    return this._api
      .get(`${this._apilastReceivedFileUrl}/hifa`)
      .then((res) => new Date(res.last_update_date));
  }
}

export default new DataResourcesRepository({
  api: api,
  apilastReceivedFileUrl: API_GET_LAST_RECEIVED_FILE
});

export { DataResourcesRepository };
