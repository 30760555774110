export const getUniqueId = (): string => {
  let uuidFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  const uuid = uuidFormat.replace(/[xy]/g, (character) => {
    const randomIntegerNumber = (Math.random() * 16) | 0;
    const newCharacter =
      character === 'x'
        ? randomIntegerNumber
        : (randomIntegerNumber & 0x3) | 0x8;
    return newCharacter.toString(16);
  });

  return uuid;
};
