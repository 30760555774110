import { ContactListResponseDTO } from '../../infraestructure/dto/providerContactListDTOMapper';
import { CONTACT_ROLE_LIST } from './ContactRole';

export const getRoleText = (keyRol) => {
  const role = CONTACT_ROLE_LIST.find((role) => role.key === keyRol);
  return role?.text;
};

export interface Contact {
  id?: string;
  name: string;
  role: string;
  telephone: string;
  email: string;
}

class ProviderContactList {
  id: number;
  name: string;
  contacts: Contact[];

  constructor({ id, name, contacts }: ProviderContactList) {
    this.id = id;
    this.name = name;
    this.contacts = contacts;
  }

  static generateFromDTO({
    id,
    name,
    contacts
  }: ContactListResponseDTO): ProviderContactList {
    let formatContacts: Contact[] = [];

    contacts.forEach((contact) => {
      formatContacts.push({
        id: contact.id,
        name: contact.name,
        role: contact.role,
        telephone: contact.telephone ?? '-',
        email: contact.email ?? '-'
      });
    });
    return new ProviderContactList({
      id,
      name,
      contacts: formatContacts
    });
  }
}

export default ProviderContactList;
