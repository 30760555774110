import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';

class TransitFulfillmentCheckpointDTO {
  name: string;
  percentage: number;
  type: CheckpointType;

  constructor({ name, type, percentage }) {
    this.name = name;
    this.type = type;
    this.percentage = percentage;
  }
}

export default TransitFulfillmentCheckpointDTO;
