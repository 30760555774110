import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { TableColumnProps } from 'modules/inbound/shared/ui/layout/components/Table/types';
import {
  EntryDetailDTO,
  PieceDTO
} from '../../infrastructure/dto/NextHoursDTO/entryDetailDTOMapper';
import { EntriesText } from '../../infrastructure/i18n/EntriesText';
import { EntriesTranslations } from '../../infrastructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { EntryDTO } from '../../infrastructure/dto/NextHoursDTO/EntryDTO';
import { Entry } from './EntryData';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

const entriesTranslations: EntriesTranslations & InboundTranslations = {
  ...EntriesText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export interface IEntry
  extends Pick<
    Entry,
    | 'plateNumber'
    | 'transportId'
    | 'carrier'
    | 'stops'
    | 'windowBooked'
    | 'packages'
  > {
  hasCriticalPieces: boolean;
}

export interface IPiece {
  isCritical: boolean;
  code: string;
  coverage: string;
  warehouse: string;
  packages: number;
  containerType: string;
  container: string;
}

interface EntriesDetailTransportsColumns extends TableColumnProps {
  key: keyof EntryDTO;
  uiKey: keyof IEntry;
}

interface EntriesDetailPiecesColumns extends TableColumnProps {
  key: keyof PieceDTO;
  uiKey: keyof IPiece;
}

export const ENTRIES_DETAIL_COLUMNS: EntriesDetailTransportsColumns[] = [
  {
    key: 'has_critical_pieces',
    uiKey: 'hasCriticalPieces',
    value: '',
    isVisible: true,
    align: 'left'
  },
  {
    key: 'plate_number',
    uiKey: 'plateNumber',
    value: entriesTranslations.plateNumber,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'transport_id',
    uiKey: 'transportId',
    value: entriesTranslations.transportId,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'carrier',
    uiKey: 'carrier',
    value: entriesTranslations.carrier,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'stops',
    uiKey: 'stops',
    value: entriesTranslations.stops,
    isVisible: true,
    align: 'right'
  },
  {
    key: 'window_booked',
    uiKey: 'windowBooked',
    value: entriesTranslations.windowBooked,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'packages',
    uiKey: 'packages',
    value: entriesTranslations.numberOfContainers,
    isVisible: true,
    align: 'right'
  }
];

export const ENTRIES_DETAIL_PIECES_COLUMNS: EntriesDetailPiecesColumns[] = [
  {
    key: 'is_critical',
    uiKey: 'isCritical',
    value: '',
    isVisible: true,
    align: 'left'
  },
  {
    key: 'code',
    uiKey: 'code',
    value: entriesTranslations.code,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'coverage',
    uiKey: 'coverage',
    value: entriesTranslations.coverage,
    isVisible: true,
    align: 'right'
  },
  {
    key: 'warehouse',
    uiKey: 'warehouse',
    value: entriesTranslations.warehouse,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'packages',
    uiKey: 'packages',
    value: entriesTranslations.numberOfContainers,
    isVisible: true,
    align: 'right'
  },
  {
    key: 'container_type',
    uiKey: 'containerType',
    value: entriesTranslations.containerType,
    isVisible: true,
    align: 'left'
  },
  {
    key: 'container',
    uiKey: 'container',
    value: entriesTranslations.container,
    isVisible: true,
    align: 'left'
  }
];

class EntryDetail {
  entryColumns: EntriesDetailTransportsColumns[];
  pieces: IPiece[];
  piecesColumns: EntriesDetailPiecesColumns[];

  constructor({ entryColumns, pieces, piecesColumns }: EntryDetail) {
    this.entryColumns = entryColumns;
    this.pieces = pieces;
    this.piecesColumns = piecesColumns;
  }

  private static _formatThousandsDotDays(quantity) {
    if (quantity === null || quantity === undefined) {
      return NO_DATA;
    }
    const numberWithDots = quantity.toLocaleString('de-DE', {
      maximumFractionDigits: 2
    });

    if (numberWithDots === '1') {
      return `${numberWithDots} ${entriesTranslations.unitSingleDay}`;
    }
    return `${numberWithDots} ${entriesTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({ pieces }: EntryDetailDTO): EntryDetail {
    return new EntryDetail({
      entryColumns: ENTRIES_DETAIL_COLUMNS,
      pieces: pieces.map((piece) => ({
        isCritical: piece.is_critical ?? false,
        code: piece.code,
        coverage: this._formatThousandsDotDays(piece.coverage),
        warehouse: piece.warehouse,
        packages: piece.packages,
        containerType: piece.container_type || NO_DATA,
        container: piece.container ? piece.container : NO_DATA
      })),
      piecesColumns: ENTRIES_DETAIL_PIECES_COLUMNS
    });
  }

  static generateEmpty() {
    return new EntryDetail({
      entryColumns: [],
      pieces: [],
      piecesColumns: []
    });
  }
}

export default EntryDetail;
