import { ManageMastersUrls } from '../../domain/MastersPanel';

const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const MASTER = '/master/';
const INFO = 'info';
const RESPONSIBLE = 'master-responsible';
const API_GET_MASTER_INFO = `${INBOUND_BASE_URL}${MASTER}${INFO}`;
const LINE_GROUPS_ENDPOINT = `${INBOUND_BASE_URL}/master/group-line`;
const LINE_GROUPS_BODY_ENDPOINT = `${LINE_GROUPS_ENDPOINT}/data`;
const WAREHOUSE_CAPACITY_ENDPOINT = `${INBOUND_BASE_URL}/master/warehouse-max-capacity`;
const WAREHOUSE_CAPACITY_BODY_ENDPOINT = `${WAREHOUSE_CAPACITY_ENDPOINT}/data`;
const API_MANAGE_MASTER = (manageMasterUrl: ManageMastersUrls) =>
  `${INBOUND_BASE_URL}${MASTER}${manageMasterUrl}`;
const API_PUT_MASTER_RESPONSIBLE = `${INBOUND_BASE_URL}${MASTER}${RESPONSIBLE}`;

export {
  LINE_GROUPS_ENDPOINT,
  LINE_GROUPS_BODY_ENDPOINT,
  WAREHOUSE_CAPACITY_ENDPOINT,
  WAREHOUSE_CAPACITY_BODY_ENDPOINT,
  API_MANAGE_MASTER,
  API_GET_MASTER_INFO,
  API_PUT_MASTER_RESPONSIBLE
};
