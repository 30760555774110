import React, { forwardRef, useState } from 'react';
import { PasswordVisibilityToggle } from './PasswordVisibilityToggle';
import { WrapperFormInput } from './styles';
import { FormInputProps } from './types';

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      name,
      maxLength,
      type,
      onChange,
      value,
      error,
      placeholder,
      required = false,
      disabled = false,
      autoComplete
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const onVisiblePasswordClick = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <WrapperFormInput isFilled={!!value.length}>
        <label htmlFor={name}>{label}</label>
        <input
          ref={ref}
          type={type === 'password' && !isPasswordVisible ? 'password' : 'text'}
          name={name}
          maxLength={maxLength}
          id={name}
          value={value}
          placeholder={placeholder ?? label}
          onChange={onChange}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {type === 'password' && (
          <PasswordVisibilityToggle
            isPasswordVisible={isPasswordVisible}
            onVisiblePasswordClick={onVisiblePasswordClick}
          />
        )}
        {error && value && <span>{error}</span>}
      </WrapperFormInput>
    );
  }
);
