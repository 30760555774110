export interface IAgingIntervalsDTO {
  '0to90': number;
  '91to120': number;
  '121to180': number;
  moreThan180: number;
}

class PipelineStockDTO {
  model: string;
  brand: string;
  stockTotal: number;
  inTransitAging: {
    invoicedIntervalValues: IAgingIntervalsDTO;
    logisticIntervalValues: IAgingIntervalsDTO;
    totalIntervalValues: IAgingIntervalsDTO;
  };
  invoicedAging: {
    warehouseIntervalValues: IAgingIntervalsDTO;
    dealerIntervalValues: IAgingIntervalsDTO;
    restIntervalValues: IAgingIntervalsDTO;
    invoicedTotalIntervalValues: IAgingIntervalsDTO;
  };
  simpleCoverage: {
    invoiced: number;
    logistic: number;
    total: number;
  };

  constructor({
    model,
    brand,
    stockTotal,
    inTransitAging,
    invoicedAging,
    simpleCoverage
  }) {
    const {
      warehouseIntervalValues,
      dealerIntervalValues,
      restIntervalValues,
      totalIntervalValues
    } = invoicedAging;
    this.model = model;
    this.brand = brand;
    this.stockTotal = stockTotal;
    this.inTransitAging = inTransitAging;
    this.invoicedAging = {
      warehouseIntervalValues,
      dealerIntervalValues,
      restIntervalValues,
      invoicedTotalIntervalValues: totalIntervalValues
    };
    this.simpleCoverage = simpleCoverage;
  }
}

export default PipelineStockDTO;
