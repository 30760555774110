import EntriesInPlantData from '../../domain/InPlant/EntriesInPlantData';
import EntriesWarehouseDlzDetail from '../../domain/InPlant/EntriesWarehouseDlzDetail';
import EntryInPlantDetail from '../../domain/InPlant/EntriesInPlantDetail';
import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesInPlantDataUseCase {
  private _entriesInPlantDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesInPlantDataRepository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<EntriesInPlantData> {
    return this._entriesInPlantDataRepository
      .getEntriesInPlantStock({ queryParams })
      .then((res) => EntriesInPlantData.generateFromDTO(res));
  }

  getEntryWarehouseDlzDetail({
    transportId
  }: {
    transportId: number;
  }): Promise<EntriesWarehouseDlzDetail> {
    return this._entriesInPlantDataRepository
      .getEntryWarehouseDlzDetail({ transportId })
      .then((res) => EntriesWarehouseDlzDetail.generateFromDTO(res));
  }

  getDetailInPlant({
    transportId
  }: {
    transportId: number;
  }): Promise<EntryInPlantDetail> {
    return this._entriesInPlantDataRepository
      .getEntryInPlantDetail({ transportId })
      .then((res) => EntryInPlantDetail.generateFromDTO(res));
  }
}

export default new GetEntriesInPlantDataUseCase({
  repository: entriesRepository
});

export { GetEntriesInPlantDataUseCase };
