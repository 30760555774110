import ISuppliesRepository from '../domain/ISuppliesRepository';
import SuppliesStatus from '../domain/SuppliesStatus';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetSuppliesRequestsStatusService {
  private _suppliesRepository: ISuppliesRepository;

  constructor({ suppliesRepository }) {
    this._suppliesRepository = suppliesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._suppliesRepository
      .getRequestsStatus({ queryParams })
      .then((res) => SuppliesStatus.generateFromDTO(res));
  }
}

export default new GetSuppliesRequestsStatusService({
  suppliesRepository: suppliesRepository
});

export { GetSuppliesRequestsStatusService };
