class EndOfProductionFiltersDTO {
  provider_inbounders: string[];
  status: string[];
  warehouses_codes: string[];
  models: string[];

  constructor({ provider_inbounders, status, warehouses_codes, models }) {
    this.provider_inbounders = provider_inbounders;
    this.status = status;
    this.warehouses_codes = warehouses_codes;
    this.models = models;
  }
}

export default EndOfProductionFiltersDTO;
