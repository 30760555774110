import { createSlice } from '@reduxjs/toolkit';
import { SharedState } from './types';

export const initialState: SharedState = {
  isMenuOpened: false,
  activeMenuButton: '',
  activeSubItemMenuButton: ''
};

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setIsMenuOpened: (state, action) => {
      state.isMenuOpened = action.payload;
    },
    setActiveMenuButton: (state, action) => {
      if (state.activeMenuButton === action.payload) {
        state.activeMenuButton = initialState.activeMenuButton;
      } else {
        state.activeMenuButton = action.payload;
      }
    },
    setActiveSubItemMenuButton: (state, action) => {
      if (state.activeSubItemMenuButton === action.payload) {
        state.activeSubItemMenuButton = initialState.activeSubItemMenuButton;
      } else {
        state.activeSubItemMenuButton = action.payload;
      }
    }
  },
  extraReducers: () => {}
});

// actions
export const {
  setIsMenuOpened,
  setActiveMenuButton,
  setActiveSubItemMenuButton
} = sharedSlice.actions;

//selectors
export const getIsMenuOpened = (state) => state.sharedState.isMenuOpened;
export const getActiveMenuButton = (state) =>
  state.sharedState.activeMenuButton;
export const getActiveSubItemMenuButton = (state) =>
  state.sharedState.activeSubItemMenuButton;

export default sharedSlice.reducer;
