import { createAsyncThunk } from '@reduxjs/toolkit';
import transitFulfillmentService from 'modules/outbound/fulfillment/services/TransitFulfillmentService';
import transitFulfillmentCheckpointsByCountryService from 'modules/outbound/fulfillment/services/TransitFulfillmentCheckpointsByCountryService';
import routesByCountryService from 'modules/outbound/fulfillment/services/RoutesByCountryService';
import downloadFulfillmentFinishedGoodsService from 'modules/outbound/fulfillment/services/DownloadFulfillmentFinishedGoodsService';
import { getSelectedCountry } from 'modules/outbound/fulfillment/redux/ListAchievementsReducer';
import {
  getFastLaneSelected,
  getLogisticObjectiveSelected,
  getSelectedBrands,
  getSelectedClientTypeInPlainFormat,
  getSelectedCommercialPackagesInPlainFormat,
  getSelectedCountriesISO,
  getSelectedDateRangeFormated,
  getSelectedFactoryInPlainFormat,
  getVersandtreueSelected
} from '../FiltersStateReducer';
import getGlobalTransitFulfillmentUseCase from '../../application/GetGlobalTransitFulfillmentUseCase';
import { DateRangeType } from 'modules/outbound/shared/domain/GetDateRangeService';
import { BrandEnum } from 'modules/shared/domain/enums';

const getAchievements = createAsyncThunk(
  `achievement/getAchievements`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedClientType = getSelectedClientTypeInPlainFormat(appState);
    const selectedDateRange = getSelectedDateRangeFormated(appState);
    const fastLaneSelected = getFastLaneSelected(appState);
    const selectedFactory = getSelectedFactoryInPlainFormat(appState);
    const updateSelectedBrands = getSelectedBrands(appState);
    const logisticObjectiveSelected = getLogisticObjectiveSelected(appState);
    const commercialPackagesSelected =
      getSelectedCommercialPackagesInPlainFormat(appState);
    const versandtreueSelected = getVersandtreueSelected(appState);

    const queryParams = {
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: updateSelectedBrands,
      logisticObjective: logisticObjectiveSelected,
      commercialPackages: commercialPackagesSelected,
      versandtreue: versandtreueSelected,
      ...selectedDateRange
    };
    const response = await transitFulfillmentService.execute({
      queryParams
    });
    return response;
  }
);

const getTransitFulfillmentCheckpointsByCountry = createAsyncThunk(
  `achievement/getTransitFulfillmentCheckpointsByCountry`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedClientType = getSelectedClientTypeInPlainFormat(appState);
    const selectedDateRange = getSelectedDateRangeFormated(appState);
    const fastLaneSelected = getFastLaneSelected(appState);
    const selectedFactory = getSelectedFactoryInPlainFormat(appState);
    const updateSelectedBrands = getSelectedBrands(appState);
    const logisticObjectiveSelected = getLogisticObjectiveSelected(appState);
    const selectedCountry = getSelectedCountry(appState);
    const commercialPackagesSelected =
      getSelectedCommercialPackagesInPlainFormat(appState);
    const versandtreueSelected = getVersandtreueSelected(appState);

    const queryParams = {
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: updateSelectedBrands,
      logisticObjective: logisticObjectiveSelected,
      commercialPackages: commercialPackagesSelected,
      versandtreue: versandtreueSelected,
      ...selectedDateRange
    };
    const response =
      await transitFulfillmentCheckpointsByCountryService.execute({
        countryCode: selectedCountry,
        queryParams
      });
    return response;
  }
);

const getRoutesByCountry = createAsyncThunk(
  `achievement/getRoutesByCountry`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedClientType = getSelectedClientTypeInPlainFormat(appState);
    const selectedDateRange = getSelectedDateRangeFormated(appState);
    const fastLaneSelected = getFastLaneSelected(appState);
    const selectedFactory = getSelectedFactoryInPlainFormat(appState);
    const updateSelectedBrands = getSelectedBrands(appState);
    const logisticObjectiveSelected = getLogisticObjectiveSelected(appState);
    const selectedCountry = getSelectedCountry(appState);
    const commercialPackagesSelected =
      getSelectedCommercialPackagesInPlainFormat(appState);
    const versandtreueSelected = getVersandtreueSelected(appState);

    const queryParams = {
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: updateSelectedBrands,
      logisticObjective: logisticObjectiveSelected,
      commercialPackages: commercialPackagesSelected,
      versandtreue: versandtreueSelected,
      ...selectedDateRange
    };
    const response = await routesByCountryService.execute({
      countryCode: selectedCountry,
      queryParams
    });
    return response;
  }
);

const downloadFulfillmentFinishedGoods = createAsyncThunk(
  `achievement/downloadFulfillmentFinishedGoods`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedClientType = getSelectedClientTypeInPlainFormat(appState);
    const selectedDateRange = getSelectedDateRangeFormated(appState);
    const fastLaneSelected = getFastLaneSelected(appState);
    const selectedFactory = getSelectedFactoryInPlainFormat(appState);
    const selectedBrands = getSelectedBrands(appState);
    const logisticObjectiveSelected = getLogisticObjectiveSelected(appState);
    const selectedCountries = getSelectedCountriesISO(appState);
    const commercialPackagesSelected =
      getSelectedCommercialPackagesInPlainFormat(appState);
    const versandtreueSelected = getVersandtreueSelected(appState);

    const queryParams = {
      countries: selectedCountries,
      clientType: selectedClientType,
      fastLane: fastLaneSelected,
      factoryId: selectedFactory,
      brands: selectedBrands,
      logisticObjective: logisticObjectiveSelected,
      commercialPackages: commercialPackagesSelected,
      versandtreue: versandtreueSelected,
      ...selectedDateRange
    };
    const response = await downloadFulfillmentFinishedGoodsService.execute({
      queryParams
    });
    return response;
  }
);

const getGlobalFulfillmentByDateRange = createAsyncThunk(
  `achievement/getGlobalFulfillmentByDateRange`,
  async ({
    dateRangeKey,
    brands,
    versandtreue
  }: {
    dateRangeKey: DateRangeType;
    brands: BrandEnum[];
    versandtreue: boolean;
  }) => {
    const response = await getGlobalTransitFulfillmentUseCase.execute({
      dateRangeKey,
      brands,
      versandtreue
    });
    return {
      dateRange: dateRangeKey,
      value:
        response.requestedDateGlobalFulfillmentPercentage
          .globalAccomplishedPercentage
    };
  }
);

export {
  downloadFulfillmentFinishedGoods,
  getAchievements,
  getTransitFulfillmentCheckpointsByCountry,
  getRoutesByCountry,
  getGlobalFulfillmentByDateRange
};
