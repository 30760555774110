import fulfillmentRepository from 'modules/outbound/fulfillment/infrastructure/repositories/FulfillmentRepository';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import CountryRoutes from 'modules/outbound/fulfillment/domain/CountryRoutes';

class RoutesByCountryService {
  private _fulfillmentRepository: ITransitRepository;

  constructor({ fulfillmentRepository }) {
    this._fulfillmentRepository = fulfillmentRepository;
  }

  execute({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    return this._fulfillmentRepository.getRoutesByCountry!({
      queryParams,
      countryCode
    }).then((res) => CountryRoutes.generateFromDTO({ dto: res, countryCode }));
  }
}

export default new RoutesByCountryService({
  fulfillmentRepository: fulfillmentRepository
});

export { RoutesByCountryService };
