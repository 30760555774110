import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

const WrapperToastify = styled.div`
  font-size: ${({ theme }: { theme: ThemeSEATProps }) =>
    theme.fonts.regularSmall.intermediateSizeAsPixels};
  position: fixed;
  z-index: 50;

  .Toastify__toast-theme--colored.Toastify__toast--success {
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.green900.rgb};
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.green50.rgb};
    .Toastify__close-button svg {
      fill: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.green900.rgb};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.yellow900.rgb};
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.yellow50.rgb};
    .Toastify__close-button svg {
      fill: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.yellow900.rgb};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.red900.rgb};
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.red50.rgb};
    .Toastify__close-button svg {
      fill: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.red900.rgb};
    }
  }

  .loading {
    color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.orange900.rgb};
    background-color: ${(props: { theme: ThemeSEATProps }) =>
      props.theme.colors.orange50.rgb};
    .Toastify__spinner {
      border-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.white.rgb};
      border-right-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.orange900.rgb};
    }
  }

  .Toastify__progress-bar-theme--colored {
    &.Toastify__progress-bar--success {
      background-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.green900.rgb};
    }
    &.Toastify__progress-bar--warning {
      background-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.yellow900.rgb};
    }
    &.Toastify__progress-bar--error {
      background-color: ${(props: { theme: ThemeSEATProps }) =>
        props.theme.colors.red900.rgb};
    }
  }
`;

export { WrapperToastify };
