import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { ContainerNeedsItemDTO } from '../infrastructure/dto/ContainerNeedsDTO';

export interface ContainerNeedsItem {
  containersId: string;
  amount: number;
}

interface GenerateFromDTOParams {
  containers_needed: ContainerNeedsItemDTO[];
  pagination: IPaginationDTO;
}

class ContainerNeedsData {
  containersNeeded: ContainerNeedsItem[];
  pagination: IPagination;

  constructor({ containersNeeded, pagination }) {
    this.containersNeeded = containersNeeded;
    this.pagination = pagination;
  }

  static generateFromDTO({
    containers_needed,
    pagination
  }: GenerateFromDTOParams): ContainerNeedsData {
    let formatData: ContainerNeedsItem[] = [];

    containers_needed.forEach((item) => {
      formatData.push({
        containersId: `${item.basic_container}-${item.supply_container}`,
        amount: item.amount
      });
    });

    return new ContainerNeedsData({
      containersNeeded: formatData,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }

  static generateEmpty() {
    return new ContainerNeedsData({
      containersNeeded: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 }
    });
  }
}

export default ContainerNeedsData;
