export interface MRTItemDTO {
  warehouses: string[];
}

export interface BCNItemDTO {
  warehouses: string[];
}

class FactoryBcnFiltersDataDTO {
  lines: number[];
  models: string[];
  MRT: MRTItemDTO;
  BCN: BCNItemDTO;

  constructor({ lines, models, MRT, BCN }) {
    this.lines = lines;
    this.models = models;
    this.MRT = MRT;
    this.BCN = BCN;
  }
}

export default FactoryBcnFiltersDataDTO;
