import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  NameValues,
  SearchOperators
} from 'modules/inbound/shared/domain/NameValues';
import { ParalyzationState } from '../../infrastructure/dto/InPlantDTO/EntriesInPlantDataDTO';

interface GenerateFromDtoParams {
  entitled_to_paralyzation: number;
  in_paralyzation: {
    entries: number;
    global_dlz: number;
  };
  near_paralyzation: number;
}

export const ParalyzationSummaryFilter = {
  ENTITLED_TO_PARALYZATION: `${NameValues.PARALYZATION_RIGHT}${SearchOperators.EQUAL}1`,
  IN_PARALYZATION: `${NameValues.PARALYZATION_RIGHT}${SearchOperators.EQUAL}1||${NameValues.PARALYZATION_STATE}${SearchOperators.EQUAL}${ParalyzationState.PARALYZED}`,
  NEAR_PARALYZATION: `${NameValues.PARALYZATION_RIGHT}${SearchOperators.EQUAL}1||${NameValues.PARALYZATION_STATE}${SearchOperators.EQUAL}${ParalyzationState.NEAR_PARALYZATION}`
} as const;

class EntriesParalyzation {
  entitledToParalyzation: number;
  inParalyzation: string;
  nearParalyzation: number;

  constructor({
    entitledToParalyzation,
    inParalyzation,
    nearParalyzation
  }: EntriesParalyzation) {
    this.entitledToParalyzation = entitledToParalyzation;
    this.inParalyzation = inParalyzation;
    this.nearParalyzation = nearParalyzation;
  }

  static generateFromDto({
    entitled_to_paralyzation,
    in_paralyzation,
    near_paralyzation
  }: GenerateFromDtoParams): EntriesParalyzation {
    return new EntriesParalyzation({
      entitledToParalyzation: entitled_to_paralyzation,
      inParalyzation: `${
        in_paralyzation.entries
      } (${GetDateFormatedService.formatSecondsToMinutes(
        in_paralyzation.global_dlz
      )})`,
      nearParalyzation: near_paralyzation
    });
  }

  static generateEmpty() {
    return new EntriesParalyzation({
      entitledToParalyzation: 0,
      inParalyzation: '0',
      nearParalyzation: 0
    });
  }
}

export { EntriesParalyzation };
