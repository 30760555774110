import IUserInboundRepository from '../../domain/UserInbound/IUserInboundRepository';
import UserInboundData from '../../domain/UserInbound/UserInbound';
import InboundUserRepository from '../../infraestructure/repositories/InboundUserRepository';

class GetUserInboundUseCase {
  private _userInboundRepository: IUserInboundRepository;

  constructor({ repository }) {
    this._userInboundRepository = repository;
  }

  execute() {
    return this._userInboundRepository
      .getInboundUserData()
      .then((res) => UserInboundData.generateFromDTO(res));
  }
}

export { GetUserInboundUseCase };
export default new GetUserInboundUseCase({
  repository: InboundUserRepository
});
