import { DeliveryStatusesDTO } from '../infrastructure/dto/deliveryStatusesDTOMapper';

export interface DeliveryStatuses {
  others: {
    noEntries: number;
  };
  quantity: {
    complete: number;
    incomplete: number;
    leftover: number;
  };
  punctuality: {
    riskOfDelay: number;
    onTime: number;
    riskOfOvertake: number;
  };
}

class DeliveryStatusesData implements DeliveryStatuses {
  others: { noEntries: number };
  quantity: { complete: number; incomplete: number; leftover: number };
  punctuality: {
    riskOfDelay: number;
    onTime: number;
    riskOfOvertake: number;
  };

  constructor({ others, quantity, punctuality }: DeliveryStatuses) {
    this.others = others;
    this.quantity = quantity;
    this.punctuality = punctuality;
  }

  static generateFromDto({
    others,
    quantity,
    punctuality
  }: DeliveryStatusesDTO): DeliveryStatusesData {
    return new DeliveryStatusesData({
      others: {
        noEntries: others.no_entries
      },
      quantity,
      punctuality: {
        riskOfDelay: punctuality.risk_of_delay,
        onTime: punctuality.on_time,
        riskOfOvertake: punctuality.risk_of_overtake
      }
    });
  }

  static generateEmpty(): DeliveryStatusesData {
    return new DeliveryStatusesData({
      others: { noEntries: 0 },
      quantity: { complete: 0, incomplete: 0, leftover: 0 },
      punctuality: {
        riskOfDelay: 0,
        onTime: 0,
        riskOfOvertake: 0
      }
    });
  }
}

export { DeliveryStatusesData };
