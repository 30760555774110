import { createSlice } from '@reduxjs/toolkit';
import EndOfProductionSummary from 'modules/inbound/endOfProductionDate/domain/EndOfProductionSummary';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import {
  downloadEndOfProductionDate,
  getEndOfProductionDateData,
  getEndOfStockSummaryData,
  getInconsistenciesSummaryData,
  getLeftoverStockSummaryData,
  getNeedsUpdateDate,
  getSleepersNoNeedsData,
  getSleepersWithNeedsData,
  getStockCoverageUpdateDate,
  getStuecklisteVWUpdateDate
} from './async';
import { EndOfProductionDateDataState, EndOfStockSummary } from './types';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { END_OF_PRODUCTION_COLUMNS } from 'modules/inbound/endOfProductionDate/domain/EndOfProductionColumns';

export const initialState: EndOfProductionDateDataState = {
  data: {
    endOfStock: [],
    columns: END_OF_PRODUCTION_COLUMNS,
    savedColumns: [],
    summary: {
      endOfStockSummary: EndOfProductionSummary.generateEmpty(),
      leftoverStockSummary: EndOfProductionSummary.generateEmpty(),
      sleepersNoNeeds: EndOfProductionSummary.generateEmpty(),
      sleepersWithNeeds: EndOfProductionSummary.generateEmpty(),
      withInconsistencies: EndOfProductionSummary.generateEmpty()
    }
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  requestEndOfStockSummaryStatus: '',
  requestLeftoverStockSummaryStatus: '',
  requestSleepersNoNeedsSummaryStatus: '',
  requestSleepersWithNeedsSummaryStatus: '',
  requestWithInconsistenciesSummaryStatus: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  downloadRequestStatus: '',
  endOfProductionDateFile: null,
  stockCoverageUpdateDate: '-',
  needsUpdateDate: '-',
  stuecklisteVWUpdateDate: '-'
};

const END_OF_PRODUCTION_COLUMNS_VIEW_STATE_KEY = 'EndOfProductionColumnsView';

const endOfProductionDateDataSlice = createSlice({
  name: 'endOfProductionDateData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${END_OF_PRODUCTION_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.data.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.data.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: END_OF_PRODUCTION_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.data.columns]
      });
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    updateVisibleColumns: (state, action) => {
      state.data.savedColumns = state.data.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateColumnsOrder: (state, action) => {
      state.data.savedColumns = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEndOfProductionDateData.fulfilled, (state, action) => {
      state.data.endOfStock = action.payload.endOfStock;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfProductionDateData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfProductionDateData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfStockSummaryData.fulfilled, (state, action) => {
      state.data.summary.endOfStockSummary = action.payload;
      state.requestEndOfStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfStockSummaryData.pending, (state, action) => {
      state.requestEndOfStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getEndOfStockSummaryData.rejected, (state, action) => {
      state.requestEndOfStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getLeftoverStockSummaryData.fulfilled, (state, action) => {
      state.data.summary.leftoverStockSummary = action.payload;
      state.requestLeftoverStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getLeftoverStockSummaryData.pending, (state, action) => {
      state.requestLeftoverStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getLeftoverStockSummaryData.rejected, (state, action) => {
      state.requestLeftoverStockSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersNoNeedsData.fulfilled, (state, action) => {
      state.data.summary.sleepersNoNeeds = action.payload;
      state.requestSleepersNoNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersNoNeedsData.pending, (state, action) => {
      state.requestSleepersNoNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersNoNeedsData.rejected, (state, action) => {
      state.requestSleepersNoNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersWithNeedsData.fulfilled, (state, action) => {
      state.data.summary.sleepersWithNeeds = action.payload;
      state.requestSleepersWithNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersWithNeedsData.pending, (state, action) => {
      state.requestSleepersWithNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getSleepersWithNeedsData.rejected, (state, action) => {
      state.requestSleepersWithNeedsSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getInconsistenciesSummaryData.fulfilled,
      (state, action) => {
        state.data.summary.withInconsistencies = action.payload;
        state.requestWithInconsistenciesSummaryStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getInconsistenciesSummaryData.pending, (state, action) => {
      state.requestWithInconsistenciesSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getInconsistenciesSummaryData.rejected, (state, action) => {
      state.requestWithInconsistenciesSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEndOfProductionDate.fulfilled, (state, action) => {
      state.endOfProductionDateFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_EOP_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEndOfProductionDate.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEndOfProductionDate.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getStockCoverageUpdateDate.fulfilled, (state, action) => {
      state.stockCoverageUpdateDate = action.payload.lastUpdate;
    });
    builder.addCase(getNeedsUpdateDate.fulfilled, (state, action) => {
      state.needsUpdateDate = action.payload.lastUpdate;
    });
    builder.addCase(getStuecklisteVWUpdateDate.fulfilled, (state, action) => {
      state.stuecklisteVWUpdateDate = action.payload.lastUpdate;
    });
  }
});

//selectors
export const getEndOfProductionDateDataState = (state) =>
  state.endOfProductionDateDataState.data.endOfStock;
export const getEndOfProductionSummaryData = (state) => {
  const summary: EndOfStockSummary =
    state.endOfProductionDateDataState.data.summary;
  return {
    ...summary,
    sleepersTotal: {
      numberOfKeys:
        summary.sleepersNoNeeds.numberOfKeys +
        summary.sleepersWithNeeds.numberOfKeys,
      valueInEuros:
        summary.sleepersNoNeeds.valueInEuros +
        summary.sleepersWithNeeds.valueInEuros,
      stockVolume:
        summary.sleepersNoNeeds.stockVolume +
        summary.sleepersWithNeeds.stockVolume
    }
  };
};

export const getColumns = (state) =>
  state.endOfProductionDateDataState.data.savedColumns;
export const getisLoadingStatus = (state) =>
  state.endOfProductionDateDataState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.endOfProductionDateDataState.requestStatus === 'rejected';
export const getisLoadingSummaryStatus = (state) =>
  state.endOfProductionDateDataState.requestEndOfStockSummaryStatus ===
    'pending' ||
  state.endOfProductionDateDataState.requestLeftoverStockSummaryStatus ===
    'pending' ||
  state.endOfProductionDateDataState.requestSleepersNoNeedsSummaryStatus ===
    'pending' ||
  state.endOfProductionDateDataState.requestSleepersWithNeedsSummaryStatus ===
    'pending' ||
  state.endOfProductionDateDataState.requestWithInconsistenciesSummaryStatus ===
    'pending';
export const getHasErrorSummary = (state) =>
  state.endOfProductionDateDataState.requestEndOfStockSummaryStatus ===
    'rejected' ||
  state.endOfProductionDateDataState.requestLeftoverStockSummaryStatus ===
    'rejected' ||
  state.endOfProductionDateDataState.requestSleepersNoNeedsSummaryStatus ===
    'rejected' ||
  state.endOfProductionDateDataState.requestSleepersWithNeedsSummaryStatus ===
    'rejected' ||
  state.endOfProductionDateDataState.requestWithInconsistenciesSummaryStatus ===
    'rejected';
export const getPagination = (state) =>
  state.endOfProductionDateDataState.pagination;
export const getSelectedColumn = (state) =>
  state.endOfProductionDateDataState.selectedColumn;
export const getDownloadEndOfProductionIsPending = (state) =>
  state.endOfProductionDateDataState.downloadRequestStatus === 'pending';
export const getDownloadEndOfProductionSuccessful = (state) =>
  state.endOfProductionDateDataState.downloadRequestStatus === 'fulfilled' &&
  state.endOfProductionDateDataState.endOfProductionDateFile !== null;
export const getDownloadEndOfProductionError = (state) =>
  state.endOfProductionDateDataState.downloadRequestStatus === 'rejected';
export const getStockCoverageUpdate = (state) =>
  state.endOfProductionDateDataState.stockCoverageUpdateDate;
export const getNeedsUpdate = (state) =>
  state.endOfProductionDateDataState.needsUpdateDate;
export const getStuecklisteVWUpdate = (state) =>
  state.endOfProductionDateDataState.stuecklisteVWUpdateDate;

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateColumnsOrder,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  resetDownloadStatus
} = endOfProductionDateDataSlice.actions;

export default endOfProductionDateDataSlice.reducer;
