import * as React from 'react';
import { LogoWithSubtitleProps } from './typing';
import Image from 'modules/shared/layout/elements/Images/Image/index';

const LogoWithSubtitle: React.FunctionComponent<LogoWithSubtitleProps> = (
  props: LogoWithSubtitleProps
) => {
  const { src, subtitle } = props;

  return (
    <>
      <Image src={src} alt={subtitle} />
      <b className="logo-subtitle"> {subtitle} </b>
    </>
  );
};

export default LogoWithSubtitle;
