import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { EntriesDataItemDTO } from '../../infrastructure/dto/NextHoursDTO/entriesDataDTOMapper';
import { PieceAlert } from '../types';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};
export interface Entry {
  hasDetail: boolean;
  plateNumber: string;
  transportId: number | string;
  carrier: string;
  estimatedTimeArrival: string;
  stops: number;
  windowBooked: string;
  mostCriticalPieceCoverage: string;
  warehouses: string;
  locations: string[];
  unloadingPoints: string;
  packages: number | string;
  pieceAlert: PieceAlert;
  supplierName: string;
}

class EntryData implements Entry {
  hasDetail: boolean;
  plateNumber: string;
  transportId: number | string;
  carrier: string;
  estimatedTimeArrival: string;
  stops: number;
  windowBooked: string;
  mostCriticalPieceCoverage: string;
  warehouses: string;
  locations: string[];
  unloadingPoints: string;
  packages: number | string;
  pieceAlert: PieceAlert;
  supplierName: string;

  constructor({
    hasDetail,
    plateNumber,
    transportId,
    carrier,
    estimatedTimeArrival,
    stops,
    windowBooked,
    mostCriticalPieceCoverage,
    warehouses,
    locations,
    unloadingPoints,
    packages,
    pieceAlert,
    supplierName
  }: Entry) {
    this.hasDetail = hasDetail;
    this.plateNumber = plateNumber;
    this.transportId = transportId;
    this.carrier = carrier;
    this.estimatedTimeArrival = estimatedTimeArrival;
    this.stops = stops;
    this.windowBooked = windowBooked;
    this.mostCriticalPieceCoverage = mostCriticalPieceCoverage;
    this.warehouses = warehouses;
    this.locations = locations;
    this.unloadingPoints = unloadingPoints;
    this.packages = packages;
    this.pieceAlert = pieceAlert;
    this.supplierName = supplierName;
  }

  private static _formatThousandsDotDays(quantity) {
    if (quantity === null) {
      return '-';
    }
    const numberWithDots = quantity
      .toFixed(2)
      .replace(/\.0+$/, '')
      .replace('.', ',')
      .toLocaleString('de-DE');

    if (numberWithDots === '1') {
      return `${numberWithDots} ${inboundTranslations.unitSingleDay}`;
    }
    return `${numberWithDots} ${inboundTranslations.unitMultipleDays}`;
  }

  static generateFromDto({
    has_detail,
    plate_number,
    transport_id,
    carrier,
    estimated_time_arrival,
    stops,
    window_booked,
    most_critical_piece_coverage,
    warehouses,
    locations,
    unloading_points,
    packages,
    urgent,
    supplier_name,
    has_critical_pieces,
    contains_critical_pieces,
    contains_serious_fault
  }: EntriesDataItemDTO) {
    return new EntryData({
      hasDetail: has_detail,
      plateNumber: plate_number,
      transportId: transport_id || '-',
      carrier,
      estimatedTimeArrival: estimated_time_arrival
        ? GetDateFormatedService.getShortDateTime(estimated_time_arrival)
        : '-',
      stops: stops,
      windowBooked: window_booked
        ? GetDateFormatedService.getShortDateTime(window_booked)
        : '-',
      mostCriticalPieceCoverage: this._formatThousandsDotDays(
        most_critical_piece_coverage
      ),
      warehouses: warehouses.join(' | ') || '-',
      locations: locations.length ? locations : ['-'],
      unloadingPoints: unloading_points.length
        ? unloading_points.join(' | ')
        : '-',
      packages: typeof packages === 'number' ? packages : '-',
      pieceAlert: {
        urgent: !!urgent,
        hasCriticalPieces: has_critical_pieces,
        containsCriticalPieces: contains_critical_pieces,
        containsSeriousFault: contains_serious_fault
      },
      supplierName: supplier_name
    });
  }
}

export { EntryData };
