import { ProviderContactListDTO } from '../../infraestructure/dto/providerContactListDTOMapper';
import ProviderContactList from './ProviderContactList';

export interface ContactListData {
  contactList: ProviderContactList[];
}

const providerContactListMapper = (
  dto: ProviderContactListDTO
): ContactListData => ({
  contactList: dto.contact_list.map((provider) =>
    ProviderContactList.generateFromDTO(provider)
  )
});

export { providerContactListMapper };
