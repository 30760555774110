import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import LineGroupsBodyDTO from '../dto/LineGroupsBodyDTO';
import LineGroupsDataDTO from '../dto/LineGroupsDataDTO';
import { LINE_GROUPS_BODY_ENDPOINT, LINE_GROUPS_ENDPOINT } from './urls';

class LineGroupsRepository extends BaseRepository {
  private _api: IApi;
  private _apiGetLineGroupsData: string;
  private _apiGetLineGroupsBody: string;

  constructor({ api, apiGetLineGroupsData, apiGetLineGroupsBody }) {
    super();
    this._api = api;
    this._apiGetLineGroupsData = apiGetLineGroupsData;
    this._apiGetLineGroupsBody = apiGetLineGroupsBody;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetLineGroupsData}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new LineGroupsDataDTO(res));
  }

  getTableBody() {
    return this._api
      .get(this._apiGetLineGroupsBody)
      .then((res) => new LineGroupsBodyDTO(res));
  }
}

export default new LineGroupsRepository({
  api: api,
  apiGetLineGroupsData: LINE_GROUPS_ENDPOINT,
  apiGetLineGroupsBody: LINE_GROUPS_BODY_ENDPOINT
});

export { LineGroupsRepository };
