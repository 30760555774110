import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { API_DOWNLOAD_PROVIDER_CODES, API_UPLOAD_PROVIDER_CODES } from './url';
import UploadProviderCodesResponseDTO from '../dto/UploadProviderCodesDTO';
import IProviderCodesRepository from '../../domain/UploadProviderCodes/IProviderCodes';

class ProviderCodesRepository
  extends BaseRepository
  implements IProviderCodesRepository
{
  private readonly _api: IApi;
  private readonly _apiDownloadProviderCodes: string;
  private readonly _apiUploadProviderCodes: string;

  constructor({ api, apiDownloadProviderCodes, apiUploadProviderCodes }) {
    super();
    this._api = api;
    this._apiDownloadProviderCodes = apiDownloadProviderCodes;
    this._apiUploadProviderCodes = apiUploadProviderCodes;
  }

  downloadProviderCodes({ queryParams }) {
    const url = `${this._apiDownloadProviderCodes}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  uploadProviderCodes(file: object) {
    return this._api
      .post(this._apiUploadProviderCodes, file)
      .then((res) => new UploadProviderCodesResponseDTO(res));
  }
}

export default new ProviderCodesRepository({
  api: api,
  apiDownloadProviderCodes: API_DOWNLOAD_PROVIDER_CODES,
  apiUploadProviderCodes: API_UPLOAD_PROVIDER_CODES
});

export { ProviderCodesRepository };
