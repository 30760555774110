const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const END_OF_PRODUCTION_DATE_ENDPOINT = '/endofproduction/stock/';
const END_OF_PRODUCTION_DATE_FILTERS_ENDPOINT = '/filters/endofproduction';
const END_OF_PRODUCTION_DATE_SUMMARY_ENDPOINT = `${END_OF_PRODUCTION_DATE_ENDPOINT}summary`;
const END_OF_PRODUCTION_DATE_DOWNLOAD = `${END_OF_PRODUCTION_DATE_ENDPOINT}download`;
const API_GET_STOCK_END_OF_PRODUCTION_DATE = `${INBOUND_BASE_URL}${END_OF_PRODUCTION_DATE_ENDPOINT}`;
const API_GET_STOCK_END_OF_PRODUCTION_FILTERS = `${INBOUND_BASE_URL}${END_OF_PRODUCTION_DATE_FILTERS_ENDPOINT}`;
const API_GET_STOCK_END_OF_PRODUCTION_SUMMARY = `${INBOUND_BASE_URL}${END_OF_PRODUCTION_DATE_SUMMARY_ENDPOINT}`;
const API_DOWNLOAD_END_OF_PRODUCTION_DATE = `${INBOUND_BASE_URL}${END_OF_PRODUCTION_DATE_DOWNLOAD}`;
export {
  API_GET_STOCK_END_OF_PRODUCTION_DATE,
  API_GET_STOCK_END_OF_PRODUCTION_FILTERS,
  API_GET_STOCK_END_OF_PRODUCTION_SUMMARY,
  API_DOWNLOAD_END_OF_PRODUCTION_DATE
};
