import { Alerts } from 'modules/inbound/refills/domain/RefillsAlertsData';
import { RefillsSaturation } from 'modules/inbound/refills/domain/RefillsSaturationData';
import { RefillStatus } from 'modules/inbound/refills/domain/RefillsStatusData';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { Refills } from '../../../domain/RefillsData';
import { SearchModality } from '../../../infrastructure/dto/types';
import { ColumnSelected } from 'modules/shared/layout/components/TableHeader/types';

interface SelectedAlert {
  status: string;
  type: string;
}

export enum COLORS {
  RED = 'red700',
  YELLOW = 'yellow700',
  GREEN = 'green700',
  BLUE = 'blue',
  WHITE = 'white',
  BLACK = 'black50'
}

export enum STATUS {
  DELAYED = 'DELAYED',
  ON_TIME = 'ON_TIME',
  NEAR_DELAY = 'NEAR_DELAY',
  DEFAULT = 'DEFAULT'
}

export const BarStatus = {
  [STATUS.DELAYED]: COLORS.RED,
  [STATUS.ON_TIME]: COLORS.GREEN,
  [STATUS.NEAR_DELAY]: COLORS.YELLOW,
  [STATUS.DEFAULT]: COLORS.BLACK
};

export enum AlertType {
  TRANSFER = 'transfer',
  LINE = 'LINE'
}
interface SelectedStatus {
  generatedAt: string;
  fromDateTime: string;
  toDateTime: string;
  searchModality: SearchModality | undefined;
  status: string;
}

export const SelectedStatusColor = {
  [COLORS.RED]: STATUS.DELAYED,
  [COLORS.GREEN]: STATUS.ON_TIME,
  [COLORS.YELLOW]: STATUS.NEAR_DELAY,
  [COLORS.BLACK]: STATUS.DEFAULT
};

export interface FiltersItem {
  name: string;
  value: any;
}

export interface RefillsDataState {
  data: {
    refills: Refills[];
    refillsStatus: RefillStatus[];
    refillsAlerts: Alerts;
    refillsSaturation: RefillsSaturation;
    lastUpdateDate: string;
    columns: ColumnProps[];
    savedColumns: ColumnProps[];
  };
  pagination: {
    page: number;
    size: number;
    pageCount: number;
    totalCount: number;
  };
  requestStatus: string;
  refillsStatusRequestStatus: string;
  refillsAlertsStatus: string;
  refillsSaturationRequestStatus: string;
  selectedColumn: ColumnSelected;
  filters: {
    search: string;
    selectedAlert: SelectedAlert;
    selectedStatus: SelectedStatus;
  };
}
