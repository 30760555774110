export interface IWarehouseStock {
  id: string;
  warehouseName: string;
  saturation: number;
  stock: number;
  capacity: number;
  invoiced: number;
  clientStock: number;
  importedStock: number;
  fleetStock: number;
  fastLane: number;
}
interface GenerateFromDTOParams {
  id: string;
  name: string;
  stock: number;
  capacity: number;
  invoiced: number;
  clientStock: number;
  importStock: number;
  fleetStock: number;
  fastLane: number;
}

class WarehouseStock implements IWarehouseStock {
  id: string;
  warehouseName: string;
  saturation: number;
  stock: number;
  capacity: number;
  invoiced: number;
  clientStock: number;
  importedStock: number;
  fleetStock: number;
  fastLane: number;

  constructor({
    id,
    warehouseName,
    saturation,
    stock,
    capacity,
    invoiced,
    clientStock,
    importedStock,
    fleetStock,
    fastLane
  }: WarehouseStock) {
    this.id = id;
    this.warehouseName = warehouseName;
    this.saturation = saturation;
    this.stock = stock;
    this.capacity = capacity;
    this.invoiced = invoiced;
    this.clientStock = clientStock;
    this.importedStock = importedStock;
    this.fleetStock = fleetStock;
    this.fastLane = fastLane;
  }

  static generateFromDTO({
    id,
    name,
    stock,
    capacity,
    invoiced,
    clientStock,
    importStock,
    fleetStock,
    fastLane
  }: GenerateFromDTOParams): WarehouseStock {
    return new WarehouseStock({
      id,
      warehouseName: name,
      saturation: Math.round((stock / capacity) * 100),
      capacity: capacity,
      stock: stock,
      invoiced: Math.round((invoiced / stock) * 100),
      clientStock: clientStock,
      importedStock: importStock,
      fleetStock: fleetStock,
      fastLane: fastLane
    });
  }

  static generateEmpty() {
    return new WarehouseStock({
      id: '',
      warehouseName: '',
      saturation: 0,
      capacity: 0,
      stock: 0,
      invoiced: 0,
      clientStock: 0,
      importedStock: 0,
      fleetStock: 0,
      fastLane: 0
    });
  }
}

export default WarehouseStock;
