import { CoverageStatusType } from '../../../inbound/directDelivery/ui/redux/DirectDeliveryReducer/types';

export interface StatsItem {
  status: CoverageStatusType;
  counter: number;
}

export interface CoverageItem {
  name: string;
  total: number;
  stats: StatsItem[];
}

class CoverageAtRiskDTO {
  models_risk: CoverageItem[];
  total_risk: CoverageItem;

  constructor({ models_risk, total_risk }) {
    this.models_risk = models_risk;
    this.total_risk = total_risk;
  }
}

export default CoverageAtRiskDTO;
