import { ILineGroupsRepository } from '../domain/ILineGroupsRepository';
import LineGroupsData from '../domain/LineGroupsData';
import lineGroupsRepository from '../infrastructure/repositories/LineGroupsRepository';

class GetLineGroupsUseCase {
  private _lineGroupsRepository: ILineGroupsRepository;

  constructor({ lineGroupsRepository }) {
    this._lineGroupsRepository = lineGroupsRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._lineGroupsRepository
      .get({ queryParams })
      .then((res) => LineGroupsData.generateFromDTO(res));
  }
}

export default new GetLineGroupsUseCase({
  lineGroupsRepository: lineGroupsRepository
});

export { GetLineGroupsUseCase };
