import { Translation } from 'infrastructure/i18n/typing';
import { ExpirationTranslations } from './typing';

export const ExpirationText: Translation<ExpirationTranslations> = {
  es: {
    pageTitle: 'Caducidades',
    searcherPlaceholder: 'Búsqueda por clave/s, descripción/es o albarán/es',
    code: 'Clave',
    handlingUnit: 'HU',
    quantity: 'Cantidad',
    warehouse: 'Almacén',
    expirationDate: 'Fecha caducidad',
    measurementUnit: 'Unidad medida',
    stockCost: 'Precio HU €',
    warehouseType: 'Tipo de almacén',
    location: 'Ubicación',
    stockDifferentiation: 'Estado HU',
    stockDifferentiationFilter: 'Diferenciación stock',
    expirationDays: 'Dias para caducidad',
    warehouses: 'Almacén',
    inbounders: 'Inbounder',
    models: 'Modelo',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionados',
    inbounderMultiselectEmpty: 'No hay inbounders disponibles',
    warehouseMultiselectAvailable: 'Almacenes disponibles',
    warehouseMultiselectSelected: 'Almacenes seleccionados',
    warehouseMultiselectEmpty: 'No hay almaceneses disponibles',
    measurementUnitsMultiselectAvailable: 'Unidades de medida disponibles',
    measurementUnitsMultiselectSelected: 'Unidades de medida seleccionados',
    measurementUnitsMultiselectEmpty: 'No hay unidades de medida disponibles',
    modelMultiselectAvailable: 'Modelos disponibles',
    modelMultiselectSelected: 'Modelos seleccionados',
    modelMultiselectEmpty: 'No hay modelos disponibles',
    differentiationMultiselectAvailable: 'Valores disponibles',
    differentiationMultiselectSelected: 'Valores seleccionados',
    differentiationMultiselectEmpty: 'No hay valores disponibles',
    daysForExpiration: 'Dias para caducidad',
    name: 'Descripción',
    providerNames: 'Nombre proveedor - Porcentaje',
    supplyPoints: 'Num. Puntos de Suministro',
    expirationStatus: 'Status',
    expired: 'Caducadas',
    riskToExpire: 'Cerca de caducar',
    huExpired: 'HUs caducadas',
    huRiskOfExpiration: 'HUs cerca de caducidad',
    valueInEuros: 'Valor en euros',
    overview: 'Visión general',
    stockDifferentiationTooltip:
      '"-" ➝ Stock disponible <br/> Q ➝ Control de calidad <br/>R ➝ Devolución <br/> S ➝ Stock bloqueado',
    measurementUnitTooltip:
      'ST ➝ Unidades ➝ 10 <br/>M ➝ Metros ➝ 30 <br/>KG ➝ Kilogramos ➝ 23 <br/>L ➝ Litros ➝ 50',
    expirationDaysTooltip:
      'Días hasta el vencimiento. El negativo ya está caducado.',
    expirationStatusTooltip:
      'Rojo ➝ Caducada <br/>Naranja ➝ Menos de 30 días para caducidad <br/>Verde ➝ Más de 30 días para caducidad',
    lastUpdate: 'Actualización fichero: ',
    coverage: 'Cobertura',
    providerDeliveryNote: 'Albarán',
    providerDeliveryNoteDate: 'Fecha albarán',
    units: 'ST ➝ Unidades ➝ 10',
    meters: 'M ➝ Metros ➝ 30',
    kilograms: 'KG ➝ Kilogramos ➝ 23',
    liters: 'L ➝ Litros ➝ 50'
  },
  en: {
    pageTitle: 'Expirations',
    searcherPlaceholder: 'Search by part/s, description/s or delivery note/s',
    code: 'Part',
    handlingUnit: 'HU',
    quantity: 'Quantity',
    warehouse: 'Warehouse',
    expirationDate: 'Expiration date',
    measurementUnit: 'Measurement unit',
    stockCost: 'Price HU €',
    warehouseType: 'Warehouse type',
    location: 'Location',
    stockDifferentiation: 'Status HU',
    stockDifferentiationFilter: 'Stock differentiation',
    expirationDays: 'Expiration days',
    warehouses: 'Warehouse',
    inbounders: 'Inbounder',
    models: 'Model',
    inbounderMultiselectAvailable: 'Inbounders available',
    inbounderMultiselectSelected: 'Inbounders selected',
    inbounderMultiselectEmpty: "There aren't inbounders available",
    warehouseMultiselectAvailable: 'Warehouse available',
    warehouseMultiselectSelected: 'Warehouse selected',
    warehouseMultiselectEmpty: "There aren't warehouse available",
    measurementUnitsMultiselectAvailable: 'Measurement units available',
    measurementUnitsMultiselectSelected: 'Measurement units selected',
    measurementUnitsMultiselectEmpty:
      "There aren't measurement units available",
    modelMultiselectAvailable: 'Models available',
    modelMultiselectSelected: 'Models selected',
    modelMultiselectEmpty: "There aren't models available",
    differentiationMultiselectAvailable: 'Avaiable values',
    differentiationMultiselectSelected: 'Selected values',
    differentiationMultiselectEmpty: "There aren't values available",
    daysForExpiration: 'Expiration days',
    name: 'Description',
    providerNames: 'Supplier Name - Percentage',
    supplyPoints: 'Num. of Supply Points',
    expirationStatus: 'Status',
    expired: 'Expired',
    riskToExpire: 'Risk to expire',
    huExpired: 'Expired HUs',
    huRiskOfExpiration: 'HUs near expiration',
    valueInEuros: 'Value in euros',
    overview: 'Overview',
    stockDifferentiationTooltip:
      '"-" ➝ Available stock <br/>Q ➝ Quality control <br/>R ➝ Return stock <br/>S ➝ Blocked stock',
    measurementUnitTooltip:
      'ST ➝ Units ➝ 10 <br/>M ➝ Meters ➝ 30 <br/>KG ➝ Kilograms ➝ 23 <br/>L ➝ Liters ➝ 50',
    expirationDaysTooltip:
      'Days until expiration. Negative is already expired.',
    expirationStatusTooltip:
      'Red ➝ Expired <br/>Orange ➝ Less than 30 days to expire <br/>Green ➝ More than 30 days to expire',
    lastUpdate: 'File update: ',
    coverage: 'Coverage',
    providerDeliveryNote: 'Delivery note',
    providerDeliveryNoteDate: 'Delivery note date',
    units: 'ST ➝ Units ➝ 10',
    meters: 'M ➝ Meters ➝ 30',
    kilograms: 'KG ➝ Kilograms ➝ 23',
    liters: 'L ➝ Liters ➝ 50'
  },
  ca: {
    pageTitle: 'Caducitats',
    searcherPlaceholder: 'Cerca per clau/s, descripció/ns o albarà/ns',
    code: 'Clau',
    handlingUnit: 'HU',
    quantity: 'Quantitat',
    warehouse: 'Magatzem',
    expirationDate: 'Data caducitat',
    measurementUnit: 'Unitat mesura',
    stockCost: 'Preu HU €',
    warehouseType: 'Tipus de magatzem',
    location: 'Ubicació',
    stockDifferentiation: 'Estat HU',
    stockDifferentiationFilter: 'Diferenciació stock',
    expirationDays: 'Dies per caducitat',
    inbounders: 'Inbounder',
    warehouses: 'Magatzem',
    models: 'Model',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionats',
    inbounderMultiselectEmpty: 'No hi ha inbounders disponibles',
    warehouseMultiselectAvailable: 'Magatzems disponibles',
    warehouseMultiselectSelected: 'Magatzems seleccionados',
    warehouseMultiselectEmpty: 'No hi ha Magatzems disponibles',
    measurementUnitsMultiselectAvailable: 'Unitats de mesura disponibles',
    measurementUnitsMultiselectSelected: 'Unitats de mesura seleccionats',
    measurementUnitsMultiselectEmpty: 'No hi ha unitats de mesura disponibles',
    modelMultiselectAvailable: 'Models disponibles',
    modelMultiselectSelected: 'Models seleccionats',
    modelMultiselectEmpty: 'No hi ha models disponibles',
    differentiationMultiselectAvailable: 'Valors disponibles',
    differentiationMultiselectSelected: 'Valors seleccionats',
    differentiationMultiselectEmpty: 'No hi ha valors disponibles',
    daysForExpiration: 'Dies per caducitat',
    name: 'Descripció',
    providerNames: 'Nom proveïdor - Percentatge',
    supplyPoints: 'Num. Punts de Subministrament',
    expirationStatus: 'Status',
    expired: 'Caducades',
    riskToExpire: 'A prop de caducar',
    huExpired: 'HUs caducades',
    huRiskOfExpiration: 'HUs a prop de caducitat',
    valueInEuros: 'Valor en euros',
    overview: 'Visió general',
    stockDifferentiationTooltip:
      '"-" ➝ Stock disponible <br/>Q ➝ Control de qualitat <br/>R ➝ Devolució <br/>S ➝ Stock bloquejat',
    measurementUnitTooltip:
      'ST ➝ Unitats ➝ 10 <br/>M ➝ Metres ➝ 30 <br/>KG ➝ Kilograms ➝ 23 <br/>L ➝ Litres ➝ 50',
    expirationDaysTooltip:
      'Dies fins al venciment. El negatiu ja està caducat.',
    expirationStatusTooltip:
      'Vermell ➝ Caducada <br/>Taronja ➝ Menys de 30 dies per a caducitat <br/>Verd ➝ Més de 30 dies per a caducitad',
    lastUpdate: 'Actualització fitxer: ',
    coverage: 'Cobertura',
    providerDeliveryNote: 'Albarà',
    providerDeliveryNoteDate: 'Data albarà',
    units: 'ST ➝ Unitats ➝ 10',
    meters: 'M ➝ Metres ➝ 30',
    kilograms: 'KG ➝ Kilograms ➝ 23',
    liters: 'L ➝ Litres ➝ 50'
  }
};
