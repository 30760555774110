export interface ICallOffFinishedGoodDTO {
  id: string;
  clientType: string;
  dealerId: string;
  currentCheckpoint: string;
  factoryWarehouseInputDate: string;
  invoiceDate: string;
  orderDate: string;
  callOffDate: string;
  fastLane: boolean;
  deliveryDays: number;
  deliveryStatus: string;
}

class CallOffFinishedGoodsListDTO {
  finishedGoods: ICallOffFinishedGoodDTO[];
  totalFinishedGoods: number;

  constructor({ finishedGoods, totalFinishedGoods }) {
    this.finishedGoods = finishedGoods;
    this.totalFinishedGoods = totalFinishedGoods;
  }
}

export default CallOffFinishedGoodsListDTO;
