import { UnloadingPointPiecesDTO } from '../../infrastructure/dto/UnloadingPointsDTO/unloadingPointPiecesDTOMapper';
import {
  IUnloadingPointPiece,
  UnloadingPointPiece
} from './UnloadingPointPiece';

export interface UnloadingPointPieces {
  pieces: IUnloadingPointPiece[];
}

export const unloadingPointPiecesMapper = (
  dto: UnloadingPointPiecesDTO
): UnloadingPointPieces => {
  return {
    pieces: dto.pieces?.map((piece) =>
      UnloadingPointPiece.generateFromDTO(piece)
    )
  };
};
