const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const TRANSFER_ENDPOINT = `${INBOUND_BASE_URL}/transfers`;
const INCOMING_MATERIALS_ENDPOINT = `/incoming_materials`;
const TRANSFER_NEXT_HOURS_ENDPOINT = `${INBOUND_BASE_URL}${INCOMING_MATERIALS_ENDPOINT}`;
const TRANSFER_CRITICAL_STOCK = '/transfers/critical-stock/summary';
const INCOMING_MATERIALS_CRITICAL_STOCK =
  '/incoming-materials/critical-stock/summary';
const TRANSFERS_FILTERS = '/transfers/filters';
const CONTAINER_NEEDS_ENDPOINT = '/containers-needed/summary';
const API_GET_TRANSFER_CRITICAL_STOCK = `${INBOUND_BASE_URL}${TRANSFER_CRITICAL_STOCK}`;
const API_GET_INCOMING_CRITICAL_STOCK = `${INBOUND_BASE_URL}${INCOMING_MATERIALS_CRITICAL_STOCK}`;
const API_GET_TRANSFERS_FILTERS = `${INBOUND_BASE_URL}${TRANSFERS_FILTERS}`;
const API_GET_TRANSFERS_NEXT_HOURS_FILTERS = `${INBOUND_BASE_URL}${INCOMING_MATERIALS_ENDPOINT}/filters`;
const API_GET_CONTAINER_NEEDS = `${INBOUND_BASE_URL}/transfers${CONTAINER_NEEDS_ENDPOINT}`;
const API_GET_CONTAINER_NEEDS_NEXT_HOURS = `${INBOUND_BASE_URL}/incoming-materials${CONTAINER_NEEDS_ENDPOINT}`;

export {
  TRANSFER_ENDPOINT,
  TRANSFER_NEXT_HOURS_ENDPOINT,
  INCOMING_MATERIALS_ENDPOINT,
  API_GET_TRANSFER_CRITICAL_STOCK,
  API_GET_INCOMING_CRITICAL_STOCK,
  API_GET_TRANSFERS_FILTERS,
  API_GET_TRANSFERS_NEXT_HOURS_FILTERS,
  API_GET_CONTAINER_NEEDS,
  API_GET_CONTAINER_NEEDS_NEXT_HOURS
};
