import { createAsyncThunk } from '@reduxjs/toolkit';
import getAllProvidersUseCase from 'modules/inbound/shared/application/AllProvidersUseCases/GetAllProvidersUseCase';

const getAllProvidersData = createAsyncThunk(
  'allProvidersData/getAllProvidersData',
  async () => {
    const response = await getAllProvidersUseCase.execute();
    return response;
  }
);

export { getAllProvidersData };
