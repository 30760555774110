import IRefillsRepository from '../domain/IRefillsRepository';
import RefillsSaturationData from '../domain/RefillsSaturationData';

import refillsRepository from '../infrastructure/repositories/RefillsRepository';

class GetRefillsSaturationService {
  private _refillsRepository: IRefillsRepository;

  constructor({ repository }) {
    this._refillsRepository = repository;
  }
  execute() {
    return this._refillsRepository
      .getRefillsSaturation()
      .then((res) => RefillsSaturationData.generateFromDTO(res));
  }
}

export default new GetRefillsSaturationService({
  repository: refillsRepository
});

export { GetRefillsSaturationService };
