const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const REFILLS_ENDPOINT = '/refills/';
const REFILLS_STATUS_ENDPOINT = 'status';
const REFILLS_ALERTS_ENDPOINT = 'alerts';
const REFILLS_SATURATION_ENDPOINT = 'saturation';
const API_GET_REFILLS = `${INBOUND_BASE_URL}${REFILLS_ENDPOINT}`;
const API_GET_REFILLS_STATUS = `${INBOUND_BASE_URL}${REFILLS_ENDPOINT}${REFILLS_STATUS_ENDPOINT}`;
const API_GET_REFILLS_ALERTS = `${INBOUND_BASE_URL}${REFILLS_ENDPOINT}${REFILLS_ALERTS_ENDPOINT}`;
const API_GET_REFILLS_SATURATION = `${INBOUND_BASE_URL}${REFILLS_ENDPOINT}${REFILLS_SATURATION_ENDPOINT}`;
export {
  API_GET_REFILLS,
  API_GET_REFILLS_STATUS,
  API_GET_REFILLS_ALERTS,
  API_GET_REFILLS_SATURATION
};
