export interface IFinishedGoodsDTO {
  id: string;
  lastRedistributionWarehouse: string;
  currentCheckpointDate: string;
  nextCheckpointETA: string;
  delayedHours: number;
  status: string;
  geolocationEta: string | undefined;
  cumulativeDelayedHours: number;
}

class FinishedGoodsListDTO {
  finishedGoods: IFinishedGoodsDTO[];
  totalFinishedGoods: number;

  constructor({ finishedGoods, totalFinishedGoods }) {
    this.finishedGoods = finishedGoods;
    this.totalFinishedGoods = totalFinishedGoods;
  }
}

export default FinishedGoodsListDTO;
