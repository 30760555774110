import { createAsyncThunk } from '@reduxjs/toolkit';
import GetDirectDeliveryFiltersService from 'modules/inbound/directDelivery/application/GetDirectDeliveryFiltersService';

const getDirectDeliveryFilters = createAsyncThunk(
  'directDeliveryFilters/getDirectDeliveryFilters',
  async () => {
    const response = await GetDirectDeliveryFiltersService.execute();
    return response;
  }
);

export { getDirectDeliveryFilters };
