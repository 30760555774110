import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSaturationUnloadingPointsUseCase } from 'modules/inbound/entries/application/UnloadingPoints/GetSaturationUnloadingPointsUseCase';
import { getEntriesBySlotAndUnloadingPointUseCase } from 'modules/inbound/entries/application/UnloadingPoints/GetEntriesBySlotAndUnloadingPointUseCase';
import { SelectedUnloadingPointSlot } from './types';
import { getSelectedTransportId, getSelectedUnloadingPointSlot } from '.';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { getSelectedFilters } from '../SaturationUnloadingPointsFilters';
import { SelectedFilters } from '../SaturationUnloadingPointsFilters/types';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { unloadingPointsQueryStringBuilder } from 'modules/inbound/entries/application/UnloadingPoints/UnloadingPointsQueryStringBuilder';
import { getPiecesInEntryUnloadingPointUseCase } from 'modules/inbound/entries/application/UnloadingPoints/GetPiecesInEntryUnloadingPointUseCase';

const getSaturationUnloadingPoints = createAsyncThunk(
  'saturationUnloadingPoints/getSaturationUnloadingPoints',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedUnloadingPoints,
      selectedCarriers,
      selectedSuppliers,
      selectedWarehouses
    }: SelectedFilters = getSelectedFilters(appState);

    const filters = {
      [NameValues.UNLOADING_POINT]: selectedUnloadingPoints.map(
        (unloadingPoint) => unloadingPoint.key
      ),
      [NameValues.CARRIER]: selectedCarriers.map((carrier) => carrier.key),
      [NameValues.SUPPLIER]: selectedSuppliers.map((supplier) => supplier.key),
      [NameValues.WAREHOUSE]: selectedWarehouses.map(
        (warehouse) => warehouse.key
      )
    };

    const queryString = unloadingPointsQueryStringBuilder(filters);

    const queryParams = {
      search: queryString && `{${queryString}}`
    };

    const response = await getSaturationUnloadingPointsUseCase.execute({
      queryParams
    });
    return response;
  }
);

const getSaturationUnloadingPointsDetail = createAsyncThunk(
  'saturationUnloadingPoints/getSaturationUnloadingPointsDetail',
  async (_, { getState }) => {
    const appState = getState();
    const { date, unloadingPoint }: SelectedUnloadingPointSlot =
      getSelectedUnloadingPointSlot(appState);
    const {
      selectedCarriers,
      selectedSuppliers,
      selectedWarehouses
    }: SelectedFilters = getSelectedFilters(appState);

    const queryParams = {
      start_time_from: date,
      start_time_to: GetDateFormatedService.addHoursToUtcDate(date),
      unloading_point: unloadingPoint,
      carrier: selectedCarriers.map((carrier) => carrier.key).join(';'),
      supplier: selectedSuppliers.map((supplier) => supplier.key).join(';'),
      warehouse: selectedWarehouses.map((warehouse) => warehouse.key).join(';')
    };

    const response = await getEntriesBySlotAndUnloadingPointUseCase.execute({
      queryParams
    });

    return response;
  }
);

const getUnloadingPointPiecesDetail = createAsyncThunk(
  'saturationUnloadingPoints/getUnloadingPointPiecesDetail',
  async (_, { getState }) => {
    const appState = getState();

    const { unloadingPoint }: SelectedUnloadingPointSlot =
      getSelectedUnloadingPointSlot(appState);

    const transportId = getSelectedTransportId(appState);

    const queryParams = {
      unloading_point: unloadingPoint
    };

    const response = await getPiecesInEntryUnloadingPointUseCase.execute({
      queryParams,
      transportId
    });

    return response;
  }
);

export {
  getSaturationUnloadingPoints,
  getSaturationUnloadingPointsDetail,
  getUnloadingPointPiecesDetail
};
