import LocaleService from 'infrastructure/i18n/LocaleService';
import { ResponseText } from 'modules/inbound/shared/infraestructure/i18n/responseText';
import { ResponseTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import UploadProviderCodesDTO from '../../infraestructure/dto/UploadProviderCodesDTO';

const inboundText: ResponseTranslations = {
  ...ResponseText[LocaleService.getLanguage()]
};

const DashboardUploadErrorCodeMessages = {
  1: inboundText.missingCols,
  2: inboundText.missingValues,
  3: inboundText.tooLarge,
  4: inboundText.codeNotFound,
  5: inboundText.unauthorizedProviders,
  6: inboundText.keysWithoutProviderCode,
  7: inboundText.duplicateKeys,
  401: inboundText.unauthorizedUser,
  500: inboundText.otherCauses
};

class UploadProviderCodes {
  statusCode: number;
  message: string;

  constructor({ statusCode, message }) {
    this.statusCode = statusCode;
    this.message = message;
  }
  static generateFromDto({
    statusCode,
    errorCode,
    message,
    providerIds
  }: UploadProviderCodesDTO): UploadProviderCodes {
    let messageResponse = '';
    if (errorCode) {
      if (providerIds?.length) {
        messageResponse = inboundText.unauthorizedProviders(providerIds);
      } else {
        messageResponse = DashboardUploadErrorCodeMessages[errorCode];
      }
    }

    return new UploadProviderCodes({
      statusCode: statusCode,
      message: messageResponse ?? message
    });
  }

  static generateEmpty() {
    return new UploadProviderCodes({
      statusCode: 0,
      message: ''
    });
  }
}

export { UploadProviderCodes };
