import { createSlice } from '@reduxjs/toolkit';
import { FinishedGoodsByCheckpointState, TimelineSectionColors } from './types';
import {
  getFinishedGoodCheckpointsTimeline,
  getFinishedGoodsByCheckpoint,
  getFinishedGoodTransportDetails,
  getTransportDetailsById
} from './async';
import { getCheckpointTypeFromCheckpointRef } from '../DeliveriesGeolocationCheckpointsReducer';
import { CheckpointType } from 'modules/outbound/shared/domain/CheckpointType';
import { DETAILED_CHECKPOINTS_DICTIONARY } from 'modules/outbound/shared/domain/checkpointsDictionary';
import localeService from 'infrastructure/i18n/LocaleService';

export const initialState: FinishedGoodsByCheckpointState = {
  finishedGoods: [],
  totalFinishedGoods: 0,
  checkpointRef: '',
  countryCode: '',
  selectedVin: '',
  timelineDetailsSelected: false,
  transportDetailsSelected: false,
  transportDetails: {
    transportId: '',
    frameNumber: '',
    origin: '',
    destination: '',
    carrierName: '',
    departureDate: '',
    currentLocation: '',
    locationDate: '',
    eta: '',
    vin: '',
    cargoVins: []
  },
  transportDetailsError: '',
  checkpoints: {
    vin: '',
    actualTimeline: [],
    expectedTimeline: [],
    actualTimelineTotalDays: 0,
    expectedTimelineTotalDays: 0
  },
  filters: {
    page: 1,
    resultsPerPage: 10
  },
  requestStatus: '',
  detailedCheckpointsRequest: '',
  detailedTransportRequest: ''
};

const finishedGoodsByCheckpointSlice = createSlice({
  name: 'finishedGoodsByCheckpoint',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.filters.page = action.payload;
    },
    updateCheckpointRef: (state, action) => {
      state.checkpointRef = action.payload;
    },
    updateFinishedGoods: (state, action) => {
      state.finishedGoods = action.payload;
    },
    selectFinishedGood: (state, action) => {
      state.selectedVin = action.payload;
    },
    showTimelineDetails: (state, action) => {
      state.timelineDetailsSelected = action.payload;
    },
    showTransportDetails: (state, action) => {
      state.transportDetailsSelected = action.payload;
    },
    restoreTransportDetailsError: (state) => {
      state.transportDetailsError = initialState.transportDetailsError;
    },
    restoreTransportDetails: (state) => {
      state.transportDetails = initialState.transportDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFinishedGoodsByCheckpoint.fulfilled, (state, action) => {
      state.finishedGoods = action.payload.finishedGoods || [];
      state.totalFinishedGoods = action.payload.totalFinishedGoods || 0;
      state.checkpointRef = action.payload.checkpointRef || '';
      state.countryCode = action.payload.countryCode || '';
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFinishedGoodsByCheckpoint.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getFinishedGoodsByCheckpoint.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.fulfilled,
      (state, action) => {
        state.checkpoints.vin = action.payload.vin;
        state.checkpoints.actualTimeline = action.payload.actualTimeline;
        state.checkpoints.expectedTimeline = action.payload.expectedTimeline;
        state.checkpoints.actualTimelineTotalDays =
          action.payload.actualTimelineTotalDays;
        state.checkpoints.expectedTimelineTotalDays =
          action.payload.expectedTimelineTotalDays;
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.pending,
      (state, action) => {
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getFinishedGoodCheckpointsTimeline.rejected,
      (state, action) => {
        state.detailedCheckpointsRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getFinishedGoodTransportDetails.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.transportDetails.transportId = action.payload.transportId;
          state.transportDetails.frameNumber = action.payload.frameNumber;
          state.transportDetails.origin = action.payload.origin;
          state.transportDetails.destination = action.payload.destination;
          state.transportDetails.carrierName = action.payload.carrierName;
          state.transportDetails.departureDate = action.payload.departureDate;
          state.transportDetails.currentLocation =
            action.payload.currentLocation;
          state.transportDetails.locationDate = action.payload.locationDate;
          state.transportDetails.eta = action.payload.eta;
          state.transportDetails.vin = action.payload.vin;
          state.transportDetails.cargoVins = action.payload.cargoVins;
          state.detailedTransportRequest = action.meta.requestStatus;
          state.transportDetailsError = initialState.transportDetailsError;
        }
      }
    );
    builder.addCase(
      getFinishedGoodTransportDetails.pending,
      (state, action) => {
        state.detailedTransportRequest = action.meta.requestStatus;
        state.transportDetailsError = initialState.transportDetailsError;
      }
    );
    builder.addCase(
      getFinishedGoodTransportDetails.rejected,
      (state, action) => {
        if (action.error.message === 'noGeoError') {
          state.transportDetailsError = action.error.message;
        }
        state.detailedTransportRequest = action.meta.requestStatus;
      }
    );
    builder.addCase(getTransportDetailsById.fulfilled, (state, action) => {
      if (action.payload) {
        state.transportDetails.transportId = action.payload.transportId;
        state.transportDetails.frameNumber = action.payload.frameNumber;
        state.transportDetails.origin = action.payload.origin;
        state.transportDetails.destination = action.payload.destination;
        state.transportDetails.carrierName = action.payload.carrierName;
        state.transportDetails.departureDate = action.payload.departureDate;
        state.transportDetails.currentLocation = action.payload.currentLocation;
        state.transportDetails.locationDate = action.payload.locationDate;
        state.transportDetails.eta = action.payload.eta;
        state.transportDetails.cargoVins = action.payload.cargoVins;
        state.transportDetailsError = initialState.transportDetailsError;
      }
      state.detailedTransportRequest = action.meta.requestStatus;
    });
    builder.addCase(getTransportDetailsById.pending, (state, action) => {
      state.detailedTransportRequest = action.meta.requestStatus;
      state.transportDetailsError = initialState.transportDetailsError;
    });
    builder.addCase(getTransportDetailsById.rejected, (state, action) => {
      if (action.error.message === 'noGeoError') {
        state.transportDetailsError = action.error.message;
      }
      state.detailedTransportRequest = action.meta.requestStatus;
    });
  }
});

//selectors
export const getfinishedGoodsByCheckpoint = (state) => {
  const selectedCheckpointType = getCheckpointTypeFromCheckpointRef(
    state,
    state.finishedGoodsByCheckpointState.checkpointRef
  );
  if (
    selectedCheckpointType === CheckpointType.WAREHOUSE ||
    selectedCheckpointType === CheckpointType.FACTORY
  ) {
    return state.finishedGoodsByCheckpointState.finishedGoods;
  }
  return state.finishedGoodsByCheckpointState.finishedGoods.map((item) => {
    delete item.currentCheckpointDate;
    return item;
  });
};

export const getfinishedGoodsByCheckpointKeys = (state) => {
  const finishedGoods = getfinishedGoodsByCheckpoint(state);
  return finishedGoods.length ? Object.keys(finishedGoods[0]) : [];
};

export const getRequestStatus = (state) =>
  state.finishedGoodsByCheckpointState.requestStatus;

export const getSelectedCheckpoint = (state) =>
  state.finishedGoodsByCheckpointState.checkpointRef;

export const getSelectedCountry = (state) =>
  state.finishedGoodsByCheckpointState.countryCode;

export const getCurrentPage = (state) =>
  state.finishedGoodsByCheckpointState.filters.page;

export const getResultsPerPage = (state) =>
  state.finishedGoodsByCheckpointState.filters.resultsPerPage;

export const getTotalPages = (state) =>
  Math.ceil(
    state.finishedGoodsByCheckpointState.totalFinishedGoods /
      state.finishedGoodsByCheckpointState.filters.resultsPerPage
  );

export const getSelectedVin = (state) =>
  state.finishedGoodsByCheckpointState.selectedVin;

export const getShouldShowTimelineDetails = (state) =>
  state.finishedGoodsByCheckpointState.timelineDetailsSelected;

export const getShouldShowTransportDetails = (state) =>
  state.finishedGoodsByCheckpointState.transportDetailsSelected;

export const getTransportDetails = (state) =>
  state.finishedGoodsByCheckpointState.transportDetails;

export const getTimelineCheckpointsRequest = (state) =>
  state.finishedGoodsByCheckpointState.detailedCheckpointsRequest;

export const getTransportDetailsRequestIsRejected = (state) =>
  state.finishedGoodsByCheckpointState.detailedTransportRequest === 'rejected';

export const getTransportDetailsRequestIsPending = (state) =>
  state.finishedGoodsByCheckpointState.detailedTransportRequest === 'pending';

export const getTransportDetailsError = (state) =>
  state.finishedGoodsByCheckpointState.transportDetailsError;

export const getCheckpointsInfo = (state) =>
  state.finishedGoodsByCheckpointState.checkpoints;

export const getSectionsToDisplay = (state) => {
  const reducer = (accumulator, currentValue) =>
    accumulator + (currentValue.days || 0.5);

  const expectedTimelineTotalDays =
    state.finishedGoodsByCheckpointState.checkpoints.expectedTimeline.reduce(
      reducer,
      0
    );

  const actualTimelineTotalDays =
    state.finishedGoodsByCheckpointState.checkpoints.actualTimeline.reduce(
      reducer,
      0
    );

  const largerTotalDays =
    expectedTimelineTotalDays > actualTimelineTotalDays
      ? expectedTimelineTotalDays
      : actualTimelineTotalDays || 1;

  const mapper = (item) => ({
    value: String(item.date.fromDate),
    color:
      TimelineSectionColors[item.checkpointKey] ||
      TimelineSectionColors.default,
    width: String(Math.round(((item.days || 0.5) / largerTotalDays) * 100)),
    isShort: item.days <= 2,
    finalDate: String(item.date.toDate)
  });

  const lang = localeService.getLanguage();
  const dictionaryGroup = DETAILED_CHECKPOINTS_DICTIONARY.find(
    (group) => group.language === lang
  );

  const legend = Object.keys(TimelineSectionColors)
    .filter((section) => section !== 'default')
    .map((section) => ({
      name: dictionaryGroup?.checkpoints[section] || 'Unknown',
      color: TimelineSectionColors[section]
    }));

  return {
    actualTimeline:
      state.finishedGoodsByCheckpointState.checkpoints.actualTimeline.map(
        mapper
      ),
    expectedTimeline:
      state.finishedGoodsByCheckpointState.checkpoints.expectedTimeline.map(
        mapper
      ),
    legend,
    actualTimelineTotalDays:
      state.finishedGoodsByCheckpointState.checkpoints.actualTimelineTotalDays,
    expectedTimelineTotalDays:
      state.finishedGoodsByCheckpointState.checkpoints
        .expectedTimelineTotalDays,
    vin: state.finishedGoodsByCheckpointState.checkpoints.vin
  };
};

//actions
export const {
  updateCurrentPage,
  updateFinishedGoods,
  updateCheckpointRef,
  selectFinishedGood,
  showTimelineDetails,
  showTransportDetails,
  restoreTransportDetailsError,
  restoreTransportDetails
} = finishedGoodsByCheckpointSlice.actions;

export default finishedGoodsByCheckpointSlice.reducer;
