import {
  NameValues,
  StatusTypes,
  TimeGraphStatus
} from 'modules/inbound/shared/domain/NameValues';
import { FilterProps } from 'modules/inbound/supplies/domain/SuppliesFilters';
import { SuppliesStatusType } from 'modules/inbound/supplies/infrastructure/dto/types';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';

export interface Filters {
  linesGroup: string[];
  warehousesSourceType: string[];
  warehousesSource: string[];
  manufacturingsSection: string[];
  suppliesType: string[];
  materialsIndicator: FilterProps[];
  phases: FilterProps[];
  lines: string[];
  warehousesTarget: string[];
}

export const SelectedFilterKeys = {
  selectedLinesGroup: NameValues.LINE_GROUP,
  selectedWarehousesSourceType: NameValues.WAREHOUSE_SOURCE_TYPE,
  selectedWarehousesSource: NameValues.WAREHOUSE_SOURCE,
  selectedManufacturingsSection: NameValues.MANUFACTURING_SECTION,
  selectedSuppliesType: NameValues.SUPPLY_TYPE,
  selectedMaterialsIndicator: NameValues.MATERIAL_INDICATOR,
  selectedPhases: NameValues.PHASE,
  selectedLines: NameValues.LINE,
  selectedWarehousesTarget: NameValues.WAREHOUSE_TARGET
} as const;

export interface SelectedSubHeaderFilters {
  selectedLinesGroup: SelectItemProps[];
  selectedWarehousesSourceType: SelectItemProps[];
  selectedWarehousesSource: SelectItemProps[];
  selectedManufacturingsSection: SelectItemProps[];
  selectedSuppliesType: SelectItemProps[];
  selectedMaterialsIndicator: SelectItemProps[];
  selectedPhases: SelectItemProps[];
  selectedLines: SelectItemProps[];
  selectedWarehousesTarget: SelectItemProps[];
}

interface SelectedWarehouseStatus {
  status: StatusTypes | null;
  name: string;
}

export interface BlockedSubheaderFilters {
  warehousesSourceType: boolean;
  warehousesSource: boolean;
  warehousesTarget: boolean;
  phase: boolean;
}

export interface FiltersReducerProps {
  filters: Filters;
  requestStatus: string;
  search: string;
  selectedRequestStatus: TimeGraphStatus;
  selectedClosedStatus: TimeGraphStatus;
  selectedWarehouseRanking: SelectedWarehouseStatus;
  selectedSubzone: SelectedWarehouseStatus;
  selectedDestinationRanking: SelectedWarehouseStatus;
  selectedStatusType: SuppliesStatusType | null;
  selectedCriticalStockType: string;
  selectedSubHeaderFilters: SelectedSubHeaderFilters;
  blockedSubheaderFilters: BlockedSubheaderFilters;
  isFilterSaved: boolean;
}
