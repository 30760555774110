const OVERVIEW_ENDPOINT = '/outbound/stock/warehouse';
const PIPELINE_ENDPOINT = '/outbound/stock/pipeline';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_GET_OVERVIEW = BASE_URL + OVERVIEW_ENDPOINT;
const API_GET_VEHICLES = API_GET_OVERVIEW + '/vehicles';
const API_GET_FORECAST = API_GET_OVERVIEW + '/forecast';
const API_GET_PIPELINE = BASE_URL + PIPELINE_ENDPOINT;
export {
  API_GET_OVERVIEW,
  API_GET_PIPELINE,
  API_GET_VEHICLES,
  API_GET_FORECAST
};
