export interface ITopFlowsLackCapacityDTO {
  deviationByFlow: DeviationByFlow[];
  negativeDeviationFlowsQuantity: number;
  negativeDeviationPercentage: number;
}

export interface DeviationByFlow {
  flow: string;
  deviation: number;
}

class TopFlowsLackCapacityDTO implements ITopFlowsLackCapacityDTO {
  deviationByFlow: DeviationByFlow[];
  negativeDeviationFlowsQuantity: number;
  negativeDeviationPercentage: number;

  constructor({
    deviationByFlow,
    negativeDeviationFlowsQuantity,
    negativeDeviationPercentage
  }) {
    this.deviationByFlow = deviationByFlow.map((deviation: DeviationByFlow) => {
      return {
        flow: deviation.flow,
        deviation: deviation.deviation
      };
    });
    this.negativeDeviationFlowsQuantity = negativeDeviationFlowsQuantity;
    this.negativeDeviationPercentage = negativeDeviationPercentage;
  }
}

export default TopFlowsLackCapacityDTO;
