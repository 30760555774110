export interface LoadsStatusDTO {
  ACP: number;
  DLY: number;
  PND: number;
  RQS: number;
}

export const loadsStatusDTOMapper = (data: any): LoadsStatusDTO => ({
  ACP: data?.loadsStatus?.ACP || 0,
  DLY: data?.loadsStatus?.DLY || 0,
  PND: data?.loadsStatus?.PND || 0,
  RQS: data?.loadsStatus?.RQS || 0
});
