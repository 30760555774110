class GlobalDelayDTO {
  total: number;
  cumulativeDelay: number;
  globalDelayed: number;

  constructor({ total, cumulativeDelay, globalDelayed }) {
    this.total = total;
    this.cumulativeDelay = cumulativeDelay;
    this.globalDelayed = globalDelayed;
  }
}

export default GlobalDelayDTO;
