import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from 'modules/shared/hooks/useField';
import { FormInput } from '../../elements/Inputs/FormInput';
import { Button, Link } from '../../pages/LoginPage/styles';
import { ForgotPasswordFormProps } from './types';
import {
  lowerCase,
  number,
  upperCase,
  length,
  specialCharacter,
  validatePassword
} from './PasswordValidation';
import { Tag } from '../../elements/Tag';
import { WrapperTags } from './styles';
import {
  getForgotPasswordCodeError,
  getForgotPasswordRequestStatusFulfilled,
  getForgotPasswordRequestStatusRejected,
  resetRequestStatus
} from 'modules/shared/infrastructure/redux/UserReducer';
import { toast } from 'react-toastify';
import { LoginTranslations } from 'modules/shared/infrastructure/i18n/Login/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { LoginText } from 'modules/shared/infrastructure/i18n/Login';

const loginTranslations: LoginTranslations = {
  ...LoginText[LocaleService.getLanguage()]
};

export const ForgotPasswordForm = ({
  handleRecoveryPassword,
  handleResendCode,
  handleBackToLogin
}: ForgotPasswordFormProps) => {
  const dispatch = useDispatch();

  const forgotPasswordRequestStatusRejected = useSelector(
    getForgotPasswordRequestStatusRejected
  );
  const forgotPasswordRequestStatusFulfilled = useSelector(
    getForgotPasswordRequestStatusFulfilled
  );

  const forgotPasswordCodeError = useSelector(getForgotPasswordCodeError);

  const username = useField({ type: 'text' });
  const code = useField({ type: 'text' });

  const password = useField({
    type: 'password',
    validation: {
      validate: validatePassword,
      errorMessage: loginTranslations.invalidPassword
    }
  });
  const repeatedPassword = useField({
    type: 'password',
    validation: {
      validate: validatePassword,
      errorMessage: '*'
    }
  });

  const handleValidateCodeAndNewPassword = (event) => {
    event.preventDefault();
    handleRecoveryPassword({
      username: username.value,
      code: code.value,
      password: password.value
    });
  };

  useEffect(() => {
    if (forgotPasswordRequestStatusFulfilled) {
      handleBackToLogin();
    }

    if (forgotPasswordRequestStatusRejected) {
      code.reset();
      dispatch(resetRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forgotPasswordRequestStatusFulfilled,
    forgotPasswordRequestStatusRejected
  ]);

  useEffect(() => {
    forgotPasswordCodeError && toast.error(loginTranslations.invalidCode);
  }, [forgotPasswordCodeError]);

  useEffect(() => {
    forgotPasswordRequestStatusRejected &&
      !forgotPasswordCodeError &&
      toast.error(loginTranslations.errorChangingPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>{loginTranslations.recoverPassword}</h3>
      <form
        onSubmit={handleValidateCodeAndNewPassword}
        data-cy="forgot-password-form"
      >
        <FormInput
          {...username}
          name="username"
          label={loginTranslations.username}
          required
        />
        <FormInput
          {...code}
          name="code"
          label={loginTranslations.codeReceived}
          required
        />
        <FormInput
          {...password}
          name="password"
          label={loginTranslations.password}
          autoComplete="new-password"
          required
        />
        <WrapperTags>
          <Tag success={upperCase.test(password.value)}>
            <span>{loginTranslations.oneCapitalLetter}</span>
          </Tag>
          <Tag success={lowerCase.test(password.value)}>
            <span>{loginTranslations.oneLowercase}</span>
          </Tag>
          <Tag success={specialCharacter.test(password.value)}>
            <span>{loginTranslations.oneSpecialChar}</span>
          </Tag>
          <Tag success={number.test(password.value)}>
            <span>{loginTranslations.oneNumber}</span>
          </Tag>
          <Tag success={length.test(password.value)}>
            <span>{loginTranslations.lengthChars}</span>
          </Tag>
        </WrapperTags>
        <FormInput
          {...repeatedPassword}
          name="repeatedPassword"
          label={loginTranslations.repeatPassword}
          autoComplete="new-password"
          required
        />
        <WrapperTags>
          <Tag
            success={
              !!password.value.length &&
              password.value === repeatedPassword.value
            }
          >
            <span>{loginTranslations.passwordsMatch}</span>
          </Tag>
        </WrapperTags>
        <Link type="button" onClick={handleResendCode}>
          {loginTranslations.resubmitCode}
        </Link>
        <Button
          type="submit"
          disabled={
            !username.value.length ||
            !code.value.length ||
            !validatePassword(password.value) ||
            password.value !== repeatedPassword.value
          }
        >
          {loginTranslations.changePassword}
        </Button>
      </form>
    </>
  );
};
