class EntriesParalyzationDTO {
  entitled_to_paralyzation: number;
  in_paralyzation: {
    entries: number;
    global_dlz: number;
  };
  near_paralyzation: number;

  constructor({
    entitled_to_paralyzation,
    in_paralyzation,
    near_paralyzation
  }) {
    this.entitled_to_paralyzation = entitled_to_paralyzation;
    this.in_paralyzation = in_paralyzation;
    this.near_paralyzation = near_paralyzation;
  }
}

export { EntriesParalyzationDTO };
