import FinishedGoodTransportDetails from '../domain/FinishedGoodTransportDetails';
import ITransitRepository from '../domain/ITransitRepository';
import deliveriesGeolocationRepository from '../infrastructure/repositories/DeliveriesGeolocationRepository';

class GetFinishedGoodTransportDetailsUseCase {
  repository: ITransitRepository;

  constructor({ repository }) {
    this.repository = repository;
  }

  execute({ vin }: { vin: string }) {
    return this.repository.getFinishedGoodTransportDetails!({ vin })
      .then((transportData) =>
        FinishedGoodTransportDetails.generateFromDTO(transportData)
      )
      .catch((error) => {
        if (error.message === 'Error: 404') {
          throw new Error('noGeoError');
        }
      });
  }
}

export default new GetFinishedGoodTransportDetailsUseCase({
  repository: deliveriesGeolocationRepository
});

export { GetFinishedGoodTransportDetailsUseCase };
