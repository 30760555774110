export interface FamilyAlarms {
  alerts: number;
  total: number;
}

export interface FamilyCoverageItemDTO {
  lines: string[];
  codes: FamilyAlarms;
  coverage: number;
  name: string;
}

class JitFamilyCoverageDTO {
  alerts_total: number;
  coverage: number;
  families: FamilyCoverageItemDTO[];

  constructor({ alerts_total, coverage, families }) {
    this.alerts_total = alerts_total;
    this.coverage = coverage;
    this.families = families;
  }
}

export default JitFamilyCoverageDTO;
