import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WrapperForm, WrapperLogin, WrapperPage } from './styles';
import scctLogo from 'assets/logos/scct-small.svg';
import { LoginForm } from '../../containers/LoginForm';
import { WelcomeMessage } from './WelcomeMessage';
import { PasswordRecovery } from '../../containers/PasswordRecovery';
import seatCodePoweredLogo from 'assets/logos/seat-code-powered.svg';
import {
  getForgotPasswordRequestStatusFulfilled,
  getLoginRequestStatusRejected,
  getNewPasswordRequired
} from 'modules/shared/infrastructure/redux/UserReducer';
import { toast } from 'react-toastify';
import LanguageSelector from '../../components/LanguageSelector';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { LoginTranslations } from 'modules/shared/infrastructure/i18n/Login/typing';
import { LoginText } from 'modules/shared/infrastructure/i18n/Login';

const loginTranslations: LoginTranslations = {
  ...LoginText[LocaleService.getLanguage()]
};

export const LoginPage = () => {
  const isLoginRejected = useSelector(getLoginRequestStatusRejected);
  const newPasswordRequired = useSelector(getNewPasswordRequired);
  const forgotPasswordRequestStatusFulfilled = useSelector(
    getForgotPasswordRequestStatusFulfilled
  );

  const [forgotPassword, setForgotPassword] = useState(false);

  const handleForgotPasswordClick = () => {
    setForgotPassword(true);
  };

  const handleBackToLoginClick = () => {
    setForgotPassword(false);
  };

  useEffect(() => {
    isLoginRejected && toast.error(loginTranslations.invalidUsernameOrPassword);
    forgotPasswordRequestStatusFulfilled &&
      toast.success(loginTranslations.passwordChanged);
  }, [forgotPasswordRequestStatusFulfilled, isLoginRejected]);

  return (
    <WrapperPage>
      <WelcomeMessage />
      <WrapperLogin>
        <img src={scctLogo} alt="Control tower logo" />
        <LanguageSelector />
        <h3>Control Tower</h3>
        <WrapperForm>
          {!forgotPassword && !newPasswordRequired && (
            <LoginForm handleForgotPassword={handleForgotPasswordClick} />
          )}
          {(forgotPassword || newPasswordRequired) && (
            <PasswordRecovery
              handleBackToLogin={handleBackToLoginClick}
              newPasswordRequired={newPasswordRequired}
            />
          )}
        </WrapperForm>
        <img
          className="seat-code-logo"
          src={seatCodePoweredLogo}
          alt="powered by seat:code"
        />
      </WrapperLogin>
    </WrapperPage>
  );
};
