import { createAsyncThunk } from '@reduxjs/toolkit';
import getWarehouseStockByModelService from 'modules/outbound/stock/services/GetWarehouseStockByModelService';
import {
  getFastLaneSelected,
  getSelected,
  getWarehouseStock
} from '../WarehouseStockReducer';

const getWarehouseStockByModel = createAsyncThunk(
  `warehouseStockByModel/getWarehouseStockByModel`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selected = getSelected(appState);
    const fastLaneSelected = getFastLaneSelected(appState);
    const warehouseStock = getWarehouseStock(appState, selected);
    const queryParams = { fastLane: fastLaneSelected };
    const response = await getWarehouseStockByModelService.get({
      warehouseReference: selected,
      queryParams,
      warehouseStock
    });
    return response;
  }
);

export { getWarehouseStockByModel };
