import { createAsyncThunk } from '@reduxjs/toolkit';
import GetJitFamiliesFiltersService from 'modules/inbound/jit/application/GetJitFamiliesFiltersService';
import {
  NameValues,
  FiltersItem
} from 'modules/inbound/shared/domain/NameValues';
import { getSelectedFilters } from '.';

const getFamiliesCoverageFilters = createAsyncThunk(
  'familiesCoverageFilters/getJitFamiliesFilters',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedLines,
      selectedTier1ProviderIds,
      selectedLogisticOperatorsIds,
      selectedFlowType,
      selectedInbounders,
      selectedFamilies
    } = getSelectedFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.FAMILY,
        values: selectedFamilies
      },
      {
        name: NameValues.LINE,
        values: selectedLines
      },
      {
        name: NameValues.PROVIDER_TIER_1_ID,
        values: selectedTier1ProviderIds.map(({ key, text }) => ({
          key: key.split('-')[0].trim(),
          text: text
        }))
      },
      {
        name: NameValues.LOGISTIC_OPERATOR_ID,
        values: selectedLogisticOperatorsIds
      },
      {
        name: NameValues.INBOUNDER,
        values: selectedInbounders
      }
    ];

    !!selectedFlowType.key &&
      queryFilters.push({
        name: NameValues.FLOW_TYPE,
        values: [selectedFlowType]
      });

    let querySearch: string[] = [];

    queryFilters.forEach((filter) => {
      const values = filter.values.map((value) => value.key);

      if (filter.values.length === 0) {
        return;
      }

      if (
        filter.name === NameValues.LINE ||
        filter.name === NameValues.INBOUNDER
      ) {
        querySearch.push(`${filter.name}:IN:(${values.join(';')})`);
      } else {
        querySearch.push(`${filter.name}:=:${values}`);
      }
    });

    const query = querySearch.filter((item) => item !== '').join('||');
    const queryParams = {
      search: query && `{${query}}`
    };
    const response = await GetJitFamiliesFiltersService.execute({
      queryParams
    });
    return response;
  }
);

export { getFamiliesCoverageFilters };
