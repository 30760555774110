import { createSlice } from '@reduxjs/toolkit';
import { PipelineStockState } from './types';
import { downloadPipelineStock, getPipelineStock } from './async';
import PipelineStock from 'modules/outbound/stock/domain/PipelineStock';
import { BrandEnum } from 'modules/shared/domain/enums';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';

export const initialState: PipelineStockState = {
  summary: PipelineStock.generateEmpty(),
  models: [],
  selected: '',
  resumeCardSelected: '',
  filters: {
    fastLaneSelected: false,
    selectedBrands: [BrandEnum.CUPRA, BrandEnum.SEAT],
    selectedRegions: []
  },
  file: null,
  downloadRequestStatus: '',
  requestStatus: ''
};

const deliveriesGeolocationSlice = createSlice({
  name: 'pipelineStock',
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
    },
    selectFastLane: (state, action) => {
      state.filters.fastLaneSelected = action.payload;
    },
    updateSelectedBrands: (state, action) => {
      state.filters.selectedBrands = action.payload;
      const selectedCard = state.selected;
      if (selectedCard.length) {
        const references = selectedCard.split('-');
        const model = references[1];
        if (action.payload.length === 1) {
          if (action.payload[0] === 'S') {
            state.selected = `S-${model}`;
          } else if (action.payload[0] === 'C') {
            state.selected = `C-${model}`;
          }
        } else if (action.payload.length > 1) {
          state.selected = `All-${model}`;
        }
      }
    },
    updateResumeCardSelected: (state, action) => {
      state.resumeCardSelected = action.payload;
    },
    updateSelectedRegions: (state, action) => {
      state.filters.selectedRegions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPipelineStock.fulfilled, (state, action) => {
      const updateData = () => {
        state.summary = action.payload.summary || PipelineStock.generateEmpty();
        state.models = action.payload.models || [];
        if (state.resumeCardSelected.length) {
          const selectedModel = state.models.find(
            (item) => item.id === state.resumeCardSelected
          );
          if (!selectedModel) {
            state.resumeCardSelected = '';
          }
        }
        state.requestStatus = action.meta.requestStatus;
      };
      if (
        state.filters.selectedBrands.length > 1 &&
        action.payload.summary.brand === 'Seat+Cupra'
      ) {
        updateData();
      } else if (
        state.filters.selectedBrands.length === 1 &&
        state.filters.selectedBrands[0] === BrandEnum.SEAT &&
        action.payload.summary.brand === 'Seat'
      ) {
        updateData();
      } else if (
        state.filters.selectedBrands.length === 1 &&
        state.filters.selectedBrands[0] === BrandEnum.CUPRA &&
        action.payload.summary.brand === 'Cupra'
      ) {
        updateData();
      } else {
        state.requestStatus = 'rejected';
      }
    });
    builder.addCase(getPipelineStock.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getPipelineStock.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadPipelineStock.fulfilled, (state, action) => {
      state.file = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: 'pipeline_stock'
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadPipelineStock.pending, (state, action) => {
      state.file = null;
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadPipelineStock.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//actions
export const {
  updateResumeCardSelected,
  updateSelected,
  selectFastLane,
  updateSelectedBrands,
  updateSelectedRegions
} = deliveriesGeolocationSlice.actions;

//selectors
export const getResumeCardSelected = (state) =>
  state.pipelineStockState.resumeCardSelected;

export const getSelectedCard = (state) => state.pipelineStockState.selected;

export const getModelsForResumeCards = (state) => {
  return state.pipelineStockState.models.map((model) => ({
    id: model.id,
    brand: model.brand,
    model: model.model,
    stockTotal: model.stockTotal,
    zp8Aging: model.inTransitAging.totalIntervalValues,
    invoicedAging: model.invoicedAging.invoicedTotalIntervalValues,
    coverage: {
      value: model.simpleCoverage.totalValue,
      percentage: Math.round(
        (model.simpleCoverage.totalValue / COVERAGE_RANGE) * 100
      )
    },
    image: model.image
  }));
};

export const getSelectedModel = (state) => {
  if (!state.pipelineStockState.resumeCardSelected) {
    return {
      ...state.pipelineStockState.summary,
      invoicedAging: {
        warehouseIntervalValues:
          state.pipelineStockState.summary.invoicedAging
            .warehouseIntervalValues,
        dealerIntervalValues:
          state.pipelineStockState.summary.invoicedAging.dealerIntervalValues,
        restIntervalValues:
          state.pipelineStockState.summary.invoicedAging.restIntervalValues
      }
    };
  }
  const selectedModel = state.pipelineStockState.models.find(
    (item) => item.id === state.pipelineStockState.resumeCardSelected
  );
  return {
    ...selectedModel,
    invoicedAging: {
      warehouseIntervalValues:
        selectedModel.invoicedAging.warehouseIntervalValues,
      dealerIntervalValues: selectedModel.invoicedAging.dealerIntervalValues,
      restIntervalValues: selectedModel.invoicedAging.restIntervalValues
    }
  };
};

export const getModels = (state) =>
  state.pipelineStockState.models.map((model) => ({
    ...model,
    invoicedAging: {
      warehouseIntervalValues: model.invoicedAging.warehouseIntervalValues,
      dealerIntervalValues: model.invoicedAging.dealerIntervalValues,
      restIntervalValues: model.invoicedAging.restIntervalValues
    }
  }));

export const getSelectedBrands = (state) =>
  state.pipelineStockState.filters.selectedBrands;

export const getSelectedFastLane = (state) =>
  state.pipelineStockState.filters.fastLaneSelected;

export const getExportableFile = (state) => state.pipelineStockState.file;

export const getDownloadRequestStatusIsPending = (state) =>
  state.pipelineStockState.downloadRequestStatus === 'pending';

export const getSelectedRegions = (state) =>
  state.pipelineStockState.filters.selectedRegions;

export const getSelectedRegionsCode = (state) =>
  state.pipelineStockState.filters.selectedRegions.map((item) => item.key);

const COVERAGE_RANGE = 4;
export default deliveriesGeolocationSlice;
