import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface ITransferStockNextHoursDTO {
  incoming_materials: ITransferStockNextHoursItemDTO[];
  pagination: IPaginationDTO;
}

enum NextHoursCriticalTypes {
  IN_TRANSIT_ALREADY_TRANSFERRED = 'IN_TRANSIT_ALREADY_TRANSFERRED'
}

export interface ITransferStockNextHoursItemDTO {
  code: string;
  description: string;
  delivery_time: string;
  warehouse: string;
  provider_delivery_note: string;
  plate_number: string;
  num_packages: number;
  asn_container: string;
  basic_container: string;
  transport_container: string;
  supply_container: string;
  supply_type: string;
  is_planned: boolean;
  supply_critical_type: NextHoursCriticalTypes.IN_TRANSIT_ALREADY_TRANSFERRED | null;
}

class TransferStockNextHoursDTO implements ITransferStockNextHoursDTO {
  incoming_materials: ITransferStockNextHoursItemDTO[];
  pagination: IPaginationDTO;

  constructor({ incoming_materials, pagination }) {
    this.incoming_materials = incoming_materials;
    this.pagination = pagination;
  }
}

export default TransferStockNextHoursDTO;
