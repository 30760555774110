import { createAsyncThunk } from '@reduxjs/toolkit';
import getCommercialPackagesUseCase from 'modules/outbound/shared/application/GetCommercialPackagesUseCase';
import getLastReceivedHifaFileDateUseCase from 'modules/outbound/shared/application/GetLastReceivedHifaFileDateUseCase';
import getDeliveryPointsUseCase from 'modules/outbound/shared/application/GetDeliveryPointsUseCase';
import getGlobalDelayUseCase from 'modules/outbound/shared/application/GetGlobalDelayUseCase';
import getFactoryDelayUseCase from 'modules/outbound/shared/application/GetFactoryDelayUseCase';
import { getLoadsStatusUseCase } from 'modules/outbound/shared/application/GetLoadsStatusUseCase';
import { getStockAgingUseCase } from 'modules/outbound/shared/application/GetStockAgingUseCase';
import getStockOccupationUseCase from 'modules/outbound/shared/application/GetStockOccupationUseCase';
import getStockSaturationUseCase from 'modules/outbound/shared/application/GetStockSaturationUseCase';

const getCommercialPackages = createAsyncThunk(
  'sharedData/getCommercialPackages',
  () => getCommercialPackagesUseCase.execute()
);

const getLastReceivedDateHifaFile = createAsyncThunk(
  'sharedData/getLastReceivedDateHifaFile',
  () => getLastReceivedHifaFileDateUseCase.execute()
);

const getDeliveryPoints = createAsyncThunk('sharedData/getDeliveryPoints', () =>
  getDeliveryPointsUseCase.execute()
);

const getGlobalDelay = createAsyncThunk('sharedData/getGlobalDelay', () =>
  getGlobalDelayUseCase.execute()
);

const getFactoryDelay = createAsyncThunk('sharedData/getFactoryDelay', () =>
  getFactoryDelayUseCase.execute()
);

const getLoadsStatus = createAsyncThunk('sharedData/getLoadsStatus', () =>
  getLoadsStatusUseCase.execute()
);

const getStockAging = createAsyncThunk('sharedData/getStockAging', () =>
  getStockAgingUseCase.execute()
);

const getStockOccupation = createAsyncThunk(
  `sharedData/getStockOccupation`,
  async () => {
    return getStockOccupationUseCase.execute();
  }
);

const getStockSaturation = createAsyncThunk(
  `sharedData/getStockSaturation`,
  async () => {
    return getStockSaturationUseCase.execute();
  }
);

export {
  getCommercialPackages,
  getLastReceivedDateHifaFile,
  getDeliveryPoints,
  getGlobalDelay,
  getFactoryDelay,
  getLoadsStatus,
  getStockAging,
  getStockOccupation,
  getStockSaturation
};
