export type VALIDATION_COLUMN_NAMES_ERROR_CODE = -1;
export type VALIDATION_NULL_DATA_ERROR_CODE = -2;
export type VALIDATION_DATA_TYPE_ERROR_CODE = -3;
export type VALIDATION_DATA_ENUM_ERROR_CODE = -4;
export type VALIDATION_DATA_LENGTH_ERROR_CODE = -5;
export type VALIDATION_DATA_PK_ERROR_CODE = -6;

export type ValidationError =
  | VALIDATION_COLUMN_NAMES_ERROR_CODE
  | VALIDATION_NULL_DATA_ERROR_CODE
  | VALIDATION_DATA_TYPE_ERROR_CODE
  | VALIDATION_DATA_ENUM_ERROR_CODE
  | VALIDATION_DATA_LENGTH_ERROR_CODE
  | VALIDATION_DATA_PK_ERROR_CODE
  | null;

interface Detail {
  fields: string[];
}

class UploadMasterResponseDTO {
  detail: Detail | null;
  error: ValidationError;
  message: string | null;

  constructor({ detail, error, message }) {
    this.detail = detail;
    this.error = error;
    this.message = message;
  }
}

export default UploadMasterResponseDTO;
