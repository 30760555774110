import IStucklisteSuppliersRepository from '../domain/IStucklisteSuppliersRepository';
import stucklisteSuppliersRepository from '../infraestructure/repositories/StucklisteSuppliersRepository';

class DownloadStucklisteSuppliersUseCase {
  private readonly _stucklisteSuppliersRepository: IStucklisteSuppliersRepository;

  constructor({ stucklisteSuppliersRepository }) {
    this._stucklisteSuppliersRepository = stucklisteSuppliersRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._stucklisteSuppliersRepository.downloadStucklisteSuppliers({
      queryParams
    });
  }
}

export default new DownloadStucklisteSuppliersUseCase({
  stucklisteSuppliersRepository: stucklisteSuppliersRepository
});

export { DownloadStucklisteSuppliersUseCase };
