export const ActionType = {
  OTHERS: 'OTHERS',
  LOAD: 'LOAD',
  DRIVING: 'DRIVING',
  WAIT: 'WAIT',
  ENTRY_HANDLE: 'ENTRY_HANDLE',
  BREAK_TIME: 'BREAK_TIME',
  WEIGHING: 'WEIGHING',
  CUSTOMS: 'CUSTOMS',
  CHECK_LIST: 'CHECK_LIST',
  EXIT: 'EXIT',
  SAMPLING: 'SAMPLING',
  UNLOAD: 'UNLOAD'
} as const;

export const StateType = {
  PLANNED: 'PLANNED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS'
};

export interface WarehouseDlzStopDTO {
  position: string;
  type: keyof typeof ActionType;
  warehouse_dlz?: number;
}

export interface WarehouseDlzCurrentActionDTO extends WarehouseDlzStopDTO {
  is_stop: boolean;
}

export interface EntryHandleDTO {
  duration: number;
  end_time: string | null;
  position: string;
  start_time: string | null;
  state: keyof typeof StateType;
}

class EntriesWarehouseDlzDetailDTO {
  current_action: WarehouseDlzCurrentActionDTO | null;
  entry_handle: EntryHandleDTO;
  global_dlz: number;
  stops_completed: WarehouseDlzStopDTO[];
  stops_pending: WarehouseDlzStopDTO[];
  transport_id: number;

  constructor({
    current_action,
    entry_handle,
    global_dlz,
    stops_completed,
    stops_pending,
    transport_id
  }) {
    this.current_action = current_action;
    this.entry_handle = entry_handle;
    this.global_dlz = global_dlz;
    this.stops_completed = stops_completed;
    this.stops_pending = stops_pending;
    this.transport_id = transport_id;
  }
}

export default EntriesWarehouseDlzDetailDTO;
