import LocaleService from 'infrastructure/i18n/LocaleService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { EndOfProductionDateText } from '../infrastructure/i18n/EndOfProductionDateText';
import { EndOfProductionDateTranslations } from '../infrastructure/i18n/typing';

const endOfProductionDateTranslations: EndOfProductionDateTranslations &
  InboundTranslations = {
  ...EndOfProductionDateText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export enum EndOfProductionColumnKeys {
  keyType = 'keyType',
  code = 'code',
  description = 'description',
  expirationDate = 'expirationDate',
  lastEntry = 'lastEntry',
  endOfConsumptionDate = 'endOfConsumptionDate',
  consumption = 'consumption',
  amountOrdered = 'amountOrdered',
  lastOrderDate = 'lastOrderDate',
  models = 'models',
  providerNames = 'providerNames',
  providerIds = 'providerIds',
  percentageAsignements = 'percentageAsignements',
  providerInbounders = 'providerInbounders',
  status = 'status',
  stock = 'stock',
  warehouseCodes = 'warehouseCodes',
  cost = 'cost',
  stockVolume = 'stockVolume',
  termins = 'termins',
  stucklisteModels = 'stucklisteModels',
  leftoverStock = 'leftoverStock',
  leftoverStockCost = 'leftoverStockCost',
  materialInTransit = 'materialInTransit',
  blockedMaterial = 'blockedMaterial'
}

export const END_OF_PRODUCTION_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: endOfProductionDateTranslations.code,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.code,
        key: NameValues.CODE,
        value: endOfProductionDateTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.KEY_TYPE,
    value: endOfProductionDateTranslations.keyType,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.keyType,
        key: NameValues.KEY_TYPE,
        value: endOfProductionDateTranslations.keyType,
        sortable: false
      }
    ]
  },
  {
    key: NameValues.DESCRIPTION,
    value: endOfProductionDateTranslations.description,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.description,
        key: NameValues.DESCRIPTION,
        value: endOfProductionDateTranslations.description,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.EXPIRATION_DATE,
    value: endOfProductionDateTranslations.expirationDate,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.expirationDate,
        key: NameValues.EXPIRATION_DATE,
        value: endOfProductionDateTranslations.expirationDate,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.LAST_ENTRY,
    value: endOfProductionDateTranslations.lastEntry,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.lastEntry,
        key: NameValues.LAST_ENTRY,
        value: endOfProductionDateTranslations.lastEntry,
        sortable: true,
        resize: false,
        hasCopyIcon: true
      }
    ]
  },
  {
    key: NameValues.TERMINS,
    value: endOfProductionDateTranslations.termins,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.termins,
        key: NameValues.TERMINS,
        value: endOfProductionDateTranslations.termins,
        sortable: false,
        resize: true,
        tooltipInfo: endOfProductionDateTranslations.terminsTooltip
      }
    ]
  },
  {
    key: NameValues.MODELS,
    value: endOfProductionDateTranslations.model,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.models,
        key: NameValues.MODELS,
        value: endOfProductionDateTranslations.model,
        sortable: false,
        tooltipInfo: endOfProductionDateTranslations.modelCarRelation
      }
    ]
  },
  {
    key: NameValues.STUCKLISTE_MODELS,
    value: endOfProductionDateTranslations.stuckliste_models,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.stucklisteModels,
        key: NameValues.STUCKLISTE_MODELS,
        value: endOfProductionDateTranslations.stuckliste_models,
        sortable: false,
        resize: true
      }
    ]
  },
  {
    key: NameValues.END_OF_CONSUMPTION_DATE,
    value: endOfProductionDateTranslations.endOfConsumptionDate,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.endOfConsumptionDate,
        key: NameValues.END_OF_CONSUMPTION_DATE,
        value: endOfProductionDateTranslations.endOfConsumptionDate,
        sortable: true,
        resize: true,
        tooltipInfo: endOfProductionDateTranslations.endOfConsumptionDateTooltip
      }
    ]
  },
  {
    key: NameValues.LEFTOVER_STOCK,
    value: endOfProductionDateTranslations.leftoverStock,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.leftoverStock,
        key: NameValues.LEFTOVER_STOCK,
        value: endOfProductionDateTranslations.leftoverStock,
        sortable: false,
        align: 'right',
        tooltipInfo: endOfProductionDateTranslations.leftoverStockTooltip
      }
    ]
  },
  {
    key: NameValues.COST,
    value: endOfProductionDateTranslations.cost,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.cost,
        key: NameValues.COST,
        value: endOfProductionDateTranslations.cost,
        sortable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.LEFTOVER_STOCK_COST,
    value: endOfProductionDateTranslations.leftoverStockCost,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.leftoverStockCost,
        key: NameValues.LEFTOVER_STOCK_COST,
        value: endOfProductionDateTranslations.leftoverStockCost,
        sortable: true,
        align: 'right',
        tooltipInfo: endOfProductionDateTranslations.leftoverStockCostTooltip
      }
    ]
  },
  {
    key: NameValues.STOCK_VOLUME,
    value: endOfProductionDateTranslations.stockVolume,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.stockVolume,
        key: NameValues.STOCK_VOLUME,
        value: endOfProductionDateTranslations.stockVolume,
        sortable: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.EXCESS_POTENCIAL,
    value: endOfProductionDateTranslations.excessPotencial,
    headerVisible: true,
    headerSpan: 4,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.stock,
        key: NameValues.STOCK,
        value: endOfProductionDateTranslations.stockSeat,
        sortable: true,
        align: 'right'
      },
      {
        uiKey: EndOfProductionColumnKeys.amountOrdered,
        key: NameValues.AMOUNT_ORDERED,
        value: endOfProductionDateTranslations.deliveryPlan,
        sortable: true,
        align: 'right'
      },
      {
        uiKey: EndOfProductionColumnKeys.materialInTransit,
        key: NameValues.MATERIAL_IN_TRANSIT,
        value: endOfProductionDateTranslations.materialInTransit,
        sortable: true,
        resize: true,
        align: 'right'
      },
      {
        uiKey: EndOfProductionColumnKeys.consumption,
        key: NameValues.CONSUMPTION,
        value: endOfProductionDateTranslations.consumption,
        sortable: true,
        align: 'right',
        tooltipInfo: endOfProductionDateTranslations.consumptionTooltip
      }
    ]
  },
  {
    key: NameValues.BLOCKED_MATERIAL,
    value: endOfProductionDateTranslations.blockedMaterial,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.blockedMaterial,
        key: NameValues.BLOCKED_MATERIAL,
        value: endOfProductionDateTranslations.blockedMaterial,
        sortable: true,
        resize: true,
        align: 'right',
        tooltipInfo: endOfProductionDateTranslations.blockedMaterialTooltip
      }
    ]
  },
  {
    key: NameValues.STATUS,
    value: endOfProductionDateTranslations.situationStatus,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.status,
        key: NameValues.STATUS,
        value: endOfProductionDateTranslations.situationStatus,
        sortable: true,
        resize: true,
        tooltipInfo: endOfProductionDateTranslations.statusSituationInfoTooltip
      }
    ]
  },
  {
    key: NameValues.PROVIDER_INBOUNDERS,
    value: endOfProductionDateTranslations.providerInbounders,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.providerInbounders,
        key: NameValues.PROVIDER_INBOUNDERS,
        value: endOfProductionDateTranslations.providerInbounders,
        sortable: false
      }
    ]
  },
  {
    key: NameValues.WAREHOUSE,
    value: endOfProductionDateTranslations.warehouses,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.warehouseCodes,
        key: NameValues.WAREHOUSE,
        value: endOfProductionDateTranslations.warehouses,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_NAMES,
    value: endOfProductionDateTranslations.providerNames,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.providerNames,
        key: NameValues.PROVIDER_NAMES,
        value: endOfProductionDateTranslations.providerNames,
        sortable: false,
        resize: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_PERCENTAGE,
    value: endOfProductionDateTranslations.percentageAsignements,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: EndOfProductionColumnKeys.percentageAsignements,
        key: NameValues.PROVIDER_PERCENTAGE,
        value: endOfProductionDateTranslations.percentageAsignements,
        sortable: false,
        tooltipInfo:
          endOfProductionDateTranslations.percentageAsignementsTooltip
      }
    ]
  }
];
