import LocaleService from 'infrastructure/i18n/LocaleService';
import SuppliesFiltersDTO from '../infrastructure/dto/SuppliesFiltersDTO';
import { SuppliesText } from '../infrastructure/i18n/SupplyText';
import { SuppliesTranslations } from '../infrastructure/i18n/typing';

const suppliesTranslations: SuppliesTranslations = {
  ...SuppliesText[LocaleService.getLanguage()]
};

export const PhasesFilterNames = {
  OPEN: suppliesTranslations.opened,
  PREPARED: suppliesTranslations.prepared,
  CLOSED: suppliesTranslations.closed
};

export interface FilterProps {
  key: string;
  text: string;
}

export interface SuppliesFilters {
  linesGroup: string[];
  warehousesSourceType: string[];
  warehousesSource: string[];
  manufacturingsSection: string[];
  suppliesType: string[];
  materialsIndicator: FilterProps[];
  phases: FilterProps[];
  lines: string[];
  warehousesTarget: string[];
}

class SuppliesFiltersData {
  linesGroup: string[];
  warehousesSourceType: string[];
  warehousesSource: string[];
  manufacturingsSection: string[];
  suppliesType: string[];
  materialsIndicator: FilterProps[];
  phases: FilterProps[];
  lines: string[];
  warehousesTarget: string[];

  constructor({
    linesGroup,
    warehousesSourceType,
    warehousesSource,
    manufacturingsSection,
    suppliesType,
    materialsIndicator,
    phases,
    lines,
    warehousesTarget
  }: SuppliesFilters) {
    this.linesGroup = linesGroup;
    this.warehousesSourceType = warehousesSourceType;
    this.warehousesSource = warehousesSource;
    this.manufacturingsSection = manufacturingsSection;
    this.suppliesType = suppliesType;
    this.materialsIndicator = materialsIndicator;
    this.phases = phases;
    this.lines = lines;
    this.warehousesTarget = warehousesTarget;
  }
  static generateFromDTO({
    lines_group,
    warehouses_source_type,
    warehouses_source,
    manufacturings_section,
    supplies_type,
    materials_indicator,
    phases,
    lines,
    warehouses_target
  }: SuppliesFiltersDTO): SuppliesFilters {
    let formatedMaterialsIndicators = materials_indicator.map((material) => {
      if (material === '') {
        return { key: ';', text: suppliesTranslations.withoutIndicator };
      }
      return { key: material, text: material };
    });

    let formatedPhases = phases.map((phase) => {
      return { key: phase, text: PhasesFilterNames[phase] };
    });

    return new SuppliesFiltersData({
      linesGroup: lines_group,
      warehousesSourceType: warehouses_source_type,
      warehousesSource: warehouses_source,
      manufacturingsSection: manufacturings_section,
      suppliesType: supplies_type,
      materialsIndicator: formatedMaterialsIndicators,
      phases: formatedPhases,
      lines: lines.map((line) => line.toString()),
      warehousesTarget: warehouses_target
    });
  }
}

export default SuppliesFiltersData;
