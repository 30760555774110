export interface IPaginationDTO {
  page: number;
  size: number;
  page_count: number;
  total_count: number;
}

export interface IPagination {
  page: number;
  size: number;
  pageCount: number;
  totalCount: number;
}

class Pagination {
  page: number;
  size: number;
  pageCount: number;
  totalCount: number;

  constructor({ page, size, pageCount, totalCount }) {
    this.page = page;
    this.size = size;
    this.pageCount = pageCount;
    this.totalCount = totalCount;
  }

  static generateFromDTO(pagination: IPaginationDTO): Pagination {
    return new Pagination({
      page: pagination.page,
      size: pagination.size,
      pageCount: pagination.page_count,
      totalCount: pagination.total_count
    });
  }

  static generateEmpty() {
    return new Pagination({
      page: 1,
      size: 50,
      pageCount: 0,
      totalCount: 0
    });
  }
}

export { Pagination };
