import IWarehouseStockRepository from 'modules/outbound/stock/domain/IWarehouseStockRepository';
import warehouseStockRepository from 'modules/outbound/stock/infrastructure/repositories/WarehouseStockRepository';
import WarehouseStockByModel from 'modules/outbound/stock/domain/WarehouseStockByModel';
import WarehouseStockByModelDTO from 'modules/outbound/stock/infrastructure/dto/WarehouseStockByModelDTO';
import { GetWarehouseByModelService } from 'modules/outbound/stock/services/GetWarehouseByModelService';

class GetWarehouseStockByModelService extends GetWarehouseByModelService {
  private _warehouseStockRepository: IWarehouseStockRepository;

  constructor({ warehouseStockRepository }) {
    super();
    this._warehouseStockRepository = warehouseStockRepository;
  }

  get({
    warehouseReference,
    queryParams,
    warehouseStock
  }: {
    warehouseReference?: string;
    queryParams: object;
    warehouseStock: number;
  }) {
    const reference = this._getReference(warehouseReference);
    return this._warehouseStockRepository
      .getInfoByWarehouse({ warehouseReference: reference, queryParams })
      .then((res) => {
        const totalPastSales = this._getTotalPastSales(res);
        return res.map((item) =>
          this._mapToWarehouseStocksByModel({
            dto: item,
            warehouseTotalStock: warehouseStock,
            totalPastSales
          })
        );
      })
      .then((res) => ({
        stockByModel: res,
        warehouseReference
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _getTotalPastSales(warehouseStock) {
    return warehouseStock.reduce(
      (acc, currentValue) => acc + currentValue.pastSales,
      0
    );
  }

  private _mapToWarehouseStocksByModel({
    dto,
    warehouseTotalStock,
    totalPastSales
  }: {
    dto: WarehouseStockByModelDTO;
    warehouseTotalStock: number;
    totalPastSales: number;
  }): WarehouseStockByModel {
    return WarehouseStockByModel.generateFromDTO({
      ...dto,
      warehouseTotalStock,
      totalPastSales
    });
  }
}

export default new GetWarehouseStockByModelService({
  warehouseStockRepository: warehouseStockRepository
});

export { GetWarehouseStockByModelService };
