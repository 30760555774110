export interface EntriesSummaryDTO {
  entries: {
    has_critical_pieces: number;
    urgents: number;
  };
}

export const entriesSummaryDTOMapper = (data: any): EntriesSummaryDTO => {
  return {
    entries: {
      has_critical_pieces: data?.entries?.has_critical_pieces ?? 0,
      urgents: data?.entries?.urgents ?? 0
    }
  };
};
