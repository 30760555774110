import { createAsyncThunk } from '@reduxjs/toolkit';
import GetCallOffFinishedGoodsByWarehouseService from 'modules/outbound/callOff/application/services/GetCallOffFinishedGoodsByWarehouseService';
import getCallOffTrackingService from 'modules/outbound/callOff/application/services/GetCallOffTrackingService';
import getCheckpointsService from 'modules/outbound/callOff/application/services/GetCheckpointsService';
import {
  getCurrentPage,
  getResultsPerPage,
  getSelectedCheckpoint,
  getSelectedStatus,
  getSelectedVin,
  getSelectedWarehouse
} from 'modules/outbound/callOff/infrastructure/redux/CallOffTrackingReducer';
import DownloadCallOffFinishedGoodsByWarehouseService from 'modules/outbound/callOff/application/services/DownloadCallOffFinishedGoodsByWarehouseService';
import getFinishedGoodCheckpointTimelineService from 'modules/outbound/fulfillment/services/FinishedGoodCheckpointTimelineService';
import getCallOffTrackingDataByCheckpointUseCase from 'modules/outbound/callOff/application/GetCallOffTrackingDataByCheckpointUseCase';
import getDateRangeService from 'modules/outbound/shared/domain/GetDateRangeService';
import CallOffTracking from 'modules/outbound/callOff/domain/CallOffTracking';

const getCallOffTracking = createAsyncThunk<
  { trackingByWarehouses: CallOffTracking[]; dateRangeType: string },
  string,
  { rejectedMeta: object }
>(
  `callOffTracking/getCallOffTracking`,
  async (dateRangeType: string, { rejectWithValue }) => {
    let dateRange = {};
    if (dateRangeType.length) {
      dateRange = getDateRangeService.formatDateFromRange(dateRangeType);
    }
    try {
      const response = await getCallOffTrackingService.execute({
        queryParams: {
          ...dateRange,
          notDeliveredToDealer: !dateRangeType.length
        }
      });
      return {
        trackingByWarehouses: response,
        dateRangeType: dateRangeType || 'default'
      };
    } catch (error) {
      return rejectWithValue(error, {
        dateRangeType: dateRangeType || 'default'
      });
    }
  },
  {
    getPendingMeta: ({ arg }) => ({
      dateRangeType: arg || 'default'
    })
  }
);

const getCallOffFinishedGoodsByWarehouse = createAsyncThunk(
  `callOffTracking/getCallOffFinishedGoodsByWarehouse`,
  async (_, { getState }) => {
    const appState = getState();
    const selectedWarehouse = getSelectedWarehouse(appState);
    const itemsPerPage = getResultsPerPage(appState);
    const currentPage = getCurrentPage(appState);
    const selectedCheckpoint = getSelectedCheckpoint(appState);
    const selectedStatus = getSelectedStatus(appState);
    const queryParams = {
      page: currentPage,
      itemsPerPage,
      checkpointType: selectedCheckpoint,
      withCallOff: !selectedCheckpoint,
      status: selectedStatus
    };
    const response = await GetCallOffFinishedGoodsByWarehouseService.execute({
      warehouseRef: selectedWarehouse,
      queryParams
    });
    return response;
  }
);
const downloadCallOffFinishedGoodsByWarehouse = createAsyncThunk(
  `callOffTracking/downloadCallOffFinishedGoodsByWarehouse`,
  async (_, { getState }) => {
    const appState = getState();
    const selectedWarehouse = getSelectedWarehouse(appState);
    const selectedCheckpoint = getSelectedCheckpoint(appState);
    const selectedStatus = getSelectedStatus(appState);
    const queryParams = {
      checkpointType: selectedCheckpoint,
      withCallOff: !selectedCheckpoint,
      status: selectedStatus
    };

    const response =
      await DownloadCallOffFinishedGoodsByWarehouseService.execute({
        warehouseRef: selectedWarehouse,
        queryParams
      });
    return response;
  }
);

const getCheckpoints = createAsyncThunk(
  `callOffTracking/getCheckpoints`,
  async () => {
    const queryParams = {
      checkpoints: ['factoryWarehouseInput', 'factoryWarehouseOutput']
    };
    const response = await getCheckpointsService.execute({
      countryCode: 'ESP',
      queryParams
    });
    return response;
  }
);

const getFinishedGoodCheckpointsTimeline = createAsyncThunk(
  `callOffTracking/getFinishedGoodCheckpointsTimeline`,
  async (_, { getState }) => {
    const appState: any = getState();
    const selectedVin = getSelectedVin(appState);
    const response = await getFinishedGoodCheckpointTimelineService.get({
      vin: selectedVin
    });
    return response;
  }
);

const getCallOffTrackingByCheckpoint = createAsyncThunk(
  `callOffTracking/getCallOffTrackingByCheckpoint`,
  async () => {
    const response = await getCallOffTrackingDataByCheckpointUseCase.execute({
      checkpointType: 'redistributionWarehouseInput'
    });
    return response;
  }
);

export {
  getCallOffTracking,
  getCallOffFinishedGoodsByWarehouse,
  downloadCallOffFinishedGoodsByWarehouse,
  getCheckpoints,
  getFinishedGoodCheckpointsTimeline,
  getCallOffTrackingByCheckpoint
};
