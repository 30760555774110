export interface TransitFulfillmentDTO {
  country: string;
  delayedCars: number;
  totalCars: number;
  checkpointThreshold: string;
  currentYearTotalCars: number;
  currentYearDelayedCars: number;
}

class TransitFulfillmentResponseDTO {
  globalKapDelayedCars: number;
  globalKapTotalCars: number;
  kap: TransitFulfillmentDTO[];
  others: TransitFulfillmentDTO[];

  constructor({ globalKapDelayedCars, globalKapTotalCars, kap, others }) {
    this.globalKapDelayedCars = globalKapDelayedCars;
    this.globalKapTotalCars = globalKapTotalCars;
    this.kap = kap;
    this.others = others;
  }
}

export default TransitFulfillmentResponseDTO;
