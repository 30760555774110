import { createSlice } from '@reduxjs/toolkit';
import {
  downloadEntriesInPlant,
  getEntriesInPlantData,
  getEntriesInPlantLastUpdateData,
  getEntryInPlantDetailData,
  getGlobalDlzSummaryUrl,
  getParalyzationSummary,
  getWarehouseDlzDetail
} from './async';
import { EntriesInPlantDataState, SelectedInPlantFilters } from './types';
import EntryInPlantDetail from 'modules/inbound/entries/domain/InPlant/EntriesInPlantDetail';
import EntriesWarehouseDlzDetail from 'modules/inbound/entries/domain/InPlant/EntriesWarehouseDlzDetail';
import { EntriesParalyzation } from 'modules/inbound/entries/domain/InPlant/EntriesParalyzation';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';

export const initialState: EntriesInPlantDataState = {
  entriesInPlant: [],
  paralyzationSummary: EntriesParalyzation.generateEmpty(),
  paralyzationSummaryRequestStatus: '',
  lastUpdate: '-',
  columns: [],
  savedColumns: [],
  entriesInPlantRequestStatus: '',
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedColumn: {
    key: '',
    isAscending: false
  },
  filters: {
    selectedSubHeaderFilters: {
      warehouses: [],
      lastPositions: []
    },
    search: ''
  },
  areFiltersSaved: false,
  selectedEntry: null,
  selectedParalyzationStatus: '',
  entryInPlantDetailRequestStatus: '',
  entryInPlantDetail: EntryInPlantDetail.generateEmpty(),
  warehouseDlzDetail: EntriesWarehouseDlzDetail.generateEmpty(),
  warehouseDlzDetailRequestStatus: '',
  globalDlzSummaryUrl: null,
  globalDlzSummaryUrlRequestStatus: '',
  entriesInPlantFile: null,
  downloadRequestStatus: ''
};

const ENTRIES_IN_PLANT_COLUMNS_VIEW_STATE_KEY = 'EntriesInPlantColumnsView';
const ENTRIES_IN_PLANT_FILTERS_STATE_KEY = 'EntriesInPlantSavedFilters';

const entriesInPlantSlice = createSlice({
  name: 'entriesInPlant',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateFilterSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    updateSelectedLastPositions: (state, action) => {
      state.filters.selectedSubHeaderFilters.lastPositions = action.payload;
    },
    updateSelectedWarehouses: (state, action) => {
      state.filters.selectedSubHeaderFilters.warehouses = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters.selectedSubHeaderFilters =
        initialState.filters.selectedSubHeaderFilters;
      localStorage.removeItem(
        `${action.payload}-${ENTRIES_IN_PLANT_FILTERS_STATE_KEY}`
      );
      state.areFiltersSaved = false;
    },
    saveFilters: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${ENTRIES_IN_PLANT_FILTERS_STATE_KEY}`,
        JSON.stringify(state.filters.selectedSubHeaderFilters)
      );
      state.areFiltersSaved = true;
    },
    loadFilters: (state, action) => {
      const localStoragefilters = getLocalStorage<SelectedInPlantFilters>(
        `${action.payload}-${ENTRIES_IN_PLANT_FILTERS_STATE_KEY}`,
        { ...state.filters.selectedSubHeaderFilters }
      );

      state.filters.selectedSubHeaderFilters = {
        warehouses: localStoragefilters.warehouses || [],
        lastPositions: localStoragefilters.lastPositions || []
      };
      state.areFiltersSaved = Object.keys(localStoragefilters).some(
        (key) => localStoragefilters[key].length
      );
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${ENTRIES_IN_PLANT_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
    },
    loadColumnsView: (state, action) => {
      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: ENTRIES_IN_PLANT_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateSelectedEntry: (state, action) => {
      state.selectedEntry = action.payload;
    },
    updateSelectedParalyzationStatus: (state, action) => {
      state.selectedParalyzationStatus = action.payload;
    },
    updateColumnsOrder: (state, action) => {
      state.savedColumns = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntriesInPlantData.fulfilled, (state, action) => {
      state.entriesInPlant = action.payload.entries;
      state.columns = action.payload.columns || [];
      state.entriesInPlantRequestStatus = action.meta.requestStatus;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getEntriesInPlantData.pending, (state, action) => {
      state.entriesInPlantRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntriesInPlantData.rejected, (state, action) => {
      state.entriesInPlantRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(
      getEntriesInPlantLastUpdateData.fulfilled,
      (state, action) => {
        state.lastUpdate = action.payload.lastUpdate;
      }
    );
    builder.addCase(getEntryInPlantDetailData.fulfilled, (state, action) => {
      state.entryInPlantDetail = action.payload;
      state.entryInPlantDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntryInPlantDetailData.pending, (state, action) => {
      state.entryInPlantDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getEntryInPlantDetailData.rejected, (state, action) => {
      state.entryInPlantDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseDlzDetail.fulfilled, (state, action) => {
      state.warehouseDlzDetail = action.payload;
      state.warehouseDlzDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseDlzDetail.pending, (state, action) => {
      state.warehouseDlzDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseDlzDetail.rejected, (state, action) => {
      state.warehouseDlzDetailRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getParalyzationSummary.fulfilled, (state, action) => {
      state.paralyzationSummary = action.payload;
      state.paralyzationSummaryRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getParalyzationSummary.pending, (state, action) => {
      state.paralyzationSummaryRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getParalyzationSummary.rejected, (state, action) => {
      state.paralyzationSummaryRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDlzSummaryUrl.fulfilled, (state, action) => {
      state.globalDlzSummaryUrl = action.payload;
      state.globalDlzSummaryUrlRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDlzSummaryUrl.pending, (state, action) => {
      state.globalDlzSummaryUrlRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getGlobalDlzSummaryUrl.rejected, (state, action) => {
      state.globalDlzSummaryUrlRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEntriesInPlant.fulfilled, (state, action) => {
      state.entriesInPlantFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_Currently_in_plant_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEntriesInPlant.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadEntriesInPlant.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getEntriesInPlant = (state) =>
  state.entriesInPlantState.entriesInPlant;
export const getEntriesParalyzationSummary = (state) =>
  state.entriesInPlantState.paralyzationSummary;
export const getEntriesInPlantLastUpdate = (state) =>
  state.entriesInPlantState.lastUpdate;
export const getColumns = (state) => state.entriesInPlantState.savedColumns;
export const getPageInfo = (state) => state.entriesInPlantState.pagination;

export const getEntriesInPlantIsPending = (state) =>
  state.entriesInPlantState.entriesInPlantRequestStatus === 'pending';
export const getEntriesInPlantHasError = (state) =>
  state.entriesInPlantState.entriesInPlantRequestStatus === 'rejected';
export const getIsParalyzationPending = (state) =>
  state.entriesInPlantState.paralyzationSummaryRequestStatus === 'pending';
export const getHasParalyzationError = (state) =>
  state.entriesInPlantState.paralyzationSummaryRequestStatus === 'rejected';

export const getSelectedColumn = (state) =>
  state.entriesInPlantState.selectedColumn;
export const getFilterSearch = (state) =>
  state.entriesInPlantState.filters.search;
export const getSelectedEntryInPlant = (state) =>
  state.entriesInPlantState.selectedEntry;
export const getSelectedParalyzationStatus = (state) =>
  state.entriesInPlantState.selectedParalyzationStatus;
export const getSelectedFilters = (state) =>
  state.entriesInPlantState.filters.selectedSubHeaderFilters;
export const getIsFilterSaved = (state) =>
  state.entriesInPlantState.areFiltersSaved;
export const getHasFiltersSelected = (state) =>
  Object.keys(state.entriesInPlantState.filters.selectedSubHeaderFilters).some(
    (key) =>
      state.entriesInPlantState.filters.selectedSubHeaderFilters[key].length
  );

export const getEntryInPlantDetailIsPending = (state) =>
  state.entriesInPlantState.entryInPlantDetailRequestStatus === 'pending';
export const getEntryInPlantDetailHasError = (state) =>
  state.entriesInPlantState.entryInPlantDetailRequestStatus === 'rejected';
export const getWarehouseDlzIsPending = (state) =>
  state.entriesInPlantState.warehouseDlzDetailRequestStatus === 'pending';
export const getWarehouseDlzHasError = (state) =>
  state.entriesInPlantState.warehouseDlzDetailRequestStatus === 'rejected';
export const getEntryInPlantDetails = (state) =>
  state.entriesInPlantState.entryInPlantDetail;
export const getWarehouseDlzTimeline = (state) =>
  state.entriesInPlantState.warehouseDlzDetail;

export const getEntriesInPlantDlzUrl = (state) =>
  state.entriesInPlantState.globalDlzSummaryUrl;
export const getGlobalDlzSummaryIsPending = (state) =>
  state.entriesInPlantState.globalDlzSummaryUrlRequestStatus === 'pending';
export const getGlobalDlzSummaryHasError = (state) =>
  state.entriesInPlantState.globalDlzSummaryUrlRequestStatus === 'rejected';
export const getDownloadEntriesInPlantIsPending = (state) =>
  state.entriesInPlantState.downloadRequestStatus === 'pending';
export const getDownloadEntriesInPlantSuccessful = (state) =>
  state.entriesInPlantState.downloadRequestStatus === 'fulfilled' &&
  state.entriesInPlantState.entriesInPlantFile !== null;
export const getDownloadEntriesInPlantError = (state) =>
  state.entriesInPlantState.downloadRequestStatus === 'rejected';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateFilterSearch,
  updateSelectedEntry,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView,
  updateColumnsOrder,
  updateSelectedParalyzationStatus,
  updateSelectedLastPositions,
  updateSelectedWarehouses,
  resetFilters,
  saveFilters,
  loadFilters,
  resetDownloadStatus
} = entriesInPlantSlice.actions;
export default entriesInPlantSlice.reducer;
