import React, { useState } from 'react';
import { LanguageAbbreviations } from './types';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg';
import WrapperLanguageSelector, {
  WrapperFlags,
  WrapperSelectedLanguage
} from './styles/WrapperLanguageSelector';
import localeService from 'infrastructure/i18n/LocaleService';

const flags = { es: 'ESP', ca: 'CAT', en: 'GBR' };

const LanguageSelector = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const getImageSrcFromCountryName = (countryName: string) => {
    return `${process.env.PUBLIC_URL}/images/flags-circle/${countryName}.svg`;
  };

  const handleAvailableLanguages = () => {
    setIsActive(!isActive);
  };

  const getFlagLanguage = (lang) => () => {
    localeService.setLanguage(lang);
    window.location.reload();
  };

  const selectedLanguage = localeService.getLanguage();
  return (
    <WrapperLanguageSelector isActive={isActive}>
      <WrapperSelectedLanguage
        onMouseDown={handleAvailableLanguages}
        isActive={isActive}
      >
        <img
          src={getImageSrcFromCountryName(flags[selectedLanguage])}
          alt={selectedLanguage}
        />
        <span>{LanguageAbbreviations[selectedLanguage]}</span>
        <ArrowIcon className="arrow-icon" />
      </WrapperSelectedLanguage>
      <WrapperFlags>
        {isActive &&
          localeService.getAvailableLanguages().map(
            (item) =>
              item !== selectedLanguage && (
                <button key={item} onClick={getFlagLanguage(item)}>
                  <img
                    src={getImageSrcFromCountryName(flags[item])}
                    alt={item}
                  />
                  <span>{LanguageAbbreviations[item]}</span>
                </button>
              )
          )}
      </WrapperFlags>
    </WrapperLanguageSelector>
  );
};

export default LanguageSelector;
