import { PartCoverageFiltersDTO } from 'modules/inbound/jit/infrastructure/dto/PartCoverageFiltersDTO';
import { FlowTypes } from '../infrastructure/dto/JitFlowTypesDTO';
import { IProvider, ISequencer } from './types';

export interface PartCoverageFilters {
  family: string[];
  lines: number[];
  providersTier1Ids: IProvider[];
  logisticOperatorsIds: ISequencer[];
  models: string[];
  inbounders: string[];
  flowTypes: FlowTypes;
}

class PartCoverageFiltersData {
  family: string[];
  lines: number[];
  providersTier1Ids: IProvider[];
  logisticOperatorsIds: ISequencer[];
  models: string[];
  inbounders: string[];
  flowTypes: FlowTypes;

  constructor({
    family,
    lines,
    providersTier1Ids,
    logisticOperatorsIds,
    models,
    inbounders,
    flowTypes
  }: PartCoverageFilters) {
    this.family = family;
    this.lines = lines;
    this.providersTier1Ids = providersTier1Ids;
    this.logisticOperatorsIds = logisticOperatorsIds;
    this.models = models;
    this.inbounders = inbounders;
    this.flowTypes = flowTypes;
  }

  static generateFromDTO({
    family,
    lines,
    tier_1_provider_ids,
    logistic_operators_ids,
    models,
    inbounders,
    flow_types
  }: PartCoverageFiltersDTO): PartCoverageFilters {
    return new PartCoverageFiltersData({
      family,
      lines,
      providersTier1Ids: tier_1_provider_ids,
      logisticOperatorsIds: logistic_operators_ids,
      models,
      inbounders,
      flowTypes: flow_types
    });
  }
}

export default PartCoverageFiltersData;
