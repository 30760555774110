abstract class BaseRepository {
  _createQueryParams(params: object) {
    if (!params) {
      return '';
    }
    const queryParams = Object.keys(params)
      .map((key) => {
        if (typeof params[key] === 'boolean') {
          if (params[key]) {
            return encodeURIComponent(key);
          }
          return '';
        }
        if (typeof params[key] === 'string') {
          if (params[key].length) {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            );
          }
          return '';
        }
        if (Array.isArray(params[key])) {
          if (params[key].length) {
            const arrayQueryParams = params[key].map((item) =>
              encodeURIComponent(item)
            );
            return encodeURIComponent(key) + '=' + arrayQueryParams.join(',');
          }
          return '';
        }
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .filter((key) => key !== '')
      .join('&');

    return queryParams.length ? `?${queryParams}` : '';
  }
}

export default BaseRepository;
