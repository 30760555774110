import IUsersRepository from '../domain/IUsersRepository';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class ResetUsersDataService {
  private _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute(userId) {
    return this._usersRepository.resetPassword(userId).then((res) => res);
  }
}

export default new ResetUsersDataService({
  repository: usersRepository
});

export { ResetUsersDataService };
