import { SaturationUnloadingPointsFiltersDTO } from '../../infrastructure/dto/UnloadingPointsDTO/SaturationUnloadingPointsFiltersDTO';

export class SaturationUnloadingPointsFilters {
  carrier: string[];
  supplier: string[];
  unloadingPoint: string[];
  warehouse: string[];

  constructor({ carrier, supplier, unloadingPoint, warehouse }) {
    this.carrier = carrier;
    this.supplier = supplier;
    this.unloadingPoint = unloadingPoint;
    this.warehouse = warehouse;
  }

  static generateFromDTO(
    dto: SaturationUnloadingPointsFiltersDTO
  ): SaturationUnloadingPointsFilters {
    return new SaturationUnloadingPointsFilters({
      carrier: dto.carrier ?? [],
      supplier: dto.supplier ?? [],
      unloadingPoint: dto.unloading_point ?? [],
      warehouse: dto.warehouse ?? []
    });
  }

  static generateEmpty() {
    return new SaturationUnloadingPointsFilters({
      carrier: [],
      supplier: [],
      unloadingPoint: [],
      warehouse: []
    });
  }
}
