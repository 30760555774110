import ITransferRepository from '../domain/ITransferRepository';
import TransfersFilters from '../domain/TransfersFilters';
import transferRepository from '../infrastructure/repositories/TransferRepository';

class GetTransfersFiltersUseCase {
  private _transfersRepository: ITransferRepository;

  constructor({ repository }) {
    this._transfersRepository = repository;
  }

  execute({ isTransferFilters }) {
    return this._transfersRepository
      .getFilters(isTransferFilters)
      .then((res) => {
        return TransfersFilters.generateFromDTO(res);
      });
  }
}

export { GetTransfersFiltersUseCase };
export default new GetTransfersFiltersUseCase({
  repository: transferRepository
});
