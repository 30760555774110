import { EntriesInPlantFiltersDTO } from '../../infrastructure/dto/InPlantDTO/entriesInPlantFiltersDTOMapper';
import { EntriesInPlantFilters } from './EntriesInPlantFilters';

export const entriesInPlantFiltersMapper = (
  dto: EntriesInPlantFiltersDTO
): EntriesInPlantFilters => {
  return new EntriesInPlantFilters({
    warehouses: dto.warehouses || [],
    lastPositions: dto.last_positions || []
  });
};
