import { ExpirationsData } from '../domain/expirationsDataMapper';
import { IExpirationRepository } from '../domain/IExpirationRepository';
import expirationRepository from '../infrastructure/repositories/ExpirationRepository';

class GetExpirationDataUseCase {
  private _expirationDataRepository: IExpirationRepository;

  constructor({ repository }) {
    this._expirationDataRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<ExpirationsData> {
    return this._expirationDataRepository.getExpirationsStock({ queryParams });
  }
}

export default new GetExpirationDataUseCase({
  repository: expirationRepository
});

export { GetExpirationDataUseCase };
