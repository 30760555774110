const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const MID_TERM_CAPACITIES_DASHBOARD_ENDPOINT =
  '/outbound/dashboards/mid-term-capacities/url';
const GET_MID_TERM_CAPACITIES_DASHBOARD =
  BASE_URL + MID_TERM_CAPACITIES_DASHBOARD_ENDPOINT;
const LONG_TERM_CAPACITIES_DASHBOARD_ENDPOINT =
  '/outbound/dashboards/long-term-capacities/url';
const GET_LONG_TERM_CAPACITIES_DASHBOARD =
  BASE_URL + LONG_TERM_CAPACITIES_DASHBOARD_ENDPOINT;
const TOP_FLOWS_LACK_CAPACITIES_ENDPOINT =
  '/outbound/transport-capacity/deviation';
const GET_TOP_FLOWS_LACK_CAPACITIES_ENDPOINT_DASHBOARD =
  BASE_URL + TOP_FLOWS_LACK_CAPACITIES_ENDPOINT;

export {
  GET_MID_TERM_CAPACITIES_DASHBOARD,
  GET_LONG_TERM_CAPACITIES_DASHBOARD,
  GET_TOP_FLOWS_LACK_CAPACITIES_ENDPOINT_DASHBOARD
};
