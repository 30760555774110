export enum FlowTypeEnum {
  SYNCRO = 'SYNCRO',
  NO_SYNCRO = 'NO_SYNCRO',
  NONE = 'NONE'
}
export type FlowTypes = [
  FlowTypeEnum.NONE,
  FlowTypeEnum.SYNCRO,
  FlowTypeEnum.NO_SYNCRO
];
