import { createSlice } from '@reduxjs/toolkit';
import {
  getLongTermCapacitiesDashboard,
  getMidTermCapacitiesDashboard,
  getTopFlowsLackCapacities
} from './async';
import { MidTermCapacitiesState } from './types';

export const initialState: MidTermCapacitiesState = {
  midTermCapacitiesDashboardUrl: null,
  midTermCapacitiesDashboardUrlRequestStatus: '',
  longTermCapacitiesDashboardUrl: null,
  longTermCapacitiesDashboardUrlRequestStatus: '',
  topFlowsLackCapacities: null,
  topFlowsLackCapacitiesRequestStatus: ''
};
const transportCapacitiesSlice = createSlice({
  name: 'capacities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMidTermCapacitiesDashboard.fulfilled,
      (state, action) => {
        state.midTermCapacitiesDashboardUrl = action.payload;
        state.midTermCapacitiesDashboardUrlRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getMidTermCapacitiesDashboard.pending, (state, action) => {
      state.midTermCapacitiesDashboardUrlRequestStatus =
        action.meta.requestStatus;
    });
    builder.addCase(getMidTermCapacitiesDashboard.rejected, (state, action) => {
      state.midTermCapacitiesDashboardUrlRequestStatus =
        action.meta.requestStatus;
    });
    builder.addCase(
      getLongTermCapacitiesDashboard.fulfilled,
      (state, action) => {
        state.longTermCapacitiesDashboardUrl = action.payload;
        state.longTermCapacitiesDashboardUrlRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getLongTermCapacitiesDashboard.pending, (state, action) => {
      state.longTermCapacitiesDashboardUrlRequestStatus =
        action.meta.requestStatus;
    });
    builder.addCase(
      getLongTermCapacitiesDashboard.rejected,
      (state, action) => {
        state.longTermCapacitiesDashboardUrlRequestStatus =
          action.meta.requestStatus;
      }
    );
    builder.addCase(getTopFlowsLackCapacities.fulfilled, (state, action) => {
      state.topFlowsLackCapacities = action.payload;
      state.topFlowsLackCapacitiesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTopFlowsLackCapacities.pending, (state, action) => {
      state.topFlowsLackCapacitiesRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getTopFlowsLackCapacities.rejected, (state, action) => {
      state.topFlowsLackCapacitiesRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getMidTermCapacitiesDashboardUrl = (state) =>
  state.transportCapacitiesState.midTermCapacitiesDashboardUrl;

export const midTermCapacitiesDashboardUrlRequestStatusIsPending = (state) =>
  state.transportCapacitiesState.midTermCapacitiesDashboardUrlRequestStatus ===
  'pending';

export const getLongTermCapacitiesDashboardUrl = (state) =>
  state.transportCapacitiesState.longTermCapacitiesDashboardUrl;

export const longTermCapacitiesDashboardUrlRequestStatusIsPending = (state) =>
  state.transportCapacitiesState.longTermCapacitiesDashboardUrlRequestStatus ===
  'pending';

export const getTopFlowsLackCapacitiesDeviations = (state) =>
  state.transportCapacitiesState.topFlowsLackCapacities;

export const topFlowsLackCapacitiesRequestStatusIsPending = (state) =>
  state.transportCapacitiesState.topFlowsLackCapacitiesRequestStatus ===
  'pending';

export const transportCapacitiesReducer = transportCapacitiesSlice.reducer;
