import WarehouseStockDTO from 'modules/outbound/stock/infrastructure/dto/WarehouseStockDTO';
import WarehouseStock from 'modules/outbound/stock/domain/WarehouseStock';
import IWarehouseStockRepository from 'modules/outbound/stock/domain/IWarehouseStockRepository';
import warehouseStockRepository from 'modules/outbound/stock/infrastructure/repositories/WarehouseStockRepository';

class GetWarehouseStockOverviewService {
  private _warehouseStockRepository: IWarehouseStockRepository;

  constructor({ warehouseStockRepository }) {
    this._warehouseStockRepository = warehouseStockRepository;
  }

  getAll(queryParams) {
    return this._warehouseStockRepository
      .getOverview({ queryParams })
      .then((res) => res.map((item) => this._mapToWarehouseStocks(item)))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToWarehouseStocks(dto: WarehouseStockDTO): WarehouseStock {
    return WarehouseStock.generateFromDTO(dto);
  }
}

export default new GetWarehouseStockOverviewService({
  warehouseStockRepository: warehouseStockRepository
});

export { GetWarehouseStockOverviewService };
