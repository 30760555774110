import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  IPagination,
  IPaginationDTO
} from 'modules/inbound/shared/domain/IPagination';
import { LineGroupsItemDTO } from '../infrastructure/dto/LineGroupsDataDTO';

export interface LineGroupsItem {
  id?: number;
  sFab: number;
  gLin: string;
  descriptionGLin: string;
  line: string;
  warehouse: string;
  createdAt: string;
  updatedAt: string;
}

interface GenerateFromDTOParams {
  last_update: string;
  data: LineGroupsItemDTO[];
  pagination: IPaginationDTO;
}

class LineGroupsData {
  lastUpdate: string;
  data: LineGroupsItem[];
  pagination: IPagination;

  constructor({ lastUpdate, data, pagination }: LineGroupsData) {
    this.lastUpdate = lastUpdate;
    this.data = data;
    this.pagination = pagination;
  }

  static generateFromDTO({
    last_update,
    data,
    pagination
  }: GenerateFromDTOParams) {
    let formatLineGroups: LineGroupsItem[] = [];

    if (data.length) {
      data.forEach((item) => {
        formatLineGroups.push({
          id: item.id,
          sFab: item.s_fab,
          gLin: item.g_lin,
          descriptionGLin: item.description_g_lin,
          line: item.line,
          warehouse: item.warehouse,
          createdAt: item.created_at,
          updatedAt: item.updated_at
        });
      });
    }

    return new LineGroupsData({
      lastUpdate: last_update
        ? `${GetDateFormatedService.getDateFormated(
            last_update,
            'de-DE'
          )} | ${GetDateFormatedService.getTimeFormated(last_update)}`
        : '-',
      data: formatLineGroups,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      }
    });
  }
}

export default LineGroupsData;
