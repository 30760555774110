import { Statistic } from './Statistic';

export interface WarehouseRankingDTO {
  statistics: Statistic[];
  total_statistics: Statistic;
}

export const warehouseRankingDTOMapper = (data: any): WarehouseRankingDTO => {
  return {
    total_statistics: Statistic.generateDTO(data?.total_statistics),
    statistics:
      data?.statistics?.map((statistic) => Statistic.generateDTO(statistic)) ??
      []
  };
};
