import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { TableColumnProps } from 'modules/inbound/shared/ui/layout/components/Table/types';
import {
  IEntryInPlantDetailDTO,
  PieceInPLantDTO
} from '../../infrastructure/dto/InPlantDTO/EntryInPlantDetailDTO';
import { EntriesText } from '../../infrastructure/i18n/EntriesText';
import { EntriesTranslations } from '../../infrastructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

const entriesTranslations: EntriesTranslations & InboundTranslations = {
  ...EntriesText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

export interface IPieceInPlant {
  isCritical: boolean;
  code: string;
  coverage: string;
  warehouse: string;
  packages: number;
  status: string;
  containerType: string;
  container: string;
}

export interface IEntryInPlantItem {
  hasCriticalPieces: boolean;
  plateNumber: string;
  transportId: number | string;
  carrier: string;
  stops: number;
  windowBooked: string;
  packages: number;
  globalDlz: string;
}

export enum EntryInPlantDetailColumnKeys {
  hasCriticalPieces = 'hasCriticalPieces',
  plateNumber = 'plateNumber',
  transportId = 'transportId',
  carrier = 'carrier',
  stops = 'stops',
  windowBooked = 'windowBooked',
  packages = 'packages',
  globalDlz = 'globalDlz'
}

export enum InPlantDetailPieceColumnKeys {
  isCritical = 'isCritical',
  code = 'code',
  coverage = 'coverage',
  warehouse = 'warehouse',
  packages = 'packages',
  status = 'status',
  container = 'container'
}

const RightAlignedColumns = [
  EntryInPlantDetailColumnKeys.stops,
  EntryInPlantDetailColumnKeys.packages,
  EntryInPlantDetailColumnKeys.globalDlz,
  InPlantDetailPieceColumnKeys.coverage,
  InPlantDetailPieceColumnKeys.coverage,
  InPlantDetailPieceColumnKeys.packages
];

interface EntriesInPlantDetailPiecesColumns extends TableColumnProps {
  key: keyof PieceInPLantDTO;
  uiKey: keyof IPieceInPlant;
}

export interface IEntryInPlantDetail {
  entryData: IEntryInPlantItem[];
  entryColumns: TableColumnProps[];
  pieces: IPieceInPlant[];
  piecesColumns: EntriesInPlantDetailPiecesColumns[];
}

const ENTRIES_IN_PLANT_DETAIL_PIECES_COLUMNS: EntriesInPlantDetailPiecesColumns[] =
  [
    {
      key: 'is_critical',
      uiKey: 'isCritical',
      value: '',
      isVisible: true,
      align: 'left'
    },
    {
      key: 'code',
      uiKey: 'code',
      value: entriesTranslations.code,
      isVisible: true,
      align: 'left'
    },
    {
      key: 'coverage',
      uiKey: 'coverage',
      value: entriesTranslations.coverage,
      isVisible: true,
      align: 'right'
    },
    {
      key: 'warehouse',
      uiKey: 'warehouse',
      value: entriesTranslations.warehouse,
      isVisible: true,
      align: 'left'
    },
    {
      key: 'packages',
      uiKey: 'packages',
      value: entriesTranslations.numberOfContainers,
      isVisible: true,
      align: 'right'
    },
    {
      key: 'status',
      uiKey: 'status',
      value: entriesTranslations.status,
      isVisible: true,
      align: 'left'
    },
    {
      key: 'container_type',
      uiKey: 'containerType',
      value: entriesTranslations.containerType,
      isVisible: true,
      align: 'left'
    },
    {
      key: 'container',
      uiKey: 'container',
      value: entriesTranslations.container,
      isVisible: true,
      align: 'left'
    }
  ];

class EntryInPlantDetail implements IEntryInPlantDetail {
  entryData: IEntryInPlantItem[];
  entryColumns: TableColumnProps[];
  pieces: IPieceInPlant[];
  piecesColumns: EntriesInPlantDetailPiecesColumns[];

  constructor({
    entryData,
    entryColumns,
    pieces,
    piecesColumns
  }: EntryInPlantDetail) {
    this.entryData = entryData;
    this.entryColumns = entryColumns;
    this.pieces = pieces;
    this.piecesColumns = piecesColumns;
  }
  private static _camelToSnakeCase(key) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase();
  }
  private static _formatThousandsDotDays(quantity) {
    if (quantity === null) {
      return '-';
    }
    const numberWithDots = quantity
      .toFixed(2)
      .replace(/\.0+$/, '')
      .replace('.', ',')
      .toLocaleString('de-DE');

    if (numberWithDots === '1') {
      return `${numberWithDots} ${entriesTranslations.unitSingleDay}`;
    }
    return `${numberWithDots} ${entriesTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    has_critical_pieces,
    plate_number,
    transport_id,
    carrier,
    stops,
    window_booked,
    packages,
    global_dlz,
    pieces
  }: IEntryInPlantDetailDTO): EntryInPlantDetail {
    return new EntryInPlantDetail({
      entryData: [
        {
          hasCriticalPieces: has_critical_pieces,
          plateNumber: plate_number,
          transportId: transport_id ?? NO_DATA,
          carrier,
          stops,
          windowBooked: GetDateFormatedService.getShortDateTime(window_booked),
          packages,
          globalDlz: GetDateFormatedService.formatSecondsToMinutes(global_dlz)
        }
      ],
      entryColumns: Object.keys(EntryInPlantDetailColumnKeys).map((key) => ({
        key: this._camelToSnakeCase(key),
        uiKey: key,
        value: entriesTranslations[key] ?? '',
        sortable: false,
        isVisible: true,
        align: RightAlignedColumns.includes(key as EntryInPlantDetailColumnKeys)
          ? 'right'
          : 'left'
      })),
      pieces: pieces.map((piece) => ({
        isCritical: piece.is_critical ?? false,
        code: piece.code,
        coverage: this._formatThousandsDotDays(piece.coverage),
        warehouse: piece.warehouse,
        packages: piece.packages,
        status: piece.status ? piece.status : NO_DATA,
        containerType: piece.container_type || NO_DATA,
        container: piece.container ? piece.container : NO_DATA
      })),
      piecesColumns: ENTRIES_IN_PLANT_DETAIL_PIECES_COLUMNS
    });
  }

  static generateEmpty() {
    return new EntryInPlantDetail({
      entryData: [],
      entryColumns: [],
      pieces: [],
      piecesColumns: []
    });
  }
}

export default EntryInPlantDetail;
