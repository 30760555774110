import IJitRepository from '../domain/IJitRepository';
import JitGeneralData from '../domain/JitGeneralData';
import JitRepository from '../infrastructure/repositories/JitRepository';

class GetJitGeneralDataService {
  private _jitRepository: IJitRepository;

  constructor({ jitRepository }) {
    this._jitRepository = jitRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._jitRepository
      .getGeneralData({ queryParams })
      .then((res) => JitGeneralData.generateFromDTO(res));
  }
}

export default new GetJitGeneralDataService({
  jitRepository: JitRepository
});

export { GetJitGeneralDataService };
