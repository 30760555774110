interface GenerateFromDTOParams {
  transportId: string;
  plate: string;
  originName: string;
  destinationName: string;
  carrier: string;
  departureDate: string;
  lastLocationName: string;
  lastLocationDate: string;
  estimatedArrival: string;
  vins: string[];
}

class FinishedGoodTransportDetails {
  transportId: string;
  frameNumber: string;
  origin: string;
  destination: string;
  carrierName: string;
  departureDate: string;
  currentLocation: string;
  locationDate: string;
  eta: string;
  cargoVins: string[];

  constructor({
    transportId,
    frameNumber,
    origin,
    destination,
    carrierName,
    departureDate,
    currentLocation,
    locationDate,
    eta,
    cargoVins
  }) {
    this.transportId = transportId;
    this.frameNumber = frameNumber;
    this.origin = origin;
    this.destination = destination;
    this.carrierName = carrierName;
    this.departureDate = departureDate;
    this.currentLocation = currentLocation;
    this.locationDate = locationDate;
    this.eta = eta;
    this.cargoVins = cargoVins;
  }

  static generateFromDTO({
    transportId,
    plate,
    originName,
    destinationName,
    carrier,
    departureDate,
    lastLocationName,
    lastLocationDate,
    estimatedArrival,
    vins
  }: GenerateFromDTOParams) {
    const departureDateAsDate = new Date(departureDate);
    const locationDateAsDate = new Date(lastLocationDate);
    const etaAsDate = new Date(estimatedArrival);

    return new FinishedGoodTransportDetails({
      transportId,
      frameNumber: plate,
      origin: originName,
      destination: destinationName,
      carrierName: carrier,
      departureDate: this._formatDate(departureDateAsDate),
      currentLocation: lastLocationName,
      locationDate: this._formatDate(locationDateAsDate),
      eta: this._formatDate(etaAsDate),
      cargoVins: vins
    });
  }

  static generateEmpty() {
    return new FinishedGoodTransportDetails({
      transportId: '',
      frameNumber: '',
      origin: '',
      destination: '',
      carrierName: '',
      departureDate: '',
      currentLocation: '',
      locationDate: '',
      eta: '',
      cargoVins: []
    });
  }

  private static _formatDate(date: Date) {
    return `${this._padTo2Digits(date.getDate())}/${this._padTo2Digits(
      date.getMonth() + 1
    )}/${this._padTo2Digits(date.getFullYear())}`;
  }
  private static _padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
}

export default FinishedGoodTransportDetails;
