import IEntriesRepository from '../../domain/IEntriesRepository';
import { SaturationUnloadingPoints } from '../../domain/UnloadingPoints/unloadingPointsMapper';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetSaturationUnloadingPointsUseCase {
  private _entriesDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesDataRepository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<SaturationUnloadingPoints> {
    return this._entriesDataRepository.getSaturationUnloadingPoints({
      queryParams
    });
  }
}

const getSaturationUnloadingPointsUseCase =
  new GetSaturationUnloadingPointsUseCase({
    repository: entriesRepository
  });

export {
  GetSaturationUnloadingPointsUseCase,
  getSaturationUnloadingPointsUseCase
};
