import { Translation } from 'infrastructure/i18n/typing';
import { TransferTranslations } from './typing';

export const TransferText: Translation<TransferTranslations> = {
  es: {
    noTransfers: 'No hay nada pendiente de trasvasar',
    trasnferStockContainer: 'Tabla de trasvases',
    code: 'Clave',
    description: 'Descripción',
    location: 'Ubicación',
    warehouse: 'Almacén',
    warehouseType: 'Tipo de almacén',
    planningType: 'Tipo de planificación',
    handlingUnit: 'HU',
    factoryCoverage: 'Cobertura SEAT',
    planned: 'Planificado',
    basicContainer: 'Cont. Básico',
    transportContainer: 'Cont. Transporte',
    supplyContainer: 'Cont. Suministro',
    lastUpdate: 'Actualización fichero: ',
    searcherPlaceholder: 'Búsqueda por clave, descripción o contenedor',
    basicContainerMultiselectAvailable: 'Cont. básicos disponibles',
    basicContainerMultiselectSelected: 'Cont. básicos seleccionados',
    basicContainerMultiselectEmpty: 'No hay Cont. básicos disponibles',
    supplyContainerMultiselectAvailable: 'Cont. suministro disponibles',
    supplyContainerMultiselectSelected: 'Cont. suministro seleccionados',
    supplyContainerMultiselectEmpty: 'No hay Cont. suministro disponibles',
    warehouseTypeMultiselectAvailable: 'Tipos almacén disponibles',
    warehouseTypeMultiselectSelected: 'Tipos almacén seleccionados',
    warehouseTypeMultiselectEmpty: 'No hay Tipos almacén disponibles',
    warehousesMultiselectAvailable: 'Almacenes disponibles',
    warehousesMultiselectSelected: 'Almacenes seleccionados',
    warehousesMultiselectEmpty: 'No hay Almacenes disponibles',
    planningTypeMultiselectAvailable: 'Tipos de planificación disponibles',
    planningTypeMultiselectSelected: 'Tipos de planificación seleccionados',
    planningTypeMultiselectEmpty: 'No hay tipos de planificación disponibles',
    supplyTypeMultiselectAvailable: 'Tipos suministro disponibles',
    supplyTypeMultiselectSelected: 'Tipos suministro seleccionados',
    supplyTypeMultiselectEmpty: 'No hay tipos suministro disponibles',
    deliveryTime: 'Fecha entrega',
    providerDeliveryNote: 'Albarán',
    plateNumber: 'Matrícula',
    numPackages: '# Bulto',
    amount: 'Cantidad',
    containersNeeds: 'Necesidades de Vacíos',
    coverageWithoutStock: 'Cob Disponible',
    coverageStoredOutsideTranfer:
      'Cobertura de piezas almacenadas fuera de la zona de trasvase.',
    transfer: 'Directo de trasvase a línea',
    inTransit: 'Directo de trasvase a línea en tránsito',
    containerAlerts: 'Alertas contenedor',
    asnContainer: 'Cont. ASN',
    supplyType: 'Tipo suministro',
    containerType: 'Tipo contenedor',
    packagingMaterial: 'Cont. almacén',
    isPlanned: 'Tipo de planificación',
    notPlanned: 'No planificado',
    materialInTransfer: 'Material en zona Trasvase',
    transferNextHours: 'Llegadas próximas 8h'
  },
  en: {
    noTransfers: 'There are no pending transfers',
    trasnferStockContainer: 'Transfers table',
    code: 'Code',
    description: 'Description',
    location: 'Location',
    warehouse: 'Warehouse',
    warehouseType: 'Warehouse type',
    planningType: 'Planning type',
    handlingUnit: 'HU',
    factoryCoverage: 'Coverage SEAT',
    planned: 'Planned',
    basicContainer: 'Cont. Basic',
    transportContainer: 'Cont. Transport',
    supplyContainer: 'Cont. Supply',
    lastUpdate: 'File update: ',
    searcherPlaceholder: 'Search by part, description or container',
    basicContainerMultiselectAvailable: 'Basic Cont. available',
    basicContainerMultiselectSelected: 'Basic Cont. selected',
    basicContainerMultiselectEmpty: "There aren't Basic Cont.",
    supplyContainerMultiselectAvailable: 'Supply Cont. available',
    supplyContainerMultiselectSelected: 'Supply Cont. selected',
    supplyContainerMultiselectEmpty: "There aren't Supply Cont.",
    warehouseTypeMultiselectAvailable: 'Warehouse Type Cont. available',
    warehouseTypeMultiselectSelected: 'Warehouse Type Cont. selected',
    warehouseTypeMultiselectEmpty: "There aren't Warehouse Type Cont.",
    warehousesMultiselectAvailable: 'Warehouses available',
    warehousesMultiselectSelected: 'Warehouses selected',
    warehousesMultiselectEmpty: "There aren't Warehouses",
    planningTypeMultiselectAvailable: 'Available planning types',
    planningTypeMultiselectSelected: 'Selected planning types',
    planningTypeMultiselectEmpty: "There aren't planning types available",
    supplyTypeMultiselectAvailable: 'Supply types available',
    supplyTypeMultiselectSelected: 'Supply types selected',
    supplyTypeMultiselectEmpty: "There aren't supply types available",
    deliveryTime: 'Delivary date',
    providerDeliveryNote: 'Delivery Note',
    plateNumber: 'Plate',
    numPackages: '# Package',
    amount: 'Amount',
    containersNeeds: 'Empty Needs',
    coverageWithoutStock: 'Cov Available',
    coverageStoredOutsideTranfer:
      'Coverage of parts stored outside the transfer zone.',
    transfer: 'Direct transfer to line',
    inTransit: 'Direct transfer to line in transit',
    containerAlerts: 'Container Alerts',
    asnContainer: 'Cont. ASN',
    supplyType: 'Supply Type',
    containerType: 'Container type',
    packagingMaterial: 'Warehouse cont.',
    isPlanned: 'Type of planning',
    notPlanned: 'Not planned',
    materialInTransfer: 'Material in Transfer zone',
    transferNextHours: 'Next 8 hours arrivals'
  },
  ca: {
    noTransfers: 'No hi ha res per transvasar',
    trasnferStockContainer: 'Taula de transvasaments',
    code: 'Clau',
    description: 'Descripció',
    location: 'Ubicació',
    warehouse: 'Magatzem',
    warehouseType: 'Tipus de magatzem',
    planningType: 'Tipus de planificació',
    handlingUnit: 'HU',
    factoryCoverage: 'Cobertura SEAT',
    planned: 'Planificat',
    basicContainer: 'Cont. Bàsic',
    transportContainer: 'Cont. Transport',
    supplyContainer: 'Cont.Subministra.',
    lastUpdate: 'Actualització fitxer: ',
    searcherPlaceholder: 'Cerca per clau, descripció o contenidor',
    basicContainerMultiselectAvailable: 'Cont. bàsics disponibles',
    basicContainerMultiselectSelected: 'Cont. bàsics seleccionats',
    basicContainerMultiselectEmpty: 'No hi ha Cont. bàsics disponibles',
    supplyContainerMultiselectAvailable: 'Cont. suministrament disponibles',
    supplyContainerMultiselectSelected: 'Cont. suministrament seleccionats',
    supplyContainerMultiselectEmpty:
      'No hi ha Cont. suministrament disponibles',
    warehouseTypeMultiselectAvailable: 'Tipus magatzem disponibles',
    warehouseTypeMultiselectSelected: 'Tipus magatzem seleccionats',
    warehouseTypeMultiselectEmpty: 'No hi ha Tipus magatzem disponibles',
    warehousesMultiselectAvailable: 'Magatzems disponibles',
    warehousesMultiselectSelected: 'Magatzems seleccionats',
    warehousesMultiselectEmpty: 'No hi ha Magatzems disponibles',
    planningTypeMultiselectAvailable: 'Tipus de planificació disponibles',
    planningTypeMultiselectSelected: 'Tipus de planificació seleccionats',
    planningTypeMultiselectEmpty: 'No hi ha tipus de planificació disponibles',
    supplyTypeMultiselectAvailable: 'Tipus suministre disponibles',
    supplyTypeMultiselectSelected: 'Tipus suministre seleccionados',
    supplyTypeMultiselectEmpty: 'No hi ha tipus suministre disponibles',
    deliveryTime: "Data d'entrega",
    providerDeliveryNote: 'Albarà',
    plateNumber: 'Matrícula',
    numPackages: '# Bulto',
    amount: 'Quantitat',
    containersNeeds: 'Necessitats de buits',
    coverageWithoutStock: 'Cob Disponible',
    coverageStoredOutsideTranfer:
      'Cobertura de peces emmagatzemades fora de la zona de transvasament.',
    transfer: 'Directe de transvasament a línia',
    inTransit: 'Directe de transvasament a línia en trànsit',
    containerAlerts: 'Alertes contenidor',
    asnContainer: 'Cont. ASN',
    supplyType: 'Tipus subministrament',
    containerType: 'Tipus contenidor',
    packagingMaterial: 'Cont. magatzem',
    isPlanned: 'Tipus de planificació',
    notPlanned: 'No planificat',
    materialInTransfer: 'Material a zona Trasvase',
    transferNextHours: 'Arrivades pròximes 8h'
  }
};
