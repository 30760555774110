import LocaleService from 'infrastructure/i18n/LocaleService';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import { RefillsStatusType } from '../infrastructure/dto/types';
import { AlertTypeColor } from 'modules/shared/domain/enums';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { IRefillsDTO } from '../infrastructure/dto/RefillsDataDTO';
import { REFILLS_COLUMNS } from './RefillsColumns';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';

const inboundTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};

const PIECE = 'PIECE',
  DAY = 'DAY';

export interface Refills {
  code: string;
  transportOrderNumber: number;
  p17Coverage: string;
  stock: string;
  warehouseUnitTarget: string;
  p17Stock: string;
  refillTimeInMinutes: string;
  delayed: boolean;
  warehouseUnitSource: string;
  p17Source: string;
  status: RefillsStatusType;
  type: string;
  isTransfer: boolean;
  handlingUnit: string;
  createdAt: string;
  sourceLocation: string;
}

export interface IRefills {
  refills: Refills[];
  columns: ColumnProps[];
}
class RefillsData implements IRefills {
  refills: Refills[];
  columns: ColumnProps[];
  pagination: IPagination;
  lastUpdate: string;

  constructor({ refills, columns, pagination, lastUpdate }: RefillsData) {
    this.refills = refills;
    this.columns = columns;
    this.pagination = pagination;
    this.lastUpdate = lastUpdate;
  }

  private static _formatThousandsDot(quantity, unitType) {
    if (quantity === null) {
      return '-';
    }
    const numberWithDots = quantity.toLocaleString('de-DE');

    if (numberWithDots === '1') {
      return unitType === PIECE
        ? `${numberWithDots} ${inboundTranslations.unitSinglePiece}`
        : `${numberWithDots} ${inboundTranslations.unitSingleDay}`;
    }
    return unitType === PIECE
      ? `${numberWithDots} ${inboundTranslations.unitMultiplePieces}`
      : `${numberWithDots} ${inboundTranslations.unitMultipleDays}`;
  }

  static generateFromDTO({
    refills,
    pagination,
    last_update
  }: IRefillsDTO): RefillsData {
    let formatRefills: Refills[] = [];

    if (refills.length) {
      refills.forEach((item) => {
        formatRefills.push({
          code: item.code,
          transportOrderNumber: item.transport_order_number,
          stock:
            item.stock >= 0 ? this._formatThousandsDot(item.stock, PIECE) : '-',
          warehouseUnitTarget: item.warehouse_unit_target,
          p17Coverage: this._formatThousandsDot(item.p17_coverage, DAY),
          p17Stock: this._formatThousandsDot(item.p17_stock, PIECE),
          refillTimeInMinutes: item.refill_time_in_minutes
            ? GetDateFormatedService.formatTimeToHoursAndMinutes(
                item.refill_time_in_minutes
              )
            : '-',
          delayed: item.delayed,
          warehouseUnitSource: item.warehouse_unit_source ?? '-',
          p17Source: item.p17_source ?? '-',
          isTransfer: item.is_transfer,
          status: item.status,
          type: AlertTypeColor[item.type],
          handlingUnit: item.handling_unit,
          createdAt: item.created_at
            ? GetDateFormatedService.getShortDateTime(item.created_at)
            : '-',
          sourceLocation: item.source_location || '-'
        });
      });
    }

    const lastUpdateToUCT = last_update
      ? GetDateFormatedService.dateFromISO8601(last_update)
      : '';

    const lastUpdateDate = lastUpdateToUCT
      ? GetDateFormatedService.getDateFormated(lastUpdateToUCT, 'de-DE')
      : '';
    const lastUpdateTime = lastUpdateToUCT
      ? GetDateFormatedService.getTimeFormated(lastUpdateToUCT)
      : '';

    return new RefillsData({
      refills: formatRefills,
      pagination: {
        page: pagination.page,
        size: pagination.size,
        pageCount: pagination.page_count,
        totalCount: pagination.total_count
      },
      lastUpdate: last_update ? `${lastUpdateDate} | ${lastUpdateTime}` : '-',
      columns: REFILLS_COLUMNS
    });
  }

  static generateEmpty(): RefillsData {
    return new RefillsData({
      refills: [],
      pagination: { page: 1, size: 10, pageCount: 0, totalCount: 0 },
      lastUpdate: '-',
      columns: []
    });
  }
}

export default RefillsData;
