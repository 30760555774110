import styled from 'styled-components';
import ThemeSEATProps from 'modules/shared/infrastructure/themes/typing';

export enum ArrowDirection {
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  FINAL = 'FINAL'
}

const arrowTransformDegrees = {
  [ArrowDirection.CLOSE]: '270',
  [ArrowDirection.OPEN]: '90'
};
const MenuUnorderedList = styled.ul<{ isOpened: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(props: { isOpened: boolean }) =>
    props.isOpened ? '0 1.5rem' : '0 2.5rem'};
  overflow-y: auto;
`;

const WrapperItem = styled.div<{ isOpened: boolean; active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(props: { isOpened: boolean }) =>
    props.isOpened ? 'flex-start' : 'center'};
  margin-bottom: 0.5rem;
  padding: 8px;
  ${(props: { theme: ThemeSEATProps; active?: boolean }) =>
    props.active &&
    `background-color:  ${props.theme.colors.orange.hexa}; border-radius:8px; `};
  & svg.icon {
    margin-right: ${(props: { isOpened: boolean }) =>
      props.isOpened && '0.5rem'};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;
  color: ${(props: { theme: ThemeSEATProps }) => props.theme.colors.white.hexa};
  font-family: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.fontFamily.regular};
  font-size: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.fonts.regularSmall.minSizeAsPixels};
`;

const Item = styled.div<{ arrowDirection: ArrowDirection }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  & svg {
    transform: ${(props: { arrowDirection: ArrowDirection }) =>
      `rotate(${arrowTransformDegrees[props.arrowDirection]}deg)`};
  }
`;

const SubItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SubItemsWrapper = styled.div<{ secondLayer: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${(props: { theme: ThemeSEATProps; secondLayer: boolean }) =>
    props.secondLayer
      ? props.theme.colors.neutral300.hexa
      : props.theme.colors.neutral600.hexa};
  border-radius: 8px;
  padding: 0.5rem;
  padding-top: 1rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
`;

const SubItem = styled.div<{
  arrowDirection: ArrowDirection;
  active: boolean;
  secondLayer: boolean;
}>`
  display: flex;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props: { theme: ThemeSEATProps }) =>
    props.theme.borders.small};
  background-color: ${(props: {
    theme: ThemeSEATProps;
    active: boolean;
    secondLayer: boolean;
  }) =>
    props.active && props.secondLayer
      ? props.theme.colors.orange500.hexa
      : props.active && props.theme.colors.orange.hexa};

  &:hover {
    background-color: ${(props: {
      theme: ThemeSEATProps;
      secondLayer: boolean;
    }) =>
      props.secondLayer
        ? props.theme.colors.neutral600.hexa
        : props.theme.colors.neutral300.hexa};
  }

  & span {
    margin-left: 1rem;
  }
  & svg {
    ${(props: { arrowDirection: ArrowDirection }) =>
      props.arrowDirection === ArrowDirection.FINAL
        ? 'display: none;'
        : `display: block;
        transform: rotate(${arrowTransformDegrees[props.arrowDirection]}deg);
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;`}
  }
`;

export {
  MenuUnorderedList,
  WrapperItem,
  Item,
  SubItems,
  SubItemsWrapper,
  SubItem,
  Wrapper
};
