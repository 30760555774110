import { createAsyncThunk } from '@reduxjs/toolkit';
import GetJitFiltersService from 'modules/inbound/jit/application/GetPartCoverageFiltersService';
import {
  NameValues,
  FiltersItem
} from 'modules/inbound/shared/domain/NameValues';
import { getSelectedSubheaderFilters } from '.';

const getPartCoverageFilters = createAsyncThunk(
  'partCoverageFilters/getPartCoverageFilters',
  async (_, { getState }) => {
    const appState = getState();
    const {
      selectedFamily,
      selectedLines,
      selectedProvidersTier1Ids,
      selectedLogisticOperatorsIds,
      selectedModels,
      selectedInbounders,
      selectedFlowType
    } = getSelectedSubheaderFilters(appState);

    const queryFilters: FiltersItem[] = [
      {
        name: NameValues.FAMILY,
        values: selectedFamily
      },
      {
        name: NameValues.LINES,
        values: selectedLines
      },
      {
        name: NameValues.PROVIDER_TIER_1_ID,
        values: selectedProvidersTier1Ids.map(({ key, text }) => ({
          key: key.split('-')[0].trim(),
          text: text
        }))
      },
      {
        name: NameValues.LOGISTIC_OPERATOR_ID,
        values: selectedLogisticOperatorsIds
      },
      {
        name: NameValues.MODELS,
        values: selectedModels
      },
      {
        name: NameValues.INBOUNDER,
        values: selectedInbounders
      }
    ];

    !!selectedFlowType.key &&
      queryFilters.push({
        name: NameValues.FLOW_TYPE,
        values: [selectedFlowType]
      });

    let querySearch: string[] = [];

    queryFilters.forEach((filter) => {
      const values = filter.values.map((value) => value.key);
      if (filter.name === NameValues.MODELS && !!filter.values.length) {
        querySearch.push(`${filter.name}:LK:(${values.join(';')})`);
      } else {
        if (
          filter.values.length > 1 ||
          (filter.name === NameValues.LINES && !!filter.values.length)
        ) {
          querySearch.push(
            `${filter.name}:IN:(${values
              .map((value) => `"${value}"`)
              .join(';')})`
          );
        }
        if (filter.values.length === 1 && filter.name !== NameValues.LINES) {
          querySearch.push(`${filter.name}:=:"${values}"`);
        }
      }
      return '';
    });

    const query = querySearch.filter((item) => item !== '').join('||');
    const queryParams = {
      search: query && `{${query}}`
    };
    const response = await GetJitFiltersService.execute({ queryParams });
    return response;
  }
);

export { getPartCoverageFilters };
