import { createSlice } from '@reduxjs/toolkit';
import { getJitFamilyCoverage } from './async';
import { JitFamilyCoverageState } from './types';

export const initialState: JitFamilyCoverageState = {
  data: {
    alertsTotal: 0,
    coverage: 0,
    families: []
  },
  requestStatus: ''
};

const jitFamilyCoverageDataSlice = createSlice({
  name: 'JitFamilyCoverageState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJitFamilyCoverage.fulfilled, (state, action) => {
      state.data.alertsTotal = action.payload.alertsTotal;
      state.data.coverage = action.payload.coverage;
      state.data.families = action.payload.families;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitFamilyCoverage.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getJitFamilyCoverage.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

export const getJitFamilyCoverageDataState = (state) =>
  state.jitFamilyCoverageState.data;
export const getIsLoadingStatus = (state) =>
  state.jitFamilyCoverageState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.jitFamilyCoverageState.requestStatus === 'rejected' ||
  !state.jitFamilyCoverageState.data.families.length;

export default jitFamilyCoverageDataSlice.reducer;
