import { ThemeSEATColors } from 'modules/shared/infrastructure/themes/colors/typing';

export enum BrandEnum {
  SEAT = 'S',
  CUPRA = 'C',
  AUDI = 'A'
}

export enum AlertTypeColor {
  LINE = 'blue',
  TRANSFER = 'white'
}

export type CriticalStockType =
  | 'IN_TRANSIT'
  | 'RECEPTION'
  | 'ST17'
  | 'TRANSFER'
  | 'NO_TRANSIT'
  | 'IN_TRANSIT_ALREADY_TRANSFERRED'
  | 'DEFAULT';

type ICriticalStockTypeColor = {
  [type in CriticalStockType]: keyof ThemeSEATColors;
};
export const CriticalStockColor: ICriticalStockTypeColor = {
  IN_TRANSIT: 'orange',
  RECEPTION: 'lightBlue',
  ST17: 'blue',
  TRANSFER: 'white',
  NO_TRANSIT: 'purple',
  IN_TRANSIT_ALREADY_TRANSFERRED: 'white',
  DEFAULT: 'gray300'
};

export enum CriticalTypes {
  IN_TRANSIT = 'inTransit',
  RECEPTION = 'reception',
  ST17 = 'st17',
  TRANSFER = 'transfer',
  NO_TRANSIT = 'noTransit',
  IN_TRANSIT_ALREADY_TRANSFERRED = 'inTransitAlreadyTransferred'
}
