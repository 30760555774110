import { createSlice } from '@reduxjs/toolkit';
import { WarehouseForecastByModelState } from './types';
import { getWarehouseForecastByModel } from './async';

export const initialState: WarehouseForecastByModelState = {
  forecastByModel: [],
  maxCapacity: 0,
  warehouseReference: '',
  requestStatus: ''
};

const deliveriesGeolocationSlice = createSlice({
  name: 'warehouseForecastByModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWarehouseForecastByModel.fulfilled, (state, action) => {
      state.forecastByModel = action.payload.forecast || [];
      state.maxCapacity = action.payload.maxCapacity || 0;
      state.warehouseReference = action.payload.warehouseReference || '';
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseForecastByModel.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getWarehouseForecastByModel.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getForecastByModel = (state) =>
  state.warehouseForecastByModelState.forecastByModel;

export const getRequestStatus = (state) =>
  state.warehouseForecastByModelState.requestStatus;

export const getWarehouseReference = (state) =>
  state.warehouseForecastByModelState.warehouseReference;

export const getMaxCapacity = (state) =>
  state.warehouseForecastByModelState.maxCapacity;

export default deliveriesGeolocationSlice;
