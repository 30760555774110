import { createAsyncThunk } from '@reduxjs/toolkit';
import CreateNewContactUseCase from 'modules/inbound/shared/application/ProviderContactListUseCases/CreateNewContactUseCase';
import {
  getCurrentSelectedProviderId,
  getSelectedContact
} from '../ContactListReducer';
import EditContactUseCase from 'modules/inbound/shared/application/ProviderContactListUseCases/EditContactUseCase';

export const postNewContact = createAsyncThunk(
  'contactForm/postNewContact',
  async (
    contactInfo: {
      role: string;
      name: string;
      telephone: string;
      email: string;
    },
    { getState }
  ) => {
    const appState = getState();
    const selectedProvider = getCurrentSelectedProviderId(appState);
    const response = await CreateNewContactUseCase.execute({
      providerId: selectedProvider,
      body: {
        role: contactInfo.role,
        name: contactInfo.name,
        telephone: contactInfo.telephone,
        email: contactInfo.email
      }
    });
    return response;
  }
);

export const editContact = createAsyncThunk(
  'contactForm/editContact',
  async (
    contactInfo: {
      role: string;
      name: string;
      telephone: string;
      email: string;
    },
    { getState }
  ) => {
    const appState = getState();
    const selectedProvider = getCurrentSelectedProviderId(appState);
    const contact = getSelectedContact(appState);
    const response = await EditContactUseCase.execute({
      providerId: selectedProvider,
      contactUid: contact.id,
      body: {
        role: contactInfo.role,
        name: contactInfo.name,
        telephone: contactInfo.telephone,
        email: contactInfo.email
      }
    });
    return response;
  }
);
