import { ExpirationDataDTO } from '../infrastructure/dto/expirationsDataDTOMapper';
import { IPagination } from 'modules/inbound/shared/domain/IPagination';
import { Expiration, ExpirationData } from './ExpirationData';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';

export interface ExpirationsData {
  lastUpdate: string;
  expirations: Expiration[];
  targetUids: string[];
  pagination: IPagination;
}

const expirationsDataMapper = (dto: ExpirationDataDTO): ExpirationsData => ({
  lastUpdate: dto.last_update
    ? `${GetDateFormatedService.getDateFormated(
        dto.last_update,
        'de-DE'
      )} | ${GetDateFormatedService.getTimeFormated(dto.last_update)}`
    : '-',
  expirations: dto.expirations.map((expirationItem) =>
    ExpirationData.generateFromDto(expirationItem)
  ),
  targetUids: dto.expirations.map((expiration) => expiration.uid),
  pagination: {
    page: dto.pagination.page,
    size: dto.pagination.size,
    pageCount: dto.pagination.page_count,
    totalCount: dto.pagination.total_count
  }
});

export { expirationsDataMapper };
