export interface GlobalTransitFulfillmentData {
  delayedCars: number;
  totalCars: number;
}
class GlobalTransitFulfillmentResponseDTO {
  requestedDatesGlobalKap: GlobalTransitFulfillmentData;

  constructor({ requestedDatesGlobalKap }) {
    this.requestedDatesGlobalKap = requestedDatesGlobalKap;
  }
}

export default GlobalTransitFulfillmentResponseDTO;
