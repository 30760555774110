import { RefillsStatusType } from './types';

interface StatsDTO {
  counter: number;
  status: RefillsStatusType;
}

interface AlertsItemDTO {
  total: number;
  stats: StatsDTO[];
}

export interface AlertsDTO {
  transfer: AlertsItemDTO;
  direct_to_line: AlertsItemDTO;
}

class RefillsAlertsDTO {
  alerts: AlertsDTO;

  constructor({ alerts }) {
    this.alerts = alerts;
  }
}

export default RefillsAlertsDTO;
