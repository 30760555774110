import {
  ColumnProps,
  PlanningColumnsKeys
} from 'modules/inbound/shared/domain/ColumnsTypes';
import { ShiftType } from 'modules/inbound/shared/domain/ShiftTypes';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { DirectDeliveryText } from '../infrastructure/i18n/DirectDeliveryText';
import { DirectDeliveryTranslations } from '../infrastructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { ShiftNames } from 'modules/inbound/shared/domain/NameValues';

const directDeliveryTranslations: DirectDeliveryTranslations &
  InboundTranslations = {
  ...DirectDeliveryText[LocaleService.getLanguage()],
  ...InboundText[LocaleService.getLanguage()]
};

/**
 *
 * @param planningDates
 * @param firstDayShifts
 * @returns
 */

export const generatePlanningDatesColumns = (
  planningDates: string[],
  firstDayShifts: number
): ColumnProps[] => {
  const SHIFTS = [ShiftType.MORNING, ShiftType.AFTERNOON, ShiftType.NIGHT];
  const SHIFTS_FIRST_DAY = SHIFTS.slice(-firstDayShifts);

  return (
    planningDates?.map((date, index) => {
      if (index === 0) {
        return {
          key: PlanningColumnsKeys.alwaysVisible,
          value: date,
          headerVisible: true,
          isVisibleCheck: true,
          headerSpan: firstDayShifts,
          columns: SHIFTS_FIRST_DAY.map((shift, shiftIndex) => ({
            uiKey: shift,
            key: shift,
            value: directDeliveryTranslations[ShiftNames[shift]],
            sortable: false,
            tooltipInfo:
              shiftIndex === 0
                ? directDeliveryTranslations.currentShiftDescription
                : undefined
          }))
        };
      }

      return {
        key: PlanningColumnsKeys.optionVisible,
        value: date,
        headerVisible: true,
        isVisibleCheck: false,
        headerSpan: 3,
        columns: SHIFTS.map((shift) => ({
          uiKey: shift,
          key: shift,
          value: directDeliveryTranslations[ShiftNames[shift]],
          sortable: false
        }))
      };
    }) ?? []
  );
};
