class JitGeneralDataDTO {
  line_sequence: string;
  workshop: string;
  branch: string;
  branch_position: number;
  line_sequence_updating_date_at: string;
  marquee: string;
  lisons: string[];

  constructor({
    line_sequence,
    workshop,
    branch,
    branch_position,
    line_sequence_updating_date_at,
    marquee,
    lisons
  }) {
    this.line_sequence = line_sequence;
    this.workshop = workshop;
    this.branch = branch;
    this.branch_position = branch_position;
    this.line_sequence_updating_date_at = line_sequence_updating_date_at;
    this.marquee = marquee;
    this.lisons = lisons;
  }
}

export default JitGeneralDataDTO;
